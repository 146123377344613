import $ from 'jquery';
import SETTINGS from "../Settings.js";


function LoaderScript(url) {
	var self = this;
	this.url = url;
	this.data = document.createElement("script");
	this.loaded = false;
	this.loading = false;
	this.progress = 0.0;
	this.weight = 1;
	this.loadType = 'script';
	this.doneCallbacks = [];

	this.start = function(completeCallback, errorCallback) {
		if (self.loading || self.loaded) console.error('Already loading: ',url);
		self.loading = true;
        var script = self.data;
        (function(script) {
        	script.onload = script.onreadystatechange = function() {	
        		script.loaded = true;
        		script.onload = script.onerror = script = script.onreadystatechange = undefined;
        		self.progress = 1.0;
        		self.loaded = true;
        		for (var i=0; i<self.doneCallbacks.length; i++) {
					self.doneCallbacks[i](self);
				}
				self.doneCallbacks = [];
        		completeCallback(self);
			};
			script.onerror = function() {
				script.loaded = true;
        		script.onload = script.onerror = script = script.onreadystatechange = undefined;
        		self.progress = 1.0;
        		self.loaded = true;
        		for (var i=0; i<self.doneCallbacks.length; i++) {
					self.doneCallbacks[i](self);
				}
				self.doneCallbacks = [];
        		errorCallback(self);
			};
			script.type = "text/javascript";
			script.async = 1;
			script.src = ETTINGS.ASSETS_URL+url;
			script.path = url;
			document.body.appendChild(script);
        })(self.data);
	};
	this.getProgress = function() { return self.progress; }
	this.dispose = function() {
		self.doneCallbacks = self.url = self.start = self.getProgress = self.dispose = self.data = self = null;
	};
	this.getWeight = function() {
		return this.weight;
	};
}

export default LoaderScript;

