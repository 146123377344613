import SETTINGS from '../Settings.js';
import AppStatus from './AppStatus.js';
import BufferingController from './BufferingController.js';
import Loader from "../loading/Loader.js";
import LoaderAudio from "../loading/LoaderAudio.js";

import AudioDisposeController from "./AudioDisposeController.js";
import AudioController from "./AudioController.js";
import SequenceRenderer from "./SequenceRenderer.js";
import BranchController from "./BranchController.js";

var audioFolder = "audio/timeline/";

import { makeNoise2D } from "open-simplex-noise";
var presetFolder = 'data/presets/audioeffect/';


var RAMP_TIME = 0.05;

class AudioEffectController {

	constructor() {
		this.audioStarted = false;

		this.startPositionRandom = new THREE.Vector2(0.0, 0.0);
		this.directionRandom = new THREE.Vector2(1.0, 1.0);
		this.dragSpeed = 0.0;
		this.pressPc = 0.0;

		this.audioBuffersByName = {};
		this.currentBackgroundTrack = null;
		this.currentEffectTrack = null;
		this.currentEffectTrack = null;
		this.currentFrame = 0;
		this.currentImpactRandom = 0;
		this.presets = {};
		this.paused = false;
		this.dragPc = 0.0;

		this.wasDragging = false; 
		// this.audioData = new Uint8Array( 17 );
		this.transitionStartedThisFrame = 0;
		this.currentProgress = 0;
		this.currentPresetName = 'none';
		this.currentId = 'none';
		this.currentTimeStart = 0;
		this.currentTimeEnd = 2;
		this.introAudio = 1;
		this.introAudioTarget = 1;
		this.introAudioTransitionStart = 0;
		this.introAudioTransitionEnd = 0;

		//noise
		this.noises = {
			"reverb": {rng:makeNoise2D(Math.floor(Math.random()*Date.now())), px:Math.random(), py: Math.random()},
			"low": {rng:makeNoise2D(Math.floor(Math.random()*Date.now())), px:Math.random(), py: Math.random()},
			"mid": {rng:makeNoise2D(Math.floor(Math.random()*Date.now())), px:Math.random(), py: Math.random()},
			"high": {rng:makeNoise2D(Math.floor(Math.random()*Date.now())), px:Math.random(), py: Math.random()},
			"feedbackFreq": {rng:makeNoise2D(Math.floor(Math.random()*Date.now())), px:Math.random(), py: Math.random()}
		}

		this.options = {
			preset: "none",
			savePreset: () => {},
			// mobile: false,
			timeline: {
				cue: "none",
				timeStart: "00:00",
				timeEnd: "01:00",
			},
			sfx: {
				file: "none",
				volume: 0.5,
				attack: 0.05,
				play: () =>{}
			},
			backgroundTrack: "v1/circulation_arterielle_B.mp3",
			effectTrack: "v1/Drone_1.mp3",
			reverb: "shimmer_smaller.wav",

			jumpStart: false,
			jumpClick: false,

			voiceVolume: 1.0,
			// voiceMixIn: 0.0,
			effectsVolume: 1.0,
			effectsTrackPreGain: 1.0,
			backgroundVolume: 0.0,
			backgroundMixIn: 0.0,
			compressor: 32,
			postGain: 1.0,

			impact: {
				enabled: false,
				loop: true,
				file: "blip_1.mp3",
				volume: 1.0,
				reverb: 0.0,
				effectMix: 1.0,
				attack: 0.05,
				release: 0.05,
				randomOffset: false
			},
			effectGain: {
				volumeMin: 0.0,
				volumeMax: 1.0,

				reverbMin: 0.0,
				reverbMax: 0.0,
			},
			feedback: {
				enabled: false,
				mix: 1.0,
				resonanceMin: 0.0,
				resonanceMax: 0.0,
				delay: 0.05,
				lowpass: 0.0,
				lowpassFreq: 500
			},
			eq: {
				eqGain: 0.0,

				lowMin: -0.5,
				lowMax: 0.5,
				lowReverbMax: 0.0,

				midMin: -0.5,
				midMax: 0.5,
				midReverbMax: 0.0,

				highMin: -0.5,
				highMax: 0.5,
				highReverbMax: 0.0,

				range: 1.0,
				randomEverything: false
				// randomEverything2: false,
			},
			oscillator: {
				enabled: false,
				frequencyMin: 3.0,
				frequencyMax: 16.0,
				depthMin: 0.0,
				depthMax: 1.0,
				onDrag: 0.25
				// target: "finalGain",
			},
			bandpass: {
				minMix: 1.0,
				maxMix: 1.0,
				release: 0.0,
				xFrequencyMin: 300.0,
				xFrequencyMax: 750.0,
				xQ: 5.0,
				yFrequencyMin: 500.0,
				yFrequencyMax: 1000.0,
				yQ: 5.0,
			}
			// distortion: {
			// 	delay1: 0.05,
			// 	delay2: 0.05,
			// 	delay3: 0.05,

			// 	feedback1: 0.5,
			// 	feedback2: 0.5,
			// 	feedback3: 0.5,

			// 	frequency: 200,
			// 	Q: 5.0,
			// 	gain: 0.0
			// }
		};
		this.defaultOptions = Utils.clone(this.options);

		this.noPresetOptions = Utils.clone(this.options);

		this.noPresetOptions.isNone = true;
		this.noPresetOptions.backgroundTrack = 'none';
		this.noPresetOptions.effectTrack = 'none';
		this.noPresetOptions.reverb = 'none';
		this.noPresetOptions.effectsVolume = 0.0;
		this.noPresetOptions.backgroundVolume = 0.0;
		// this.noPresetOptions.effectsTrackPreGain = 0.0;
		// this.noPresetOptions.backgroundMixIn = 0.0;
		this.noPresetOptions.postGain = 0.0;
		this.noPresetOptions.isEmpty = true;
		
		this.emptyPreset = {value:{isEmpty: true, options:this.noPresetOptions}}



		this.ranges = {
			preset: {isMultiDropdown: true, autofillBaseValues: ["none"], values:[], autofill: presetFolder, autofillEvent: true, callback: () => {
				if (this.options.preset === 'none') {
					this.currentPresetName = 'none';
				} else {
					this.reloadPreset(true);
				}
			}},
			// mobile: {isBoolean: true, isCheckbox: true, callback: () => {}},
			savePreset: {isButton: true},
			sfx: {
				isFolder: true,
				isOpen: false,
				file: {isMultiDropdown: true, autofillBaseValues: ["none"], values:[], autofill: "audio/sfx/", autofillEvent: true},
				volume: {isSlider: true, min:0.0, max:1.0},
				attack: {isSlider: true, min:0.01, max:0.2},
				play: {isButton: true, action: ()=>{
					((file) => {
						console.log("loading", 'audio/sfx/'+file);
						if (file !== 'none') {
							if(this.audioBuffersByName['audio/sfx/'+file]) {
								this.triggerSFX(AudioController.getCurrentTime(), {file: file, timecode: AudioController.getCurrentTime(), attack:this.options.sfx.attack, volume:this.options.sfx.volume})
							} else {
								var batchName = Utils.generateUUID();
								var xhr = new LoaderAudio('audio/sfx/'+file, true);
								xhr.start(() => {
									this.audioBuffersByName['audio/sfx/'+file] = xhr.data;
									this.triggerSFX(AudioController.getCurrentTime(), {file: file, timecode: AudioController.getCurrentTime(), attack:this.options.sfx.attack, volume:this.options.sfx.volume});
								},console.warn);
								Loader.start();
							}
						}	
					})(this.options.sfx.file);
				}}
			},
			timeline: {
				isFolder: true,
				isOpen: false,
				cue: {isDropdown: true, values:[]},
				timeStart: {isText: true},
				timeEnd: {isText: true},
			},
			backgroundTrack: {isMultiDropdown: true, autofillBaseValues: ["none"], values:[], autofill: "audio/music/", autofillEvent: true, callback: () => {

			}},
			effectTrack: {isMultiDropdown: true, autofillBaseValues: ["none"], values:[], autofill: "audio/music/", autofillEvent: true, callback: () => {

			}},
			reverb: {isMultiDropdown: true, autofillBaseValues: ["none"], values:[], autofill: "audio/impulse_response/", autofillEvent: true, callback: () => {

			}},
			jumpStart: {isBoolean: true, isCheckbox: true},
			jumpClick: {isBoolean: true, isCheckbox: true},
			voiceVolume: {isSlider: true, min:0.0, max:2.0},
			// voiceMixIn: {isSlider: true, min:0.0, max:2.0, curve: 2.0},
			effectsVolume: {isSlider: true, min:0.0, max:2.0, curve: 2.0},
			effectsTrackPreGain: {isSlider: true, min:0.0, max:2.0, curve: 2.0},
			backgroundVolume: {isSlider: true, min:0.0, max:1.0, curve: 4.0},
			backgroundMixIn: {isSlider: true, min:0.0, max:2.0, curve: 2.0},
			compressor:{isSlider: true, min:0.0, max:100.0},
			postGain: {isSlider: true, min:0.0, max:1.0},
			effectGain: {
				isFolder: true,
				isOpen: false,
				volumeMin: {isSlider: true, min:0.0, max:2.0},
				volumeMax: {isSlider: true, min:0.0, max:2.0},
				reverbMin: {isSlider: true, min:0.0, max:2.0},
				reverbMax: {isSlider: true, min:0.0, max:2.0},
			},
			impact: {
				isFolder: true,
				isOpen: false,
				isHidden: false,
				enabled: {isBoolean: true},
				loop: {isBoolean: true},
				file: {isMultiDropdown: true, autofillBaseValues: ["none"], values:[]}, //, autofill: "audio/impact/", autofillEvent: true, callback: () => {}},
				volume: {isSlider: true, min:0.0, max:1.0},
				reverb: {isSlider: true, min:0.0, max:1.0},
				effectMix: {isSlider: true, min:0.0, max:2.0},
				attack: {isSlider: true, min:0.0, max:3.0},
				release: {isSlider: true, min:0.0, max:3.0}
			},
			feedback: {
				isFolder: true,
				isOpen: false,
				isHidden: false,
				enabled: {isBoolean: true},
				mix: {isSlider: true, min:0.0, max:1.0},
				resonanceMin: {isSlider: true, min:0.0, max:1.0},
				resonanceMax: {isSlider: true, min:0.0, max:1.0},
				delay: {isSlider: true, min:0.0, max:0.5},
				// panning: {isSlider: true, min:0.0, max:1.0},
				lowpass: {isSlider: true, min:0.0, max:1.0},
				lowpassFreq: {isSlider: true, min:0.0, max:3200.0}
			},			
			eq: {
				isFolder: true,
				isOpen: false,
				isHidden: false,
				eqGain: {isSlider: true, min:0.0, max:2.0},

				lowMin: {isSlider: true, min:-1.0, max:1.0},
				lowMax: {isSlider: true, min:-1.0, max:1.0},
				lowReverbMax: {isSlider: true, min:0.0, max:2.0},
				midMin: {isSlider: true, min:-1.0, max:1.0},
				midMax:{isSlider: true, min:-1.0, max:1.0},
				midReverbMax: {isSlider: true, min:0.0, max:2.0},
				highMin: {isSlider: true, min:-1.0, max:1.0},
				highMax: {isSlider: true, min:-1.0, max:1.0},
				highReverbMax: {isSlider: true, min:0.0, max:2.0},
				range: {isSlider: true, min:0.0, max:2.0},
				randomEverything: {isBoolean: true},
				// randomEverything2: {isBoolean: true},
			},
			oscillator: {
				isFolder: true,
				isOpen: false,
				isHidden: false,
				enabled: {isBoolean: true},
				frequencyMin: {isSlider: true, min:0.0, max:440.0, curve: 3.0},
				frequencyMax: {isSlider: true, min:0.0, max:440.0, curve: 3.0},
				depthMin:  {isSlider: true, min:0.0, max:2.0},
				depthMax: {isSlider: true, min:0.0, max:2.0},
				onDrag: {isSlider: true, min:0.0, max:1.0}
				// target: {isDropdown: true, values:[
				// 	"feedbackMix", "feedbackDelay", "effectVolume", "effectPitch", "eqGain",  "eqLow", "eqLowQ", "eqLowFreq", "eqMid", "eqMidQ", "eqMidFreq", "eqHigh", "eqHighQ", "eqHighFreq", //"impact",
				// 	"bandpassMix", "bandpassXFreq", "bandpassXQ", "bandpassYFreq", "bandpassYQ" 
				// ]},
			},
			bandpass: {
				isFolder: true,
				isOpen: false,
				isHidden: false,
				minMix: {isSlider: true, min:0.0, max:1.0, curve: 2.0},
				maxMix: {isSlider: true, min:0.0, max:1.0, curve: 2.0},
				release: {isSlider: true, min:0.0, max:1.0},
				xFrequencyMin: {isSlider: true, min:0.0, max:4000.0},
				xFrequencyMax: {isSlider: true, min:0.0, max:4000.0},
				xQ: {isSlider: true, min:0.01, max:30.0},
				yFrequencyMin: {isSlider: true, min:0.0, max:4000.0},
				yFrequencyMax: {isSlider: true, min:0.0, max:4000.0},
				yQ: {isSlider: true, min:0.01, max:30.0},
			}
			// distortion: {
			// 	isFolder: true,
			// 	isOpen: false,
			// 	isHidden: false,
			// 	delay1: {isSlider: true, min:0.0, max:1.0, curve: 2.0},
			// 	delay2: {isSlider: true, min:0.0, max:1.0, curve: 2.0},
			// 	delay3: {isSlider: true, min:0.0, max:1.0, curve: 2.0},
			// 	feedback1: {isSlider: true, min:0.0, max:1.0},
			// 	feedback2: {isSlider: true, min:0.0, max:1.0},
			// 	feedback3: {isSlider: true, min:0.0, max:1.0},

			// 	frequency: {isSlider: true, min:1, max:3000.0},
			// 	Q: {isSlider: true, min:0.1, max:30.0},
			// 	gain: {isSlider: true, min:-50.0, max:50.0},
			// }

		};
	}		


	//----------------
	//
	// Loading each preset
	//
	//----------------
	preloadData(batchName) {
		if (SETTINGS.TIMELINE_MODE) {
			for (var o in AppStatus.timelineInfo.audio) {
				if (AppStatus.timelineInfo.audio[o].id=="FIN" && (SETTINGS.INSTALLATION_MODE && !SETTINGS.PRESENTATION_MODE)) AppStatus.timelineInfo.audio[o].preset = "none";
				var preset = AppStatus.timelineInfo.audio[o].preset;
				if (preset && preset!=='none')
					this.presets[preset] = Loader.addXHR(batchName, 'data/presets/audioeffect/' + preset, 'json');

				preset = AppStatus.timelineInfo.audio[o].early_end;
				if (preset && preset!=='none')
					this.presets[preset] = Loader.addXHR(batchName, 'data/presets/audioeffect/' + preset, 'json');
			}
			if (SETTINGS.INTRO_MODE && AppStatus.timelineInfo.intro.audio && AppStatus.timelineInfo.intro.audio!=='none') {
				this.presets[AppStatus.timelineInfo.intro.audio] = Loader.addXHR(batchName, 'data/presets/audioeffect/' + AppStatus.timelineInfo.intro.audio, 'json');
			}
			if (SETTINGS.INTRO_MODE && AppStatus.timelineInfo.intro.audio2 && AppStatus.timelineInfo.intro.audio2!=='none') {
				this.presets[AppStatus.timelineInfo.intro.audio2] = Loader.addXHR(batchName, 'data/presets/audioeffect/' + AppStatus.timelineInfo.intro.audio2, 'json');
			}
		}
	}
	preloadTimeline(batchName, loader, timeStart, timeEnd) {

		//get all timecodes for presets
		if (!this.timecodes) {
			this.timecodes = [];

			for (var o in AppStatus.timelineInfo.audio) {
				var time = Utils.timecodeToSeconds(o);
				this.timecodes.push({
					timecode: time,
					timeStart: time,
					timeEnd: AppStatus.AUDIO_DURATION,
					info: AppStatus.timelineInfo.audio[o]
				});
				// console.log("timeStart:",time);
			}

			// Object.entries(AppStatus.timelineInfo.audio);
			// for (var i=0; i<timecodes.length; i++) {
			// 	this.timecodes[i][0] = Utils.timecodeToSeconds(this.timecodes[i][0]);
			// }
			this.timecodes.sort(function(a,b) {
				return a.timecode < b.timecode ? -1 : 1;
			});
			for (var i=0; i<this.timecodes.length-1; i++) {
				this.timecodes[i].timeEnd = this.timecodes[i+1].timeStart;
			}

			this.timecodes[this.timecodes.length-1].duration = 1000000;
			this.timecodes[this.timecodes.length-1].timeEnd = 1000000;
		}
		
		//if preset is used within this time range, load all assets
		for (var i=0; i<this.timecodes.length; i++) {
			if ((this.timecodes[i].timeEnd >= timeStart-0.1 && this.timecodes[i].timeEnd<=timeEnd+0.1) || (this.timecodes[i].timeStart >= timeStart-0.1 && this.timecodes[i].timeStart <= timeEnd+0.1)) {
				
				//load assets for this preset		
				var presetInfo = this.presets[this.timecodes[i].info.preset];
				if (this.timecodes[i].info.early_end) presetInfo = this.presets[this.timecodes[i].info.early_end];
				if (presetInfo && presetInfo.value) {
					if (this.timecodes[i].info.early_end) presetInfo.value = this.migratePreset(presetInfo.value);
					else this.presets[this.timecodes[i].info.preset].value = presetInfo.value = this.migratePreset(presetInfo.value);

					var pOpt = presetInfo.value.options;
					
					if (pOpt.backgroundTrack && pOpt.backgroundTrack!=='none')
						this.audioBuffersByName["audio/music/"+pOpt.backgroundTrack] = loader.addAudio(batchName, "audio/music/"+pOpt.backgroundTrack, true);

					if (pOpt.effectTrack && pOpt.effectTrack!=='none')
						this.audioBuffersByName["audio/music/"+pOpt.effectTrack] = loader.addAudio(batchName, "audio/music/"+pOpt.effectTrack, true);

					if (pOpt.reverb && pOpt.reverb!=='none')
						this.audioBuffersByName["audio/impulse_response/"+pOpt.reverb] = loader.addAudio(batchName, "audio/impulse_response/"+pOpt.reverb, true);

					if (pOpt.impact.file && pOpt.impact.enabled && pOpt.impact.file!=='none') {

						if (/\.mp3|\.wav/.test(pOpt.impact.file)) {
							this.audioBuffersByName["audio/impact/"+pOpt.impact.file] = {value:[loader.addAudio(batchName, "audio/impact/"+pOpt.impact.file, true)]};
						} else {
							this.audioBuffersByName["audio/impact/"+pOpt.impact.file] = loader.addMultiAudio(batchName, "audio/impact/"+pOpt.impact.file, true);
						}

					}
				}
			}		
		}

		//get all sfx for presets
		if (!this.sfxTimecodes) {
			this.sfxTimecodes = [];

			for (var o in AppStatus.timelineInfo.sfx) {
				if (AppStatus.timelineInfo.sfx[o].file && AppStatus.timelineInfo.sfx[o].file !== "none") {
					var time = Utils.timecodeToSeconds(o);
					this.sfxTimecodes.push({
						timecode: time,
						file: AppStatus.timelineInfo.sfx[o].file,
						volume: AppStatus.timelineInfo.sfx[o].volume,
						attack: AppStatus.timelineInfo.sfx[o].attack,
						played: false
					});
				}
			}

			this.sfxTimecodes.sort(function(a,b) {
				return a.timecode < b.timecode ? -1 : 1;
			});
		}

		//if preset is used within this time range, load all assets
		for (var i=0; i<this.sfxTimecodes.length; i++) {
			if (this.sfxTimecodes[i].timecode >= timeStart-0.1 && this.sfxTimecodes[i].timecode<=timeEnd+0.1) {

				this.audioBuffersByName["audio/sfx/"+this.sfxTimecodes[i].file] =  loader.addAudio(batchName, "audio/sfx/"+this.sfxTimecodes[i].file, true);

				//load assets for this preset		
				// var presetInfo = this.presets[this.sfxTimecodes[i].info.preset];
				// if (presetInfo && presetInfo.value) {
				// 	this.presets[this.timecodes[i].info.preset].value = presetInfo.value = this.migratePreset(presetInfo.value);

				// 	var pOpt = presetInfo.value.options;
					
				// 	if (pOpt.backgroundTrack && pOpt.backgroundTrack!=='none')
				// 		this.audioBuffersByName["audio/music/"+pOpt.backgroundTrack] = loader.addAudio(batchName, "audio/music/"+pOpt.backgroundTrack, true);

				// 	if (pOpt.effectTrack && pOpt.effectTrack!=='none')
				// 		this.audioBuffersByName["audio/music/"+pOpt.effectTrack] = loader.addAudio(batchName, "audio/music/"+pOpt.effectTrack, true);

				// 	if (pOpt.reverb && pOpt.reverb!=='none')
				// 		this.audioBuffersByName["audio/impulse_response/"+pOpt.reverb] = loader.addAudio(batchName, "audio/impulse_response/"+pOpt.reverb, true);

				// 	if (pOpt.impact.file && pOpt.impact.enabled && pOpt.impact.file!=='none') {

				// 		if (/\.mp3|\.wav/.test(pOpt.impact.file)) {
				// 			this.audioBuffersByName["audio/impact/"+pOpt.impact.file] = {value:[loader.addAudio(batchName, "audio/impact/"+pOpt.impact.file, true)]};
				// 		} else {
				// 			this.audioBuffersByName["audio/impact/"+pOpt.impact.file] = loader.addMultiAudio(batchName, "audio/impact/"+pOpt.impact.file, true);
				// 		}

				// 	}
				// }
			}		
		}
	}

	preloadIntro(batchName, loader, i) {
		if (SETTINGS.INTRO_MODE) {

			var presets = [AppStatus.timelineInfo.intro.audio,AppStatus.timelineInfo.intro.audio2];

			//load assets for this preset
			// for (var i=0; i<presets.length; i++) {
				if (presets[i] && presets[i]!=="none") {
					var presetInfo = this.presets[presets[i]];
					if (presetInfo && presetInfo.value) {
						this.presets[presets[i]].value = presetInfo.value = this.migratePreset(presetInfo.value);

						var pOpt = presetInfo.value.options;
						if (pOpt.backgroundTrack && pOpt.backgroundTrack!=='none')
							this.audioBuffersByName["audio/music/"+pOpt.backgroundTrack] = loader.addAudio(batchName, "audio/music/"+pOpt.backgroundTrack, true);

						if (pOpt.effectTrack && pOpt.effectTrack!=='none')
							this.audioBuffersByName["audio/music/"+pOpt.effectTrack] = loader.addAudio(batchName, "audio/music/"+pOpt.effectTrack, true);

						if (pOpt.reverb && pOpt.reverb!=='none')
							this.audioBuffersByName["audio/impulse_response/"+pOpt.reverb] = loader.addAudio(batchName, "audio/impulse_response/"+pOpt.reverb, true);

						if (pOpt.impact.file && pOpt.impact.enabled && pOpt.impact.file!=='none') {
							if (/\.mp3|\.wav/.test(pOpt.impact.file)) {
								this.audioBuffersByName["audio/impact/"+pOpt.impact.file] = {value:[loader.addAudio(batchName, "audio/impact/"+pOpt.impact.file, true)]};
							} else {
								this.audioBuffersByName["audio/impact/"+pOpt.impact.file] = loader.addMultiAudio(batchName, "audio/impact/"+pOpt.impact.file, true);
							}
						}
					}
				}
			}
		// }

	}


	preloadEditor(targetOpt) {
		var pOpt = targetOpt||this.options;
		
		var shouldStart = false,
			batchName = "preset_batch"+Utils.generateUUID();

		if (pOpt.backgroundTrack && pOpt.backgroundTrack!=='none' && !this.audioBuffersByName["audio/music/"+pOpt.backgroundTrack]) {
			shouldStart = true;
			this.audioBuffersByName["audio/music/"+pOpt.backgroundTrack] = Loader.addAudio(batchName, "audio/music/"+pOpt.backgroundTrack, true);
		}

		if (pOpt.effectTrack && pOpt.effectTrack!=='none' && !this.audioBuffersByName["audio/music/"+pOpt.effectTrack]){
			shouldStart = true
			this.audioBuffersByName["audio/music/"+pOpt.effectTrack] = Loader.addAudio(batchName, "audio/music/"+pOpt.effectTrack, true);
		}

		if (pOpt.reverb && pOpt.reverb!=='none' && !this.audioBuffersByName["audio/impulse_response/"+pOpt.reverb]) {
			shouldStart = true;
			this.audioBuffersByName["audio/impulse_response/"+pOpt.reverb] = Loader.addAudio(batchName, "audio/impulse_response/"+pOpt.reverb, true);
		}

		if (pOpt.impact.file && pOpt.impact.enabled && pOpt.impact.file!=='none' && !this.audioBuffersByName["audio/impact/"+pOpt.impact.file]) {
			shouldStart = true;
			if (/\.mp3|\.wav/.test(pOpt.impact.file)) {
				this.audioBuffersByName["audio/impact/"+pOpt.impact.file] = {value:[Loader.addAudio(batchName, "audio/impact/"+pOpt.impact.file, true)]};
			} else {
				this.audioBuffersByName["audio/impact/"+pOpt.impact.file] = Loader.addMultiAudio(batchName, "audio/impact/"+pOpt.impact.file, true);
			}
		}

		if (shouldStart) Loader.start();
	}

	
	//----------------
	//
	// Setup the audio graph
	//
	//----------------
	setup() {
		if (this.audioStarted) return;
		this.audioStarted = true;
		this.setupGraph();
	}

	setupGraph() {
		if (this.graphStarted) return;
		this.graphStarted = true;
		console.log("Setup Audio Graph");

		this.postGain = audioContext.createGain();
		this.postGain.gain.tweenToValue(0.0, 0.0);
		this.postGain.connect(audioContext.destination);

		this.finalGain = audioContext.createGain();
		// this.finalGain.connect(this.postGain);
		this.compressorConnected = true;


		this.compressor = audioContext.createDynamicsCompressor();
		this.compressor.knee.value = 20;
		this.compressor.connect(this.postGain);
		



	
		//background + base mix
		this.backgroundStarted = false;
		this.backgroundGain = audioContext.createGain();
		this.backgroundGain.gain.tweenToValue(0.0, 0.0);
		this.backgroundGain.connect(this.finalGain);

		this.backgroundMixGain = audioContext.createGain();
		this.backgroundMixGain.gain.tweenToValue(0.0, 0.0);

		//gain + base mix
		this.effectStarted = false;
		this.effectGain = audioContext.createGain();
		this.effectGain.gain.tweenToValue(0.0, 0.0);
		this.effectGain.connect(this.finalGain);


		//reverb + base mix
		this.reverbStarted = false;
		this.reverbInGain = audioContext.createGain();
		this.reverbInGain.gain.tweenToValue(1.0, 0.0);

		// this.reverbNode = audioContext.createConvolver();
		// this.reverbNode.buffer = this.reverbBuffers[i].value.buffer;
		// this.reverbNode.connect(this.finalGain);

		// this.reverbFadeGain = audioContext.createGain();
		// this.reverbFadeGain.gain.tweenToValue(0.0,0.0);
		// this.reverbFadeGain.


		this.reverbGain = audioContext.createGain();
		this.reverbGain.gain.tweenToValue(0.0, 0.0);
		this.reverbGain.connect(this.reverbInGain);


		//feedback filter
		this.feedbackBaseNode = audioContext.createGain();
		this.feedbackBaseNode.gain.value = 1.0;

		this.feedbackDelayNode = audioContext.createDelay();
		this.feedbackDelayNode.delayTime.tweenToValue(0.05, 0.0);


		this.feedbackResonanceNode = audioContext.createGain();
		this.feedbackResonanceNode.gain.tweenToValue(1.0,0.0);

		this.feedbackNoPassNode = audioContext.createGain();
		this.feedbackNoPassNode.gain.tweenToValue(1.0,0.0);

		this.feedbackLowpassNode = audioContext.createBiquadFilter();
		this.feedbackLowpassNode.type = 'lowpass'
		this.feedbackLowpassNode.frequency.tweenToValue(320,0.0);
		this.feedbackLowpassNode.Q.tweenToValue(1.0,0.0);
		this.feedbackLowpassNode.gain.tweenToValue(0.0, 0.0);

		this.feedbackMixNode = audioContext.createGain();
		this.feedbackMixNode.gain.tweenToValue(0.0, 0.0);


		// if (!SETTINGS.FAST_DEBUG_MODE) {

			this.feedbackBaseNode.connect(this.feedbackDelayNode);
			this.feedbackDelayNode.connect(this.feedbackNoPassNode);
			this.feedbackDelayNode.connect(this.feedbackLowpassNode);

			this.feedbackNoPassNode.connect(this.feedbackResonanceNode);
			this.feedbackLowpassNode.connect(this.feedbackResonanceNode);

			this.feedbackResonanceNode.connect(this.feedbackMixNode);
			this.feedbackResonanceNode.connect(this.feedbackBaseNode);
		// }





		// this.feedbackMixNode.connect(this.finalGain);
		// this.feedbackMixNode.connect(this.feedbackBaseNode);


		//low/mid/high + mix
		this.eqGain = audioContext.createGain();
		this.eqGain.gain.tweenToValue(0.0, 0.0);

		window.EQFilterInfo = {
			low: {
				frequency: 320,
				Q: 1.0
			},
			mid: {
				frequency: 1000,
				Q: 0.5
			},
			high: {
				frequency: 3200,
				Q: 1.0
			}
		};
		this.lowNode = audioContext.createBiquadFilter();
		this.lowNode.type = 'lowshelf'
		this.lowNode.frequency.tweenToValue(window.EQFilterInfo.low.frequency,0.0);
		this.lowNode.Q.tweenToValue(window.EQFilterInfo.low.Q,0.0);
		this.lowNode.gain.tweenToValue(this.options.eq.lowMin, RAMP_TIME);

		this.midNode = audioContext.createBiquadFilter();
		this.midNode.type = 'peaking'
		this.midNode.frequency.tweenToValue(window.EQFilterInfo.mid.frequency,0.0);
		this.midNode.Q.tweenToValue(window.EQFilterInfo.mid.Q, 0.0);
		this.midNode.gain.tweenToValue(this.options.eq.midMin, RAMP_TIME);
		
		this.highNode = audioContext.createBiquadFilter();
		this.highNode.type = 'highshelf'
		this.highNode.frequency.tweenToValue(window.EQFilterInfo.high.frequency,0.0);
		this.highNode.Q.tweenToValue(window.EQFilterInfo.high.Q,0.0);
		this.highNode.gain.tweenToValue(this.options.eq.highMin, RAMP_TIME);
		

		// if (!SETTINGS.FAST_DEBUG_MODE) {
			this.lowNode.connect(this.finalGain);
			this.midNode.connect(this.lowNode);
			this.highNode.connect(this.midNode);
			this.eqGain.connect(this.highNode);
		// }

		//low/mid/high reverb
		this.lowReverbGain = audioContext.createGain();
		this.lowReverbGain.gain.tweenToValue(0.0, 0.0);
		this.lowReverbGain.connect(this.reverbInGain);
		this.lowNode.connect(this.lowReverbGain);

		this.midReverbGain = audioContext.createGain();
		this.midReverbGain.gain.tweenToValue(0.0, 0.0);
		this.midReverbGain.connect(this.reverbInGain);
		this.midNode.connect(this.midReverbGain);

		this.highReverbGain = audioContext.createGain();
		this.highReverbGain.gain.tweenToValue(0.0, 0.0);
		this.highReverbGain.connect(this.reverbInGain);
		this.highNode.connect(this.highReverbGain);

		// this.analyser = audioContext.createAnalyser();
		// this.analyser.fftSize = 32;
		// this.finalGain.connect( this.analyser );


		this.effectMasterGain = audioContext.createGain();
		this.effectMasterGain.gain.tweenToValue(0.0, 0.0);
		// this.effectMasterGain.connect(this.finalGain);

		this.effectMasterGain.connect(this.effectGain);
		this.effectMasterGain.connect(this.reverbGain);
		this.effectMasterGain.connect(this.eqGain);
		this.effectMasterGain.connect(this.feedbackBaseNode);
		this.backgroundMixGain.connect(this.effectMasterGain);

		// this.voiceMixGain = audioContext.createGain();
		// this.voiceMixGain.gain.tweenToValue(0.0,0.0);
		// this.voiceMixGain.connect(this.effectMasterGain);
		// AudioController.finalGain.connect(this.voiceMixGain);


		this.bandpassX = audioContext.createBiquadFilter();
		this.bandpassX.type = 'bandpass'
		this.bandpassX.frequency.tweenToValue(1,0.0);
		this.bandpassX.Q.tweenToValue(5.0,0.0);
		this.bandpassX.connect(this.compressor);

		this.bandpassY = audioContext.createBiquadFilter();
		this.bandpassY.type = 'bandpass'
		this.bandpassY.frequency.tweenToValue(1,0.0);
		this.bandpassY.Q.tweenToValue(5.0,0.0);
		this.bandpassY.connect(this.compressor);


		this.bandpassGain = audioContext.createGain();
		this.bandpassGain.gain.tweenToValue(1,0.0);
		this.bandpassGain.connect(this.bandpassX);
		this.bandpassGain.connect(this.bandpassY);


		this.bandpassDryGain = audioContext.createGain();
		this.bandpassDryGain.gain.tweenToValue(1.0, 0.0);
		this.bandpassWetGain = audioContext.createGain();
		this.bandpassWetGain.gain.tweenToValue(0.0, 0.0);

		// if (!SETTINGS.FAST_DEBUG_MODE) {
			this.bandpassWetGain.connect(this.bandpassGain)
			this.bandpassDryGain.connect(this.compressor);

			this.finalGain.connect(this.bandpassDryGain);
			this.finalGain.connect(this.bandpassWetGain);
		// } else {
		// 	this.finalGain.connect(this.compressor);
		// }
		


		// this.effectMasterGain.connect(this.bandpassGain);
		this.bandpassConnected = true;




		// experimental delay/flangers/chorus
		//
		// create 3 bandpass -> delay -> feedback
		// add an oscillator to each param (frequency, delay)
		//
		// this.peaking1 = audioContext.createBiquadFilter();
		// this.peaking1.type = 'peaking'
		// this.peaking1.frequency.tweenToValue(200,0.0);
		// this.peaking1.Q.value = 5.0;


		// this.peaking2 = audioContext.createBiquadFilter();
		// this.peaking2.type = 'peaking'
		// this.peaking2.frequency.tweenToValue(200,0.0);
		// this.peaking2.Q.value = 5.0;

		// this.peaking3 = audioContext.createBiquadFilter();
		// this.peaking3.type = 'peaking'
		// this.peaking3.frequency.tweenToValue(200,0.0);
		// this.peaking3.Q.value = 5.0;

		// this.peakingDelay1 = audioContext.createDelay();
		// this.peakingDelay1.delayTime.tweenToValue(0.05, 0.0);


		// this.peakingDelay2 = audioContext.createDelay();
		// this.peakingDelay2.delayTime.tweenToValue(0.05, 0.0);

		// this.peakingDelay3 = audioContext.createDelay();
		// this.peakingDelay3.delayTime.tweenToValue(0.05, 0.0);


		// this.peakingFeedback1 = audioContext.createGain();
		// this.peakingFeedback1.gain.tweenToValue(0.5,0.0);
		// this.peakingFeedback1.connect(this.peaking1);
		// this.peakingDelay1.connect(this.peakingFeedback1);
		// this.peakingFeedback1.connect(this.finalGain);


		// this.peakingFeedback2 = audioContext.createGain();
		// this.peakingFeedback2.gain.tweenToValue(0.5,0.0);
		// this.peakingFeedback2.connect(this.peaking2);
		// this.peakingDelay2.connect(this.peakingFeedback2);
		// this.peakingFeedback2.connect(this.finalGain);


		// this.peakingFeedback3 = audioContext.createGain();
		// this.peakingFeedback3.gain.tweenToValue(0.5,0.0);
		// this.peakingFeedback3.connect(this.peaking3);
		// this.peakingDelay3.connect(this.peakingFeedback3);
		// this.peakingFeedback3.connect(this.finalGain);

		// this.peaking1.connect(this.peakingDelay1);
		// this.peaking2.connect(this.peakingDelay2);
		// this.peaking3.connect(this.peakingDelay3);

		// this.effectMasterGain.connect(this.peaking1);
		// this.effectMasterGain.connect(this.peaking2);
		// this.effectMasterGain.connect(this.peaking3);




		this.currentPlayTime = 0;
		this.firstPlay = true;
		this.currentWordId = 1;
		this.nextWordEnd = audioContext.currentTime;

		this.forceImpact = true;
		this.wasDragging = false;
		this.speedGoingDown = 0;
		this.lastSpeed = 0;

		//oscillator
		// if (this.options.oscillator.enabled) {
		// 	this.oscillator = audioContext.createOscillator();
		// 	this.oscillator.frequency.tweenToValue(1.0,0.0);
		// 	this.oscillatorGain = audioContext.createGain();
		// 	this.oscillatorGain.gain.tweenToValue(0.0, 0.0);
		// 	this.oscillator.connect(this.oscillatorGain);
		// 	this.currentOscillatorTarget = null;
		// }
	}

	disposeGraph() {

		if (!this.graphStarted) return;
		this.graphStarted = false;
		console.log("Dispose Audio Graph");

		var transitionDurationOut = 0.1;

		var opt = this.options;

		//fade out last
			if (this.effectFadeGain) {
				this.effectFadeGain.gain.tweenToValue(0.0, transitionDurationOut);
				AudioDisposeController.dispose(this.effectSnd, transitionDurationOut);
				AudioDisposeController.dispose(this.effectFadeGain, transitionDurationOut);
				this.effectSnd = null;
				this.effectFadeGain = null;
			}
			this.currentEffectTrack = "none";


			if (this.reverbStarted) {
				this.reverbStarted = false;
				this.reverbFadeGain.gain.tweenToValue(0.0, transitionDurationOut);
				AudioDisposeController.dispose(this.reverbNode, transitionDurationOut);
				AudioDisposeController.dispose(this.reverbFadeGain, transitionDurationOut);
				this.reverbNode = null;
				this.reverbFadeGain = null;
			}
			this.currentReverb = "none";


			//fade out last
			if (this.backgroundFadeGain) {
				this.backgroundFadeGain.gain.tweenToValue(0.0, transitionDurationOut);
				AudioDisposeController.dispose(this.backgroundSnd, transitionDurationOut);
				AudioDisposeController.dispose(this.backgroundFadeGain, transitionDurationOut);
				this.backgroundSnd = null;
				this.backgroundFadeGain = null;
			}
			this.currentBackgroundTrack = "none";


			if (this.feedbackConnected) {

			this.feedbackOutNode.gain.tweenToValue(0.0, transitionDurationOut);
			AudioDisposeController.dispose(this.feedbackOutNode, transitionDurationOut);
			this.feedbackOutNode = null;
			this.feedbackConnected = false;
		}


		if (this.impact) {
			var offset = 0;
			this.impact.impactMasterGain.gain.tweenToValue(0.0, offset+0.1);
			AudioDisposeController.dispose(this.impact.snd, offset+0.1);
			AudioDisposeController.dispose(this.impact.impactGain, offset+0.1);
			AudioDisposeController.dispose(this.impact.impactEffectGain, offset+0.1);
			AudioDisposeController.dispose(this.impact.impactReverbGain, offset+0.1);
			AudioDisposeController.dispose(this.impact.impactMasterGain, offset+0.1);
			this.impact = null;
		}

		if (this.oscillator) {
			this.oscillator.disconnect();
			this.oscillatorGain.disconnect();
			this.oscillator = null;
			this.oscillatorGain = null;
			this.currentOscillatorTarget = null;
		}
		

		this.postGain.gain.tweenToValue(0.0, 0.1);
		AudioDisposeController.dispose(this.postGain, 0.1);
		AudioDisposeController.dispose(this.finalGain, 0.1);
		AudioDisposeController.dispose(this.compressor, 0.1);
		AudioDisposeController.dispose(this.backgroundGain, 0.1);
		AudioDisposeController.dispose(this.backgroundMixGain, 0.1);
		AudioDisposeController.dispose(this.effectGain, 0.1);
		AudioDisposeController.dispose(this.reverbInGain, 0.1);
		AudioDisposeController.dispose(this.reverbGain, 0.1);
		AudioDisposeController.dispose(this.feedbackBaseNode, 0.1); 
		AudioDisposeController.dispose(this.feedbackDelayNode, 0.1); 
		AudioDisposeController.dispose(this.feedbackResonanceNode, 0.1);
		AudioDisposeController.dispose(this.feedbackNoPassNode, 0.1); 
		AudioDisposeController.dispose(this.feedbackLowpassNode, 0.1);
		AudioDisposeController.dispose(this.feedbackMixNode, 0.1);
		AudioDisposeController.dispose(this.eqGain, 0.1);
		AudioDisposeController.dispose(this.lowNode, 0.1);
		AudioDisposeController.dispose(this.midNode, 0.1); 
		AudioDisposeController.dispose(this.highNode, 0.1);
		AudioDisposeController.dispose(this.lowReverbGain, 0.1);
		AudioDisposeController.dispose(this.midReverbGain, 0.1);
		AudioDisposeController.dispose(this.highReverbGain, 0.1);
		AudioDisposeController.dispose(this.bandpassX, 0.1); 
		AudioDisposeController.dispose(this.bandpassY, 0.1);
		AudioDisposeController.dispose(this.bandpassGain, 0.1);
		AudioDisposeController.dispose(this.bandpassDryGain, 0.1);
		AudioDisposeController.dispose(this.bandpassWetGain, 0.1);


		this.postGain = this.finalGain = this.compressor = this.backgroundGain = this.backgroundMixGain = this.effectGain = this.reverbInGain = this.reverbGain = this.feedbackBaseNode = this.feedbackDelayNode = this.feedbackResonanceNode = this.feedbackNoPassNode = this.feedbackLowpassNode = this.feedbackMixNode = this.eqGain = this.lowNode = this.midNode = this.highNode = this.lowReverbGain = this.midReverbGain = this.highReverbGain = this.bandpassX = this.bandpassY = this.bandpassGain = this.bandpassDryGain = null;
	}


	cleanupFinal() {

		if (!this.graphStarted) return;
		this.graphStarted = false;
		console.log("Dispose Audio Graph");

		var transitionDurationOut = 0.1;

		//fade out last
			if (this.effectFadeGain) {
				AudioDisposeController.disposeNow(this.effectSnd, transitionDurationOut);
				AudioDisposeController.disposeNow(this.effectFadeGain, transitionDurationOut);
				this.effectSnd = null;
				this.effectFadeGain = null;
			}
			this.currentEffectTrack = "none";


			if (this.reverbStarted) {
				this.reverbStarted = false;
				this.reverbFadeGain.gain.tweenToValue(0.0, transitionDurationOut);
				AudioDisposeController.disposeNow(this.reverbNode, transitionDurationOut);
				AudioDisposeController.disposeNow(this.reverbFadeGain, transitionDurationOut);
				this.reverbNode = null;
				this.reverbFadeGain = null;
			}
			this.currentReverb = "none";


			//fade out last
			if (this.backgroundFadeGain) {
				this.backgroundFadeGain.gain.tweenToValue(0.0, transitionDurationOut);
				AudioDisposeController.disposeNow(this.backgroundSnd, transitionDurationOut);
				AudioDisposeController.disposeNow(this.backgroundFadeGain, transitionDurationOut);
				this.backgroundSnd = null;
				this.backgroundFadeGain = null;
			}
			this.currentBackgroundTrack = "none";


			if (this.feedbackConnected) {

			this.feedbackOutNode.gain.tweenToValue(0.0, transitionDurationOut);
			AudioDisposeController.disposeNow(this.feedbackOutNode, transitionDurationOut);
			this.feedbackOutNode = null;
			this.feedbackConnected = false;
		}


		if (this.impact) {
			var offset = 0;
			this.impact.impactMasterGain.gain.tweenToValue(0.0, offset+transitionDurationOut);
			AudioDisposeController.disposeNow(this.impact.snd, offset+transitionDurationOut);
			AudioDisposeController.disposeNow(this.impact.impactGain, offset+transitionDurationOut);
			AudioDisposeController.disposeNow(this.impact.impactEffectGain, offset+transitionDurationOut);
			AudioDisposeController.disposeNow(this.impact.impactReverbGain, offset+transitionDurationOut);
			AudioDisposeController.disposeNow(this.impact.impactMasterGain, offset+transitionDurationOut);
			this.impact = null;
		}

		if (this.oscillator) {
			this.oscillator.disconnect();
			this.oscillatorGain.disconnect();
			this.oscillator = null;
			this.oscillatorGain = null;
			this.currentOscillatorTarget = null;
		}
		

		this.postGain.gain.tweenToValue(0.0, 0.1);
		AudioDisposeController.disposeNow(this.postGain, 0.1);
		AudioDisposeController.disposeNow(this.finalGain, 0.1);
		AudioDisposeController.disposeNow(this.compressor, 0.1);
		AudioDisposeController.disposeNow(this.backgroundGain, 0.1);
		AudioDisposeController.disposeNow(this.backgroundMixGain, 0.1);
		AudioDisposeController.disposeNow(this.effectGain, 0.1);
		AudioDisposeController.disposeNow(this.reverbInGain, 0.1);
		AudioDisposeController.disposeNow(this.reverbGain, 0.1);
		AudioDisposeController.disposeNow(this.feedbackBaseNode, 0.1); 
		AudioDisposeController.disposeNow(this.feedbackDelayNode, 0.1); 
		AudioDisposeController.disposeNow(this.feedbackResonanceNode, 0.1);
		AudioDisposeController.disposeNow(this.feedbackNoPassNode, 0.1); 
		AudioDisposeController.disposeNow(this.feedbackLowpassNode, 0.1);
		AudioDisposeController.disposeNow(this.feedbackMixNode, 0.1);
		AudioDisposeController.disposeNow(this.eqGain, 0.1);
		AudioDisposeController.disposeNow(this.lowNode, 0.1);
		AudioDisposeController.disposeNow(this.midNode, 0.1); 
		AudioDisposeController.disposeNow(this.highNode, 0.1);
		AudioDisposeController.disposeNow(this.lowReverbGain, 0.1);
		AudioDisposeController.disposeNow(this.midReverbGain, 0.1);
		AudioDisposeController.disposeNow(this.highReverbGain, 0.1);
		AudioDisposeController.disposeNow(this.bandpassX, 0.1); 
		AudioDisposeController.disposeNow(this.bandpassY, 0.1);
		AudioDisposeController.disposeNow(this.bandpassGain, 0.1);
		AudioDisposeController.disposeNow(this.bandpassDryGain, 0.1);
		AudioDisposeController.disposeNow(this.bandpassWetGain, 0.1);


		this.postGain = this.finalGain = this.compressor = this.backgroundGain = this.backgroundMixGain = this.effectGain = this.reverbInGain = this.reverbGain = this.feedbackBaseNode = this.feedbackDelayNode = this.feedbackResonanceNode = this.feedbackNoPassNode = this.feedbackLowpassNode = this.feedbackMixNode = this.eqGain = this.lowNode = this.midNode = this.highNode = this.lowReverbGain = this.midReverbGain = this.highReverbGain = this.bandpassX = this.bandpassY = this.bandpassGain = this.bandpassDryGain = null;
	}


	//----------------
	//
	// Update audio every frame, loop and transition between buffers
	//
	//----------------
	update(delta) {

		var now = performance.now(),
			opt = this.options;

		//--------------------
		//
		// Get current preset && Check for transition
		//
		//--------------------
		var currentTime = AudioController.getCurrentTime();
		
		//get preset options
		var currentPresetInfo = this.options;

		var transitionDuration = 0.5,
			transitionDurationOut = 0.6;

		this.transitionStartedThisFrame = false;

		var thisIsNone = true;
		var nextIsNone = true;

		//transition
		if (SETTINGS.TIMELINE_MODE && this.timecodes) {
			currentPresetInfo = null;
			for (var i=0; i<this.timecodes.length; i++) {
				if ((currentTime >= this.timecodes[i].timeStart && currentTime < this.timecodes[i].timeEnd) || (i==0 && SETTINGS.TIMELINE_MODE && SETTINGS.INTRO_MODE && AppStatus.isOnIntro)) {
					//get info for this preset		
					var presetInfo = this.presets[this.timecodes[i].info.preset];

					//specific presets modes outside of main timeline
					if (SETTINGS.TIMELINE_MODE && SETTINGS.INTRO_MODE && AppStatus.isOnIntro && this.introAudio==1) presetInfo = this.presets[AppStatus.timelineInfo.intro.audio];
					if (SETTINGS.TIMELINE_MODE && SETTINGS.INTRO_MODE && AppStatus.isOnIntro && this.introAudio==2) presetInfo = this.presets[AppStatus.timelineInfo.intro.audio2];
					if (BranchController.currentSequenceIsEarlyEndAudio() && this.presets[this.timecodes[i].info.early_end]) {
						if (this.transitionStarted) this.transitionStarted = false;
						presetInfo = this.presets[this.timecodes[i].info.early_end];
						// AppStatus.log("is early end audio",BranchController.currentBranchState);
					}

					//
					if (!presetInfo || (presetInfo && !presetInfo.value)) {
						thisIsNone = true;
						presetInfo = this.emptyPreset;
						this.currentId = this.timecodes[i].info.id;
						this.currentPresetName = "none";
						this.currentProgress = Utils.cmap(currentTime, this.timecodes[i].timeStart, this.timecodes[i].timeEnd, 0.0, 1.0);
						this.currentTimeStart = this.timecodes[i].timeStart;
						this.currentTimeEnd = this.timecodes[i].timeEnd;
					} else {
						thisIsNone = false;
					}


					//transition early_end presets
					if (SETTINGS.TIMELINE_MODE && BranchController.nextSequenceIsEarlyEndAudio()) {
						if (!presetInfo || (presetInfo && !presetInfo.value)) {
							presetInfo =  this.emptyPreset;
							nextIsNone = true;
						} else {
							nextIsNone = false;
						}
						if (!this.transitionStarted) this.transitionStartedThisFrame = true;
						this.transitionStarted = true;
						this.transitionPc = BranchController.earlyEndAudioTransitionPc();
						this.transitionOptions =  this.presets[this.timecodes[i].info.early_end];
						transitionDuration = 5.0;
						transitionDurationOut = 5.0 + 0.5;
						AppStatus.log("is early end transition", this.transitionPc,this.transitionOptions);
					}


					if (presetInfo && presetInfo.value) {
						currentPresetInfo = presetInfo.value.options;

						var tDur = Math.min( (this.timecodes[i].timeEnd-this.timecodes[i].timeStart ) * 0.33, 5.0);
						if (this.timecodes[i].info && this.timecodes[i].info.transition!==undefined) tDur = this.timecodes[i].info.transition;
						tDur = Math.max(tDur, 0.05);

						//for timeline display
						this.currentId = this.timecodes[i].info.id;
						this.currentPresetName = this.timecodes[i].info.preset;
						this.currentProgress = Utils.cmap(currentTime, this.timecodes[i].timeStart, this.timecodes[i].timeEnd, 0.0, 1.0);
						this.currentTimeStart = this.timecodes[i].timeStart;
						this.currentTimeEnd = this.timecodes[i].timeEnd;

						//transition audio intro
						if (SETTINGS.TIMELINE_MODE && SETTINGS.INTRO_MODE && AppStatus.isOnIntro && this.introAudio==1 && this.introAudioTarget==2) {
							 if (!this.transitionStarted) this.transitionStartedThisFrame = true;
							this.transitionStarted = true;
							this.transitionPc = Utils.cmap(performance.now(), this.introAudioTransitionStart, this.introAudioTransitionEnd, 0.0, 1.0);
							if (this.transitionPc>=1.0) {
								this.introAudio = 2;
							}
							this.transitionOptions =  this.presets[AppStatus.timelineInfo.intro.audio2].value.options;
							transitionDuration = AppStatus.timelineInfo.intro.audio2_transition;
							transitionDurationOut = AppStatus.timelineInfo.intro.audio2_transition + 0.5;
						}

						//transition presets timeline
						else if (currentTime >= this.timecodes[i].timeEnd-tDur && i+1 < this.timecodes.length) {
							var nextPreset = this.presets[this.timecodes[i+1].info.preset];
							if (!nextPreset) {
								nextPreset = this.emptyPreset;
								nextIsNone = true;
							} else {
								nextIsNone = false;
							}
							if (nextPreset && nextPreset.value && (!nextPreset.value.isEmpty || !presetInfo.value.isEmpty)) {
									// if (!this.transitionStarted)
								if (!this.transitionStarted) this.transitionStartedThisFrame = true;
								this.transitionStarted = true;
								this.transitionPc = Utils.cmap(currentTime, this.timecodes[i].timeEnd-tDur, this.timecodes[i].timeEnd, 0.0, 1.0);
								this.transitionOptions =  nextPreset.value.options;
								transitionDuration = tDur;
								transitionDurationOut = tDur + 0.5;
								if (this.timecodes[i].info.slowFadeOut) {
									transitionDurationOut *= 2.0;
								}
							} else {
								this.transitionStarted = false;
							}
							
						} else {
							this.transitionStarted = false;
						}
					}
				}
			}
		}

		// if (SETTINGS.FAST_DEBUG_MODE) this.transitionStarted = false;

		if (isNaN(transitionDuration)) transitionDuration = 0.5;
		// if (!transitionDuration) transitionDuration = 0.05;
		if (isNaN(transitionDurationOut)) transitionDurationOut = 0.6;
		// if (!transitionDurationOut) transitionDurationOut = 0.05;

		if (this.paused) transitionDuration = 0.15;




		// AppStatus.log(this.transitionStarted,this.transitionPc);

		//--------------------
		//
		// update options to current preset
		//
		//--------------------
		if (this.audioStarted) {

			//--------------------
			//
			// update options to current preset
			//
			//--------------------
			if (!this.transitionStarted) {
				
				opt = currentPresetInfo;

			} else {


				var sourceOpt = currentPresetInfo,
					targetOpt = this.transitionOptions;

				opt = this.options;

				this.options.backgroundTrack = targetOpt.backgroundTrack;
				this.options.effectTrack = targetOpt.effectTrack;
				this.options.reverb = targetOpt.reverb;
				this.options.jumpClick = sourceOpt.jumpClick;
				// this.options.voiceMixIn = Utils.lerp(sourceOpt.voiceMixIn, targetOpt.voiceMixIn, this.transitionPc);

				//
				// Transition impact
				//
				//if both impact are enabled -> use source until 100%, no transition
				if ((sourceOpt.impact.enabled && !targetOpt.impact.enabled) || (sourceOpt.impact.enabled && targetOpt.impact.enabled && sourceOpt.impact.file !== targetOpt.impact.file)) {
					this.options.impact.enabled = true;
					this.options.impact.file = sourceOpt.impact.file;
					this.options.impact.volume = sourceOpt.impact.volume * (targetOpt.impact.enabled ? 1.0 : (1.0-this.transitionPc));
					this.options.impact.loop = sourceOpt.impact.loop;

					this.options.impact.reverb = sourceOpt.impact.reverb; //Utils.lerp(sourceOpt.impact.reverb, targetOpt.impact.reverb, this.transitionPc);
					this.options.impact.effectMix = sourceOpt.impact.effectMix; //Utils.lerp(sourceOpt.impact.effectMix, targetOpt.impact.effectMix, this.transitionPc);
					this.options.impact.attack = sourceOpt.impact.attack; //Utils.lerp(sourceOpt.impact.attack, targetOpt.impact.attack, this.transitionPc);
					this.options.impact.release = sourceOpt.impact.release; //Utils.lerp(sourceOpt.impact.release, targetOpt.impact.release, this.transitionPc);
					this.options.impact.randomOffset = sourceOpt.impact.randomOffset;

				} else if (!sourceOpt.impact.enabled && targetOpt.impact.enabled) {

					this.options.impact.enabled = true;
					this.options.impact.file = targetOpt.impact.file;
					this.options.impact.volume = targetOpt.impact.volume * this.transitionPc;
					this.options.impact.loop = targetOpt.impact.loop;

					this.options.impact.reverb = targetOpt.impact.reverb; //Utils.lerp(sourceOpt.impact.reverb, targetOpt.impact.reverb, this.transitionPc);
					this.options.impact.effectMix = targetOpt.impact.effectMix; //Utils.lerp(sourceOpt.impact.effectMix, targetOpt.impact.effectMix, this.transitionPc);
					this.options.impact.attack = targetOpt.impact.attack; //Utils.lerp(sourceOpt.impact.attack, targetOpt.impact.attack, this.transitionPc);
					this.options.impact.release = targetOpt.impact.release; //Utils.lerp(sourceOpt.impact.release, targetOpt.impact.release, this.transitionPc);
					this.options.impact.randomOffset = targetOpt.impact.randomOffset; //Utils.lerp(sourceOpt.impact.release, targetOpt.impact.release, this.transitionPc);

				//if same file && both enabled, tween parameters
				} else if (sourceOpt.impact.enabled && targetOpt.impact.enabled && sourceOpt.impact.file == targetOpt.impact.file) {

					this.options.impact.enabled = true;
					this.options.impact.volume = Utils.lerp(sourceOpt.impact.volume, targetOpt.impact.volume, this.transitionPc);
					this.options.impact.loop = sourceOpt.impact.loop;

					this.options.impact.reverb = Utils.lerp(sourceOpt.impact.reverb, targetOpt.impact.reverb, this.transitionPc);
					this.options.impact.effectMix = Utils.lerp(sourceOpt.impact.effectMix, targetOpt.impact.effectMix, this.transitionPc);
					this.options.impact.attack = Utils.lerp(sourceOpt.impact.attack, targetOpt.impact.attack, this.transitionPc);
					this.options.impact.release = Utils.lerp(sourceOpt.impact.release, targetOpt.impact.release, this.transitionPc);
					this.options.impact.randomOffset = Utils.lerp(sourceOpt.impact.randomOffset, targetOpt.impact.randomOffset, this.transitionPc);

				} else if (!sourceOpt.impact.enabled && !targetOpt.impact.enabled) {
				
					this.options.impact.enabled = false;
					this.options.impact.file = "none";

				}
				

				//tween feedback
				var feedbackTransitionPc = this.transitionPc;
				this.options.feedback.enabled = sourceOpt.feedback.enabled || targetOpt.feedback.enabled;

				if (sourceOpt.feedback.enabled && !targetOpt.feedback.enabled) {
					feedbackTransitionPc = 0.0;
					this.options.feedback.mix = sourceOpt.feedback.mix * (1.0-this.transitionPc);
				} else if (!sourceOpt.impact.enabled && targetOpt.impact.enabled) {
					feedbackTransitionPc = 1.0;
					this.options.feedback.mix = targetOpt.feedback.mix * (this.transitionPc);
				}

				if (SETTINGS.isFirefox) {
					opt.feedback.enabled = this.transitionPc<1.0 ? sourceOpt.feedback.enabled : targetOpt.feedback.enabled;
					feedbackTransitionPc = this.transitionPc<1.0 ? 0.0 : 1.0;
				}


				this.options.feedback.resonanceMin = Utils.lerp(sourceOpt.feedback.resonanceMin, targetOpt.feedback.resonanceMin, feedbackTransitionPc);
				this.options.feedback.resonanceMax = Utils.lerp(sourceOpt.feedback.resonanceMax, targetOpt.feedback.resonanceMax, feedbackTransitionPc);
				this.options.feedback.delay = Utils.lerp(sourceOpt.feedback.delay, targetOpt.feedback.delay, feedbackTransitionPc);

				this.options.feedback.lowpass = Utils.lerp(sourceOpt.feedback.lowpass, targetOpt.feedback.lowpass, feedbackTransitionPc);
				this.options.feedback.lowpassFreq = Utils.lerp(sourceOpt.feedback.lowpassFreq, targetOpt.feedback.lowpassFreq, feedbackTransitionPc);


				//tween bandpass
				var bandpassTransitionPc = this.transitionPc;
				// // this.options.bandpass.enabled = sourceOpt.bandpass.mix>0 || targetOpt.bandpass.mix>0;
				// if (sourceOpt.bandpass.mix>0 && targetOpt.bandpass.mix<=0) {
				// 	bandpassTransitionPc = 0.0;
				// 	this.options.bandpass.minVolume = sourceOpt.bandpass.minVolume  * (1.0-this.transitionPc);
				// 	this.options.bandpass.maxVolume = sourceOpt.bandpass.maxVolume  * (1.0-this.transitionPc);

				// } else if (sourceOpt.bandpass.mix<=0 && targetOpt.bandpass.mix>=0) {
				// 	bandpassTransitionPc = 1.0;
				// 	this.options.bandpass.minVolume = targetOpt.bandpass.minVolume * (this.transitionPc);
				// 	this.options.bandpass.maxVolume = targetOpt.bandpass.maxVolume * (this.transitionPc);
				// }

				this.options.bandpass.minMix = Utils.lerp(sourceOpt.bandpass.minMix, targetOpt.bandpass.minMix, this.transitionPc);
				this.options.bandpass.maxMix = Utils.lerp(sourceOpt.bandpass.maxMix, targetOpt.bandpass.maxMix, this.transitionPc);
				this.options.bandpass.release = Utils.lerp(sourceOpt.bandpass.release, targetOpt.bandpass.release, bandpassTransitionPc);
				this.options.bandpass.xFrequencyMin = Utils.lerp(sourceOpt.bandpass.xFrequencyMin, targetOpt.bandpass.xFrequencyMin, bandpassTransitionPc);
				this.options.bandpass.xFrequencyMax = Utils.lerp(sourceOpt.bandpass.xFrequencyMax, targetOpt.bandpass.xFrequencyMax, bandpassTransitionPc);
				this.options.bandpass.xQ = Utils.lerp(sourceOpt.bandpass.xQ, targetOpt.bandpass.xQ, bandpassTransitionPc);
				this.options.bandpass.yFrequencyMin = Utils.lerp(sourceOpt.bandpass.yFrequencyMin, targetOpt.bandpass.yFrequencyMin, bandpassTransitionPc);
				this.options.bandpass.yFrequencyMax = Utils.lerp(sourceOpt.bandpass.yFrequencyMax, targetOpt.bandpass.yFrequencyMax, bandpassTransitionPc);
				this.options.bandpass.yQ = Utils.lerp(sourceOpt.bandpass.yQ, targetOpt.bandpass.yQ, bandpassTransitionPc);


				// feedback: {
				// 	enabled: false,
				// 	randomEverything: false
				// 	bandpass: {
				// 		enabled: false,
				// this.options.backgroundTrack = Utils.lerp(sourceOpt.backgroundTrack, targetOpt.backgroundTrack, this.transitionPc);
				// this.options.effectTrack = Utils.lerp(sourceOpt.effectTrack, targetOpt.effectTrack, this.transitionPc);
				// this.options.reverb = Utils.lerp(sourceOpt.reverb, targetOpt.reverb, this.transitionPc);
				// this.options.jumpStart = Utils.lerp(sourceOpt.jumpStart, targetOpt.jumpStart, this.transitionPc);
				// this.options.jumpClick = Utils.lerp(sourceOpt.jumpClick, targetOpt.jumpClick, this.transitionPc);
				
				// this.options.impact.enabled = Utils.lerp(sourceOpt.impact.enabled, targetOpt.impact.enabled, this.transitionPc);
				// this.options.feedback.enabled = Utils.lerp(sourceOpt.feedback.enabled, targetOpt.feedback.enabled, this.transitionPc);
				// this.options.bandpass.enabled = Utils.lerp(sourceOpt.bandpass.enabled, targetOpt.bandpass.enabled, this.transitionPc);
				// this.options.impact.file = Utils.lerp(sourceOpt.impact.file, targetOpt.impact.file, this.transitionPc);
				// this.options.impact.loop = Utils.lerp(sourceOpt.impact.loop, targetOpt.impact.loop, this.transitionPc);
				// this.options.eq.randomEverything = Utils.lerp(sourceOpt.eq.randomEverything, targetOpt.eq.randomEverything, this.transitionPc);

				//
				// Transition everything else
				//
				this.options.voiceVolume = Utils.lerp(sourceOpt.voiceVolume, targetOpt.voiceVolume, this.transitionPc);
				this.options.effectsVolume = Utils.lerp(sourceOpt.effectsVolume, targetOpt.effectsVolume, this.transitionPc);
				this.options.backgroundVolume = Utils.lerp(sourceOpt.backgroundVolume, targetOpt.backgroundVolume, this.transitionPc);
				this.options.backgroundMixIn = Utils.lerp(sourceOpt.backgroundMixIn, targetOpt.backgroundMixIn, this.transitionPc);
				this.options.compressor = Utils.lerp(sourceOpt.compressor, targetOpt.compressor, this.transitionPc);
				this.options.postGain = Utils.lerp(sourceOpt.postGain, targetOpt.postGain, this.transitionPc);
				
				this.options.effectGain.volumeMin = Utils.lerp(sourceOpt.effectGain.volumeMin, targetOpt.effectGain.volumeMin, this.transitionPc);
				this.options.effectGain.volumeMax = Utils.lerp(sourceOpt.effectGain.volumeMax, targetOpt.effectGain.volumeMax, this.transitionPc);
				this.options.effectGain.reverbMin = Utils.lerp(sourceOpt.effectGain.reverbMin, targetOpt.effectGain.reverbMin, this.transitionPc);
				this.options.effectGain.reverbMax = Utils.lerp(sourceOpt.effectGain.reverbMax, targetOpt.effectGain.reverbMax, this.transitionPc);
				this.options.eq.eqGain = Utils.lerp(sourceOpt.eq.eqGain, targetOpt.eq.eqGain, this.transitionPc);
				this.options.eq.lowMin = Utils.lerp(sourceOpt.eq.lowMin, targetOpt.eq.lowMin, this.transitionPc);
				this.options.eq.lowMax = Utils.lerp(sourceOpt.eq.lowMax, targetOpt.eq.lowMax, this.transitionPc);
				this.options.eq.lowReverbMax = Utils.lerp(sourceOpt.eq.lowReverbMax, targetOpt.eq.lowReverbMax, this.transitionPc);
				this.options.eq.midMin = Utils.lerp(sourceOpt.eq.midMin, targetOpt.eq.midMin, this.transitionPc);
				this.options.eq.midMax = Utils.lerp(sourceOpt.eq.midMax, targetOpt.eq.midMax, this.transitionPc);
				this.options.eq.midReverbMax = Utils.lerp(sourceOpt.eq.midReverbMax, targetOpt.eq.midReverbMax, this.transitionPc);
				this.options.eq.highMin = Utils.lerp(sourceOpt.eq.highMin, targetOpt.eq.highMin, this.transitionPc);
				this.options.eq.highMax = Utils.lerp(sourceOpt.eq.highMax, targetOpt.eq.highMax, this.transitionPc);
				this.options.eq.highReverbMax = Utils.lerp(sourceOpt.eq.highReverbMax, targetOpt.eq.highReverbMax, this.transitionPc);
				this.options.eq.range = Utils.lerp(sourceOpt.eq.range, targetOpt.eq.range, this.transitionPc);
		
				

				// sourceOpt.oscillator.enabled, targetOpt.oscillator.enabled

				// this.options.oscillator.frequencyMin = Utils.lerp(sourceOpt.oscillator.frequencyMin, targetOpt.oscillator.frequencyMin, this.transitionPc);
				// this.options.oscillator.frequencyMax = Utils.lerp(sourceOpt.oscillator.frequencyMax, targetOpt.oscillator.frequencyMax, this.transitionPc);
				var oscillatorOpt = 
					sourceOpt.oscillator.enabled>0.01 && targetOpt.oscillator.enabled < 0.01 ? sourceOpt:
					targetOpt.oscillator.enabled>0.01 && sourceOpt.oscillator.enabled < 0.01 ? targetOpt: 
					this.transitionPc > 0.5 ? targetOpt:
					sourceOpt;
				this.options.oscillator.frequencyMin = oscillatorOpt.oscillator.frequencyMin;
				this.options.oscillator.frequencyMax = oscillatorOpt.oscillator.frequencyMax;

				this.options.oscillator.depthMin = oscillatorOpt.oscillator.depthMin;
				this.options.oscillator.depthMax = oscillatorOpt.oscillator.depthMax;
				this.options.oscillator.target = oscillatorOpt.oscillator.target;
				this.options.oscillator.enabled = oscillatorOpt.oscillator.enabled;
				// : targetOpt.oscillator.enabled; //Utils.lerp(, targetOpt.oscillator.enabled, this.transitionPc);

				// Utils.lerp(sourceOpt.oscillator.target, targetOpt.oscillator.target, this.transitionPc);

				// this.options.oscillator.onDrag = Utils.lerp(sourceOpt.oscillator.onDrag, targetOpt.oscillator.onDrag, this.transitionPc);
				// this.options.oscillator.frequencyMin = Utils.lerp(sourceOpt.oscillator.frequencyMin, targetOpt.oscillator.frequencyMin, this.transitionPc);
				// this.options.oscillator.frequencyMax = Utils.lerp(sourceOpt.oscillator.frequencyMax, targetOpt.oscillator.frequencyMax, this.transitionPc);

				// this.options.oscillator.depthMin = Utils.lerp(sourceOpt.oscillator.depthMin, targetOpt.oscillator.depthMin, this.transitionPc);
				// this.options.oscillator.depthMax = Utils.lerp(sourceOpt.oscillator.depthMax, targetOpt.oscillator.depthMax, this.transitionPc);
				// this.options.oscillator.target = Utils.lerp(sourceOpt.oscillator.target, targetOpt.oscillator.target, this.transitionPc);
			}

			this.currentOptions = opt;




			if (SETTINGS.TIMELINE_MODE && opt) opt.jumpStart = false;

			//--------------------
			//
			// Check for ready state & buffering
			//
			//--------------------
			if (!this.isReady()) {
				// console.warn("AudioEffectController buffering");
				return;
			}

			// if (SETTINGS.isMobile) {
			if (SETTINGS.TIMELINE_MODE) {
				if (thisIsNone && nextIsNone) {
					if (this.graphStarted) this.disposeGraph();
				} else if (!this.graphStarted) this.setupGraph();
			}

			 if (!this.graphStarted) {
			 	return;
			 }


			//--------------------
			//
			// Trigger timecode based sfx
			//
			//--------------------
			if (SETTINGS.TIMELINE_MODE) {
				for (var i=0; i<this.sfxTimecodes.length; i++) {
					if (!this.sfxTimecodes[i].played && currentTime >= this.sfxTimecodes[i].timecode-0.1 && currentTime <= this.sfxTimecodes[i].timecode+1.0) {
						this.sfxTimecodes[i].played = true;

						this.triggerSFX(currentTime, this.sfxTimecodes[i]);


					} else if (currentTime < this.sfxTimecodes[i].timecode-1.0) {
						this.sfxTimecodes[i].played = false;
					}
				}
			}



			//--------------------
			//
			// Start/Crossfade playback of main buffers
			//
			//--------------------
			//effect
			if (this.currentEffectTrack !== opt.effectTrack || this.paused) {
				this.currentEffectTrack = opt.effectTrack;

				//fade out last
				if (this.effectFadeGain) {
					console.log("FADING EFFECT",transitionDurationOut)
					this.effectFadeGain.gain.tweenToValue(0.0, transitionDurationOut);
					AudioDisposeController.dispose(this.effectSnd, transitionDurationOut);
					AudioDisposeController.dispose(this.effectFadeGain, transitionDurationOut);
					this.effectSnd = null;
					this.effectFadeGain = null;
				}

				//fade in new
				// var buf = Loader.getAsset("audio/music/"+opt.effectTrack);
				var buf = this.audioBuffersByName["audio/music/"+opt.effectTrack]
				if (buf && buf.value && buf.loaded && !this.paused) {
					console.log("PLAYING EFFECT");
					this.currentEffectBuffer = buf.value.buffer;
					this.effectSnd = audioContext.createBufferSource();
					this.effectSnd.loop = true;
						
					var targetVol = opt.effectsTrackPreGain;
					if (this.transitionStarted && this.transitionOptions)  targetVol = this.transitionOptions.effectsTrackPreGain;
					this.effectFadeGain = audioContext.createGain();
					this.effectFadeGain.gain.tweenToValue(0.0, 0.0);
					this.effectFadeGain.gain.tweenToValue(targetVol, transitionDuration);
					this.effectSnd.connect(this.effectFadeGain);
					this.effectFadeGain.connect(this.effectMasterGain);

					this.effectSnd.buffer = this.currentEffectBuffer;
					this.effectSnd.start(audioContext.currentTime, opt.jumpStart?this.currentEffectBuffer.duration*0.25:0.0);
				} else {
					if (opt.effectTrack !== 'none' && !this.paused) console.log("no buffer:", opt.effectTrack,this.audioBuffersByName["audio/music/"+opt.effectTrack]);
				}
			}

			//
			// background
			//
			if (this.currentBackgroundTrack !== opt.backgroundTrack || this.paused) {

				//fade out last
				if (this.backgroundFadeGain) {
					console.log("FADING BACKGROUND",transitionDurationOut)
					this.backgroundFadeGain.gain.tweenToValue(0.0, transitionDurationOut);
					AudioDisposeController.dispose(this.backgroundSnd, transitionDurationOut);
					AudioDisposeController.dispose(this.backgroundFadeGain, transitionDurationOut);
					this.backgroundSnd = null;
					this.backgroundFadeGain = null;
				}

				//fade in new
				var buf = this.audioBuffersByName["audio/music/"+opt.backgroundTrack];
				if (buf && buf.value && buf.loaded && !this.paused) {
					console.log("PLAYING BACKGROUND");
					this.currentBackgroundTrack = opt.backgroundTrack;
					this.currentBackgroundBuffer = buf.value.buffer;
					this.backgroundSnd = audioContext.createBufferSource();
					this.backgroundSnd.loop = true;
					
					this.backgroundFadeGain = audioContext.createGain();
					this.backgroundFadeGain.gain.tweenToValue(0.0, 0.0);
					this.backgroundFadeGain.gain.tweenToValue(1.0, transitionDuration);
					this.backgroundSnd.connect(this.backgroundFadeGain);

					this.backgroundFadeGain.connect(this.backgroundGain);
					this.backgroundFadeGain.connect(this.backgroundMixGain);


					this.backgroundSnd.buffer = this.currentBackgroundBuffer;
					this.backgroundSnd.start(audioContext.currentTime, opt.jumpStart?this.currentBackgroundBuffer.duration*0.25:0.0);
				}
			}

			//
			// reload reverb
			//
			if (this.currentReverb != opt.reverb || !this.reverbStarted) {

				if (this.reverbStarted) {
					console.log("FADING REVERB",transitionDurationOut)
					this.reverbStarted = false;
					this.reverbFadeGain.gain.tweenToValue(0.0, transitionDurationOut);
					AudioDisposeController.dispose(this.reverbNode, transitionDurationOut);
					AudioDisposeController.dispose(this.reverbFadeGain, transitionDurationOut);
					this.reverbNode = null;
					this.reverbFadeGain = null;
				}

				//no fading for now
				var buf = this.audioBuffersByName["audio/impulse_response/"+opt.reverb];
				if (opt.reverb!=="none" && buf && buf.value && buf.value.buffer && buf.loaded && !this.paused) {
					this.reverbStarted = true;
					this.currentReverb = opt.reverb;

					this.reverbFadeGain = audioContext.createGain();
					this.reverbFadeGain.gain.tweenToValue(0.0,0.0);
					this.reverbFadeGain.gain.tweenToValue(1.0,transitionDuration);
					this.reverbFadeGain.connect(this.finalGain);

					this.reverbNode = audioContext.createConvolver();
					this.reverbNode.buffer = buf.value.buffer;
					this.reverbNode.connect(this.reverbFadeGain);

					this.reverbInGain.disconnect();
					this.reverbInGain.connect(this.reverbNode);
				}
			}

			this.impactBuffer = this.audioBuffersByName["audio/impact/"+opt.impact.file];

			//--------------------
			//
			// update interaction info
			//
			//--------------------
			if (SequenceRenderer.pressedThisFrame || (SequenceRenderer.isDragging && !this.wasDragging)) {
				this.startPositionRandom.set(Math.random() -0.5, Math.random()-0.5);
				this.directionRandom.set(
					(Math.random()>0.5 ? 1.0 : -1.0)* (Math.random()*0.75+1.0),
					(Math.random()>0.5 ? 1.0 : -1.0)* (Math.random()*0.75+1.0));
			}
			this.wasDragging = SequenceRenderer.isDragging;
			var dragX = (SequenceRenderer.currentPosition.x - SequenceRenderer.startPosition.x)*this.directionRandom.x + this.startPositionRandom.x;
			var dragY = (SequenceRenderer.currentPosition.y - SequenceRenderer.startPosition.y)*this.directionRandom.y + this.startPositionRandom.y;


			this.dragSpeed += Math.abs(SequenceRenderer.currentDragSpeed.x)*0.1*delta;
			this.dragSpeed += Math.abs(SequenceRenderer.currentDragSpeed.y)*0.1*delta;
			if (SequenceRenderer.isDragging) this.dragSpeed += 0.01*delta;
			if (this.pressedThisFrame) this.dragSpeed += 0.5;
			this.dragSpeed = Utils.deltaSmoothingSnap2(this.dragSpeed,SequenceRenderer.isDragging?0.33:0.0, RAMP_TIME, delta);
			this.dragSpeed = Utils.clamp(this.dragSpeed, 0.0, 1.0);
		


			//--------------------
			//
			// update gains & mix
			//
			//--------------------
			var norm = 1.0/opt.voiceVolume,
				voiceVolume = 1.0;

			if (!SETTINGS.TIMELINE_MODE || opt.voiceVolume<=0) {
				voiceVolume = opt.voiceVolume;
				norm = 1.0;
			}
			if (isNaN(norm) || norm>=10) norm = 1.0;
			if (norm<0) norm = 0.0;

			var pc = this.dragSpeed;
			// this.voiceGain.gain.tweenToValue(opt.voiceVolume, RAMP_TIME);
			if (AudioController.mainGain) AudioController.mainGain.gain.tweenToValue(voiceVolume, RAMP_TIME);
			AudioController.volume = voiceVolume;


			this.backgroundGain.gain.tweenToValue(opt.backgroundVolume, RAMP_TIME);

			this.backgroundMixGain.gain.tweenToValue(opt.backgroundMixIn, RAMP_TIME);

			this.effectMasterGain.gain.tweenToValue(opt.effectsVolume, RAMP_TIME);

			this.effectGain.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,opt.effectGain.volumeMin, opt.effectGain.volumeMax), RAMP_TIME);

			this.eqGain.gain.tweenToValue(opt.eq.eqGain*0.5, RAMP_TIME);


			norm *= AppStatus.globalVolume;

			this.postGain.gain.tweenToValue(opt.postGain * norm * ( (AudioController.pausedForMenu||AppStatus.lostFocus) ?0:1), 0.2);
			// if (!SETTINGS.FAST_DEBUG_MODE) this.compressor.threshold.tweenToValue(opt.compressor * -1, 0.1);

			// AppStatus.log(this.transitionStarted,opt, opt.backgroundVolume, opt.backgroundMixIn, opt.postGain * norm * (AudioController.pausedForMenu?0:1), opt.compressor);


			// this.voiceMixGain.gain.tweenToValue(opt.voiceMixIn,0.05);

			// if ( (opt.compressor>0) !== this.compressorConnected) {
			// 	this.compressorConnected = opt.compressor>0;
				
			// 	if (opt.compressor>0) {
			// 		this.compressor = audioContext.createDynamicsCompressor();
			// 		this.compressor.knee.value = 20;
			// 		this.compressor.connect(this.postGain);

			// 		this.finalGain.disconnect();
			// 		this.finalGain.connect(this.compressor);
			// 	} else {
			// 		this.compressor.disconnect();
			// 		this.compressor = null;
			// 		this.finalGain.disconnect();
			// 		this.finalGain.connect(this.postGain);
			// 	}
			// }
			if (this.compressorConnected) this.compressor.threshold.tweenToValue(Math.min(opt.compressor * -1,-0.005), 0.1);


			//--------------------
			//
			// update oscillator
			//
			//--------------------
			if (opt.oscillator.enabled) {
				opt.oscillator.target = "finalGain";
				var oscillatorPc = Utils.clamp(this.pressPc + this.dragPc*opt.oscillator.onDrag*2.0, 0.0, 1.0);

				if (!this.oscillator) {
					// if (this.oscillator) {
					// 	this.oscillator.disconnect();
					// 	this.oscillatorGain.disconnect();
					// }
				

					this.oscillator = audioContext.createOscillator();
					this.oscillator.frequency.tweenToValue(Utils.ccmap(oscillatorPc,0.0,1.0,opt.oscillator.frequencyMin, opt.oscillator.frequencyMax),0)
					// this.oscillator.type = 'square';
					this.oscillatorGain = audioContext.createGain();
					this.oscillatorGain.gain.tweenToValue(0.0, 0.0);
					this.oscillator.connect(this.oscillatorGain);
					this.oscillator.start(0);
					// this.currentOscillatorTarget = opt.oscillator.target;


					// this.currentOscillatorTarget = opt.oscillator.target
					// this.oscillatorGain.disconnect();
					// this.oscillatorGain.connect({
					// 	"finalGain":this.finalGain.gain,

					// 	"feedbackMix":this.feedbackMixNode.gain,
					// 	"feedbackDelay": this.feedbackDelayNode.delayTime,
					// 	"effectVolume":this.effectGain.gain,
					// 	"effectPitch": this.effectSnd?this.effectSnd.playbackRate:null,
					// 	"eqGain": this.eqGain,
					// 	"impact":null,
					// 	"eqLow": this.lowNode.gain,
					// 	"eqLowQ": this.lowNode.Q,
					// 	"eqLowFreq": this.lowNode.frequency,

					// 	"eqMid": this.midNode.gain,
					// 	"eqMidQ": this.midNode.Q,
					// 	"eqMidFreq": this.midNode.frequency,

					// 	"eqHigh": this.highNode.gain,
					// 	"eqHighQ": this.highNode.Q,
					// 	"eqHighFreq": this.highNode.frequency,

					// 	"bandpassMix":this.bandpassWetGain.gain,
					// 	"bandpassXFreq":this.bandpassX.frequency,
					// 	"bandpassXQ":this.bandpassX.Q,
					// 	"bandpassYFreq":this.bandpassY.frequency,
					// 	"bandpassYQ": this.bandpassY.Q

					// }[opt.oscillator.target]);
					this.oscillatorGain.connect(this.finalGain.gain);
					// console.log("connect:",opt.oscillator.target, Utils.ccmap(oscillatorPc,0.0,1.0,opt.oscillator.depthMin, opt.oscillator.depthMax));
				}

				// this.oscillator.frequency.value = Utils.ccmap(pc,0.0,1.0,opt.oscillator.frequencyMin, opt.oscillator.frequencyMin);
				this.oscillator.frequency.tweenToValue(Utils.ccmap(oscillatorPc,0.0,1.0,opt.oscillator.frequencyMin, opt.oscillator.frequencyMax),0.1);
				this.oscillatorGain.gain.tweenToValue(Utils.ccmap(oscillatorPc,0.0,1.0,opt.oscillator.depthMin, opt.oscillator.depthMax), RAMP_TIME);
			} else if (this.oscillator) {
				this.oscillator.disconnect();
				this.oscillatorGain.disconnect();
				this.oscillator = null;
				this.oscillatorGain = null;
				this.currentOscillatorTarget = null;
			}



			//--------------------
			//
			// Restart effectSnd (jumpClick, new buffer)
			//
			//--------------------
			if (SequenceRenderer.pressedThisFrame && this.effectSnd && this.currentEffectBuffer && opt.jumpClick && !this.paused) {

				this.effectFadeGain.gain.tweenToValue(0.0, 0.1);
				AudioDisposeController.dispose(this.effectSnd, 0.1);
				AudioDisposeController.dispose(this.effectFadeGain, 0.1);

				this.effectSnd = audioContext.createBufferSource();
				this.effectSnd.loop = true;
				
				this.effectFadeGain = audioContext.createGain();
				this.effectFadeGain.gain.tweenToValue(0.0, 0.0);
				this.effectFadeGain.gain.tweenToValue(opt.effectsTrackPreGain, 0.1);

				this.effectSnd.connect(this.effectFadeGain);

				this.effectFadeGain.connect(this.effectMasterGain);

				this.effectSnd.buffer = this.currentEffectBuffer;
				this.effectSnd.start(audioContext.currentTime, (Math.random()*0.8+0.1)*this.currentEffectBuffer.duration);
			} else if (this.effectFadeGain && !this.transitionStarted) {
				this.effectFadeGain.gain.tweenToValue(opt.effectsTrackPreGain, 0.1);
			}



			//--------------------
			//
			// setup/connect bandpass
			//
			//--------------------
			// if (opt.bandpass.enabled && !this.bandpassX) {

			// 	this.bandpassX = audioContext.createBiquadFilter();
			// 	this.bandpassX.type = 'bandpass'
			// 	this.bandpassX.frequency.tweenToValue(1,0.0);
			// 	this.bandpassX.Q.tweenToValue(5.0,0.0);
			// 	this.bandpassX.connect(this.finalGain);

			// 	this.bandpassY = audioContext.createBiquadFilter();
			// 	this.bandpassY.type = 'bandpass'
			// 	this.bandpassY.frequency.tweenToValue(1,0.0);
			// 	this.bandpassY.Q.tweenToValue(5.0,0.0);
			// 	this.bandpassY.connect(this.finalGain);


			// 	this.bandpassGain = audioContext.createGain();
			// 	this.bandpassGain.gain.tweenToValue(0,0.0);
			// 	// this.bandpassGain.gain.tweenToValue(1,RAMP_TIME);
			// 	this.bandpassGain.connect(this.bandpassX);
			// 	this.bandpassGain.connect(this.bandpassY);

			// 	this.effectMasterGain.connect(this.bandpassGain);
			// 	// this.bandpassX.gain.tweenToValue(opt.eq.lowMin, RAMP_TIME);

			// } else if (!opt.bandpass.enabled && this.bandpassY) {

			// 	this.bandpassGain.disconnect();
			// 	this.bandpassY.disconnect();
			// 	this.bandpassX.disconnect();
			// 	this.bandpassX = this.bandpassY = this.bandpassGain = null;

			// }

			//--------------------
			//
			// update bandpass
			//
			//--------------------
			// if (opt.bandpass.enabled) {

				var bandpassDragX = Math.abs(dragX*2.0) < 1.0 ? (dragX*2.0) : Utils.map(Math.abs(dragX),0.5,1.0,1.0,0.0)*Utils.sign(dragX);
				var bandpassDragY = Math.abs(dragY*2.0) < 1.0 ? (dragY*2.0) : Utils.map(Math.abs(dragY),0.5,1.0,1.0,0.0)*Utils.sign(dragY);

				// var bandpassDragY =  Math.abs(dragY*2.0) < 1.0 ? (dragY*2.0) : (1.0*Utils.sign(dragX) - (dragX*2.0));
				// console.log(bandpassDragX, bandpassDragY);

				// AppStatus.log(bandpassDragX, bandpassDragY);

				this.bandpassX.frequency.tweenToValue(Utils.ccmap(bandpassDragX, -1.0, 1.0, opt.bandpass.xFrequencyMin, opt.bandpass.xFrequencyMax), 0.1);
				this.bandpassY.frequency.tweenToValue(Utils.ccmap(bandpassDragY, -1.0, 1.0, opt.bandpass.yFrequencyMin, opt.bandpass.yFrequencyMax), 0.1);

				this.dragPc = Utils.deltaSmoothingSnap2(this.dragPc, SequenceRenderer.isDragging?0.4+Math.min(SequenceRenderer.currentDragSpeed.length()*1.1,1.0) : 0.0, 0.075, delta);

				this.pressPc = Utils.deltaSmoothingSnap2(this.pressPc, 0.0, SequenceRenderer.isDragging?0.035:0.07, delta);
				if (SequenceRenderer.pressedThisFrame) this.pressPc = Utils.clamp(this.pressPc+0.75,0.0,1.0);


				this.bandpassX.Q.tweenToValue(
					Utils.ccmap(this.dragPc,0.0,1.0,Utils.ccmap(opt.bandpass.release,1.0,0.0, 1.0,opt.bandpass.xQ), opt.bandpass.xQ), RAMP_TIME);
				this.bandpassY.Q.tweenToValue(
					Utils.ccmap(this.dragPc,0.0,1.0,Utils.ccmap(opt.bandpass.release,1.0,0.0, 1.0,opt.bandpass.yQ), opt.bandpass.yQ), RAMP_TIME);


				// var gainTarget = Utils.ccmap(this.dragPc, 0.0, 1.0, opt.bandpass.minVolume, opt.bandpass.maxVolume);
				// if (opt.bandpass.enabled) {
				// 	this.bandpassGain.gain.tweenToValue(gainTarget, 0.1);
				// }
				// else
				// this.bandpassGain.gain.tweenToValue(gainTarget, transitionDurationOut);
			// }

			var targetMix = Utils.lerp(opt.bandpass.minMix, opt.bandpass.maxMix, this.dragPc);

			this.bandpassDryGain.gain.tweenToValue(1.0-targetMix, RAMP_TIME);
			this.bandpassWetGain.gain.tweenToValue(targetMix, RAMP_TIME);


			//--------------------
			//
			// Update EQ
			//
			//--------------------
			this.lowNode.frequency.tweenToValue(opt.eq.range*window.EQFilterInfo.low.frequency,0.1);
			this.midNode.frequency.tweenToValue(opt.eq.range*window.EQFilterInfo.mid.frequency,0.1);
			this.highNode.frequency.tweenToValue(opt.eq.range*window.EQFilterInfo.high.frequency,0.1);



			if (!opt.eq.randomEverything && !opt.eq.randomEverything2) {
				this.reverbGain.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,opt.effectGain.reverbMin, opt.effectGain.reverbMax), RAMP_TIME);
				this.lowNode.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,opt.eq.lowMin*(opt.eq.lowMin<0.0?2.0:1.0), opt.eq.lowMax)*20, RAMP_TIME);
				this.lowReverbGain.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,0.0, opt.eq.lowReverbMax), RAMP_TIME);
				this.midNode.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,opt.eq.midMin*(opt.eq.midMin<0.0?2.0:1.0), opt.eq.midMax)*20, RAMP_TIME);
				this.midReverbGain.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,0.0, opt.eq.midReverbMax), RAMP_TIME);

				this.highNode.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,opt.eq.highMax*(opt.eq.highMax<0.0?2.0:1.0), opt.eq.highMax)*20, RAMP_TIME);
				this.highReverbGain.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,0.0, opt.eq.highReverbMax), RAMP_TIME);
			
			} else if (opt.eq.randomEverything2) {

				this.reverbGain.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,opt.effectGain.reverbMin, opt.effectGain.reverbMax), RAMP_TIME);
				this.lowReverbGain.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,0.0, opt.eq.lowReverbMax), RAMP_TIME);
				this.midReverbGain.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,0.0, opt.eq.midReverbMax), RAMP_TIME);
				this.highReverbGain.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,0.0, opt.eq.highReverbMax), RAMP_TIME);


				var angle = Math.atan2(dragY, dragX);
				var dst = Utils.distance(dragX,dragY,0,0);

				var lowX = Utils.cmap(Utils.distanceBetweenAngles(angle, Math.PI*2.0*0.33),0,Math.PI*2.0*0.5,0.0,1.0)*dst;
				var midX = Utils.cmap(dragX,-1,1,0.0,1.0);
				var highX = Utils.cmap(dragY,-1,1,0.0,1.0);

				// console.log(lowX, midX, highX);

				this.lowNode.gain.tweenToValue(Utils.ccmap(lowX,0.0,1.0,opt.eq.lowMin*(opt.eq.lowMin<0.0?2.0:1.0), opt.eq.lowMax)*20, RAMP_TIME);

				this.midNode.gain.tweenToValue(Utils.ccmap(midX,0.0,1.0,opt.eq.midMin*(opt.eq.midMin<0.0?2.0:1.0), opt.eq.midMax)*20, RAMP_TIME);

				this.highNode.gain.tweenToValue(Utils.ccmap(highX,0.0,1.0,opt.eq.highMax*(opt.eq.highMax<0.0?2.0:1.0), opt.eq.highMax)*20, RAMP_TIME);



			} else {


				var posx = SequenceRenderer.currentPosition.x*5.0,
					posy = SequenceRenderer.currentPosition.y*5.5;


				this.reverbGain.gain.tweenToValue((this.noises.reverb.rng(posx+this.noises.reverb.px, posy+this.noises.reverb.py) * 0.5 + 0.5)*pc, RAMP_TIME);
				this.lowNode.gain.tweenToValue(this.noises.low.rng(posx+this.noises.low.px, posy+this.noises.low.py)*pc*30, RAMP_TIME);
				this.midNode.gain.tweenToValue(this.noises.mid.rng(posx+this.noises.mid.px, posy+this.noises.mid.py)*pc*30, RAMP_TIME);
				this.highNode.gain.tweenToValue(this.noises.high.rng(posx+this.noises.high.px, posy+this.noises.high.py)*pc*30, RAMP_TIME);

				this.lowReverbGain.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,0.0, opt.eq.lowReverbMax), RAMP_TIME);

				this.midReverbGain.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,0.0, opt.eq.midReverbMax), RAMP_TIME);

				this.highReverbGain.gain.tweenToValue(Utils.ccmap(pc,0.0,1.0,0.0, opt.eq.highReverbMax), RAMP_TIME);


			}

			//--------------------
			//
			// Update feedback
			//
			//--------------------
			/*
					this.feedbackDelayNode = audioContext.createDelay();
		this.feedbackDelayNode.delayTime.tweenToValue(0.05, 0.0);


		this.feedbackResonanceNode = audioContext.createGain();
		this.feedbackResonanceNode.gain.tweenToValue(1.0,0.0);

		this.feedbackNoPassNode = audioContext.createGain();
		this.feedbackNoPassNode.gain.tweenToValue(1.0,0.0);

		this.feedbackLowpassNode = audioContext.createBiquadFilter();
		this.feedbackLowpassNode.type = 'lowpass'
		this.feedbackLowpassNode.frequency.tweenToValue(320,0.0);
		this.feedbackLowpassNode.Q.tweenToValue(1.0,0.0);
		this.feedbackLowpassNode.gain.tweenToValue(0.0, 0.0);

		this.feedbackMixNode = audioContext.createGain();
		this.feedbackMixNode.gain.tweenToValue(0.0, 0.0);

		this.feedbackBaseNode.connect(this.feedbackDelayNode);
		this.feedbackDelayNode.connect(this.feedbackNoPassNode);
		this.feedbackDelayNode.connect(this.feedbackLowpassNode);

		this.feedbackNoPassNode.connect(this.feedbackResonanceNode);
		this.feedbackLowpassNode.connect(this.feedbackResonanceNode);
		
		this.feedbackResonanceNode.connect(this.feedbackMixNode);
		this.feedbackResonanceNode.connect(this.feedbackBaseNode);

*/
			opt.feedback.enabled = false;
			if (opt.feedback.enabled && opt.feedback.mix > 0.0) {

				if (!this.feedbackConnected) {
					// console.log("CONNECT FEEDBACK!");
					this.feedbackConnected = true;
					this.feedbackMixNode.disconnect();
					this.feedbackOutNode = audioContext.createGain();
					this.feedbackOutNode.gain.tweenToValue(0,0);
					this.feedbackOutNode.gain.tweenToValue(1.0,transitionDuration);
					this.feedbackMixNode.connect(this.feedbackOutNode);
					this.feedbackOutNode.connect(this.finalGain);
					// this.feedbackDelayNode.delayTime.tweenToValue(opt.feedback.delay, 0.0);
					if (!SETTINGS.isFirefox)this.feedbackDelayNode.delayTime.tweenToValue(opt.feedback.delay, 0.0);
					else this.feedbackDelayNode.delayTime.value = opt.feedback.delay;
				}


				// this.feedbackBaseNode.gain.tweenToValue(opt.feedback.mix, RAMP_TIME);
				if (!SETTINGS.isFirefox)this.feedbackDelayNode.delayTime.tweenToValue(opt.feedback.delay, RAMP_TIME);
				else this.feedbackDelayNode.delayTime.value = opt.feedback.delay;

				this.feedbackNoPassNode.gain.tweenToValue(1.0-opt.feedback.lowpass, RAMP_TIME);
				this.feedbackLowpassNode.gain.tweenToValue(opt.feedback.lowpass, RAMP_TIME);
				this.feedbackLowpassNode.frequency.tweenToValue(opt.feedback.lowpassFreq, RAMP_TIME);

				this.feedbackResonanceNode.gain.tweenToValue(Utils.ccmap(pc, 0.0, 1.0, opt.feedback.resonanceMin, opt.feedback.resonanceMax), RAMP_TIME);
				this.feedbackMixNode.gain.tweenToValue(opt.feedback.mix, RAMP_TIME);

			} else if (this.feedbackConnected) {

				this.feedbackOutNode.gain.tweenToValue(0.0, transitionDurationOut);
				AudioDisposeController.dispose(this.feedbackOutNode, transitionDurationOut);
				this.feedbackOutNode = null;
				this.feedbackConnected = false;
			}

			if (this.impact && (this.paused || !SequenceRenderer.isDragging || SequenceRenderer.pressedThisFrame || !opt.impact.enabled  || !this.impactBuffer)) {
				var offset = Math.max(this.impact.minTime-audioContext.currentTime,0);
				this.impact.impactMasterGain.gain.tweenToValue(0.0, offset+opt.impact.release);
				AudioDisposeController.dispose(this.impact.snd, offset+opt.impact.release);
				AudioDisposeController.dispose(this.impact.impactGain, offset+opt.impact.release);
				AudioDisposeController.dispose(this.impact.impactEffectGain, offset+opt.impact.release);
				AudioDisposeController.dispose(this.impact.impactReverbGain, offset+opt.impact.release);
				AudioDisposeController.dispose(this.impact.impactMasterGain, offset+opt.impact.release);
				this.impact = null;
			}

			//--------------------
			//
			// distortion
			//
			//--------------------
			// this.peaking1.frequency.tweenToValue(opt.distortion.frequency, 0.05);
			// this.peaking1.Q.tweenToValue(opt.distortion.Q, 0.05);
			// this.peaking1.gain.tweenToValue(opt.distortion.gain, 0.05);
			
			// this.peaking2.frequency.tweenToValue(opt.distortion.frequency, 0.05);
			// this.peaking2.Q.tweenToValue(opt.distortion.Q, 0.05);
			// this.peaking2.gain.tweenToValue(opt.distortion.gain, 0.05);

			// this.peaking3.frequency.tweenToValue(opt.distortion.frequency, 0.05);
			// this.peaking3.Q.tweenToValue(opt.distortion.Q, 0.05);
			// this.peaking3.gain.tweenToValue(opt.distortion.gain, 0.05);


			// this.peakingFeedback1.gain.tweenToValue(opt.distortion.feedback1, 0.05);
			// this.peakingDelay1.delayTime.tweenToValue(opt.distortion.delay1, 0.05);

			// this.peakingFeedback2.gain.tweenToValue(opt.distortion.feedback2, 0.05);
			// this.peakingDelay2.delayTime.tweenToValue(opt.distortion.delay2, 0.05);

			// this.peakingFeedback3.gain.tweenToValue(opt.distortion.feedback3, 0.05);
			// this.peakingDelay3.delayTime.tweenToValue(opt.distortion.delay3, 0.05);


			//--------------------
			//
			// Impact sound
			//
			//--------------------
			var goingUp = false;

			if (!SETTINGS.isMobile) {
				if (SequenceRenderer.currentDragSpeed.length() > this.lastSpeed) {
					if (this.speedGoingDown>=20) {goingUp = true;}
					this.speedGoingDown = Math.min(this.speedGoingDown-1,0);
				}
				else {
					this.speedGoingDown = Math.max(this.speedGoingDown+1,0);
				}
				this.lastSpeed = SequenceRenderer.currentDragSpeed.length();
			}
			

			if ((SequenceRenderer.pressedThisFrame || (goingUp && opt.impact.loop) || (!SETTINGS.isMobile && SequenceRenderer.isDragging && !this.wasDragging && !this.impact && opt.impact.loop)) && !this.paused && opt.impact.enabled && this.impactBuffer && this.impactBuffer.value && this.impactBuffer.value.length > 0) {
				
				if (this.impact) {
					var offset = Math.max(this.impact.minTime-audioContext.currentTime,0);
					this.impact.impactMasterGain.gain.tweenToValue(0.0, offset+opt.impact.release);
					AudioDisposeController.dispose(this.impact.snd, offset+opt.impact.release);
					AudioDisposeController.dispose(this.impact.impactGain, offset+opt.impact.release);
					AudioDisposeController.dispose(this.impact.impactEffectGain, offset+opt.impact.release);
					AudioDisposeController.dispose(this.impact.impactReverbGain, offset+opt.impact.release);
					AudioDisposeController.dispose(this.impact.impactMasterGain, offset+opt.impact.release);
					this.impact = null;
				}

				this.forceImpact = false;
				var impactMasterGain = audioContext.createGain();
				impactMasterGain.gain.value = 1.0;
			
				var impactReverbGain = audioContext.createGain();
				impactReverbGain.gain.value = opt.impact.reverb;
				impactReverbGain.connect(this.reverbInGain);

				var impactGain = audioContext.createGain();
				impactGain.gain.value = opt.impact.volume;
				impactGain.connect(this.finalGain);

				var impactEffectGain = audioContext.createGain();
				impactEffectGain.gain.value = opt.impact.effectMix;
				impactEffectGain.connect(this.effectMasterGain);

				var snd = audioContext.createBufferSource();
				snd.loop = false;
				impactMasterGain.connect(impactGain);
				impactMasterGain.connect(impactReverbGain);
				impactMasterGain.connect(impactEffectGain);
				snd.connect(impactMasterGain);
				// snd.playbackRate.value = Utils.random(opt.impact.pitchMin, opt.impact.pitchMax);
				var impacts =  this.impactBuffer.value.filter((v)=>v);
				
				if (impacts.length > 1) {
					var last = this.currentImpactRandom;
					while (this.currentImpactRandom == last) this.currentImpactRandom  = Math.floor(Math.random()*impacts.length);
				} else {
					this.currentImpactRandom = 0;
				}
				// console.log("Playing buffer:",this.currentImpactRandom, impacts[this.currentImpactRandom]);
				var buffer = impacts[this.currentImpactRandom].value.buffer;
				snd.buffer = buffer;

				if (!opt.impact.loop) {
					AudioDisposeController.dispose(snd, buffer.duration);
					AudioDisposeController.dispose(impactGain, buffer.duration);
					AudioDisposeController.dispose(impactEffectGain, buffer.duration);
					AudioDisposeController.dispose(impactReverbGain, buffer.duration);
					AudioDisposeController.dispose(impactMasterGain, buffer.duration);
					// if (!SETTINGS.isMobile && (!SequenceRenderer.mouseIsDown && !SequenceRenderer.pressedThisFrame)) {
					// 	impactMasterGain.gain.tweenToValue(0.0, 0.0);
					// 	impactMasterGain.gain.tweenToValue(1.0, 0.1);
					// }
				

				} else {

					var atck = opt.impact.attack;
					if (!SequenceRenderer.mouseIsDown && !SequenceRenderer.pressedThisFrame) atck = Math.max(atck,0.5);
					impactMasterGain.gain.tweenToValue(0.0, 0.0);
					impactMasterGain.gain.tweenToValue(1.0, atck);
					snd.loop = true;
					this.impact = {
						minTime: audioContext.currentTime+atck,
						snd: snd,
						impactMasterGain: impactMasterGain,
						impactGain: impactGain,
						impactReverbGain: impactReverbGain,
						impactEffectGain: impactEffectGain
					}
				}

				var offset = opt.impact.randomOffset ? Math.random()*buffer.duration:0.0;
				snd.start(audioContext.currentTime, offset);
			}
			this.wasDragging = SequenceRenderer.isDragging;
		}


	}

	triggerSFX(currentTime, info) {
		if (info.file == 'none' || !info.file) return;
		var buffer = this.audioBuffersByName["audio/sfx/"+info.file].value.buffer;

		var impactGain = audioContext.createGain();
		impactGain.connect(audioContext.destination);

		var startTime = audioContext.currentTime + Math.max( (info.timecode-currentTime), 0);

		if (audioContext.currentTime < startTime) impactGain.gain.setValueAtTime(0.0, audioContext.currentTime);
		impactGain.gain.setValueAtTime(0.0, startTime);
		impactGain.gain.linearRampToValueAtTime(info.volume, startTime+Math.max(info.attack||0,0.05));
		impactGain.gain.setValueAtTime(info.volume, startTime+buffer.duration-0.05);
		impactGain.gain.linearRampToValueAtTime(0.0, startTime+buffer.duration);

		var snd = audioContext.createBufferSource();
		snd.loop = false;
		snd.buffer = buffer;
		snd.connect(impactGain);
		snd.start(startTime);

		AudioDisposeController.dispose(snd, startTime+buffer.duration);
		AudioDisposeController.dispose(impactGain, startTime+buffer.duration);
	}



	isReady() {
		if (!this.audioStarted) return false;
		if (!this.currentOptions) return false;

		var opt = this.currentOptions;

		var buf = null;
		var loaded = true;
		buf = this.audioBuffersByName["audio/music/"+opt.effectTrack]
		loaded = loaded && (opt.effectTrack == 'none' || !!(buf && buf.value && buf.loaded));
		buf = this.audioBuffersByName["audio/music/"+opt.backgroundTrack];
		loaded = loaded && ((opt.backgroundTrack == 'none') || !!(buf && buf.value && buf.loaded));
		buf = this.audioBuffersByName["audio/impulse_response/"+opt.reverb];
		loaded = loaded && (opt.reverb == 'none' || !!(buf && buf.value && buf.loaded));

		if (opt.impact.enabled && opt.impact.file!='none') {
			buf = this.audioBuffersByName["audio/impact/"+opt.impact.file];
			if (buf && buf.value) {
				for (var i = 0; i <buf.value.length; i++) {
					loaded = loaded && !!(buf && buf.value[i].value && buf.value[i].loaded);
				}
			}
		}
		if (!loaded) {
			// console.warn("not ready!");
		}
		return loaded;
	}


	//
	// Migrate preset versions
	//
	migratePreset(preset) {

		if (preset.migrated) return preset;
		preset.migrated = true;

		var opt = preset.options;

		if (opt.backgroundMixIn == undefined) opt.backgroundMixIn  =0.0;
		if (opt.effectsVolume == undefined) opt.effectsVolume = 1.0;

		if (!opt.impact || preset.version<=5) opt.impact = Utils.clone(this.defaultOptions.impact);
		if (preset.version < 7) {

			opt.eq = {
				eqGain: opt.eqGain,

				lowMin: opt.lowMin,
				lowMax: opt.lowMax,
				lowReverbMax: opt.lowReverbMax,

				midMin: opt.midMin,
				midMax: opt.midMax,
				midReverbMax: opt.midReverbMax,

				highMin: opt.highMin,
				highMax: opt.highMax,
				highReverbMax: opt.highReverbMax,

				randomEverything: !!opt.randomEverything,
			};



			delete opt.eqGain;

			delete opt.lowMin;
			delete opt.lowMax;
			delete opt.lowReverbMax;

			delete opt.midMin;
			delete opt.midMax;
			delete opt.midReverbMax;

			delete opt.highMin;
			delete opt.highMax;
			delete opt.highReverbMax;
			delete opt.randomEverything;

			opt.effectGain = {
				volumeMin: opt.volumeMin,
				volumeMax: opt.volumeMax,
				reverbMin: opt.reverbMin,
				reverbMax: opt.reverbMax
			};
			delete opt.volumeMin;
			delete opt.volumeMax;
			delete opt.reverbMin;
			delete opt.reverbMax;

			opt.impact.loop = false;
			opt.impact.attack = 0.0;
			opt.impact.release = 0.0;
			opt.impact.randomOffset = !!opt.impact.randomOffset;

			delete opt.waveform;
			// delete preset.
			
			opt.timeline =  Utils.clone(this.defaultOptions.timeline);
			opt.timeline.timeStart = "00:00";
			opt.timeline.timeEnd = "1:00";
		}

		if (opt.compressor == undefined) opt.compressor = 0;
		if (opt.postGain == undefined) opt.postGain = 1.0;

		if (opt.oscillator == undefined) opt.oscillator = Utils.clone(this.defaultOptions.oscillator);
		if (opt.bandpass == undefined) opt.bandpass = Utils.clone(this.defaultOptions.bandpass);
		if (opt.bandpass.release == undefined) opt.bandpass.release = 0.0;
		if (opt.jumpClick == undefined) opt.jumpClick = false;
		if (opt.eq.range == undefined) opt.eq.range = 1.0;

		if (opt.effectsTrackPreGain == undefined) opt.effectsTrackPreGain = 1.0;

		if (opt.mobile) delete opt.mobile;

		if (preset.version < 9) {
			opt.bandpass.minMix = opt.bandpass.enabled ? 1 : 0;
			opt.bandpass.maxMix = opt.bandpass.enabled ? 1 : 0;
		}

		// opt.voiceMixIn = opt.voiceMixIn||0;

		opt.sfx = Utils.clone(this.defaultOptions.sfx);

		if (opt.transition) delete opt.transition;

		opt.isNone = false;
		// console.log(opt.impact);


		// if (SETTINGS.FAST_DEBUG_MODE) {
		// 	opt.oscillator.enabled = false;
		// 	opt.reverb = 'none';
			// opt.impact.enabled = false;
			// opt.feedback.enabled = false;
		// }

		return preset;
	}
}
window.AudioEffectController = window.AudioEffectController||new AudioEffectController();
export default window.AudioEffectController;