import $ from 'jquery';
import * as THREE from 'three';
import Utils3D from './3DUtils.js';

var Utils = Utils3D;

Utils.map = function(value, istart, istop, ostart, ostop) {
	return ostart + (ostop - ostart) * ((value - istart) / (istop - istart));
};
Utils.safecmap = function(value, istart, istop, ostart, ostop) {
	var v = Math.max(Math.min(ostart + (ostop - ostart) * ((value - istart) / (istop - istart)),ostop),ostart);
	if (isNaN(v)) return ostart;
	return v;
};
Utils.cmap = function(value, istart, istop, ostart, ostop) {
	return Math.max(Math.min(ostart + (ostop - ostart) * ((value - istart) / (istop - istart)),ostop),ostart);
};
Utils.ccmap = function(value, istart, istop, ostart, ostop) {
	return Math.max(Math.min(ostart + (ostop - ostart) * ((value - istart) / (istop - istart)),Math.max(ostop,ostart)),Math.min(ostop,ostart));
};
Utils.cmapZeroOne = function(value, istart, istop) {
	return Math.max(Math.min(1.0 * ((value - istart) / (istop - istart)),1.0),0.0);
};
Utils.clamp = function(value, ostart, ostop) {
	return Math.max(Math.min(value,ostop),ostart);
};
Utils.sign = function(value) {
	return value < 0 ? -1 : 1;
};
Utils.randoma = function(arr) {
	return arr[Math.floor(Math.random()*arr.length)];
};
Utils.random = function(mi, ma) {
	return Math.random()*(ma-mi) + mi;
};
Utils.randomi = function(mi, ma) {
	return Math.floor(Math.random()*(ma-mi) + mi);
};
Utils.capitalizeFirstLetter = function(string) {
	if (!string || (string && !string.length)) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};
Utils.shuffle = function(arr) {
	var na = [];
	while (arr.length > 0) {
		var i = Math.floor(Math.random()*arr.length);
		na.push(arr[i]);
		arr.splice(i,1);
	}
	return na;
};
Utils.clone = function(obj) {
	return JSON.parse(JSON.stringify(obj));
};
Utils.cloneArray = function(source) {
	return $.extend(true, [], source);
};

Utils.cloneInto = function(source, target) {
	$.extend(true, target, source);
	return Utils.clone(target);
};
Utils.cloneIntoMod = function(source, target) {
	for (var prop in source) {
		if (prop in target) {
			if (source[prop] && source[prop].constructor == Object) {
				Utils.cloneIntoMod(source[prop], target[prop])
			} else {
				target[prop] = source[prop];
			}
		}
	}
	return Utils.clone(target);
};
Utils.cloneIntoAdd = function(source, target) {
	for (var prop in source) {
		if (target[prop] && target[prop].constructor == Array) {
			target[prop] = target[prop].concat(source[prop]);
		} else {
			target[prop] = source[prop];
		}
	}
	return jQuery.extend(true, target, source);
	// return Utils.clone(target);
};
Utils.cloneIntoRaw = function(source, target) {
	for (var o in source) {
		if (typeof source[o] === 'number' || typeof source[o] === 'string' || typeof source[o] === 'boolean') {
			target[o] = source[o];
		}
	}
	return target;
};




Utils.fastConcat = function(a,b) {
	Array.prototype.push.apply(a, b);
	return a;
};

Utils.shuffleClone = function(arr) {
	var na = [];
	var source = [];
	for (var i=0; i<arr.length; i++) {
		source[i] = i;
	}
	while (source.length > 0) {
		var n = Math.floor(Math.random()*source.length);
		na.push(arr[source[n]]);
		source.splice(n,1);
	}
	return na;
};
Utils.zeroPad = function(num, numzeros) {
	var s = parseInt(num,10).toString();
	while(s.length<numzeros) s = '0'+s;
	return s;
};


Utils.generateUUID = function() {
	return Date.now().toString()+'_'+Math.random().toString().split('.').pop()
};

Utils.currentID = 0;
Utils.generateID = function() {
	return (Utils.currentID++).toString();
};

Utils.lerp = function lerp(a, b, pc) {
	return a * (1.0-pc) + b * pc;
};

Utils.lerpSnap = function(a, b, pc) {
	// bias = bias || 0.001;
	if (Math.abs(a-b) <= 0.001) return b;
	return a * (1.0-pc) + b * pc;
};

Utils.deltaSmoothing = function deltaSmoothing(src, target, smoothing, delta) {
	return Utils.lerp(target, src, Math.max(Math.min(Math.pow(smoothing, delta),1.0),0.0)  );
};

Utils.deltaSmoothingSnap = function(src, target, smoothing, delta) {
	return Utils.lerpSnap(src, target, Math.max(Math.min(Math.pow(smoothing, delta),1.0),0.0));
};

Utils.deltaSmoothingSnap2 = function(src, target, smoothing, delta) {
	return Utils.lerpSnap(src, target, Math.max(Math.min(1.0-Math.pow(1.0-smoothing, delta),1.0),0.0));
	// return Utils.lerpSnap(src, target, Math.max(Math.min(smoothing * delta,1.0),0.0));
};


Utils.normalizeAngle = function(a) {
	while (a<0) a += Math.PI*2;
	while (a>Math.PI*2) a-= Math.PI*2;
	return a;
};
Utils.normalizeAngleDegree = function(a) {
	while (a<0) a += 360;
	while (a>360) a-= 360;
	return a;
};
Utils.distance = function(xa,ya,xb,yb) {
	var dst = Math.sqrt(Math.pow(xa-xb,2.0) + Math.pow(ya-yb,2.0));
	if (isNaN(dst)) dst = 0.0;
	return dst;
}

Utils.distanceBetweenAngles = function(a,b) {
	a = Utils.normalizeAngle(a);
	b = Utils.normalizeAngle(b);
	var abs = Math.abs(a-b);
	if (abs < Math.PI) return abs;

	if (a >= Math.PI*1.0 && b <= Math.PI*1.0) return Math.abs(a - (b+Math.PI*2));
	if (b >= Math.PI*1.0 && a <= Math.PI*1.0) return Math.abs((a+Math.PI*2) - b);
	return abs;
};

Utils.directionBetweenAngle = Utils.directionBetweenAngles = function(a,b) {
	var abs = Math.abs(a-b);
	if (abs <= Math.PI) return (a < b) ? 1 : -1;

	if (a >= Math.PI*1.0 && b <= Math.PI*1.0) return 1;
	if (b >= Math.PI*1.0 && a <= Math.PI*1.0) return -1;
	return 1;
};
Utils.lerpAngle = function(a, b, pc) {

	a = Utils.normalizeAngle(a);
	b = Utils.normalizeAngle(b);

	var dir = Utils.directionBetweenAngles(a,b),
		dist = Utils.distanceBetweenAngles(a,b);

	return a + dir * dist * pc;
};
Utils.lerpAngleSnap = function(a, b, pc) {

	a = Utils.normalizeAngle(a);
	b = Utils.normalizeAngle(b);

	var dir = Utils.directionBetweenAngles(a,b),
		dist = Utils.distanceBetweenAngles(a,b);
	
	if (dist <= 0.0001) return a + dir * dist;
	return a + dir * (dist * pc);
};

Utils.lerpAngleSnapDelta = function(a, b, smoothing, delta) {

	a = Utils.normalizeAngle(a);
	b = Utils.normalizeAngle(b);

	var dir = Utils.directionBetweenAngles(a,b),
		dist = Utils.distanceBetweenAngles(a,b);
	
	//if (dist <= 0.00001) return a + dir * dist;
	return Utils.deltaSmoothingSnap(a,  a + dir *  dist, smoothing, delta);
};

Utils.replaceGender = function(text, gender) {
	var x = text;
	var y = /\[(.*?)\]/gi;
	var z = null;
	while((z=y.exec(text)) != null) {
		x = x.replace(z[0], (z[1].split('//')[gender||0]||'').trim());
	}
	return x;
};


(function(Utils) {
	var timeStarts = {},
		averages = {},
		lastLog = {};

	Utils.time = function(name) {
		timeStarts[name] = performance.now();
	};

	Utils.timeEnd = function(name, printEvery) {
		var now = performance.now();
		if (averages[name] === undefined) {
			averages[name] = (now-timeStarts[name]);
			lastLog[name] = -100000;
		}
		averages[name] = averages[name] * 0.95 + 0.05 * (now-timeStarts[name]);
		if (!printEvery) console.log(name, (now-timeStarts[name]));

		if (now - lastLog[name] > (printEvery||5000)) {
			console.log('Average time for '+name+': '+averages[name]);
			lastLog[name] = now;
		} 

	};
})(Utils);


//
// Save JSON file with comments
//
Utils.saveJsonSimple = function(fileName,folder,json) {
	return new Promise(function(resolve, reject) {
		if (confirm('Sauvegarder le fichier: ' + folder+fileName)) {
			try {
				//
				// send to 
				//
				$.ajax({
					type:'POST',
					dataType:'text',
					url: window.location.origin + '/save_json',
					data: {
						fileName: fileName,
						folder: folder,
						value: json
					},
					success: function(a) {
						alert('Sauvegarde Réussie: '+fileName);
						resolve();
					},
					error: function(a) {
						alert('ERREUR: ECHEC DE LA SAUVEGARDE DU FICHIER JSON.');
						reject();
					}
				});


			} catch (er) {
				alert('ERREUR: ECHEC DE LA SAUVEGARDE DU FICHIER JSON - ',er);
				console.error(er);
				reject();
			}
		} else {
			reject();
		}
	});
};


Utils.savePresetCallback = function(src, folderName) {
	return (function() {
		console.log("ookk",prompt);
		var value = prompt('Nom du preset', src.options.preset);
		if (!value) {return;}


		if (!/\.json/.test(value)) {
			value = value+'.json';
		}
        var jsonFile = JSON.stringify(
        	{
				version: SETTINGS.INTERNAL_VERSION,
				options: this.options
			},
		null,'\t');

        Utils.saveJsonSimple(value, folderName, jsonFile).then(function() {
       	 	// options.preset = value;
       	 	console.log("Save successful ",value);
       	 	// Utils.cloneInto()
        }.bind(src)).catch(function(e) {
        	console.log("Save error!",e);
        	// saving = false;
        });
	}.bind(src))
}


Utils.hashCode = function(s){
	if (s && typeof s === "number") return s;
  	return Math.abs(s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0));              
}

//extra string capitalize
Utils.capitalize = function(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

//easing
Utils.easeInOutSine = function(time, duration) {
	return -0.5 * (Math.cos(Math.PI*time/duration) - 1);
};
Utils.easeInOutQuint = function(t) {return t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t};
Utils.easeOutQuint = function(t) {return 1+(--t)*t*t*t*t};
Utils.easeInQuint = function(t) {return t*t*t*t*t};

Utils.easeInQuart = function(t) {return t*t*t};


Utils.emptyFunction = function() {};


//audio mixing

Utils.mixWet = function(pc) {
	return Math.log(1.0+pc*(Math.E-1.0)) || 0;
}
Utils.mixDry = function(pc) {
	return Math.log(1.0+(1.0-pc)*(Math.E-1.0)) || 0;
}


Utils.secondsToTimecode = Utils.formatSeconds = function(seconds) {
	return Utils.zeroPad(Math.floor(seconds/60),2) + ":"+Utils.zeroPad(Math.floor(seconds)%60,2) + "."+Utils.zeroPad(Math.floor( (seconds%1)*100),2)
	// return Utils.zeroPad(Math.floor(seconds/60),2) + ":"+Utils.zeroPad(Math.floor(seconds)%60,2);
};
Utils.timecodeToSeconds = function(seconds) {
	var tt = seconds.replace(".",":").split(":");
	return parseInt(tt[0],10)*60 + parseInt(tt[1],10) + (tt.length<=2?0:(parseInt(tt[2],10)/100));
};



Array.prototype.move = function(from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
};



if (typeof window !== "undefined") window.Utils = Utils;
export default Utils