import $ from 'jquery';
import FastEvent from './FastEvent.js';
import Utils from './Utils.js';	
/*

	<div class="ui-item">
        <div class="ui-color-code"></div>
        <div class="ui-label">input checkbox</div>
        <input class="ui-checkbox-input" type="checkbox" value="true"></input>
    </div>

*/
function Button(UI) {
	if (!UI.enabled) return;

	//
	// Create the div hierarchy
	//
	var containerDiv = document.createElement('div'),
		buttonDiv = document.createElement('div'),
		colorCodeDiv = document.createElement('div'),
		labelDiv = document.createElement('div');
	this.domElement = containerDiv;

	containerDiv.appendChild(buttonDiv);
	buttonDiv.appendChild(colorCodeDiv);
	buttonDiv.appendChild(labelDiv);

	//
	// Set the classes and basic properties
	//
	$(containerDiv).addClass('ui-item');
	$(colorCodeDiv).addClass('ui-color-code');
	$(labelDiv).addClass('ui-label-button');
	$(buttonDiv).addClass('ui-button');

	
	//
	// props
	//
	this.uuid = Math.floor(Math.random() * 100000000000).toFixed(0);
	this.value = false;
	this.propName = '';
	this.toWatch = {};
	this.path = [];
	this.shouldSend  = true;
	this.action = function(){ 
		if (this.shouldSend) UI.updateAction(this.path, this.propName);
	}.bind(this);
	

	//
	// init the properties
	//
	this.init = function(toWatch, propName, props, path) {
		
		this.propName = propName;
		this.toWatch = toWatch;
		this.path = path;
		if (props.shouldSend!== undefined) this.shouldSend = props.shouldSend;

		var act = props.action || this.toWatch[this.propName];
		if (act && typeof act == 'function') this.action = act;

		$(labelDiv).text(propName);
		$(colorCodeDiv).css('background-color', props.colorCode || '#'+Math.floor(Math.random()*0xffffff).toString(16));
		//if (this.shouldSend) UI.updateAction(this.path, this.propName);

		//Object.observe(toWatch, this.updateValue);

		//
		// Midi link
		//
		// this.props = props;
		// UI.midiEnable(this.props, props.midiChannel, labelDiv);
		// FastEvent.on(this.props,'midiMessage', function(e, channel, note, velocity) {
		// 	this.action(); //if (velocity>0) 
		// }.bind(this));

	}.bind(this);


	//
	// ACTION
	//
	$(containerDiv).on('click touchstart', function(e) {

		this.action();

	}.bind(this));


	//copy path name
	$(labelDiv).on('click', function(e) {
		if (e.altKey) {
			Utils.copyToClipboard(labelDiv, this.path.concat(this.propName).join('.'));
		}
	}.bind(this));
	
	//
	// Clean up
	//
	this.dispose = function() {


		$(this).off(); FastEvent.off(this);
		$(containerDiv).off();
		$(colorCodeDiv).off();
		$(labelDiv).off();
		$(buttonDiv).off();

		$(containerDiv).remove();
		$(colorCodeDiv).remove();
		$(labelDiv).remove();
		$(buttonDiv).remove();

		this.domElement = null;
		containerDiv = null;
		colorCodeDiv = null;
		labelDiv = null;
		buttonDiv = null;
	};
};

export default Button;