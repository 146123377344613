import $ from 'jquery';
import FastEvent from './FastEvent.js';
import Utils from './Utils.js';

function DropdownList(UI) {
	if (!UI.enabled) return;

	//
	// Create the div hierarchy
	//
	var containerDiv = document.createElement('div'),
		subContainerDiv = document.createElement('div'),
		mainRowContainerDiv = document.createElement('div'),
		colorCodeDiv = document.createElement('div'),
		labelDiv = document.createElement('div'),
		sequencerAddDiv = document.createElement('div'),
		sequencerDeleteDiv = document.createElement('div');

	this.domElement = containerDiv;


	containerDiv.appendChild(mainRowContainerDiv);
	containerDiv.appendChild(subContainerDiv);


	mainRowContainerDiv.appendChild(colorCodeDiv);
	mainRowContainerDiv.appendChild(labelDiv);
	mainRowContainerDiv.appendChild(sequencerDeleteDiv);
	mainRowContainerDiv.appendChild(sequencerAddDiv);


	//
	// Set the classes and basic properties
	//
	$(containerDiv).addClass('ui-item-sequencer');
	$(containerDiv).css('width', 'calc(100% - 4px)');
	$(subContainerDiv).addClass('ui-item-sequencer');
	// $(subContainerDiv).css('width', 'calc(100% - 4px)');
	$(mainRowContainerDiv).addClass('ui-item-sequencer-row');
	$(mainRowContainerDiv).css('transform', 'translate(-4px, 0)');

	$(colorCodeDiv).addClass('ui-color-code');
	$(labelDiv).addClass('ui-label-folder');
	$(sequencerDeleteDiv).addClass('ui-sequencer-delete');
	$(sequencerAddDiv).addClass('ui-sequencer-add');
	$(sequencerDeleteDiv).text("-");
	$(sequencerAddDiv).text("+");

	this.allDropdowns = [];
	var allDropdownsOptions = [],
		allDropdownsPropName = [],
		allDropdownsRanges = [],
		allDropdownsFunctions = [];


	//
	// dropdownObserve
	//
	function dropdownObserve(self, dropdownf, dropdownOpt, propName) {
		return function(evt, changes) {
			for (var i=0; i<self.allDropdowns.length; i++) {
				if (self.allDropdowns[i] === dropdownf) {
					self.value[i] = dropdownOpt['composition'];
				}
			}
			// FastEvent.trigger(self.toWatch, 'update-values');
			if (self.callback) self.callback();
		};
	};



	//
	// Drag dropdown
	//
	function dropdownDraggable(dropdownf, self) {
		$(dropdownf.labelDiv).on('mousedown', function(e) {
			var dragStart = e.pageY,
				currentOrder = 0;

			$(document.body).on('mousemove.dropdowndrag', function(e) {
				var newOrder = Math.round( (e.pageY-dragStart) / 28);
				if (currentOrder !== newOrder) {
					currentOrder = newOrder;
					var movev = currentOrder;
					if (currentOrder !== 0) movev += Utils.sign(currentOrder);
					$(dropdownf.domElement).css('order', dropdownf.props.order + movev);
				}
			});

			$(window).on('mouseup.dropdowndrag', function() {
				$(document.body).off('mousemove.dropdowndrag');
				$(window).off('mouseup.dropdowndrag');

				//shift all dropdowns by value
				// if (currentOrder !== 0) {

				if (currentOrder !== 0) {
					var newPos = dropdownf.props.order + currentOrder;

					Utils.arrayMove(self.allDropdowns, dropdownf.props.order, newPos);
					Utils.arrayMove(allDropdownsOptions, dropdownf.props.order, newPos);
					Utils.arrayMove(allDropdownsPropName, dropdownf.props.order, newPos);
					Utils.arrayMove(allDropdownsRanges, dropdownf.props.order, newPos);

					for (var i=0; i<self.allDropdowns.length; i++) {
						subContainerDiv.removeChild(self.allDropdowns[i].domElement	);
					}
					for (var i=0; i<self.allDropdowns.length; i++) {
						self.allDropdowns[i].props.order = i;
						$(self.allDropdowns[i].domElement).css('order', i);
						subContainerDiv.appendChild(self.allDropdowns[i].domElement);
						$(self.allDropdowns[i].labelDiv).text(i);
						self.value[i] = allDropdownsOptions[i]['composition']
					}
					if (self.callback) self.callback();
				}
			});
		});
	};




	//
	// Add / Remove a row
	// Bind on button click
	//
	this.addRow = function(e) {

		var propName = 'composition',
		// this.allDropdowns.length.toString(),
			dropdownRange = {},
			dropdownOpt = {},
			dropdownf = new UI.DropdownMulti(UI);


		var fnc = dropdownObserve(this, dropdownf, dropdownOpt, propName);
		UI.watchProperty(dropdownOpt, propName, fnc);
		allDropdownsFunctions.push(fnc);

		dropdownRange[propName] = JSON.parse(JSON.stringify(this.ranges));
		dropdownRange[propName].isMultiDropdown = true;
		dropdownRange[propName].isDropdownList = false;
		dropdownRange[propName].order = this.allDropdowns.length;

		if (e) {
			dropdownOpt[propName] = "none";
			this.value.push(dropdownOpt[propName]);
		} else {
			dropdownOpt[propName] = this.value[this.allDropdowns.length];
		}



		dropdownf.init(dropdownOpt, propName, dropdownRange[propName], []);
		subContainerDiv.appendChild(dropdownf.domElement);
		$(dropdownf.domElement).css('order', this.allDropdowns.length);
		$(dropdownf.labelDiv).text(this.allDropdowns.length);

		this.allDropdowns.push(dropdownf);
		allDropdownsOptions.push(dropdownOpt);
		allDropdownsPropName.push(propName);
		allDropdownsRanges.push(dropdownRange);

		dropdownf.canUpdateUI = true;
		dropdownf.updateUI();

		dropdownDraggable(dropdownf, this);

		FastEvent.trigger(this.toWatch, 'sequence-update');

		$(sequencerDeleteDiv).toggleClass('disabled', (this.allDropdowns.length <= 0));
		if (this.callback) this.callback();

	}.bind(this);
		
	//
	// Remove the last row from the list and pop values array
	//
	this.removeRow = function(e, force) {

		if (this.allDropdowns.length <= 0 && !force) return;

		var p = this.allDropdowns.pop();
		if (p) {
			p.dispose();
			UI.unwatchProperty(allDropdownsOptions.pop(), allDropdownsPropName.pop(), allDropdownsFunctions.pop());
			allDropdownsRanges.pop();

			this.value.pop();

		} else {
			
			allDropdownsOptions.pop();
			allDropdownsFunctions.pop();
			allDropdownsPropName.pop();
			allDropdownsRanges.pop();

			this.value.pop();
		}
		FastEvent.trigger(this.toWatch, 'sequence-update');
			
		$(sequencerDeleteDiv).toggleClass('disabled', (this.allDropdowns.length <= 0));
		if (this.callback) this.callback();
	}.bind(this);

	$(sequencerAddDiv).on('click',this.addRow);
	$(sequencerDeleteDiv).on('click',this.removeRow);



	//---------------------
	//
	// props
	//
	//---------------------
	this.uuid = Math.floor(Math.random() * 100000000000).toFixed(0);
	this.value = [];
	this.ranges = null;
	this.propName = '';
	this.toWatch = {};
	this.changing = false;
	this.callback = null;
	this.path = [];


	//---------------------
	//
	// init the properties 
	//
	//---------------------
	this.init = function(toWatch, propName, props, path) {
		

		this.propName = propName;
		this.toWatch = toWatch;
		this.path = path;
		this.ranges = props;
		this.value = this.toWatch[this.propName].values;




		$(labelDiv).text(propName);
		$(colorCodeDiv).css('background-color', props.colorCode || '#'+Math.floor(Math.random()*0xffffff).toString(16));

		this.callback = props ? props.callback : null;


		FastEvent.on(this.toWatch, 'sequence-newvalues', this.updateAllValues);

		for (var i=0; i<this.value.length; i++) {
			this.addRow(false);
		}
		// if (this.value.length == 0) this.addRow(true);
		$(sequencerDeleteDiv).toggleClass('disabled', (this.allDropdowns.length <= 0));

		//
		// Observe for range change
		//

		//
		// Tick Events
		//
		// FastEvent.on(this.toWatch,'tick',this.updateTick);

		// UI.watchProperty(this.toWatch,'directValue',this.updateDirectValue);

		UI.watchProperty(this.toWatch, propName, this.updateAllValues.bind(this));


		FastEvent.on(props, 'updateRange', function(evt) {
			for (var i=0; i<this.allDropdowns.length; i++) {
				// allDropdownsRanges[i].values = this.ranges.values;
				this.allDropdowns[i].props.values = this.allDropdowns[i].values = this.ranges.values;
				this.allDropdowns[i].shouldUpdateRange = true;
			}
			this.shouldUpdateRange = true;


		}.bind(this));

		//
		// Midi link
		//
		// UI.midiEnable(this, labelDiv);
		// $(this).on('midiMessage', function(e,channel, note, velocity) {
		// 	this.toWatch[this.propName] = velocity > 0;
		// }.bind(this));

	}.bind(this);

	this.updateAllValues = function() {
		this.value = this.toWatch[this.propName].values;

		var vals = [];
		for (var i=0; i<this.value.length; i++) {
			vals.push(this.value[i]);
		}
		while(this.allDropdowns.length) {
			this.removeRow(true, true);
		}
		for (var i=0; i<vals.length; i++) {
			this.value[i] = vals[i];
			this.addRow(false);
		}
		if (this.value.length == 0) this.addRow(true);

	}.bind(this);



	this.currentTick = 0;


	//
	// Tick Events
	//
	// this.updateTick = function(evt, data) {
	// 	if (this.disposed) return;
		
	// 	this.currentTick = data.id;
		
	// 	//
	// 	// Increment current value dropdown
	// 	//

	// }.bind(this);


	//
	// Update UI
	//



	//
	// Clean up
	//
	this.disposed = false;
	this.dispose = function() {
		if (this.disposed) return;
		this.disposed = true;

		//Object.unobserve(this.toWatch, this.updateValue);
		
		this.callback = null;
		$(this).off(); FastEvent.off(this);

		// for (var i=0; i<allSequencerRows.length; i++) {
		// 	var d = allSequencerRows[i];
		// 	if (d) {
		// 		for (var j=0; j<d.sequence.length; j++) {
		// 			$(d.sequence[j]).off();
		// 			$(d.sequence[j]).remove();
		// 		}
		// 		$(d.sequencerDiv).empty();
		// 		$(d.sequencerDiv).remove();
		// 		d.sequence = [];
		// 	}
		// }
		while(this.value.length) {
			this.removeRow(true, true);
		}
		//allSequencerRows = [];
		FastEvent.off(this.toWatch,'directValue',this.updateDirectValue);
		UI.unwatchProperty(this.toWatch, propName);

		$(containerDiv).off();
		$(mainRowContainerDiv).off();
		$(colorCodeDiv).off();
		$(labelDiv).off();
		$(sequencerAddDiv).off();
		$(sequencerDeleteDiv).off();

		$(containerDiv).remove();
		$(mainRowContainerDiv).remove();
		$(colorCodeDiv).remove();
		$(labelDiv).remove();
		$(sequencerAddDiv).remove();
		$(sequencerDeleteDiv).remove();
		$(subContainerDiv).remove();

		containerDiv = null;
		mainRowContainerDiv = null;
		colorCodeDiv = null;
		labelDiv = null;
		subContainerDiv = null;
		sequencerAddDiv = null;
		sequencerDeleteDiv = null;

	};
};

export default DropdownList;