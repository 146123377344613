import $ from 'jquery';
import * as THREE from 'three';
import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import Fbo from '../utils/Fbo.js';

import Loader from "../loading/Loader.js";
import LoaderAudio from "../loading/LoaderAudio.js";
import PageLoader from "../loading/PageLoader.js";
import AudioDisposeController from "../controllers/AudioDisposeController.js";
import AudioController from "../controllers/AudioController.js";
import SequenceRenderer from "../controllers/SequenceRenderer.js";
import AudioEffectController from "../controllers/AudioEffectController.js";
import BranchController from "../controllers/BranchController.js";
import MenuController from '../controllers/MenuController.js';
import AnalyticsController from '../controllers/AnalyticsController.js';
import StorageController from '../controllers/StorageController.js';
import ABDisposeController from '../controllers/ABDisposeController.js';

import Scene from './Scene.js';

import Sequence from '../objects/Sequence.js';
import SVGFrame from '../objects/SVGFrame.js';
import PointState from '../objects/PointState.js';
import { makeNoise2D } from "open-simplex-noise";

import DreamUI from "../DreamUI.js";

import HueController from '../controllers/HueController.js';


var presetFolder = 'data/presets/sequences/';
var audioFolder = "audio/timeline/";
var AUDIO_DIVISION_DURATION = 60.0;
var AUDIO_DIVISION_OVERLAP = 0.5;

var audioContext = window.audioContext = window.audioContext||new AudioContext();

var defaultTransitionParams = {
	duration: 1.0
};




class TimelineScene extends Scene {

	constructor() {
		super()
		SETTINGS.TIMELINE_MODE = true;

		this.disposed = false;
		this.presetFolder = presetFolder;
		this.sequences = [];
		this.currentSequence = 0;
		this.targetSequence = 0;
		this.buffering = false;
		this.bufferingDivShown = false;
		this.shouldTransition = false;
		this.transitionDelay = 0;
		this.currentPageId = 0;
		this.introSequences = [];

		this.isOnIntro = SETTINGS.INTRO_MODE;
		this.currentIntro = 0;
		this.targetIntro = 1;
		this.introHasClicked = false;
		this.introFinishingHasDragged = false;
		this.introStartTime = 0;
		this.introFinishing = false;
		
		this.introManualTransition = false;
		this.introTransitionStarted = false;
		this.introTransitionHalfway = false;
		this.introTransitionStartTime = 0;

		this.analyticsTimeSent = -1;
		this.storageTimeSet = -1;
		this.randomPresetSelected = -1;
		this.installationReloadSent = false;
		this.loadedEventTracked = false;




		//-----------
		//
		// Debug UI
		//
		//---------
		window.DreamUI = DreamUI;
		this.hasDatGUI = false;
		this.options = {
			"play/pause": () => {
				AudioController.timelineStarted = !AudioController.timelineStarted;
				AudioController.startContext(() => {
					AudioEffectController.setup();
					if (SETTINGS.JUMP_TIME) {
						AudioController.seek(Utils.timecodeToSeconds(SETTINGS.JUMP_TIME));
					}
				});
			},
			currentTime: "00:00",
			progress: 0.0,

			sequence: {
				id: "none",
				preset: "none",
				progress: 0.0,
				loop: false
			},
			audio: {
				id: "none",
				preset: "none",
				progress: 0.0
			}
		};
		this.defaultOptions = Utils.cloneInto(this.options.sequence, {});

		this.ranges = {
			"play/pause": {isButton: true},
			currentPreset: {isText: true},
			currentTime: {isText: true},
			progress: {isSlider: true, min: 0.0, max: 1.0, callback: (time, dragging)=> {
				if (!dragging) return;
				AudioController.seek(this.options.progress * AppStatus.AUDIO_DURATION);
			}},
			sequence: {
				isFolder: true,
				isOpen: !SETTINGS.isMobile,
				id: {isText: true},
				preset: {isText: true},
				progress: {isSlider: true, min: 0.0, max:1.0, callback: (time, dragging)=> {
					if (!dragging) return;
					AudioController.seek(Utils.cmap(this.options.sequence.progress, 0.0, 1.0, this.sequences[this.currentSequence].timecode, this.sequences[this.currentSequence].timeEnd));
				}},
				loop: {isBoolean: true}
			},
			audio: {
				isFolder: true,
				isOpen: !SETTINGS.isMobile,
				id: {isText: true},
				preset: {isText: true},
				progress: {isSlider: true, min: 0.0, max:1.0, callback: (time, dragging)=> {
					if (!dragging || !AudioEffectController.audioStarted) return;
					AudioController.seek(Utils.cmap(this.options.audio.progress, 0.0, 1.0, AudioEffectController.currentTimeStart, AudioEffectController.currentTimeEnd));
				}}
			}
		};

		// this.guiCallbacks = Utils.cloneIntoAdd({}, this.guiCallbacks||{});

		DreamUI.autofillEnabled = true;
		if (SETTINGS.SHOW_UI) DreamUI.init(this.options, this.ranges);


		if (!SETTINGS.INTRO_MODE) {
			$('#loader-content').css('display', 'flex').fadeIn(0).css('display', 'flex');
			$('#loader').toggleClass('fadein-fast', true).toggleClass('fadeout-fast', false);
		} else {
			$('#instructions-enter').on('click', ()=>{
				if (!AppStatus.mainLoaderShown && performance.now()>AppStatus.mainSceneReadyTime) {
					this.introHasClicked = true;
					console.log("ENTER!");
				}
			});
			$('#instructions-arrow').on('click', ()=>{
				if (!AppStatus.mainLoaderShown) this.introHasClicked = true;
			});

			if (!SETTINGS.isMobile) {
				$('#instructions-enter').toggleClass('simple-toggle', false);
				$('#instructions-arrow').toggleClass('simple-toggle', false);
				$('#instructions-enter').toggleClass('pressable', true);
				$('#instructions-arrow').toggleClass('pressable', true);
			}
		}
	

		//
		// setup controllers
		//
		AudioController.setup();
		if (SETTINGS.JUMP_TIME) {
			AudioController.seek(Utils.timecodeToSeconds(SETTINGS.JUMP_TIME));
		} else if (SETTINGS.RELOAD_ENABLED) {
			var reloadedState = StorageController.getState();
			if (reloadedState) {
				console.log("RELOADED:",reloadedState);
				AppStatus.reloaded = true;
				this.isOnIntro = false;
				AppStatus.isOnIntro = false
				AudioController.pausedForMenu = true;
				SETTINGS.INTRO_MODE = false;
				AppStatus.pausePresetReload = true;
				
				

				var currentNow = Date.now();
				if (SETTINGS.RELOAD_24H) currentNow = Date.now() + 24 * 60 * 60 * 1000;
				if (currentNow - (reloadedState.dateTime||0) >= 24 * 60 * 60 * 1000) {
					console.log("Reloaded page from a while ago. Welcome back!");

					$('#pause-container').toggleClass('reload-old', true);
					$('#pause-img').toggleClass('reload-old', true);
					$('#pause-timecode-container').toggleClass('reload-old', true);
					$('#pause-restart-container').toggleClass('reload-old', true);
					if (SETTINGS.isMobile && !SETTINGS.INSTALLATION_MODE && !SETTINGS.PRESENTATION_MODE) {
						$('#pause-headphones').css('background-image',"url("+SETTINGS.ASSETS_URL+"images/ui/pause_long_headphones_"+(SETTINGS.isIOS?'iphone':'android')+"_"+(SETTINGS.LANGUAGE)+"_2.png)");
						$('#pause-headphones').toggleClass(SETTINGS.isIOS?'ios':'android');

					}

					AnalyticsController.trackEvent("return_page_reloaded_24h");
					AppStatus.reload24h = true;

				} else {
					$('#pause-container').toggleClass('reload', true);
					$('#pause-timecode-container').toggleClass('reload', true);

					AnalyticsController.trackEvent("return_page_reloaded");
					AppStatus.reload24h = false;
				}

				//AnalyticsController.trackEvent("got_focus");


				AppStatus.isOnLandingPage = false;
				AppStatus.SELECTED_DURATION_LONG = reloadedState.longDuration;
				AudioController.timelineStarted = true;
				AudioController.seek(reloadedState.timecode);



				setTimeout(MenuController.pause,900);
				setTimeout(MenuController.show,1000);

			} else {
				//console.log("NOT RELOADED:",reloadedState);
			}
		}


		


		//
		// Focus handling
		//
		if (!SETTINGS.INSTALLATION_MODE) {
			$(document).on('visibilitychange', this.pauseVisibility);
			$(window).on('blur', this.blur);
			$(window).on('focus', this.pauseFocus);
			if (!SETTINGS.isMobile && SETTINGS.isSafari) $(window).on('pagehide', this.blur);	
		}

		//
		// Reload cleanup
		//
		window.onunload = window.onbeforeunload = this.cleanup.bind(this);
		window.addEventListener("beforeunload", this.cleanup.bind(this), false);
	}
	blur() {
		if (SETTINGS.IS_LOCAL || SETTINGS.INSTALLATION_MODE) return;
		AppStatus.lostFocus = true;
		AppStatus.pausePresetReload = true;
		$('#pause-container').toggleClass('reload', true);
		$('#pause-timecode-container').toggleClass('reload', true);
		if (!AppStatus.isOnLandingPage && !AppStatus.isOnEndPage && !AppStatus.isOnIntro && !AudioController.pausedForMenu && !MenuController.shown) {
			MenuController.pause();
			MenuController.show();
			AudioController.pausedForMenu = true;
			InteractionController.pressedThisFrame = false;
			//AnalyticsController.trackEvent("lost_focus");

			// if (SETTINGS.RELOAD_ENABLED && !AppStatus.isOnIntro && !AppStatus.isOnLandingPage) {
			// 	StorageController.setState(AudioController.getCurrentTime());
			// }

		} else if (AppStatus.isOnLandingPage || AppStatus.isOnEndPage || AppStatus.isOnIntro) {
			if (AudioEffectController.postGain) AudioEffectController.postGain.gain.tweenToValue(0,0.2);
		}
	}
	pauseVisibility() {
		if (SETTINGS.IS_LOCAL || SETTINGS.INSTALLATION_MODE) return;
		console.log("VISIBILITY CHANGE",document.hidden);
		if (document.hidden) {
			AppStatus.lostFocus = true;

			// if (SETTINGS.RELOAD_ENABLED && !AppStatus.isOnIntro && !AppStatus.isOnLandingPage) {
			// 	StorageController.setState(AudioController.getCurrentTime());
			// }
			AppStatus.pausePresetReload = true;
			$('#pause-container').toggleClass('reload', true);
			$('#pause-timecode-container').toggleClass('reload', true);

			//pause
			if (!AppStatus.isOnLandingPage && !AppStatus.isOnEndPage && !AppStatus.isOnIntro && !AudioController.pausedForMenu && !MenuController.shown) {
				MenuController.pause();
				MenuController.show();
				AudioController.pausedForMenu = true;
				InteractionController.pressedThisFrame = false;
				//AnalyticsController.trackEvent("lost_focus");
			} else if (AppStatus.isOnLandingPage || AppStatus.isOnEndPage || AppStatus.isOnIntro) {
				if (AudioEffectController.postGain) AudioEffectController.postGain.gain.tweenToValue(0,0.2);
			}
		} else {
			//resume
			AppStatus.lostFocus = false;
		}
	}
	pauseFocus() {
		AppStatus.lostFocus = false;
	}


	//preload json data
	prePreloadData(batchName) {
		this.timelineInfo = Loader.addXHR(batchName, SETTINGS.TIMELINE_FILE, 'json5');
		SequenceRenderer.preloadData(batchName);



	}

	//preload json data
	preloadData(batchName) {
		super.preloadData(batchName);


		for (var o in AppStatus.presetsBundle.value) {
			var opt = AppStatus.presetsBundle.value[o].options;
			// if (opt.effects && opt.effects.oignonSkin && opt.effects.oignonSkin.enabled && opt.effects.oignonSkin.color<1) {
			// 	console.log(o, opt.effects.oignonSkin.enabled, opt.effects.oignonSkin.color)
			// }
			// if (opt && opt.effects && opt.effects.digitalPrototype && opt.effects.digitalPrototype.enabled) {
			// 	console.log(o, opt.effects.digitalPrototype)
			// } else {
			// 	// console.log("disabled:",opt.effects.digitalPrototype);
			// }
		}

		SequenceRenderer.preload(batchName);
		this.timelineInfo = this.timelineInfo.value || this.timelineInfo;
		AppStatus.timelineInfo = this.timelineInfo;
		if (SETTINGS.INSTALLATION_MODE||SETTINGS.PRESENTATION_MODE) this.timelineInfo.intro = this.timelineInfo.intro_installation;


		var t = 0;
		if (SETTINGS.FAST_DEBUG_MODE) {
			AppStatus.SELECTED_DURATION_LONG = true;
			var n = 0;
			var newSeq = {};
			for (var o in AppStatus.timelineInfo.sequences) {
				var time = Utils.secondsToTimecode((n++) * 1.5);

				t++;
				newSeq[time] = AppStatus.timelineInfo.sequences[o];
				// newSeq[time].preset = (t % 20 == 0) ? "timeline_v1/0_2_title_fr_3g.json" : newSeq[time].preset;
				// if (newSeq[time].passive) newSeq[time].passive = (t % 2 == 0) ? "timeline_v1/0_2_title_fr_3g.json" : "timeline_v1/0_2_title_en_3g.json";

				// newSeq[time].preset = (t % 4 == 0) ? "timeline_v1/31_1_overload_a_notouch_4_vec.json" :
				// 					 (t % 4 == 1) ? "timeline_v1/32_1_chemins_3c_notouch_1.json" :
				// 					 (t % 4 == 2) ? "timeline_v1/32_1_chemins_3q_notouch_1.json" :
				// 					 "timeline_v1/0_2_title_fr_3g.json";
				// if (newSeq[time].passive) newSeq[time].passive = (t % 2 == 0) ?  : 				
			}
			AppStatus.timelineInfo.sequences = newSeq;
			var totalTime = n*1.5;


			var numAudio = Object.keys(AppStatus.timelineInfo.audio).length;
			n=0;
			var newAudio = {};
			for (var o in AppStatus.timelineInfo.audio) {
				var time = Utils.secondsToTimecode((n++) * totalTime/numAudio);
				newAudio[time] = AppStatus.timelineInfo.audio[o];
				// newAudio[time].preset = "none";
			}
			AppStatus.timelineInfo.audio = newAudio;


			for (var i=0; i<AppStatus.timelineInfo.audio_divisions.length; i++) {
				AppStatus.timelineInfo.audio_divisions[i] = (i/AppStatus.timelineInfo.audio_divisions.length) * totalTime;
				console.log(AppStatus.timelineInfo.audio_divisions[i]);
			}
			AppStatus.timelineInfo.AUDIO_DURATION = totalTime;
		}

			
		// for (var o in AppStatus.timelineInfo.audio) {
		// 	if (SETTINGS.INSTALLATION_MODE && AppStatus.timelineInfo.audio[o].) AppStatus.timelineInfo.audio
		if (this.timelineInfo.AUDIO_DURATION) AppStatus.AUDIO_DURATION = this.timelineInfo.AUDIO_DURATION;
		if (this.timelineInfo.voice) AudioController.setAudioFile(this.timelineInfo.voice, this.timelineInfo.audio_divisions);

		if (AppStatus.reloaded) MenuController.pause();


		this.earlyEndSequence0 = null;
		this.earlyEndSequence0a = null;

		this.earlyEndSequence1 = null;
		this.earlyEndSequence2 = null;
		this.earlyEndSequence3 = null;

		this.pauseSequence = new Sequence(0, {preset: this.timelineInfo.pausePreset, id:"pause", isBranch:false, isPause: true });
		this.pauseSequence.preloadData(batchName);

		this.reloadSequence = new Sequence(0, {preset: this.timelineInfo.reloadPreset, id:"reload", isBranch:false, isPause: true, isReload: true });
		this.reloadSequence.preloadData(batchName);
	
		//
		// Create all sequences
		//
		for (var timecode in this.timelineInfo.sequences) {

			var info = this.timelineInfo.sequences[timecode];
			if (info.installation && SETTINGS.INSTALLATION_MODE) info.preset = info.installation;

			if (info.branch) {
				var branchInfo = info.branch;
				var seq = new Sequence(Utils.timecodeToSeconds(timecode)-0.05, branchInfo);
				seq.preloadData(batchName);
				this.sequences.push(seq);

				if (branchInfo.early_end) {
					this.earlyEndSequence0 = new Sequence(0, {preset: branchInfo.preset_end_0, id:"end_0", isBranch:true, autoTime: true});
					this.earlyEndSequence0.preloadData(batchName);

					this.earlyEndSequence0a = new Sequence(0, {preset: branchInfo.preset_end_0a, id:"end_0a", isBranch:true, autoTime: true});
					this.earlyEndSequence0a.preloadData(batchName);

					this.earlyEndSequence1 = new Sequence(0, {preset: branchInfo.preset_end_1, id:"end_1", isBranch:true, autoTime: true});
					this.earlyEndSequence1.preloadData(batchName);

					this.earlyEndSequence2 = new Sequence(0, {preset: branchInfo.preset_end_2, id:"end_2", isBranch:true, autoTime: true});
					this.earlyEndSequence2.preloadData(batchName);

					this.earlyEndSequence3 = new Sequence(0, {preset: branchInfo.preset_end_3, id:"end_3", isBranch:true, autoTime: true, theEnd: true});
					this.earlyEndSequence3.preloadData(batchName);
				}
			}


			var seq = new Sequence(Utils.timecodeToSeconds(timecode), info);
			seq.preloadData(batchName);
			this.sequences.push(seq);

			if (seq.params.passive) {
				var altInfo = Utils.clone(info);
				altInfo.preset = altInfo.passive;
				var altSeq = new Sequence(Utils.timecodeToSeconds(timecode), altInfo);
				altSeq.preloadData(batchName);
				altSeq.pageId = PageLoader.currentPageId;
				seq.passiveAltSequence = altSeq;
				altSeq.activeAltSequence = seq;
			}
		}

		//sort and add durations
		this.sequences = this.sequences.sort(function(a,b) {
			return a.timecode < b.timecode ? -1 : 1;
		});
		for (var i=0; i<this.sequences.length-1; i++) {
			this.sequences[i].duration = this.sequences[i+1].timecode - this.sequences[i].timecode;
			this.sequences[i].timeEnd = this.sequences[i].timeStart + this.sequences[i].duration;
			this.sequences[i].arrayId = i;

			//set first preset for pause
			if (AppStatus.reloaded) {
				if (AudioController.getCurrentTime()>=this.sequences[i].timeStart && AudioController.getCurrentTime()<this.sequences[i].timeEnd) {
					this.sequences[i].params.firstPreset = true;
				}
			}
		}
		this.sequences[this.sequences.length-1].duration = 10000000; // - this.sequences[i].timecode;
		this.sequences[this.sequences.length-1].timeEnd = this.sequences[this.sequences.length-1].timeStart + this.sequences[this.sequences.length-1].duration;


		//
		// Add all intro sequences
		//
		var ct = 0;
		if (SETTINGS.INTRO_MODE) {


			for (var i =0; i< this.timelineInfo.intro.sequences.length; i++) {
				var info = this.timelineInfo.intro.sequences[i];

				if (info.onlyLive && !(SETTINGS.LIVE_CINEMA_MODE || SETTINGS.PRESENTATION_MODE)) {
					info = null;
				} else {
					if (info.installation && SETTINGS.INSTALLATION_MODE) info.preset = info.installation;
					if (info.mobile && SETTINGS.isMobile) info.preset = info.mobile;
					if (info.android && SETTINGS.isMobile && !SETTINGS.isIOS) info.preset = info.android;
					if (info.iphone && SETTINGS.isMobile && SETTINGS.isIOS) info.preset = info.iphone;
					if (info.mobile && SETTINGS.isMobile) info.preset = info.mobile;
					if (info.desktop && !SETTINGS.isMobile) info.preset = info.desktop;
					if (info.randomPresets) {
						if (this.randomPresetSelected < 0) this.randomPresetSelected = Math.floor(Math.random()*info.randomPresets.length);
						info.preset = info.randomPresets[this.randomPresetSelected];
					}


					//installation add enter btn
					if (SETTINGS.PRESENTATION_MODE && i==0) {
						info.enterButton = true;
						info.duration = -1;
						info.durationMin = 1;
					}

					var seq = new Sequence(ct, info);
					seq.preloadData(batchName);
					seq.isIntro = true;
					seq.duration = info.duration;
					seq.timeEnd = ct + info.duration;
					seq.arrayId = i;
					this.introSequences.push(seq);

					ct += info.duration;
				}
			}
		}


		//
		// Setup installaiton
		//
		if (SETTINGS.INSTALLATION_MODE && SETTINGS.HUE_ENABLED) HueController.setup();

		//
		// Preload all audio presets
		//
		AudioEffectController.preloadData(batchName);
	}


	//preload all assets
	preload(batchName) {
		super.preload(batchName);

		//
		// other assets
		//
		BranchController.preload(batchName);

		//
		// For each sequence, create a batch, add svg and audio files
		// Don't autostart batch
		//
		var gotAudio2 = false;
		var allSequences = this.introSequences.concat(this.sequences);
		for (var i=0; i<allSequences.length; i++) {
			var seqBatchName = 'sequence'+this.currentPageId;
			PageLoader.addPageLoadState(false, {id:this.currentPageId, isIntro:allSequences[i].isIntro});
			allSequences[i].pageId = PageLoader.currentPageId;
			allSequences[i].preload(seqBatchName, PageLoader);


			if (allSequences[i].passiveAltSequence) {
				var altSeq = allSequences[i].passiveAltSequence;
				altSeq.duration = allSequences[i].duration;
				altSeq.timeEnd = allSequences[i].timeEnd;
				altSeq.arrayId = allSequences[i].arrayId;
				altSeq.pageId = PageLoader.currentPageId;
				altSeq.preload(seqBatchName, PageLoader);
			}

			if (allSequences[i].params.early_end) {
				this.earlyEndSequence0.pageId = PageLoader.currentPageId;
				this.earlyEndSequence0.preload(seqBatchName, PageLoader);
				this.earlyEndSequence0a.pageId = PageLoader.currentPageId;
				this.earlyEndSequence0a.preload(seqBatchName, PageLoader);
				this.earlyEndSequence1.pageId = PageLoader.currentPageId;
				this.earlyEndSequence1.preload(seqBatchName, PageLoader);
				this.earlyEndSequence2.pageId = PageLoader.currentPageId;
				this.earlyEndSequence2.preload(seqBatchName, PageLoader);
				this.earlyEndSequence3.pageId = PageLoader.currentPageId;
				this.earlyEndSequence3.preload(seqBatchName, PageLoader);
			}

			if (allSequences[i].isIntro && allSequences[i].params.audio2) gotAudio2 = true;

			if (!allSequences[i].isIntro) {
				AudioController.preloadTimeline(seqBatchName, PageLoader, allSequences[i]);
				AudioEffectController.preloadTimeline(seqBatchName, PageLoader, allSequences[i].timeStart, allSequences[i].timeEnd);
				BranchController.preloadTimeline(seqBatchName, PageLoader, allSequences[i]);
			} else if (SETTINGS.INTRO_MODE && !gotAudio2) {
				AudioEffectController.preloadIntro(seqBatchName, PageLoader, 0);
			} else if (SETTINGS.INTRO_MODE) {
				AudioEffectController.preloadIntro(seqBatchName, PageLoader, 1);
			}

			if (allSequences[i].params.firstPreset || allSequences[i].params.theEnd) {
				this.pauseSequence.pageId = PageLoader.currentPageId;
				this.pauseSequence.preload(seqBatchName, PageLoader);

				this.reloadSequence.pageId = PageLoader.currentPageId;
				this.reloadSequence.preload(seqBatchName, PageLoader);
			}
		
			PageLoader.popPageLoadState();
			this.currentPageId++;
		}
	}





	//setup interaction and events
	start() {
		super.start();

		if (SETTINGS.INTRO_MODE) {
			AppStatus.isOnLandingPage = true;

			if (SETTINGS.INSTALLATION_MODE) {//} && (!SETTINGS.isMobile || (window.webkit && window.webkit.messagesHandlers))) {
				console.log("Try to start context!")
				AudioController.startContext(() => {
				});
			}

			AudioEffectController.setup();
			$(document.body).on("click.first", (e) => {
				console.log("Start context!")
				AudioController.startContext(() => {
					// AudioEffectController.setup();
				});
				$(document.body).off('click.first');
			});

		} else if (!AppStatus.reloaded) {
			AppStatus.isOnLandingPage = false;
			// if (!SETTINGS.isMobile) {
			// 	$(this.startDiv).remove();
			// 	AudioController.timelineStarted = !AudioController.timelineStarted;
			// 	AudioController.startContext(() => {
			// 		AudioEffectController.setup();
			// 		if (SETTINGS.JUMP_TIME) {
			// 			AudioController.seek(Utils.timecodeToSeconds(SETTINGS.JUMP_TIME));
			// 		}
			// 	});
			// } else {
				$('#loader').toggleClass('fadein-fast', false).toggleClass('fadeout-fast', true);
				this.startDiv = document.createElement('div');
				$(this.startDiv).html('').css({
					display: "grid",
					position: 'fixed',
					// top: '50%',
					// left: '50%',
					"text-align": "center",
					"font-size": "3em",
					'background-color': "white",
					height:"100%",
					width:"100%",
					display: "grid",
					  "align-items": "center",
					  "justify-content": "center",
					"z-index": 1000,
					"cursor": "pointer"
					// transform: "translate(-50%, 0%)"
				}).appendTo(document.body);

				$(this.startDiv).html('Cliquez pour commencer')
				$(this.startDiv).on('click', (e) => {
					AudioController.timelineStarted = !AudioController.timelineStarted;
					AudioController.startContext(() => {
						AudioEffectController.setup();

						if (SETTINGS.JUMP_TIME) {
							AudioController.seek(Utils.timecodeToSeconds(SETTINGS.JUMP_TIME));
						}

					});
					$(this.startDiv).off('touchstart');
					$(this.startDiv).fadeOut(100);
				})

				// .on("touchstart.first", (e) => {
				// 	AudioController.timelineStarted = !AudioController.timelineStarted;
				// 	AudioController.startContext(() => {
				// 		AudioEffectController.setup();

				// 		if (SETTINGS.JUMP_TIME) {
				// 			AudioController.seek(Utils.timecodeToSeconds(SETTINGS.JUMP_TIME));
				// 		}

				// 	});
				// 	$(this.startDiv).off('touchstart.first');
				// 	$(this.startDiv).remove();
				// });
			// }
		} else if (AppStatus.reloaded) {
			AppStatus.mainLoaderShown = true;
			AppStatus.mainLoaderShownTime = performance.now()
			AudioEffectController.setup();
			$(document.body).on("click.first", (e) => {
				console.log("Start context!")
				AudioController.startContext(() => {
					// AudioEffectController.setup();
				});
				$(document.body).off('click.first');
			});
		}
		

		MenuController.init();
		if (SETTINGS.INTRO_MODE) MenuController.show();
		SequenceRenderer.setup();
	}


	//main loop
	update(delta) {
		super.update(delta);
		if (this.cleanedUp) return;
	
		var now = performance.now(),
			opt = this.options;

		//
		// Update playback
		//
		var timeDelta = (now - this.lastTime) / 1000;
		this.lastTime = now;
		this.currentTime += timeDelta;


		//unpause
		if (AudioController.pausedForMenu && SETTINGS.INSTALLATION_MODE && now - MenuController.pauseStartTime >= 30 * 1000) {
			MenuController.resume();
		}

		//
		// Update loading : make sure all svg are loaded before proceding
		//
		this.sequence = this.sequences[this.currentSequence];
		if (AppStatus.reloaded) PageLoader.update(this.sequence.pageId, true);
		if (AudioController.pausedForMenu) {
			this.sequence = (AppStatus.pausePresetReload||SETTINGS.INSTALLATION_MODE) ? this.reloadSequence : this.pauseSequence;
		}
		if (this.isOnIntro) this.sequence = this.introSequences[this.currentIntro];

		if (BranchController.currentSequenceIsEarlyEnd(0)) this.sequence = this.earlyEndSequence0; 
		if (BranchController.currentSequenceIsEarlyEnd(1)) this.sequence = this.earlyEndSequence0a; 
		if (BranchController.currentSequenceIsEarlyEnd(2)) this.sequence = this.earlyEndSequence1; 
		if (BranchController.currentSequenceIsEarlyEnd(3)) this.sequence = this.earlyEndSequence2; 
		if (BranchController.currentSequenceIsEarlyEnd(4)) this.sequence = this.earlyEndSequence3; 


		if (AppStatus.reloaded) {
			 PageLoader.update(this.sequence.pageId, true);
		}
		if (!AudioController.pausedForMenu) PageLoader.update(this.sequence.pageId, this.shouldTransition || this.buffering || this.transitionDelay>0);
		SequenceRenderer.update(this.sequence.options, this.getCurrentProgress(), delta);
		BranchController.update();

		if (SETTINGS.NFB_MENU && !MenuController.nfbHeaderCustomized) MenuController.customizeNFBMenu();

		
		// this.sequences[this.currentSequence].prepare();

		this.buffering = false;
		this.shouldTransition = false;

		//allow for 
		if (AppStatus.isOnLandingPage || this.sequence.pageId==0 || AppStatus.reloaded) {
			AppStatus.mainSceneReady = this.sequence.isReady();
			// if (AppStatus.reloaded) this.sequences[this.currentSequence]

			if (this.introSequences.length>=0 && AppStatus.isOnLandingPage && SETTINGS.INTRO_MODE) AppStatus.mainSceneReady = this.sequence.isReady() && this.introSequences[1].isReady(); // && this.introSequences[2].isReady();

			if (AppStatus.mainSceneReady && AppStatus.mainLoaderShown && (now-AppStatus.mainLoaderShownTime > 1100 || now < 4500)) {
				AppStatus.mainLoaderShown = false;
				AppStatus.mainSceneReadyTime = performance.now()+500;
				$('#loader-content').fadeOut(500);
				$('#loader').toggleClass('fadein', false).toggleClass('fadeout', true);
				console.log("FADEOUT!", now-AppStatus.mainLoaderShownTime, now < 4500);
				// $('#logos-background').remove();

				$('#logos-background').toggleClass('black', false);
				$('#logos-background').toggleClass('shown', false).toggleClass('fadeout', true);
				setTimeout(()=>{$('#logos-background').remove();},1100);
			}
			//  else if (!AppStatus.mainSceneReady && !AppStatus.mainLoaderShown && AppStatus.logosFinished) {
			// 	console.log("FADEIN2!");
			// 	AppStatus.mainLoaderShownTime = now;
			// 	AppStatus.mainLoaderShown = true;
			// 	$('#loader').toggleClass('fadein', true);
			// }

			// if (!AppStatus.mainLoaderShown && AppStatus.logosFinished && !MenuController.shown) MenuController.show();
		}


		//---------------
		//
		// Update and transition forward for intro sequences
		//
		//---------------
		if (this.sequence.isReady()) {

			if (!this.loadedEventTracked) {
				this.loadedEventTracked = true;
				AnalyticsController.trackEvent("splash");
			}

			if (this.isOnIntro) {
				AppStatus.isOnIntro = true;

				if (!this.sequence.intoStarted && this.sequence.isReady()) {
					this.sequence.introStartTime = now;
					this.sequence.intoStarted = true;
					this.introHasClicked = false;
					console.log("READY!", this.sequence.introStartTime, this.sequence.params.duration);
					if (this.sequence.params.enterButton) {
						$('#instructions-enter-container').css('opacity',0).show().animate({ opacity: 1 }, 300);
						if (SETTINGS.NFB_MENU) {
							if (SETTINGS.isMac) {
								$('#intro-aboutbtn-container').toggleClass('mac', true);
							}
							$('#intro-aboutbtn-container').css('opacity',0).show().animate({ opacity: 1 }, 300);
						}
					}
				}

				if (!this.sequence.params.onRelease && (SequenceRenderer.pressedThisFrame || (SETTINGS.isMobile && SequenceRenderer.mouseIsDown && SequenceRenderer.currentDragSpeed.length()>0.2) || InteractionController.scrolledThisFrame)) {
					this.introHasClicked = true;
				}
				if (this.sequence.params.onRelease && (SequenceRenderer.releasedThisFrame||(!SequenceRenderer.pressedThisFrame&&SequenceRenderer.isDragging&&((!SETTINGS.isMobile && now-SequenceRenderer.mouseMoveStart>1500.0) || (SETTINGS.isMobile && now-SequenceRenderer.lastPressTime>1500)) && SequenceRenderer.currentDragSpeed.length()>0.05))) {
					this.introHasClicked = true;
				}

				// if (this.sequence.params.onRelease && SETTINGS.INSTALLATION_MODE && SequenceRenderer.simulatorEnabled) {
				// 	this.introHasClicked = true;
				// 	this.introManualTransition = true;
				// }

				// if (this.sequence.params.introFin) console.log(this.introFinishing, now-SequenceRenderer.mouseMoveStart, now-SequenceRenderer.lastPressTime);
				if (this.sequence.params.introFin && !SETTINGS.isMobile && SequenceRenderer.isDragging && (now-SequenceRenderer.mouseMoveStart >= 100)) {
					this.introFinishingHasDragged = true;
				}
				if (this.sequence.params.introFin && !this.introTransitionStarted && this.introFinishingHasDragged && now-SequenceRenderer.mouseMoveStart>1000.0) {
					this.introManualTransition = true;
				}

				//should start transition check
				if ((now-this.sequence.introStartTime)/1000 >= this.sequence.params.duration && this.sequence.params.duration > 0) {
					this.shouldTransition = true;
				}
				if (!this.shouldTransition && !this.introManualTransition && !this.sequence.params.timeSelection && this.sequence.params.onClick && this.introHasClicked && (now-this.sequence.introStartTime)/1000 > this.sequence.params.durationMin) {
					this.introManualTransition = true;
				}
				if ((SETTINGS.INSTALLATION_MODE||SETTINGS.PRESENTATION_MODE) && this.sequence.params.durationAutoStart && (now-this.sequence.introStartTime)/1000 > 4) {
					this.introManualTransition = true;
					this.introHasClicked = true;
				} 


				if (!this.shouldTransition && !this.introManualTransition && (this.sequence.params.enterButton || this.sequence.params.arrowButton)  && this.introHasClicked) this.introManualTransition = true;
				if (this.introManualTransition) {
					this.shouldTransition = true;
					if (!AppStatus.beginSent) {
						AppStatus.beginSent = true;
						AnalyticsController.trackEvent("begin");
					}
				}

				if (!this.introTransitionStarted && MenuController.infoPageOpen) this.shouldTransition = false;

				//start transition
				if (!this.introTransitionStarted && this.shouldTransition) {
					this.targetIntro = this.currentIntro+1;
					this.transitionSequence = this.introSequences[this.targetIntro];

					if (this.targetIntro>=this.introSequences.length) {
						this.transitionSequence = this.sequences[0];
						this.introFinishing = true;
						if (this.transitionSequence.params.audio3 || this.sequence.params.audio3) AudioEffectController.introAudio = 3;
					}

					//buffering if target is not loaded
					PageLoader.update(this.targetSequence, true);

					if (!this.transitionSequence.isReady()) {
						this.shouldTransition = false;
						console.log("Intro buffering");
					} else {
						// console.log("Intro begin transition",this.currentIntro, this.targetIntro);
						this.introTransitionStarted = true;
						this.introTransitionHalfway = false;
						this.introTransitionStartTime = now;
						
						//transition sequence starts after transition duration
						if (!this.introFinishing)
							this.transitionSequence.update(0,0,0, true);
						else
							this.transitionSequence.update(this.sequence.params.transition, Utils.ccmap(this.sequence.params.transition, this.transitionSequence.timeStart, this.transitionSequence.timeEnd, 0.0, 1.0), 0, true);

						//begin transition
						this.sequence.beginTransition(this.transitionSequence, {});
						this.transitionSequence.beginTransition(this.sequence, {}, true);
						if (this.sequence.params.audio2) {
							AudioEffectController.introAudioTarget = 2;
							AudioEffectController.introAudioTransitionStart = now;
							AudioEffectController.introAudioTransitionEnd = now + AppStatus.timelineInfo.intro.audio2_transition*1000;
						}

						if (!AppStatus.beginSent) {
							AppStatus.beginSent = true;
							AnalyticsController.trackEvent("begin");
						}
						AppStatus.isOnLandingPage = false;

						if (this.sequence.params.ecouteurs) {
							$('#instructions-headphones-container').fadeOut(300);
						}
						if (this.sequence.params.arrowButton && !this.transitionSequence.params.arrowButton) {
							$('#instructions-arrow-container').fadeOut(300);
						}
						if (this.sequence.params.enterButton) {
							$('#instructions-enter-container').fadeOut(300);
						}
						if (this.transitionSequence.params.arrowButton && !this.sequence.params.arrowButton) {
							$('#instructions-arrow-container').show().animate({ opacity: 1 }, 100);
						}
						if (this.introFinishing) {
							AudioController.timelineStarted = true;
						}
					}
				}


				//other ui
				if (this.introTransitionStarted && !this.introTransitionHalfway && !this.buffering &&  (now-this.introTransitionStartTime)/1000 >= this.sequence.params.transition/2) {
					this.introTransitionHalfway = true;

					
					if (this.sequence.params.glissez) {
						$('#instructions-glissez-container').fadeOut(300);
					}
					if (this.transitionSequence.params.ecouteurs) {
						$('#instructions-headphones-container').css('opacity',0).show().animate({ opacity: 1 }, 300);
					}
					if (this.transitionSequence.params.glissez) {
						$('#instructions-glissez-container').css('opacity',0).show().animate({ opacity: 1 }, 300);
						SequenceRenderer.simulationModeLocked = false;
					}					

					if (MenuController.shown && !MenuController.infoPageOpen && this.transitionSequence.params.hideMenu) {
						AppStatus.startMenuShown = false;
						MenuController.hideSlow(1.0);
						if (SETTINGS.NFB_MENU) $('#intro-aboutbtn-container').fadeOut(500);
					}


					//time selection dialog show + add events
					if (this.transitionSequence.params.timeSelection) {
						console.log("SHOW TIMESELECT");
						$('#timeselect-buttons-container').css('opacity',0).css('display','flex').animate({ opacity: 1 }, 300);
						$('#timeselect-short').on('click', ()=>{
							$('#timeselect-short').toggleClass('pressed',true);
							this.introManualTransition = true;
							AppStatus.SELECTED_DURATION_LONG = false;

							$('#timeselect-short').css('pointer-events', 'none').off('click');
							$('#timeselect-long').css('pointer-events', 'none').off('click');
							$('#timeselect-buttons-container').fadeOut(250);

							AnalyticsController.trackEvent("timeselect_5minutes");
						});
						$('#timeselect-long').on('click', ()=>{
							$('#timeselect-long').toggleClass('pressed',true);
							this.introManualTransition = true;
							AppStatus.SELECTED_DURATION_LONG = true;

							$('#timeselect-short').css('pointer-events', 'none').off('click');
							$('#timeselect-long').css('pointer-events', 'none').off('click');
							$('#timeselect-buttons-container').fadeOut(250);


							AnalyticsController.trackEvent("timeselect_19minutes");
						});
					}

					SequenceRenderer.mouseMoveStart = now;
				}


				if (this.introTransitionStarted && (now-this.introTransitionStartTime)/1000 >= this.sequence.params.transition) this.shouldTransition = false;

				//end transition
				if (!this.shouldTransition && !this.buffering && this.introTransitionStarted) {
					SequenceRenderer.mouseMoveStart = now;

					this.introTransitionStarted = false;
					this.introManualTransition = false;
					
					this.sequence.endTransition(true);
					if (this.transitionSequence) this.transitionSequence.endTransition();
					this.currentIntro = this.targetIntro;

					AnalyticsController.trackEvent("intro_part_"+this.currentIntro);

					this.sequence = this.transitionSequence;

					if (!this.introFinishing) {
						this.sequence.update(0, 0, delta);
						SequenceRenderer.update(this.sequence, 0, delta);
						this.sequence.update(0, 0, delta);
					} else {
						//first sequence update @ right time
						this.sequence.update(AudioController.getCurrentTime()-this.sequence.timeStart, this.getCurrentProgress(), 0);
						SequenceRenderer.update(this.sequence, 0, delta);
						this.sequence.update(AudioController.getCurrentTime()-this.sequence.timeStart, this.getCurrentProgress(), 0);
					}

					this.introHasClicked = false;
					this.sequence.introStartTime = now;
					this.sequence.intoStarted = true;
					this.transitionSequence = null;

					if (this.introFinishing) {
						this.isOnIntro = false;
						AppStatus.isOnIntro = false;
						this.currentSequence = 0;
						this.shouldTransition = false;
						AudioController.timelineStarted = true;
						AnalyticsController.trackEvent("intro_done");



						// SequenceRenderer.cleanOignon();
					}
				}

				//update current sequence


				if (this.isOnIntro) {
					var progressPc = Utils.cmap(now-this.sequence.introStartTime,0,this.sequence.params.duration*1000, 0.0, 1.0);
					var transitionPc = Utils.cmap(now-this.introTransitionStartTime,0, this.sequence.params.transition*1000+16, 0.0, 1.0);
					if (isNaN(progressPc))progressPc = 1.0;
					this.sequence.forcedTransitionPc = transitionPc;
					this.sequence.update(progressPc*(this.sequence.params.duration||0), progressPc, delta);

				} else {
					this.sequence.update(AudioController.getCurrentTime()-this.sequence.timeStart, this.getCurrentProgress(), delta);
				}


			//---------------
			//
			// Update and transition forward for main timeline
			//
			//---------------
			} else {

				
				if (!AudioController.pausedForMenu) {

					//begin transition
					this.shouldTransition = AudioController.getCurrentTime()-this.sequence.timeStart >= (this.sequence.duration - this.sequence.options.transition.duration);
					if (AudioController.getCurrentTime()<this.sequence.timeStart) this.shouldTransition = true;
					// if (AudioController.pausedForMenu) this.shouldTransition = false;

					//if next sequence is branch and almost done
					if (this.sequence.isBranch) {
						this.shouldTransition = BranchController.shouldTransitionOut();
					}


					if (this.sequence.params.ending) SequenceRenderer.simulationModeLocked = true;
					if (this.sequence.params.theEnd && AudioController.getCurrentTime()>=this.sequence.timeStart) {
						this.shouldTransition = false;
						MenuController.showEndDivs();
						AppStatus.isOnEndPage = true;
						SequenceRenderer.simulationModeLocked = true;

						if (!AppStatus.finished) {
							AppStatus.finished = true;
							StorageController.clearState();
						}
					} else if (this.sequence.params.theEnd && this.shouldTransition) {
						MenuController.hideEndDivs();
						AppStatus.isOnEndPage = false;

					}



					if (SETTINGS.IS_LOCAL && this.ranges.audio.progress.ui) this.ranges.sequence.progress.ui.setColor(this.sequence.transitionStarted ? '#aa3333' : '#2bbab6');

					if (this.shouldTransition && !this.sequence.transitionStarted) {
						this.targetSequence = this.currentSequence+1;
						for (var i = 0; i < this.sequences.length; i++) {
							if (AudioController.getCurrentTime()>=this.sequences[i].timeStart && AudioController.getCurrentTime()<this.sequences[i].timeEnd) {
								this.targetSequence = i;
								if (this.targetSequence < this.currentSequence) {
									console.warn("warning: going back",AudioController.getCurrentTime());
								}
							}
						}
						if (this.targetSequence < this.currentSequence) {
							console.warn("warning going back");
						}

						if (this.targetSequence == this.currentSequence) {
							this.targetSequence = this.currentSequence+1;
						}

						if (this.sequence.isBranch) {
							this.shouldTransition = BranchController.shouldTransitionOut();
							this.targetSequence = this.currentSequence+1;

						} else {
							//if next sequence is a branch and should skip
							if (this.sequences[this.targetSequence] && this.sequences[this.targetSequence].isBranch && BranchController.shouldSkipBranch(this.sequences[this.targetSequence])) {
								this.sequences[this.targetSequence].params.skipLocked = true;
								this.sequences[this.targetSequence].params.shouldSkip = true;
								this.targetSequence++;
							} else {
								this.sequences[this.targetSequence].params.skipLocked = true;
								this.sequences[this.targetSequence].params.shouldSkip = false;
							}
							this.transitionSequence = this.sequences[this.targetSequence+1];
						}

					
						if (this.sequences[this.targetSequence].passiveAltSequence && SequenceRenderer.passivePc > 0.85 && !SETTINGS.SCREEN_RECORD) {
							console.log("Selecting passive preset alternative",this.sequences[this.targetSequence].params.passive);
							this.sequences[this.targetSequence] = this.sequences[this.targetSequence].passiveAltSequence;
						}
						this.transitionSequence = this.sequences[this.targetSequence];
						if (BranchController.nextSequenceIsEarlyEnd(0) && BranchController.shouldTransitionOut()) this.transitionSequence = this.earlyEndSequence0; 
						if (BranchController.nextSequenceIsEarlyEnd(1) && BranchController.shouldTransitionOut()) this.transitionSequence = this.earlyEndSequence0a; 
						if (BranchController.nextSequenceIsEarlyEnd(2) && BranchController.shouldTransitionOut()) this.transitionSequence = this.earlyEndSequence1; 
						if (BranchController.nextSequenceIsEarlyEnd(3) && BranchController.shouldTransitionOut()) this.transitionSequence = this.earlyEndSequence2; 
						if (BranchController.nextSequenceIsEarlyEnd(4) && BranchController.shouldTransitionOut()) this.transitionSequence = this.earlyEndSequence3; 


						PageLoader.update(this.transitionSequence.pageId, true);

						if (this.transitionSequence && this.transitionSequence.isReady()) {

							if (!this.sequence.transitionStarted) {
								if (this.transitionSequence) this.transitionSequence.update(0,0,0, true);
								this.sequence.beginTransition(this.transitionSequence, {});
							}
							if (this.transitionSequence && !this.transitionSequence.transitionStarted) {
								this.transitionSequence.update(0,0,0, true);
								this.transitionSequence.beginTransition(this.sequence, {}, true);
							}

						} else {

							if (this.transitionSequence && !this.transitionSequence.isReady()) {
								this.buffering = true;
							}
						}
					}

					// if (!AudioEffectController.isReady()) this.buffering = true;

					//end transition
					if (AudioController.getCurrentTime()>=this.sequence.timeEnd || AudioController.getCurrentTime()<this.sequence.timeStart && !this.sequence.isBranch) this.shouldTransition = false;
					
					//end transition for branch!
					if (this.transitionSequence && this.transitionSequence.isBranch && BranchController.branchStarted && !BranchController.shouldTransitionOut()) {
						this.shouldTransition = false;
					}
					//end transition
					if (!this.shouldTransition && !this.buffering && this.sequence.transitionStarted) {
						// console.log("end transition", this.transitionSequence.id);
						this.sequences[this.currentSequence].endTransition(true);
						if (this.transitionSequence) this.transitionSequence.endTransition();
						this.currentSequence = this.targetSequence;
						this.transitionSequence = null;
						// if (SETTINGS.IS_LOCAL) this.ranges.progress.ui.setColor('#2bbab6');

						this.sequence = this.sequences[this.currentSequence];

						if (BranchController.currentSequenceIsEarlyEnd(0)) this.sequence = this.earlyEndSequence0; 
						if (BranchController.currentSequenceIsEarlyEnd(1)) this.sequence = this.earlyEndSequence0a; 
						if (BranchController.currentSequenceIsEarlyEnd(2)) this.sequence = this.earlyEndSequence1; 
						if (BranchController.currentSequenceIsEarlyEnd(3)) this.sequence = this.earlyEndSequence2; 
						if (BranchController.currentSequenceIsEarlyEnd(4)) this.sequence = this.earlyEndSequence3; 


						this.sequence.update(AudioController.getCurrentTime()-this.sequence.timeStart, this.getCurrentProgress(), delta);
						SequenceRenderer.update(this.sequences[this.currentSequence].options, this.getCurrentProgress(), delta);
						// this.sequence.update(AudioController.getCurrentTime()-this.sequence.timeStart, this.getCurrentProgress(), delta);
						this.hasEndedTransition = true;
					}

				}

				//update current sequence
				this.sequence.update(AudioController.getCurrentTime()-this.sequence.timeStart, this.getCurrentProgress(), delta);

				//
				// update time for analytics
				// 
				var analyticsTargetTime = Math.floor(AudioController.getCurrentTime()/30)*30;
				if (AudioController.getCurrentTime()<=60) analyticsTargetTime = Math.floor(AudioController.getCurrentTime()/10)*10;
				if (Math.abs(analyticsTargetTime-this.analyticsTimeSent) > 0.01) {
					this.analyticsTimeSent = analyticsTargetTime;
					AnalyticsController.trackEvent("time_"+Utils.secondsToTimecode(this.analyticsTimeSent));
				}


				if (SETTINGS.RELOAD_ENABLED) {
					var storageTargetTime = Math.floor(AudioController.getCurrentTime()/5)*5;
					if (Math.abs(storageTargetTime-this.storageTimeSet) > 0.01) {
						this.storageTimeSet = storageTargetTime;
						if (!this.sequence.isBranch && !BranchController.branchStarted && !AppStatus.finished) StorageController.setState(AudioController.getCurrentTime());
					}
				}
			}

		} else {
			this.buffering = true;
		}
		if (window.forceBuffering) this.buffering = true;

		if (!this.buffering && !this.shouldTransition) this.transitionDelay--; else this.transitionDelay = 5;

		//dispose unused sequences
		if (!(this.shouldTransition || this.buffering || this.transitionDelay>0)) {
			var targetDispose = (AppStatus.currentFrame+77) % this.sequences.length;
			if (targetDispose < this.currentSequence && targetDispose> this.currentSequence+10) {
				this.sequences[targetDispose].dispose();
			}
			if (!this.isOnIntro && SETTINGS.INTRO_MODE) {
				this.introSequences[(AppStatus.currentFrame+77) % this.introSequences.length].dispose();
			}
		}

		if (SETTINGS.INSTALLATION_MODE && AppStatus.isOnEndPage && AudioController.getCurrentTime() >= AppStatus.AUDIO_DURATION) {
				
			var dontReload = false;
			if (SETTINGS.LIVE_CINEMA_MODE) {
				dontReload = true;
				if (SETTINGS.RELOAD_AS_INSTALLATION) {
					if (SequenceRenderer.pressedThisFrame) {
						dontReload = false;
					}
				}
			}

			if (!this.installationReloadSent && !dontReload) {
				this.installationReloadSent = true;

				setTimeout(()=> {

					this.cleanup();
				
					//~~ OR HARD WKWEBVIEW CLEANUP//
					if (window.webkit && window.webkit.messagesHandlers && window.webkit.messageHandlers.reloadHandler) {
						if (SETTINGS.RELOAD_AS_INSTALLATION) {
							window.webkit.messageHandlers.reloadHandler.postMessage(window.location.href.replace("live", "installation"));
						} else {
							window.webkit.messageHandlers.reloadHandler.postMessage(null);
						}
					} else {
						if (SETTINGS.RELOAD_AS_INSTALLATION) {
							window.location.search = window.location.search.replace('live', 'installation');
						} else {
							window.location.reload();
						}
					}

				},2000);
			}
		}


		// if (SETTINGS.INSTALLATION_MODE ) {
		// 	if (AudioController.getCurrentTime() >= 5.0) {
		// 		if (!this.installationReloadSent) {
		// 			this.installationReloadSent = true;

		// 			setTimeout(()=> {

		// 				this.cleanup();
					
		// 				//~~ OR HARD WKWEBVIEW CLEANUP//
		// 				if (window.webkit && window.webkit.messagesHandlers && window.webkit.messageHandlers.reloadHandler) {
		// 					window.webkit.messageHandlers.reloadHandler.postMessage(null);
		// 				} else {
		// 					window.location.reload();
		// 				}

		// 			},2000);
		// 		}
		// 	}
		// }
		

		
		//update audio
		AudioController.audioReady = !this.buffering;
		AudioController.update();
		AudioEffectController.update(delta);

		//buffering div
		if (this.buffering && AudioController.timelineStarted && !this.bufferingDivShown) {
			this.bufferingDivShown = true;
			$('#loader-content').fadeIn(0).css('display', 'flex');
			$('#loader').toggleClass('fadein-fast', true).toggleClass('fadeout-fast', false);

		} else if (!this.buffering && this.bufferingDivShown) {
			$('#loader').toggleClass('fadein-fast', false).toggleClass('fadeout-fast', true);
			this.bufferingDivShown = false;
		}


		//
		// Update sequence
		//
		if (!this.buffering && SETTINGS.SHOW_UI) {

			//
			// Update ui
			//
			if (!AudioController.resyncing && this.ranges.progress.ui && !this.ranges.progress.ui.dragging && !this.ranges.sequence.progress.ui.dragging && !this.ranges.audio.progress.ui.dragging && !this.ranges.audio.progress.ui.dragging) {
				opt.progress = Utils.cmap(AudioController.getCurrentTime(), 0.0, AppStatus.AUDIO_DURATION, 0.0, 1.0);
				DreamUI.update(opt, "progress");

				opt.sequence.progress = Utils.cmap(AudioController.getCurrentTime(), this.sequences[this.currentSequence].timecode, this.sequences[this.currentSequence].timeEnd, 0.0, 1.0);
				if (this.sequence.isBranch) opt.sequence.progress = BranchController.getCurrentProgress();
				DreamUI.update(opt.sequence, "progress");

				opt.audio.progress  = AudioEffectController.currentProgress;
				DreamUI.triggerProperty(opt.audio, "progress");

			}
		}

		if (SETTINGS.INSTALLATION_MODE && SETTINGS.HUE_ENABLED) HueController.update(delta);

		if (SETTINGS.INSTALLATION_MODE && !SETTINGS.PRESENTATION_MODE && !SETTINGS.LIVE_CINEMA_MODE && !SETTINGS.HEADPHONES_MODE) {
			AppStatus.globalVolume = Utils.deltaSmoothingSnap2(AppStatus.globalVolume, Utils.ccmap(now-SequenceRenderer.lastRealClick, 3 * 50 * 1000, 3 * 60 * 1000, AppStatus.infoJson.installation_volume, AppStatus.infoJson.installation_volume_idle), 0.9, delta);
			if (AudioController.finalGain) AudioController.finalGain.gain.tweenToValue(AppStatus.globalVolume, 1.0);
		}
		if (SETTINGS.VOLUME_BUTTON_ENABLED) {
			if (AudioController.finalGain) AudioController.finalGain.gain.tweenToValue(AppStatus.globalVolume, 0.1);
		}


		//update UI
		if (!AudioController.resyncing && SETTINGS.SHOW_UI) {
			opt.currentTime = Utils.formatSeconds(AudioController.getCurrentTime());
			DreamUI.triggerProperty(opt, "currentTime");

			opt.progression = Utils.ccmap(AudioController.getCurrentTime(), 0.0, AppStatus.AUDIO_DURATION, 0.0, 1.0);
			DreamUI.triggerProperty(opt, "progression");

			opt.sequence.id = this.sequences[this.currentSequence].timelineId;
			DreamUI.triggerProperty(opt.sequence, "id");

			opt.sequence.preset = this.sequences[this.currentSequence].presetName||'none';
			DreamUI.triggerProperty(opt.sequence, "preset");

			opt.audio.id = AudioEffectController.currentId;
			DreamUI.triggerProperty(opt.audio, "id");

			opt.audio.preset  = AudioEffectController.currentPresetName;;
			DreamUI.triggerProperty(opt.audio, "preset");
		
			if (SETTINGS.IS_LOCAL && this.ranges.audio.progress.ui) this.ranges.audio.progress.ui.setColor(AudioEffectController.transitionStarted ? '#aa3333' : '#2bbab6');
		}

		DreamUI.triggerUpdates();
	}

	render() {
		super.render();
		if (this.cleanedUp) return;
		if (this.buffering) return;

		renderer.setRenderTarget(null);
		renderer.setClearColor(0xffffff,1);
		renderer.clear();

		if (!this.buffering) SequenceRenderer.render(); 
		// else console.log("this.buffering!");


		// if (this.hasEndedTransition) {
		// 	this.hasEndedTransition = false;
		// 	console.log("ENDED TRANSITION");
		// }
		// renderer.render(this.scene, this.camera);
	}

	getCurrentProgress() {
		return Utils.ccmap(AudioController.getCurrentTime(), this.sequences[this.currentSequence].timeStart, this.sequences[this.currentSequence].timeEnd, 0.0, 1.0)
	}

	getTexture() {
		return this.fbo.texture.texture;
	}


	cleanup() {
		if (this.cleanedUp) return;
		this.cleanedUp = true;
		AppStatus.cleanedUp = true;

		if (AudioEffectController) AudioEffectController.cleanupFinal();
		if (RendererController) RendererController.dispose();
		if (SequenceRenderer) SequenceRenderer.dispose();
		if (window.renderer) window.renderer.dispose();
		if (window.PageLoader) window.PageLoader.disposeAll();
		if (ABDisposeController) ABDisposeController.cleanup();

		if (window.audioContext) {
			try {
				window.audioContext.close();
				window.audioContext = null;
			} catch(er) {}
		};
		window.audioContext = null;

		this.sequences = [];
		this.introSequences = [];

		window.renderer = undefined;
		window.loader = window.Loader = undefined;
		window.mainScene = undefined;
		window.SequenceRenderer = undefined;
		window.PageLoader = null;
		window.AudioEffectController = null;
		window.AudioController = null;
		window.AudioDisposeController = null;
		console.log("Memory clean up complete");
	}

	//cleanup everything
	dispose() {
		super.dispose();
		if (this.disposed) return;
		this.disposed = true;
	}
};

export default TimelineScene;	
