import $ from 'jquery';
import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import * as THREE from 'three';
import Utils from '../utils/Utils.js';
import LoaderXHR from './LoaderXHR.js';
import DDSLoader from '../utils/DDSLoader.js';

var ddsLoader = new DDSLoader();

function LoaderMultiTexture(folder, binarySource, jsonSource, compressed, params) {
	
	var self = this,
		completeCallback,
		errorCallback;
	
	this.url = folder+jsonSource;
	this.data = {};
	this.loaded = false;
	this.loading = false;
	this.progress = 0.0;
	this.weight = 1;
	this.loadType = 'multitextures';
	this.currentImage = 0;
	this.loadErrors = null;
	this.binXHR = null;
	this.concatenated = true;
	this.compressed = compressed;

	this.createTexture = function(img, info, w, h) {
		var tex;
		if (!compressed) {
			tex = new THREE.Texture(img);
			tex.format = params.format||THREE.RGBAFormat;
			tex.type = THREE.UnsignedByteType;
			tex.wrapT = tex.wrapS = params.wrapping||THREE.ClampToEdgeWrapping;
			tex.minFilter = params.minFilter||THREE.LinearFilter;
			tex.magFilter = params.magFilter||THREE.LinearFilter;
			tex.generateMipmaps = params.generateMipmaps||false;
			tex.needsUpdate = true;
		} else {
			tex = new THREE.CompressedTexture(img, w, h, THREE.RGBA_S3TC_DXT5_Format); //, THREE.UnsignedByteType, THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping, THREE.LinearFilter, THREE.LinearMipMapLinearFilter, 1, THREE.LinearEncoding)
			tex.type = THREE.UnsignedByteType;
			tex.wrapT = tex.wrapS = THREE.ClampToEdgeWrapping;
			tex.minFilter = params.minFilter||THREE.LinearFilter;
			tex.magFilter = params.magFilter||THREE.LinearFilter;
			tex.generateMipmaps = params.generateMipmaps||false;
			tex.needsUpdate = true;
		}
		tex.info = info;
		if (params.upload) Utils.uploadTexture(tex);
		return tex;
	}.bind(this);
	

	//
	// For unConcatenated, load all images one after the other
	//
	function loadNextImage() {
		if (self.loaded) return;
		self.progress = self.currentImage / self.allImages.length;
		//done
		if (self.currentImage >= self.allImages.length) {
			self.progress = 1.0;
	    	self.loaded = true;
	    	if (self.loadErrors) {
	    		errorCallback(self);
	    		return;
	    	}
	    	completeCallback(self);
	    	errorCallback = completeCallback = null;
	    	return;
		}

		//create image and load it
	    (function(name,url) {
	    	var img = new Image();
			self.data[name] = img;
	    	img.onload = function() {	
	    		img.decoded = true;
	    		img.onload = img.onerror = img = undefined;
	    		self.currentImage++;
	    		loadNextImage();
			}
			img.onerror = function() {
				self.loadErrors = self.loadErrors || [];
				self.loadErrors.push(url);
				img.onload = img.onerror = img = undefined;
				self.currentImage++;
				loadNextImage();
			}
			img.src = url;
	    })(self.allImages[self.currentImage],folder+self.allImages[self.currentImage]);
	}



	//
	//
	// Concatenated Images Files using ArrayBuffer
	//
	//
	self.data.images = {};

	//load the json and binary
	this.start = function(completeCallback, errorCallback) {
		if (self.loading) console.error('Already Loading');

		var infoXHR = new LoaderXHR(jsonSource,'json'),
			numDone = 0,
			error = false;
		self.binXHR = new LoaderXHR(binarySource,'arraybuffer');
		self.progress = 0.0;
		function doneError() {
			error = true;
			done();
		}
		function done() {
			numDone++;
			if (numDone==2) {
				self.data.info = infoXHR.data.value;
				self.data.binary = self.binXHR.data.value;
				infoXHR.dispose();
				self.binXHR.dispose();
				self.progress = 1.0;
				infoXHR = self.binXHR = null;
				done = doneError = undefined;
				self.data.decodeAllImages().then(completeCallback, errorCallback);
				//if (!error) completeCallback(self); else errorCallback(self);
			}
		}
		infoXHR.start(done,doneError);
		self.binXHR.start(done,doneError);

	};

	this.clean = function() {
		self.data.binary = null;
		self.binXHR.dispose();
		self.binXHR = null;
	};

	this.getInfo = function(name) {
		return self.data.info[name];
	};

	self.data.decodeAllImages = function() {
		return new Promise(function (fulfill, reject){
			var imgs = self.data.getAllImages();
			function next() {
				if (imgs.length<=0) {
					fulfill(self);
					return;
				}
				self.data.decodeImage(imgs.pop()).then(next);
			}
			next();
		});
	};

	//
	// Read the image from the blob
	//
	self.data.decodeImage = function(name) {
		return new Promise(function (fulfill, reject){
			//fulfill(self.data[name]);
			if (name=="version") {fulfill(); return;}
			if (!self.data.images[name]) {
				if (!self.compressed) {
					var img = new Image();
					(function(img) {
						var	ext = name.split('.').pop() === 'jpg' ? 'jpg' : 'png',
							bytesInfo = self.data.info[name],
							ab = self.data.binary.slice(bytesInfo.start,bytesInfo.end),
							blob = new Blob([ab],{'type':'image/'+ext}),
						url = window.URL.createObjectURL(blob);

						function imgLoaded(e) {

							var tex = self.createTexture(img, bytesInfo, bytesInfo.width, bytesInfo.height);
							self.data.images[name] = tex;

							img.decoded = true;
							window.URL.revokeObjectURL(url);
							url = undefined;
							blob = undefined;
							ab = undefined;
							bytesInfo = undefined;
							ext = undefined;
							img.onload = undefined;
							img.removeEventListener('load',imgLoaded);
							imgLoaded = undefined;
							fulfill(img);
							img = null;
						}
						img.addEventListener('load',imgLoaded,false);

						img.src = url;
					})(img);
					
				
				} else {
					//Compressed Texture Load
					var	ext = name.split('.').pop(),
						bytesInfo = self.data.info[name],
						ab = self.data.binary.slice(bytesInfo.start,bytesInfo.end);

					var img = ddsLoader.parse(ab); //{}
					var tex = self.createTexture(img, bytesInfo, bytesInfo.width, bytesInfo.height)
					if (img.mipmaps.length>1) img.mipmaps.shift();

					if (img && img.mipmaps && img.mipmaps.length) {
						tex.image = img;
						tex.image.data = img.mipmaps[0].data;
						tex.mipmaps = img.mipmaps;
						tex.mipmapCount = img.mipmapCount-1;
						tex.format = img.format;
						tex.loaded = true;

						tex.needsUpdate = true;
						tex.shouldUpdate = true;
					} else {
						console.warn('DDS Decoding Error for image:', self.url, self.data.path);
					}

						// img = THREE.DDSLoader.parse(ab);
					img.decoded = true;
					self.data.images[name] = tex;
					fulfill(img);
				}
			} else {
				fulfill(self.data.images[name]);
			}
		});
	};

	//
	// access images
	//
	self.data.getTexture = function(name) {
		if (!self.data.images[name]) console.warn('Missing texture:',name);
		return self.data.images[name];
	};

	//
	// Prepare all images
	//
	self.data.getAllImages = function() {
		var imgs = [];
		for (var name in self.data.info) {
			imgs.push(name);
		}
		return imgs;
	};

	self.data.getInfo = function(name) {
		return self.data.info[name];
	};
	

	this.getProgress = function() { 
		if (self.binXHR) return self.binXHR.getProgress();
		return self.progress;
	};
	this.getWeight = function() {
		return this.weight;
	};
	this.dispose = function() {
		completeCallback = errorCallback = self.binXHR = self.url = self.start = self.getProgress = self.dispose = self.data = null;
	};

}
export default LoaderMultiTexture;