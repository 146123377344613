import SETTINGS from '../Settings.js';

var protocol = /https/.test(window.location.protocol) ? 'wss':'ws';

function WebsocketController(IP, _port) {
	
	var socket = null,
		socketConnecting = false,
		socketConnected = false,
		connectedOnce = false,
		lastHeartbeat = -10000,
		msgQueue = [];

	this.IP = IP;
	this.port = _port;
	this.listeners = {};
	this.hasError = false;

	//
	// Initialize the socket & this events
	//
	this.init = function() {
		if (!SETTINGS.IS_LOCAL) return;
		if (socketConnecting || socketConnected) return;
		// this.IP = SETTINGS.WEBSOCKET_IP;
		this.IP = window.location.hostname;

		// if (!SETTINGS.IS_LOCAL) {
		// 	this.IP = 'protected-depths-15198.herokuapp.com';
		// 	this.port =  /https/.test(window.location.protocol)?443:80;
		// } else {
		// 	// this.IP = 'protected-depths-15198.herokuapp.com';
		// 	// this.port =  5000;
		// }
		this.connect();
	}.bind(this);


	this.connect = function() {
		if (socket) {
			socket.close();
			socket = null;
		}
		console.log('Websocket - Trying to connect.', this.port);
		socketConnecting = true;
		try {
			socket = new WebSocket( protocol+'://'+this.IP+':'+this.port+'/');
		} catch (err) {
			console.log('Websocket creation error @', protocol+'://'+this.IP+':'+this.port+'/');
		}


		if (socket) {
			socket.onopen = function() {
				console.log('Websocket connected');
				this.hasError = false;
				socketConnected = true;
				connectedOnce = true;
				for (var i=0; i<msgQueue.length; i++) {
					socket.send(msgQueue[i]);
				}
				msgQueue = [];
			}.bind(this);

			socket.onerror = function(e) {
				console.log('Websocket creation error @', protocol+'://'+this.IP+':'+this.port+'/');
				this.hasError = true;
				if (e.target !== socket) return;
				socketConnecting = false;
				socketConnected = false;
				msgQueue = [];
				socket = null;
				if (connectedOnce) setTimeout(this.init, 2000); else setTimeout(this.init, 5000);
			}.bind(this);

			socket.onclose = function(e) {
				console.log("Closed websocked");
				if (e.target !== socket) return;
				socketConnecting = false;
				socketConnected = false;
				msgQueue = [];
				socket = null;
				if (connectedOnce) setTimeout(this.init, 2000); else setTimeout(this.init, 5000);
			}.bind(this);

			socket.onmessage = this.handleMessage;
		}
		window.socket = socket;
	};


	this.on = this.addEventListener = function(event, callback) {
		this.listeners[event] = this.listeners[event]||[];
		this.listeners[event].push(callback);
	};

	this.handleMessage = function(e) {
		// try {
		// 	JSON.parse(e.data);
		// } catch (er) {
		// 	console.log("JSON ERROR",e.data);
		// }

		if (e.data === 'reload') {
			// window.location.reload();
			return;
		}

		var msg = JSON.parse(e.data);
		if (this.listeners[msg.action]) {
			for (var i=0; i<this.listeners[msg.action].length; i++) {
				this.listeners[msg.action][i](msg);
			}
		}
	}.bind(this);

	this.connected = function() {
		return socketConnected;
	};

	this.update = this.sendHeartbeat = function() {
		if (!socketConnected) return;
		if (performance.now() - lastHeartbeat >= 2000) {
			lastHeartbeat = performance.now();
			// socket.send('{"action":"heartbeat"}');
		}
	};

	this.sendMessageRaw = function(msg) {
		console.log(msg);
		if (!socketConnecting) return;
		if (socketConnected) {
			socket.send(msg);
		} else {
			msgQueue.push(msg);
		}
	};

	this.sendMessage = this.send = function(obj) {
		if (!socketConnecting) return;
		var msg = JSON.stringify(obj);
		if (socketConnected) {
			socket.send(msg);
		} else {
			msgQueue.push(msg);
		}
	};
};
window.WebsocketController = window.WebsocketController||new WebsocketController(null,  /https/.test(window.location.protocol)?9090:8080);
export default window.WebsocketController;