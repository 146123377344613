import $ from 'jquery';
import FastEvent from './ui/FastEvent.js';

import Window from "./ui/Window.js";
import Folder from "./ui/Folder.js";
import Slider from "./ui/Slider.js";
import HideButton from "./ui/HideButton.js";
import Checkbox from "./ui/Checkbox.js";
import TextInput from "./ui/TextInput.js";
import ColorPicker from "./ui/ColorPicker.js";
import Button from "./ui/Button.js";
import Dropdown from "./ui/Dropdown.js";
import DropdownMulti from "./ui/DropdownMulti.js";
import DropdownList from "./ui/DropdownList.js";
import BinFolder from "./ui/BinFolder.js";



var UI = {};
UI.autofillEnabled = true;
UI.Window = Window;
UI.Folder = Folder;
UI.Slider = Slider;
UI.HideButton = HideButton;
UI.Checkbox = Checkbox;
UI.TextInput = TextInput;
UI.ColorPicker = ColorPicker;
UI.Button = Button;
UI.Dropdown = Dropdown;
UI.DropdownList = DropdownList;
UI.DropdownMulti = DropdownMulti;
UI.BinFolder = BinFolder;

UI.width = 256;

UI.currentFrame = 0;

UI.enabled = true; //disable all UI
UI.currentZIndex = 1;
UI.mainDiv = null;
UI.socket = null;
UI.connected = false;
UI.hideButton = null;
UI.mainFolder = null;

UI.fullpage = false;
UI.hasChanged = false;

UI.allElementsByPath = {};
UI.allFolders = [];
UI.allWindows = []; 
UI.blockUpdates = false;
UI.headerStart = 0;
UI.currentSequence = 'default';
UI.currentLayout = 'default';

UI.headerColor = '#e8e8e8';
UI.themeColor = '#2bbab6';


UI.settingUp = false;
UI.shiftPressed = false;

UI.folderStructure = {};

UI.globalScale = 1.0;

UI.themeColors = {
	'Samplers':'#f45841',
	'Filters':'#f5b015',
	'Mix':'#fff263',
	'Modulators':'#60A8E4'
};
// UI.triggerColor = '#ffffff';
// UI.samplerColor = 'f45841';
// UI.modulatorColor = '#60A8E4';
// UI.mixerColor = '#f5b0015';



//
// Set manually when linking UI
//

UI.recursiveUpdateAll = function(src, dst) {
	for (var o in src) {
		if (typeof src[o] !== "object") {
			dst[o] = src[o];
		} else {
			if (!dst[o] && src[o]) dst[o] = src[o].constructor === Array ? [] : {};
			if (src[o]) UI.recursiveUpdateAll(src[o], dst[o]);
		}
	}
};

UI.recursiveUpdateArray = function(src, dst) {
	for (var o in src) {
		if (typeof src[o] !== "object" || src.constructor === Array || o ==="sequence") {
			dst[o] = src[o];
		} else {
			// if (src[o] && src[o].constructor === Array) {
			// 	dst[o] = src[o];
			// } else {
				if (!dst[o] && src[o]) dst[o] = src[o].constructor === Array ? [] : {};
				UI.recursiveUpdateArray(src[o], dst[o]);
			//}
			// if (!dst[o] && src[o]) dst[o] = src[o].constructor === Array ? [] : {};
			// if (src[o]) UI.recursiveUpdateAll(src[o], dst[o]);
		}
	}
};

function recursiveUpdateFunction(src, dst) {
	for (var o in src) {
		if (typeof src[o] == "function") {
			dst[o] = true;
		} else if (typeof src[o] !== "object") {
			dst[o] = src[o];
		} else {
			if (!dst[o]) { dst[o] = src[o].constructor === Array ? [] : {}; }
			recursiveUpdateFunction(src[o], dst[o]);
		}
	}
};

UI.update = function() {}; //no socket for now
UI.updateArray = function() {}; //no socket for now
UI.updateAction = function() {}; //no socket for now


UI.isOverMainFolder = true;

//
// Begin UI and add to screen
//
UI.init = function(opt, ran) {

	UI.fullpage = true;

	// UI.setupHeader();

	UI.mainDiv = document.createElement('div');
	$(UI.mainDiv).addClass('ui-main');
	document.body.appendChild(UI.mainDiv);

	// $(UI.mainDiv).hover(
	// 	function() {
	// 		UI.isOverMainFolder = true;
	// 		UI.mainFolder.enableUIUpdates();
	// 	}.bind(this),
	// 	function() {
	// 		UI.isOverMainFolder = false;
	// 		UI.mainFolder.disableUIUpdates();
	// 	}.bind(this)
	// );


	if (opt && ran) UI.setup(opt, ran);
};


UI.setup = function(opt, ran) {

	UI.wasSetup = true;

	UI.blockUpdates = true;

	//
	// Clean up (or not...)
	//
	for (var i=0; i<UI.allFolders.length; i++) {
		if (UI.allFolders[i]) UI.allFolders[i].dispose();
	}
	for (var i=0; i<UI.allWindows.length; i++) {
		if (UI.allWindows[i]) UI.allWindows[i].dispose(true, false);
	}
	UI.allWindows = [];
	UI.allFolders = [];
	if (UI.hideButton) {
		UI.hideButton.dispose();
		UI.hideButton = null;
	}
	if (UI.mainFolder) {
		UI.mainFolder.dispose();
	}

	//update options & create new folder
	var options = opt;
	var ranges = ran;
	// UI.createConsoleOptions();

	var gui = UI.createFolder(options, null, ranges, [], 0);
	UI.mainDiv.appendChild(gui.rowDiv);
	UI.mainFolder = gui;
	// UI.mainFolder.toggleClose();
	UI.mainFolder.enableUIUpdates();

	//hide button
	UI.hideButton = new UI.HideButton(UI);
	UI.hideButton.init();
	UI.mainDiv.appendChild(UI.hideButton.domElement);

	$(window).on('keydown',function(e) {
		UI.shiftPressed = e.shiftKey;
	});
	$(window).on('keyup',function(e) {
		UI.shiftPressed = false;
	});
};
// UI.hideMain = function() {
// 	UI.isOverMainFolder = false;
// 	UI.mainFolder.disableUIUpdates();
// };



// //new id utility
// function getId(name) {
// 	var id = 0;
// 	while(UI.currentSoundEngine.options[name+'_'+id] !== undefined) {
// 		id++;
// 	}
// 	return name+'_'+id; //UI.propPreset+'~'+
// };


//
// Update the UI by adding an option element
//
UI.addElement = function(path, newOption, newRange) {

	var propName = path.pop();

	var opt = options;
	for (var i=0; i<path.length; i++) {
		opt = opt[path[i]] || {};
	}
	opt[propName] = newOption;
	//co[path[path.length-1]] = newOption;



	var ran = ranges;
	for (var i=0; i<path.length; i++) {
		ran = ran[path[i]] || {isFolder: true, isOpen: false};
	}
	ran[propName] = newRange;
	//co[path[path.length-1]] = newRange;

	//
	// if is folder create new and add to parent, otherwise skip and simply add a property
	//
	var folder = UI.mainFolder;
	for (var i=0; i<path.length; i++) {
		folder = folder.subfolders[path[i]];
	}

	//
	// Create a new element that isn't a folder by skipping source folder
	//
	var res;
	if (newRange && newRange.isFolder) {

		var subPath = path.concat(propName)
		var subFolder = UI.createFolder(newOption, propName, newRange, subPath);
		folder.rowDiv.appendChild(subFolder.uiItem);
		folder.addItem(subFolder);
		folder.subfolders[propName] = subFolder;
		res = subFolder;

	//
	// create a new element that's a folder
	//
	} else {
		UI.createFolder(opt, propName, ran, path, 0, folder);
	}
};



// Recursively create a folder div
//
UI.createFolder = function(options, propName, ranges, path, startingSize, skipFolder) {

	//----------------------
	//
	// Create folder & div
	//
	//----------------------
	ranges = ranges || {isFolder: true, isOpen: true, colorCode: '#000000'};
	path = path || [];
	startingSize = startingSize || 0;

	var gui;
	if (!skipFolder) {
		gui = new UI.Folder(UI);
		gui.init(options, path.length>0 ? path[path.length-1] : '', ranges, path, startingSize);
	} else {
		gui = skipFolder;
	}

	//----------------------
	//
	// Fill folder div with properties
	//
	//----------------------
	for (var propName in options) {

		var value = options[propName],
			props = ranges[propName] || null;

		//----------------------
		//
		// Determine value type based on props
		//
		//----------------------
		//
		//--SUBFOLDER
		//
		if (props && props.isFolder) {

			var subPath = path.concat(propName)

			var subFolder = UI.createFolder(value, propName, props, subPath, startingSize);
			gui.rowDiv.appendChild(subFolder.uiItem);
			gui.addItem(subFolder);
			gui.subfolders[propName] = subFolder;


		} else if (props && (props.hidden || props.isHidden) ) {
			
			//no ui
			
		//
		//--slider
		//
		// } else if (props && props.isModulatedSlider) {

		// 	var sliderm = new UI.ModulatedSlider(UI);
		// 	sliderm.init(options, propName, props, path, startingSize+1);
		// 	// console.log(sliderm.uiItem)
		// 	gui.addItem(sliderm);
		// 	gui.rowDiv.appendChild(sliderm.rowDiv);
		// 	gui.subfolders[propName] = sliderm;

		} else if (props && (props.isSlider || (props.min!==undefined && props.max !== undefined))) {

			var sliderf = new UI.Slider(UI);
			sliderf.init(options, propName, props, path);
			gui.addItem(sliderf);

		// //
		// //--dropdown
		// //
		} else if (props && props.isDropdown) { 

			var dropdown = new UI.Dropdown(UI);
			dropdown.init(options, propName, props, path);
			gui.addItem(dropdown);

		} else if (props && props.isMultiDropdown) { 

			var dropdown = new UI.DropdownMulti(UI);
			dropdown.init(options, propName, props, path);
			gui.addItem(dropdown);

		} else if (props && props.isDropdownList) { 

			var dropdown = new UI.DropdownList(UI);
			dropdown.init(options, propName, props, path);
			gui.addItem(dropdown);

		// //
		// //--color
		// //
		} else if (props && props.isColor) {

			var color = new UI.ColorPicker(UI);
			color.init(options, propName, props, path);
			gui.addItem(color);

		//
		//--button
		//
		} else if ((props && (props.isButton || props.isAction)) || typeof value === "function") {

			props = props || {isButton: true};

			var button = new UI.Button(UI);
			button.init(options, propName, props, path);
			gui.addItem(button);

		// //
		// //--checkbox
		// //
		} else if ((props && props.isBoolean) || typeof value === "boolean") {

			props = props || {isBoolean: true};

			var checkbox = new UI.Checkbox(UI);
			checkbox.init(options, propName, props, path);
			gui.addItem(checkbox);

		// //
		// //--sequencer
		// //
		// } else if (props && props.isSequencerUI) { 

		// 	var sequencer = new UI.Sequencer(UI);
		// 	sequencer.init(options, propName, props, path);
		// 	gui.addItem(sequencer);

		// //
		// //--divider
		// //
		} else if (props && props.isBinFolder) { 

			var binfolder = new UI.BinFolder(UI);
			binfolder.init(options, propName, props, path, startingSize);
			gui.rowDiv.appendChild(binfolder.uiItem);
			gui.addItem(binfolder);


		//
		//--text input
		//
		} else if ((props && (props.isText || props.isNumber)) || typeof value === "string" || typeof value === "number") {

			props = props || {isText: typeof value === "string", isNumber: typeof value === "number"};

			var text = new UI.TextInput(UI);
			text.init(options, propName, props, path);
			gui.addItem(text);

			
		//
		//--ERROR
		//
		} else {

			console.warn('Error: property not recognized:', propName);

		}
	}

	//
	// Return new folder
	//
	return gui;
};



//
// Add/Reorder missing elements to older presets
//
function migratePreset(type, options,ranges) {
	switch (type) {

		//add speed
		case 'clock':
			if (!ranges.speed) {
				options.speed = 1.0;
				ranges.speed = {min:0.0, max:3.0};
			}
			break;


		//add pitch + clockEffect 
		case 'soundloop':
			break;

	}
};



//
// Dispose all elements from all GUI
//
UI.disposing = false;
UI.disposeAll = function() {
	UI.disposing = true;
	for (var i=0; i<UI.allWindows.length; i++) {
		if (UI.allWindows[i]) {
			UI.allWindows[i].dispose(true, false);
		}
	}
	UI.allWindows = [];
	UI.disposing = false;
};

UI.removeWindow = function(target) {
	for (var i=0; i<UI.allWindows.length; i++) {
		if (UI.allWindows[i] && UI.allWindows[i] === target) {
			UI.allWindows.splice(i,1);
			i--;
		}
	}
};

//
// Get a folder element
//
UI.getFolder = function(path) {

	for (var i=0; i<UI.allFolders.length; i++) {

		var allowed = UI.allFolders[i].path.length === path.length;
		for (var j=0; j<path.length; j++) {
			allowed = allowed && path[j] === UI.allFolders[i].path[j];
		}
		if (allowed) return UI.allFolders[i];
	}
	return null;

};

//
// Delete Element
//
UI.deleteElement = function(path, propName) { //options, propName, ranges, window, 

	var opt = options;
	for (var i=0; i<path.length-1; i++) {
		opt = opt[path[i]];
	}
	var ran = ranges;
	for (var i=0; i<path.length-1; i++) {
		ran = ran[path[i]];
	}

	//
	// Clean up (or not...)
	//
	for (var i=0; i<UI.allFolders.length; i++) {
		if (UI.allFolders[i].toWatch === opt[propName]) {
			UI.allFolders[i].dispose();
		}
	}
	delete opt[propName]
	delete ran[propName];
};


UI.close = function() {
	UI.mainFolder.close();
};
UI.open = function() {
	UI.mainFolder.open();
}

//---------------------
//
// Property Events triggering
//
//---------------------
UI.updateQueue = new Map();

UI.watchProperty = function(options, propName, func) {
	FastEvent.on(options, 'ui-'+propName, func);
};
UI.unwatchProperty = function(options, propName, func) {
	FastEvent.off(options, 'ui-'+propName, func);
};
UI.triggerProperty = function(options, propName) {
	if (!UI.wasSetup) return;
	if (!options.eventHandler || !options.eventHandler.events.has('ui-'+propName)) return;

	UI.updateQueued = true;
	if (UI.updateQueue.has(options.eventHandler)) {
		UI.updateQueue.get(options.eventHandler)[propName] = options;
	} else {
		var o = {};
		o[propName] = options;
		UI.updateQueue.set(options.eventHandler, o);
	}
};
UI.triggerUpdates = function() {
	if (!UI.updateQueued) return;
	UI.currentFrame++;

	for (var key of UI.updateQueue.keys()) {
		var o = UI.updateQueue.get(key);
		var propKeys = Object.keys(o);
		for (var j=0; j<propKeys.length; j++) {
 	 		FastEvent.trigger(o[propKeys[j]], 'ui-'+propKeys[j]);
  		}
	}
	UI.updateQueue.clear();
	UI.updateQueued = false;
};
UI.triggerEvent = FastEvent.trigger;


UI.update = UI.triggerProperty;


export default UI;
