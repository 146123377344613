import JSHue from "../utils/jshue.js";

var hsl = {h:0,s:0,l:0};

class HueController {	
	constructor() {
		this.ready = false;
		this.bridge = null;
		this.user = null;
		this.lastSend = 0;
		this.pauseColorSent = false;


		this.activePc = 0.0;
		this.latestColor = new THREE.Vector3(1.0, 1.0, 1.0);

		this.enabled = SETTINGS.TIMELINE_MODE;

		//custom color in preset
		this.customColorEnabled = false;
		this.customColor = new THREE.Color(0xffffff);
		this.customColorPaletteMode = false;

		this.customColorBEnabled = false;
		this.customColorB = new THREE.Color(0xffffff);
		this.customColorTime = 0;

		//custom color transition
		this.customColorTransitionPc = 0.0;
		this.customColorNextEnabled = false;
		this.customColorNext = new THREE.Color(0xffffff);
		this.customColorNextPaletteMode = false;

		this.customColorBNext = new THREE.Color(0xffffff);
		this.customColorBNextPaletteMode = false;
		this.customColorNextTime = 0;

		//tonnerre flash
		this.flashMode = false;
		this.flashPc = 0.0;
		this.shouldFlash = false;
		this.silhouetteMode = false;
		this.silhouetteModeNext = false;


		//other modes
		this.introMode = false;
	}

	/**
	 * Converts an RGB color value to HSV. Conversion formula
	 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
	 * Assumes r, g, and b are contained in the set [0, 255] and
	 * returns h, s, and v in the set [0, 1].
	 *
	 * @param   Number  r       The red color value
	 * @param   Number  g       The green color value
	 * @param   Number  b       The blue color value
	 * @return  Array           The HSV representation
	 */
 	rgbToHsv(r, g, b) {
	  // r /= 255, g /= 255, b /= 255;

	  var max = Math.max(r, g, b), min = Math.min(r, g, b);
	  var h, s, v = max;
	  // console.log(r,g,b);

	  var d = max - min;
	  s = max == 0 ? 0 : d / max;

	  if (max == min) {
	    h = 0; // achromatic
	  } else {
	    switch (max) {
	      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
	      case g: h = (b - r) / d + 2; break;
	      case b: h = (r - g) / d + 4; break;
	    }

	    h /= 6;
	  }

	  return  {h: h, s: s, b: v};
	}

	rgb2hsb(r,g,b) {
	    var H, S, V, C;
	    V = Math.max(r, g, b);
	    C = V - Math.min(r, g, b);
	    H = C == 0 ? null :
	        V == r ? (g - b) / C :
	        V == g ? (b - r) / C + 2 :
	                 (r - g) / C + 4;
	    H = (H + 360) % 6 * 60 / 360;
	    S = C == 0 ? 0 : C / V;
	    return {h: H, s: S, b: V};
	}

	setup() {

		if (this.hue) return;

		this.hue = JSHue();
		this.hue.discover().then(bridges => {
	    if(bridges.length === 0) {
	    
	        console.warn('No hue bridges found.');
	    
	    } else {
	        bridges.forEach(b => console.log('Bridge found at IP address %s.', b.internalipaddress));

	        var bridge = this.hue.bridge(bridges[0].internalipaddress);
	        this.bridge = bridge;
	        this.internalipaddress = bridges[0].internalipaddress;

	       	this.user = bridge.user('H-Vg4EE9UpuX8uJs82aYL1G00VFue6fjDxsC1PUa');

	       	this.user.getLights().then(data => {
	       		console.log("user data:", data);
				if (data && data[0] && data[0].error) {

			       	window.alert("No User : press HUE Bridge Link Button")
		        	
		        	// create user account (requires link button to be pressed)
					bridge.createUser('brainstream').then(data => {

					    // extract bridge-generated username from returned data
					    var username = data[0].success.username;

					    console.log('New bridge username:', username);

					    // instantiate user object with username
					    this.user = bridge.user(username);
					    this.ready = true;
					});
				} else {
					this.ready = true;
				}
			});
	    }
		}).catch((e) => {
			console.log('Error finding bridges', e);
			console.log('Trying with fallback ip: 192.168.8.163');

			var bridge = this.hue.bridge('192.168.8.163');
	        this.bridge = bridge;

	       	this.user = bridge.user('H-Vg4EE9UpuX8uJs82aYL1G00VFue6fjDxsC1PUa');

	       	this.user.getLights().then(data => {
		   		console.log("user data:", data);
				if (data && data[0] && data[0].error) {

			       	window.alert("No User : press HUE Bridge Link Button")
		        	
		        	// create user account (requires link button to be pressed)
					bridge.createUser('brainstream').then(data => {

					    // extract bridge-generated username from returned data
					    var username = data[0].success.username;

					    console.log('New bridge username:', username);

					    // instantiate user object with username
					    this.user = bridge.user(username);
					    this.ready = true;
					});
				} else {
					this.ready = true;
				}
			});
		});
	}


	update(delta) {
		if (!this.ready) return;
		if (!this.enabled) return;

		if (AudioController.pausedForMenu && SETTINGS.INSTALLATION_PAUSE_STOP_HUE) {
			if (this.pauseColorSent) return;
			if (!this.pauseColorSent) this.pauseColorSent = true;
		} else {
			this.pauseColorSent = false;
		}

		var now = performance.now();
		
		var simulationOff = SequenceRenderer.simulationStarted ? 0.0 : 1.0;
		var power = simulationOff*Math.min(SequenceRenderer.multiClickPc+SequenceRenderer.powerEffectPc+SequenceRenderer.currentDragSpeed.length()*2.0+SequenceRenderer.actualPressPc*0.5 + SequenceRenderer.actualPressPc,1.0);


		var uniforms = SequenceRenderer.backgroundMaterial.uniforms.fillAlpha.value < 1.0 ? SequenceRenderer.backgroundMaterial.uniforms : SequenceRenderer.backgroundMaterialSimple.uniforms;

		var col = new THREE.Color(0xfffffff).set(uniforms.fillColor.value);
		
		if (SequenceRenderer.backgroundMaterial.uniforms.fillAlpha.value < 1.0) {			
			var t = (SequenceRenderer.gradientTime*0.5+SequenceRenderer.pulseTime*0.5) % 1.0;
			var tA = (1.0-Math.abs(Utils.cmap(t, 0.0, 0.666, -1.0, 1.0)));
			var tB = (1.0-Math.abs(Utils.cmap(t, 0.333, 0.9999, -1.0, 1.0)));
			var tC = Math.min(Utils.cmap(t, 0.666, 1.0, 0.0, 1.0) + Utils.ccmap(t, 0.0, 0.3333, 1.0, 0.0),1.0);

			var maxAlpha = 1.0-SequenceRenderer.backgroundMaterial.uniforms.fillAlpha.value;
			col.lerp(SequenceRenderer.backgroundMaterial.uniforms.colorA.value, tA*maxAlpha);
			col.lerp(SequenceRenderer.backgroundMaterial.uniforms.colorB.value, tB*maxAlpha);
			col.lerp(SequenceRenderer.backgroundMaterial.uniforms.colorC.value, tC*maxAlpha);
		}

		col.lerp(Utils.whiteColor, 1.0-uniforms.alpha.value);
		

		//
		// Correction générale automatique
		//
		//bleu vert -> plus bleu moins vert
		var hsl = col.getHSL({h:0,s:0,l:0});
		if (col.b > col.g && col.g > col.r && hsl.l < 0.8) {
			col.g *= Utils.ccmap(hsl.s, 0.5, 1.0, 1.0, 0.5);
		}



		//transition to custom color
		if (!this.customColorEnabled) {this.customColor.copy(col);}
		if (!this.customColorNextEnabled) {this.customColorNext.copy(col);}

		col.lerp(this.customColor, 1.0-this.customColorTransitionPc);
		col.lerp(this.customColorNext, this.customColorTransitionPc);


		//color B auto select new hue if !enabled
		if (this.customColorEnabled && !this.customColorBEnabled && !this.introMode && !this.customColorPaletteMode) {
			var range = Utils.ccmap(power,0,1,0.075,0.05)
			this.customColorB.copy(col);
			this.customColorB.getHSL(hsl);
			this.customColorB.setHSL((hsl.h+range)%1.0, hsl.s*0.66, hsl.l);
		}

		//color B tween
		if (this.customColorEnabled && !this.customColorPaletteMode) {
			var t = (SequenceRenderer.gradientTime*0.5+SequenceRenderer.pulseTime*0.5);
			col.lerp(this.customColorB, (1.0-this.customColorTransitionPc) * Utils.cmap(Math.sin(t*Math.PI*0.33),-1,1,0,1));
			// console.log((1.0-this.customColorTransitionPc) * Utils.cmap(Math.sin(t*Math.PI*0.5),-1,1,0,1));
		}

		//color B auto select new hue if !enabled
		if (this.customColorNextEnabled && !this.customColorBNextEnabled && !this.introMode && !this.customColorNextPaletteMode) {
			this.customColorBNext.copy(col);
			var range = Utils.ccmap(power,0,1,0.05,0.02);
			this.customColorBNext.getHSL(hsl);
			this.customColorBNext.setHSL((hsl.h+0.04)%1.0, hsl.s*0.5, hsl.l);
		}

		//color B tween
		if (this.customColorNextEnabled && !this.customColorNextPaletteMode) {
			var t = (SequenceRenderer.gradientTime*0.5+SequenceRenderer.pulseTime*0.5);
			col.lerp(this.customColorBNext, (this.customColorTransitionPc) * Utils.cmap(Math.sin(t*Math.PI*0.33),-1,1,0,1));
			// console.log((1.0-this.customColorTransitionPc) * Utils.cmap(Math.sin(t*Math.PI*0.5),-1,1,0,1));
		}






		//tonnerre flash mode
		if (this.flashMode && this.shouldFlash && !SequenceRenderer.simulationStarted) {
			this.lastSend = now-290;
			// this.user.setLightState(1, {
			// 	hue: 0,
			// 	bri: 255,
			// 	sat: 0,
			// 	transitiontime: 0, //unit is in 0.1s
			// 	on: true
			// });
			this.user.setLightState(SETTINGS.LIGHT_2_ENABLED ? 2 : 1, {
				hue: 0,
				bri: 255,
				sat: 0,
				transitiontime: 0, //unit is in 0.1s
				on: true
			});
		}
		this.shouldFlash = false;


		//intro/pause mode
		if (this.introMode) {
			col.r = col.g = col.b = 1.0;

			col.r *= Utils.cmap(Math.sin(now / 900), -1, 1, 0xd3/255,0xaf/255);
			col.g *= Utils.cmap(Math.sin(now / 900), -1, 1, 0xa3/255,0xf5/255);
			col.b *= Utils.cmap(Math.sin(now / 900), -1, 1, 0xfa/255,0xff/255);
		}

		//tween color & update for click & send every 300ms
		this.latestColor.lerp(
			new THREE.Vector3(col.r,col.g,col.b),
				delta*(0.05+simulationOff*Math.min(SequenceRenderer.multiClickPc+SequenceRenderer.powerEffectPc+SequenceRenderer.currentDragSpeed.length()*2.0+SequenceRenderer.actualPressPc*0.5,1.0)*0.15));

		if (now-this.lastSend > 300) {
			this.lastSend = now;

			var hsb = this.rgb2hsb(this.latestColor.x, this.latestColor.y, this.latestColor.z);

			var brightnessRatioA = 0.25,
				brightnessRatioB = 0.75;

			if ((this.silhouetteMode || this.silhouetteModeNext) && !this.paletteMode) {
				brightnessRatioA = Utils.ccmap(this.customColorTransitionPc, 0, 1, this.silhouetteMode?0.5:0.25, this.silhouetteModeNext?0.5:0.25);
				brightnessRatioB =	Utils.ccmap(this.customColorTransitionPc, 0, 1, this.silhouetteMode?0.5:0.75, this.silhouetteModeNext?0.5:0.75);
				var satExtra = Utils.ccmap(this.customColorTransitionPc, 0, 1, this.silhouetteMode?2.0:1.0, this.silhouetteModeNext?2.0:1.0);
				hsb.s = Utils.clamp(hsb.s * satExtra, 0.0, 1.0);
				hsb.b += Utils.ccmap(this.customColorTransitionPc, 0, 1, this.silhouetteMode?0.25:0.0, this.silhouetteModeNext?0.25:0.0);
			}

			hsb.b = hsb.b*brightnessRatioA + brightnessRatioB*power;

			// hsb.s = Math.min(hsb.s*1.25,1.0);
			this.user.setLightState(SETTINGS.LIGHT_2_ENABLED ? 2 : 1, {
				hue: Math.floor(hsb.h * 65000),
				bri: Math.floor(hsb.b * 255),
				sat: Math.floor(hsb.s * 255),
				transitiontime: (this.flashMode) ? 1 : 3, //unit is in 0.1s
				on: true
			});

			// this.user.setLightState(2, {
			// 	hue: Math.floor(hsb.h * 65000),
			// 	bri: Math.floor(hsb.b * 255),
			// 	sat: Math.floor(hsb.s * 255),
			// 	transitiontime: 3, //unit is in 0.1s
			// 	on: true
			// });
			

		}

	}
};

window.HueController = window.HueController || new HueController();
export default window.HueController;