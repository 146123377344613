import $ from 'jquery';
import FastEvent from './FastEvent.js';
import Utils from './Utils.js';
import Autofill from './Autofill.js';

/*

	<div class="ui-item">
        <div class="ui-color-code"></div>
        <div class="ui-label">input checkbox</div>
        <input class="ui-te-input" type="text" value=""></input>
    </div>

*/
function DropdownMulti(UI) {
	if (!UI.enabled) return;

	//
	// Create the div hierarchy
	//
	var containerDiv = document.createElement('div'),
		colorCodeDiv = document.createElement('div'),
		labelDiv = document.createElement('div'),
		arrowDiv = document.createElement('div'),
		dropdownDivLabel = document.createElement('div'),
		dropdownDiv = document.createElement('div'),
		searchDiv = null,
		searchInput = null;
	this.domElement = containerDiv;
	this.labelDiv = labelDiv;


	containerDiv.appendChild(colorCodeDiv);
	containerDiv.appendChild(labelDiv);
	containerDiv.appendChild(dropdownDiv);

	//
	// Set the classes and basic properties
	//
	$(containerDiv).addClass('ui-item');
	$(colorCodeDiv).addClass('ui-color-code');
	$(labelDiv).addClass('ui-label');
	$(dropdownDiv).addClass('ui-multi-dropdown-input');
	$(arrowDiv).addClass('ui-multi-dropdown-arrow');
	$(dropdownDivLabel).addClass('ui-multi-dropdown-label');

	$(arrowDiv).text('⇅');


	// dropdownDiv.type = 'text';
	dropdownDiv.value = '';
	dropdownDiv.appendChild(dropdownDivLabel);
	dropdownDiv.appendChild(arrowDiv);




	//-------------------
	//
	// props
	//
	//-------------------
	this.uuid = Math.floor(Math.random() * 100000000000).toFixed(0);
	this.value = false;
	this.propName = '';
	this.toWatch = {};
	this.changing = false;
	this.callback = null;
	this.values = [];
	this.path = [];
	this.shouldSend = true;
	this.folder = null;
	this.lastAutofillFolder = null;
	this.autofillExtensions = null;
	this.autofillArrays = null;
	this.props = null;
	this.lastValue = null;
	this.relativeValue = null;
	this.allChildren = new WeakMap();
	this.allDivs = [];

	this.divByOption = {};
	this.hierarchyByOption = {};
	this.hierarchy = [];

	this.absolutePosition = false; //for audioEngine scene

	this.searchEnabled = false;
	this.searchClicked = false;

	//-----------------
	//
	//  Global Overall Div Handling
	//
	//-----------------
	this.dropdownClicked = function(e) {
		
		if (!this.globalDivCreated || this.shouldUpdateRange) this.updateGlobalDiv();

		if (this.globalDivShown) return;
		this.globalDivShown = true;
		var px = $(dropdownDiv).position().left-$(dropdownDiv).width()/2 + 35;
		var py = $(dropdownDiv).position().top;

		if (this.divByOption[this.value]) $(this.divByOption[this.value]).trigger('opt-selected'); else FastEvent.trigger(this, 'unselect');

		if (!this.absolutePosition) {
			$(this.globalDiv).css('right',px+'px').css('top',py+'px').show();
		} else {
			$(this.globalDiv).css('left',(px-128-30)+'px').css('top',(py+20)+'px').show();
		}
		$(this.globalContainerDiv).show();
		// (function(self) {
			$(document.body).off('mousedown.hidedropdown'+this.uuid+' touchstart.hidedropdown'+this.uuid);
			$(document.body).on('mousedown.hidedropdown'+this.uuid+' touchstart.hidedropdown'+this.uuid,function(e) {
				if (this.searchClicked) {
					this.searchClicked = false;
					return;
				}
				if (this.globalDivShown && !this.allChildren.get(e.target)) {
					this.globalDivShown = false;
					$(this.globalDiv).hide();
					$(this.globalContainerDiv).hide();
					$(document.body).off('mousedown.hidedropdown'+this.uuid+' touchstart.hidedropdown'+this.uuid);
				}
			}.bind(this));
		// })(this);

	}.bind(this);
	$(dropdownDiv).click(this.dropdownClicked);

	$(dropdownDiv).on('mousedown',function(e) {
		if (e.button>1) {
			// console.log('copying',this.value);
			// Utils.copyToClipboard({},this.value);
			e.preventDefault();
			return false;
		}
	}.bind(this));

	function recursiveAddFolder(self, folderValues, containerDiv, level, hierarchy) {

		for (var propName in folderValues) {

			var opt = document.createElement('div'),
				icon = document.createElement('div'),
				label = document.createElement('div');

			$(opt).addClass('multi-dropdown-option');
			$(icon).addClass('multi-dropdown-option-icon');
			$(label).addClass('multi-dropdown-option-label');
			$(label).text(propName);
			opt.appendChild(icon);
			opt.appendChild(label);
			containerDiv.appendChild(opt);
			opt.propName = propName;

			self.allChildren.set(opt, true);
			self.allChildren.set(label, true);
			self.allChildren.set(icon, true);
			self.allDivs.push(opt);

			opt.folder = containerDiv.folder;
			opt.isFolder = false;

			if (typeof folderValues[propName] === 'object') {

				$(opt).addClass('multi-dropdown-option-folder');
				$(icon).text('◀').css('font-size','0.62em').css('padding-top', '0.9em');

				var container = document.createElement('div');

				$(container).addClass('multi-dropdown-folder-container');

				opt.isFolder = true;
				container.folder = opt;

				opt.appendChild(container);
				self.allDivs.push(container);
				self.allChildren.set(container, true);

				recursiveAddFolder(self, folderValues[propName], container, level+1, hierarchy.concat(opt));

				//hide other folders on rollover
				(function(level, propName, opt, container) {
					$(opt).on('mouseover', function(e) {
						FastEvent.trigger(self, 'hide-level-'+level);
						$(container).css('display', 'flex');
						$(opt).toggleClass('shown',true);
					});
					FastEvent.on(self, 'hide-level-'+level, function(e) {
						// self.minScrollTop = Math.max(containerDiv.scrollHeight, self.minScrollTop||0);
						$(self.globalDiv).css('margin-bottom', self.minScrollTop+'px');
						// $(self.globalDiv).css('min-height', $(self.globalDiv).height()+'px');

						$(opt).toggleClass('shown',false);
						$(container).hide();
					});

					$(opt).on('dispose', function() {
						if (opt) {
							$(opt).off('mouseover');
							$(opt).off('dispose');
							$(opt).off('click');
							$(opt).off('opt-selected');
							$(opt).off();
						}
						if (container) $(container).empty();
						if (self) FastEvent.off(self, 'unselect');
						if (self) FastEvent.off(self, 'hide-level-'+level);
						if (self) FastEvent.trigger(self, 'hide-level-'+level);
						container = label = level = propName = opt = icon = hierarchy = self = folderValues = containerDiv = level = null;
					});

				})(level, propName, opt, container);

			} else {
				
				self.divByOption[folderValues[propName]] = opt;
				// this.hierarchyByOption[folderValues[propName]] = hierarchy;

				if (propName.length >= 30) $(label).attr('title', folderValues[propName]);

				//add same-level folders on rollover
				(function(level, propName, opt, icon, label, value) {
					$(opt).on('mouseover', function(e) {
						FastEvent.trigger(self, 'hide-level-'+level);
					});

					function selected(e) {
						FastEvent.trigger(self, 'unselect');
						for (var j=0; j<hierarchy.length; j++) {
							$(hierarchy[j]).toggleClass('selected', true);
						}
						$(opt).toggleClass('selected',true);
						$(icon).text('√').toggleClass('selected', true);
						$(label).toggleClass('selected', true);
						self.value = value;

						self.currentSelected = opt;
						$(dropdownDivLabel).text(self.value.split('/').pop());
						$(dropdownDivLabel).attr('title', self.value.split('/').pop());
						this.shouldUpdateUI = true;

						if (self.value !== self.toWatch[self.propName]) {
							self.toWatch[self.propName] = self.value;
							self.changing = true;
							UI.triggerProperty(self.toWatch, self.propName);
							if (self.callback) self.callback.apply(self, [self.value]);
							self.changing = false;

							// Utils.copyToClipboard(document.body,  self.value);
							if (self.globalDivShown && !UI.shiftPressed) {
								self.globalDivShown = false;
								$(self.globalDiv).hide();
								$(self.globalContainerDiv).hide();
								$(document.body).off('mousedown.hidedropdown'+this.uuid+' touchstart.hidedropdown'+this.uuid);
							}
						}
					}

				
					$(opt).on('dispose', function() {
						if (opt) {
							$(opt).off('mouseover');
							$(opt).off('dispose');
							$(opt).off('click');
							$(opt).off('opt-selected');
							$(opt).off();
						}
						if (self) FastEvent.off(self, 'unselect');
						if (container) $(container).empty();
						if (self) FastEvent.off(self, 'hide-level-'+level);
						if (self) FastEvent.trigger(self, 'hide-level-'+level);
						container = label = level = propName = opt = icon = value = hierarchy = self = folderValues = containerDiv = level = null;
					});

					//
					// Selection Event
					//
					$(opt).on('opt-selected', selected);
					$(opt).on('click', selected);
					FastEvent.on(self, 'unselect', function(e) {
						if (self.currentSelected === opt) {
							$(opt).toggleClass('selected',false);
							$(icon).text('').toggleClass('selected', false);
							$(label).toggleClass('selected', false);

							for (var j=0; j<hierarchy.length; j++) {
								$(hierarchy[j]).toggleClass('selected', false);
							}
						}
					});



				})(level, propName, opt, icon, label, folderValues[propName]);
			}
		}
	}

	//
	// Select specific value
	//
	this.cleanupDiv = function() {
		if (this.globalDiv) {
			for (var i=0; i<this.allDivs.length; i++) {
				$(this.allDivs[i]).trigger('dispose').off().empty().remove();
			}
			$(this.globalDiv).empty().off().remove();
			$(this.globalContainerDiv).empty().remove();

			this.allDivs = [];
			this.divByOption = {};
			this.allChildren = new WeakMap();
			this.globalDiv = null;
			this.globalContainerDiv = null;
		}
	};

	this.updateGlobalDiv = function() {
		this.shouldUpdateRange = false;
		this.globalDivCreated = true;

		//
		// Cleanup old div
		//
		this.cleanupDiv();
		
		this.globalDiv = document.createElement('div');
		$(this.globalDiv).addClass('multi-dropdown');

		if (searchDiv) {
			this.globalDiv.appendChild(searchDiv);
			$(searchInput).focus();
			
			searchInput.value = '';
			searchInput.oninput = this.updateSearch;
			searchDiv.onmousedown = function(e) {
				this.searchClicked = true;
				$(searchInput).focus();
			}.bind(this);
		}



		this.globalContainerDiv = document.createElement('div');
		$(this.globalContainerDiv).addClass('multi-dropdown-global-container');
		this.globalContainerDiv.appendChild(this.globalDiv);
		document.body.appendChild(this.globalContainerDiv);

		if (!this.globalDivShown) {
			$(this.globalContainerDiv).hide();
			$(this.globalDiv).hide();
		}

		//split values by folder
		this.values = this.updateRangeValues || this.values;

		if (this.autofillBaseValues) this.values = this.autofillBaseValues.concat(this.values);


		//
		//  Create array that looks like this:
		//  {valueA: originalValue,  folder: {valueB: originalValue }}
		//
		var allFolders = {};
		for (var i=0; i<this.values.length; i++) {
			var np = allFolders;
			var folderSplit = this.values[i].split('/');
			while (folderSplit.length > 1) {
				var f = folderSplit.shift();
				if (f) {
					np[f] = np[f] || {};
					np = np[f];
				}
			}
			//start with basevalues only & folders at the top
			if (this.autofillBaseValues && this.autofillBaseValues.length > i) {
				var p = folderSplit.shift();
				if (p) np[p] = this.values[i];
			}
		}
		for (var i=0; i<this.values.length; i++) {
			var np = allFolders;
			var folderSplit = this.values[i].split('/');
			while (folderSplit.length > 1) {
				var f = folderSplit.shift();
				if (f) {
					np[f] = np[f] || {};
					np = np[f];
				}
			}
			if (!this.autofillBaseValues || this.autofillBaseValues.length <= i) {
				var p = folderSplit.shift();
				if (p) np[p] = this.values[i];
			}
		}

		//
		// Create the appropriate divs for each subfolder
		//
		recursiveAddFolder(this, allFolders, this.globalDiv, 0, []);
		if (searchDiv) $(searchInput).focus();

	}.bind(this);


	

	// //-------------------
	// //
	// // Searching
	// //
	// //-------------------
	// function createSearchDivs() {
	// 	if (searchDiv) {
	// 		return;
	// 		$(searchInput).off().remove();
	// 		$(searchDiv).off().remove();
	// 		searchDiv = searchInput = null;
	// 	}

	// 	searchDiv = document.createElement('div');
	// 	$(searchDiv).addClass('multi-dropdown-option');
	// 	searchInput = document.createElement('input');
	// 	$(searchInput).addClass('multi-dropdown-search');
	// 	searchInput.type = 'text';
	// 	searchInput.placeholder = 'Search';
	// 	searchDiv.appendChild(searchInput);
		
	// 	// searchInput.oninput = this.updateSearch;
	// 	// searchDiv.onmousedown = function(e) {
	// 	// 	this.searchClicked = true;
	// 	// 	$(searchInput).focus();
	// 	// }.bind(this);
	// 	// window.searchInput = searchInput;

	// 	// console.log('SEARCH')
	// 	// $(searchInput).on('input', this.updateSearch);
	// 	// $(searchDiv).on('mousedown', function(e) {
	// 	// 	this.searchClicked = true;
	// 	// 	$(searchInput).focus();
	// 	// }.bind(this));
	// };

	this.updateSearch = function(e) {

		this.currentSearch = searchInput.value;
		var regexp = new RegExp(this.currentSearch);

		for (var i=0; i<this.allDivs.length; i++) {
			if (this.allDivs[i].folder) {
				this.allDivs[i].folder.searchNum = 0;
			}
			if (this.allDivs[i].isFolder) this.allDivs[i].searchNum=0;
		}
		for (var i=0; i<this.allDivs.length; i++) {
			if (regexp.test(this.allDivs[i].propName)) {
				$(this.allDivs[i]).show();
				// if (this.allDivs[i].folder) this.allDivs[i].folder.searchNum++;
				if (this.allDivs[i].isFolder) this.allDivs[i].searchNum++;

				var f = this.allDivs[i].folder;
				while(f) {
					f.searchNum++;
					f = f.folder;
				}

			} else {
				$(this.allDivs[i]).hide();
			}
		}
		for (var i=0; i<this.allDivs.length; i++) {
			if (this.allDivs[i].isFolder) {
				if (this.allDivs[i].searchNum<=0) {
					$(this.allDivs[i]).hide();
				} else {
					$(this.allDivs[i]).show();
				}
			}
		}
		FastEvent.trigger(this, 'hide-level-6');
		FastEvent.trigger(this, 'hide-level-5');
		FastEvent.trigger(this, 'hide-level-4');
		FastEvent.trigger(this, 'hide-level-3');
		FastEvent.trigger(this, 'hide-level-2');
		FastEvent.trigger(this, 'hide-level-1');
		FastEvent.trigger(this, 'hide-level-0');


	}.bind(this);



	//-------------------
	//
	// object.observe change
	//
	//-------------------
	this.updateValue = function(evt, changes) {
		//prevent double changes / infinite loops
		if (this.changing)
			return;

		this.changing = true;
		var lv = this.value;
		this.value = this.toWatch[this.propName];
		if (lv !== this.value) {
			this.shouldUpdateUI = true;
			this.updateUI();
			if (this.callback) this.callback.apply(this, [this.value]);
		}
		this.changing = false;

	}.bind(this);



	var autoArray = function(evt, changes) {
		if (this.propName === 'source') {
			// console.log('autofill',this, this.autofillArrays, UI.currentSoundEngine.getNamedArray('allModulators'));
		}
		var arr = [];
		for (var j=0; j<this.autofillArrays.length; j++) {
			var na = this.autofillArrays[j];
			if (typeof na === "string") na = UI.currentSoundEngine.getNamedArray(na.replace('SoundEngine.',''));
			arr = arr.concat(na);
		}
		this.updateRange(arr);

	}.bind(this);


	//
	// init the properties
	//
	this.init = function(toWatch, propName, props, path) {
			

		if (this.propName) console.log('INIT TWICE SEARCH')

		this.propName = propName;
		this.toWatch = toWatch;
		this.path = path;
		this.props = props;
		if (props.shouldSend!== undefined) this.shouldSend = props.shouldSend;


		if (props.searchEnabled||true) {
			this.searchEnabled = true;
			
			searchDiv = document.createElement('div');
			$(searchDiv).addClass('multi-dropdown-option');
			searchInput = document.createElement('input');
			$(searchInput).addClass('multi-dropdown-search');
			searchInput.type = 'text';
			searchInput.placeholder = 'Search';
			searchDiv.appendChild(searchInput);
		}


		//add options
		for (var i=0; i<props.values.length; i++) {
			// var d = document.createElement('option');
			// d.value = props.values[i];
			// $(d).text(props.values[i]);
			// dropdownDiv.appendChild(d);
		}
		this.values = props.values;
		// this.shouldUpdateRange = true;


		this.globalDivCreated = false;
		this.globalDiv = null;
		this.globalContainerDiv = null;

	


		// $(dropdownDiv).on('change', this.updateValue);

		this.updateValue(null, [{name:propName}]);


		$(labelDiv).text(propName);
		$(colorCodeDiv).css('background-color', props.colorCode || '#'+Math.floor(Math.random()*0xffffff).toString(16));

		UI.watchProperty(this.toWatch, this.propName, this.updateValue);

		FastEvent.on(toWatch, this.propName+'-random', function() {
			this.toWatch[this.propName] = Utils.randoma(this.values);
			UI.triggerProperty(this.toWatch, this.propName);
		}.bind(this));

		this.callback = props.callback;

		FastEvent.on(props, 'updateRange', function(evt) {
			this.values = props.values
			this.shouldUpdateRange = true;
		}.bind(this));


		this.autofillBaseValues = props.autofillBaseValues;
		if (props.autofill) {
			this.autofillBaseValues = props.autofillBaseValues;
			this.autofillSort = props.autofillSort;
			this.autofillExtensions = props.autofillExtensions;
			this.autofillAllowFolders = props.autofillAllowFolders;
			this.autofill(props.autofill);

			if (props.autofillEvent) {
				FastEvent.on(props, 'autofill', function(evt) {
					this.autofill(props.autofill, true);
				}.bind(this));
			}
			

		} else if (props.autofillArrays) {
			this.autofillArrays = props.autofillArrays;
			for (var i=0; i<props.autofillArrays.length; i++) {
				var arr = props.autofillArrays[i];
				if (typeof arr === 'string') {
					FastEvent.on(SoundEngine, 'updateArray-'+arr.replace('SoundEngine.',''), autoArray);
				} else {
					//Object.observe(arr, autoArray);
				}
			}
			autoArray();
		}


		// $(this.toWatch).bind('modulateDropdown-'+this.propName, function(e,value) {
		// 	this.toWatch[this.propName] = this.values[value];
		// }.bind(this));
		this.props.numValues = this.values.length;


		// //
		// // Midi link
		// //
		// this.props = props;
		// UI.midiEnable(props, props.midiChannel, labelDiv);
		// FastEvent.on(props,'midiMessage', function(e,channel, note, velocity) {
		// 	var value = Math.floor( Utils.cmap(velocity, 0, 127, 0, this.values.length-1) );
		// 	if (UI.shiftPressed) {
		// 		if (this.relativeValue === null) {
		// 			this.relativeValue = this.value;
		// 		}
		// 		value = this.relativeValue + Utils.cmap(velocity, 0, 127, -64, 64);
		// 		value = Math.floor(value);
		// 	} else {
		// 		this.relativeValue = null;
		// 	}
		// 	var lv = this.toWatch[this.propName];
		// 	this.toWatch[this.propName] = this.values[value];
		// 	if (lv!==this.toWatch[this.propName]) UI.triggerProperty(this.toWatch, this.propName);
		// }.bind(this));

	}.bind(this);


	this.setLabel = function(newName) {
		$(labelDiv).text(newName);
	};

	//
	// Update UI 
	//
	this.canUpdateUI = true;
	this.shouldUpdateUI = false;
	this.shouldUpdateRange = false;
	this.updateRangeValues = null;

	// this.lastUpdateFrame = -1;
	this.updateUI = function() {
		if (!this.canUpdateUI) return;
		if (!this.shouldUpdateUI) return;
		this.shouldUpdateUI = false;
		if (this.lastUpdateFrame === UI.currentFrame) return;
		this.lastUpdateFrame = UI.currentFrame;
		
		if (!dropdownDiv) return;
			this.changing = true;
		// dropdownDiv.value = this.value.split('/').pop();
		var splitval = this.value ? this.value.split('/').pop() : '';
		$(dropdownDivLabel).attr('title',splitval);
		$(dropdownDivLabel).text(splitval);
		// $(dropdownDivLabel).text(self.value);
						// $(dropdownDivLabel).attr('title', self.value);
		this.changing = false;
		
	}.bind(this);



	//update range
	this.updateRange = function(values) {
		if (this.disposed) return;
		this.shouldUpdateRange = true;
		this.updateRangeValues = values;
	}.bind(this);


	//------------
	//
	// Autofill
	//
	//-----------
	this.autofill = function(folder, force) {
			folder = folder || this.folder;

		if (!folder) {
			console.warn('trying to autofill with no target folder');
			return;
		}

		if (this.lastAutofillFolder === folder && !force) {
			return;
		}
		this.lastAutofillFolder = folder;

		Autofill(this.props, UI.autofillEnabled, function(list) {

			if (this.props.customSort) {
				list.sort(this.props.customSort);
			}
			this.updateRange(list);
		}.bind(this));
	};


	$(labelDiv).on('click', function(e) {
		if (e.altKey) {
			// Utils.copyToClipboard(labelDiv, this.toWatch[this.propName]);
				// this.path.concat(this.propName).join('.'));
		}
	}.bind(this));
	
	//
	// Clean up
	//
	this.disposed = false;
	this.dispose = function() {
		if (this.disposed) return;
		this.disposed = true;

		this.cleanupDiv();
		// UI.midiDisable(this.props, this.props.midiChannel, labelDiv);
		// FastEvent.off(this.props, 'midiMessage');
		$(document.body).off('mousedown.hidedropdown touchstart.hidedropdown'+this.uuid);

		// Object.unobserve(this.toWatch, this.updateValue);
		if (this.autofillArrays) {
			for (var i=0; i<this.autofillArrays.length; i++) {
				var arr = this.autofillArrays[i];
				if (typeof arr === "string") {
					// arr = UI.getNamedArray(arr);
					// this.soundEngine.addNamedArraysCallbacks(arr, autoArray);
					FastEvent.off(SoundEngine, 'updateArray-'+arr.replace('SoundEngine.',''), autoArray);
				} else {
					//Object.unobserve(arr, autoArray);
				}
			}
		}
		this.autofillArrays = [];
		$(dropdownDiv).off('change', this.updateValue);

		this.callback = null;
		$(containerDiv).off();
		$(colorCodeDiv).off();
		$(labelDiv).off();
		$(dropdownDiv).off();
		if (searchDiv) $(searchDiv).off();
		if (searchInput) $(searchInput).off();

		$(containerDiv).remove();
		$(colorCodeDiv).remove();
		$(labelDiv).remove();
		$(dropdownDiv).remove();
		if (searchDiv) $(searchDiv).remove();
		if (searchInput) $(searchInput).remove();

		this.domElement = null;
		this.labelDiv = null;
		containerDiv = null;
		colorCodeDiv = null;
		labelDiv = null;
		searchInput = null;
		dropdownDiv = null;
		searchDiv = null;
	};

};
export default DropdownMulti;