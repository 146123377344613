import $ from 'jquery';

import SETTINGS from '../Settings.js';
import AppStatus from './AppStatus.js';
import EmbeddedTextFR from '../../html/texte_fr.js';
import EmbeddedTextEN from '../../html/texte_en.js';
import AnalyticsController from './AnalyticsController.js';
import AudioController from './AudioController.js';
import StorageController from './StorageController.js';

import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

const EmbeddedText = SETTINGS.LANGUAGE === 'fr' ? EmbeddedTextFR : EmbeddedTextEN;


// var load_framework = (function() {
//     var indexOptions = "";
//     function init(indexOptions, statics_root_url) {
//         if (!statics_root_url){
//             statics_root_url = "https://interactive-pip.nfb.ca/static/";
//         }
//         load_framework.indexOptions = indexOptions;
//         $('head').append( $('<link rel="stylesheet" type="text/css" />').attr('href', statics_root_url + 'css/interactive-framework-header.min.css') );
//         // $('head').append( $('<script src="https://interactive-pip.nfb.ca/v2/app.js" type="text/javascript"></script>');



//         	// .attr('src', statics_root_url)href', statics_root_url + 'css/interactive-framework-header.min.css') );

//         // var listener = setInterval(function(){
//         //     if($("body").css("page-break-after")=== "always"){
//         //         clearInterval(listener);
//         //         $("body").css("page-break-after","auto");
                
//         //         $.when(
//         //             $.ajax({crossDomain: true, type: "GET", url: statics_root_url + "../templates/header_iframe_parent.html", dataType: "html"}),
//         //             $.ajax({crossDomain: true, type: "GET", url: statics_root_url + "js/lib/interactive_framework_lib.min.js", dataType: "script"}),
//         //             $.ajax({crossDomain: true, type: "GET", url: statics_root_url + "js/header.js", dataType: "script"}),
//         //             $.ajax({crossDomain: true, type: "GET", url: statics_root_url + "js/nfb_ga.js", dataType: "script"}))
//         //         .then(function(htmlReturned, cssReturned) {
//         //             $('body').prepend(htmlReturned[0]);
//         //             header.init(indexOptions, statics_root_url);
//         //             $('#containerOverlay').append('<iframe  width="100%" height="100%" id="overlayIframe" name="overlayIframe"></iframe>'); //add iframe src
//         //             $('#overlayIframe').attr('src', statics_root_url + '../templates/iframe_child.html');
//         //             $(document).trigger( "ifw_headerLoaded");
//         //         });
//         //     }
//         // },50);
//     }


//     return {
//         init: init,
//         load_framework: load_framework
//     };
// })();


function MenuController() {
	this.shown = false;
	this.dragging = false;
	this.animationTimeout = -1;
	this.infoAnimationTimeout = -1;

	this.nfbHeaderShown = false;
	this.infoPageOpen = false;
	this.aboutPageOpen = false;
	this.creditsPageOpen = false;
	this.sharePageOpen = false;
	this.initialized = false;

	this.chapterNumber = {};
	this.chapterPartOneTwo = '';

	this.addedToPageMode = false;

	this.nfbHeaderLoaded = false;
	this.nfbHeaderCustomized = false;
	// this.nfbHeaderLoaded = fals;e


	// this.drag
	this.init = function() {
		this.initialized = true;
		if (SETTINGS.MENU_DISABLED) return;


		// $('#menu').on('touchstart', this.touchDown);
		// $('#menu').on('touchend', this.touchUp);
		// $('#menu').on('touchmove', this.touchMove);
		// $('#menu').toggleClass('animate-up', true);

		//
		// Language SETTINGS
		//
		if (SETTINGS.LANGUAGE == 'fr') {
			$('#menu-header-language').text('EN');
		} else {
			$('#menu-header-language').text('FR');
		}
		$('#about-page').html(EmbeddedText.pages["about"]);
		$('#credits-page').html(EmbeddedText.pages["credits"]);
		$('#share-page').html(EmbeddedText.pages["share"]);
		// if (SETTINGS.isMobile) {
		// 	$('.about-link').toggleClass('disabled', true);
		// 	$('.about-link').attr('href','');
		// }
		/*
		<div class="share-large">
					<div class="share-btn" id="share-facebook">
						<div id="facebook-logo"></div>
						<div class="share-text">Facebook</div>
					</div>
				</div>
				<div class="share-large">
					<div class="share-btn" id="share-twitter">
						<div id="twitter-logo"></div>
						<div class="share-text">Twitter</div>
					</div>
				</div>
				<div class="share-large" id="share-link-container">
					<div class="share-btn" id="share-btn-link">
						<div id="sharelink-logo" id="share-link"></div>
						<div class="share-text">Copy Link</div>
					</div>
				</div>
				*/

		//share page events
		if (!ClipboardJS.isSupported()) {
			$('#share-link-container').hide();
		} else {


			$('#share-btn-link').on('touchstart',function() {
				$('#sharelink-logo').toggleClass('active',true);
				$('#share-btn-link').find('.share-text').toggleClass('active',true);
			}).on('touchend', function() {
				$('#sharelink-logo').toggleClass('active',false);
				$('#share-btn-link').find('.share-text').toggleClass('active',false);
			})
				
			$('#share-facebook').on('touchstart',function() {
				$('#facebook-logo').toggleClass('active',true);
				$('#share-facebook').find('.share-text').toggleClass('active',true);
			}).on('touchend', function() {
				$('#facebook-logo').toggleClass('active',false);
				$('#share-facebook').find('.share-text').toggleClass('active',false);
			}).on('click', function(e) {
				AnalyticsController.trackEventDirectly("share_facebook");
				window.open('http://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent(EmbeddedText.meta.url),'_blank');
			});

			$('#share-twitter').on('touchstart',function() {
				$('#twitter-logo').toggleClass('active',true);
				$('#share-twitter').find('.share-text').toggleClass('active',true);
			}).on('touchend', function() {
				$('#twitter-logo').toggleClass('active',false);
				$('#share-twitter').find('.share-text').toggleClass('active',false);
			}).on('click',function(e) {
				AnalyticsController.trackEventDirectly("share_twitter");
				if (SETTINGS.isMobile) {
					window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(EmbeddedText.meta.share_twitter) + '&url='+encodeURIComponent((SETTINGS.LANGUAGE=='fr'?'serotonine.onf.ca':'brainstream.nfb.ca')),'_blank');
				} else { //must add https on desktop for some reason
					window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(EmbeddedText.meta.share_twitter) + '&url='+encodeURIComponent((SETTINGS.LANGUAGE=='fr'?'serotonine.onf.ca':'brainstream.nfb.ca')),'_blank');
				}
			});

			this.clip = new ClipboardJS(document.getElementById('share-btn-link')||document.createElement('div'), {
			    text: function(trigger) {
			        return (SETTINGS.LANGUAGE=='fr'?'serotonine.onf.ca':'brainstream.nfb.ca')
			        // EmbeddedText.meta.url
			    }
			});

			this.clip.on('success', function(e) {
				$('#share-btn-link').find('.share-text').html(SETTINGS.LANGUAGE=='fr'?'Copié':'Copied')
				$('#share-btn-link').toggleClass('copied', true);
				setTimeout(function() {
					$('#share-btn-link').toggleClass('copied', false);
				},200);
			    e.clearSelection();
				AnalyticsController.trackEventDirectly("share_copy_link");
			});

			this.clip.on('error', function(e) {
			});
		}


		//
		// NFB Header
		//
		// if (!SETTINGS.BETA_MODE) {
			$(document).on('ifw_openOverlay', ()=>{this.infoPageOpen=true;});
			$(document).on('ifw_closeOverlay', ()=>{if (this.shown && this.infoPageOpen) { this.hide(); }this.infoPageOpen=false;});
			var indexOptions = {
		        projectId: 241,
			    baseUrl: {
			      en: "https://brainstream.nfb.ca",
			      fr: "https://serotonine.onf.ca"
			    },
			    forceLocale: SETTINGS.LANGUAGE,
			    theme: 'black',
			    transparent: false,
			    hideOnScroll: false,
			    hideOnNoActivity: false,
			    logoPosition: SETTINGS.isMobile ? 'left' : 'center',
			    buttons: {
			      grid: !SETTINGS.isMobile,
			      about: false,
			      switchLang: true,
			      toggleSound: false,
			      toggleFullscreen: !SETTINGS.isMobile,
			      share: false,
			      shareFB: false, //SETTINGS.isMobile,
			      shareTwitter: false //SETTINGS.isMobile
			    },
			    openInNewWindow: {
			    	about: false,
			     	grid: true
			    },
			    devMode: false
		    };
		    $(document).on('ifw_headerLoaded', ()=>{
		    	this.nfbHeaderShown = true;
		    	console.log("NFB HEADER SHOWN");
		    	if (window.ifw_header) window.ifw_header.init(indexOptions);
		    	this.nfbHeaderLoaded = true;
		    	$('#menu-header-container').remove();
		    	// setTimeout(()=>{
		    	if (!this.shown)  {
		    		try { window.ifw_header.hide(); } catch(er) {
		    			console.warn("nfb menu error: ",er);
		    		}
		    		setTimeout(window.ifw_header.hide,500);
		    	}
		    	if (AnalyticsController.sendQueue) AnalyticsController.sendQueue();
		    	$('#pause-aboutbtn-container').on('click', this.openAbout);
		    	$('#intro-aboutbtn-container').on('click', this.openAbout);

		    	// window.ifw_header.hide();

		    	// },1000)

		    	// $('#interactive-header').toggleClass('animate-up', 'true');
		    	// $('#interactive-header').css('transition','transform 0.2s ease-out');
		    	// $('#interactive-header').css('pointer-events', 'none');
		    	// $('#interactive-header').hide();
		  //   	document.getElementById('interactive-header').addEventListener(SETTINGS.isMobile ? 'touchend' : 'click', function(e) {
			 //    	if (!this.shown) {
			 //    		e.preventDefault();
			 //    		return false;
			 //    	}
				// }.bind(this),{passive: false});
				// document.getElementById('interactive-header').addEventListener(SETTINGS.isMobile ? 'touchstart' : 'mousedown', function(e) {
			 //    	if (!this.shown) {
			 //    		e.preventDefault();
			 //    		return false;
			 //    	}
				// }.bind(this),{passive: false});
		    });
		    if (SETTINGS.NFB_MENU) {
		    	var script = document.createElement('script');
				script.type = "text/javascript";
				script.async = 1;
				if (SETTINGS.TIMELINE_MODE) {
					script.src = "https://interactive-pip.nfb.ca/v2/app.js";
					document.body.appendChild(script);
				}
				$('#menu-header-container').remove();
		    }
		    

		    // if (window.ifw_header) window.ifw_header.init(indexOptions);
		    
		// }
		
		// if (SETTINGS.INTRO_MODE || !AppStatus.MAIN_LOADED) {
	 //    	$('#menu-bar-container').hide();
	    	
	 //    }


	 	// $('#menu-header-container').css('display', 'flex');
	 	$('#menu-button').show();

	 	$('#menu-pages').hide();

		$('#menu-header-info-container').on('click',this.openAbout);
		$('#menu-header-share-container').on('click',this.openShare);

		$('#menu-header-language').on('click',AppStatus.changeLanguage);

		$('#menu-header-back').on('click',this.closeInfoDelayed);
		$('#info-back-button').on('click',this.closeInfo);

		$('#menu-button').on('click', this.openAbout);


	 	$('#end-credits').on('click', this.openCredits);
	 	$('#end-share').on('click', this.openShare);
	 	$('#end-restart').on('click', function(e) {
	 		$('#end-restart').toggleClass('pressed', true);
			AnalyticsController.trackEventDirectly("end-restart");
	 		StorageController.clearState();
	 		window.location.reload();
	 	});

	 	if (!SETTINGS.isMobile) $('#menu-header-fs-container').css('display', 'inline-flex');
	 	if (SETTINGS.isMobile) {
	 		$('#info-back-button').toggleClass('mobile-noselect', true);
	 		$('#menu-header-info').toggleClass('mobile-noselect', true);
			$('#menu-header-language').toggleClass('mobile-noselect', true);
			$('#menu-header-info-container').toggleClass('mobile-noselect', true);
			$('#menu-header-language-container').toggleClass('mobile-noselect', true);
	 	}


	 	$('#menu-header-fs-container').on('click', function(e) {
	 		console.log("fs");
	 		var element = document.body;
			if (event instanceof HTMLElement) {
				element = event;
			}
			var isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

			element.requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || function () { return false; };
			document.cancelFullScreen = document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || function () { return false; };

			isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();

			$('#menu-header-fs-container').toggleClass('active',!isFullscreen);
	 	});


	 	$('#pause-container').on('click', this.resume);
	 	$('#pause-restart').on('click', function() {
			AnalyticsController.trackEventDirectly("pause-restart");
	 		StorageController.clearState();
			window.location.reload();
	 	});


	 	if (SETTINGS.INSTALLATION_MODE) {
	 		window.hardRestart = -1;
			$('#pause-restart').on("touchstart mousedown",function(){
			    window.hardRestart = setTimeout(function(){
			    	window.location.reload();
			    },4000);
			}).on("touchup mouseup mouseleave touchleave",function(){
			    clearTimeout(window.hardRestart);
			});
	 	}


	 	if (SETTINGS.isMobile) {
	 		$('#timeselect-buttons-container').toggleClass('mobile', true);
	 		$('#early-end-buttons-container').toggleClass('mobile', true);
	 	}

		// if (SETTINGS.isIOS) {
		// 	(function() {
		// 		var lastTap = 0;
		// 		document.getElementById('menu').addEventListener('touchstart', function(e) {
		// 			var lt  = lastTap;
		// 			lastTap = performance.now();
		// 			if (performance.now()-lt < 500) {
		// 				e.preventDefault();
		// 				return false;
		// 			}
		// 		},{passive: false});
		// 	})();
		// }

	};

	this.customizeNFBMenu = function(e) {
		if (this.nfbHeaderCustomized) return;
		if ($('#interactive-header').length <= 0) return;
		this.nfbHeaderCustomized = true;

		// console.log("NFB MENU CUSTOMIZED");
		
		var parent = $('.switch-lang-link').parent();
		var lang = $('.switch-lang-link');
		parent.prepend($('<button id="nfb-custom-share-container"><div id="nfb-custom-share"></div></div>'));
		parent.prepend($('<button id="nfb-custom-info-container"><div id="nfb-custom-info"></div></div>'));
		parent.append(lang.remove());
		// $('#interactive-header').css('z-index',3);
		// console.log($('#interactive-header .flex-container')[1]);



		$('#nfb-custom-info-container').on('click',this.openAbout);
		$('#nfb-custom-share-container').on('click',this.openShare);


	}.bind(this);

	


	this.openAbout = function(e) {

		if (this.infoAnimationTimeout>-1) return;
		if (this.aboutPageOpen) {
			this.aboutPageOpen = false;
			$('#menu-header-info-container').toggleClass('active', false);
			// this.hide();
			this.closeInfoDelayed();
			return;
		}
		this.aboutPageOpen = true;
		this.sharePageOpen = false;
		this.creditsPageOpen = false;
		AnalyticsController.pageview('/about');

		$('#info-page-header-text').text(EmbeddedText.pages["about-title"]);


		$('#share-page').hide();
		$('#credits-page').hide();
		$('#about-page').show();
		$('#menu-header-info-container').toggleClass('active', true);
		$('#menu-header-share-container').toggleClass('active', false);

		this.openInfo();
		$('#info-page').scrollTop(0);

	}.bind(this);


	this.openCredits = function(e) {

		if (this.infoAnimationTimeout>-1) return;
		if (this.creditsPageOpen) {
			this.creditsPageOpen = false;
			$('#menu-header-info-container').toggleClass('active', false);
			$('#menu-header-share-container').toggleClass('active', false);
			// this.hide();
			this.closeInfoDelayed();
			return;
		}
		this.creditsPageOpen = true;
		this.aboutPageOpen = false;
		this.sharePageOpen = false;
		AnalyticsController.pageview('/credits');

		$('#info-page-header-text').text(EmbeddedText.pages["credits-title"]);

		$('#credits-page').show();
		$('#share-page').hide();
		$('#about-page').hide();
		$('#menu-header-info-container').toggleClass('active', false);
		$('#menu-header-share-container').toggleClass('active', false);

		this.openInfo();
		$('#info-page').scrollTop(0);

	}.bind(this);




	this.openShare = function(e) {
		//use
		if (navigator.share && SETTINGS.isMobile) {
			navigator.share({
			  title: EmbeddedText.meta.title,
			  text: EmbeddedText.meta.share_others,
			  url: EmbeddedText.meta.url
			}).then(function(sucess, error){
				console.log(error);
				AnalyticsController.trackEvent("native_share");
			}).catch((e) =>{
				if (e && e.message && e.message.startsWith('Abort')) {
					console.log("Abort share")
				} else {
					console.warn("Share error",e);
					AnalyticsController.trackEvent("share_page");
					this.openSharePage();
				}
				
			});
		} else {
			this.openSharePage();
		}

		

	}.bind(this);

	this.openSharePage = function(e) {
		if (this.infoAnimationTimeout>-1) return;
		if (this.sharePageOpen) {
			this.sharePageOpen = false;
			$('#menu-header-share-container').toggleClass('active', false);
			// this.hide();
			this.closeInfoDelayed();
			return;
		}
		this.creditsPageOpen = false;
		this.aboutPageOpen = false;
		this.sharePageOpen = true;
		AnalyticsController.pageview('/share');

		$('#info-page-header-text').text(EmbeddedText.pages["share-title"]);
		$('#share-btn-link').find('.share-text').html(SETTINGS.LANGUAGE=='fr'?'Copier le lien':'Copy link')

		$('#share-page').show();
		$('#about-page').hide();
		$('#credits-page').hide();
		$('#menu-header-info-container').toggleClass('active', false);
		$('#menu-header-share-container').toggleClass('active', true);

		this.openInfo();
	}.bind(this);


	this.openInfo = function() {
		if (this.infoPageOpen) return;
		this.infoPageOpen = true;
		// AnalyticsController.pageview('/info');

		this.show();

		$('#info-page').scrollTop(0);
		$('#info-page').toggleClass('hide', false);
		$('#info-page').toggleClass('hide-out', false);
		$('#info-page').show();
		$('#info-page').toggleClass('animate-up', false);
		if (this.infoAnimationTimeout>=0) window.clearTimeout(this.infoAnimationTimeout)
		this.infoAnimationTimeout = setTimeout(function() {
			$('#info-page').toggleClass('animate-up', true);
			setTimeout(function(){if (!this.shown) this.show();}.bind(this),200);
			// this.infoAnimationTimeout = -1;
			this.infoAnimationTimeout = setTimeout(()=>this.infoAnimationTimeout=-1,200);
		}.bind(this),16);
	}.bind(this);

	// this.closeAbout = function(e) {
	// 	if (e && this.infoPageOpen) {this.hide();}
	// 	this.closeInfo();
	// }.bind(this);
	
	// this.closeShare = function(e) {
	// 	if (e && !this.infoPageOpen) {this.hide();}
	// 	this.closeInfo();
	// }.bind(this);

	this.closeInfo = function() {
		if (!this.infoPageOpen) return;
		this.sharePageOpen = false;
		$('#menu-header-share-container').toggleClass('active', false);
		this.aboutPageOpen = false;
		$('#menu-header-about-container').toggleClass('active', false);
		this.creditsPageOpen = false;
		// this.hide();
		this.closeInfoDelayed();


	}.bind(this);

	this.closeInfoDelayed = function() {
		if (!this.infoPageOpen) return;
		this.infoPageOpen = false;
		
		$('#info-page').toggleClass('animate-up', false);
		// $('#info-page').toggleClass('hide', true);
		$('#info-page').toggleClass('hide-out', false);
		$('#info-page').toggleClass('hide', true);
		$('#info-page').toggleClass('hide-out', true);
		$('#menu-header-info-container').toggleClass('active', false);
		$('#menu-header-share-container').toggleClass('active', false);
		if (this.infoAnimationTimeout>=0) window.clearTimeout(this.infoAnimationTimeout)
		this.infoAnimationTimeout = setTimeout(function() {
			$('#info-page').toggleClass('hide', false);
			$('#info-page').toggleClass('hide-out', false);
			$('#info-page').hide();
			// $('#menu').css('display','none');
			// $('#menu-button').css('display','none');
			// $('#interactive-header').hide();
			this.infoAnimationTimeout = -1;
		}.bind(this),210);
	}.bind(this);


	this.openRestart = function() {
		this.show();
		this.selectRestart();
	}.bind(this);

	this.toggle = function() {
		if (SETTINGS.MENU_DISABLED) return;
		if (!this.initialized) return;
		if (this.shown) this.hide(); else this.show();
		if (this.infoPageOpen) {
			if (window.header) window.header.closeOverlay();
			this.closeInfoDelayed();
		}
	}.bind(this);

	this.show = function() {
		if (SETTINGS.MENU_DISABLED) return;
		if (SETTINGS.ERROR_MODE) return;
		if (this.shown) return;

		if (this.nfbHeaderLoaded) window.ifw_header.reveal();

		$('#menu-button').toggleClass('open', true);

		this.shown = true;

		if (!SETTINGS.MENU_BAR_HIDDEN) {
			$('#menu-header-container').css('display', 'flex').hide().stop().fadeIn(150);
			$('#menu-header-container').css('pointer-events', 'auto');
		}

		
	}.bind(this);

	this.hide = function() {
		if (SETTINGS.MENU_DISABLED) return;
		if (!this.shown) return;
		if (AppStatus.startMenuShown|| AppStatus.isOnEndPage) return;

		if (this.nfbHeaderLoaded) window.ifw_header.hide();

		$('#menu-button').toggleClass('open', false);
		$('#menu-header-container').css('pointer-events', 'none');
		$('#menu-header-container').stop().fadeOut(250);

		AudioController.pausedForMenu = false;

		this.shown = false;
	}.bind(this);

	this.hideSlow = function(seconds) {
		if (SETTINGS.MENU_DISABLED) return;
		if (!this.shown) return;
		AudioController.pausedForMenu = false;

		if (this.nfbHeaderLoaded) window.ifw_header.hide();

		$('#menu-button').toggleClass('open', false);
		$('#menu-header-container').stop().fadeOut(seconds*1000);
		$('#menu-header-container').css('pointer-events', 'none');
		this.shown = false;
	}.bind(this);


	this.showEndDivs = function() {
		if (this.endDivsShown) return;
		if (SETTINGS.INSTALLATION_MODE) return;
		this.endDivsShown = true;
		this.show();
		$('#end-buttons-container').toggleClass('fade-out-standard', false).toggleClass('fade-in-standard', true);
		
		AnalyticsController.trackEvent("the_end");
	}
	this.hideEndDivs = function() {
		if (!this.endDivsShown) return;
		this.endDivsShown = false;
		this.hideSlow();
		$('#end-buttons-container').toggleClass('fade-in-standard', false).toggleClass('fade-out-standard', true);
	}


	this.pause = function() {

		this.pauseStartTime = performance.now();

		if (SETTINGS.INSTALLATION_MODE) {
			$('#pause-container').toggleClass('reload-old', true);
			$('#pause-restart').toggleClass('reload-old', true);
			$('#pause-img').toggleClass('reload-old', true);
			$('#pause-timecode-container').toggleClass('reload-old', true);
			$('#pause-restart-container').toggleClass('reload-old', true);
		}


		$('#pause-container').css('opacity',0).show().animate({opacity:1}, 100);
		$('#pause-timecode-container').css('opacity',0).css('display', 'flex').animate({opacity:1}, 100);
		$('#pause-restart-container').css('opacity',0).css('display', 'flex').animate({opacity:1}, 100);
		if (SETTINGS.isMobile && AppStatus.pausePresetReload && AppStatus.reload24h && !SETTINGS.INSTALLATION_MODE) $('#pause-headphones-container').css('opacity',0).css('display', 'flex').animate({opacity:1}, 100);

		//timecode!
		var timecode = Utils.secondsToTimecode(AudioController.getCurrentTime());
		$('#timecode-0').css('background-position', '0 '+(1200-parseInt(timecode.charAt(0),10)*100)+'%');
		$('#timecode-1').css('background-position', '0 '+(1200-parseInt(timecode.charAt(1),10)*100)+'%');
		$('#timecode-2').css('background-position', '0 '+(1200-parseInt(timecode.charAt(3),10)*100)+'%');
		$('#timecode-3').css('background-position', '0 '+(1200-parseInt(timecode.charAt(4),10)*100)+'%');

		var duration = Utils.secondsToTimecode(AppStatus.AUDIO_DURATION);
		var shortDuration = (SETTINGS.LANGUAGE == 'fr' ? AppStatus.AUDIO_DURATION_SHORT_FR : AppStatus.AUDIO_DURATION_SHORT_EN);
		if (!AppStatus.SELECTED_DURATION_LONG && AudioController.getCurrentTime() <= shortDuration + 1.0) {
			duration = Utils.secondsToTimecode(shortDuration);
		}

		$('#timecode-duration-0').css('background-position', '0 '+(1200-parseInt(duration.charAt(0),10)*100)+'%');
		$('#timecode-duration-1').css('background-position', '0 '+(1200-parseInt(duration.charAt(1),10)*100)+'%');
		$('#timecode-duration-2').css('background-position', '0 '+(1200-parseInt(duration.charAt(3),10)*100)+'%');
		$('#timecode-duration-3').css('background-position', '0 '+(1200-parseInt(duration.charAt(4),10)*100)+'%');

		if (SETTINGS.NFB_MENU) {
			$('#intro-aboutbtn-container').show();
		}

		// if (SETTINGS.RELOAD_ENABLED && !AppStatus.isOnIntro && !AppStatus.isOnLandingPage) {
		// 	StorageController.setState(AudioController.getCurrentTime());
		// }
	}.bind(this);

	this.resume = function() {

		if (!AppStatus.beginSent) {
			AppStatus.beginSent = true;
			AnalyticsController.trackEvent("begin");
		}
						
		$('#pause-container').fadeOut(100);
		$('#pause-timecode-container').fadeOut(100);
		$('#pause-restart-container').fadeOut(100);
		$('#pause-headphones-container').fadeOut(100);
		setTimeout(function() {
			$('#pause-container').toggleClass('reload', false);
			$('#pause-timecode-container').toggleClass('reload', false);
			$('#pause-restart-container').toggleClass('reload', false);
			$('#pause-container').toggleClass('reload-old', false);
			$('#pause-img').toggleClass('reload-old', false);
			$('#pause-restart').toggleClass('reload-old', false);
			$('#pause-timecode-container').toggleClass('reload-old', false);
			$('#pause-restart-container').toggleClass('reload-old', false);
		},150);
		// $('#pause-aboutbtn-container').fadeOut(100);
		$('#intro-aboutbtn-container').fadeOut(100);
		AudioController.pausedForMenu = false;
		SequenceRenderer.copresenceEnabledPc = 0;
		SequenceRenderer.copresenceEnabled = false;
		AppStatus.pausePresetReload = false;

		if (this.shown) this.hideSlow(0.5);
	}.bind(this);


	// //
	// // Add to page mode
	// //
	// this.addedToPage = null;
	// this.addToPage = function(div) {
	// 	// if (!this.shown) {
	// 	$(div).append($('#menu-header-container').detach());
	// 	$('#menu-header-container').toggleClass('page', true);
	// 	// }
	// 	if (this.addedToPage) $('body').append($('#menu-header-container').detach());
	// 	this.addedToPage = div;
	// };

	// this.removeFromPage = function(div) {
	// 	if (this.addedToPage !== div) return;
	// 	$('#menu').append($('#menu-header-container').detach());
	// 	$('#menu-header-container').toggleClass('page', false);
	// 	this.addedToPage = null;
	// };

}

window.MenuController = window.MenuController||new MenuController();
export default window.MenuController;


