//------------------------
//
//  The main renderer
//
//------------------------
import $ from 'jquery';
import SETTINGS from '../Settings.js';
import AppStatus from './AppStatus.js';
import * as THREE from 'three';
window.THREE = window.THREE ||THREE;

function RendererController() {

	this.currentColor = new THREE.Color(0xffffff);
	this.wasSetup = false;
	this.added = false;
	var renderer = null;

	//-------------------
	//
	// Create webgl renderer and place on background
	//
	//-------------------
	this.init = function() {
		if (this.wasSetup) return;
		this.wasSetup = true;
		renderer = new THREE.WebGLRenderer({
			antialias: !SETTINGS.isDesktop || SETTINGS.isMac,
			transparent: false,
			alpha: false,
			preserveDrawingBuffer: false,
			precision: (SETTINGS.isDesktop)?'mediump':'highp',
			stencil: false,
			powerPreference: (SETTINGS.isSafari||SETTINGS.isMobile)?undefined:"high-performance"
		});
		// renderer = new THREE.WebGLRenderer({
		// 	antialias: false,
		// 	transparent: false,
		// 	alpha: false,
		// 	preserveDrawingBuffer: false,
		// 	precision: 'mediump',
		// 	stencil: false
		// 	// powerPreference: (SETTINGS.isSafari||SETTINGS.isMobile)?undefined:"high-performance"
		// });



		// renderer.setSize(AppStatus.innerWidth(), AppStatus.innerHeight());
		// renderer.setPixelRatio(window.devicePixelRatio ? window.devicePixelRatio : 1);
		
		renderer.setSize(AppStatus.innerWidth(), AppStatus.innerHeight());
		// var ratio = 1 + (window.devicePixelRatio ? window.devicePixelRatio : 1);
		// if (SETTINGS.LOW_RES_MODE) ratio = 1;
		renderer.setPixelRatio(window.devicePixelRatio);

		window.renderer = renderer;
		this.resize();

		renderer.setClearColor(this.currentColor.getHex(), 1.0);
		renderer.clear();
		renderer.autoClear = false;

		this.renderHeight = AppStatus.innerHeight();
		this.renderWidth = AppStatus.innerWidth();
		$(window).on('resize', this.resize);

		this.add();

		this.renderWidth = renderer.domElement.width;
		this.renderHeight = renderer.domElement.height;

	};

	this.add = function() {
		if (this.added) return;
		this.added = true;
		$('#main').show();
		$('#renderer').append(renderer.domElement);
	};

	this.clear = function(col, target) {
		Utils.clearPlane.material.color.set(0x181818);
		renderer.render(Utils.clearScene, Utils.orthographicCameraFull, target, false);
	};

	//resize viewport
	this.resize = function() {
		if (!renderer) return;
		renderer.setSize(AppStatus.innerWidth(), AppStatus.innerHeight());
		var ratio = 1 + (window.devicePixelRatio ? window.devicePixelRatio : 1);
		if (AppStatus.innerWidth()*window.devicePixelRatio > 1920) ratio = 2; //window.devicePixelRatio;
		renderer.setPixelRatio(ratio);
		this.renderWidth = renderer.domElement.width;
		this.renderHeight = renderer.domElement.height;
		
	}.bind(this);

	this.dispose = function() {
		if (renderer) renderer.dispose();
		window.renderer = renderer = null;
	}

};

window.RendererController = window.RendererController|| new RendererController();
export default window.RendererController;
