import $ from 'jquery';
import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import * as THREE from 'three';
import Utils from '../utils/Utils.js';
import LoaderXHR from './LoaderXHR.js';
import DDSLoader from '../utils/DDSLoader.js';

function LoaderTexture(url,params) {

	url = url.replace(/\\/gi,'/').toLowerCase();

	this.params = params||{};
	if (!this.params.isDDS) {
		this.data = new THREE.Texture(Utils.emptyCanvas);
		this.data.format = this.params.format||THREE.RGBFormat;
		this.data.type = THREE.UnsignedByteType;
		this.data.wrapT = this.data.wrapS = this.params.wrapping||THREE.ClampToEdgeWrapping;
		this.data.minFilter = this.params.minFilter||THREE.LinearFilter;
		this.data.magFilter = this.params.magFilter||THREE.LinearFilter;
		this.data.generateMipmaps = this.params.generateMipmaps||false;
		this.data.needsUpdate = true;
		
	} else {
		this.data = new THREE.CompressedTexture([], 4096, 4096, params.format||THREE.RGB_S3TC_DXT1_Format); //, THREE.UnsignedByteType, THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping, THREE.LinearFilter, THREE.LinearMipMapLinearFilter, 1, THREE.LinearEncoding)
		this.data.type = THREE.UnsignedByteType;
		this.data.wrapT = this.data.wrapS = THREE.ClampToEdgeWrapping;
		this.data.type = THREE.UnsignedByteType;
		this.data.minFilter = this.params.minFilter||THREE.LinearFilter;
		this.data.magFilter = this.params.magFilter||THREE.LinearFilter;
		this.data.generateMipmaps = this.params.generateMipmaps||false;
	}
	
	this.data.path = url;
	this.data.loaded = false;
	// this.data.onUpdate = TextureHandler.textureUpdate;
	this.data.noRescale = !!this.params.noRescale;
	window.allLoadedFiles.push(url);

	this.url = url;
	this.loaded = false;
	this.loading = false;
	this.progress = 0.0;
	this.weight = 1;
	this.loadType = 'texture';
	this.xhr = null;
	this.multipleBatches = false;
	this.retrying = false;
	this.retryingSecondTime = false;
	this.loadedBytes = 0;

	this.completeCallback = null;
	this.errorCallback = null;
	this.blobURL = null;
};


//
// Image decoded from blob
//
LoaderTexture.prototype.decodedImage = function(e) {
	this.data.image = this.img;
	this.data.sourceImage = this.img;

	// if (this.params.filtering) {
		// this.data = this.data.setSmoothing(this.params.filtering);
	// }
	

	// if (!this.data.noRescale) this.data.setQuality(1024);


	this.data.needsUpdate = true;
	this.data.shouldUpdate = true;

	if (this.params.upload && !SETTINGS.DEFER_DECODING) {
		// console.log(this.data);
		Utils.uploadTexture(this.data);
		// renderer.setTexture2D(this.data, 1);
		// renderer.setTexture2D(null, 1);
		// TextureUpdateController.update(this.data, true);
	}
	 // else {
	// this.data.needsUpdate = true;
		// this.data.shouldUpdate = true;
	// }
	this.data.loaded = true;
	//this.data.image = this.null;

	window.URL.revokeObjectURL(this.blobURL);
	this.blobURL = null;	

	this.img.onload = null;
	this.img = null;
	this.xhr.data.value = null;
	this.xhr.dispose();
	this.xhr = null;
	this.progress = 1.0;
	this.loaded = true;
	this.loading = false;

	this.completeCallback(this);
};



//-----------------
//
// Loaded an image blob: decode and load to texture
//
//-----------------
LoaderTexture.prototype.xhrDone = function() {
	//this.data.value = new AudioBinaryBuffer(this.xhr.data.value, url);
	//this.data.value.isShared = this.isShared;
	if (!this.xhr.data.value) {
		this.xhrError();
		return;
	}

	// this.xhr.data.value.type = /\.png/.test(this.url) ? 'image/png' :
	// 					  /\.webp/.test(this.url) ? 'image/webp' :
	// 					  'image/jpg';

	this.blobURL = window.URL.createObjectURL(this.xhr.data.value);

	this.img = new Image();
	this.img.srcURL = this.url;
	this.img.onload = this.decodedImage.bind(this);
	this.img.onerror = function() {

		if (this.retrying) {
			console.warn('Blob decoding error: Trying a third time with image source.', this.url);
			setTimeout(function() {
				this.img = new Image();
				this.img.onload = this.decodedImage.bind(this);
				this.img.onerror = function() {
					console.warn('Major texture loading and decoding error. Using black texture.');
					this.img = Utils.emptyCanvas;
					this.decodedImage();
				};
				this.img.src = SETTINGS.ASSETS_URL+this.url;
			}.bind(this),1000);
			return;
		}

		console.warn('Blob decoding error: Trying a second time', this.url);
		window.URL.revokeObjectURL(this.blobURL);
		this.blobURL = null;

		this.retrying = true;
		setTimeout(function() {
					
			this.img = null;
			this.xhr = new LoaderXHR(this.url, 'blob');
			this.xhr.start(this.xhrDone.bind(this),this.xhrError.bind(this));

		}.bind(this),1000);
	}.bind(this);
	this.img.src = this.blobURL;
}

LoaderTexture.prototype.xhrError = function() {
	console.log('Texture loading error:',this.url);
	this.data.value = new THREE.Texture(Utils.emptyCanvas);
	this.data.value.onUpdate = null;
	this.xhr.dispose();
	this.xhr = null;
	this.progress = 1.0;
	this.loaded = true;
	this.loading = false;
	console.log('error',this.url);
	this.errorCallback(this);
}


//-----------------
//
// Loaded a compressed texture - decode and create compressed texture out of it
//
//-----------------
LoaderTexture.prototype.xhrDoneCompressed = function() {

	var ab = this.xhr.data.value;
	var img = new DDSLoader().parse(ab); //{}


	if (img.mipmaps.length>1) img.mipmaps.shift();

	if (img && img.mipmaps && img.mipmaps.length) {
		// this.data.image = img;
		// this.data.image.width = img.width;
		// this.data.image.height = img.height;
		// this.data.image.data = img.mipmaps[0].data;
		// this.data.mipmaps = img.mipmaps;
		// this.data.mipmapCount = img.mipmapCount-1;

		this.data.image = img;
		this.data.image.data = img.mipmaps[0].data;
		this.data.mipmaps = img.mipmaps;
		this.data.mipmapCount = img.mipmapCount-1;
		this.data.format = img.format;
		this.data.loaded = true;

			
		// this.data.format = img.format;
		// this.data.loaded = true;

		this.data.needsUpdate = true;
		this.data.shouldUpdate = true;
	} else {
		console.warn('DDS Decoding Error for image:', this.url, this.data.path);
	}
	// this.data.width = img.width;
	// this.data.height = img.height;
	
	// if (this.params.upload) {
		// this.data.needsUpdate = true;
		// TextureUpdateController.update(this.data, true);
		// this.data.needsUpdate = false;
	// } else {
	// 	this.data.needsUpdate = true;
	// }

	// console.log(this.data);

	this.xhr.data.value = null;
	this.xhr.dispose();
	this.xhr = null;
	this.progress = 1.0;
	this.loaded = true;
	this.loading = false;

	this.completeCallback(this);
};


//-----------------
//
// Loading process
//
//-----------------
LoaderTexture.prototype.start = function(_completeCallback, _errorCallback) {
	if (this.loading || this.loaded) console.error('Already loading: ',this.url);
	this.loading = true;
	this.completeCallback = _completeCallback;
	this.errorCallback = _errorCallback;


	if (this.params.isDDS) {
		var realUrl = this.url.replace(/\/personnage\//gi, '/personnage_compressed/').replace(/\.jpg|\.png|\.JPG|\.PNG|\.webp/gi, '\.dds.gz');
		this.xhr = new LoaderXHR(realUrl, 'arraybuffer');
		this.xhr.start(this.xhrDoneCompressed.bind(this), this.xhrError.bind(this));
	
	// } else if (SETTINGS.IMAGE_QUALITY && !this.params.noCompression) {

	// 	var realUrl = this.url.replace(/\/visuel\//gi, '/visuel_compressed/'+SETTINGS.IMAGE_QUALITY+'/').replace(/\.jpg|\.png|\.JPG|\.PNG|\.webp/gi, '\.png');
	// 	this.xhr = new LoaderXHR(realUrl, 'blob');
	// 	this.xhr.start(this.xhrDone.bind(this), this.xhrError.bind(this));

	} else {

		this.xhr = new LoaderXHR(this.url, 'blob');
		this.xhr.start(this.xhrDone.bind(this), this.xhrError.bind(this));
	}

};

LoaderTexture.prototype.getProgress = function() { 
	if (this.xhr) return this.xhr.getProgress();
	return this.progress;
};

LoaderTexture.prototype.dispose = function() {
	if (this.xhr) this.xhr.dispose();
	if (this.blobURL) {
		window.URL.revokeObjectURL(this.blobURL);
		this.blobURL = null;
	}
	this.completeCallback = this.errorCallback = this.url = this.start = this.getProgress = this.dispose = this.data = null;
};

LoaderTexture.prototype.reset = function() {
	this.loading = this.loaded = false;
	if (SETTINGS.isIOS) return;
	if (this.data && this.data.dispose) this.data.dispose();
	if (this.xhr) this.xhr.reset();
	if (this.blobURL && !SETTINGS.isIOS) {
		window.URL.revokeObjectURL(this.blobURL);
		this.blobURL = null;
	}
	this.data.loaded = false;
	this.data.image = null;
}

LoaderTexture.prototype.getWeight = function() {
	return this.weight;
};




//external ref
export default LoaderTexture;

