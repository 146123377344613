import SETTINGS from '../Settings.js';
import RendererController from './RendererController.js'
import SequenceRenderer from './SequenceRenderer.js'


function AppStatus() {
		
	this.AUDIO_DURATION = 800;
	this.AUDIO_DURATION_SHORT_FR = 310;
	this.AUDIO_DURATION_SHORT_EN = 328;

	this.currentFrame = 0;
	this.frameNeedsUpdate = true;
	this.started = false;
	this.forceUpdate = true;
	this.svgBinaryMeta = {};
	this.transitionStartedThisFrame = 0;
	this.isOnLandingPage = true;
	this.isOnEndPage = false;
	this.reloaded = false;
	this.lostFocus = false;
	this.pausePresetReload = false;
	this.reload24h = false;

	this.SELECTED_DURATION_LONG = false;
	this.logosFinished = !SETTINGS.LOGOS_ENABLED;
	this.logosStarted = false;
	this.mainSceneReady = !SETTINGS.TIMELINE_MODE;
	this.mainSceneReadyTime = 0;
	this.mainLoaderShown = false;
	this.mainLoaderShownTime = 0;
	this.logosShownTime = 0;
	this.isOnIntro = SETTINGS.INTRO_MODE && SETTINGS.TIMELINE_MODE;
	this.startMenuShown = SETTINGS.INTRO_MODE && SETTINGS.TIMELINE_MODE;

	this.presetsBundle = {value:{}};
	this.shadersBundle = {value:{}};

	this.finished = false; //final screen, don't reload here
	this.cleanedUp = false;
	this.beginSent = false;
	this.globalVolume = 1.0;

	if (SETTINGS.INSTALLATION_MODE && !SETTINGS.FORCE_5MINUTES) this.SELECTED_DURATION_LONG = true;

	//
	// Landscape mode detection, scrolling andflip X and Y
	//
	this.landscapeMode = window.outerWidth > window.outerHeight && SETTINGS.isMobile && !SETTINGS.isTablet; //SETTINGS.isMobile && window.screen && window.screen.orientation && /landscape/.test(window.screen.orientation.type);;
	this.isLandscapeMode = function() {
		return ((window.orientation==90||window.orientation==-90) && window.outerWidth>window.outerHeight) && !SETTINGS.EDIT_MODE; //this.landscapeMode;
	};


	this.resize = function() {
		if (SETTINGS.isMobile) document.body.scrollTop=0;
		this.landscapeMode = ((window.orientation==90||window.orientation==-90) && window.outerWidth>window.outerHeight) && !SETTINGS.EDIT_MODE; //SETTINGS.isMobile && window.screen && window.screen.orientation && /landscape/.test(window.screen.orientation.type);;
		this.frameNeedsUpdate = true;
	}.bind(this);


	function resetScroll() {
		if (SETTINGS.isMobile) document.body.scrollTop=0;
	}
	document.body.onorientationchange = function() {
		console.log("orientation change");
		if (SETTINGS.isMobile) document.body.scrollTop=0;
		setTimeout(resetScroll, 100);
		setTimeout(resetScroll, 300);
		setTimeout(resetScroll, 600);
		setTimeout(resetScroll, 2000);
		this.landscapeMode = ((window.orientation==90||window.orientation==-90) && window.outerWidth>window.outerHeight) && !SETTINGS.EDIT_MODE; //SETTINGS.isMobile && window.screen && window.screen.orientation && /landscape/.test(window.screen.orientation.type);;
		this.frameNeedsUpdate = true;
	}.bind(this);

	this.innerWidth = function() {
		return SETTINGS.USE_SCREEN_WIDTH ? window.screen.width : window.innerWidth;
	};

	this.innerHeight = function() {
		return SETTINGS.USE_SCREEN_WIDTH ? window.outerHeight : window.innerHeight;
	};

	this.pageX = function() {
		return !this.landscapeMode ? ["pageX"] : ["pageY"];
	};
	this.pageY = function() {
		return !this.landscapeMode ? ["pageX"] : ["pageY"];
	};
	
	this.changeLanguage = function() {

	};
	this.log = function(...msg) {
		if (this.currentFrame%10 == 0) console.log.apply(console, arguments);
	}

};

window.AppStatus = window.AppStatus || new AppStatus();
export default window.AppStatus;