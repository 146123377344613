import $ from 'jquery';
import * as THREE from 'three';
import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';


class InteractionController {

	constructor() {
		
		this.currentDragSpeed = new THREE.Vector2(0.0, 0.0);
		this.targetPosition = new THREE.Vector2(0.0, 0.0);
		this.currentPosition = new THREE.Vector2(0.0, 0.0);
		this.rawPosition = new THREE.Vector2(0.0, 0.0);
		this.startPosition = new THREE.Vector2(0.0, 0.0);

		this.lastPressTime = 0;
		this.justClickPressTime = 0;
		this.releaseTime = 0;
		this.clickMode = false;
		

		this.clickedOnce = false;

		this.dragPc = 0.0;
		this.clickPc = 0.0;
		this.clickPcElastic = 0.0;
		this.dragPcElastic = 0.0;

		this.multiClickPc = 0;
		this.pressPc = 0.0;
		this.pressPcElastic = 0.0;


		this.lastMouseMove = 0;
		this.mouseMoveStart = 0;

		this.cameraStretch = new THREE.Vector2(1,1);

		this.ELASTIC_TIME = 0.75;
		this.ELASTIC_BOUNCES = 5.25;
		this.ELASTIC_PRESS_CURVE = 1.0;
		this.ELASTIC_RELEASE_CURVE = 0.9;
	}

	start() {
		//
		// bind event handlers
		//
		if (!SETTINGS.isMobile) {
			$('#main')
				.on('mousedown', this.mouseDown.bind(this))
				.on('mousemove', this.mouseMove.bind(this));

			$(window).on('mouseup', this.mouseUp.bind(this));
			document.addEventListener('wheel', this.scroll.bind(this), false);
		} else {
			// $('#main')
			// 	.on('touchstart', this.touchStart.bind(this))
			// 	.on('touchmove', this.touchMove.bind(this))
			// 	.on('touchend', this.touchEnd.bind(this));
		}

	}


	//----------------
	//
	// Handle mouse events
	//
	//----------------
	mouseDown(e) {

		if (!this.isDragging) this.mouseMoveStart = performance.now();

		this.isDragging = true;
		this.mouseIsDown = true;
		this.clickedOnce = true;
		this.pressedThisFrame = true;

		this.targetPosition.x = ((e.pageX/ AppStatus.innerWidth()) * 1.0-0.5)*this.cameraStretch.x;
		this.targetPosition.y = ((e.pageY/ AppStatus.innerHeight()) * 1.0-0.5)*this.cameraStretch.y;
		
		this.rawPosition.x = ((e.pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5;
		this.rawPosition.y = ((e.pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5;
		this.lastPressTime = this.lastMouseMove = performance.now();

	}

	mouseMove(e) {
		var pressing = false;
		if (!this.isDragging) {
			// this.pressedThisFrame = true;
		 	this.mouseMoveStart = performance.now();
		}
		this.isDragging = true;
		this.lastMouseMove = performance.now();
		this.clickedOnce = true;

		if (this.isDragging) {
			var nx = ((e.pageX/ AppStatus.innerWidth()) * 1.0-0.5)*this.cameraStretch.x;
			var ny = ((e.pageY/ AppStatus.innerHeight()) * 1.0-0.5)*this.cameraStretch.y;
			this.currentDragSpeed.x += nx-this.targetPosition.x;
			this.currentDragSpeed.y += ny-this.targetPosition.y;
			this.targetPosition.set(nx, ny);
		}
		this.rawPosition.x = ((e.pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5;
		this.rawPosition.y = ((e.pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5;
	}

	mouseUp(e) {
		this.releaseTime = performance.now();
		this.mouseIsDown = false;
	}
	scroll(e) {
		this.scrolledThisFrame = true;
	}

	//----------------
	//
	// Handle touch events
	//
	//----------------
	touchStart(e) {


	}

	touchMove(e) {


	}

	touchEnd(e) {


	}


	update(delta) {
	
		var now = performance.now();

		//
		// Update mouse move
		//
		if (SETTINGS.MOUSEMOVE_MODE) {

			//release after 500ms
			if (this.isDragging && !this.mouseIsDown && now - this.lastMouseMove >= 200) {
				this.isDragging = false;
			}
			if (this.mouseIsDown || this.pressedThisFrame) this.lastMouseMove = now;


			this.currentPosition.lerp(this.targetPosition,delta*0.2);
			this.currentDragSpeed.lerp(new THREE.Vector2(0,0,0), delta*0.05);


			//
			// Drag curve time + accelerate with dragSpeed
			//
			// console.log(now-this.justClickPressTime);
			var clickPc = Utils.ccmap(now-this.justClickPressTime, 0, 500, 1, 0);
			var dragTimeCurve = 
				Math.pow(Utils.ccmap(now-this.mouseMoveStart, 0.0, 2500-this.currentDragSpeed.length()*500 -  clickPc*500, clickPc*0.5, 1.0 - clickPc*0.2),0.9) * Utils.ccmap(now - this.lastMouseMove, 0, 500*0.5, 1, 0);
			// AppStatus.log(now-this.justClickPressTime, clickPc); //Utils.ccmap(now-this.mouseMoveStart, 0.0, 4000-this.currentDragSpeed.length()*1000 -  clickPc*500, clickPc*0.5, 1.0 - clickPc*0.1));
			this.dragPc = Utils.deltaSmoothingSnap2(this.dragPc, dragTimeCurve+this.multiClickPc*0.2, 0.1-this.currentDragSpeed.length()*0.02+clickPc*0.05, delta);

			//elastic
			var dragCurveElastic = Utils.ccmap(now-this.mouseMoveStart, 0.0, (2500-this.currentDragSpeed.length()*100  - clickPc*500)*this.ELASTIC_TIME, clickPc*0.45, 1.0 - clickPc*0.1) * Utils.ccmap(now - this.lastMouseMove, 0, 1750*this.ELASTIC_TIME, 1, 0);
			var releaseTimePcRaw = Utils.ccmap(now-this.lastMouseMove, 0, 1750*this.ELASTIC_TIME, 0.0, 1.0);
			var releaseTimePc = Math.pow(releaseTimePcRaw, this.ELASTIC_PRESS_CURVE);
			this.dragPcElastic = (dragCurveElastic * Math.sin(Math.PI/2 + releaseTimePc * Math.PI * this.ELASTIC_BOUNCES) * 0.75 * (1.0-Math.pow(releaseTimePcRaw,this.ELASTIC_RELEASE_CURVE)));



			//
			// Just click
			//
			if (this.pressedThisFrame) {
				// console.log("huhh");
				if (now-this.justClickPressTime < 750) {
					this.multiClickPc += 0.33;
					this.mouseMoveStart -= 150;
				} else {
					this.multiClickPc -= 0.1;
				}
				if (now-this.justClickPressTime < 400) {
					this.justClickPressTime = Math.min(now, this.justClickPressTime+150);
					// console.log("-150", now-this.justClickPressTime);
				} else if (now-this.justClickPressTime < 800) {
					this.justClickPressTime = Math.min(now, this.justClickPressTime+ (now-this.justClickPressTime)*0.7 );
					// console.log("-250", now-this.justClickPressTime);
				} else {
					this.justClickPressTime = this.lastPressTime;
					// console.log("0", now-this.justClickPressTime);
				}
				this.clickMode = true;	
				this.multiClickPc = Utils.clamp(this.multiClickPc, 0.0, 1.0);
			}
			this.multiClickPc = Utils.deltaSmoothingSnap2(this.multiClickPc, 0.0, this.clickMode?0.01:0.02, delta);

			var timePcRaw = Utils.cmap(now-this.justClickPressTime, 0, 1200, 0.05, 1.0);
			var timePc = Math.pow(timePcRaw,0.5);
			var justClickPc = Math.sin(timePc*Math.PI)*Math.pow(Utils.ccmap(timePc, 0.5,1.0,1.0,0.0),2.0) * (0.5 + this.multiClickPc*0.4);


			// if (this.clickMode && this.mouseIsDown && timePc > 0.5 ) {
			// 	this.clickMode = false;
			// 	this.clickPc = Math.max(this.clickPc, justClickPc+0.05);; //Utils.deltaSmoothingSnap2(this.actualPressPc, 1.0, 0.05, delta);
			// }
			// if (!this.clickMode) justClickPc = 0.0;
			this.clickPc = clickPc;
			// AppStatus.log(timePcRaw, this.clickPc);

			// this.actualPressPcElastic = this.actualPressPc;
			// this.pressPcElastic = this.pressPc;


			//--------------
			//
			// Elastic click mode
			//
			//---------------
			// timePcRaw = Utils.cmap(now-this.justClickPressTime, 0, 1200, 0.05, 1.0);
			// timePc = Math.pow(timePcRaw,0.33);

			// var elasticTarget = timePc; //Math.sin(timePc*Math.PI)*(1.0-timePcRaw); // * (0.5 + this.multiClickPc*0.5);
			// if (this.isDragging) {
			// 	this.elastic2 = elasticTarget; //Math.max(elasticTarget, Utils.cmap(now-this.justClickPressTime, 0, 2000, 0.0, 1.0));
				
			// } else {
					
			// 	var releaseTimePcRaw = Utils.ccmap(now-this.releaseTime, 0, 1000, 0.0, 1.0);
			// 	var releaseTimePc = Math.pow(releaseTimePcRaw,2.0);
			// 	 this.elastic2 = Math.abs(elasticTarget * Math.sin(Math.PI/2 + releaseTimePc * Math.PI * 2.25) * (1.0-Math.pow(releaseTimePcRaw,0.75)));
				
			// }

			
			// if (!this.clickMode) {justClickPcElastic = justClickPc = 0.0;}
			// if (!SETTINGS.CLICK_MODE) {justClickPcElastic = justClickPc = 0.0;}


			// this.pressPc = this.clickPc;
			// console.log(this.pressedThisFrame); //now-this.lastPressTime, this.justClickPressTime);
			this.pressPc = this.dragPc; //Math.max(this.dragPc*0.8,this.clickPc*0.5 + this.dragPc*0.5);
			this.pressPcElastic = Utils.deltaSmoothingSnap2(this.pressPcElastic, this.dragPcElastic, 0.15, delta);



			// console.log(this.pressPc);


			// if (this.pressedThisFrame) {
			// 	if (now-this.justClickPressTime < 750) {
			// 		this.multiClickPc += 0.33;
			// 	} else {
			// 		this.multiClickPc -= 0.1;
			// 	}
			// 	if (now-this.justClickPressTime < 666) this.justClickPressTime = this.justClickPressTime+150;
			// 	else this.justClickPressTime = this.lastPressTime;
			// 	this.clickMode = true;	
			// 	this.multiClickPc = Utils.clamp(this.multiClickPc, 0.0, 1.0);
			// }

			// this.multiClickPc = Utils.deltaSmoothingSnap2(this.multiClickPc,0.0, this.clickMode?0.01:0.02, delta);





		}



	}


	updateAfter() {
		// console.log("hhh");
		this.pressedThisFrame = false;
		this.scrolledThisFrame = false;
	}

};

window.InteractionController = window.InteractionController||new InteractionController();
export default window.InteractionController;