import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import Fbo from '../utils/Fbo.js';
import addGuiOption from '../utils/GUIHelper.js';

import * as dat from 'dat.gui';


class Scene {

	constructor() {

		//state
		this.disposed = false;
		this.presetsEnabled = false;

		//common values
		this.currentTime = 0.0;
		this.lastTime = performance.now();

		//gui
		this.hasDatGUI = true;
		this.options = {};
		this.ranges = {};
		this.guiCallbacks = {};
	}

	enablePresets(presetFolder) {
		if (this.presetsEnabled) return;
		this.presetsEnabled = true;
		this.presetFolder = presetFolder;

		this.options = Utils.cloneInto(this.options, {
			preset: 'none',
			savePreset: true,
		});
		
		this.ranges = Utils.cloneInto(this.ranges, {
			preset: null
		});

		this.guiCallbacks = Utils.cloneIntoAdd(this.guiCallbacks, {
			preset: this.reloadPreset.bind(this),
		});
	}




	reloadPreset(force) {
		if (this.options.preset == this.currentPresetName) return;
		this.currentPresetName = this.options.preset;
		var xhr = new LoaderXHR(this.presetFolder+this.options.preset, 'json', true);
		xhr.start(function(val) {
			console.log("Loaded preset");
			var migrated = this.migratePreset(xhr.data.value);
			if (migrated.options.preset) delete migrated.options.preset;
			Utils.cloneIntoMod(migrated.options, this.options);
		}.bind(this))
	}

	preloadData(batchName) {
		if (this.presetsEnabled) this.presetList = Loader.addXHR(batchName, '/filelist?dir='+this.presetFolder, 'json');
	}

	//preload all assets
	preload(batchName) {
	
	}

	//init basics
	start() {
		if (this.presetsEnabled) this.ranges.preset = ['none'].concat(this.presetList.value.files);
		if (this.presetsEnabled && this.options.nextPreset) this.ranges.nextPreset = ['none'].concat(this.presetList.value.files);

		//add gui
		if (this.presetsEnabled) this.options.savePreset = Utils.savePresetCallback(this, this.presetFolder);


		if (this.hasDatGUI) {
			this.gui = window.gui = new dat.GUI();
			addGuiOption(this.gui, this.options, this.ranges, this.guiCallbacks);
			$(this.gui.domElement.parentElement).css('z-index', 10);

			if (SETTINGS.RELEASE_MODE) {
				this.gui.hide();
				$('.dg').hide();
			} else {
				this.gui.show();
				$('.dg').show();
			}
			if (SETTINGS.isMobile) {
				this.gui.close();
			}
		}

		//set basic variables
		this.currentTime = 0.0;
		this.lastTime = performance.now();
	}

	//update
	update(delta) {
		if (this.soundScene) this.soundScene.update(this.options, delta);
	}

	//render
	render() {
		// renderer.clear();
	}

	dispose(){};


	migratePreset(preset) {
		return preset;
	}
};

export default Scene;