import SETTINGS from '../Settings.js';

//------------------------------
//
//
// Simple Utility to dispose audio nodes after a fadeOut
//
//
//------------------------------
function AudioDisposeController() {

	this.nodeDisposeBin = [];
	//this.nodeDisposeType = [];
	//this.nodeDisposeAudioEndTime = [];
	//this.nodeDisposeRealEndTime = [];

	this.dispose = function(node, delay) {
		
		if (isNaN(delay)) delay = 0;
		delay = delay || 0;

		this.nodeDisposeBin.push({
			ref: node,
			audioTime: audioContext.currentTime+delay+0.015, //mark a audioContext.currentTime-based deadline
			realTime: performance.now()+delay*1500+10 //mark a clock-based deadline
		});
	};

	this.update = function() {
		if (this.nodeDisposeBin.length <= 0) return;
		if (!window.audioContext) {this.nodeDisposeBin = []; return;}

		var audioNow = audioContext.currentTime,
			realNow = performance.now();

		// if (!this.scratchBuffer) this.scratchBuffer = audioContext.createBuffer(1, 1, audioContext.sampleRate)

		for (var i=0; i<this.nodeDisposeBin.length; i++) {
			// Check if audioContext.current has passed by the duration of this sound
			// Otherwise check if the computer clock time has passed way by its deadline for this sound. This should never happen and is for safety only.
			if (this.nodeDisposeBin[i].audioTime <= audioNow || this.nodeDisposeBin.realTime <= realNow) {



				if (this.nodeDisposeBin[i].ref.dispose) this.nodeDisposeBin[i].ref.dispose();
				if (this.nodeDisposeBin[i].ref.disconnect) this.nodeDisposeBin[i].ref.disconnect();
				if (this.nodeDisposeBin[i].ref.constructor === ConvolverNode) this.nodeDisposeBin[i].ref.buffer = null;
				if (this.nodeDisposeBin[i].ref.stop && !this.nodeDisposeBin[i].ref.alreadyStopped) {
					this.nodeDisposeBin[i].ref.stop(audioContext.currentTime);
					this.nodeDisposeBin[i].ref.alreadyStopped = true;
				}
            	if (this.nodeDisposeBin[i].ref.buffer) {
            		try { this.nodeDisposeBin[i].ref.buffer = null; } catch(e) {}
            	}
				this.nodeDisposeBin[i].ref = null;
				this.nodeDisposeBin.splice(i,1);
				i--;
			}
		}
	};


	this.disposeNow = function(node) {
		if (node.dispose) node.dispose();
		if (node.disconnect) node.disconnect();
		if (node.constructor === ConvolverNode) node.buffer = null;
		if (node.stop && !node.alreadyStopped) {
			node.stop(audioContext.currentTime);
			node.alreadyStopped = true;
		}
    	if (node.buffer) {
    		try { node.buffer = null; } catch(e) {}
    	}
		node = null;
	};
}

window.AudioDisposeController = window.AudioDisposeController||new AudioDisposeController();
export default window.AudioDisposeController;
