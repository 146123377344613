import {
	Box3,
	Float32BufferAttribute,
	InstancedBufferGeometry,
	InstancedInterleavedBuffer,
	InterleavedBufferAttribute,
	Sphere,
	Vector3,
	WireframeGeometry,
	DynamicDrawUsage
} from 'three';

var LineSegmentsGeometry = function () {

	InstancedBufferGeometry.call( this );

	this.type = 'LineSegmentsGeometry';

	var positions = [ - 1, 2, 0, 1, 2, 0, - 1, 1, 0, 1, 1, 0, - 1, 0, 0, 1, 0, 0, - 1, - 1, 0, 1, - 1, 0 ];
	var uvs = [ - 1, 2, 1, 2, - 1, 1, 1, 1, - 1, - 1, 1, - 1, - 1, - 2, 1, - 2 ];
	var index = [ 0, 2, 1, 2, 3, 1, 2, 4, 3, 4, 5, 3, 4, 6, 5, 6, 7, 5 ];

	this.setIndex( index );
	this.setAttribute( 'position', new Float32BufferAttribute( positions, 3 ) );
	this.setAttribute( 'uv', new Float32BufferAttribute( uvs, 2 ) );

};

LineSegmentsGeometry.prototype = Object.assign( Object.create( InstancedBufferGeometry.prototype ), {

	constructor: LineSegmentsGeometry,

	isLineSegmentsGeometry: true,

	applyMatrix4: function ( matrix ) {

		var start = this.attributes.instanceStart;
		var end = this.attributes.instanceEnd;

		if ( start !== undefined ) {

			start.applyMatrix4( matrix );

			end.applyMatrix4( matrix );

			start.needsUpdate = true;

		}

		if ( this.boundingBox !== null ) {

			this.computeBoundingBox();

		}

		if ( this.boundingSphere !== null ) {

			this.computeBoundingSphere();

		}

		return this;

	},

	setPositions: function ( array ) {

		var lineSegments;

		if ( array instanceof Float32Array ) {

			lineSegments = array;

		} else if ( Array.isArray( array ) ) {

			lineSegments = new Float32Array( array );

		}

		//bufferSubData is incredibly slow on many devices & many gpu except on iOS apparently, so we force three.js to use bufferData
		//At least on Android
		var skipSubData = true; //(SETTINGS.isMobile && !SETTINGS.isIos);

		if (!this.positionsSet) {
			this.positionsSet = true;
			// var instanceBuffer = new InstancedInterleavedBuffer( lineSegments, 6, 1 ); // xyz, xyz
			// lineSegments = lineSegments.subarray( 0, (this.instanceCount+1)*6)

			var instanceBuffer = new InstancedInterleavedBuffer( lineSegments, 6, 1 )
			instanceBuffer.usage = DynamicDrawUsage;
			// window.instanceBuffer = instanceBuffer;
			this.instanceBuffer = instanceBuffer;

			var attrA = new InterleavedBufferAttribute( instanceBuffer, 3, 0 );
			attrA.usage = DynamicDrawUsage;
			// attrA.updateRange.count = (this.instanceCount+1)*6;;

			var attrB = new InterleavedBufferAttribute( instanceBuffer, 3, 3 );
			attrB.usage = DynamicDrawUsage;
			// attrB.updateRange.count = (this.instanceCount+1)*6;;

			this.setAttribute( 'instanceStart', attrA ); // xyz
			this.setAttribute( 'instanceEnd', attrB ); // xyz

		} else if (skipSubData) {

			//update buffer
			this.instanceBuffer.array = lineSegments; //.subarray(0, (this.instanceCount+1)*12); //;.set(lineSegments, 0, (this.instanceCount + 1) * 3);

			this.getAttribute( 'instanceStart' ).data.updateRange.count = -1; //(this.instanceCount+1)*6;
			this.getAttribute( 'instanceStart' ).data.needsUpdate = true;
			this.getAttribute( 'instanceEnd' ).data.updateRange.count = -1; //(this.instanceCount+1)*6;
			this.getAttribute( 'instanceEnd' ).data.needsUpdate = true;

			// console.log(lineSegments.length);


		} else {

			// console.log(lineSegments.length, this.getAttribute( 'instanceStart' ).data.array.length);
			// this.getAttribute( 'instanceStart' ).data.array.set(lineSegments, 0, (this.instanceCount + 1) * 3);
				
			// this.srcArray = this.srcArray||this.getAttribute( 'instanceStart' ).data.array.slice(0);
			// this.srcArray2 = this.srcArray2||this.getAttribute( 'instanceEnd' ).data.array.slice(0);

			// this.srcArray.set(lineSegments, 0, (this.instanceCount + 1) * 3);

			// this.instanceBuffer.array = lineSegments;
			// this.getAttribute( 'instanceStart' ).data.array = lineSegments;

			this.instanceBuffer.array = lineSegments; //.subarray(0, (this.instanceCount+1)*12); //;.set(lineSegments, 0, (this.instanceCount + 1) * 3);

			// this.getAttribute( 'instanceStart' ).data.array = this.srcArray.subarray(0, Utils.clamp(this.instanceCount + 1) * 6,0,this.srcArray.length);
			// this.getAttribute( 'instanceStart' ).data.array.set(lineSegments, 0, (this.instanceCount + 1) * 3);
			this.getAttribute( 'instanceStart' ).data.updateRange.count = (this.instanceCount+1)*6;
			this.getAttribute( 'instanceStart' ).data.needsUpdate = true;
			// this.getAttribute( 'instanceStart' ).data.onUpload((a) =>{
			// 	this.getAttribute( 'instanceStart' ).data.updateRange.count = (this.instanceCount+1)*6;
			// 	console.log(this);
			// });
			// console.log(this.getAttribute( 'instanceStart' ).data.updateRange.count);
			// console.log(this.getAttribute( 'instanceStart' ).data.updateRange.count);
			// this.getAttribute( 'instanceStart' ).data.array = lineSegments.subarray(0, (this.instanceCount + 1) * 3);
			// this.getAttribute( 'instanceEnd' ).data.array = lineSegments.subarray(0, (this.instanceCount + 1) * 3);
			// this.srcArray2.set(lineSegments, 0, (this.instanceCount + 1) * 3);

			// this.getAttribute( 'instanceEnd' ).data.array = lineSegments;
			// this.getAttribute( 'instanceEnd' ).data.array.set(lineSegments, 0, (this.instanceCount + 1) * 3);
			// this.getAttribute( 'instanceEnd' ).data.array = this.srcArray2.subarray(0, Utils.clamp(this.instanceCount + 1) * 6,0,this.srcArray2.length);
			// this.getAttribute( 'instanceEnd' ).data.array.set(lineSegments, 0, (this.instanceCount + 1) * 3);
			this.getAttribute( 'instanceEnd' ).data.updateRange.count = (this.instanceCount+1)*6;
			this.getAttribute( 'instanceEnd' ).data.needsUpdate = true;
			// this.getAttribute( 'instanceEnd' ).data.onUpload((a) =>{
			// 	this.getAttribute( 'instanceEnd' ).data.updateRange.count = (this.instanceCount+1)*6;
			// 	console.log(this);
			// });

			// window.attr = this.getAttribute( 'instanceEnd' );

			// this.getAttribute( 'instanceStart' ).data.array.set(lineSegments, 0, (this.instanceCount + 1) * 3);
			// this.getAttribute( 'instanceEnd' ).data.updateRange.count = (this.instanceCount + 1); // lineSegments.length;
			// this.getAttribute( 'instanceStart' ).needsUpdate = true;
			// this.getAttribute( 'instanceEnd' ).data.array.set(lineSegments, 0, (this.instanceCount + 1) * 3);
			// this.getAttribute( 'instanceEnd' ).data.updateRange.count = (this.instanceCount + 1); //lineSegments.length;
			// this.getAttribute( 'instanceEnd' ).needsUpdate = true;
			



		}

		
		//

		// this.computeBoundingBox();
		// this.computeBoundingSphere();

		return this;

	},

	setColors: function ( array ) {

		var colors;

		if ( array instanceof Float32Array ) {

			colors = array;

		} else if ( Array.isArray( array ) ) {

			colors = new Float32Array( array );

		}

		var instanceColorBuffer = new InstancedInterleavedBuffer( colors, 6, 1 ); // rgb, rgb

		this.setAttribute( 'instanceColorStart', new InterleavedBufferAttribute( instanceColorBuffer, 3, 0 ) ); // rgb
		this.setAttribute( 'instanceColorEnd', new InterleavedBufferAttribute( instanceColorBuffer, 3, 3 ) ); // rgb

		return this;

	},

	fromWireframeGeometry: function ( geometry ) {

		this.setPositions( geometry.attributes.position.array );

		return this;

	},

	fromEdgesGeometry: function ( geometry ) {

		this.setPositions( geometry.attributes.position.array );

		return this;

	},

	fromMesh: function ( mesh ) {

		this.fromWireframeGeometry( new WireframeGeometry( mesh.geometry ) );

		// set colors, maybe

		return this;

	},

	fromLineSegments: function ( lineSegments ) {

		var geometry = lineSegments.geometry;

		if ( geometry.isGeometry ) {

			this.setPositions( geometry.vertices );

		} else if ( geometry.isBufferGeometry ) {

			this.setPositions( geometry.attributes.position.array ); // assumes non-indexed

		}

		// set colors, maybe

		return this;

	},

	computeBoundingBox: function () {

		var box = new Box3();

		return function computeBoundingBox() {

			if ( this.boundingBox === null ) {

				this.boundingBox = new Box3();

			}

			var start = this.attributes.instanceStart;
			var end = this.attributes.instanceEnd;

			if ( start !== undefined && end !== undefined ) {

				this.boundingBox.setFromBufferAttribute( start );

				box.setFromBufferAttribute( end );

				this.boundingBox.union( box );

			}

		};

	}(),

	computeBoundingSphere: function () {

		var vector = new Vector3();

		return function computeBoundingSphere() {

			if ( this.boundingSphere === null ) {

				this.boundingSphere = new Sphere();

			}

			if ( this.boundingBox === null ) {

				this.computeBoundingBox();

			}

			var start = this.attributes.instanceStart;
			var end = this.attributes.instanceEnd;

			if ( start !== undefined && end !== undefined ) {

				var center = this.boundingSphere.center;

				this.boundingBox.getCenter( center );

				var maxRadiusSq = 0;

				for ( var i = 0, il = start.count; i < il; i ++ ) {

					vector.fromBufferAttribute( start, i );
					maxRadiusSq = Math.max( maxRadiusSq, center.distanceToSquared( vector ) );

					vector.fromBufferAttribute( end, i );
					maxRadiusSq = Math.max( maxRadiusSq, center.distanceToSquared( vector ) );

				}

				this.boundingSphere.radius = Math.sqrt( maxRadiusSq );

				if ( isNaN( this.boundingSphere.radius ) ) {

					console.error( 'THREE.LineSegmentsGeometry.computeBoundingSphere(): Computed radius is NaN. The instanced position data is likely to have NaN values.', this );

				}

			}

		};

	}(),

	toJSON: function () {

		// todo

	},

	applyMatrix: function ( matrix ) {

		console.warn( 'THREE.LineSegmentsGeometry: applyMatrix() has been renamed to applyMatrix4().' );

		return this.applyMatrix4( matrix );

	}

} );

export { LineSegmentsGeometry };
