import $ from 'jquery';
import FastEvent from './FastEvent.js';
import Utils from './Utils.js';

/*
	<div class="ui-item">
		<div class="ui-color-code"></div>
		<div class="ui-label">content</div>
		<div class="ui-slider-out">
			<div class="ui-slider-in"></div>
		</div>
		<input class="ui-number-input" type="number" step="1.0" value="50.12" pattern="/d"></input>
	</div>

*/
function Slider(UI) {
	if (!UI.enabled) return;

	//
	// Create the div hierarchy
	//
	var containerDiv = document.createElement('div'),
		colorCodeDiv = document.createElement('div'),
		labelDiv = document.createElement('div'),
		sliderOutDiv = document.createElement('div'),
		sliderInDiv = document.createElement('div'),
		numberDiv = document.createElement('input');
	this.domElement = containerDiv;


	containerDiv.appendChild(colorCodeDiv);
	containerDiv.appendChild(labelDiv);
	containerDiv.appendChild(sliderOutDiv);
	sliderOutDiv.appendChild(sliderInDiv);
	containerDiv.appendChild(numberDiv);

	//
	// Set the classes and basic properties
	//
	$(containerDiv).addClass('ui-item'); 
	$(colorCodeDiv).addClass('ui-color-code');
	$(labelDiv).addClass('ui-label');
	$(sliderOutDiv).addClass('ui-slider-out');
	$(sliderInDiv).addClass('ui-slider-in');
	$(numberDiv).addClass('ui-number-input');

	numberDiv.type = 'number';
	numberDiv.step = '0.01';
	numberDiv.pattern = '/d';
	numberDiv.value = 0.0;

	//
	// props
	//
	this.uuid = Math.floor(Math.random() * 100000000000).toFixed(0);
	this.value = 0.0;
	this.propName = '';
	this.toWatch = {};
	this.path = [];
	this.min = 0.0;
	this.max = 1.0;
	this.changing = false;
	this.isInt = false;
	this.range = null;
	this.relativeValue = null;
	this.relativeLastPosition = null;

	$(sliderInDiv).css('width', '0%');


	//
	// Update UI 
	//
	this.canUpdateUI = false;
	this.shouldUpdateUI = true;
	this.shouldUpdateStep = true;
	this.lastUpdateFrame = -1;
	this.updateUI = function() {
		if ( this.disposed ) return;
		if ( (!this.canUpdateUI || !this.shouldUpdateUI) && !this.dragging) {
			return;
		}
		this.shouldUpdateUI = false;
		if (this.lastUpdateFrame === UI.currentFrame) {
			return;
		}
		this.lastUpdateFrame = UI.currentFrame;
		this.changing = true;
		
		// var pc = Math.min(Math.max(this.value,this.min),this.max);
		// pc = (this.value-this.min) / (this.max - this.min);
		var pc = Utils.cmap(this.value, this.min, this.max, 0.0, 1.0);
		if (this.props.curve) {
			if (pc > 0.0 && pc < 1.0) pc = Math.pow(pc, 1.0/this.props.curve);
		}
		
		pc = (pc * 100);
		$(sliderInDiv).css('width', pc+'%');
		// $(sliderInDiv).css('transform', 'scale('+pc+',1.0)');
		numberDiv.value = this.value;
		if (this.shouldUpdateStep) {
			numberDiv.step = (this.max-this.min) / 20.0;
			if (this.isInt) numberDiv.step = Math.ceil(numberDiv.step);
		}
		this.shouldUpdateStep = false;

		this.changing = false;

	}.bind(this);


	//object.observe change
	this.updateValue = function(evt, changes) {
		//prevent double changes / infinite loops
		if (this.changing) return;


		//
		// Value has changed
		//
		this.changing = true;

		//
		// update slider pos
		//
		var lv = this.value;
		this.value = this.toWatch[this.propName];
		if (this.isInt) {
			this.value = Math.round(this.value);
			this.toWatch[this.propName] = this.value;
		}

		
		UI.hasChanged = true;

		//
		// update number display
		//
		if (lv !== this.value) {
			this.shouldUpdateUI = true;
			this.updateUI();
			if (this.callback) this.callback.apply(this, [this.value, this.dragging]);
		}
		this.changing = false;

	}.bind(this);


	//
	// init the properties
	//
	this.init = function(toWatch, propName, props, path) {
		
		this.min = props.min || 0.0;
		this.max = props.max || 1.0;
		this.propName = propName;
		this.toWatch = toWatch;
		this.props = props;
		this.path = path;
		this.isInt = props.isInt || false;

		this.props.ui = this;

		// this.shouldUpdateUI = true;
		this.updateValue(null, [{name:propName}]);
		// this.updateUI();

		numberDiv.step = (this.max-this.min) / 20.0;
		if (this.isInt) numberDiv.step = Math.ceil(numberDiv.step);
		this.shouldUpdateStep = false;

		$(labelDiv).text(propName);
		$(colorCodeDiv).css('background-color', props.colorCode || '#'+Math.floor(Math.random()*0xffffff).toString(16));

		this.range = props;
		UI.watchProperty(this.toWatch, this.propName, this.updateValue);
		UI.watchProperty(this.toWatch, this.propName+"ui", this.updateUI);
		UI.watchProperty(this.range, 'max', this.updatePropRangeMax);
		UI.watchProperty(this.range, 'min', this.updatePropRangeMin);
		UI.watchProperty(this.range, 'isInt', this.updatePropRangeIsInt);

		this.callback = props.callback;

	}.bind(this);

	this.setLabel = function(newName) {
		$(labelDiv).text(newName);
	};

	//
	// Update Range
	//
	this.updatePropRangeMin = function() {
		this.min = this.range.min;
		this.shouldUpdateStep = true;
		// changed = true;
		this.shouldUpdateUI = true;
		this.updateUI();
	}.bind(this);


	this.updatePropRangeMax = function() {
		this.max = this.range.max;
		this.shouldUpdateStep = true;
		// changed = true;
		this.shouldUpdateUI = true;
		this.updateUI();
	}.bind(this);

	this.updatePropRangeIsInt = function() {
		this.isInt = this.range.isInt;
		this.shouldUpdateStep = true;
		var ov = this.toWatch[this.propName].value;
		if (this.isInt) this.toWatch[this.propName].value = Math.floor(this.toWatch[this.propName].value);
		if (ov !== this.toWatch[this.propName].value) UI.triggerProperty(this.toWatch, this.propName);
		this.shouldUpdateUI = true;
		this.updateUI();
	}.bind(this);


	//
	// Input change
	//
	numberDiv.onchange = function() {

		if (this.changing) return;
		var val = parseFloat(numberDiv.value,10);
		if (isNaN(val)) val = 0;
		var ov = this.toWatch[this.propName];
		this.toWatch[this.propName] = val;
		if (ov !== this.toWatch[this.propName]) UI.triggerProperty(this.toWatch, this.propName);

	}.bind(this);


	//
	// Slider change / dragging
	//
	this.dragging = false;
	this.dragStartValue = 0.0;
	this._sliderUpdate = function(e) {
		if (!this.dragging) return;
		FastEvent.trigger(this.toWatch, 'ui-update');

		var px = e.pageX !== undefined ? e.pageX : e.originalEvent.touches[0].pageX;

		if (UI.shiftPressed) {
			if (this.relativeValue === null) {
				this.relativeValue = this.value;
				this.relativeLastPosition = px;
			}
		} else {
			this.relativeValue = null;
		}


		var left = $(sliderOutDiv).offset().left;
		var width = $(sliderOutDiv).width();

		var pc = (px - left) / width;

		pc = Math.max(Math.min(pc, 1.0), 0.0);
		if (this.props.curve) {
			if (pc > 0.0 && pc < 1.0) pc = Math.pow(pc, this.props.curve);
		}

		var v = pc * (this.max-this.min) + this.min;

		if (UI.shiftPressed) {
			v = this.relativeValue +  ( (px - this.relativeLastPosition) / 4096) * (this.max-this.min);
			v = Utils.clamp(v, this.min, this.max);
		}
		if (this.isInt) v = Math.round(v);

		var ov = this.toWatch[this.propName];
		this.toWatch[this.propName] = v;

		// this.value = v;
		if (ov !== this.toWatch[this.propName]) UI.triggerProperty(this.toWatch, this.propName);			
		
		e.preventDefault();

	}.bind(this);

	this._sliderOff = function(e) {
		this.dragging = false;
		$(window).off('mousemove.'+this.uuid+' touchmove.'+this.uuid);
		$(window).off('touchend.'+this.uuid+' mouseup.'+this.uuid);
	}.bind(this);

	$(sliderOutDiv).on('mousedown touchstart', function(e) {
		this.dragging = true;
		this._sliderUpdate(e);

		$(window).on('mousemove.'+this.uuid+' touchmove.'+this.uuid, this._sliderUpdate);
		$(window).on('touchend.'+this.uuid+' mouseup.'+this.uuid, this._sliderOff);

	}.bind(this));



	this.currentColor = null;
	this.setColor = function(col) {
		if (this.currentColor == col) return;
		this.currentColor = col;
		$(sliderInDiv).css('background-color', col);
	}



	//copy path name
	$(labelDiv).on('click', function(e) {
		if (e.altKey) {
			Utils.copyToClipboard(labelDiv, this.path.concat(this.propName).join('.'));
		}
	}.bind(this));

	//
	// Remove
	//
	this.dispose = function() {

		UI.midiDisable(this.props, this.props.midiChannel, labelDiv);
		FastEvent.off(this.props, 'midiMessage');
		
		UI.unwatchProperty(this.toWatch, this.propName, this.updateValue);
		UI.unwatchProperty(this.range, 'max', this.updatePropRangeMax);
		UI.unwatchProperty(this.range, 'min', this.updatePropRangeMin);
		UI.unwatchProperty(this.range, 'isInt', this.updatePropRangeIsInt);

		this.callback = null;
		$(this).off(); FastEvent.off(this);
		$(containerDiv).off();
		$(colorCodeDiv).off();
		$(labelDiv).off();
		$(sliderOutDiv).off();
		$(sliderInDiv).off();
		$(numberDiv).off();

		$(containerDiv).remove();
		$(colorCodeDiv).remove();
		$(labelDiv).remove();
		$(sliderOutDiv).remove();
		$(sliderInDiv).remove();
		$(numberDiv).remove();

		this.domElement = null;
		containerDiv = null;
		colorCodeDiv = null;
		labelDiv = null;
		sliderOutDiv = null;
		sliderInDiv = null;
		numberDiv = null;
		this.range = null;

	};

};
export default Slider;
