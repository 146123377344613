import SETTINGS from '../Settings.js';
import LoaderXHR from './LoaderXHR.js';
import SVGFrame from '../objects/SVGFrame.js';
import ABDisposeController from '../controllers/ABDisposeController.js';

window.allSvgPoints = window.allSvgPoints||{};

function LoaderSVG(url, filename) {
	var self = this,
		completeCallback,
		errorCallback;

	url = url.replace(/\\/gi,'/');

	this.data = new SVGFrame();
	this.url = url;
	this.filename = filename;
	this.loaded = false;
	this.loading = false;
	this.progress = 0.0;
	this.weight = 1;
	this.loadType = 'svg';
	this.xhr = null;
	this.multipleBatches = false;
	this.loadedBytes = 0;
	this.decodeDirectly = true; //decodeDirectly || false;
	this.binaryFormat = false;

	this.data.loaded = false;

	this.doneCallbacks = [];
	window.allLoadedFiles.push(url);

	


	//callbacks
	function xhrDone() {
		if (!self.xhr) return;

		if (!self.binaryFormat) {
			self.data.parse(self.xhr.data.value);
		} else {
			self.data.fromBinaryFormat(AppStatus.svgBinaryMeta[self.filename], self.xhr.data.value);
		}

		self.data.isShared = self.isShared;
		self.xhr.dispose();
		self.xhr = null;
		self.progress = 1.0;

		self.loaded = true;
		self.loading = false;
		self.data.loaded = true;
		completeCallback(self);
		for (var i=0; i<self.doneCallbacks.length; i++) {
			self.doneCallbacks[self];
		}
		self.doneCallbacks = [];

	}
	function xhrError() {
		self.data.loaded = true;
		self.xhr.dispose();
		self.xhr = null;
		self.progress = 1.0;
		self.loaded = true;
		self.loading = false;
		console.log('error',url);
		for (var i=0; i<self.doneCallbacks.length; i++) {
			self.doneCallbacks[self];
		}
		self.doneCallbacks = [];
		errorCallback(self);
	}

	this.start = function(_completeCallback, _errorCallback) {
		if (self.loading || self.loaded) console.error('Already loading: ',url);
		self.loading = true;
		completeCallback = _completeCallback;
		errorCallback = _errorCallback;


		if (AppStatus.svgBinaryMeta[self.filename] && !SETTINGS.DISABLE_SVGC) {
			var binaryUrl = url;
			self.binaryFormat = true;
			// url = url.replace(/svgc/gi, 'svg').replace(/\.svg/gi, '.svgc').replace(/\/svg\//gi, '/svgc/').replace(/svg\//gi, 'svgc/')
			binaryUrl = binaryUrl.replace(/\.svg/gi, '.'+AppStatus.svgBinaryMeta[self.filename].checksum+'.svgc').replace(/\/svg\//gi, '/svgc/').replace(/svg\//gi, 'svgc/')

			if (SETTINGS.IS_LOCAL) binaryUrl += "?checksum="+AppStatus.svgBinaryMeta[self.filename].checksum;
			// if (/svgcc/.test(binaryUrl)) {
			// 	console.warn("WTF!");
			// 	console.log(binaryUrl,self.filename);
			// }
			self.xhr = new LoaderXHR(binaryUrl, 'arraybuffer');
			self.xhr.start(xhrDone,xhrError);

		} else {
			self.xhr = new LoaderXHR(url, 'text');
			self.xhr.start(xhrDone,xhrError);
		}
	};

	this.getProgress = function() { 
		if (self.xhr) return self.xhr.getProgress();
		return self.progress;
	};

	this.dispose = function() {
		if (self.xhr) self.xhr.dispose();
		if (self.data && self.data.value && self.data.value.byteLength) ABDisposeController.dispose(self.data.value);
		if (self.data) self.data.dispose();
		completeCallback = errorCallback = self.url = self.start = self.getProgress = self.dispose = self.data = null;
	};

	this.reset = function() {
		self.data.loaded = false;
		if (self.data && self.data.value && self.data.value.byteLength) ABDisposeController.dispose(self.data.value);
		self.data.value = self.xhr = null;
		self.loading = self.loaded = false;
		if (self.data) self.data.dispose();
		self.data.loadError = self.loadError = false;
		self.progress = 0.0;
	}

	this.getWeight = function() {
		return this.weight;
	};
}
export default LoaderSVG;