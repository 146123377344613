import $ from 'jquery';

import * as THREE from 'three';
import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';

import SVGFrame from './SVGFrame.js';




//----------------
//
// SVG Frame
// Load all splines from an svg in an easily readable/morphable format
//
//----------------
class SVGSequence {

	constructor() {
		this.isSequence = true;
		this.loaded = false;
		this.frames = [];
		this.fps = 8;
		this.uuid = "seq"+Utils.generateID();
		this.layers = {};
		this.parent = null;
		this.disposed = false;
	}

	loadComplete() {
		// for (var layer in this.layers) {

		// 	var arr = [];
		// 	var hasLayer = false;
		// 	for (var i = 0; i <this.frames.length; i++) {
		// 		if (this.frames[i].layers[layer]) {hasLayer = true; window.seq = this.frames[i]; break; }
		// 	}
		// 	if (hasLayer) {
		// 		for (var i = 0; i <this.frames.length; i++) {
		// 			if (this.frames[i].layers[layer]) arr.push(this.frames[i].layers[layer]);
		// 			else if (hasLayer) {arr.push(new SVGFrame());}
		// 		}

		// 		var seq = this.layers[layer];
		// 		seq.frames = arr;
		// 		seq.fps = this.fps;
		// 		seq.loaded = true;
		// 		// seq.parent = this;
		// 	}
		// }
	}

	getDuration(fps) {
		if (!fps) return this.frames.length;
		return (this.frames.length / fps);
	}

	getFrame() {
		return this.frames[0];
	}

	getEndFrame() {
		return this.frames[this.frames.length - 1];
	}

	isLayerMode() {
		for (var i = 0; i <this.frames.length; i++) {
			if (this.frames[i].layerMode) return true;
		}
		return false;
	}
	getLayer(layer) {
		if (this.layers[layer]) return this.layers[layer];

		var arr = [];
		var hasLayer = false;
		for (var i = 0; i <this.frames.length; i++) {
			if (this.frames[i].layers[layer]) {hasLayer = true; break; }
		}
		if (!hasLayer) return null;

		for (var i = 0; i <this.frames.length; i++) {
			if (this.frames[i] && this.frames[i].layers[layer]) arr.push(this.frames[i].layers[layer]);
			else if (hasLayer) {arr.push(new SVGFrame());}
		}
		var seq = new SVGSequence();
		// seq.parent = this;
		// seq.loader = this.loader;

		seq.frames = arr;
		seq.fps = this.fps;
		seq.loaded = true;
		seq.parent = this;
		this.layers[layer] = seq;
		return seq;
	}
		

	dispose() {
		// if (this.disposed) return;
		this.disposed = true;
		for (var i = 0; i <this.frames.length; i++) {
			if (this.frames[i]) this.frames[i].dispose();
		}
		for (var o in this.layers) {
			this.layers[o].dispose();
		}
		
		this.loaded = false;
		this.frames = [];
		this.layers = {};
	}
}

export default SVGSequence;

