//  The Worker to be sent as bloburl
function BackgroundWorker() {
	this.receive = function(e) {};
	this.addEventListener('message', this.receive.bind(this));
};

//-----------
//
//  The Controller
//
//-----------
function ABDisposeController() {

	this.worker = null;

	this.setup = function() {
		if (this.worker) return;
		var blobURL = window.URL.createObjectURL( new Blob([ '(',BackgroundWorker.toString(), ')()' ], { type: 'application/javascript' } ) );
		this.worker = new Worker( blobURL );
		URL.revokeObjectURL( blobURL );
		this.worker.onmessage = function(e) {};
	};

	this.dispose = function(ab) {
		if (!ab || !this.worker) return;
		if (!ab.byteLength === undefined) {console.warn("disposing error : not an arraybuffer"); return;}
		if (!ab.byteLength) return;
		this.worker.postMessage({
			action: 'disposeArraybuffer',
			ab: ab
		},[ab]);
	}.bind(this);

	// reset
	this.cleanup = function() {
		if (this.worker) {
			try {this.worker.terminate();}catch(er){};
		}
		this.worker = null;
	};
};

window.ABDisposeController = window.ABDisposeController||new ABDisposeController();

export default window.ABDisposeController;
