import $ from 'jquery';
import * as THREE from 'three';
// import * as dat from 'dat.gui';
// var ThreeLine = require('three-line-2d')(THREE);
// var ThreeLineBasicShader = require('three-line-2d/shaders/basic')(THREE);
var normalizePath = require('normalize-path-scale');
// var arc = require('arc-to');

window.normalizePath = normalizePath;

import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import Fbo from '../utils/Fbo.js';
import addGuiOption from '../utils/GUIHelper.js';

import Scene from './Scene.js';

var presetFolder = 'data/presets/audioeffect/';

import SVGLoader from '../utils/SVGLoader.js';
const SVGParser = new SVGLoader();
var parser = typeof DOMParser !== 'undefined'?(new DOMParser()) : null;

import Easing from 'easing-functions';

import SVGParse from 'parse-svg-path'
import SVGAbs from 'abs-svg-path'
import SVGNormalize from 'normalize-svg-path'
// import SVGBalance from 'balance-svg-paths'
import SVGSimplify from 'simplify-path'
// import SVGContours from 'svg-path-contours'
// import SVGPathOutline from 'svg-path-outline'
// var makerjs = require("makerjs");


// import SVG from 'svg.js';
// var svgDraw = SVG();

// import SVGMesh3D from 'svg-mesh-3d';
// import triangleCentroid from 'triangle-centroid'

// import rough from 'roughjs';
// import rough from 'roughjs/bundled/rough.esm.js'
// window.rough = rough;

var SVG_SIZE = 1080;

// const createGeom = require('three-simplicial-complex')(THREE)

// var ThreeLine = require('three-line-2d')(THREE);
// var ThreeLineBasicShader = require('three-line-2d/shaders/basic')(THREE);


import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';

window.Line2 = Line2;
window.LineMaterial = LineMaterial;
window.LineGeometry = LineGeometry;

import { makeNoise2D } from "open-simplex-noise";

import  AudioTween from "../sound/AudioTween.js";
import  MixNode from "../sound/MixNode.js";

import DreamUI from "../DreamUI.js";

import LoaderAudio from "../loading/LoaderAudio.js";

var targets = [
	"clean/body_11_clean_1.svg",
	"clean/body_11_clean_2.svg",
	"clean/body_11_clean_3.svg",
	"clean/body_11_clean_4.svg",
	"clean/body_11_clean_5.svg",
	"clean/body_11_clean_6.svg",
	"clean/body_11_clean_7.svg",
	"clean/body_11_clean_8.svg",
	"clean/body_11_clean_9.svg",
	"clean/body_11_clean_10.svg",
	"clean/body_11_clean_11.svg",
	"select_shapes/body_3_6.svg",
	"select_shapes/body_6_4.svg",
	"select_shapes/body_6_8.svg",
	"select_shapes/body_6_14.svg",
	"select_shapes/body_6_16.svg",
	"select_shapes/body_9_hit_2.svg"
];

var LINE_RESOLUTION = 1024;
var SVG_CURVE_PARSE_RESOLUTION = 6;
var MAX_LINES = 64;


var T_DURATION = 0.01;
var audioContext = window.audioContext = window.audioContext||new AudioContext();




/*

Base Layer
Other Layer:
volumeMin:,
volumeMax:

feedbackMin,
feedbackMax,
reverbMin,
reverbMax,

lfoMin,
lfoMax,

pitchMin,
pitchMax,

lfoPitchMin,
lfoPitchMax,

eqLowMin,
eqLowMax,

eqMediumMin,
eqMediumMax,


eqHighMin,
eqHighMax,

bitterMin,
bitterMax,

*/



class CleanTween extends Scene {

	constructor() {
		super()
		this.disposed = false;

		if (SETTINGS.isMobile) LINE_RESOLUTION = 512;
		this.presetFolder = presetFolder;

		//gui presets
		// this.enablePresets(presetFolder);
		this.currentSvgName = null;
		this.svgByName = {};
		this.allSvgNames = [];

		this.dragSpeed = 0.0;
		this.currentDragSpeed = new THREE.Vector2(0.0, 0.0);
		this.currentDragPosition = new THREE.Vector2(0.0, 0.0);
		this.targetPosition = new THREE.Vector2(0.0, 0.0);
		this.currentPosition = new THREE.Vector2(0.0, 0.0);
		this.startPosition = new THREE.Vector2(0.0, 0.0);
		this.startPositionRandom = new THREE.Vector2(0.0, 0.0);
		this.directionRandom = new THREE.Vector2(1.0, 1.0);
		this.targetSplines = [];

		this.dragPc = 0.0;


		//three.js
		this.scene = new THREE.Scene();
		this.camera = new THREE.PerspectiveCamera( 50, AppStatus.innerWidth() / AppStatus.innerHeight(), 0.1, 100000 );
		this.camera.position.set( 0, 0, 2200 );
		this.camera.scale.y = -1;
		this.camera.aspect = AppStatus.innerWidth() / AppStatus.innerHeight();
		this.camera.updateProjectionMatrix();

		this.eyes = new THREE.Object3D();
		this.scene.add(this.eyes);
		this.svgsData = [];
		this.eyesSvgData = [];
		this.eyesGroups = [];
		this.eyeLines = [];
		this.eyesClosed = false;

		this.nextCligne = performance.now()+5000;
		this.cligneStart = performance.now();

		this.morphStopped = true;
		this.morphPc = 0.0;
		this.morphTarget = 0;


		this.tmpPoints = [];
		for (var i=0; i<LINE_RESOLUTION; i++) {
			this.tmpPoints.push(new THREE.Vector3(0,0,0));
		}
		this.tmpSpline = new THREE.SplineCurve(this.tmpPoints);

		this.svgIdByName = {};
		this.transitionData = {};
		this.allLines = [];

		var randomOrder = [];
		for (var i=0; i<targets.length; i++) {
			randomOrder.push(i);
		}
		this.randomOrder = Utils.shuffle(randomOrder);

		this.currentFrame = 0;
		this.currentImpactRandom = 0;

		//gui
		window.DreamUI = DreamUI;
		this.hasDatGUI = false;
		this.options = AudioEffectController.options;
		this.options.savePreset = () => {
			var value = prompt('Nom du preset', this.options.preset);
			if (!value) {return;}

			value = value.replace(/\ /gi,'_').toLowerCase().replace(/é|e|ê/gi,'e').replace(/à/gi,'a');

			if (!/\.json/.test(value)) {
				value = value+'.json';
			}
			var sfx = this.options;
			delete this.options.sfx;
	        var jsonFile = JSON.stringify(
	        	{
					version: SETTINGS.INTERNAL_VERSION,
					options: this.options
				},
			null,'\t');
			this.options.sfx = sfx;

	        Utils.saveJsonSimple(value, presetFolder, jsonFile).then(function() {
	       	 	this.options.preset = value;
	       	 	DreamUI.update(this.options, 'preset');
				DreamUI.triggerEvent(this.ranges.preset, 'autofill');
	       	 	console.log("Save successful ",value);
	       	 	// Utils.cloneInto()
	        }.bind(this)).catch(function(e) {
	        	console.log("Save error!",e);
	        	// saving = false;
	        });
		};
		
		this.defaultOptions = Utils.clone(this.options);
		this.options.sfx.play = 


		this.ranges = AudioEffectController.ranges;
		// this.ranges.mobile.callback = () =>{
		// 	console.log("mousemove")
		// 	SETTINGS.MOUSEMOVE_MODE = !SETTINGS.MOUSEMOVE_MODE;
		// };
		this.ranges.preset.callback = () => {
			if (this.options.preset === 'none') {
				this.currentPresetName = 'none';
			} else {
				this.reloadPreset(true);
			}
		};
		
		this.ranges.timeline.cue.callback = (val, dragging) => {
			if (this.timecodeById[this.options.timeline.cue]) {
				var info = this.timecodeById[this.options.timeline.cue];

				console.log(info);
				this.options.timeline.timeStart = Utils.secondsToTimecode(info.timeStart);
				DreamUI.triggerProperty(this.options.timeline, "timeStart", info.timeStart);
				this.options.timeline.timeEnd = Utils.secondsToTimecode(info.timeEnd);
				DreamUI.triggerProperty(this.options.timeline, "timeEnd", info.timeEnd);

				AudioController.seek(info.timeStart);
			}
		};
		if (SETTINGS.isMobile && !SETTINGS.isTablet) {
			for (var o in this.ranges) {
				if (o!=='preset' && o!='voiceVolume') {
					this.ranges[o].isHidden = true;
				}
			}
		}

		this.guiCallbacks = Utils.cloneIntoAdd({}, this.guiCallbacks||{});		

		DreamUI.autofillEnabled = true;
		DreamUI.init(this.options, this.ranges);
		this.tweenPc = 0.0;
	
	};

	cloneIntoMod(source, target) {
		for (var prop in source) {
			if (prop in target) {
				var isObj = source[prop] && source[prop].constructor == Object;

				if (isObj && source[prop].value==undefined && source[prop].values==undefined) {
					console.log("Updating folder:",prop);
					this.cloneIntoMod(source[prop], target[prop]);

				} else if (isObj && source[prop].value!==undefined) {

					var up = (target[prop].value != source[prop].value);
					target[prop].value = source[prop].value;
					DreamUI.update(target, prop); //if (up) 

				} else if (isObj && source[prop].values) {

					target[prop].values = Utils.cloneArray(source[prop].values);
					DreamUI.update(target, prop);

				} else {
					var up = (target[prop] != source[prop]);
					if (up) console.log("updating value:",prop, target[prop], source[prop]);
					target[prop] = source[prop];
					if (up) DreamUI.update(target, prop); //if (up) 

				}
			}
		}
	}

	reloadPreset(force) {
		if (this.options.preset == this.currentPresetName) return;
		this.currentPresetName = this.options.preset;
		var xhr = new LoaderXHR(this.presetFolder+this.options.preset, 'json', true);
		xhr.start(function(val) {
			console.log("Loaded preset", xhr.data.value);
			var migrated = AudioEffectController.migratePreset(xhr.data.value);
			if (migrated.preset) delete migrated.options.preset;
			this.cloneIntoMod(migrated.options, this.options);

			DreamUI.update(this.options, 'preset');
			this.options.preset = this.currentPresetName;

			DreamUI.triggerUpdates();
			console.log(this.options);

			DreamUI.triggerEvent(this.ranges.impact, this.options.impact.enabled ? 'openFolder':'closeFolder')
			DreamUI.triggerEvent(this.ranges.feedback, (this.options.feedback.enabled && this.options.feedback.mix>0.0) ? 'openFolder':'closeFolder')
			DreamUI.triggerEvent(this.ranges.eq, this.options.eq.eqGain>0.001 ? 'openFolder':'closeFolder')
			DreamUI.triggerEvent(this.ranges.effectGain, (this.options.effectGain.volumeMin+this.options.effectGain.volumeMax+this.options.effectGain.reverbMin+this.options.effectGain.reverbMax)>0.001 ? 'openFolder':'closeFolder')
			DreamUI.triggerEvent(this.ranges.bandpass, this.options.bandpass.maxMix>0.001 ? 'openFolder':'closeFolder');
			DreamUI.triggerEvent(this.ranges.oscillator, this.options.oscillator.enabled ? 'openFolder':'closeFolder');


			// DreamUI.triggerProperty(this.ranges, 'preset'
		}.bind(this))
	}



	//preload json data
	prePreloadData(batchName) {

		this.timelineInfo = Loader.addXHR(batchName, 'data/timeline.json', 'json5');

		Loader.addShader(batchName, 'shaders/brushes/source.frag');

		if (SETTINGS.IS_LOCAL) this.impactList =  Loader.addXHR(batchName, '/filelist?dir=audio/impact/', 'json');
		else this.impactList  =  Loader.addXHR('prePreloadData', 'data/autofillcache/audio_impact_.json', 'json');

		SequenceRenderer.preloadData(batchName);

	}

	//preload json data
	preloadData(batchName) {
		super.preloadData(batchName);
		AudioEffectController.preloadData(batchName);
		SequenceRenderer.preload(batchName);


		this.timelineInfo = this.timelineInfo.value;
		AppStatus.timelineInfo = this.timelineInfo;
		if (this.timelineInfo.AUDIO_DURATION) AppStatus.AUDIO_DURATION = this.timelineInfo.AUDIO_DURATION;
		if (this.timelineInfo.voice) AudioController.setAudioFile(this.timelineInfo.voice, this.timelineInfo.audio_divisions);

		//
		// Get all timecodes and update GUI
		//
		this.timecodes = [];
		this.timecodeIds = [];
		this.timecodeById = {};
		this.cues = ["none"];
		for (var timecode in this.timelineInfo.audio) {
			var info = {
				timeStart:Utils.timecodeToSeconds(timecode),
				timecode:Utils.timecodeToSeconds(timecode),
				info: this.timelineInfo.audio[timecode],
				id: this.timelineInfo.audio[timecode].id
			}
			this.cues.push(this.timelineInfo.audio[timecode].id);
			this.timecodeById[info.id] = info
			this.timecodes.push(info);
		}
		

		//sort and add durations
		this.timecodes = this.timecodes.sort(function(a,b) {
			return a.timecode < b.timecode ? -1 : 1;
		});
		for (var i=0; i<this.timecodes.length-1; i++) {
			this.timecodes[i].duration = this.timecodes[i+1].timecode - this.timecodes[i].timecode;
			this.timecodes[i].timeEnd = this.timecodes[i].timeStart + this.timecodes[i].duration;
			this.timecodes[i].arrayId = i;
		}
		this.timecodes[this.timecodes.length-1].duration = AppStatus.AUDIO_DURATION - this.timecodes[i].timecode;
		this.timecodes[this.timecodes.length-1].timeEnd = this.timecodes[this.timecodes.length-1].timeStart + this.timecodes[this.timecodes.length-1].duration;
		for (var i=0; i<this.timecodes.length; i++) {
			this.timecodeIds.push( this.timecodes[i].id);
		}

		//get next preset
		this.nextPresetByCue = {};
		for (var i=0; i<this.timecodes.length-1; i++) {
			this.nextPresetByCue[this.timecodes[i].id] = this.timecodes[i+1].info.preset;
		}


		this.timecodeById["none"] = {
			timeStart: 0,
			timecode: 0,
			timeEnd: 60.0,
			info: null,
			id: "none"
		}

		//change audio cue
		this.ranges.timeline.cue.values = this.cues;
		DreamUI.triggerEvent(this.ranges.timeline.cue, "updateRange");


	}
	

	//preload all assets
	preload(batchName) {
		super.preload(batchName);

	
		//preload all targets
		for (var i=0; i<targets.length; i++) {
			this.svgsData[i] = Loader.addXHR(batchName, 'images/svg/'+targets[i], 'text');
			this.svgIdByName[targets[i]] = i;
		}

		for (var i=1; i<4; i++) {
			this.eyesSvgData.push(Loader.addXHR(batchName, 'images/eyes/eyes_1_'+i+'.svg', 'text'));
		}
		Loader.addShader(batchName, 'shaders/line/v0.vert');
		Loader.addShader(batchName, 'shaders/line/v0.frag');

		this.voiceBuffer = Loader.addAudio(batchName, 'audio/timeline/v1_proto_caro_lowpitch_01.mp3');
		this.silenceInfo = Loader.addXHR(batchName, 'audio/temp/silences.json', 'json');

	}



	parseSVGSDirect(rawPathTxt, returnPoints) {
		// var simplifiedPath = (SVGNormalize(SVGAbs(SVGParse(rawPath))));
		// rawPath = simplifiedPath.join(' ');

		// var input = makerjs.importer.fromSVGPathData(rawPath, { bezierAccuracy: 0.5 });
		// var chains = makerjs.model.findChains(input, {
		// 	contain: false,
		// 	shallow: false,
		// 	unifyBeziers: true,
		// 	byLayers: true
		// })[""];

		// if (!returnPoints)console.log(simplifiedPath);
		// var n = 0;
		// for (var i=0; i<simplifiedPath.length; i++) {if (simplifiedPath[i][0]=='M') n++;}
		// if (!returnPoints)console.log(n);

		var allSplines = [];
		// if (!returnPoints) console.log(chains);
		// for (var n = 0; n<chains.length; n++) {
		// 	var chain = chains[n];
		// 	window.chain = chain;
		// 	if (chain.links.length>1) {
		// 		// console.log(chain.links.length);


		// 		//
		// 		// Invert start and end if necessary
		// 		//
				var shouldFlip = false;
		// 		if (chain.links[0].endPoints[0][0] > chain.links[chain.links.length-1].endPoints[0][0]) {
		// 			shouldFlip = true;
		// 		}
		// var svgData = parser.parseFromString(rawPathTxt,"text/xml");
		// var rawPaths = $(svgData).find('path');

		// for (var i=0; i<rawPaths.length; i++) {

				//
				// Invert start and end if necessary
				//
				// var shouldFlip = false;
				// if (chain.links[0].endPoints[0][0] > chain.links[chain.links.length-1].endPoints[0][0]) {
				// 	shouldFlip = true;
				// }


				// rawPath = makerjs.exporter.chainToSVGPathData(chain,0,0.01);

				var rawPath = rawPathTxt;

		// 		rawPath = makerjs.exporter.chainToSVGPathData(chain,0,0.01);
				var simplifiedPath = (SVGNormalize(SVGAbs(SVGParse(rawPath))));

				var currentSubPath = [];
				var subPaths = [];
				for (var j = 0; j <simplifiedPath.length; j++) {
					if (simplifiedPath[j][0] == "M") {
						currentSubPath = [];
						subPaths.push(currentSubPath);
					}
					currentSubPath.push(simplifiedPath[j]);
					for (var k=1; k<simplifiedPath[j].length; k+=2) {
						simplifiedPath[j][k] = simplifiedPath[j][k] / SVG_SIZE - 0.5;
						simplifiedPath[j][k+1] = simplifiedPath[j][k+1] / SVG_SIZE - 0.5;
					}
				}
				// for (var j = 0; j <subPaths.length; j++) {
				// 	var subPath = subPaths[j];
				// 	if (subPath[0][0]  > subPath[subPath.length-1][0]) {
				// 		shouldFlip = true;
				// 	}
				// }

				//
				// Create a line mesh for each subpaths
				//
				for (var j=0; j<subPaths.length; j++) {

					shouldFlip = false;
					var subPath = subPaths[j];
					if (subPath[0][1]  > subPath[subPath.length-1][1]) {
						shouldFlip = true;
					}
					// console.log(j, shouldFlip, subPath[0][1], subPath[subPath.length-1][1]);

					// if (j==4||true) {

						var currentPos = new THREE.Vector2(subPaths[j][0][1], subPaths[j][0][2]);
						var linePath = [];
						var points = [];
						var centroid = new THREE.Vector2(0,0), numWeight = 0;
						points.push(currentPos); //[currentPos.x, currentPos.y]);
						for (var k=1; k<subPaths[j].length; k++) {
							// console.log(subPaths[j][k]);
							var nextPos = new THREE.Vector2(subPaths[j][k][5], subPaths[j][k][6]);
							var curve = new THREE.CubicBezierCurve(
								currentPos,
								new THREE.Vector2(subPaths[j][k][1], subPaths[j][k][2]),
								new THREE.Vector2(subPaths[j][k][3], subPaths[j][k][4]),
								nextPos);

							var weight = curve.getLength();
							centroid.x += nextPos.x * weight;
							centroid.y += nextPos.y * weight;
							numWeight += weight;

							// var subPoints = curve.getSpacedPoints(15);
							var subPoints = curve.getPoints(SVG_CURVE_PARSE_RESOLUTION);
							subPoints.pop();
							// console.log(currentPos, nextPos, subPoints);
							// subPoints.push(currentPos);
							// subPoints.unshift(nextPos);
							// points.push(nextPos);
							points = points.concat(subPoints);
							currentPos = nextPos;
						}
						points.push(currentPos);
						centroid.x /= numWeight;
						centroid.y /= numWeight;


						// for (var k=1; k<points.length; k++) {
						// 	if (points.length>3 && points[k].distanceTo(points[k-1]) < 0.01) {
						// 		points.splice(k,1);
						// 		k--;
						// 	}
						// }

						var oPoints = points;
						if (shouldFlip) oPoints = oPoints.reverse();
						var spline = new THREE.SplineCurve(points);

						var points = spline.getSpacedPoints((LINE_RESOLUTION-3));
						points.unshift(oPoints[0])
						points.push(oPoints[oPoints.length-1]);
						
						if (!returnPoints) {
							spline = new THREE.SplineCurve(points);
							spline.startPoint = oPoints[0];
							spline.endPoint = oPoints[oPoints.length-1];
							spline.centroid = centroid;

							var an = Utils.normalizeAngle(Math.atan2(spline.centroid.y, spline.centroid.x));
							spline.sortedAngle = Utils.distanceBetweenAngles(an, -Math.PI*0.5) * -Utils.directionBetweenAngles(an, -Math.PI*0.5);

							allSplines.push(spline);
						} else {
							// var points = spline.getSpacedPoints((LINE_RESOLUTION-3));
							// points.unshift(oPoints[0])
							// points.push(oPoints[oPoints.length-1]);

							for (var k=0; k<points.length; k++) {
								points[k] = [points[k].x, points[k].y];
							}
							allSplines.push(points);
						}
					// }
				}
			// }
		// 	}
		// }
		return allSplines;
	}

	//setup interaction and events
	start() {

		super.start();

		this.impactList  = this.impactList.value.files;
		var actualImpactList = {};
		console.log(this.impactList);
		this.impactFolders = {};
		for (var i=0; i<this.impactList.length; i++) {
			var split = this.impactList[i].split('/');
			var folder = split.shift();
			// console.log(folder, split);
			if (split.length>0) {
				this.impactFolders[folder] = this.impactFolders[folder] ||[];
				this.impactFolders[folder].push(this.impactList[i]);
			}
			actualImpactList[folder] = true;
		}
		console.log( Object.keys(actualImpactList));
		this.ranges.impact.file.values = Object.keys(actualImpactList);
		DreamUI.triggerEvent(this.ranges.impact.file, "updateRange");

		SequenceRenderer.setup();
		AudioController.setup({sequence:{timeline:{timeStart: "00:00", timeEnd: "01:00"}}});

		this.fbo = new Fbo(1920*2, 1080*2, {
			format: THREE.RGBAFormat,
			minFilter: THREE.LinearFilter,
			magFilter: THREE.LinearFilter,
			generateMipmaps: false,
			wrap: THREE.ClampToEdgeWrapping,
			type: THREE.UnsignedByteType,
			premultiplyAlpha: false,
			depthBuffer: false,
			stencilBuffer: false,
			pingPongEnabled: false
		});


		//----------------
		//
		// Parse all SVG
		//
		//----------------
		for (var svgd=0; svgd<this.svgsData.length; svgd++) {
			var svgData = SVGParser.parse(this.svgsData[svgd].value);
			var rawPaths = $(svgData.xml).find('path');

			var sp = [];
			for (var i=0; i<rawPaths.length; i++) {

				var rawPath = rawPaths[i].getAttribute('d');
				var splines = this.parseSVGSDirect(rawPath, false);
				sp = sp.concat(splines);
				// this.targetSplines.push(splines);
				// console.log(splines);
			}
			this.targetSplines.push(sp);
		}

	

		//----------------
		//
		// Create Animation
		//
		//----------------
		this.lineMaterial = new LineMaterial( {
			color: 0,
			linewidth: 0.005, // in pixels
			dashed: false
		});

		//----------------
		//
		// Parse & add eyes SVG
		//
		//----------------
		this.eyeMaterials = [];
		for (var svgd=0; svgd<3; svgd++) {
			var svgData = SVGParser.parse(this.eyesSvgData[svgd].value);
			var rawPaths = $(svgData.xml).find('path');


			var group = new THREE.Object3D();
			for (var i=0; i<rawPaths.length; i++) {
				var rawPath = rawPaths[i].getAttribute('d');
				var allPoints = this.parseSVGSDirect(rawPath, false);

				for (var j=0; j<allPoints.length; j++) {


					var points = allPoints[j].getPoints(256);
					points.unshift(allPoints[j].startPoint)
					points.push(allPoints[j].endPoint);
					var linePoints = new Float32Array(points.length*3);
					for (var i = 0; i < points.length; i++) {linePoints[i*3] = points[i].x; linePoints[i*3+1] = points[i].y;}

					const geometry = new LineGeometry();
					geometry.setPositions( linePoints );
					var matLine = new LineMaterial( {
						color: 0,
						linewidth: 0.005, // in pixels
						dashed: false,
						transparent: true,
						opacity: 1.0
					} );

					var line = new Line2( geometry, matLine );
					line.computeLineDistances();
					line.scale.set( 1024, 1024, 1024 );
					// line.position.y -= 256;
					this.scene.add( line );

					group.add(line);
					this.eyeMaterials.push(line.material);
				}	
				if (svgd>0) group.visible = false;

				this.eyes.add(group);
				this.eyesGroups.push(group);
			}
		}



		//----------------
		//
		// Pre-create all lines
		//
		//----------------
		for (var i=0; i<MAX_LINES; i++) {
			var pos = new Float32Array(LINE_RESOLUTION*3);
			var  geometry = new LineGeometry();
			geometry.setPositions( pos );
			
			var line = new Line2( geometry, this.lineMaterial.clone());
			line.computeLineDistances();
			line.visible = true;
			line.scale.set(1024,1024,1024);
			line.position.y -= 256;
			line.positionArray = pos;
			this.scene.add( line );
			this.allLines.push(line);
		}
		renderer.setRenderTarget(this.fbo.texture);
		renderer.clear();
		renderer.render(this.scene, this.camera);


		//----------------
		//
		// Start audio context
		//
		//----------------
		var startDiv = document.createElement('div');
		$(startDiv).html('Cliquez pour commencer').css({
			display: "grid",
			position: 'fixed',
			// top: '50%',
			// left: '50%',
			"text-align": "center",
			"font-size": "3em",
			'background-color': "white",
			height:"100%",
			width:"100%",
			display: "grid",
			  "align-items": "center",
			  "justify-content": "center",
			"z-index": 1000,
			"cursor": "pointer"
			// transform: "translate(-50%, 0%)"
		}).appendTo(document.body).one("click", () => {

			AudioController.timelineStarted = true;
			AudioController.startContext(() =>{
				$(startDiv).remove();
				this.audioStarted = true;
				AudioEffectController.setup();


			});
		


		});



		//
		// Create list of silences and words
		//
		this.wordList = [];
		for (var i = 0; i <this.silenceInfo.value.silenceStart.length-1; i++) {
			var start = this.silenceInfo.value.silenceStart[i];
			var end = this.silenceInfo.value.silenceEnd[i];
			var nextStart = this.silenceInfo.value.silenceStart[i+1];

			this.wordList.push({
				"type":"silence",
				start: start,
				end: end
			});
			this.wordList.push({
				"type":"word",
				start: Math.max(end-T_DURATION,0.0),
				end: nextStart+T_DURATION
			});
		}

		this.currentReaction = 0;
		this.hasReacted = true;

		//touch/mouse control
		$('#main').mousedown((e)=>{
			this.isDragging = true;
			this.targetPosition.x = (e.pageX/ AppStatus.innerWidth()) * 2.0-1.0;
			this.targetPosition.y = (e.pageY/ AppStatus.innerHeight()) * 2.0-1.0;

			this.startPosition.copy(this.targetPosition);
			this.dragPc = Math.max(this.dragPc, 0.3);
			this.dragPc += 0.33;
			// this.dragPc = Math.min(this.dragPc, 1.0);



			this.currentPosition.copy(this.targetPosition);
			this.hasReacted = false;
			this.pressedThisFrame = true;
		})
		.on('mousemove', function(e) {
			if (this.isDragging) {
				var nx = (e.pageX/ AppStatus.innerWidth()) * 2.0-1.0;
				var ny = (e.pageY/ AppStatus.innerHeight()) * 2.0-1.0;
				this.currentDragSpeed.x += nx-this.targetPosition.x;
				this.currentDragSpeed.y += ny-this.targetPosition.y;
				this.targetPosition.set(nx, ny);
			}	
		}.bind(this));
		$(window).mouseup((e)=>{this.isDragging = false;});


		$('#main').on('touchstart', function(e) {
			this.isDragging = true;
			this.targetPosition.x = (e.originalEvent.touches[0].pageX/ AppStatus.innerWidth()) * 2.0-1.0;
			this.targetPosition.y = (e.originalEvent.touches[0].pageY/ AppStatus.innerHeight()) * 2.0-1.0;
			this.currentPosition.copy(this.targetPosition);
			this.startPosition.copy(this.targetPosition);

			this.dragPc = Math.max(this.dragPc, 0.3);
			this.dragPc += 0.33;
			this.dragPc = Math.min(this.dragPc, 1.0);


			this.hasReacted = false;
			this.pressedThisFrame = true;
		}.bind(this)).on('touchmove', function(e) {
			var nx = (e.originalEvent.touches[0].pageX/ AppStatus.innerWidth()) * 2.0-1.0;
			var ny = (e.originalEvent.touches[0].pageY/ AppStatus.innerHeight()) * 2.0-1.0;

			this.currentDragSpeed.x += nx-this.targetPosition.x;
			this.currentDragSpeed.y += ny-this.targetPosition.y;
			this.targetPosition.set(nx, ny);
		}.bind(this))
		$(window).on('touchend', (e)=>{this.isDragging = false;});

	}



	getTransitionData(splinesStart, splinesEnd) {

		var opt = this.options;

		opt.tweenRotation = opt.tweenRotation||0;

		splinesStart = [].concat(splinesStart); //this.targetSplines[start]);
		splinesEnd = [].concat(splinesEnd); //this.targetSplines[end]);
		var invertStartStop = true;
		var randomSort = this.options.assignRandom; // (start == end && !opt.tweenRotation) || 

		if (splinesStart.length > splinesEnd.length) {
			// console.log("INVERT TWEEN");
			invertStartStop = false;
			var o = splinesEnd;
			splinesEnd = splinesStart;
			splinesStart = o;
		}


		//should sort shapes based on size / angle / distance
		//but just assign randomly for now
		splinesStart = Utils.shuffle(splinesStart);
		splinesEnd = Utils.shuffle(splinesEnd);

		var numShapesStart = splinesStart.length;
		var numShapesEnd = splinesEnd.length;


		// var splinesA = splinesStart;
		// var splinesB = splinesEnd;
		// var flippedAssign = false;

		// //assign shapes based on most shapes fit to least shapes
		// if (numShapesStart > numShapesEnd ) {
		// 	splinesB = splinesStart;
		// 	splinesA = splinesEnd;
		// 	flippedAssign = true;
		// }



		var totalLengthStart = 0;
		var totalLengthEnd = 0;
		for (var i=0; i<splinesStart.length; i++) {
			splinesStart[i].cachedLength = splinesStart[i].cachedLength||splinesStart[i].getLength();
			totalLengthStart += splinesStart[i].cachedLength
		}
		for (var i=0; i<splinesEnd.length; i++) {
			splinesEnd[i].cachedLength = splinesEnd[i].cachedLength||splinesEnd[i].getLength();
			totalLengthEnd += splinesEnd[i].getLength();
		}



		var lines = [];
		var linePoints = [];
		var randomSpeed = [];
		var startShapeTargets= [];
		var endShapeTargets = [];

		//
		// assign end shapes to start shapes
		//
		// if (numShapesEnd >=  numShapesStart) {

		// splinesStart.sort(function(a,b) {
		// 	return a.cachedLength >= b.cachedLength ? -1 : 1;
		// });
		splinesStart.sort(function(a,b) {
			return a.sortedAngle > b.sortedAngle ? 1 : -1;
		});
		splinesEnd.sort(function(a,b) {
			return a.sortedAngle > b.sortedAngle ? 1 : -1;
		});
		if (randomSort) splinesEnd = Utils.shuffle(splinesEnd);

		for (var i=0; i<opt.tweenRotation; i++) {
			splinesEnd.unshift(splinesEnd.pop());
		}


		var currentPc = 0.0;
		var currentShapeStart = 0;
		var currentStartPoint = 0;

		//assign shape 1 to shape 1, 2 to 2, etc
		// var pointsByShape = LINE_RESOLUTION / splinesStart.length;
		var numShapesByShape = (splinesStart.length+1) / ((splinesEnd.length));
		
		//roughly assign each end shape to a start shape
		var currentStartShape = 0, currentNShape = 0, totalLengthByStartShape = [];
		for (var i = 0; i < splinesEnd.length; i++) {

			//select target
			endShapeTargets[i] = ({
				"target": currentStartShape,
				numPoints: 0,
				startPoint: 0,
				endPoint: 0
			});

			//calc total length
			totalLengthByStartShape[currentStartShape] = totalLengthByStartShape[currentStartShape]||0;
			totalLengthByStartShape[currentStartShape] += splinesEnd[i].cachedLength;

			//increment shape
			currentNShape+=numShapesByShape;
			if (currentNShape >= 1 && currentStartShape<splinesStart.length-1) {
				currentNShape -= 1;
				currentStartShape++;
			}
		}

		// console.log("currentStartShape", currentStartShape,splinesEnd.length, splinesStart.length, numShapesByShape, currentNShape);

		//get num points for each spline
		var currentPointByStartShape = [];
		var currentPointByEndShape = [];
		for (var i = 0; i < splinesEnd.length; i++) {
			var startShape = endShapeTargets[i].target;

			var pc = splinesEnd[i].cachedLength / totalLengthByStartShape[startShape];
			endShapeTargets[i].numPoints = Math.max(Math.round(pc * LINE_RESOLUTION),3);

			currentPointByStartShape[startShape] = currentPointByStartShape[startShape]||0;
			endShapeTargets[i].startPoint = currentPointByStartShape[startShape];
			currentPointByStartShape[startShape] += endShapeTargets[i].numPoints;
			endShapeTargets[i].endPoint = currentPointByStartShape[startShape];
		}

		for (var i=0;i<endShapeTargets.length;i++) {
			//create geometries
			var points = new Float32Array((Math.max(endShapeTargets[i].numPoints-1,2)+1)*3);
			var rs = []
			for (var j=0; j<endShapeTargets[i].numPoints; j++) {
				// points.push([0.0, 0.0]);
				rs.push(Math.random());
			}
			// var line = new THREE.Mesh(ThreeLine(points, {distances: true, closed:false}), this.line.material);
			// line.visible = false;
			// line.scale.set(1024,1024,1024);
			// line.position.y -= 256;
			// this.scene.add(line);

			// var  geometry = new LineGeometry();
			// geometry.setPositions( points );
			
			// var line = new Line2( geometry, this.lineMaterial.clone());
			// line.computeLineDistances();
			// line.visible = false;
			// line.scale.set(1024,1024,1024);
			// line.position.y -= 256;
			// this.scene.add( line );
			// this.line = line;

			// this.allLines.push(line);
			// lines.push(line);
			linePoints.push(points);
			randomSpeed.push(rs);
		}

		return {
			// startPoints: startPoints,
			// endPoints: endPoints,
			// assignStart: flippedAssign,
			splinesStart: splinesStart,
			splinesEnd: splinesEnd,
			endShapeTargets: endShapeTargets,
			lines: lines,
			linePoints: linePoints,
			randomSpeed: randomSpeed,
			invertStartStop: invertStartStop,
			rng: makeNoise2D(Date.now())
			// splinePointsStart: splinePointsStart,
			// splinePointsEnd: splinePointsEnd
		}

	}


	//main loop
	update(delta) {
		super.update(delta);

		var now = performance.now(),
			opt = this.options;

		//
		// Update playback
		//
		var timeDelta = (now - this.lastTime) / 1000;
		this.lastTime = now;
		this.currentTime += timeDelta;

		//reset line array
		var currentLine = 0;
		for (var i=0; i<this.allLines.length; i++) this.allLines[i].visible = false;



		AudioController.options.sequence.timeline.timeStart = opt.timeline.timeStart;
		AudioController.options.sequence.timeline.timeEnd = opt.timeline.timeEnd;
		AudioController.preloadEditor();
		AudioController.update();

		SequenceRenderer.update(null, 0.0, delta);
		AudioEffectController.preloadEditor();
		AudioEffectController.update(delta);


		//
		// mix reverbs
		//
		if (AudioEffectController.audioStarted) {
			// AudioEffectController.analyser.getByteFrequencyData( this.audioData );
		}
			

		if (AudioController.audioPlaying) {
			var tt = 0.0;
			for (var k=0; k<16; k++) {
				tt += AudioController.audioData[k];
			}
			// console.log(tt/16);
			this.tweenPc += tt/255.0 * delta * 0.01 * (opt.dragAdvance?1.0:0.1);
		}


		// this.reverbs[i]

		//
		// Morph on drag	
		//
		this.tweenPc += Math.pow(this.targetPosition.clone().sub(this.currentPosition).length(),1.25) * 0.1 + this.currentDragSpeed.length()*0.05;
		// this.tweenPc += timeDelta*(0.5+0.5*Utils.clamp(1.0-this.currentDragSpeed.length(), 0.0, 1.0))*1.25;
		// if (opt.interaction=='auto') this.tweenPc += 0.01*delta;
		// else this.tweenPc += Math.pow(this.targetPosition.clone().sub(this.currentPosition).length(),1.25) * 1.0 + this.currentDragSpeed.length()*0.2;
		if (this.tweenPc >= 1.0) {
			this.tweenPc -= 1.0;
			this.morphTarget++;
		}

			
		// this.currentPosition.lerp(this.targetPosition,delta*0.05);


		// var quadrantStart = this.svgIdByName[opt.svgA];
		// var quadrantEnd = this.svgIdByName[opt.svgB];
		// var startSplines = this.targetSplines[quadrantStart];
		// var endSplines = this.targetSplines[quadrantEnd];

		var quadrantStart = Math.floor(this.morphTarget) % this.targetSplines.length;
		var quadrantEnd = (quadrantStart+1) % this.targetSplines.length;
		var startSplines = this.targetSplines[this.randomOrder[quadrantStart]];
		var endSplines = this.targetSplines[this.randomOrder[quadrantEnd]];
		
		// if (this.isDragging) this.tweenPc = this.targetPosition.x*0.5+0.5;
		opt.tweenRotation = Math.floor(opt.tweenRotation);
		var transitionId = quadrantStart+'_'+quadrantEnd+"_"+opt.assignRandom+"_"+opt.tweenRotation;
		if (!this.transitionData[transitionId] || this.lastTransitionId !== transitionId) {
			this.transitionData[transitionId] = this.getTransitionData(startSplines, endSplines);
		}
		this.lastTransitionId = transitionId;
		var tData = this.transitionData[transitionId];


		var quadrantPc = this.tweenPc;

		if (opt.easing && opt.easing !== 'Linear') {
			quadrantPc = Easing[opt.easing].In(quadrantPc);
		}


		if (tData.invertStartStop) {
			quadrantPc = 1.0-quadrantPc;
		}

		//
		// Fit end shapes to start shapes
		//
		var cpoint = 0;
		var pointsByStartShape = [];
		var pointsByEndShape = [];
		for (var i=0; i<tData.endShapeTargets.length; i++) {
			var targetMeta = tData.endShapeTargets[i];

			// tData.lines[i].visible = true;
			var line = this.allLines[currentLine];
			currentLine++;
			line.visible = true;

			var startSpline = tData.splinesStart[targetMeta.target];
			var endSpline = tData.splinesEnd[i];
			var endPoints = endSpline.getPoints(Math.max(targetMeta.numPoints-1,2));

			if (!pointsByStartShape[targetMeta.target]) {
				pointsByStartShape[targetMeta.target] = startSpline.getSpacedPoints((LINE_RESOLUTION-1));
			}


			if (opt.tweenNoiseSmooth) {
				opt.tweenNoise = false;

				for (var j=0; j<endPoints.length; j++) {
					// console.log(tData.rng(i,j*0.01));
					endPoints[j].lerp(pointsByStartShape[targetMeta.target][Math.min(targetMeta.startPoint+j,(LINE_RESOLUTION-1))], Math.pow(quadrantPc, 1.5 + 1.0 * tData.rng(i,j*0.025) )); //  ) );
					tData.linePoints[i][j*3] = endPoints[j].x;
					tData.linePoints[i][j*3+1] = endPoints[j].y;
				}
			
			} else if (opt.tweenNoise) {
				for (var j=0; j<endPoints.length; j++) {
					var nj = j;
					if (opt.tweenRotation) nj = (j + opt.tweenRotation*200)%endPoints.length;
					endPoints[nj].lerp(pointsByStartShape[targetMeta.target][Math.min(targetMeta.startPoint+j,(LINE_RESOLUTION-1))], Math.pow(quadrantPc, 0.5+tData.randomSpeed[i][j]));
					tData.linePoints[i][j*3] = endPoints[nj].x;
					tData.linePoints[i][j*3+1] = endPoints[nj].y;
				}
			} else {
				for (var j=0; j<endPoints.length; j++) {
					endPoints[j].lerp(pointsByStartShape[targetMeta.target][Math.min(targetMeta.startPoint+j,(LINE_RESOLUTION-1))], quadrantPc);
					tData.linePoints[i][j*3] = endPoints[j].x;
					tData.linePoints[i][j*3+1] = endPoints[j].y;
				}
			}





			for (var j=0; j<endPoints.length; j++) {
				endPoints[j].lerp(pointsByStartShape[targetMeta.target][Math.min(targetMeta.startPoint+j,(LINE_RESOLUTION-1))], quadrantPc);
				tData.linePoints[i][j*3] = endPoints[j].x;
				tData.linePoints[i][j*3+1] = endPoints[j].y;
			}
				

			if (opt.waveform||true) {
				//wave deform
				var angles = new Float32Array(endPoints.length);
				var vx = new Float32Array(endPoints.length);
				var vy = new Float32Array(endPoints.length);
				for (var j=0; j<endPoints.length; j++) {
					 angles[j] = Math.atan2(endPoints[j].y-0.2, endPoints[j].x);
					 vx[j] = Math.cos(angles[j]);
					 vy[j] = Math.sin(angles[j]);
				}
				var waveTime = [];
				if (AudioController.audioPlaying) {
					for (var k=3; k<16; k++) {
						var waveTime = (Math.sin(performance.now() / (100+k*10) )) * 0.1 * (AudioController.audioData[k]/255);
						for (var j=0; j<endPoints.length; j++) {
							var angleDelta = Math.sin(angles[j]*(12.5*k+20))*0.5+0.5;
							tData.linePoints[i][j*3] += vx[j] * waveTime * angleDelta ;// * (Math.cos(angle*2.5)*0.5+0.5);
							tData.linePoints[i][j*3+1] += vy[j] * waveTime * angleDelta;// * (Math.cos(angle*2.5)*0.5+0.5);

						}
					}
				}
			}
			
						




			line.geometry.instanceCount = endPoints.length-1; //(tData.linePoints[i].length-1)/2;
			// line.geometry.setAttribute( 'instanceStart', new THREE.InterleavedBufferAttribute( line.geometry.attributes.instanceStart.data, 3, 6*10 ) ); // xyz
			// line.geometry.setAttribute( 'instanceEnd', new THREE.InterleavedBufferAttribute( line.geometry.attributes.instanceStart.data, 3, 6*10+3 ) ); // xyz

			line.geometry.setPositions(tData.linePoints[i]);
			// console.log(i, tData.linePoints[i].length, line.geometry.attributes.position.length);
		}


		this.currentDragSpeed.lerp(new THREE.Vector3(0,0,0), delta*0.07);
		this.currentPosition.copy(this.targetPosition);

		//get top points for eyes
		// var topMostPoint = 0.1;
		// var topMostX = 0.0;
		// var topWeight = 1.0;
		// for (var i=0; i<LINE_RESOLUTION; i++) {
		// 	if (this.linePoints[i][1] <= topMostPoint) {
		// 		topMostPoint = this.linePoints[i][1];
		// 		topMostX = this.linePoints[i][0];
		// 	}
		// }


		// for (var i=0; i<LINE_RESOLUTION; i++) {
		// 	this.linePositions[i*3] = this.linePoints[i][0];
		// 	this.linePositions[i*3+1] = this.linePoints[i][1];
		// }
		// this.lineGeometry.setPositions(this.linePositions);

		// topMostPoint /= topWeight;
		// topMostX /= topWeight;
		// this.line.geometry.update(this.linePoints);
		// this.line.material.uniforms.time.value = performance.now()/1000;
		// this.line.material.uniforms.dispTime.value += timeDelta * opt.dispNoiseSpeed;
		// this.line.material.uniforms.thicknessTime.value += timeDelta * opt.strokeNoiseSpeed;
		// this.line.material.uniforms.dispNoiseDepth.value = opt.dispNoiseDepth;
		// this.line.material.uniforms.dispNoiseScale.value = opt.dispNoiseScale;
		// this.line.material.uniforms.thicknessNoiseDepth.value = opt.strokeNoiseDepth;
		// this.line.material.uniforms.thicknessNoiseScale.value = opt.strokeNoiseScale;


		// Utils.autoReloadShaderManual(this.line.material, 'shaders/line/v0.vert', 'shaders/line/v0.frag');

		// console.log(topMostPoint, topMostX);

		//
		// Eyes : cligne + move
		//
		if (performance.now() > this.nextCligne) {
			this.nextCligne = performance.now()+Math.random()*4000+2000;
			if (Math.random()<0.2) this.nextCligne = performance.now()+500;
			this.cligneStart = now;

		}
		if (now-this.cligneStart < 100) {
			this.eyesGroups[0].visible = false;
			this.eyesGroups[2].visible = true;
		} else {
			this.eyesGroups[0].visible = true;
			this.eyesGroups[2].visible = false;
		}

		// if (opt.eyesFollow) {
		// 	this.eyes.position.lerp(new THREE.Vector3(100 * topMostX, 700 * topMostPoint-256,0), 0.2*delta);
		// } else {
			this.eyes.position.lerp(new THREE.Vector3(0,-256,0), 0.2*delta);
		// }
		// for (var i = 0; i<this.eyeMaterials.length; i++) {
		// 	this.eyeMaterials[i].opacity = 1.0
		// }

		// for (var i=0; i<this.eyeLines.length; i++) {
		// 	var line = this.eyeLines[i];

		// 	line.material.uniforms.dispTime.value += timeDelta * 5.0;
		// 	line.material.uniforms.thicknessTime.value += timeDelta * 5.0;

		// 	line.material.uniforms.dispNoiseDepth.value = 0.02;
		// 	line.material.uniforms.dispNoiseScale.value = 0.5;
		// 	line.material.uniforms.thicknessNoiseDepth.value = 0.02;
		// 	line.material.uniforms.thicknessNoiseScale.value = 0.5;
		// }

		SequenceRenderer.updateAfter();
		DreamUI.triggerUpdates();
		this.pressedThisFrame = false;
	}

	render() {
		super.render();

		renderer.setRenderTarget(this.fbo.texture);
		renderer.setRenderTarget(null);
		renderer.setClearColor(0xffffff,1);
		renderer.clear();

		renderer.render(this.scene, this.camera);
	}

	getTexture() {
		return this.fbo.texture.texture;
	}

	

	//cleanup everything
	dispose() {
		super.dispose();
		if (this.disposed) return;
		this.disposed = true;
	}
};

export default CleanTween;	