import $ from 'jquery';
import * as THREE from 'three';
import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import Fbo from '../utils/Fbo.js';

import Loader from "../loading/Loader.js";
import LoaderAudio from "../loading/LoaderAudio.js";
import AudioDisposeController from "../controllers/AudioDisposeController.js";
import InteractionController from "../controllers/InteractionController.js";
import Scene from './Scene.js';

import Sequence from '../objects/Sequence.js';
import SVGFrame from '../objects/SVGFrame.js';
import SVGMorph from '../objects/SVGMorph.js';
import PointState from '../objects/PointState.js';
import { makeNoise2D } from "open-simplex-noise";
import SequenceOptions from "../objects/SequenceOptions.js";
import DreamUI from "../DreamUI.js";


var presetFolder = 'data/presets/sequences/';
var audioFolder = "audio/timeline/";

var AUDIO_DIVISION_DURATION = 60.0;
var AUDIO_DIVISION_OVERLAP = 0.5;

var MAX_LINES = 64;
// SETTINGS.LINE_RESOLUTION = 512;
var audioContext = window.audioContext = window.audioContext||new AudioContext();

class OignonTestScene extends Scene {

	constructor() {
		super()
		this.disposed = false;
	
		//
		// Create sequence
		//
		// this.sequence = new Sequence(0, this.options.sequence);
		this.shouldReloadFrames = true;
		this.shouldReloadFramesTransition = true;
	}


	//preload json data
	prePreloadData(batchName) {
		// Loader.addShader(batchName, 'shaders/brushes/source.frag');

		SequenceRenderer.preloadData(batchName);


	}

	//preload json data
	preloadData(batchName) {
		super.preloadData(batchName);
		SequenceRenderer.preload(batchName);

	}

	//preload all assets
	preload(batchName) {
		super.preload(batchName);

	}



	//setup interaction and events
	start() {

		super.start();


		SequenceRenderer.setup();

		this.fbo = new Fbo(renderer.domElement.width, renderer.domElement.height, {
			format: THREE.RGBAFormat,
			minFilter: THREE.LinearFilter,
			magFilter: THREE.LinearFilter,
			generateMipmaps: false,
			wrap: THREE.ClampToEdgeWrapping,
			type: THREE.UnsignedByteType,
			premultiplyAlpha: false,
			depthBuffer: false,
			stencilBuffer: false,
			pingPongEnabled: false
		});


		this.packedFbo = new Fbo(renderer.domElement.width, renderer.domElement.height, {
			format: THREE.RGBAFormat,
			minFilter: THREE.LinearFilter,
			magFilter: THREE.LinearFilter,
			generateMipmaps: false,
			wrap: THREE.ClampToEdgeWrapping,
			type: THREE.UnsignedByteType,
			premultiplyAlpha: false,
			depthBuffer: false,
			stencilBuffer: false,
			pingPongEnabled: false
		});


	}


	//main loop
	update(delta) {
		super.update(delta);
		PageLoader.update(0);

		var now = performance.now(),
			opt = this.options;


		SequenceRenderer.pressedThisFrame = false;
	}


	render() {
		super.render();

		renderer.setRenderTarget(null);
		renderer.setClearColor(0xffffff,1);
		renderer.clear();
		Utils.renderTexture(this.packedFbo.texture.texture);

	}

	getTexture() {
		return this.fbo.texture.texture;
	}

	//cleanup everything
	dispose() {
		super.dispose();
		if (this.disposed) return;
		this.disposed = true;
	}
};

export default OignonTestScene;	
