import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';

import LoaderXHR from './LoaderXHR.js';
import SVGFrame from '../objects/SVGFrame.js';
import SVGSequence from '../objects/SVGSequence.js';

import LoaderSVG from './LoaderSVG.js';
import ABDisposeController from '../controllers/ABDisposeController.js';

window.allSvgPoints = window.allSvgPoints||{};

function LoaderSVGSequence(url, filename) {
	var self = this,
		completeCallback,
		errorCallback;
	this.data = new SVGSequence();
	this.data.loader = this;
	this.url = url;
	this.filename = filename;
	this.loaded = false;
	this.loading = false;
	this.progress = 0.0;
	this.weight = 1;
	this.loadType = 'svg';
	this.xhr = [];
	this.multipleBatches = false;
	this.loadedBytes = 0;
	this.decodeDirectly = true; //decodeDirectly || false;
	this.binaryFormat = false;
	this.data.loaded = false;

	this.doneCallbacks = [];
	window.allLoadedFiles.push(url);

	this.numXHRDone = 0;
	this.xhrBundle = null;


	//loaded the svg as a bundled binary file
	function xhrDoneBundle(e) {
		if (!self.xhrBundle) return;
		if (!self.loading) return;


		var offsets = AppStatus.svgBinaryMeta["svg/"+url].offsets;
		var meta = AppStatus.svgSequenceMeta[url];
  		if (!meta) console.warn("No svg meta for url:",url);
 		var ub = new Float32Array(self.xhrBundle.data.value);

 		self.offsets = offsets;
 		self.meta = meta;
 		self.ub = ub;
 		self.currentFrameParse = 0;

 		for (var i=0; i<meta.numFrames; i+=4) {
  			setTimeout(parseAdvance,16*i);
  			// parseAdvance();
		}

  // 		for (var i=0; i<meta.numFrames; i+=4) {
  // 			var block = ub.subarray(offsets[i]/4, offsets[i+1]/4)
  // 			self.data.frames[i].fromBinaryFormat(AppStatus.svgBinaryMeta["svg/"+meta.frames[i]], block);
  // 			self.data.frames[i].loaded = true;
		// }

		// //cleanup
		// self.xhrBundle.dispose();
		// self.xhrBundle = null;
		// self.data.isShared = self.isShared;	
		// self.progress = 1.0;
		// self.loaded = true;
		// self.loading = false;
		// self.data.loaded = true;
		// self.data.loadComplete();
		// completeCallback(self);
		// for (var i=0; i<self.doneCallbacks.length; i++) {
		// 	self.doneCallbacks(self);
		// }
		// self.doneCallbacks = [];
	}

	function parseAdvance() {
		if (self.loaded || !self.loading) return;
		var parseMax = Math.min(self.currentFrameParse+4,self.meta.numFrames);
		for (var i=self.currentFrameParse; i<parseMax; i++) {

			if (!self.loaded) {
				var block = self.ub.subarray(self.offsets[i]/4, self.offsets[i+1]/4)
	  			self.data.frames[i].fromBinaryFormat(AppStatus.svgBinaryMeta["svg/"+self.meta.frames[i]], block);
	  			self.data.frames[i].loaded = true;
				
	  			self.currentFrameParse++;
				if (self.currentFrameParse >= self.meta.numFrames) {
		  			//cleanup
					self.xhrBundle.dispose();
					self.xhrBundle = null;
					self.data.isShared = self.isShared;	
					self.progress = 1.0;
					self.loaded = true;
					self.loading = false;
					self.data.loaded = true;

					self.offsets = null;
			 		self.meta = null;
			 		self.ub = null;
			 		self.currentFrameParse = 0;

					self.data.loadComplete();
					completeCallback(self);
					for (var i=0; i<self.doneCallbacks.length; i++) {
						self.doneCallbacks(self);
					}
					self.doneCallbacks = [];
				}
			}

		}
	}



	//loaded the svg as multiple files
	function xhrDone(e) {
		if (!self.xhr[e.arrayId]) return;
		if (!self.loading) return;

		// console.log("Frame",e.arrayId);
		if (!self.xhr[e.arrayId].binaryFormat) {
			self.data.frames[e.arrayId].parse(self.xhr[e.arrayId].data.value);
		} else {
			self.data.frames[e.arrayId].fromBinaryFormat(self.xhr[e.arrayId].binaryMeta, self.xhr[e.arrayId].data.value);
			// self.data.fromBinaryFormat(AppStatus.svgBinaryMeta[self.filename], self.xhr.data.value);
		}

		self.data.frames[e.arrayId].loaded = true;
		self.xhr[e.arrayId].dispose();
		self.xhr[e.arrayId] = null;


		self.numXHRDone++;
		if (self.numXHRDone >= self.xhr.length) {
				// self.data.parse(self.xhr.data.value);
			// for (var i = 0; i < self.xhr.length; i++) {
			// 	self.data.frames[i] = new SVGFrame();
			// 	self.data.frames[i].parse(self.xhr[i].data.value);
			// 	self.data.frames[i].loaded = true;
			// 	self.xhr[i].dispose();
			// }
			// self.xhr[i] = [];
			self.xhr = [];

			self.data.isShared = self.isShared;
			
			self.progress = 1.0;

			self.loaded = true;
			self.loading = false;
			self.data.loaded = true;
			self.data.loadComplete();
			completeCallback(self);
			for (var i=0; i<self.doneCallbacks.length; i++) {
				self.doneCallbacks(self);
			}
			self.doneCallbacks = [];
		}
	}
	function xhrError() {
		self.data.loaded = true;
		for (var i=0; i<self.xhr.length; i++) self.xhr[i].dispose();
		self.xhr = [];
		if (self.xhrBundle) self.xhrBundle.dispose();
		self.xhrBundle = null;
		self.progress = 1.0;
		self.loaded = true;
		self.loading = false;
		console.log('error',url);
		for (var i=0; i<self.doneCallbacks.length; i++) {
			self.doneCallbacks(self);
		}
		self.doneCallbacks = [];
		errorCallback(self);
	}

	this.start = function(_completeCallback, _errorCallback) {
		if (self.loading || self.loaded) console.error('Already loading: ',url);
		self.loading = true;
		completeCallback = _completeCallback;
		errorCallback = _errorCallback;
  		

		self.numXHRDone = 0;
  		var meta = AppStatus.svgSequenceMeta[url];
  		if (!meta) console.warn("No svg meta for url:",url);

  		//load bundle
  		if (AppStatus.svgBinaryMeta["svg/"+url] && !SETTINGS.DISABLE_SVGC) {
			self.binaryFormat = true;
  			for (var i=0; i<meta.numFrames; i++) {
				self.data.frames[i] = new SVGFrame();
			}
			var svgcUrl = "svg/"+url;
			svgcUrl = svgcUrl.replace(/\.svg/gi, '').replace(/\/svg\//gi, '/svgc/').replace(/svg\//gi, 'svgc/')+'.'+AppStatus.svgBinaryMeta["svg/"+url].checksum+".svgc"
			if (SETTINGS.IS_LOCAL && AppStatus.svgBinaryMeta["svg/"+url]) svgcUrl += "?checksum="+AppStatus.svgBinaryMeta["svg/"+url].checksum;
			// else console.log("no checksum", "svg/"+url);

			self.xhrBundle = new LoaderXHR(svgcUrl, 'arraybuffer');
			self.xhrBundle.binaryFormat = true;
			self.xhrBundle.binaryMeta = AppStatus.svgBinaryMeta["svg/"+url];
			self.xhrBundle.start(xhrDoneBundle,xhrError);

		//load as multiple files
  		} else {
  			
  			console.log("load as multiple files", meta.numFrames);

  			for (var i=0; i<meta.numFrames; i++) {

				self.data.frames[i] = new SVGFrame();
				if (/seq_eyes/gi.test(url)) self.data.frames[i].eyesMode = true;

	  			if (AppStatus.svgBinaryMeta["svg/"+meta.frames[i]] && !SETTINGS.DISABLE_SVGC) {
					self.binaryFormat = true;
					var svgcUrl = "svg/"+meta.frames[i];

					svgcUrl = svgcUrl.replace(/\.svg/gi, '.'+AppStatus.svgBinaryMeta["svg/"+meta.frames[i]].checksum+'.svgc').replace(/\/svg\//gi, '/svgc/').replace(/svg\//gi, 'svgc/')
					if (SETTINGS.IS_LOCAL) svgcUrl += "?checksum="+AppStatus.svgBinaryMeta["svg/"+meta.frames[i]].checksum;
					self.xhr[i] = new LoaderXHR(svgcUrl, 'arraybuffer');
					self.xhr[i].binaryFormat = true;
					self.xhr[i].binaryMeta = AppStatus.svgBinaryMeta["svg/"+meta.frames[i]];
					// console.log(self.xhr[i].binaryMeta);
				} else {
	  				self.xhr[i] = new LoaderXHR("svg/"+meta.frames[i], 'text');
				}
				self.xhr[i].arrayId = i;
	  			self.loadFrameDelayed(self.xhr[i], i);
	  		}

  		}
	};

	this.loadFrameDelayed = function(xhr, i) {
		window.setTimeout(function() {
			xhr.start(xhrDone,xhrError);
		},i*6);
	}


	this.getProgress = function() { 
		// if (self.xhr) return self.xhr.getProgress();
		return self.progress;
	};

	this.dispose = function() {
		for (var i=0; i<self.xhr.length; i++) self.xhr[i].dispose();
		if (self.xhrBundle) self.xhrBundle.dispose();
		if (self.ub && self.ub.value && self.ub.value.byteLength) ABDisposeController.dispose(self.data.value);
		self.xhr = [];
		self.xhrBundle = null;
		self.numXHRDone = 0;
		if (self.data) self.data.dispose();
		completeCallback = errorCallback = self.url = self.start = self.getProgress = self.dispose = self.data = null;
	};

	this.reset = function() {
		self.data.loaded = false;
		self.xhr = [];
		for (var i=0; i<self.xhr.length; i++) self.xhr[i].reset();
		if (self.xhrBundle) self.xhrBundle.reset();
		self.xhrBundle = null;
		self.data.value  = null;
		self.loading = self.loaded = false;
		self.data.loadError = self.loadError = false;
		if (self.data) self.data.dispose();
		if (self.ub && self.ub.value && self.ub.value.byteLength) ABDisposeController.dispose(self.data.value);
		self.progress = 0.0;
		self.numXHRDone = 0;
		self.offsets = null;
 		self.meta = null;
 		self.ub = null;
 		self.currentFrameParse = 0;
	}

	this.getWeight = function() {
		return this.weight;
	};
}
export default LoaderSVGSequence;