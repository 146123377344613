import $ from 'jquery';
import FastEvent from './FastEvent.js';
import Utils from './Utils.js';
	
/*

	<div class="ui-item">
        <div class="ui-color-code"></div>
        <div class="ui-label">input checkbox</div>
        <input class="ui-checkbox-input" type="checkbox" value="true"></input>
    </div>

*/
function Checkbox(UI) {
	if (!UI.enabled) return;

	//
	// Create the div hierarchy
	//
	var containerDiv = document.createElement('div'),
		colorCodeDiv = document.createElement('div'),
		labelDiv = document.createElement('div'),
		checkboxDiv = document.createElement('input');
	this.domElement = containerDiv;



	containerDiv.appendChild(colorCodeDiv);
	containerDiv.appendChild(labelDiv);
	containerDiv.appendChild(checkboxDiv);

	//
	// Set the classes and basic properties
	//
	$(containerDiv).addClass('ui-item');
	$(colorCodeDiv).addClass('ui-color-code');
	$(labelDiv).addClass('ui-label');
	$(checkboxDiv).addClass('ui-checkbox-input');

	checkboxDiv.type = 'checkbox';
	checkboxDiv.value = false;

	//
	// props
	//
	this.uuid = Math.floor(Math.random() * 100000000000).toFixed(0);
	this.value = false;
	this.propName = '';
	this.toWatch = {};
	this.changing = false;
	this.callback = null;
	this.path = [];

	//object.observe change
	this.updateValue = function() {
		//prevent double changes / infinite loops
		if (this.changing)
			return;

		this.changing = true;
		var lv = this.value;
		this.value = this.toWatch[this.propName];
		if (lv !== this.value) {this.shouldUpdateUI = true; this.updateUI();}
		if (this.props && this.props.callback && lv !== this.value) this.props.callback.apply(this, [this.value]);

		this.changing = false;
	}.bind(this);


	//
	// Update UI 
	//
	this.canUpdateUI = false;
	this.shouldUpdateUI = false;
	this.updateUI = function() {
	
		if (!this.canUpdateUI || !this.shouldUpdateUI) return;
			this.shouldUpdateUI = false;
			this.changing = true;
		checkboxDiv.checked = this.value;
		this.changing = false;

	}.bind(this);



	//
	// init the properties
	//
	this.init = function(toWatch, propName, props, path) {
		

		this.propName = propName;
		this.toWatch = toWatch;
		this.path = path;
		this.props = props;
		this.updateValue();
		checkboxDiv.checked = this.value;

		$(labelDiv).text(propName);
		$(colorCodeDiv).css('background-color', props.colorCode || '#'+Math.floor(Math.random()*0xffffff).toString(16));

		UI.watchProperty(this.toWatch, this.propName, this.updateValue);
		// this.callback = props ? props.callback : null;

		//
		// Midi link
		//
		this.props = props;
		// UI.midiEnable(this.props, props.midiChannel, labelDiv);
		// FastEvent.on(this.props, 'midiMessage', function(e,channel, note, velocity) {
		// 	FastEvent.trigger(this.toWatch, 'ui-update');
		// 	this.toWatch[this.propName] = velocity > 0;
		// 	SoundEngine.triggerPropertyUI(this.toWatch, this.propName);
		// }.bind(this));

	}.bind(this);


	//
	// Input change
	//
	$(checkboxDiv).on('change',function(e) {
		if (this.changing) return;
		this.toWatch[this.propName] = checkboxDiv.checked;
		UI.triggerProperty(this.toWatch, this.propName);
	}.bind(this));

	$(labelDiv).on('click', function(e) {
		if (e.altKey) {
			Utils.copyToClipboard(labelDiv, this.path.concat(this.propName).join('.'));
		}
	}.bind(this));
	
	//
	// Clean up
	//
	this.dispose = function() {

		// UI.midiDisable(this.props, this.props.midiChannel, labelDiv);
		// FastEvent.off(this.props, 'midiMessage');

		UI.unwatchProperty(this.toWatch, this.propName, this.updateValue);
		this.callback = null;
		$(this).off(); FastEvent.off(this);
		$(containerDiv).off();
		$(colorCodeDiv).off();
		$(labelDiv).off();
		$(checkboxDiv).off();

		$(containerDiv).remove();
		$(colorCodeDiv).remove();
		$(labelDiv).remove();
		$(checkboxDiv).remove();

		this.domElement = null;
		containerDiv = null;
		colorCodeDiv = null;
		labelDiv = null;
		checkboxDiv = null;
	};


};

export default Checkbox;