import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';

import LoaderXHR from './LoaderXHR.js';
import SVGFrame from '../objects/SVGFrame.js';
import SVGSequence from '../objects/SVGSequence.js';

import LoaderAudio from './LoaderAudio.js';
import ABDisposeController from '../controllers/ABDisposeController.js';


class LoaderMultiAudio {

	constructor(url, decodeDirectly) {
		this.data = {value: []};

		url = url.replace(/\\/gi,'/');

		this.url = url;
		this.loaded = false;
		this.loading = false;
		this.progress = 0.0;
		this.weight = 1;
		this.loadType = 'audio';
		this.xhr = [];
		this.multipleBatches = false;
		this.loadedBytes = 0;
		this.decodeDirectly = true; //decodeDirectly || false;

		this.data.loaded = false;

		this.doneCallbacks = [];
		window.allLoadedFiles.push(this.url);

		this.numXHRDone = 0;
		this.gotFileList = false;
		this.fileList = [];
	}

	//-----------------
	//
	// Begin loading
	//
	//------------------
	start(_completeCallback, _errorCallback) {
		if (this.loading || this.loaded) console.error('Already loading: ',this.url);
		this.loading = true;
		this.completeCallback = _completeCallback;
		this.errorCallback = _errorCallback;
  		
		if (this.gotFileList) {
			this.startLoadingAudio();
		} else {
			this.getFileList();
		}
	}

	getFileList() {
		var flist = new LoaderXHR(
			SETTINGS.IS_LOCAL ? '/filelist?dir='+this.url : 'data/autofillcache/'+this.url.replace(/\//gi,'_').replace(/\ /gi,'-')+'.json'
		, 'json');
		flist.start(() => {
			this.fileList = flist.data.value.files;
			this.gotFileList = true;
			flist.dispose();
			flist = null;
			if (!this.loading) return; //stopped loading in the meantime
			this.startLoadingAudio();
		},this.xhrError.bind(this));
	

	};



	//load audio slowly
	startLoadingAudio() {
		this.numXHRDone = 0;
  		for (var i=0; i<this.fileList.length; i++) {
  			this.xhr[i] = new LoaderAudio(this.url+"/"+this.fileList[i], this.decodeDirectly);
			this.xhr[i].arrayId = i;
			this.data.value[i] = this.xhr[i].data;
  			this.loadFrameDelayed(this.xhr[i], i);
  		}
	};
	loadFrameDelayed(xhr, i) {
		window.setTimeout(() => {
			xhr.start(this.xhrDone.bind(this),this.xhrError.bind(this));
		},i*5);
	};



	//callbacks
	xhrDone(e) {
		// this.data.value[e.arrayId] = this.xhr[e.arrayId].data;
		// this.xhr[e.arrayId].dispose();
		if (!this.loading) return;
		this.numXHRDone++;
		if (this.numXHRDone >= this.xhr.length) {
			this.xhr = [];
			this.data.isShared = this.isShared;
			this.progress = 1.0;
			this.loaded = true;
			this.loading = false;
			this.data.loaded = true;
			this.completeCallback(this);
			for (var i=0; i<this.doneCallbacks.length; i++) {
				this.doneCallbacks(this);
			}
			this.doneCallbacks = [];
		}
	}
	xhrError(e) {
		this.data.loaded = true;
		for (var i=0; i<this.xhr.length; i++) this.xhr[i].dispose();
		this.xhr = [];
		this.progress = 1.0;
		this.loaded = true;
		this.loading = false;
		console.log('error',this.url);
		for (var i=0; i<this.doneCallbacks.length; i++) {
			this.doneCallbacks[this];
		}
		this.doneCallbacks = [];
		this.errorCallback(this);
	}


	//-----------
	//
	// Loading params
	//
	//-----------
	getProgress() { 
		// if (this.xhr) return this.xhr.getProgress();
		return this.progress;
	};

	dispose() {
		for (var i=0; i<this.xhr.length; i++) this.xhr[i].dispose();
		this.xhr = [];
		this.numXHRDone = 0;
		this.completeCallback = this.errorCallback = this.url = this.start = this.getProgress = this.dispose = this.data = null;
	};

	reset() {
		console.log("reset multi audio");
		this.data.loaded = false;
		// if (this.data && this.data.value && this.data.value.byteLength) ABDisposeController.dispose(this.data.value);
		for (var i = 0; i <this.xhr.length; i++) {
			if (this.xhr[i]) this.xhr[i].reset();
		}
		this.data = {value: []};
		this.xhr = [];
		this.loading = this.loaded = false;
		this.data.loadError = this.loadError = false;
		this.progress = 0.0;
		this.numXHRDone = 0;
	}

	getWeight() {
		return this.weight;
	};

}
export default LoaderMultiAudio;