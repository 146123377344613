import $ from 'jquery';
import * as THREE from 'three';

// import SETTINGS from '../Settings.js';
// import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';

import SVGParse from 'parse-svg-path'
import SVGAbs from 'abs-svg-path'
import SVGNormalize from 'normalize-svg-path'

import SimpleSpline from './SimpleSpline.js';

// const SVGParser = new DOMParser();
var parser = typeof DOMParser !== 'undefined'?(new DOMParser()) : null;
// xmlDoc = parser.parseFromString(text,"text/xml");

var layersEquivalent = {
	"body": "back",
	"main": "back",
	"motion": "front",
	"text": "over"
};
var LAYERS_IDS = {
	"back": 1,
	"front": 2,
	"eyes": 3,
	"over": 4
};
var LAYERS_BY_ID = {
	1: "back",
	2: "front",
	3: "eyes",
	4: "over"
}



//----------------
//
// SVG Frame
// Load all splines from an svg in an easily readable/morphable format
//
//----------------
class SVGFrame {

	constructor() {
		this.loaded = false;
		this.uuid = "frame"+Utils.generateID();
		this.splines = [];
		this.totalLength = 0;
		this.cachedPoints = null;
		this.cachedSource = null;
		this.layerMode = false;
		this.layers = {};
		this.eyesMode = false;
		this.isEmpty = false;
		// this.hasLayerTransition = true;
	}

	setParser(p, _$) {
		parser = p;
		$ = _$;
	}

	parse(rawPath, forceRes) {
		// console.time("parse");
		// for (var parsetime=0; parsetime<100; parsetime++) {
		// Utils.time("parse svg", 30);
		// console.log(this);
		var prev = 0;
		// if (!forceRes) prev = this.parse(rawPath, true).totalPoints;


		this.totalPoints = 0;
		this.totalLength = 0;
		var allSplines = [];

		this.layerMode = false;
		this.layers = {};

		var numLayers = 0;
		var currentLayerNum = -1;
		var currentGroupId = 'svg';

		//parse
		// var svgData = SVGParser.parse(rawPath);
		var svgData = parser.parseFromString(rawPath,"text/xml");
		var rawPaths = $(svgData).find('path');

		svgData = $(svgData).find('svg')[0]||svgData;
			
		var viewbox = [0,0,1080,1920];
		if ($(svgData).attr('viewbox') || $(svgData).attr('viewBox')) {
			viewbox = ($(svgData).attr('viewbox')||$(svgData).attr('viewBox')).split(' ');
			for (var i = 0; i < viewbox.length; i++) {
				viewbox[i] = parseInt(viewbox[i],0);
			}
		}

		var ratio = viewbox[2]/viewbox[3];
		var SVG_WIDTH = viewbox[2]; //svgData.xml.width.baseVal.value; //parseInt( $(svgData.xml).svg.getAttribute('width').replace('px',''), 10);
		var SVG_HEIGHT = viewbox[3]; //parseInt( $(svgData.xml).svg.getAttribute('height').replace('px',''), 10);
		//use viewbox instead?

		for (var i=0; i<rawPaths.length; i++) {

			var transform = null;

			// var group = 
			// 	$(rawPaths[i]).group();
			var parent = $(rawPaths[i]).parent();

			currentGroupId = $(rawPaths[i]).attr('id');

			while (parent && parent.is('g')) {
				if (parent.attr('transform')) {
					var tt = parent.attr('transform');
					if (/matrix/.test(tt)) {
						var ts = tt.split('matrix(').pop().replace(')','').split(',');
						if (ts.length >= 6) {
							var tMat = new THREE.Matrix3();
							tMat.elements[0] = parseFloat(ts[0], 10);
							tMat.elements[1] = parseFloat(ts[1], 10);

							tMat.elements[3] = parseFloat(ts[2], 10);
							tMat.elements[4] = parseFloat(ts[3], 10);

							tMat.elements[6] = parseFloat(ts[4], 10) / SVG_HEIGHT;
							tMat.elements[7] = parseFloat(ts[5], 10) / SVG_HEIGHT;

							if (transform) {
								transform.multiply(tMat);
							} else {
								transform = tMat;
							}
						}
					}
				}
				if (parent.attr('id')) {
					currentGroupId = parent.attr('id');
					// console.log(currentGroupId);
				}
				parent = parent.parent();
			}

			if (currentGroupId && layersEquivalent[currentGroupId]) currentGroupId = layersEquivalent[currentGroupId];
			if (currentGroupId === 'back' || currentGroupId === 'front' || currentGroupId === 'eyes'  || currentGroupId === 'over') {
				if (!this.layers[currentGroupId]) numLayers++;
				this.layerMode = true;
			} else {
				if (!this.layers[currentGroupId]) currentLayerNum++;
				currentGroupId = ['back', 'front', 'eyes', 'over'][currentLayerNum%4];
			}
			// console.log("Got layers:",currentGroupId);
			this.layers[currentGroupId] = this.layers[currentGroupId]||new SVGFrame();

			//
			// Get stroke and color here:
			//
			// color = rawPaths[i].getAttribute('stroke')
			// with = rawPaths[i].getAttribute('stroke-width')

			var simplifiedPath = (SVGNormalize(SVGAbs(SVGParse(rawPaths[i].getAttribute('d')))));
			var currentSubPath = [];
			var subPaths = [];
			for (var j = 0; j <simplifiedPath.length; j++) {
				if (simplifiedPath[j][0] == "M") {
					currentSubPath = [];
					subPaths.push(currentSubPath);
				}
				currentSubPath.push(simplifiedPath[j]);
				for (var k=1; k<simplifiedPath[j].length; k+=2) {
					simplifiedPath[j][k] = (simplifiedPath[j][k] - SVG_WIDTH/2) / SVG_HEIGHT;
					simplifiedPath[j][k+1] = (simplifiedPath[j][k+1] - SVG_HEIGHT/2) / SVG_HEIGHT;
					// simplifiedPath[j][k+1] += 1.0 - (ratio/(1080/1920));
				}
			}

			//
			// Create a spline for each subpaths
			//
			var curve = new THREE.CubicBezierCurve();
			for (var j=0; j<subPaths.length; j++) {

				var shouldFlip = false;
				var subPath = subPaths[j];
				if (subPath[0][1]  > subPath[subPath.length-1][1]) {
					shouldFlip = true;
				}

				//get points for each curves
				curve.v0.set(subPath[0][1], subPath[0][2]);
				if (transform) curve.v0.applyMatrix3(transform);
				var linePath = [];
				var points = [];
				var centroid = new THREE.Vector2(0,0), numWeight = 0;
				points.push(curve.v0.clone()); //[currentPos.x, currentPos.y]);


				for (var k=1; k<subPath.length; k++) {
					curve.v1.set(subPath[k][1], subPath[k][2]); //cc1
					curve.v2.set(subPath[k][3], subPath[k][4]); //cc2
					curve.v3.set(subPath[k][5], subPath[k][6]); //nextpos


					if (transform) {
						curve.v1.applyMatrix3(transform);
						curve.v2.applyMatrix3(transform);
						curve.v3.applyMatrix3(transform);
					}
				
					var dst = curve.getLength();
					var res = Math.floor(Math.pow(Utils.cmap(dst, 0.000,0.12,0.0,1),0.75)*5+6);
					// if (res>4) console.log(dst,res);
					// console.log(curve.getTangent());

					var subPoints = curve.getPoints(forceRes?6:res);
					subPoints.pop();
					points = points.concat(subPoints);
					curve.v0.copy(curve.v3); //nextPos;
				}
				points.push(curve.v0);


				//simplify
				var cachedLength = 0.0;

				for (var k=1; k<points.length; k++) {
					var dst = points[k].distanceTo(points[k-1]);

					if (dst < (forceRes?0.001:0.0011)) {
						if (points.length>4) {

							if (k>0 && k<points.length-2) {
								// points[k+1].x = ((points[k].x - points[k-1].x) / dst) * 0.0005;
								// points[k+1].y += ((points[k].y - points[k-1].y) / dst) * 0.0005;
								points[k+1] = points[k+1].lerp(points[k],0.25);
								// points[k-1] = points[k-1].lerp(points[k],0.1);
							}
							

							points.splice(k,1);
							k--;
						} else if (dst>0.00001) {
							//normalize & add
							points[k].x += ((points[k].x - points[k-1].x) / dst) * 0.0005;
							points[k].y += ((points[k].y - points[k-1].y) / dst) * 0.0005;
						}
						 else {
							points[k].x += 0.5 * 0.0005;
							points[k].y += 0.5 * 0.0005;
						}
					} else {
						cachedLength += dst;
						centroid.x += points[k].x;
						centroid.y += points[k].y;
						numWeight += 1;
					}
				}
				centroid.x /= numWeight;
				centroid.y /= numWeight;

				//create spline from points
				var oPoints = points;
				if (shouldFlip) oPoints = oPoints.reverse();
				var spline = new SimpleSpline();
				spline.setVectors(points);
				this.totalPoints += oPoints.length;


				// var points = spline.getSpacedPoints((SETTINGS.LINE_RESOLUTION-3));
				// points.unshift(oPoints[0])
				// points.push(oPoints[oPoints.length-1]);
				
				// spline = new THREE.SplineCurve(points);
				// spline.startPoint = oPoints[0];
				// spline.endPoint = oPoints[oPoints.length-1];
				// spline.centroid = centroid;

				var an = Utils.normalizeAngle(Math.atan2(centroid.y, centroid.x));
				spline.sortedAngle = Utils.distanceBetweenAngles(an, -Math.PI*0.5) * -Utils.directionBetweenAngles(an, -Math.PI*0.5);
				// spline.centroidAngle = 0;

				spline.cachedLength = cachedLength; //spline.cachedLength||spline.getLength();
				spline.layer = currentGroupId;
				allSplines.push(spline);


				if (this.layerMode) this.layers[currentGroupId].splines.push(spline);

				//cached length
				if (isNaN(spline.cachedLength)) spline.cachedLength = spline.getLength();
				if (spline.cachedLength <= 0) {
					// window.spline = spline;
					// console.warn("Nooo");
					spline.cachedLength = 0.005;
				}
				this.totalLength += spline.cachedLength;
				if (isNaN(this.totalLength)) {
					console.warn("NAN");
				}
			}


			//
			// Save properties
			//
			this.splines = allSplines;
		}
		// Utils.timeEnd("parse svg", 30);

		// console.log(this.totalPoints);
		// if (!forceRes && this.totalPoints > 500) console.log(this.totalPoints, prev);
		// return this;
		// if (numLayers<=1) {
		// 	// console.log("Only one layer");
		// 	this.layerMode = false;
		// }



		// var res = this.toBinaryFormat();
		// console.log(res.meta, res.buffer);
		// this.fromBinaryFormat({info:res.meta},res.buffer);

		// }
		// console.timeEnd("parse");
	}

	fromPoints(allPoints) {

		if (allPoints.length == 0) allPoints = [new Float32Array(12),new Float32Array(12),new Float32Array(12),new Float32Array(12)];

		this.totalLength = 0;
		var allSplines = [];

		for (var i = 0; i < allPoints.length; i++) {
			var oPoints = [];
			var centroid = new THREE.Vector2(0,0);
			var np = allPoints[i].length/3;

			var shouldFlip = false;
			if (allPoints[i][1]  > allPoints[i][(np-1)*3+1]) {
				shouldFlip = true;
			}


			var cachedLength = 0.0;
			for (var j = 0; j <np; j++) {
				var c = new THREE.Vector2(allPoints[i][j*3], allPoints[i][j*3+1]);
				centroid.x += c.x;
				centroid.y += c.y;

				if (j>0) {
					cachedLength += oPoints[oPoints.length-1].distanceTo(c);
				}
				oPoints.push(c)
			}
			centroid.x /= np;
			centroid.y /= np;

			//create spline from points
			if (shouldFlip) oPoints = oPoints.reverse();
			var spline = new SimpleSpline();
			spline.setVectors(oPoints);

			// console.log(oPoints);
			// window.spline = spline;

			// var points = spline.getSpacedPoints(SETTINGS.LINE_RESOLUTION-3);
			// points.unshift(oPoints[0])
			// points.push(oPoints[oPoints.length-1]);
			
			// spline = new THREE.SplineCurve(points);
			// spline.startPoint = oPoints[0];
			// spline.endPoint = oPoints[oPoints.length-1];
			// spline.centroid = centroid;

			var an = Utils.normalizeAngle(Math.atan2(centroid.y, centroid.x));
			spline.sortedAngle = Utils.distanceBetweenAngles(an, -Math.PI*0.5) * -Utils.directionBetweenAngles(an, -Math.PI*0.5);
			// spline.centroidAngle = an;

			spline.cachedLength = cachedLength||spline.cachedLength||spline.getLength();
			allSplines.push(spline);

			//cached length
			if (isNaN(spline.cachedLength)) spline.cachedLength = spline.getLength();
			if (spline.cachedLength <= 0) {
				// window.spline = spline;
				// console.warn("Nooo");
				spline.cachedLength = 0.005;
			}
			this.totalLength += spline.cachedLength;
			if (isNaN(this.totalLength)) {
				console.warn("NAN");
			}
		}

		//
		// Save properties
		//
		this.splines = allSplines;
		return this;
	}

	merge(frame, sourceLayer) {
		if (!frame) return;
		for (var i = 0; i < frame.splines.length; i++) {
			frame.splines[i].sourceLayer = frame.splines[i].sourceLayer||sourceLayer;
			this.splines.push(frame.splines[i]);
		}
		this.totalLength += frame.totalLength;
		if (isNaN(this.totalLength)) {
			console.warn("NAN");
		}
		// if (sourceLayer) this.hasLayerTransition = true;

		// console.log("merge to:",frame.splines[i].sourceLayer, sourceLayer);
		this.cachedPoints = null;
	}
	setSourceLayer(sourceLayer) {
		for (var i = 0; i < this.splines.length; i++) {
			this.splines[i].sourceLayer = this.splines[i].sourceLayer||sourceLayer;
		}
	}

	getPositions() {
		if (this.cachedPoints) return this.cachedPoints;
		this.cachedPoints = [];

		if (isNaN(this.totalLength)) {
			console.warn("NAN");
		}

		var mres = 1.0;
		var resolution = 256;
		if (this.totalLength) {
			resolution *= Utils.ccmap(this.totalLength, 10, 30, 1.0, 0.33);
			mres *= Utils.ccmap(this.totalLength, 10, 30, 1.0, 0.33);
		}
		resolution *= Utils.ccmap(this.splines.length, 500, 1500, 1.0, 0.33);
		mres *= Utils.ccmap(this.splines.length, 500, 1500, 1.0, 0.33);

		var minLine = 6;
		if (this.splines.length>2000) {
			minLine = 5;
		}

		// if (this.splines.length > 500) console.log("resolution",resolution, this.splines.length, this.totalLength);
		var totalp= 0;
		for (var i=0; i<this.splines.length; i++) {
			var spline = this.splines[i];
			var np = Math.floor(Utils.cmap(Math.pow(spline.cachedLength,0.75), 0.000, 2.5*mres, minLine, resolution));;

			totalp+=np
			// if (np >= 100) console.log("big line",spline.cachedLength, np);
			var points = spline.getPoints(np);
			this.cachedPoints.push(points);
		}
		// console.log("shown",totalp, this.splines.length, this.totalLength);
		// this.getNumPoints(this.splines);
		return this.cachedPoints;
	}

	getNumPoints(splines) {
		var numPoints = [];
		
		var mres = 1.0;
		var resolution = 256;
		if (this.totalLength) {
			resolution *= Utils.ccmap(this.totalLength, 10, 30, 1.0, 0.33);
			mres *= Utils.ccmap(this.totalLength, 10, 30, 1.0, 0.33);
		}
		resolution *= Utils.ccmap(splines.length, 500, 1500, 1.0, 0.33);
		mres *= Utils.ccmap(splines.length, 500, 1500, 1.0, 0.33);
		var minLine = 6;
		if (splines.length>2000) {
			minLine = 4;
		}
		for (var i=0; i<splines.length; i++) {
			var spline = splines[i];
			var np = Math.floor(Utils.cmap(Math.pow(spline.cachedLength,0.75), 0.000, 2.5*mres, minLine, resolution));;
			numPoints.push(np);
		}
		return numPoints;
	}


	// getSourceLayers() {
	// 	if (this.cachedPoints) return this.cachedPoints;
	// 	this.cachedPoints = [];

	// 	if (isNaN(this.totalLength)) {
	// 		console.warn("NAN");
	// 	}
	// 	var resolution = window.SETTINGS?window.SETTINGS.LINE_RESOLUTION:512;
	// 	if (this.totalLength) resolution *= Utils.ccmap(this.totalLength, 10, 30, 1.0, 0.33);
	// 	resolution *= Utils.ccmap(this.splines.length, 500, 1500, 1.0, 0.33);
	// 	for (var i=0; i<this.splines.length; i++) {
	// 		var spline = this.splines[i];
	// 		var np = Math.floor(Utils.cmap(spline.cachedLength, 0.005, 3.0, 8, resolution));;
	// 		var points = spline.getPoints(np);
	// 		// var fpoints = new Float32Array(points.length*3);
	// 		// for (var j = 0; j < points.length; j++) {
	// 		// 	fpoints[j*3] = points[j].x;
	// 		// 	fpoints[j*3+1] = points[j].y;
	// 		// 	fpoints[j*3+2] = 0.0;
	// 		// }
	// 		this.cachedPoints.push(points);
	// 		// if (points.length==0) window.spline = spline;
	// 	}
	// 	return this.cachedPoints;
	// }


	getFrame() {
		return this;
	}

	getEndFrame(){
		return this;
	}

	dispose() {
		for (var i=0; i<this.splines.length; i++) {
			this.splines[i].dispose();
		}
		this.splines = [];
		this.totalLength = 0;
		this.cachedPoints = null;
		this.layerMode = false;
		this.layers = {};
		this.layerMode = false;
		this.loaded = false;
		// this.hasLayerTransition = false;
	}

	createDate(seq, textOffset, yearInText) {

			// yearInText = true;

		var date = new Date();
		date.setFullYear(date.getFullYear() + 7);
		// date = new Date('09/25/2028');

		var mm = Utils.zeroPad((date.getMonth()+ 1).toString(), 2);
		var dd = Utils.zeroPad(date.getDate().toString(),2)
		var yy = (date.getFullYear()).toString();



		var dateString = SETTINGS.LANGUAGE=='fr' ? (dd+'.'+mm+'.'+yy) : (mm+'.'+dd+'.'+yy)
		if (yearInText) dateString = yy.toString();

		// date.toLocaleDateString(SETTINGS.LANGUAGE=='fr'?'fr-FR':'en-US', {day:'2-digit', year: 'numeric', month:'numeric'});
		dateString = dateString.replace(/\-/gi, "/");
		// console.log(dateString, SETTINGS.LANGUAGE=='fr'?'fr-FR':'en-US');

		// dateString.split("");
		// console.log(dateString[0]);
		var positions = [];

		var size = 0;

		// dateString.length*0.019;

		for (var i = 0; i < dateString.length; i++) {
			var char = dateString[i];
			if (char == '/' || char == '-' || char == '.') size += 0.009;
			else size += 0.02;
		}
		// console.log(size);
		var currentPos = -size * 0.5 - 0.005;

		for (var i = 0; i < dateString.length; i++) {
			var char = dateString[i];
			var frame = null
			var frameSize = 0;
			if (char == '/' || char == '-' || char == '.') {
				frame = seq.frames[11];
				frameSize = 0.009;
			} else {
				frame = seq.frames[parseInt(char, 10)];
				frameSize = 0.02
			}

			frame.cachedPoints = null;
			var fpos = frame.getPositions();
			var offset = currentPos + frameSize/2;
			currentPos += frameSize;


			var to = textOffset;
			if (yearInText) {
				if (SETTINGS.LANGUAGE=='fr') {
					offset -= 0.0875;
					to -= 0.1025;
				} else {
					offset -= 0.107;
					to -= 0.081;
				}
			} else if (SETTINGS.LANGUAGE == 'en') {
				to -= 0.0189;
				offset += 0.059;
			}

			 // + (i/(dateString.length)) * size;
			for (var j=0; j<fpos.length; j++) {
				for (var k=0; k<fpos[j].length; k+=3) {
					fpos[j][k] += offset;
					fpos[j][k+1] -= 0.0515 - to;
				}
			}

			positions = positions.concat(fpos);
			// this.merge(frame);
			// console.log(char, frame);
		}
		this.fromPoints(positions);

		return this;
	}


	//-------------
	//
	// SVG Converted Binary Format
	//
	//-------------
	toBinaryFormat() {
		var result = {};
		result.totalLength = this.totalLength;
		result.layerMode = this.layerMode;
		result.numSplines = this.splines.length;
		// result.bufferSize = this.totalPoints*2 + this.splines.length*7;
		// result.splines = [];

		var binary = new Float32Array(this.totalPoints*2 + this.splines.length*4);
		var currentOffset = 0;

		for (var i = 0; i < this.splines.length; i++) {

			var spline = this.splines[i];

			// result.splines.push(currentOffset);

			var splineMeta = new Float32Array([
				spline.points.length,
				// 0,
				// 0,
				spline.sortedAngle,
				// 0,
				spline.cachedLength,
				(this.layerMode)?LAYERS_IDS[this.splines[i].layer]:0
			]);
			binary.set(splineMeta, currentOffset);
			currentOffset += 4;


			// var start = currentOffset;
			// var end = currentOffset + this.splines[i].points.length;

			// console.log(this.totalPoints, binary.length, currentOffset, currentOffset+this.splines[i].points.length);
			// for (var j=0; j<this.splines[i].points.length; j++) {
			// 	if (isNaN(this.splines[i].points[j])) {
			// 		console.log("Error: NaN points");
			// 	}
			// }
			// if (this.splines[i].points.length < 6) {
			// 	console.log("Error too small");
			// }
			binary.set(this.splines[i].points, currentOffset);
			currentOffset += this.splines[i].points.length;

			// var splineMeta = {
			// 	start:start,
			// 	end:end,
			// 	centroid: {x:spline.centroid.x,y:spline.centroid.y},
			// 	sortedAngle: spline.sortedAngle,
			// 	centroidAngle: spline.centroidAngle,
			// 	cachedLength: spline.cachedLength
			// };
			// if (this.layerMode) splineMeta.layer = this.splines[i].layer;
			// result.splines.push(splineMeta);
		}
		// console.log("META:",result);
		return {meta: result, buffer: binary.buffer};
	}

	fromBinaryFormat(meta, buffer) {
		// Utils.time("parse", 30);
		if (!meta) {
			console.log("No meta");
		}
		this.totalLength = meta.info.totalLength;
		this.layerMode = meta.info.layerMode;
		this.cachedPoints = null;

		var positions = new Float32Array(buffer);

		// if (meta.info.bufferSize !== positions.length) {
		// 	console.warn("wTF");
		// }
		// console.log(meta.info, positions.length);

		this.splines = [];
		this.layers = {};

		var currentOffset = 0;
		for (var i = 0; i < meta.info.numSplines; i++) {
		// while (currentOffset < positions.length-7) {
			// var splineMeta = meta.splines[i];

			var splineMeta = positions.subarray(currentOffset, currentOffset+4);
			currentOffset+=4;

			// console.log(splineMeta);

			var end = currentOffset + Math.round(splineMeta[0]);
			var s = new SimpleSpline()
			s.setArray2(positions.subarray(currentOffset, end));
			currentOffset = end;
			// s.centroid.set(splineMeta.centroid.x,splineMeta.centroid.y);
			// s.sortedAngle = splineMeta.sortedAngle;
			// s.centroidAngle = splineMeta.centroidAngle;
			// s.cachedLength = splineMeta.cachedLength;
			// s.centroid.set(splineMeta[1], splineMeta[2]);
			s.sortedAngle = splineMeta[1];
			// if (isNaN(s.sortedAngle)) {s.sortedAngle = 0; console.warn("NaN angle: ",s, splineMeta)};
			// s.centroidAngle = splineMeta[4];
			s.cachedLength = splineMeta[2];

			// s.startPoint.set(s.points[0], s.points[1]);
			// s.endPoint.set(s.points[s.points.length-2], s.points[s.points.length-1]);

			if (this.layerMode && splineMeta[3]) {
				s.layer = LAYERS_BY_ID[Math.round(splineMeta[3])];
				this.layers[s.layer] = this.layers[s.layer]||new SVGFrame();
				this.layers[s.layer].splines.push(s);
			}
			this.splines.push(s);
		}

		// window.svgc = this;
		// console.log(meta);
		// Utils.timeEnd("parse", 30);
	}
}
SVGFrame.BINARY_FORMAT_VERSION = 16;
export default SVGFrame;
