export default {

	cmap: function(value, istart, istop, ostart, ostop) {
		return Math.max(Math.min(ostart + (ostop - ostart) * ((value - istart) / (istop - istart)),ostop),ostart);
	},

	clamp: function(value, ostart, ostop) {
		return Math.max(Math.min(value,ostop),ostart);
	},

	sign: function(value) {
		return value < 0 ? -1 : 1;
	},

	arrayMove: function(arr, source, target) {
		arr.splice(target, 0, arr.splice(source, 1)[0]);
	}
}