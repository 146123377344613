import $ from 'jquery';
import * as THREE from 'three';
import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import Fbo from '../utils/Fbo.js';

import Loader from "../loading/Loader.js";
import LoaderAudio from "../loading/LoaderAudio.js";
import AudioDisposeController from "../controllers/AudioDisposeController.js";
import AudioController from "../controllers/AudioController.js";
import SequenceRenderer from "../controllers/SequenceRenderer.js";
import InteractionController from "../controllers/InteractionController.js";
import CopresenceController from "../controllers/CopresenceController.js";

import Sequence from '../objects/Sequence.js';
import SVGFrame from '../objects/SVGFrame.js';
import SVGMorph from '../objects/SVGMorph.js';
import PointState from '../objects/PointState.js';

import {Line2} from "../lines/Line2.js";
import {LineGeometry} from "../lines/LineGeometry.js";
import {LineMaterial} from "../lines/LineMaterial.js";

import Easing from 'easing-functions';

import { easeInElastic} from 'js-easing-functions';

import { makeNoise2D } from "open-simplex-noise";

//-------------------------
//
// Meta Controller for the lines & effects in the current sequences
//
//-------------------------
var MAX_RESOLUTION = 1024 * 24 * 3,
	MAX_RESOLUTION_OIGNON_VECTOR = 1024 * 2 * 3,
	LINE_CURSOR_RESOLUTION = 16,
	MAX_CURSOR = 12;
var OIGNON_MAX_FRAME = 25;
var PALETTE_A = [0xBC6695, 0x2747D9, 0x030303, 0xF3642B, 0x074216, 0xF476C3, 0x296DEF, 0xD66C2D]
var PALETTE_B = [0xc78563,0x4953B8,0x982E73,0xd2fddf,0xce6f44,0xfcfcfc,0x6251D4,0xA5628f];

var OIGNON_VECTOR_MAX_FRAME = 25;

var CAMERA_DISTANCE = 1100;
var CURSOR_DURATION = 100;

class SequenceRendererStatic {

	constructor() {

		// if (SETTINGS.isMobile) SETTINGS.LINE_RESOLUTION = 512;

		//gui presets
		this.currentDragSpeed = new THREE.Vector2(0.0, 0.0);
		this.currentDragPosition = new THREE.Vector2(0.0, 0.0);
		this.targetPosition = new THREE.Vector2(0.0, 0.0);
		this.currentPosition = new THREE.Vector2(0.0, 0.0);
		this.rawPosition = new THREE.Vector2(0.0, 0.0);
		// this.rawPosition = new THREE.Vector2(0.0, 0.0);
		this.startPosition = new THREE.Vector2(0.0, 0.0);
		this.targetSplines = [];

		this.pulseTime = 0.0;
		this.lastPressTime = 0;
		this.justClickPressTime = 0;
		this.releaseTime = 0;
		this.clickMode = false;
		this.pressedThisFrame = false;
		this.releasedThisFrame = false;

		this.pressPc = 0.0;
		this.actualPressPc = 0.0;
		this.actualPressPcElastic = 0.0;

		this.clickPcTarget = 0.0;
		this.clickPc = 0.0;
		this.cameraStretch = new THREE.Vector2(1.0, 1.0);

		this.rng = makeNoise2D(Date.now());
		this.smoothNoiseTime = 0.0;

		this.currentIdx = 0;
		this.lastIdx = 0;

		this.oignonFrames = 0;
		this.lastOignonFrames = 0;


		this.trackPadModePc = 0.0;
		this.trackpadMode = false;

		this.sidesDistance = 0.0;
		this.sidesDistanceTarget = 0.0;

		this.multiClickPc = 0.0;

		this.elastic2 = 0.0;


		this.randomCursorTime = 0.0;
		this.staticDragPc = 0.0;
		this.randomCursorMultiClickPc = 0.0;
		this.powerEffectPc = 0.0;

		this.isOverOtherDiv = false;


		this.passivePc = 0.0;
		this.pauseButtonColor = 0.0;
		this.randomCursorTarget = new THREE.Vector2(0.0, 0.0);



		//
		// Simulator for installation & live cinema
		//
		this.lastRealClick = -1800000;
		this.simulationModeLocked = SETTINGS.TIMELINE_MODE;

		this.simulationStarted = false;
		this.simulationNextClick = 0;
		this.simulationIsDragging = false;
		this.simulationEndTime = 0;
		this.simulationClient = {
			pressed: false,
			targetX: Math.pow(Math.random(),1.5)*0.33 * (Math.random()<0.5?-1:1),
			targetY: Math.pow(Math.random(),1.5)*0.33 * (Math.random()<0.5?-1:1),

			nextTx:  Math.pow(Math.random(),1.5)*0.33 * (Math.random()<0.5?-1:1),
			nextTy:  Math.pow(Math.random(),1.5)*0.33 * (Math.random()<0.5?-1:1),
			nextPc: 0.0,

			currentX: Math.pow(Math.random(),1.5)*0.33 * (Math.random()<0.5?-1:1),
			currentY: Math.pow(Math.random(),1.5)*0.33 * (Math.random()<0.5?-1:1),

			oTx: Math.pow(Math.random(),1.5)*0.33 * (Math.random()<0.5?-1:1),
			oTy: Math.pow(Math.random(),1.5)*0.33 * (Math.random()<0.5?-1:1),

			nextSpeed: Math.random()*0.75+0.2,
			speed: Math.random()*0.4+0.1,
			nextPc: 0.0,
			time: 0
		};

		this.simulationUseRealUser = false; //follow a real user
		this.simulationUser = null; //user ref
		this.simulationUserSelectTime = 0; //start time for following, switch after a while
		this.simulationUserLastSeen = 0; //last click time, switch if not clicking
		this.simulationUserScore = 0; //follow a little longer with no click if it has a good score


	}


	preloadData(batchName) {

	}

	preload(batchName) {
	
		//
		// Add Shaders
		//
		Loader.addShader(batchName, 'shaders/common/vertex.vert');
		Loader.addShader(batchName, 'shaders/effects/pulseGradient.vert');
		Loader.addShader(batchName, 'shaders/effects/pulseGradient3.frag');
		Loader.addShader(batchName, 'shaders/common/color.frag');
		Loader.addShader(batchName, 'shaders/effects/oignonColor.frag');
		// Loader.addShader(batchName, 'shaders/effects/oignonPack.frag');
		Loader.addShader(batchName, 'shaders/effects/emboss.vert');
		Loader.addShader(batchName, 'shaders/effects/emboss.frag');
		Loader.addShader(batchName, 'shaders/effects/background.frag');
		Loader.addShader(batchName, 'shaders/common/copresence.frag');

		Loader.addShader(batchName, 'shaders/brushes/emboss.frag');
		Loader.addShader(batchName, 'shaders/brushes/linebrush_col.frag');
		Loader.addShader(batchName, 'shaders/brushes/linebrush_0.frag');
		Loader.addShader(batchName, 'shaders/brushes/linebrush_1.frag');
		Loader.addShader(batchName, 'shaders/brushes/linebrush_2.frag');

		Loader.addShader(batchName, 'shaders/brushes/emboss.vert');
		Loader.addShader(batchName, 'shaders/brushes/linebrush_col.vert');
		Loader.addShader(batchName, 'shaders/brushes/linebrush_0.vert');
		Loader.addShader(batchName, 'shaders/brushes/linebrush_1.vert');
		Loader.addShader(batchName, 'shaders/brushes/linebrush_2.vert');

		Loader.addShader(batchName, 'shaders/brushes/emboss2.vert');
		Loader.addShader(batchName, 'shaders/brushes/emboss2.frag');


		Loader.addShader(batchName, 'shaders/brushes/linebrush_global.vert');
		Loader.addShader(batchName, 'shaders/brushes/linebrush_global.frag');


		Loader.addShader(batchName, 'shaders/brushes/base.vert');
		Loader.addShader(batchName, 'shaders/brushes/base.frag');


		// Loader.addShader(batchName, 'shaders/effects/fxaa2.vert');
		// Loader.addShader(batchName, 'shaders/effects/fxaa2.frag');

		Loader.addShader(batchName, 'shaders/effects/digital.vert');
		Loader.addShader(batchName, 'shaders/effects/digital.frag');

		Loader.addShader(batchName, 'shaders/effects/noise.vert');
		Loader.addShader(batchName, 'shaders/effects/noise.frag');

		Loader.addShader(batchName, 'shaders/common/roundedBox.frag');

		// Loader.addShader(batchName, 'shaders/cursor/cursor.vert');
		// Loader.addShader(batchName, 'shaders/cursor/cursor.frag');

		// Loader.addShader(batchName, 'shaders/cursor/cursor2.vert');
		// Loader.addShader(batchName, 'shaders/cursor/cursor2.frag');

		this.noiseTexture = Loader.addTexture(batchName, 'images/common/noise_couleur0.jpg', {
			format: THREE.LuminanceFormat,
			minFilter: THREE.LinearMipMapLinearFilter,
			magFilter: THREE.LinearFilter,
			wrapping: THREE.MirroredRepeatWrapping,
			generateMipmaps: true
		});


		this.pauseButtonTexture = Loader.addTexture(batchName, 
			SETTINGS.INSTALLATION_MODE ? 'images/ui/install_recommencer_icon.png' : 'images/ui/pause_w.png', {
			format: THREE.RGBAFormat,
			minFilter: THREE.LinearMipMapLinearFilter,
			magFilter: THREE.LinearFilter,
			wrapping: THREE.ClampToEdgeWrapping,
			generateMipmaps: true
		});
		this.playButtonTexture = Loader.addTexture(batchName, 'images/ui/play_w.png', {
			format: THREE.RGBAFormat,
			minFilter: THREE.LinearMipMapLinearFilter,
			magFilter: THREE.LinearFilter,
			wrapping: THREE.ClampToEdgeWrapping,
			generateMipmaps: true
		});
		if (SETTINGS.VOLUME_BUTTON_ENABLED) {
			this.volumeButtonTexture = Loader.addTexture(batchName, 'images/ui/install_volume_icon.png', {
				format: THREE.RGBAFormat,
				minFilter: THREE.LinearMipMapLinearFilter,
				magFilter: THREE.LinearFilter,
				wrapping: THREE.ClampToEdgeWrapping,
				generateMipmaps: true
			});
			this.volumeMoinsTexture = Loader.addTexture(batchName, 'images/ui/install_volume_moins.png', {
				format: THREE.RGBAFormat,
				minFilter: THREE.LinearMipMapLinearFilter,
				magFilter: THREE.LinearFilter,
				wrapping: THREE.ClampToEdgeWrapping,
				generateMipmaps: true
			});
			this.volumePlusTexture = Loader.addTexture(batchName, 'images/ui/install_volume_plus.png', {
				format: THREE.RGBAFormat,
				minFilter: THREE.LinearMipMapLinearFilter,
				magFilter: THREE.LinearFilter,
				wrapping: THREE.ClampToEdgeWrapping,
				generateMipmaps: true
			});

		}

		this.copresenceCanvas = document.createElement('canvas');
		this.copresenceCanvas.width = 256; //SETTINGS.isMobile?128:192;
		this.copresenceCanvas.height = 128; //SETTINGS.isMobile?64:96;;
		this.copresenceContext = this.copresenceCanvas.getContext('2d');



		//load cursors svg sequences
		this.cursorPressSequence = Loader.addSVGSequence(batchName, "seq_cursor_a1");
		this.cursorHoldSequence = Loader.addSVGSequence(batchName, SETTINGS.CURSOR_HOLD||"seq_cursor_hold_e");
		this.cursorReleaseSequence = Loader.addSVGSequence(batchName, "seq_cursor_a3");

		
	}


	setup() {

		//three.js
		this.scene = new THREE.Scene();
		this.container = new THREE.Object3D();
		this.scene.add(this.container);
		this.copresenceScene = new THREE.Scene();
		this.copresencePlaneScene = new THREE.Scene();
		this.camera = new THREE.PerspectiveCamera( 50, AppStatus.innerWidth() / AppStatus.innerHeight(), 0.1, 100000 );


		// this.camera.position.set( 0, 540, 2250 );
		this.camera.position.set( 0, 0, CAMERA_DISTANCE );
		this.camera.scale.y = -1;
		this.camera.aspect = AppStatus.innerWidth() / AppStatus.innerHeight();
		// this.camera.updateProjectionMatrix();
		this.resize();

		this.fbo = new Fbo(renderer.domElement.width, renderer.domElement.height, {
			format: THREE.RGBAFormat,
			minFilter: THREE.LinearFilter,
			magFilter: THREE.LinearFilter,
			generateMipmaps: false,
			wrap: THREE.ClampToEdgeWrapping,
			type: THREE.UnsignedByteType,
			premultiplyAlpha: false,
			depthBuffer: false,
			stencilBuffer: false,
			pingPongEnabled: false
		});


		// this.lineMaterial = new LineMaterial( {
		// 	color: new THREE.Color(0),
		// 	linewidth: 0.003, // in pixels
		// 	dashed: false
		// });
		this.lineMaterial = new THREE.RawShaderMaterial({
			transparent: false,
			depthTest: false,
			depthWrite: false,
			blending: THREE.NoBlending,
			side: THREE.DoubleSide,
			vertexShader: Loader.getShader('shaders/brushes/base.vert'),
			fragmentShader: Loader.getShader('shaders/brushes/base.frag'),
			// clipping: true,
			uniforms: {
				color: { type:'c', value: new THREE.Color(0)},
				resolution: { type: 'v2', value:new THREE.Vector2(renderer.domElement.width, renderer.domElement.height)},
				lineWidth: {type: 'f', value: 0.0025},
				ratio: {type: 'f', value: 1.0},
				pixelRatio: {type: 'f', value: 2.0},
				
				pulseTime: {type: 'f', value: 0.0},
				pulseDepth: {type: 'f', value: 0.0},


				strokeInteractive: {type: 'f', value: 0.0},
				strokeInteractiveDistance: {type: 'f', value: 0.0},
				strokeInteractivePulse: {type: 'f', value: 0.0},
				mousePos: {type: 'v2', value: new THREE.Vector2(0,0)},

			}
		});
		this.lineWidth = 1.0;
		Utils.compileMaterial(this.lineMaterial);


		this.brushMaterials = {};
		var bMats = ["emboss","emboss2", "linebrush_global"];

		for (var i=0; i<bMats.length; i++) {
			var shader = bMats[i];
			this.brushMaterials[shader] = new THREE.RawShaderMaterial({
				transparent: true,
				depthTest: false,
				depthWrite: false,
				side: THREE.DoubleSide,
				vertexShader: Loader.getShader('shaders/brushes/'+shader+'.vert'),
				fragmentShader: Loader.getShader('shaders/brushes/'+shader+'.frag'),
				uniforms: {
					tDiffuse: { type: 't', value:this.brushTexture},
					source: { type: 'v2', value:new THREE.Vector2(0.5,0.5)},
					brushRatio: { type: 'v2', value:new THREE.Vector2(0.5,0.5)},
					color: { type: 'c', value:new THREE.Color(0)},
					ratio: { type: 'f', value: 0.5625},
					resolution: { type: 'v2', value:new THREE.Vector2(renderer.domElement.width, renderer.domElement.height)},
					sourceSize: {type: 'f', value: 0.05},
					rotation: {type: 'f', value: 0.0},
					size: {type: 'f', value: 0.05},
					lineWidth: {type: 'f', value: 0.05},
					colorPc: { type: 'f', value: 0.0},

					pulseTime: {type: 'f', value: 0.0},
					pulseDepth: {type: 'f', value: 1.0},
					pixelRatio: {type: 'f', value: 1.0},

					strokeInteractive: {type: 'f', value: 0.0},
					strokeInteractiveDistance: {type: 'f', value: 0.0},
					strokeInteractivePulse: {type: 'f', value: 0.0},
					mousePos: {type: 'v2', value: new THREE.Vector2(0,0)},
				}
			});
		}


		//----------------
		//
		// Pre-create all lines
		//
		//----------------
		// this.allLines = [];
		// for (var i=0; i<MAX_LINES; i++) {
			var pos = new Float32Array(MAX_RESOLUTION);
			var  geometry = new LineGeometry();
			geometry.setPositions( pos );
			
			var line = new Line2( geometry, this.lineMaterial);//); // /.clone()
			line.computeLineDistances();
			line.visible = true;
			line.scale.set(1024,1024,1024);
			// line.position.y -= 242; //(1080/2)/2;
			line.positionArray = pos;
			line.defaultMaterial = line.material;
			this.container.add( line );
			this.line = line;
			this.positions = pos;



			this.lineGeometry2 = new LineGeometry();
			this.lineGeometry2.setPositions( pos.subarray(0,1024 * 2 * 3));
			this.lineGeometry2.computeBoundingSphere();
			this.lineGeometry4 = new LineGeometry();
			this.lineGeometry4.setPositions( pos.subarray(0,1024 * 4 * 3));
			this.lineGeometry4.computeBoundingSphere();
			this.lineGeometry8 = new LineGeometry();
			this.lineGeometry8.setPositions( pos.subarray(0,1024 * 8 * 3));
			this.lineGeometry8.computeBoundingSphere();
			this.lineGeometry16 = new LineGeometry();
			this.lineGeometry16.setPositions( pos.subarray(0,1024 * 16 * 3));
			this.lineGeometry16.computeBoundingSphere();
			this.lineGeometry24  = new LineGeometry();
			this.lineGeometry24.setPositions( pos.subarray(0,1024 * 24 * 3));
			this.lineGeometry24.computeBoundingSphere();
			this.lineGeometry32 = geometry;
			this.lineGeometry32.computeBoundingSphere();

		//
		// Line cursor
		//
		this.planeMaterial = new THREE.RawShaderMaterial({
			transparent: true,
			depthTest: false,
			depthWrite: false,
			side: THREE.DoubleSide,
			vertexShader: Loader.getShader('shaders/common/vertex.vert'),
			fragmentShader: Loader.getShader('shaders/common/copresence.frag'),
			uniforms: {
				tDiffuse: { type: 't', value:Utils.emptyTexture},
				palette: { type: 'c', value:new THREE.Color(0xffffff)},
				alpha: { type: 'f', value:1.0},
			}
		});
		Utils.compileMaterial(this.planeMaterial);
		// if (SETTINGS.MOUSEMOVE_MODE) {
			var lcPos = new Float32Array(LINE_CURSOR_RESOLUTION*3);
			var lcGeom = new LineGeometry();
			lcGeom.setPositions( lcPos );
			
			var lcLine = new Line2( lcGeom, this.lineMaterial.clone());//); // /.clone()
			lcLine.computeLineDistances();
			lcLine.visible = true;
			lcLine.scale.set(1024,1024,1024);
			lcLine.positionArray = lcPos;
			lcLine.defaultMaterial = lcLine.material;

			lcLine.currentId = -1;
			lcLine.planeTexture = Utils.createTexture(this.copresenceCanvas,THREE.LuminanceFormat, false, true);
			lcLine.planeTexture.premultiplyAlpha = false;
			lcLine.planeTexture.needsUpdate = true;
			lcLine.plane = new THREE.Mesh(Utils.planeGeometry, this.planeMaterial.clone());
			lcLine.plane.material.uniforms.tDiffuse.value = lcLine.planeTexture;
			lcLine.plane.visible = false;
			lcLine.plane.scale.set(128 * 1/AppStatus.innerWidth(),64* 1/AppStatus.innerHeight(),1);
			this.copresencePlaneScene.add( lcLine.plane );


			// this.lineCursorScene = new THREE.Scene();
			this.scene.add( lcLine );
			this.lineCursor = lcLine;
			this.currentLineCursorPos = new THREE.Vector2(0,0);
			this.lineCursorPositions = lcPos;
			this.lastLineCursorTime = performance.now();
		// }


		//preload font
		this.copresenceContext.fillStyle = '#000000';
		this.copresenceContext.fillRect(0,0, this.copresenceCanvas.width, this.copresenceCanvas.width);
		this.copresenceContext.font = '500 '+(SETTINGS.isMobile?'18pt':'18pt')+" 'Work Sans'";
		this.copresenceContext.textAlign = 'center';
		this.copresenceContext.fillStyle = 'white';
		this.copresenceContext.strokeStyle = 'white';
		this.copresenceContext.fillText('Participant',0,0);



		this.copresenceWasEnabled = false;
		this.copresenceEnabled = false;
		this.copresenceEnabledPc = 0;
		this.copresenceCursors = [];
		this.innactiveCursors = [];
		this.copresenceTextures = [];
		for (var i=0; i<MAX_CURSOR; i++) {
			this.innactiveCursors.push(i);
			var lcPos = new Float32Array(LINE_CURSOR_RESOLUTION*3);
			var lcGeom = new LineGeometry();
			lcGeom.setPositions( lcPos );
			
			var lcLine = new Line2( lcGeom, this.lineMaterial);//); // /.clone()
			lcLine.computeLineDistances();
			lcLine.visible = false;
			lcLine.scale.set(1024,1024,1024);
			lcLine.positionArray = lcPos;
			lcLine.defaultMaterial = this.lineMaterial;
			
			this.scene.add( lcLine );
			this.copresenceCursors.push(lcLine);

			lcLine.targetPos = new THREE.Vector2(0,0);
			lcLine.currentPos = new THREE.Vector2(0,0);
			lcLine.positions = lcPos;
			lcLine.lastTime = performance.now();
			lcLine.currentId = -1;


			// this.copresenceContext.clearRect(0,0,this.copresenceCanvas.width,this.copresenceCanvas.height);
			// this.copresenceContext.font = "normal normal 200 14pt 'Montserrat'";
			// this.copresenceContext.textAlign = 'center';
			// this.copresenceContext.fillStyle = '#000000';
			// this.copresenceContext.globalAlpha = 1.0;
			// // this.copresenceContext.fillAlpha = 1.0;
			// this.copresenceContext.fillText('Participant #'+i, 64,32);



			this.copresenceTextures[i] = Utils.createTexture(this.copresenceCanvas,THREE.LuminanceFormat, false, true);
			this.copresenceTextures[i].premultiplyAlpha = false;
			this.copresenceTextures[i].needsUpdate = true;
			lcLine.plane = new THREE.Mesh(Utils.planeGeometry, this.planeMaterial.clone());
			lcLine.plane.material.uniforms.tDiffuse.value = this.copresenceTextures[i];
			lcLine.plane.visible = false;
			lcLine.plane.scale.set(128 * 1/AppStatus.innerWidth(),64* 1/AppStatus.innerHeight(),1);//.multiplyScalar(1/AppStatus.innerHeight());
			this.copresencePlaneScene.add( lcLine.plane );
		}

		//----------
		//
		// Background
		//
		//------------
		this.backgroundMaterial = new THREE.RawShaderMaterial({
			transparent: false,
			depthTest: false,
			depthWrite: false,
			side: THREE.DoubleSide,
			vertexShader: Loader.getShader('shaders/effects/pulseGradient.vert'),
			fragmentShader: Loader.getShader('shaders/effects/pulseGradient3.frag'),
			uniforms: {
				tNoise: {type: 't', value:this.noiseTexture},
				alpha: { type: 'f', value: 0 },

				fillColor: { type: 'c', value: new THREE.Color(0) },
				fillAlpha: { type:'f', value:0.0},

				resolution: {type:'v2', value:new THREE.Vector2(1,1)},

				pulseTime: { type: 'f', value: 0 },
				gradientTime: { type: 'f', value: 0 },

				gradientMin: { type: 'f', value: 0 },
				gradientMax: { type: 'f', value: 0 },
				noiseA: { type: 'f', value: 0 },
				noiseB: { type: 'f', value: 0 },
				noiseTime: { type: 'f', value: 0 },
				randomOffset: { type: 'v2', value: new THREE.Vector2(1,1) },

				colorA: { type: 'c', value: new THREE.Color(0) },
				colorB: { type: 'c', value: new THREE.Color(0) },
				colorC: { type: 'c', value: new THREE.Color(0) }
			}
		});
		this.backgroundMaterialSimple = new THREE.RawShaderMaterial({
			transparent: false,
			depthTest: false,
			depthWrite: false,
			side: THREE.DoubleSide,
			vertexShader: Loader.getShader('shaders/common/vertex.vert'),
			fragmentShader: Loader.getShader('shaders/effects/background.frag'),
			uniforms: {
				fillColor: { type: 'c', value: new THREE.Color(0) },
				alpha: { type: 'f', value: 0 },
			}
		});

	
		//
		this.pulseTime = 0.0;
		this.gradientTime = 103.1;
		this.noiseTime = 71.7;
		this.renderBackground = false;
		this.pulseSpeed = 0.0;
		this.gradientSpeed = 0.0;
		
		this.paletteA = "palette_a";
		this.paletteB = "palette_b";
		this.paletteMixPc = 0.0;


		this.oignonVectorPaletteA = "palette_a";
		this.oignonVectorPaletteB = "palette_b";
		this.oignonVectorPaletteMixPc = 0.0;

		Utils.compileMaterial(this.backgroundMaterialSimple);
		Utils.compileMaterial(this.backgroundMaterial);
		this.roundedBoxMaterial = new THREE.RawShaderMaterial({
			transparent: true,
			depthTest: false,
			blending: THREE.NormalBlending,
			depthWrite: false,
			side: THREE.DoubleSide,
			vertexShader: Loader.getShader('shaders/common/vertex.vert'),
			fragmentShader: Loader.getShader('shaders/common/roundedBox.frag'),
			uniforms: {
				ratio: { type: 'v2', value: new THREE.Vector2(1,1) },
				size: { type: 'v2', value: new THREE.Vector2() },
				resolution: { type: 'v2', value: new THREE.Vector2(AppStatus.innerWidth(), AppStatus.innerHeight()) },
				alpha: { type: 'f', value: 0 },
			}
		});
		this.roundedBoxPlane = new THREE.Mesh(Utils.planeGeometry, this.roundedBoxMaterial); //new THREE.MeshBasicMaterial({ color: 'white', blending: THREE.NoBlending, side: THREE.DoubleSide, depthTest: false, depthWrite: false}));//this.roundedBoxMaterial
		this.roundedBoxPlane.scale.set(512,512,512);
		this.roundedBoxScene = new THREE.Scene();
		this.roundedBoxScene.add(this.roundedBoxPlane);
		this.showRoundedBox = false;

		//----------
		//
		// Effects and oignon skin
		//
		//------------
		this.fbos = [];
		for (var i = 0; i < OIGNON_MAX_FRAME; i++) {
			// this.fbos[i] = new Fbo(renderer.domElement.width/2, renderer.domElement.height/2, {//SETTINGS.isMobile?renderer.domElement.width:renderer.domElement.width,SETTINGS.isMobile?renderer.domElement.height:renderer.domElement.height, {
			// this.fbos[i] = new Fbo(SETTINGS.isMobile?renderer.domElement.width/2:renderer.domElement.width,SETTINGS.isMobile?renderer.domElement.height/2:renderer.domElement.height, {
			this.fbos[i] = new Fbo(Math.floor(renderer.domElement.width*0.6)-4,Math.floor(renderer.domElement.height*0.6)-4, {
				format: THREE.RGBAFormat,
				minFilter: THREE.LinearFilter,
				magFilter: THREE.LinearFilter,
				generateMipmaps: false,
				wrap: THREE.ClampToEdgeWrapping,
				type: THREE.UnsignedByteType,
				premultiplyAlpha: false,
				depthBuffer: false,
				stencilBuffer: false,
				pingPongEnabled: false
			});
			Utils.clearFboAlpha(this.fbos[i].texture);
		}
		this.oignonFbo = new Fbo(Math.floor(renderer.domElement.width*0.6),Math.floor(renderer.domElement.height*0.6), {
			format: THREE.RGBAFormat,
			minFilter: THREE.LinearFilter,
			magFilter: THREE.LinearFilter,
			generateMipmaps: false,
			wrap: THREE.ClampToEdgeWrapping,
			type: THREE.UnsignedByteType,
			premultiplyAlpha: false,
			depthBuffer: false,
			stencilBuffer: false,
			pingPongEnabled: false
		});
		Utils.clearFboAlpha(this.oignonFbo.texture);


		this.lastOignon = 0.0;
		this.lastOignonVector = 0.0;
		this.oignonFrames = 12;
		this.oignonSkip = 1;
		this.oignonColor = 0.0;
		this.oignonSkinEnabled = false;

		this.oignonMaterial = new THREE.RawShaderMaterial({
			transparent: true,
			depthTest: false,
			depthWrite: false,
			side: THREE.DoubleSide,
			vertexShader: Loader.getShader('shaders/common/vertex.vert'),
			fragmentShader: Loader.getShader('shaders/effects/oignonColor.frag'),
			uniforms: {
				tDiffuse: { type: 't', value:this.fbos[0].texture},
				col: { type: 'c', value:new THREE.Color(0)},
				alpha: { type: 'f', value:1.0},
				paletteMix: { type: 'f', value:0.0}
			}
		});


		// this.oignonPackMaterial = new THREE.RawShaderMaterial({
		// 	transparent: true,
		// 	depthTest: false,
		// 	depthWrite: false,
		// 	side: THREE.DoubleSide,
		// 	vertexShader: Loader.getShader('shaders/common/vertex.vert'),
		// 	fragmentShader: Loader.getShader('shaders/effects/oignonPack.frag'),
		// 	uniforms: {
		// 		frame0: { type: 't', value:this.fbos[0].texture},
		// 		frame1: { type: 't', value:this.fbos[0].texture},
		// 		frame2: { type: 't', value:this.fbos[0].texture},
		// 		frame3: { type: 't', value:this.fbos[0].texture},
		// 		frame4: { type: 't', value:this.fbos[0].texture},
		// 		frame5: { type: 't', value:this.fbos[0].texture},
		// 		frame6: { type: 't', value:this.fbos[0].texture},
		// 		frame7: { type: 't', value:this.fbos[0].texture},

		// 		col0: { type: 'v4', value:new THREE.Vector4(0.0)},
		// 		col1: { type: 'v4', value:new THREE.Vector4(0.0)},
		// 		col2: { type: 'v4', value:new THREE.Vector4(0.0)},
		// 		col3: { type: 'v4', value:new THREE.Vector4(0.0)},
		// 		col4: { type: 'v4', value:new THREE.Vector4(0.0)},
		// 		col5: { type: 'v4', value:new THREE.Vector4(0.0)},
		// 		col6: { type: 'v4', value:new THREE.Vector4(0.0)},
		// 		col7: { type: 'v4', value:new THREE.Vector4(0.0)},

		// 		alpha: { type: 'f', value:1.0},
		// 		paletteMix: { type: 'f', value:0.0}
		// 	}
		// });


		this.effectsFbo = new Fbo(renderer.domElement.width, renderer.domElement.height, {
			format: THREE.RGBAFormat,
			minFilter: THREE.NearestFilter,
			magFilter: THREE.NearestFilter,
			generateMipmaps: false,
			wrap: THREE.ClampToEdgeWrapping,
			type: THREE.UnsignedByteType,
			premultiplyAlpha: false,
			depthBuffer: false,
			stencilBuffer: false,
			pingPongEnabled: false
		});
		renderer.setRenderTarget(this.effectsFbo.texture);
		renderer.clear();
		renderer.render(this.scene, this.camera);



		this.embossMaterial = new THREE.RawShaderMaterial({
			vertexShader: Loader.getShader('shaders/effects/emboss.vert'),
			fragmentShader: Loader.getShader('shaders/effects/emboss.frag'),
			uniforms: {
				tDiffuse: { type: 't', value:null},
				px1: { type: 'vec2', value:new THREE.Vector2(1.0 / renderer.domElement.width, 1.0 / renderer.domElement.height)},
				glitch: { type: 'float', value:0.0},
				embossAlpha: { type: 'float', value:1.0},
			},
			transparent: false,
			depthTest: false,
			depthWrite: false,
			side: THREE.DoubleSide
		});
		this.embossEnabled = false;
		this.embossDst = 1.0;
		this.embossAlpha = 1.0;
		this.embossFps = 60.0;
		this.useEffectsFbo = false;
		this.strokeColor = new THREE.Color(0);


		//
		// FXAA global
		//
		this.targetFbo = new Fbo(renderer.domElement.width, renderer.domElement.height, {
			format: THREE.RGBAFormat,
			minFilter: THREE.LinearFilter,
			magFilter: THREE.LinearFilter,
			generateMipmaps: false,
			wrap: THREE.ClampToEdgeWrapping,
			type: THREE.UnsignedByteType,
			premultiplyAlpha: false,
			depthBuffer: false,
			stencilBuffer: false,
			pingPongEnabled: true,
			antialias: false
		});
		// this.fxaaMaterial = new THREE.RawShaderMaterial({
		// 	vertexShader: Loader.getShader('shaders/effects/fxaa2.vert'),
		// 	fragmentShader: Loader.getShader('shaders/effects/fxaa2.frag'),
		// 	blending: THREE.NoBlending,
		// 	transparent: false,
		// 	depthTest: false,
		// 	depthWrite: false,
		// 	side: THREE.DoubleSide,
		// 	uniforms: {
		// 		tDiffuse: { type: 't', value: this.targetFbo.texture.texture },
		// 		resolution: { type: 'v2', value:new THREE.Vector2(renderer.domElement.width, renderer.domElement.height)}
		// 	}
		// });
		this.digitalMaterial = new THREE.RawShaderMaterial({
			vertexShader: Loader.getShader('shaders/effects/digital.vert'),
			fragmentShader: Loader.getShader('shaders/effects/digital.frag'),
			blending: THREE.NoBlending,
			transparent: false,
			depthTest: false,
			depthWrite: false,
			side: THREE.DoubleSide,
			uniforms: {
				tDiffuse: { type: 't', value: this.targetFbo.getPing().texture },
				// tLast: { type: 't', value: this.targetFbo.getPong().texture },
				tNoise: { type: 't', value: this.noiseTexture },
				time: { type: 'f', value: 0},
				randomOffset: { type: 'v2', value: new THREE.Vector2()},
				randomOffset2: { type: 'v2', value: new THREE.Vector2()},

				alpha: { type: 'f', value: 0},

				noiseA: { type: 'f', value: 0},
				noiseB: { type: 'f', value: 0},
				noiseC: { type: 'f', value: 0},
				noiseD: { type: 'f', value: 0},
				noiseE: { type: 'f', value: 0},
				noiseE: { type: 'f', value: 0},
				noiseF: { type: 'f', value: 0},
				// noiseG: { type: 'f', value: 0},
				// noiseH: { type: 'f', value: 0},

				radial: { type: 'f', value: 0},
				colorA: { type: 'f', value: 0},
				colorB: { type: 'f', value: 0},

				resolution: { type: 'v2', value:new THREE.Vector2(renderer.domElement.width, renderer.domElement.height)}
			}
		});
		this.noiseMaterial = new THREE.RawShaderMaterial({
			defines: {
				COLORED: SETTINGS.NOISE_COLOR?1:0
			},
			vertexShader: Loader.getShader('shaders/effects/noise.vert'),
			fragmentShader: Loader.getShader('shaders/effects/noise.frag'),
			blending: THREE.NoBlending,
			transparent: false,
			depthTest: false,
			depthWrite: false,
			side: THREE.DoubleSide,
			uniforms: {
				tDiffuse: { type: 't', value: this.targetFbo.getPing().texture },
				tNoise: { type: 't', value: this.noiseTexture },
				randomOffset: { type: 'v2', value: new THREE.Vector2()},
				resolution: { type: 'v2', value: new THREE.Vector2()},
				noisePc: { type: 'f', value: 0.025}
			}
		});

		//----------
		//
		//  Oignon skin vector frames
		//
		//------------
		this.oignonVectorInfo = [];
		for (var i=0; i<OIGNON_VECTOR_MAX_FRAME; i++) {
			this.oignonVectorInfo[i] = {
				lastRender: 0,
				positions: new Float32Array(MAX_RESOLUTION_OIGNON_VECTOR),
				positionLength: 0,
				materials: null,
				uniforms: null,
				oid: i,
				randomBrush: new THREE.Vector2()
			}
		}
		this.currentOignonVectorFrame = this.oignonVectorInfo[0];
		this.oignonPositionPool = [];
		this.oignonVectorRandomBrush = 0.0;



		//----------
		//
		// touch feedback
		//
		//------------
		// this.cursors = [];
		// this.cursorMaterial  = new THREE.RawShaderMaterial({
		// 	vertexShader: Loader.getShader('shaders/cursor/cursor.vert'),
		// 	fragmentShader: Loader.getShader('shaders/cursor/cursor.frag'),
		// 	blending: THREE.SubtractiveBlending,
		// 	transparent: true,
		// 	depthTest: false,
		// 	depthWrite: false,
		// 	side: THREE.DoubleSide,
		// 	uniforms: {
		// 		tNoise: { type: 't', value: this.noiseTexture },
		// 		time: { type: 'f', value: 0},
		// 		randomOffset: { type: 'v2', value: new THREE.Vector2()},
		// 		alpha: { type: 'f', value: 0},
		// 		speed: { type: 'f', value: 0},
		// 		direction: { type: 'v2', value: new THREE.Vector2(1,1)},
		// 		resolution: { type: 'v2', value:new THREE.Vector2(renderer.domElement.width, renderer.domElement.height)}
		// 	}
		// });
		// Utils.compileMaterial(this.cursorMaterial);

		// this.cursorMaterial2  = new THREE.RawShaderMaterial({
		// 	vertexShader: Loader.getShader('shaders/cursor/cursor2.vert'),
		// 	fragmentShader: Loader.getShader('shaders/cursor/cursor2.frag'),
		// 	blending: THREE.AdditiveBlending,
		// 	transparent: true,
		// 	depthTest: false,
		// 	depthWrite: false,
		// 	side: THREE.DoubleSide,
		// 	uniforms: {
		// 		tNoise: { type: 't', value: this.noiseTexture },
		// 		time: { type: 'f', value: 0},
		// 		randomOffset: { type: 'v2', value: new THREE.Vector2()},
		// 		alpha: { type: 'f', value: 0},
		// 		speed: { type: 'f', value: 0},
		// 		direction: { type: 'v2', value: new THREE.Vector2(1,1)},
		// 		resolution: { type: 'v2', value:new THREE.Vector2(renderer.domElement.width, renderer.domElement.height)}
		// 	}
		// });
		// Utils.compileMaterial(this.cursorMaterial2);
		// this.cursorScene = new THREE.Scene();
		// for (var i=0; i<MAX_CURSOR; i++) {
		// 	var c = new THREE.Object3D();
		// 	c.cursorA = new THREE.Mesh(Utils.planeGeometry, this.cursorMaterial.clone());
		// 	c.cursorB = new THREE.Mesh(Utils.planeGeometry, this.cursorMaterial2.clone());
		// 	c.add(c.cursorA);
		// 	c.add(c.cursorB);
		// 	// c.scale.set
		// 	c.visible = i==0;
		// 	this.cursorScene.add(c);
		// 	this.cursors.push(c);
		// }


		//--------------
		//
		// Pause Button
		//
		//--------------
		this.uiScene = new THREE.Scene();
		this.pauseButton = new THREE.Mesh(Utils.planeGeometry,
			new THREE.MeshBasicMaterial({
				map: this.pauseButtonTexture,
				color: new THREE.Color(0xffffff),
				blending: THREE.NormalBlending,
				transparent: true,
				side: THREE.DoubleSide,
				depthWrite: false,
				depthTest: false,
				alphaTest: false,
				opacity: 0.0
			}));
		
		if (!SETTINGS.isMobile || SETTINGS.isTablet) {
			this.pauseButton.scale.set(20,20,20);
			this.pauseButton.position.x = 32;
			this.pauseButton.position.y = AppStatus.innerHeight()-32;
			// this.pauseButton.scale.set(18,18,18);
		} else {
			this.pauseButton.position.y = AppStatus.innerHeight()-28;
			this.pauseButton.position.x = 28;
			this.pauseButton.scale.set(18,18,18);
			$('#pause-restart-container').toggleClass('mobile', true);
			$('#timecode-final').toggleClass('mobile', true);
		}
		
		this.uiCamera = new THREE.OrthographicCamera(0, AppStatus.innerWidth(), 0, AppStatus.innerHeight(), -1, 1);
		this.uiScene.add(this.pauseButton);



		//--------------
		//
		// VOLUME Button
		//
		//--------------
		if (SETTINGS.VOLUME_BUTTON_ENABLED) {
			// this.uiScene = new THREE.Scene();
			this.volumeButtonOpen = false;
			this.volumeButton = new THREE.Mesh(Utils.planeGeometry,
				new THREE.MeshBasicMaterial({
					map: this.volumeButtonTexture,
					color: new THREE.Color(0xffffff),
					blending: THREE.NormalBlending,
					transparent: true,
					side: THREE.DoubleSide,
					depthWrite: false,
					depthTest: false,
					alphaTest: false,
					opacity: 1.0
				}));
			this.uiScene.add(this.volumeButton);
			
			if (!SETTINGS.isMobile || SETTINGS.isTablet) {
				this.volumeButton.scale.set(20*1.181818,20,20);
				this.volumeButton.position.x = AppStatus.innerWidth()-38;
				this.volumeButton.position.y = AppStatus.innerHeight()-32;
				// this.pauseButton.scale.set(18,18,18);
			} else {
				this.volumeButton.position.y = AppStatus.innerHeight()-28;
				this.volumeButton.position.x = AppStatus.innerWidth()-36;
				this.volumeButton.scale.set(18*1.181818,18,18);
			}
				

			this.volumeMoinsButton = new THREE.Mesh(Utils.planeGeometry,
				new THREE.MeshBasicMaterial({
					map: this.volumeMoinsTexture,
					color: new THREE.Color(0xffffff),
					blending: THREE.NormalBlending,
					transparent: true,
					side: THREE.DoubleSide,
					depthWrite: false,
					depthTest: false,
					alphaTest: false,
					opacity: 1.0
				}));
			this.volumeMoinsButton.visible = false;
			this.volumeMoinsButton.scale.set(11,11,11);
			this.volumeMoinsButton.position.x = AppStatus.innerWidth()-38;
			this.volumeMoinsButton.position.y = AppStatus.innerHeight() - 32 - 48;

			this.uiScene.add(this.volumeMoinsButton);


			this.volumePlusButton = new THREE.Mesh(Utils.planeGeometry,
				new THREE.MeshBasicMaterial({
					map: this.volumePlusTexture,
					color: new THREE.Color(0xffffff),
					blending: THREE.NormalBlending,
					transparent: true,
					side: THREE.DoubleSide,
					depthWrite: false,
					depthTest: false,
					alphaTest: false,
					opacity: 1.0
				}));

			this.volumePlusButton.scale.set(11,11,11);
			this.volumePlusButton.position.x = AppStatus.innerWidth() - 38;
			this.volumePlusButton.position.y = AppStatus.innerHeight() - 32 - 48 - 48;
			this.volumePlusButton.visible = false;
			this.volumeAnimation = 0;
			this.volumePlusAnimation = 0;
			this.volumeMoinsAnimation = 0;

			this.uiScene.add(this.volumePlusButton);

		}
		

		//--------------
		//
		// Chiffres -> over, no effect
		//
		//--------------
		this.chiffreScene = new THREE.Scene();
		this.chiffrePositions = new Float32Array(384*3);

		this.chiffreGeom = new LineGeometry();
		this.chiffreGeom.setPositions( this.chiffrePositions );
		this.currentChiffreIdx = 0;
		
		var chiffreLine = new Line2( this.chiffreGeom, this.lineMaterial);//); // /.clone()
		chiffreLine.computeLineDistances();
		chiffreLine.visible = true;
		chiffreLine.scale.set(1024,1024,1024);
		chiffreLine.positionArray = lcPos;
		this.chiffre = chiffreLine;
		this.chiffreScene.add( chiffreLine );


		//--------------
		//
		// timeline eyes -> over, no effect
		//
		//--------------
		this.eyesScene = new THREE.Scene();
		this.eyesPositions = new Float32Array(512*3);

		this.eyesGeom = new LineGeometry();
		this.eyesGeom.setPositions( this.eyesPositions );
		this.currentEyesIdx = 0;
		
		var eyesLine = new Line2( this.eyesGeom, this.lineMaterial);//); // /.clone()
		eyesLine.computeLineDistances();
		eyesLine.visible = true;
		eyesLine.scale.set(1024,1024,1024);
		eyesLine.positionArray = lcPos;
		this.eyesLine = eyesLine;
		this.eyesScene.add( eyesLine );


		//--------------
		//
		// cursor animations -> over, no effect
		//
		//--------------
		this.cursorScene = new THREE.Scene();
		this.cursorPositions = new Float32Array(960);
		this.cursorPositions.fill(0);

		this.cursorGeom = new LineGeometry();
		this.cursorGeom.setPositions( this.cursorPositions );
		this.currentCursorIdx = 0;
		this.currentCursor = null;
		
		var cursor = new Line2( this.cursorGeom, this.lineMaterial);//); // /.clone()
		cursor.computeLineDistances();
		cursor.visible = true;
		cursor.scale.set(1024,1024,1024);
		this.cursor = cursor;
		this.cursorScene.add( cursor );
		this.cursors = [];


		//----------
		//
		// touch/mouse control
		//
		//------------
		this.justClickPc = 0.0;
		this.pressPc = 0.0;
		this.pressPcElastic = 0.0;
		this.pressPcSpeed = 0.0;
		this.mouseIsDown = false;
		this.lastMouseMove = 0;
		this.randomCursorPc = 1.0;
		this.clickedOnce = false;
		this.mouseMoveStart = 0;
		this.lastDragTime = 0;

		this.cursorShown = 0;
		if ((SETTINGS.MOUSEGRAB_MODE || SETTINGS.MOUSEGRAB2_MODE || SETTINGS.MOUSEMOVE_MODE) && (SETTINGS.SCENE =="timeline" || !SETTINGS.SCENE || SETTINGS.SCENE=="editor")) $('#main').css('cursor', 'none');

		if (!SETTINGS.isMobile) {
			if (SETTINGS.MOUSEMOVE_MODE) InteractionController.start();

			$('#main').mouseover((e) => {
				this.isOverOtherDiv = false;
			}).mouseout((e) => {
				this.isOverOtherDiv = true;
			});


			$('#main').mousedown((e)=>{

				if (!this.isDragging) this.mouseMoveStart = performance.now();
				this.lastRealClick = performance.now();

				this.isDragging = true;
				this.mouseIsDown = true;
				this.clickedOnce = true;

				if (!AppStatus.isOnLandingPage && !AppStatus.isOnEndPage && !AppStatus.isOnIntro) {
					if (!SETTINGS.MENU_DISABLED && e.pageX < 50 && e.pageY > AppStatus.innerHeight()-50) {
						if (!MenuController.shown) {
							MenuController.pause();
							MenuController.show();
							AudioController.pausedForMenu=true;
							InteractionController.pressedThisFrame = false;
							AnalyticsController.trackEvent("pause");
						} else {
							MenuController.resume();
							MenuController.hide();
							AudioController.pausedForMenu = false;
							InteractionController.pressedThisFrame = false;
						}
						return;

					} else if (MenuController.infoPageOpen) {
						// if (AudioController.pausedForMenu) {
						// 	MenuController.resume();
						// 	AudioController.pausedForMenu = false;
						// 	InteractionController.pressedThisFrame = false;
						// }
						// if (MenuController.shown) MenuController.hideSlow(1.0);
						
						return;
					}
				}
				

				if (SETTINGS.MOUSECAPTURE_MODE) {
					document.getElementById('main').requestPointerLock(document.getElementById('main'));
				}
			
				this.pressedThisFrame = true;
				

				this.targetPosition.x = ((e.pageX/ AppStatus.innerWidth()) * 1.0-0.5)*this.cameraStretch.x;
				this.targetPosition.y = ((e.pageY/ AppStatus.innerHeight()) * 1.0-0.5)*this.cameraStretch.y;
				
				this.rawPosition.x = ((e.pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5;
				this.rawPosition.y = ((e.pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5;


				if (!SETTINGS.MOUSEGRAB_MODE) this.startPosition.copy(this.rawPosition);


				if (SETTINGS.TRACKPAD_MODE) {
					if (Utils.distance(this.rawPosition.x*1.1*this.cameraStretch.x, this.rawPosition.y*this.cameraStretch.y, 0.0, 0.0) >= 0.33) {
						this.trackpadMode = true;
					} else {
						this.trackpadMode = false;
					}

					if (this.trackpadMode) {
						this.rawPosition.x = 0.0;
						this.rawPosition.y = 0.0;
						this.targetPosition.x = 0.0;
						this.targetPosition.y = 0.0;
						this.trackPadModePc = 0.0;
						if (performance.now()-this.lastPressTime >= 666) this.currentPosition.copy(this.targetPosition);
					}
				}

				if (SETTINGS.MOUSEGRAB_MODE || SETTINGS.MOUSEGRAB2_MODE) {
					this.trackpadMode = true;
	
					if (this.trackpadMode && SETTINGS.MOUSEGRAB2_MODE) {
						this.rawPosition.x = 0.0;
						this.rawPosition.y = 0.0;
						this.targetPosition.x = 0.0;
						this.targetPosition.y = 0.0;
						this.trackPadModePc = 0.0;
						if (performance.now()-this.lastPressTime >= 666) this.currentPosition.copy(this.targetPosition);
					}
					if (SETTINGS.MOUSEGRAB2_MODE) {
						this.startPosition.set(
							((e.originalEvent.pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5,
							((e.originalEvent.pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5
						);
					}
				}


				if (!SETTINGS.MOUSEMOVE_MODE) {
					if (performance.now()-this.lastPressTime >= 666) this.currentPosition.copy(this.targetPosition);
					else this.currentPosition.lerp(this.targetPosition, 0.33);
				}
				this.lastPressTime = this.lastMouseMove = performance.now();
			})
			.on('mousemove', function(e) {
				var pressing = false;
				this.lastRealClick = performance.now();


				if (!this.isDragging) this.mouseMoveStart = performance.now();
				if ((SETTINGS.MOUSEGRAB_MODE || SETTINGS.MOUSEGRAB2_MODE || SETTINGS.MOUSEMOVE_MODE) && !AppStatus.isOnIntro && (SETTINGS.SCENE =="timeline" || !SETTINGS.SCENE || SETTINGS.SCENE=="editor")) {
					if (e.pageX < 50 && e.pageY > AppStatus.innerHeight()-50) {
						if (this.cursorShown !== 1) {
							$('#main').css('cursor', 'pointer');
							this.cursorShown = 1;
						}
					} else if (AudioController.pausedForMenu) {
						if (this.cursorShown!==2) {
							$('#main').css('cursor', 'auto');
							this.cursorShown = 2;
						}
					} else if ((e.pageX > 50 || e.pageY < AppStatus.innerHeight()-50)) {
						if (this.cursorShown!==0) {
							$('#main').css('cursor', 'none');
							this.cursorShown = 0;
						}
					}
				}


				if (SETTINGS.MOUSEMOVE_MODE || SETTINGS.MOUSEGRAB_MODE || SETTINGS.MOUSEGRAB2_MODE) {
					if (!this.isDragging) {
						pressing = true;
						this.pressedThisFrame = true;
						this.lastDragTime = performance.now();
						if (!SETTINGS.MOUSEMOVE_MODE) this.lastPressTime = performance.now();

						if ((SETTINGS.MOUSEGRAB_MODE || SETTINGS.MOUSEGRAB2_MODE) && !this.isDragging) {
							this.trackpadMode = true;
			
							if (this.trackpadMode) {
								// this.rawPosition.x *= 0.5;
								// this.rawPosition.y *= 0.5;
								// this.startPosition.copy(this.rawPosition);
								// this.targetPosition.x *= 0.5;
								// this.targetPosition.y *= 0.5;
								this.trackPadModePc = 1.0;

								this.startPosition.set(
									((e.originalEvent.pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5,
									((e.originalEvent.pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5
								);
								if (performance.now()-this.lastPressTime >= 666) this.currentPosition.copy(this.targetPosition);
							}
							if (SETTINGS.MOUSEGRAB2_MODE) {
								this.rawPosition.x = 0.0;
								this.rawPosition.y = 0.0;
								this.targetPosition.x = 0.0;
								this.targetPosition.y = 0.0;
								this.startPosition.set(
									((e.originalEvent.pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5,
									((e.originalEvent.pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5
								);
								this.currentPosition.set(0,0);
							}
						}


					}
					this.isDragging = true;
					this.lastMouseMove = performance.now();
					this.clickedOnce = true;	
				}


				if (this.trackpadMode) {

					this.rawPosition.x = ((e.originalEvent.pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5;
					this.rawPosition.y = ((e.originalEvent.pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5;

					var mdst = Utils.distance(this.rawPosition.x*this.cameraStretch.x, this.rawPosition.y*this.cameraStretch.y, 0.0, 0.0);
					// this.trackPadModePc = Math.max(
						// this.trackPadModePc, Utils.ccmap(mdst, 0.3,0.33,1.0,0.0));
					this.trackPadModePc = 1.0;

					var rx = ((e.originalEvent.pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5,
						ry = ((e.originalEvent.pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5;
					this.rawPosition.x = (rx-this.startPosition.x) * 2.0;
					this.rawPosition.y = (ry-this.startPosition.y) * 2.0;
					if (SETTINGS.MOUSEGRAB2_MODE || SETTINGS.MOUSEGRAB_MODE){
						this.rawPosition.x = (rx-this.startPosition.x) * 6.0;
						this.rawPosition.y = (ry-this.startPosition.y) * 6.0;
					}	

					// this.rawPosition.lerp(
					// 	new THREE.Vector2(((e.originalEvent.pageX/ AppStatus.innerWidth())*2.0-1.0), ((e.originalEvent.pageY/ AppStatus.innerHeight())*2.0-1.0)),
					// 	this.trackPadModePc);

					var nx = (this.rawPosition.x*0.5)*this.cameraStretch.x;
					var ny = (this.rawPosition.y*0.5)*this.cameraStretch.y;

					// this.rawPositionTarget.copy(this.rawPosition);

					this.currentDragSpeed.x += nx-this.targetPosition.x;
					this.currentDragSpeed.y += ny-this.targetPosition.y;
					this.targetPosition.set(nx, ny);
					return;
				}

				if (this.isDragging) {
					var nx = ((e.pageX/ AppStatus.innerWidth()) * 1.0-0.5)*this.cameraStretch.x;
					var ny = ((e.pageY/ AppStatus.innerHeight()) * 1.0-0.5)*this.cameraStretch.y;
					this.currentDragSpeed.x += nx-this.targetPosition.x;
					this.currentDragSpeed.y += ny-this.targetPosition.y;
					this.targetPosition.set(nx, ny);
				}
				this.rawPosition.x = ((e.pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5;
				this.rawPosition.y = ((e.pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5;
				// this.rawPositionTarget.copy(this.rawPosition);
				if (pressing) this.startPosition.copy(this.rawPosition);
			}.bind(this));
			$(window).mouseup((e)=>{
				this.mouseIsDown = false;
				this.releasedThisFrame = true;
				if (!SETTINGS.MOUSEMOVE_MODE && !SETTINGS.MOUSEGRAB_MODE && !SETTINGS.MOUSEGRAB2_MODE) {this.isDragging = false;this.releaseTime = performance.now();}
			}); //this.isDragging = false; 

	
		}
		
		$('#main').on('touchstart', function(e) {
			if (!this.isDragging) this.mouseMoveStart = performance.now();
		

			if (SETTINGS.VOLUME_BUTTON_ENABLED && !AppStatus.isOnLandingPage && !AppStatus.isOnEndPage) {
				if (e.originalEvent.touches[0].pageX > AppStatus.innerWidth() - 50) {

					console.log(e.originalEvent.touches[0].pageY);

					if (this.volumeButtonOpen && e.originalEvent.touches[0].pageY >= AppStatus.innerHeight()-152 && e.originalEvent.touches[0].pageY < AppStatus.innerHeight()-101) {
						this.volumePlusAnimation = 5;
						AppStatus.globalVolume = Utils.clamp(AppStatus.globalVolume+0.2, AppStatus.infoJson.installation_volume_min, AppStatus.infoJson.installation_volume_max);
						
						if (window.localStorage) {
							try {window.localStorage.setItem('volume', AppStatus.globalVolume.toString());}catch(er){}
						}
						return;

					} else if (this.volumeButtonOpen && e.originalEvent.touches[0].pageY >= AppStatus.innerHeight()-101 && e.originalEvent.touches[0].pageY < AppStatus.innerHeight()-58) {
						this.volumeMoinsAnimation = 5;
						AppStatus.globalVolume = Utils.clamp(AppStatus.globalVolume-0.2, AppStatus.infoJson.installation_volume_min, AppStatus.infoJson.installation_volume_max);
						if (window.localStorage) {
							try {window.localStorage.setItem('volume', AppStatus.globalVolume.toString());}catch(er){}
						}
						return;

					} else if (e.originalEvent.touches[0].pageY >= AppStatus.innerHeight()-58) {
						this.volumeButtonOpen = !this.volumeButtonOpen;
						this.volumeAnimation = 4;
						if (this.volumeButtonOpen) {
							this.volumePlusButton.visible = true;
							this.volumeMoinsButton.visible = true;
						} else {
							this.volumePlusButton.visible = false;
							this.volumeMoinsButton.visible = false;
						}
						return;
					} else if (this.volumeButtonOpen) {
						this.volumeButtonOpen = false;
						this.volumePlusButton.visible = false;
						this.volumeMoinsButton.visible = false;
					}

				} else if (this.volumeButtonOpen) {
					this.volumeButtonOpen = false;
					this.volumePlusButton.visible = false;
					this.volumeMoinsButton.visible = false;
				}
			}
			
			this.isDragging = true;
			this.mouseIsDown = true;
			this.lastRealClick = performance.now();


			if (!AppStatus.isOnLandingPage && !AppStatus.isOnEndPage && !AppStatus.isOnIntro) {
					
				if (!SETTINGS.MENU_DISABLED && e.originalEvent.touches[0].pageX < 50 && e.originalEvent.touches[0].pageY > AppStatus.innerHeight()-50) {
					if (!MenuController.shown) {
						MenuController.pause();
						MenuController.show();
						AudioController.pausedForMenu=true;
					} else {
						MenuController.resume();
						MenuController.hide();
						AudioController.pausedForMenu = false;
					}
					return;
				} else if (MenuController.infoPageOpen) {
					// if (AudioController.pausedForMenu) {
					// 	MenuController.resume();
					// 	AudioController.pausedForMenu = false;
					// }
					// if (MenuController.shown) MenuController.hideSlow(1.0);
					return;
				}
			}

			this.pressedThisFrame = true;


			this.rawPosition.x = ((e.originalEvent.touches[0].pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5;
			this.rawPosition.y = ((e.originalEvent.touches[0].pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5;
			this.startPosition.copy(this.rawPosition);

			this.targetPosition.x = ((e.originalEvent.touches[0].pageX/ AppStatus.innerWidth()) * 1.0-0.5)*this.cameraStretch.x;
			this.targetPosition.y = ((e.originalEvent.touches[0].pageY/ AppStatus.innerHeight()) * 1.0-0.5)*this.cameraStretch.y;

			if (performance.now()-this.lastPressTime >= 666) this.currentPosition.copy(this.targetPosition);
			else this.currentPosition.lerp(this.targetPosition, 0.33);
			this.lastPressTime = this.lastMouseMove = performance.now();


			if (SETTINGS.TRACKPAD_MODE || SETTINGS.TRACKPAD_MODE_2) {
				if (Utils.distance(this.rawPosition.x*1.1*this.cameraStretch.x, this.rawPosition.y*this.cameraStretch.y, 0.0, 0.0) >= 0.33) {
					this.trackpadMode = true;
				} else {
					this.trackpadMode = false;
				}

				if (this.trackpadMode) {
					this.rawPosition.x = 0.0;
					this.rawPosition.y = 0.0;
					this.targetPosition.x = 0.0;
					this.targetPosition.y = 0.0;
					this.trackPadModePc = 0.0;
					this.currentPosition.copy(this.targetPosition);
				}
			}
			// this.rawPositionTarget.copy(this.rawPosition);

		}.bind(this)).on('touchmove', function(e) {

			this.lastRealClick = performance.now();

			if (this.trackpadMode && SETTINGS.TRACKPAD_MODE) {

				this.rawPosition.x = ((e.originalEvent.touches[0].pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5;
				this.rawPosition.y = ((e.originalEvent.touches[0].pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5;

				var mdst = Utils.distance(this.rawPosition.x*this.cameraStretch.x, this.rawPosition.y*this.cameraStretch.y, 0.0, 0.0);
				this.trackPadModePc = Math.max(
					this.trackPadModePc, Utils.ccmap(mdst, 0.3,0.33,1.0,0.0));

				var rx = ((e.originalEvent.touches[0].pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5,
					ry = ((e.originalEvent.touches[0].pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5;
				this.rawPosition.x = (rx-this.startPosition.x) * 5.0;
				this.rawPosition.y = (ry-this.startPosition.y) * 7.0;
				

				this.rawPosition.lerp(
					new THREE.Vector2(((e.originalEvent.touches[0].pageX/ AppStatus.innerWidth())*2.0-1.0), ((e.originalEvent.touches[0].pageY/ AppStatus.innerHeight())*2.0-1.0)),
					this.trackPadModePc);

				var nx = (this.rawPosition.x*0.5)*this.cameraStretch.x;
				var ny = (this.rawPosition.y*0.5)*this.cameraStretch.y;



				this.currentDragSpeed.x += nx-this.targetPosition.x;
				this.currentDragSpeed.y += ny-this.targetPosition.y;
				this.targetPosition.set(nx, ny);
				// this.rawPositionTarget.copy(this.rawPosition);

				return;
			}

			else if (this.trackpadMode && SETTINGS.TRACKPAD_MODE_2) {

				this.rawPosition.x = ((e.originalEvent.touches[0].pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5;
				this.rawPosition.y = ((e.originalEvent.touches[0].pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5;

				var mdst = Utils.distance(this.rawPosition.x*this.cameraStretch.x, this.rawPosition.y*this.cameraStretch.y, 0.0, 0.0);
				this.trackPadModePc =  Utils.ccmap(mdst, 0.2,0.55,1.0,0.0);
				if (this.trackPadModePc >= 1.0) this.trackpadMode = false;

				var rx = ((e.originalEvent.touches[0].pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5,
					ry = ((e.originalEvent.touches[0].pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5;
				this.rawPosition.x = (this.startPosition.x-rx) * (4 + 5.0 * (1.0-this.trackPadModePc));
				this.rawPosition.y = (this.startPosition.y-ry) * (4 + 5.0 * (1.0-this.trackPadModePc));
				

				this.rawPosition.lerp( new THREE.Vector2(rx*2, ry*2),
					this.trackPadModePc);

				var nx = (this.rawPosition.x*0.5)*this.cameraStretch.x;
				var ny = (this.rawPosition.y*0.5)*this.cameraStretch.y;

				this.currentDragSpeed.x += nx-this.targetPosition.x;
				this.currentDragSpeed.y += ny-this.targetPosition.y;
				this.targetPosition.set(nx, ny);

				// this.rawPositionTarget.copy(this.rawPosition);
				return;
			}


			this.rawPosition.x = ((e.originalEvent.touches[0].pageX/ AppStatus.innerWidth())*2.0-1.0)*0.5;
			this.rawPosition.y = ((e.originalEvent.touches[0].pageY/ AppStatus.innerHeight())*2.0-1.0)*0.5;

			var nx = ((e.originalEvent.touches[0].pageX/ AppStatus.innerWidth()) * 1.0-0.5)*this.cameraStretch.x;
			var ny = ((e.originalEvent.touches[0].pageY/ AppStatus.innerHeight()) * 1.0-0.5)*this.cameraStretch.y;

			this.currentDragSpeed.x += nx-this.targetPosition.x;
			this.currentDragSpeed.y += ny-this.targetPosition.y;
			this.targetPosition.set(nx, ny);

			this.lastMouseMove = performance.now();
			// this.rawPositionTarget.copy(this.rawPosition);

		}.bind(this))
		$(window).on('touchend', (e)=>{
			this.lastRealClick = performance.now();
			this.releasedThisFrame = true;
			this.isDragging = false; this.releaseTime = performance.now(); this.mouseIsDown = false;
		});

	}


	startFrame() {

	}


	simulateClicks(delta) {

		if (!SETTINGS.SIMULATION_ENABLED) return;

		var now = performance.now();

		// if (!this.simulationStarted) {justClickPcElastic
		var startDelay = SETTINGS.TIMELINE_MODE ? 15000 : 5000; 

		//stop simulation, user is clicking
		if ((this.isDragging||InteractionController.isDragging) && !this.simulationIsDragging) this.lastRealClick = now;
		if (now-this.lastRealClick < startDelay || this.simulationModeLocked) {
			if (this.simulationStarted) {
				this.simulationClient.pressed = false;
				console.log("Stopping simulation!",now-this.lastRealClick);
				if (this.simulationModeLocked) {
					this.isDragging = false;
					this.mouseIsDown = false;
					this.pressedThisFrame = false;
					this.simulationIsDragging = false;
				}
			}
			this.simulationStarted = false;

			this.simulationClient.currentX = this.rawPosition.x;
			this.simulationClient.currentY = this.rawPosition.y;
			this.simulationIsDragging = false;
			return;

		} else {

			//start simulation
			this.simulationStarted = true;
			// this.simulationNextClick = now - 100;
			if (!this.simulationStarted) console.log("Starting simulation!");
		}


		//
		// update simulation
		//
		if (this.simulationStarted) {


			//start a new click 
			if (!this.isDragging) {

				//should avoid current human
				var userToAvoid = -100;
				if (this.simulationUseRealUser && this.simulationUser && now - this.simulationUserSelectTime >= 15000) {
					console.log("trying to follow someone else", this.simulationUser.id);
					userToAvoid = this.simulationUser.id;
					this.simulationNextClick = 0;
				}


				var currentHuman = null;
				var clientList = [];
				for (var id in CopresenceController.participants) {
					var client = CopresenceController.participants[id];
					if (client && !client.simulated && client.pressed && Math.abs(client.id - userToAvoid) > 0.1) {
						if (now-client.lastUpdate < 500) {
							clientList.push(client);
							if (this.simulationUser && Math.abs(client.id - this.simulationUser.id) < 0.1) {
								currentHuman = client;
							}
						}
					}
				}

				//~~try to find our human again if it clicks again before nextClick time
				var usingSameHuman = false;
				if (SETTINGS.REAL_HUMANS_SIMULATOR_ENABLED && this.simulationUseRealUser && currentHuman && this.simulationUser) {
					// console.log("Same human clicked again!");
					this.simulationNextClick = 0;
					clientList = [currentHuman];
					usingSameHuman = true;
				}

				//follow a user or simulation
				if (now > this.simulationNextClick) {
					this.simulationNextClick = now + 3000 + Math.random()*12000;

					// console.log("current client list:",clientList);

					//real user available, follow it
					//force simulate 33% of the time
					if (SETTINGS.REAL_HUMANS_SIMULATOR_ENABLED && clientList.length > 0) { // && Math.random() > 0.9
						var client = Utils.randoma(clientList);
						console.log("Following human!", client.id);

						if (!usingSameHuman) this.simulationUserSelectTime = now;
						this.simulationEndTime = now + 10000 + Math.random()*3000;
						this.simulationNextClick = this.simulationEndTime + 1000 + Math.random()*1500;
						this.simulationUseRealUser = true;
						this.simulationUser = client;

						this.simulationIsDragging = true;


						//copied from touchstart function
						if (!this.isDragging) this.mouseMoveStart = performance.now();
						this.isDragging = true;
						this.mouseIsDown = true;
						this.pressedThisFrame = true;
						this.rawPosition.x = this.simulationClient.currentX;
						this.rawPosition.y = this.simulationClient.currentY;
						this.startPosition.copy(this.rawPosition);


						// this.rawPosition.x = this.simulationUser.currentX;
						// this.rawPosition.y = this.simulationUser.currentY;
						this.targetPosition.x = this.simulationUser.currentX*this.cameraStretch.x;
						this.targetPosition.y = this.simulationUser.currentY*this.cameraStretch.y;
						this.simulationIsDragging = true;
						// this.currentPosition.copy(this.targetPosition);

						// for (var i=0; i<LINE_CURSOR_RESOLUTION; i++) {
						// 	this.currentLineCursorPos.lerp(this.rawPosition.clone().multiply(this.cameraStretch), 0.1);
						// 	this.lineCursorPositions.set(this.lineCursorPositions.subarray(0, -3), 3);
						// 	this.lineCursorPositions[0] = this.currentLineCursorPos.x;
						// 	this.lineCursorPositions[1] = this.currentLineCursorPos.y;
						// }

						// if (performance.now()-this.lastPressTime >= 666) this.currentPosition.copy(this.targetPosition);
						// else this.currentPosition.lerp(this.targetPosition, 0.33);
						// this.lastPressTime = this.lastMouseMove = performance.now();

					} else {
						if (this.simulationUseRealUser) {
							// this.currentPosition.copy(this.targetPosition);
							// for (var i=0; i<LINE_CURSOR_RESOLUTION; i++) {
							// 	this.currentLineCursorPos.lerp(this.rawPosition.clone().multiply(this.cameraStretch), 0.1);
							// 	this.lineCursorPositions.set(this.lineCursorPositions.subarray(0, -3), 3);
							// 	this.lineCursorPositions[0] = this.currentLineCursorPos.x;
							// 	this.lineCursorPositions[1] = this.currentLineCursorPos.y;
							// }
						}

						this.simulationUseRealUser = false;
					}

					
					//no real user, fake clicks
					if (!this.simulationUseRealUser) {
						// console.log("Simulation press!");

						this.simulationUseRealUser = false;
						this.simulationUser = null;

						this.simulationIsDragging = true;
						this.simulationEndTime = now + 3000 + Math.random()*4000;
						this.simulationNextClick = this.simulationEndTime + Math.random()*1500;
						if (Math.random()<0.5) this.simulationNextClick = this.simulationEndTime+1;


						//copied from touchstart function
						if (!this.isDragging) this.mouseMoveStart = performance.now();
						this.isDragging = true;
						this.mouseIsDown = true;
						this.pressedThisFrame = true;
						this.rawPosition.x = this.simulationClient.currentX;
						this.rawPosition.y = this.simulationClient.currentY;
						this.startPosition.copy(this.rawPosition);
						this.targetPosition.x = this.simulationClient.currentX*this.cameraStretch.x;
						this.targetPosition.y = this.simulationClient.currentY*this.cameraStretch.y;
						this.simulationIsDragging = true;

						if (performance.now()-this.lastPressTime >= 666) this.currentPosition.copy(this.targetPosition);
						else this.currentPosition.lerp(this.targetPosition, 0.33);
						this.lastPressTime = this.lastMouseMove = performance.now();
					}
				}

			}
		}

		//
		// Update simulated click
		//
		if (this.simulationIsDragging) {

			var client = this.simulationClient;

			client.time += delta * 1/60;

			client.nextSpeed = 0.42+0.42*this.rng(client.time/-1.17, 4.33);
			client.speed = Utils.clamp(0.125 + this.rng(-2.22, client.time/0.5)*0.105,0.001,1.0);


			client.nextPc += client.nextSpeed*0.05*delta;


			if (client.nextPc >= 1.0) {
				client.nextPc -= 1.0;
				client.oTx = client.nextTx;
				client.oTy = client.nextTy;
				client.nextTx = Math.pow(Math.random(),2.2)*0.33 * (Math.random()<0.5?-1:1);
				client.nextTy = Math.pow(Math.random(),2.2)*0.33 * (Math.random()<0.5?-1:1);
			}

			var nextTx = client.nextTx + (this.rng(client.time*0.425, 2.0))*0.125;
			var nextTy = client.nextTy + (this.rng(-1.0, client.time*0.432))*0.125;

			client.targetX = Utils.lerp(client.oTx, nextTx, client.nextPc);
			client.targetY = Utils.lerp(client.oTy, nextTy, client.nextPc);

			// AppStatus.log(client.nextSpeed, client.speed);
			// AppStatus.log(nextTx, nextTy);

			client.currentX = Utils.deltaSmoothingSnap2(
				client.currentX,
				client.targetX,
				client.speed,
				delta
			);

			client.currentY = Utils.deltaSmoothingSnap2(
				client.currentY,
				client.targetY,
				client.speed,
				delta
			);

			//
			// Update simulation drag
			//
			if (this.simulationIsDragging && !this.simulationUseRealUser) {

				//release
				if (now >= this.simulationEndTime) {
					// console.log("Simulation release");
					this.releasedThisFrame = true;
					this.isDragging = false;
					this.releaseTime = performance.now();
					this.mouseIsDown = false;
					this.simulationIsDragging = false;

				//mousemove
				} else {

					this.rawPosition.x = this.simulationClient.currentX;
					this.rawPosition.y = this.simulationClient.currentY;

					var nx = this.simulationClient.currentX*this.cameraStretch.x;
					var ny = this.simulationClient.currentY*this.cameraStretch.y;

					this.currentDragSpeed.x += nx-this.targetPosition.x;
					this.currentDragSpeed.y += ny-this.targetPosition.y;
					this.targetPosition.set(nx, ny);

					this.lastMouseMove = performance.now();
				}
			}

			//
			// Update real user
			//
			if (this.simulationIsDragging && this.simulationUseRealUser) {
					
				//human has released, set end/next time
				if (this.simulationUser && (this.simulationUser.simulated || !this.simulationUser.pressed || now-this.simulationUser.lastUpdate > 500) ) {
					// console.log("user released", this.simulationUser, this.simulationUser.pressed);
					this.simulationEndTime = now-1;
					this.simulationNextClick = Math.min(this.simulationNextClick, now+1000 + Math.random()*1500);

				}

				//force release after a while anyway
				if (now >= this.simulationEndTime) {
					// console.log("user force release after time");
					this.releasedThisFrame = true;
					this.isDragging = false;
					this.releaseTime = performance.now();
					this.mouseIsDown = false;
					this.simulationIsDragging = false;

					//after a 30 secondes, try to change current user, maybe
					// if (now - this.simulationUserSelectTime >= 30000) {
					// 	// this.simulationUser = null;
					// 	console.log("trying to follow someone else")
					// }

				//human is still active, mousemove towards target
				} else {

					this.rawPosition.x = Utils.deltaSmoothingSnap2(this.rawPosition.x, this.simulationUser.currentX, 0.95, delta);
					this.rawPosition.y = Utils.deltaSmoothingSnap2(this.rawPosition.y, this.simulationUser.currentY, 0.95, delta);
					// this.rawPosition.y = this.simulationUser.currentY;

					var nx = this.simulationUser.currentX*this.cameraStretch.x;
					var ny = this.simulationUser.currentY*this.cameraStretch.y;

					this.currentDragSpeed.x += nx-this.targetPosition.x;
					this.currentDragSpeed.y += ny-this.targetPosition.y;
					this.targetPosition.set(nx, ny);

					this.lastMouseMove = performance.now();

					//copy simulator there
					this.simulationClient.currentX = this.rawPosition.x;
					this.simulationClient.currentY = this.rawPosition.x;
					this.simulationClient.oTx = this.simulationUser.currentX;
					this.simulationClient.oTy = this.simulationUser.currentY;
					this.simulationClient.targetX = this.simulationUser.targetX;
					this.simulationClient.targetY = this.simulationUser.targetY;
					this.simulationClient.nextTx = this.simulationUser.targetX;
					this.simulationClient.nextTY = this.simulationUser.targetY;
				}
			}
		}
	}

	update(opt, progress, delta) {

		var now = performance.now();;


		this.simulateClicks(delta);


		// console.log(!SETTINGS.isMobile, this.isDragging, this.clickedOnce, now - this.lastMouseMove >= 500);
		if (!SETTINGS.isMobile && this.isDragging && !this.mouseIsDown && this.clickedOnce && now - this.lastMouseMove >= 500 && (SETTINGS.MOUSEMOVE_MODE||SETTINGS.MOUSEGRAB_MODE||SETTINGS.MOUSEGRAB2_MODE) && !this.simulationStarted) {
			this.isDragging = false;
			this.releaseTime = performance.now();
			this.releasedThisFrame = true;
		}

		// var timeDelta = (now - this.lastTime) / 1000;
		// this.lastTime = now;
		// this.currentTime += timeDelta;
		this.oignonFrames = Utils.clamp(this.oignonFrames, 1, OIGNON_MAX_FRAME-1);


		for (var i=this.lastOignonFrames; i<this.oignonFrames+2; i++) {
			var id = Math.min(Math.floor(i),this.fbos.length-1);
			Utils.clearFboAlpha(this.fbos[id].texture);
			// console.log("clear",id);
		}
		this.lastOignonFrames = this.oignonFrames+2;


		//update ui
		this.pauseButton.material.map = AudioController.pausedForMenu ? this.playButtonTexture : this.pauseButtonTexture;
		if (!AppStatus.isOnLandingPage && this.pauseButton.material.opacity < 1.0) this.pauseButton.material.opacity = Utils.deltaSmoothingSnap2(this.pauseButton.material.opacity, 1.0, 0.05, delta);
		this.pauseButton.material.color.setRGB(this.pauseButtonColor, this.pauseButtonColor, this.pauseButtonColor);


		if (SETTINGS.VOLUME_BUTTON_ENABLED) {
			this.volumeAnimation--;
			var w = this.pauseButtonColor * (this.volumeAnimation>0?0.5:1);
			if (this.pauseButtonColor<0.5) w = this.pauseButtonColor + (this.volumeAnimation>0?0.5:0);
			this.volumeButton.material.color.setRGB(w,w,w);

			this.volumePlusAnimation--;
			if (AppStatus.globalVolume >= AppStatus.infoJson.installation_volume_max) this.volumePlusAnimation = 1;
			w = this.pauseButtonColor * (this.volumePlusAnimation>0?0.5:1);
			if (this.pauseButtonColor<0.5) w = this.pauseButtonColor + (this.volumePlusAnimation>0?0.5:0);
			this.volumePlusButton.material.color.setRGB(w,w,w);


			this.volumeMoinsAnimation--;
			if (AppStatus.globalVolume <= AppStatus.infoJson.installation_volume_min) this.volumeMoinsAnimation = 1;
			w = this.pauseButtonColor * (this.volumeMoinsAnimation>0?0.5:1.0);
			if (this.pauseButtonColor<0.5) w = this.pauseButtonColor + (this.volumeMoinsAnimation>0?0.5:0);
			this.volumeMoinsButton.material.color.setRGB(w,w,w);
		}

		// if (SETTINGS.INSTALLATION_MODE) {
		// 	this.pauseButton.scale.y = this.pauseButton.scale.x * (AudioController.pausedForMenu?1.0:1.25);
		// 	this.pauseButton.position.x = 32 + (AudioController.pausedForMenu?3:0);
		// 	this.pauseButton.position.y = AppStatus.innerHeight()-32 - (AudioController.pausedForMenu?2:2);
		// }

		// for (var i = 0; i < this.allLines.length; i++) {
		// 	this.allLines[i].visible = false;
		// this.allLines[i].material.uniforms.lineWidth.value = this.lineWidth*0.003;
		// }
		// console.log(AppStatus.currentFrame);
		this.currentLine = 0;
		this.currentIdx = 0;
		this.currentChiffreIdx = 0;
		this.currentEyesIdx = 0;

		// this.lineMaterial.lineWidth = this.lineWidth*0.003;
		// console.log(this.lineWidth);

		// //update
		// this.currentPosition.lerp(this.targetPosition,delta*0.075);
		// this.currentDragSpeed.lerp(new THREE.Vector2(0,0,0), delta*0.05);

		//standard press
		
		//update
		this.currentPosition.lerp(this.targetPosition,delta*0.15);
		this.currentDragSpeed.lerp(new THREE.Vector2(0,0,0), delta*0.05);


		if (this.pressedThisFrame) this.clickPcTarget += 0.75;
		this.clickPcTarget = Utils.clamp(this.clickPcTarget, 0.0, 1.0);
		this.clickPcTarget = Utils.deltaSmoothingSnap2(this.clickPcTarget, 0.0, this.isDragging?0.1:0.05, delta);
		this.clickPc = Utils.deltaSmoothingSnap2(this.clickPc, this.clickPcTarget, 0.1, delta);



		//elastic
		if (SETTINGS.isMobile || !SETTINGS.MOUSEMOVE_MODE) {

			//standard press
			this.actualPressPc = Utils.deltaSmoothingSnap2(this.actualPressPc, this.isDragging?1.0:0.0, 0.05, delta);
			
			//elastic
			if (this.isDragging) {
				this.pressPcSpeed = Utils.deltaSmoothingSnap2(this.pressPcSpeed, this.isDragging?1.0:0.0, 0.05, delta);
				this.actualPressPcElastic = Utils.deltaSmoothingSnap2(this.actualPressPcElastic, Easing['Sinusoidal'].In(this.pressPcSpeed), 0.5, delta);
			} else {
				this.pressPcSpeed = Utils.deltaSmoothingSnap2(this.pressPcSpeed, this.isDragging?1.0:0.0, 0.005 + this.pressPcSpeed * 0.01, delta);
				this.actualPressPcElastic = Utils.deltaSmoothingSnap2(this.actualPressPcElastic, Easing['Elastic'].In(this.pressPcSpeed), 0.5, delta);
			}

			if (SETTINGS.CLICK_WAVE_MODE) {
				this.actualPressPc *= (1.0-this.clickPcTarget);
				this.actualPressPcElastic *= (1.0-this.clickPcTarget);
			}

		} else {

			var target = Utils.ccmap(now- this.lastMouseMove, 0, 2000, 1.0, 0.0);
			target *= Utils.cmap(this.currentDragSpeed.length(),0.0,0.1,0.0,1.0);

			if (SETTINGS.CLICK_WAVE_MODE) {
				target *= (1.0-this.clickPcTarget);
			}

			var speed = Utils.ccmap(now- this.lastMouseMove, 0, 2000, 0.01, 0.06);
			this.actualPressPc = Utils.deltaSmoothingSnap2(this.actualPressPc, target, speed, delta);


			// var elasticTarget = now - this.lastMouseMove > 500 ? Easing['Elastic'].In(this.pressPcSpeed):Easing['Sinusoidal'].In(this.pressPcSpeed); //Easing['Sinusoidal'].In(this.pressPcSpeed));

			var elasticTarget = Utils.cmap(
				Math.pow(Utils.ccmap((now - this.lastMouseMove),0,1400,0,1),0.33), 1.0, 0.0,
				Easing['Elastic'].In(this.pressPcSpeed), Easing['Sinusoidal'].In(this.pressPcSpeed)); //Easing['Sinusoidal'].In(this.pressPcSpeed));

			if (SETTINGS.CLICK_WAVE_MODE) {
				target *= (1.0-this.clickPcTarget);
			}
			// console.log(Math.pow(Utils.cmap((now - this.lastMouseMove),0,500,0,1),0.33));

			target = Utils.ccmap(now- this.lastMouseMove, 0, 2000, 1.0, 0.0);
			target *= Utils.cmap(this.currentDragSpeed.length(),0.0,0.15,0.0,1.0);

			speed *= Utils.cmap(this.pressPcElastic,0.0,1.0,1.0,0.33);








			this.pressPcSpeed = Utils.deltaSmoothingSnap2(this.pressPcSpeed, target, speed, delta);

			// console.log(Math.pow(target,0.5), Easing['Elastic'].In(this.pressPcSpeed), Easing['Sinusoidal'].In(this.pressPcSpeed));
			// console.log(this.pressPcSpeed>this.pressPcElastic?'Elastic':'Sinusoidal');
			// this.pressPcElastic = Easing[this.pressPc>target?'Elastic':'Sinusoidal'].In(this.pressPcSpeed);
			this.actualPressPcElastic =  Utils.deltaSmoothingSnap2(this.actualPressPcElastic, elasticTarget, 0.3-target*0.25, delta);



			// console.log(this.pressPcSpeed, target);
			// this.pressPcSpeed = Utils.deltaSmoothingSnap2(this.pressPcSpeed, 0.0,  Math.pow(Utils.ccmap(now - this.lastMouseMove, 0, 500,0.0,1.0),2.0)*0.05, delta);
			// // this.pressPcElastic = Utils.deltaSmoothingSnap2(this.pressPcElastic, 0.0, Math.pow(Utils.ccmap(now - this.lastMouseMove, 0, 500,0.0,1.0),1.0)*0.05, delta);
			// this.pressPcElastic = Utils.deltaSmoothingSnap2(this.pressPcElastic, Easing['Elastic'].In(this.pressPcSpeed), 0.5, delta);

		}


		// if (this.isDragging) {
			this.sidesDistanceTarget = Utils.ccmap(Utils.distance(this.rawPosition.x*this.cameraStretch.x, this.rawPosition.y*this.cameraStretch.y, 0.0, 0.0),0.25,0.6,0.0,1.0);
			// this.sidesDistanceTarget *= Utils.cmap(now-this.justClickPressTime, 0, 2000, 0.5, 1.0);
			this.sidesDistance = Utils.deltaSmoothingSnap2(this.sidesDistance, this.sidesDistanceTarget, 0.05, delta);
		// }
		// this.sidesDistance = 0.0;

		var justClickPcElastic = this.actualPressPcElastic;
		var justClickPc = 0;

		if (SETTINGS.CLICK_MODE) {
			if (this.pressedThisFrame) {
				if (now-this.justClickPressTime < 750) {
					this.multiClickPc += 0.33;
				} else {
					this.multiClickPc -= 0.1;
				}
				if (now-this.justClickPressTime < 666) this.justClickPressTime = this.justClickPressTime+150;
				else this.justClickPressTime = this.lastPressTime;
				this.clickMode = true;	
				this.multiClickPc = Utils.clamp(this.multiClickPc, 0.0, 1.0);
			}

			this.multiClickPc = Utils.deltaSmoothingSnap2(this.multiClickPc,0.0, this.clickMode?0.01:0.02, delta);

			var timePcRaw = Utils.cmap(now-this.justClickPressTime, 0, 1200, 0.05, 1.0);
			var timePc = Math.pow(timePcRaw,0.4);
			justClickPc = Math.sin(timePc*Math.PI)*Math.pow(Utils.ccmap(timePc, 0.5,1.0,1.0,0.0),2.0) * (0.5 + this.multiClickPc*0.33);


			var dragging = (SETTINGS.MOUSEMOVE_MODE&& !this.simulationStarted) ? this.mouseIsDown : this.isDragging;

			if (this.clickMode && dragging && timePc > 0.5 ) { //&& this.actualPressPc>justClickPc
				this.clickMode = false;
				this.actualPressPc = Math.max(this.actualPressPc, justClickPc+0.05);; //Utils.deltaSmoothingSnap2(this.actualPressPc, 1.0, 0.05, delta);
				// this.actualPressPcElastic = Math.max(this.actualPressPcElastic, justClickPc+0.05);; //Utils.deltaSmoothingSnap2(this.actualPressPc, 1.0, 0.05, delta);
			}
			if (!this.clickMode) justClickPc = 0.0;
			if (!SETTINGS.CLICK_MODE) justClickPc = 0.0;

			this.actualPressPcElastic = this.actualPressPc;
			// this.pressPcElastic = this.pressPc;


			//--------------
			//
			// Elastic click mode
			//
			//---------------
			timePcRaw = Utils.cmap(now-this.justClickPressTime, 0, 1200, 0.05, 1.0);
			timePc = Math.pow(timePcRaw,0.33);

			var elasticTarget = timePc; //Math.sin(timePc*Math.PI)*(1.0-timePcRaw); // * (0.5 + this.multiClickPc*0.5);
			if (this.isDragging) {
				this.elastic2 = elasticTarget; //Math.max(elasticTarget, Utils.cmap(now-this.justClickPressTime, 0, 2000, 0.0, 1.0));
				
			} else {
					
				var releaseTimePcRaw = Utils.ccmap(now-this.releaseTime, 0, 1000, 0.0, 1.0);
				var releaseTimePc = Math.pow(releaseTimePcRaw,2.0);

				// this.elastic2 = Utils.ccmap(
				// 	releaseTimePcRaw,
				// 	0.5,0.75,
				// 	elasticTarget,
				// 	Math.sin(releaseTimePcRaw, )
				// );

				// if (releaseTimePc < 0.75) {console.log(elasticTarget, Utils.ccmap(releaseTimePc, 0.0, 0.75,1.0,0.0)); this.elastic2 = elasticTarget*Utils.ccmap(releaseTimePc, 0.0, 0.75,1.0,0.0);}
				// else if (releaseTimePc < 0.9) {console.log(elasticTarget, Utils.ccmap(releaseTimePc, 0.75, 0.9,0.0,0.25));  this.elastic2 = elasticTarget*Utils.ccmap(releaseTimePc, 0.75, 0.9,0.0,-2.0);}
				// else if (releaseTimePc < 1.0) this.elastic2 = elasticTarget*Utils.ccmap(releaseTimePc, 0.9, 1.0,0.25,0.0);
				// else this.elastic2 = 0.0;

				 this.elastic2 = Math.abs(elasticTarget * Math.sin(Math.PI/2 + releaseTimePc * Math.PI * 2.25) * (1.0-Math.pow(releaseTimePcRaw,0.75)));
				
			}

			
			if (!this.clickMode) {justClickPcElastic = justClickPc = 0.0;}
			if (!SETTINGS.CLICK_MODE) {justClickPcElastic = justClickPc = 0.0;}

			this.actualPressPcElastic = this.elastic2;
		}
		


		//elastic
		var clickPc = Utils.ccmap(now-this.justClickPressTime, 0, 500, 1, 0);
		var dragCurveElastic = Utils.ccmap(now-this.mouseMoveStart, 0.0, (2500-this.currentDragSpeed.length()*100  - clickPc*500)*InteractionController.ELASTIC_TIME, clickPc*0.45, 1.0 - clickPc*0.1) * (this.isDragging ? 1 : Utils.ccmap(now - this.releaseTime, 0, 1750*InteractionController.ELASTIC_TIME, 1, 0));
		var releaseTimePcRaw =this.isDragging ? 0.0 : Utils.ccmap(now-this.releaseTime, 0, 1750*InteractionController.ELASTIC_TIME, 0.0, 1.0);
		var releaseTimePc = Math.pow(releaseTimePcRaw, InteractionController.ELASTIC_PRESS_CURVE);
		var dragPcElastic = (dragCurveElastic * Math.sin(Math.PI/2 + releaseTimePc * Math.PI * InteractionController.ELASTIC_BOUNCES) * 0.75 * (1.0-Math.pow(releaseTimePcRaw,InteractionController.ELASTIC_RELEASE_CURVE)));
		this.pressPcElastic =  Utils.deltaSmoothingSnap2(this.pressPcElastic, dragPcElastic, 0.15, delta);
		this.pressPc = Math.max(this.actualPressPc, justClickPc);


		// this.rawPosition.lerp(this.rawPosition, 0.03*delta);
		

		if (SETTINGS.MOUSEMOVE_MODE && !this.simulationStarted) {
			InteractionController.cameraStretch.copy(this.cameraStretch)
			InteractionController.update(delta);

			this.currentPosition.copy(InteractionController.currentPosition);
			this.rawPosition.copy(InteractionController.rawPosition);
			this.currentDragSpeed.copy(InteractionController.currentDragSpeed);
			this.pressPc = InteractionController.pressPc;
			this.pressPcElastic = InteractionController.pressPcElastic;
			this.isDragging = InteractionController.isDragging;
			this.mouseIsDown = InteractionController.mouseIsDown;
			this.pressedThisFrame = InteractionController.pressedThisFrame;
		}
		if (SETTINGS.RANDOM_CURSOR) {
			// this.pressPc = 1.0;
			// this.isDragging = true;

			if (this.pressedThisFrame) {
				this.randomCursorMultiClickPc += 0.5;
			}
			this.randomCursorMultiClickPc = Utils.deltaSmoothingSnap2(this.randomCursorMultiClickPc,0.0,0.02,delta);

			if (this.simulationStarted) this.randomCursorMultiClickPc *= 0.9;
			var mcPc = Math.pow(Utils.cmap(this.randomCursorMultiClickPc, 0.5,2.0,0.0,1.0),2.0);


			this.staticDragPc += Utils.ccmap(this.currentDragSpeed.length(), 0.0, 0.1, 1.0, 0.0) * delta * 0.65/60;
			this.staticDragPc -= Utils.ccmap(this.currentDragSpeed.length(), 0.02, 0.125, 0.0, 3.0) * delta * 1/60;
			if (this.simulationStarted) this.staticDragPc *= 0.9;


			if (SETTINGS.MOUSEMOVE_MODE && !this.simulationStarted && !this.mouseIsDown) this.staticDragPc *= 0.95;
			if (this.pressedThisFrame) this.staticDragPc *= 0.95;
			this.staticDragPc = Utils.clamp(this.staticDragPc, 0.0, 1.0);
			this.staticDragPc = Utils.deltaSmoothingSnap2(this.staticDragPc, 0.0, 0.005, delta);
			if (!this.isDragging) this.staticDragPc = Utils.deltaSmoothingSnap2(this.staticDragPc, 0.0, 0.05, delta);

			var sdPc = Math.pow(Utils.cmap(this.staticDragPc,0.0,1.0,0.0,1.0),1.0);
			// AppStatus.log(this.staticDragPc, sdPc);
			// mcPc = Math.max(, mcPc);
			// this.randomCursorTime = 0.0;
			// this.staticDragPc = 0.0;
			// this.randomCursorMultiClickPc = 0.0;

			var mcPc2 = mcPc + sdPc;




			// if (SETTINGS.RANDOM_CURSOR_LENT) this.randomCursorTime += delta * 1/60 * (0.5+ mcPc);
			// else this.randomCursorTime += delta * 3/60 * (0.5+ mcPc);
			this.randomCursorTime += delta * 1/60 * (0.5+ mcPc*0.75);

			Utils.tmpVector2.set(this.rng(this.randomCursorTime, 2.0) * mcPc2 * 0.5,  this.rng(-this.randomCursorTime, -2.0) * mcPc2 * 0.5);
			Utils.tmpVector2.x += this.rawPosition.x*this.cameraStretch.x;
			Utils.tmpVector2.y += this.rawPosition.y*this.cameraStretch.y;
			Utils.tmpVector2.multiplyScalar(0.75);

			var randomAngle = this.rng(this.randomCursorTime*2.0, 3.0) * Math.PI*2.0;

			var staticTarget = new THREE.Vector2(
				this.currentPosition.x + Math.sin(this.randomCursorTime*70.0) * Math.cos(randomAngle) * sdPc*0.01,
				this.currentPosition.y + Math.sin(this.randomCursorTime*70.0) * Math.sin(randomAngle) * sdPc*0.01
			);
			// Utils.tmpVector2.lerp(staticTarget, sdPc* (1.0-mcPc));

			this.randomCursorTarget.copy(staticTarget); //, delta*0.15 + sdPc*0.1);
					

			if (this.simulationStarted) this.randomCursorPc = 0.0;
			if (this.simulationStarted) this.staticDragPc *= 0.9;
			if (this.simulationStarted) this.randomCursorPc = 0.0;
			if (SETTINGS.RANDOM_CURSOR) this.currentPosition.lerp(this.randomCursorTarget, this.pressPc *  sdPc * (1.0-mcPc) * this.randomCursorPc);

			this.powerEffectPc = mcPc;
		}

		if (this.simulationStarted) this.powerEffectPc = 0.0;

		if (AudioController.pausedForMenu) {
			this.powerEffectPc = 0.0;
			this.pressPc *= 0.1;
			this.pressPcElastic *= 0.1;
			InteractionController.pressPc *= 0.1;
			InteractionController.pressPcElastic *= 0.1;
			this.isDragging = false;
			this.staticDragPc = 0.0;
			this.currentDragSpeed.x *= 0.1;
			this.currentDragSpeed.y *= 0.1;

			InteractionController.currentDragSpeed.x *= 0.1;
			InteractionController.currentDragSpeed.y *= 0.1;

			this.pressedThisFrame = false;
		}



		//
		// Update passiveness
		//
		this.passivePc = Utils.deltaSmoothingSnap2(this.passivePc, 1.0, 0.0015, delta);
		if (this.pressedThisFrame) this.passivePc -= 0.5;
		this.passivePc -= this.pressPc * 0.008 * delta;
		if (this.isDragging) this.passivePc -=  0.008 * delta; else this.passivePc += 0.001*delta;
		this.passivePc = Utils.clamp(this.passivePc, 0.0, 1.0);
		if (SETTINGS.FORCE_PASSIVE) this.passivePc = 1.0;

		//background
		this.pulseTime += delta * this.pulseSpeed * 1/60;
		this.gradientTime  += delta * this.gradientSpeed * 1/60;
		this.noiseTime = now/1000;


		this.gradientTime += justClickPc * delta * 3/60;
		this.gradientTime += Math.pow(Utils.ccmap(now-this.lastPressTime,0,1000,1.0,0.0),1.5) * delta * 10/60;


		this.backgroundMaterial.uniforms.gradientTime.value = this.gradientTime;
		this.backgroundMaterial.uniforms.pulseTime.value = this.pulseTime;
		this.backgroundMaterial.uniforms.noiseTime.value = this.noiseTime;
		this.backgroundMaterial.uniforms.randomOffset.value.set(Math.random()*1000.0, Math.random()*1000.0);

		// Utils.autoReloadShaderManual(this.backgroundMaterial, 'shaders/effects/pulseGradient.vert', 'shaders/effects/pulseGradient3.frag')


		//effects
		if (this.oignonSkinEnabled) {
			if (now - this.lastOignon >= 1000/this.oignonFps) {
				this.lastOignon = now;
				var fbo = this.fbos.splice(Math.min(Math.ceil(this.oignonFrames),this.fbos.length-1), 1)[0];
				this.fbos.unshift(fbo);
			}
		}

		if (this.oignonSkinVectorEnabled) {
			if (now - this.lastOignonVector >= 1000/this.oignonVectorFps) {
				this.lastOignonVector = now;
				var f = this.oignonVectorInfo.splice(Math.min(Math.ceil(this.oignonVectorFrames),this.oignonVectorInfo.length-1), 1)[0];
				this.oignonVectorInfo.unshift(f);
				this.currentOignonVectorFrame = f;
				f.randomBrush.set(Math.random()*1.0-0.5, Math.random()*1.0-0.5);
			}

			//clean frame
			var f = this.currentOignonVectorFrame;
			// for (var i=0; i<f.positions.length; i++) {
			// this.oignonPositionPool.push(f.positions[i]);
			// }
			// f.positions = [];
			f.positionLength = 0;
			f.materials = null;
			f.uniforms = null;
		}

		//emboss
		if (this.embossEnabled) {
			this.useEffectsFbo = true;
			this.embossMaterial.uniforms.tDiffuse.value = this.effectsFbo.texture.texture;
			this.embossMaterial.uniforms.embossAlpha.value = this.embossAlpha;
			this.embossMaterial.uniforms.px1.value.set( this.embossDst / this.effectsFbo.width,  this.embossDst / this.effectsFbo.height)

		} else {
			this.useEffectsFbo = false;
		}


		//digital noise effect
		this.digitalEnabled  = opt && opt.effects && opt.effects.digitalPrototype && opt.effects.digitalPrototype.enabled;
		// this.targetFbo.pingPong();
		if (this.digitalEnabled) {
			this.digitalMaterial.uniforms.tDiffuse.value = this.targetFbo.texture.texture;
			// this.digitalMaterial.uniforms.tLast.value = this.targetFbo.getPong().texture;
			this.digitalMaterial.uniforms.tNoise.value = this.noiseTexture;
			this.digitalMaterial.uniforms.time.value = now / 1000;
			this.digitalMaterial.uniforms.randomOffset.value.set(Math.random(), Math.random());
			this.digitalMaterial.uniforms.randomOffset2.value.set(Math.random(), Math.random());

			this.digitalMaterial.uniforms.alpha.value = opt.effects.digitalPrototype.alpha;

			if (SETTINGS.INSTALLATION_MODE || SETTINGS.isTablet) {
				this.digitalMaterial.uniforms.alpha.value *= 0.75;
			} 

			this.digitalMaterial.uniforms.noiseA.value = opt.effects.digitalPrototype.gridA*opt.effects.digitalPrototype.alpha;
			this.digitalMaterial.uniforms.noiseB.value = opt.effects.digitalPrototype.noiseB*opt.effects.digitalPrototype.alpha;
			this.digitalMaterial.uniforms.noiseC.value = opt.effects.digitalPrototype.gridC*opt.effects.digitalPrototype.alpha;
			this.digitalMaterial.uniforms.noiseD.value = opt.effects.digitalPrototype.radialD*opt.effects.digitalPrototype.alpha;
			this.digitalMaterial.uniforms.noiseE.value = opt.effects.digitalPrototype.radialE*opt.effects.digitalPrototype.alpha;
			this.digitalMaterial.uniforms.noiseF.value = opt.effects.digitalPrototype.gridF*opt.effects.digitalPrototype.alpha;
			// this.digitalMaterial.uniforms.noiseG.value = opt.effects.digitalPrototype.accumG*opt.effects.digitalPrototype.alpha;
			// this.digitalMaterial.uniforms.noiseH.value = opt.effects.digitalPrototype.accumNoiseH*opt.effects.digitalPrototype.alpha;

			this.digitalMaterial.uniforms.colorA.value = opt.effects.digitalPrototype.colorA*opt.effects.digitalPrototype.alpha;
			this.digitalMaterial.uniforms.colorB.value = opt.effects.digitalPrototype.colorB*opt.effects.digitalPrototype.alpha;
			this.digitalMaterial.uniforms.radial.value = opt.effects.digitalPrototype.radial*opt.effects.digitalPrototype.alpha;

			this.digitalMaterial.uniforms.resolution.value.set(renderer.domElement.width, renderer.domElement.height);
			// Utils.autoReloadShaderManual(this.digitalMaterial, 'shaders/effects/digital.vert', 'shaders/effects/digital.frag')
		}
		this.noiseMaterial.uniforms.tDiffuse.value = this.targetFbo.texture.texture;
		this.noiseMaterial.uniforms.tNoise.value = this.noiseTexture;
		this.noiseMaterial.uniforms.randomOffset.value.set(Math.random(), Math.random());
		this.noiseMaterial.uniforms.resolution.value.set(renderer.domElement.width, renderer.domElement.height); //.normalize();
		this.noiseMaterial.uniforms.noisePc.value = AppStatus.infoJson.noiseGlobal;
		


		// Utils.autoReloadShaderManual(this.noiseMaterial, 'shaders/effects/noise.vert',  'shaders/effects/noise.frag');

		// if (SETTINGS.MOUSEMOVE_MODE) {

		while (now-this.lastLineCursorTime >= 1000/60) {
			this.lastLineCursorTime = Math.max(this.lastLineCursorTime, now-1000/30);
			this.lastLineCursorTime += 1000/120;

			this.currentLineCursorPos.lerp(this.rawPosition.clone().multiply(this.cameraStretch), 0.1);
			this.lineCursorPositions.set(this.lineCursorPositions.subarray(0, -3), 3);
			this.lineCursorPositions[0] = this.currentLineCursorPos.x;
			this.lineCursorPositions[1] = this.currentLineCursorPos.y;
		}

		this.currentLineCursorPos.lerp(this.rawPosition.clone().multiply(this.cameraStretch), 0.1*delta);
		this.lineCursorPositions[0] = this.currentLineCursorPos.x;
		this.lineCursorPositions[1] = this.currentLineCursorPos.y;

		this.lineCursor.geometry.needsUpdate = true;
		this.lineCursor.material = this.line.material;
		this.lineCursor.visible = !AudioController.pausedForMenu;

		if (SETTINGS.SIMULATION_ENABLED && this.simulationModeLocked && !this.mouseIsDown && !this.isDragging) {
			this.lineCursor.visible = false;
		}



			this.copresenceEnabledPc = Utils.deltaSmoothingSnap2(this.copresenceEnabledPc, this.copresenceEnabled?1:0, 0.075, delta);
			if (MenuController.pausedForMenu) this.copresenceEnabledPc = 0;
			if (this.copresenceEnabled || this.copresenceEnabledPc>0.005) {
				if (this.lineCursor.currentId !== CopresenceController.wsId) {
					this.lineCursor.currentId = CopresenceController.wsId;

					// this.copresenceContext.clearRect(0, 0, this.copresenceCanvas.width, this.copresenceCanvas.height);
					this.copresenceContext.fillStyle = '#000000';
					this.copresenceContext.fillRect(0,0, this.copresenceCanvas.width, this.copresenceCanvas.width);

					this.copresenceContext.font = '500 '+(SETTINGS.isMobile?'18pt':'18pt')+" 'Work Sans'";
					this.copresenceContext.textAlign = 'center';
					this.copresenceContext.fillStyle = 'white';
					this.copresenceContext.strokeStyle = 'white';
					// this.copresenceContext.globalAlpha = 1.0;
					// this.copresenceContext.globalCompositeOperation = 'copy';

					// this.copresenceContext.fillRect(0,0,128,128);
					// this.copresenceContext.fillAlpha = 1.0;
					this.copresenceContext.fillText('Participant'+(SETTINGS.LANGUAGE=='fr'?'·e':'')+' #'+CopresenceController.wsId, this.copresenceCanvas.width/2,this.copresenceCanvas.height/2);
					// this.copresenceContext.fillText('Participant #'+CopresenceController.wsId, this.copresenceCanvas.width/2,this.copresenceCanvas.height/2);
					// this.copresenceContext.fillText('Participant #'+CopresenceController.wsId, this.copresenceCanvas.width/2,this.copresenceCanvas.height/2);
					// this.copresenceContext.fillText('Participant #'+CopresenceController.wsId, this.copresenceCanvas.width/2,this.copresenceCanvas.height/2);

					this.lineCursor.planeTexture.needsUpdate = true;
					Utils.uploadTexture(this.lineCursor.planeTexture);
				}
				this.lineCursor.plane.visible = !this.isOverOtherDiv && !AudioController.pausedForMenu;
				this.lineCursor.plane.position.x = (this.rawPosition.x);// * this.cameraStretch.x;
				this.lineCursor.plane.position.y = (-this.rawPosition.y) + (SETTINGS.isMobile?(32/AppStatus.innerHeight()):0);// * this.cameraStretch.y;
				// this.lineCursor.plane.material.uniforms.palette.value.setRGB(0,0,0);
				this.lineCursor.plane.scale.set(128 * 1/AppStatus.innerWidth(),64* 1/AppStatus.innerHeight(),1);

				var bw =  (this.oignonColor * (this.oignonSkinEnabled ? 1 : 0))>0.2 ? 1.0 : 0.0;
				this.lineCursor.plane.material.uniforms.palette.value.setRGB(bw, bw, bw);

				if (!SETTINGS.isMobile) {
					this.lineCursor.plane.material.opacity = this.copresenceEnabledPc;
				} else {
					this.lineCursor.plane.material.opacity = Utils.deltaSmoothingSnap2(
						this.lineCursor.plane.material.opacity, (this.isDragging||now-this.lastPressTime<500)?this.copresenceEnabledPc:0, 0.2,delta);
				}

				//palette

			}
			
			// this.lineCursor.material.uniforms.resolution.value.set(AppStatus.innerWidth(), AppStatus.innerHeight());
			// this.lineCursor.material.uniforms.color.value.copy( this.strokeColor ) ;
			// this.lineCursor.material.uniforms.lineWidth.value = this.lineWidth*SETTINGS.BASE_LINEWIDTH;
			// this.lineCursor.material.uniforms.ratio.value = renderer.domElement.height/renderer.domElement.width;
			// if (this.lineCursor.material.uniforms.pixelRatio) this.lineCursor.material.uniforms.pixelRatio.value = window.devicePixelRatio||1;
			this.lineCursor.geometry.instanceCount = Math.floor(this.lineCursorPositions.length/3 - 2);
			// console.log(this.lineCursor.geometry.instanceCount);
			this.lineCursor.geometry.setPositions(this.lineCursorPositions);
		// }



		if (this.copresenceEnabled || this.copresenceEnabledPc>0.005) {

				var startIndex = Utils.clamp(Math.ceil(this.oignonFrames), 0, OIGNON_MAX_FRAME);
				var paletteA = AppStatus.infoJson.oignon_palettes[this.paletteA];
				var paletteB = AppStatus.infoJson.oignon_palettes[this.paletteB];
				var actualPalette = [];
				var n = 0;
				for (var i=startIndex; i>=0; i-=(this.oignonSkip+1)) {
					var paletteColor = new THREE.Color(paletteA[n%paletteA.length]).lerp(new THREE.Color(paletteB[n%paletteB.length]), this.paletteMixPc);
					actualPalette.push(paletteColor);
					n++;
				}
				actualPalette.shift();
				// console.log(actualPalette);

			for (var i=0; i<MAX_CURSOR; i++) {
				this.copresenceCursors[i].visible = false;
				this.copresenceCursors[i].plane.visible = false;
			}
			for (var j in CopresenceController.participants) {
				var client = CopresenceController.participants[j];

				var simulationSkip = false;
				if (this.simulationUseRealUser && client && this.simulationUser && this.simulationUser.id == client.id)simulationSkip = true;

				if (client.assigned && this.copresenceWasEnabled && !simulationSkip) {
					var i = client.assignedId;

					// console.log("DRaw",i);
					var cursor = this.copresenceCursors[i];
					cursor.visible = this.copresenceEnabledPc>0.005;

					//update id
					if (cursor.currentId !== client.id) {
						cursor.currentId = client.id;

						// this.copresenceContext.clearRect(0,0,this.copresenceCanvas.width,this.copresenceCanvas.height);
						this.copresenceContext.fillStyle = '#000000';
						this.copresenceContext.fillRect(0,0, this.copresenceCanvas.width, this.copresenceCanvas.width);
						this.copresenceContext.font = '500 '+(SETTINGS.isMobile?'18pt':'18pt')+" 'Work Sans'";
						this.copresenceContext.textAlign = 'center';
						this.copresenceContext.fillStyle = 'white';
						this.copresenceContext.strokeStyle = 'white';
						// this.copresenceContext.globalAlpha = 1.0;
						// this.copresenceContext.globalCompositeOperation = 'add';


						// this.copresenceContext.fillRect(0,0,128,128);
						// this.copresenceContext.fillAlpha = 1.0;
						this.copresenceContext.fillText('Participant'+(SETTINGS.LANGUAGE=='fr'?'·e':'')+' #'+client.id, this.copresenceCanvas.width/2,this.copresenceCanvas.height/2);

						// this.copresenceContext.fillText('Participant #'+client.id, this.copresenceCanvas.width/2,this.copresenceCanvas.height/2);
						// this.copresenceContext.fillText('Participant #'+client.id, this.copresenceCanvas.width/2,this.copresenceCanvas.height/2);
						// this.copresenceContext.fillText('Participant #'+client.id, this.copresenceCanvas.width/2,this.copresenceCanvas.height/2);

						// console.log("filltext",client.id);
						this.copresenceTextures[i].needsUpdate = true;
						Utils.uploadTexture(this.copresenceTextures[i]);
					}
					

					if (client.pressed) {
						if (!client.simulated || client.simulationTypeA) {
							cursor.targetPos.x = client.currentX * this.cameraStretch.x;
							cursor.targetPos.y = client.currentY * this.cameraStretch.y;
						} else {
							cursor.targetPos.x = this.rng(i, now/1500) * 0.45;
							cursor.targetPos.y = this.rng(-i-100, now/1500) * 0.45;
						}
					}
					
					if (now-cursor.lastTime > 1000 || cursor.currentPos.distanceTo(cursor.targetPos) > 0.15) {
						cursor.currentPos.copy(cursor.targetPos);
						for (var k=0; k<LINE_CURSOR_RESOLUTION; k++) {
							cursor.positions[k*3+0] = cursor.currentPos.x;
							cursor.positions[k*3+1] = cursor.currentPos.y;
						}						
					}
					
					while (now-cursor.lastTime >= 1000/60) {
						cursor.lastTime = Math.max(cursor.lastTime, now-1000/30);
						cursor.lastTime += 1000/120;

						cursor.currentPos.lerp(cursor.targetPos, 0.15);
						cursor.positions.set(cursor.positions.subarray(0, -3), 3);
						cursor.positions[0] = cursor.currentPos.x;
						cursor.positions[1] = cursor.currentPos.y;
					}

					cursor.currentPos.lerp(cursor.targetPos, 0.15*delta);
					cursor.positions[0] = cursor.currentPos.x;
					cursor.positions[1] = cursor.currentPos.y;

					cursor.geometry.needsUpdate = true;

					cursor.plane.visible = true;
					cursor.plane.position.x = cursor.currentPos.x / this.cameraStretch.x; // * AppStatus.innerWidth() * (CAMERA_DISTANCE/AppStatus.innerHeight());
					cursor.plane.position.y = -cursor.currentPos.y;// * AppStatus.innerHeight() * (CAMERA_DISTANCE/AppStatus.innerHeight());
					cursor.plane.scale.set(128 * 1/AppStatus.innerWidth(),64* 1/AppStatus.innerHeight(),1);

					cursor.plane.material.uniforms.alpha.value = this.copresenceEnabledPc;

					// var maxPaletteA = Math.min(paletteA.length,Math.floor(this.oignonFrames/(this.oignonSkip+1))-1);
					// var maxPaletteB = Math.min(paletteB.length,Math.floor(this.oignonFrames/(this.oignonSkip+1))-1);
					var paletteColor = new THREE.Color(actualPalette[(client.id%actualPalette.length) ]);

					// .lerp(new THREE.Color(paletteB[(client.id%maxPaletteB) ]), this.paletteMixPc);
					// console.log(client.id, paletteA[client.currentId%paletteA.length], new THREE.Color(paletteA[client.id%paletteA.length]), new THREE.Color(paletteB[ client.id%paletteB.length]), paletteColor,  this.paletteMixPc);
					// AppStatus.log(maxPaletteA);
					cursor.plane.material.uniforms.palette.value.setRGB(0,0,0);
					cursor.plane.material.uniforms.palette.value.lerp(paletteColor, this.oignonColor * (this.oignonSkinEnabled ? 1 : 0));

					cursor.material = this.line.material;

					// for (var o in this.line.material.uniforms) {
					// 	if (cursor.material.uniforms[o]) {
					// 		if (this.line.material.uniforms[o].value && this.line.material.uniforms[o].value.copy) {
					// 			cursor.material.uniforms[o].value.copy(this.line.material.uniforms[o].value);
					// 		} else {
					// 			cursor.material.uniforms[o].value = this.line.material.uniforms[o].value;
					// 		}
					// 	}
					// }
					cursor.geometry.setPositions(cursor.positions);

				}
				this.copresenceWasEnabled = true;

			}
		} else if (this.copresenceWasEnabled) {
			this.copresenceWasEnabled = false;
			for (var i=0; i<MAX_CURSOR; i++) {
				this.copresenceCursors[i].visible = false;
				this.copresenceCursors[i].plane.visible = false;
			}
		}

		


		//cursor
		// if (SETTINGS.isMobile) {

		// 	this.cursors[0].visible = true;

		// 	this.cursors[0].cursorA.material.uniforms.tNoise.value = this.noiseTexture;
		// 	this.cursors[0].cursorA.material.uniforms.time.value = performance.now()/1000;
		// 	this.cursors[0].cursorA.material.uniforms.randomOffset.value.set(Math.random(), Math.random());
		// 	this.cursors[0].cursorA.material.uniforms.resolution.value.set(renderer.domElement.width, renderer.domElement.height);
		// 	this.cursors[0].cursorA.material.uniforms.direction.value.lerp(this.currentDragSpeed, 0.5);
		// 	this.cursors[0].cursorA.material.uniforms.speed.value = this.currentDragSpeed.length();
		// 	this.cursors[0].cursorA.material.uniforms.alpha.value = Utils.lerp(this.cursors[0].cursorA.material.uniforms.alpha.value, this.isDragging?1.0:0.0, 0.1);
		// 	this.cursors[0].cursorA.position.set(this.rawPosition.x, -this.rawPosition.y);

		// 	this.cursors[0].cursorB.material.uniforms.tNoise.value = this.noiseTexture;
		// 	this.cursors[0].cursorB.material.uniforms.time.value = performance.now()/1000;
		// 	this.cursors[0].cursorB.material.uniforms.randomOffset.value.set(Math.random(), Math.random());
		// 	this.cursors[0].cursorB.material.uniforms.resolution.value.set(renderer.domElement.width, renderer.domElement.height);
		// 	this.cursors[0].cursorB.material.uniforms.direction.value.lerp(this.currentDragSpeed, 0.5);
		// 	this.cursors[0].cursorB.material.uniforms.speed.value = this.currentDragSpeed.length();
		// 	this.cursors[0].cursorB.material.uniforms.alpha.value = Utils.lerp(this.cursors[0].cursorB.material.uniforms.alpha.value, this.isDragging?1.0:0.0, 0.1);
		// 	this.cursors[0].cursorB.position.set(this.rawPosition.x, -this.rawPosition.y);

		// 	// Utils.autoReloadShaderManual(this.cursors[0].cursorA.material, 'shaders/cursor/cursor.vert', 'shaders/cursor/cursor.frag');
		// 	// Utils.autoReloadShaderManual(this.cursors[0].cursorB.material, 'shaders/cursor/cursor2.vert', 'shaders/cursor/cursor2.frag');
		// }

		//new cursor
		if (this.pressedThisFrame && !SETTINGS.AUDIO_EFFECT_EDITOR) {
			if (this.currentCursor) {
				this.currentCursor.active = false;
				this.currentCursor.releaseTime = Math.max(now,this.currentCursor.pressTime+CURSOR_DURATION);
			}
			this.currentCursor = {
				active: true,
				px: this.rawPosition.x * this.cameraStretch.x,
				py: this.rawPosition.y * this.cameraStretch.y,
				pressTime: now,
				releaseTime: 0
			};
			this.cursors.push(this.currentCursor);
		}

		if (this.staticDragPc > 0.2 && !this.currentCursor) {
			this.currentCursor = {
				staticHold: true,
				active: true,
				px: this.rawPosition.x * this.cameraStretch.x,
				py: this.rawPosition.y * this.cameraStretch.y,
				pressTime: now,
				releaseTime: 0
			};
			this.cursors.push(this.currentCursor);
		}




		//release cursor
		if (this.currentCursor && (!this.currentCursor.staticHold||this.staticDragPc<0.175||this.currentDragSpeed.length()>0.025||!this.isDragging)) {
			// if (!this.mouseIsDown) {
				this.currentCursor.active = false;
				this.currentCursor.releaseTime = Math.max(now,this.currentCursor.pressTime+CURSOR_DURATION);
				this.currentCursor = null;
			// } else {
			// 	this.currentCursor.px = this.rawPosition.x * this.cameraStretch.x;
			// 	this.currentCursor.py = this.rawPosition.y* this.cameraStretch.y;
			// }
		}
		


		//update cursors
		this.currentCursorIdx = 0;
		for (var i=0;i<this.cursors.length; i++) {
			var c = this.cursors[i];
			var positionsArr = null;


			//static hold cursor effect
			if (c.staticHold) {

				var f = Math.floor( (now/1000) * 30 ) % this.cursorHoldSequence.frames.length;
				positionsArr = this.cursorHoldSequence.frames[f].getPositions();

				c.px = this.rawPosition.x * this.cameraStretch.x;
				c.py = this.rawPosition.y * this.cameraStretch.y;

				if (!c.active) {
					this.cursors.splice(i,1);
					i--;
					break;
				}

			} else {

				//press anim
				if (now < c.pressTime+CURSOR_DURATION) {

					positionsArr = this.cursorPressSequence.frames[Math.floor(Utils.cmap(now, c.pressTime, c.pressTime+CURSOR_DURATION, 0, this.cursorPressSequence.frames.length))].getPositions();
					// console.log("seq1",Math.floor(Utils.cmap(now, c.pressTime, c.pressTime+CURSOR_DURATION, 0, this.cursorPressSequence.frames.length)));

				//release anim
				} else if (!c.active) {

					if (now >= c.releaseTime+CURSOR_DURATION) {

						this.cursors.splice(i,1);
						i--;

					} else {
					// console.log("release");
						positionsArr = this.cursorReleaseSequence.frames[Math.min(Math.floor(Utils.cmap(now, c.releaseTime, c.releaseTime+CURSOR_DURATION, 0, this.cursorPressSequence.frames.length)), this.cursorPressSequence.frames.length-1)].getPositions();
					
						// console.log("seq2",Math.min(Math.floor(Utils.cmap(now, c.releaseTime, c.releaseTime+CURSOR_DURATION, 0, this.cursorPressSequence.frames.length)), this.cursorPressSequence.frames.length-1));

					}

				//hold
				} else {

					var f = Math.floor( (now/1000) * 30 ) % 5;
					positionsArr = this.cursorHoldSequence.frames[f].getPositions();
					console.log("HOLD");
				}
			}

			if (positionsArr) {
				for (var j=0; j<positionsArr.length; j++) {
					var positions = positionsArr[j]
					if (this.currentCursorIdx + positions.length+3 >= 960) {
						console.warn("max cursor resolution reached");
					} else {
						// this.cursorPositions.set(positions, this.currentCursorIdx);
						for (var k=0; k<positions.length; k+=3) {
							this.cursorPositions[this.currentCursorIdx+k] = positions[k] + c.px;
							this.cursorPositions[this.currentCursorIdx+k+1] = positions[k+1] + c.py;
							this.cursorPositions[this.currentCursorIdx+k+2] = 0;
						}
						this.cursorPositions[this.currentCursorIdx+positions.length] = this.cursorPositions[positions.length-3];
						this.cursorPositions[this.currentCursorIdx+positions.length+1] = this.cursorPositions[positions.length-2];
						this.cursorPositions[this.currentCursorIdx+positions.length+2] = 1;
						this.currentCursorIdx += positions.length+3;
					}
				}
			}
		}
		if (this.currentCursorIdx > 0) {
			this.cursorGeom.instanceCount = this.currentCursorIdx/3;
			this.cursorGeom.setPositions( this.cursorPositions );
		}

		if (AudioController.pausedForMenu) {}
			
	}

	// getCurrentLine() {
	// 	return this.allLines[ (this.currentLine) % this.allLines.length ]
	// }

	getNextLine() {
		this.currentIdx = 0;
		// if (this.currentLine+1 > this.allLines.length) console.warn("Too many lines in frame.");
		return this.allLines[ (this.currentLine++) % this.allLines.length ];
	}

	addPositions(positions) {
		if (this.currentIdx + positions.length >= this.positions.length) {
			this.currentIdx = 0;
			if (!window.maxRes) {window.maxRes = true; console.warn("max resolution reached"); }
			// return;
		}

		this.positions.set(positions, this.currentIdx);
		this.positions[this.currentIdx+positions.length] = positions[positions.length-3];
		this.positions[this.currentIdx+positions.length+1] = positions[positions.length-2];
		this.positions[this.currentIdx+positions.length+2] = 1; //use z to signify line end for shader
		// this.positions[this.currentIdx+positions.length-5] = positions[positions.length-3];
		// this.positions[this.currentIdx+positions.length-4] = positions[positions.length-2];
		// this.positions[this.currentIdx+positions.length-1] = 1; //use z to signify line end for shader
		this.currentIdx += positions.length+3;


		// this.positions.set(positions, this.currentIdx);
		// this.currentIdx += positions.length;

		this.line.geometry.instanceCount = this.currentIdx/3;
		// console.log(this.currentIdx);
	}

	addChiffre(positions) {
		if (this.currentChiffreIdx + positions.length+3 >= this.chiffrePositions.length) {
			if (AppStatus.currentFrame%30==0) console.warn("max chiffre resolution reached");
			return;
		}
		this.chiffrePositions.set(positions, this.currentChiffreIdx);
		this.chiffrePositions[this.currentChiffreIdx+positions.length] = this.chiffrePositions[positions.length-3];
		this.chiffrePositions[this.currentChiffreIdx+positions.length+1] = this.chiffrePositions[positions.length-2];
		this.chiffrePositions[this.currentChiffreIdx+positions.length+2] = 1;
		this.currentChiffreIdx += positions.length+3;
		this.chiffreGeom.instanceCount = this.currentChiffreIdx/3;
	}

	

	getEyesArray(positions) {
		if (this.currentEyesIdx + positions.length+3 >= this.eyesPositions.length) {
			console.warn("max eyes resolution reached", this.currentEyesIdx + positions.length+3);
		}
		return this.eyesPositions.subarray(this.currentEyesIdx, this.currentEyesIdx+positions.length);
	}
	setEyesArray(positions) {
		if (this.currentEyesIdx + positions.length+3 >= this.eyesPositions.length) {
			if (AppStatus.currentFrame%30==0) console.warn("max eyes resolution reached");
			return;
		}
		this.eyesPositions[this.currentEyesIdx+positions.length] = this.eyesPositions[positions.length-3];
		this.eyesPositions[this.currentEyesIdx+positions.length+1] = this.eyesPositions[positions.length-2];
		this.eyesPositions[this.currentEyesIdx+positions.length+2] = 1;
		this.currentEyesIdx += positions.length+3;
		this.eyesGeom.instanceCount = this.currentEyesIdx/3;
	}

	getPositionsArray(positions) {
		if (this.currentIdx + positions.length >= this.positions.length) {
			// console.log(this.positions.length, this.currentIdx);
			this.currentIdx -= positions.length+3;
			if (!window.maxRes) {window.maxRes = true; console.warn("max resolution reached"); }
		}
		return this.positions.subarray(this.currentIdx, this.currentIdx+positions.length);
	}
	setPositionsArray(positions) {
		if (this.currentIdx + positions.length+3 >= MAX_RESOLUTION) {
			if (AppStatus.currentFrame%30==0) console.warn("max resolution reached");
			return;
		}
		this.positions[this.currentIdx+positions.length] = positions[this.currentIdx+positions.length-3];
		this.positions[this.currentIdx+positions.length+1] = positions[this.currentIdx+positions.length-2];
		this.positions[this.currentIdx+positions.length+2] = 1;
		this.currentIdx += positions.length+3;
		this.line.geometry.instanceCount = this.currentIdx/3;
	}


	pushOignonVectorPoints(positions, material, uniforms) {
		var f = this.currentOignonVectorFrame;
		// console.log(f.oid);
		// for (var i = 0; i < positions.length; i++) {

			//save points for re-rendering
			// var p = this.oignonPositionPool.length > 0 ? this.oignonPositionPool.pop() : new Float32Array(MAX_RESOLUTION);
			// console.log(p);
			// p.set(positions);

			// console.log(f.positions.length, positions.length);
			f.positions.set(this.positions.subarray(0, MAX_RESOLUTION_OIGNON_VECTOR));
			f.positionLength = Math.min(this.line.geometry.instanceCount,MAX_RESOLUTION_OIGNON_VECTOR/3);
			f.materials = material;

			var uniforms = {};
			for (var o in material.uniforms) {
				if (material.uniforms[o].type=='v2' || material.uniforms[o].type=='v3') uniforms[o] = material.uniforms[o].value.clone();
				else uniforms[o] = material.uniforms[o].value;
			}
			f.uniforms = uniforms;


			//save material
		// }
		f.lastRender = performance.now();
	}


	renderOignonVectorFrame(frame, index) {
		// console.log("render frame:",frame);
		if (!frame) return;
		if (performance.now() - frame.lastRender >= 4000) return;

		var paletteA = AppStatus.infoJson.oignon_palettes[this.oignonVectorPaletteA];
		var paletteB = AppStatus.infoJson.oignon_palettes[this.oignonVectorPaletteB];

		// var actualNumFrames = Math.floor( Math.min(Math.ceil(this.oignonVectorFrames), OIGNON_MAX_FRAME) / (this.oignonVectorSkip+1));				

		// var npc = n/actualNumFrames;
		//how many times should we go through the palette?

		// console.log(index, index%paletteA.length);
		// console.log(index);

		var paletteColor = new THREE.Color(paletteA[index%paletteA.length]).lerp(new THREE.Color(paletteB[ index%paletteB.length]), this.oignonVectorPaletteMixPc);

		// paletteColor.setRGB(n/24, 0.0, 0.0);

		// console.log(n);
		// this.oignonMaterial.uniforms.col.value.copy(paletteColor).lerp(this.strokeColor, 1.0-this.oignonColor);
		// this.oignonMaterial.uniforms.col.value.copy(paletteColor).lerp(this.strokeColor, 1.0-this.oignonColor);

		// for (var i = 0; i < frame.positions.length; i++) {
			var i = 0;
			if (frame.positionLength > 0) {
				var line = this.line;
				line.visible = true;
				line.geometry = this.lineGeometry2;
				line.geometry.instanceCount = Math.min(frame.positionLength, 1024*2-2);
				line.geometry.setPositions(frame.positions);

				line.material = frame.materials;
				for (var o in frame.uniforms) {
					if (line.material.uniforms[o].type=='v2' || line.material.uniforms[o].type=='v3') line.material.uniforms[o].value.copy(frame.uniforms[o]);
					else line.material.uniforms[o].value = frame.uniforms[o];
				}
				if (line.material.color) line.material.color.lerp(paletteColor, this.oignonVectorColor);
				if (line.material.uniforms.color) line.material.uniforms.color.value.lerp(paletteColor, this.oignonVectorColor);

				if (line.material.uniforms.source && index < Math.min(Math.ceil(this.oignonVectorFrames), OIGNON_VECTOR_MAX_FRAME)) {
					line.material.uniforms.source.value.x += frame.randomBrush.x * this.oignonVectorRandomBrush;
					line.material.uniforms.source.value.y += frame.randomBrush.y * this.oignonVectorRandomBrush;

					// console.log(line.material.uniforms.source.value.x);
					// console.log(frame.randomBrush.x.this.oignonVectorRandomBrush);
				}
				renderer.render(this.scene, this.camera);
			} else {
				// console.log("no frame",frame.positionLength);
			}
			
			// line.material = line.defaultMaterial;
			// line.material.linewidth = this.lineWidth*SETTINGS.BASE_LINEWIDTH;
			// line.material.color.copy( this.strokeColor ) ;
		// }
		// for (var i = frame.positions.length; i < this.currentLine; i++) {
		// 	this.allLines[i].visible = false;
		// }
		// this.currentLine = frame.positions.length;f

	}


	// cleanOignon() {
	// 	var now = performance.now();
	// 	for (var i = 0; i<this.fbos.length; i++) {
	// 		if (now - this.fbos[i].lastRender < 4000) {
	// 			Utils.clearFboAlpha(this.fbos[i].texture);
	// 			this.fbos[i].lastRender = now-100000;
	// 		}
	// 	}
	// }

	render(opt) {

		if (this.currentIdx > 0 || this.currentIdx !== this.lastIdx) {

			if (this.currentIdx <= 1024 * 2 * 3) {this.line.geometry = this.lineGeometry2;}
			else if (this.currentIdx <= 1024 * 4 * 3) {this.line.geometry = this.lineGeometry4;}
			else if (this.currentIdx <= 1024 * 8 * 3) {this.line.geometry = this.lineGeometry8;}
			else if (this.currentIdx <= 1024 * 16 * 3) {this.line.geometry = this.lineGeometry16;}
			else if (this.currentIdx <= 1024 * 24 * 3) {this.line.geometry = this.lineGeometry24;}
			else  {this.line.geometry = this.lineGeometry32;}

			this.line.geometry.setPositions(this.positions);
			this.lastIdx = this.currentIdx;
			this.line.geometry.instanceCount = this.currentIdx/3;
		}
		if (this.currentEyesIdx > 0) {
			this.eyesLine.material = this.line.material;
			this.eyesGeom.setPositions(this.eyesPositions);
		}
		

		this.oignonFrames = Utils.clamp(this.oignonFrames, 1, OIGNON_MAX_FRAME-1);

		var now = performance.now();

		renderer.setRenderTarget(null);
		renderer.setClearColor(0xffffff,1);
		renderer.clear();


		var target = this.useEffectsFbo ? this.effectsFbo.texture : 
					(this.digitalEnabled||SETTINGS.NOISE_ENABLED) ? this.targetFbo.texture : null;

		var lastOignonColor = Utils.blackColor;
		if (this.oignonSkinEnabled) {
			renderer.setRenderTarget(this.fbos[0].texture);
			renderer.setClearColor(0x000000,0);
			renderer.clear();



			this.fbos[0].lastRender = now;
			renderer.render(this.scene, this.camera);

			// renderer.render(this.lineCursorScene, this.camera);

			renderer.setRenderTarget(this.oignonFbo.texture);
			renderer.setClearColor(0xffffff,1);
			renderer.clear();

			if (this.renderBackground) {
				Utils.renderMaterial(this.backgroundMaterial.uniforms.fillAlpha.value < 1.0 ? this.backgroundMaterial : this.backgroundMaterialSimple, null, false);
			}

			Utils.defaultPlane.material = this.oignonMaterial;
			this.oignonMaterial.uniforms.paletteMix.value = this.oignonColor;
			var n = 0;
			var pc = this.oignonFrames%1.0;
			var startIndex = Math.min(Math.ceil(this.oignonFrames), OIGNON_MAX_FRAME);

			var paletteA = AppStatus.infoJson.oignon_palettes[this.paletteA];
			var paletteB = AppStatus.infoJson.oignon_palettes[this.paletteB];

			
			for (var i=startIndex; i>=0; i-=(this.oignonSkip+1)) {
				// if (!this.fbos[i] || (this.fbos[i] && this.fbos[i].texture)){
				// 	console.log(i, this.fbos[i]);
				// }
				if (now - this.fbos[i].lastRender < 4000) {
					this.oignonMaterial.uniforms.tDiffuse.value = this.fbos[i].texture.texture;
					var paletteColor = new THREE.Color(paletteA[n%paletteA.length]).lerp(new THREE.Color(paletteB[n%paletteB.length]), this.paletteMixPc);

					this.oignonMaterial.uniforms.col.value.copy(paletteColor).lerp(this.strokeColor, 1.0-this.oignonColor);
					if (i == startIndex && i-(this.oignonSkip+1)>=0) {
						this.oignonMaterial.uniforms.alpha.value = pc;
					} else {
						this.oignonMaterial.uniforms.alpha.value = 1.0;
					}
					lastOignonColor = this.oignonMaterial.uniforms.col.value;



					renderer.render(Utils.defaultScene, Utils.orthographicCamera);
				}
				n++;
			}
			Utils.defaultScene.scale.set(1,1,1);
			
				// var currentPack = 0;
			// for (var i=startIndex; i>=0; i-=(this.oignonSkip+1)) {
			// 	if (now - this.fbos[i].lastRender < 4000) {
			// 		// this.oignonMaterial.uniforms.tDiffuse.value = this.fbos[i].texture.texture;
			// 		var paletteColor = new THREE.Color(paletteA[n%paletteA.length]).lerp(new THREE.Color(paletteB[n%paletteB.length]), this.paletteMixPc);

			// 		this.oignonMaterial.uniforms.col.value.copy(paletteColor).lerp(this.strokeColor, 1.0-this.oignonColor);
			// 		if (i == startIndex && i-(this.oignonSkip+1)>=0) {
			// 			this.oignonMaterial.uniforms.alpha.value = pc;
			// 		} else {
			// 			this.oignonMaterial.uniforms.alpha.value = 1.0;
			// 		}
			// 		lastOignonColor = this.oignonMaterial.uniforms.col.value;

			// 		this.oignonPackMaterial.uniforms.paletteMix.value = this.oignonColor;
			// 		this.oignonPackMaterial.uniforms["frame"+currentPack].value = this.fbos[Math.floor(i/4)].texture.texture;
			// 		this.oignonPackMaterial.uniforms["col"+currentPack].value.set(lastOignonColor.r, lastOignonColor.g, lastOignonColor.b, 1.0);

			// 		// renderer.render(Utils.defaultScene, Utils.orthographicCamera);
			// 	}

			// 	currentPack++;

			// 	if (currentPack >= 8) {
			// 		Utils.defaultPlane.material = this.oignonPackMaterial;
			// 		renderer.render(Utils.defaultScene, Utils.orthographicCamera);
			// 		currentPack = 0;
			// 	}


			// 	n++;
			// }


			if (this.copresenceEnabledPc>0.005) {
				renderer.render(this.copresenceScene, this.camera);
			}
			Utils.defaultPlane.material = Utils.defaultMaterial;


			renderer.setRenderTarget(target);
			renderer.setClearColor(0xffffff,1);
			renderer.clear();
			Utils.renderTexture(this.oignonFbo.texture.texture, false);

			if (this.copresenceEnabledPc>0.005) {
				renderer.render(this.copresencePlaneScene, Utils.orthographicCamera);
			}
			// this.fxaaMaterial.uniforms.tDiffuse.value = this.oignonFbo.texture.texture;
			// this.fxaaMaterial.uniforms.resolution.value.set(renderer.domElement.width, renderer.domElement.height);
			// Utils.renderMaterial(this.fxaaMaterial,null, false);
			// Utils.autoReloadShaderManual(this.fxaaMaterial, 'shaders/effects/fxaa2.vert', 'shaders/effects/fxaa2.frag')



		} else if (this.oignonSkinVectorEnabled && this.oignonVectorRandomBrush>0.01) {

			renderer.setRenderTarget(this.oignonFbo.texture);
			renderer.setClearColor(0xffffff,1);
			renderer.clear();


			if (this.renderBackground) {
				Utils.renderMaterial(this.backgroundMaterial.uniforms.fillAlpha.value < 1.0 ? this.backgroundMaterial : this.backgroundMaterialSimple, null, false);
			}

			renderer.render(this.scene, this.camera);
		
			var pc = this.oignonVectorFrames%1.0;
			var startIndex = Math.min(Math.ceil(this.oignonVectorFrames), OIGNON_VECTOR_MAX_FRAME);
			var oIndex = startIndex;
			for (var i=0; i<=startIndex; i+=(this.oignonVectorSkip+1)) {
				this.renderOignonVectorFrame(this.oignonVectorInfo[i], i, lastOignonColor);	
			}

			if (this.copresenceEnabledPc>0.005) {
				renderer.render(this.copresenceScene, this.camera);
			}

			renderer.setRenderTarget(target);
			renderer.setClearColor(0xffffff,1);
			renderer.clear();
			Utils.renderTexture(this.oignonFbo.texture.texture, false);

			if (this.copresenceEnabledPc>0.005) {
				renderer.render(this.copresencePlaneScene, Utils.orthographicCamera);
			}

		} else {
			renderer.setRenderTarget(target);
			renderer.setClearColor(0xffffff,1);
			renderer.clear();

			if (this.renderBackground) {
				Utils.renderMaterial(this.backgroundMaterial.uniforms.fillAlpha.value < 1.0 ? this.backgroundMaterial : this.backgroundMaterialSimple, target, false);
			}
			// Utils.autoReloadShaderManual(this.roundedBoxMaterial, null, 'shaders/common/roundedBox.frag');
			if (this.showRoundedBox) renderer.render(this.roundedBoxScene, this.camera);


			renderer.render(this.scene, this.camera);
			if (this.copresenceEnabledPc>0.005) {
				renderer.render(this.copresenceScene, this.camera);
				renderer.render(this.copresencePlaneScene, Utils.orthographicCamera);
			}

			if (this.oignonSkinVectorEnabled) {
				var pc = this.oignonVectorFrames%1.0;
				var startIndex = Math.min(Math.ceil(this.oignonVectorFrames), OIGNON_VECTOR_MAX_FRAME);

				var oIndex = startIndex;

				// for (var i=startIndex; i>=0; i-=(this.oignonVectorSkip+1)) {
				// 	this.renderOignonVectorFrame(this.oignonVectorInfo[i], oIndex);
				// 	oIndex+=(this.oignonVectorSkip+1);
				// }
				for (var i=0; i<=startIndex; i+=(this.oignonVectorSkip+1)) {
					this.renderOignonVectorFrame(this.oignonVectorInfo[i], i, lastOignonColor);
					// oIndex+; //=(this.oignonVectorSkip+1);
				}
				// for (var i=0; i<24; i++) {
				// 	this.renderOignonVectorFrame(this.oignonVectorInfo[i], oIndex);
				// 	oIndex+=(this.oignonVectorSkip+1);
				// 	// break;
				// }
			}

		}



		if (this.currentEyesIdx > 0) {
			if (this.oignonSkinEnabled) {
				this.eyesLine.material.uniforms.color.value.copy(lastOignonColor); //.lerp(this.strokeColor, 1.0-this.oignonColor);
			}
			this.eyesScene.scale.copy(this.container.scale);
			renderer.render(this.eyesScene, this.camera);
		}


		if (this.currentCursorIdx > 0) {
			if (this.oignonSkinEnabled) {
				this.cursor.material.uniforms.color.value.copy(lastOignonColor); //.lerp(this.strokeColor, 1.0-this.oignonColor);
			}
			this.cursor.material = this.line.material;
			renderer.render(this.cursorScene, this.camera);

		}

		if (this.currentChiffreIdx > 0) {
			this.chiffreGeom.setPositions(this.chiffrePositions);
			this.chiffre.material.uniforms.color.value.setRGB(0,0,0);
			renderer.render(this.chiffreScene, this.camera);
		}
		// renderer.setRenderTarget(target);
		// renderer.render(this.lineCursorScene, this.camera);
		// renderer.render(this.eyesScene, this.camera);


		//emboss
		if (this.useEffectsFbo && this.embossEnabled) {
			renderer.setRenderTarget((this.digitalEnabled||SETTINGS.NOISE_ENABLED) ? this.targetFbo.texture : null);
			renderer.setClearColor(0xffffff,1);
			renderer.clear();
			Utils.renderMaterial(this.embossMaterial,null,false);
		}


		if (this.digitalEnabled) {
			renderer.setRenderTarget(null);
			renderer.setClearColor(0xffffff,1);
			renderer.clear();
			
			Utils.renderMaterial(this.digitalMaterial,null, false);
		} else if (SETTINGS.NOISE_ENABLED) {
			renderer.setRenderTarget(null);
			renderer.setClearColor(0xffffff,1);
			renderer.clear();
			Utils.renderMaterial(this.noiseMaterial,null, false);
		}


		if (!SETTINGS.MENU_DISABLED && !AppStatus.isOnIntro && !AppStatus.isOnEndPage) renderer.render(this.uiScene, this.uiCamera);
		// if (SETTINGS.VOLUME_ENABLED && !AppStatus.isOnIntro && !AppStatus.isOnEndPage) renderer.render(this.uiScene, this.uiCamera);


		// Utils.defaultPlane.scale.set(0.33,0.33,0.33);
		// Utils.emptyMaterial.color.setRGB(window.HueController.latestColor.x, window.HueController.latestColor.y, window.HueController.latestColor.z);
		// console.log(Utils.emptyMaterial.color);
		// Utils.renderMaterial(Utils.emptyMaterial);
		// Utils.defaultPlane.scale.set(1,1,1);
		// Utils.emptyMaterial.color.setRGB(0,0,0);



		// if (SETTINGS.isMobile)
		// 	renderer.render(this.cursorScene, Utils.orthographicCamera);


		// if (this.this.digitalEnabled) {
		// 	renderer.setRenderTarget(null);
		// 	renderer.setClearColor(0xffffff,1);
		// 	renderer.clear();
		// 	this.fxaaMaterial.uniforms.tDiffuse.value = this.targetFbo.texture.texture;
		// 	this.fxaaMaterial.uniforms.resolution.value.set(renderer.domElement.width, renderer.domElement.height);
		// 	Utils.renderMaterial(this.fxaaMaterial,null, false);
		// 	Utils.autoReloadShaderManual(this.fxaaMaterial, 'shaders/effects/fxaa2.vert', 'shaders/effects/fxaa2.frag')
		// }
	}


	resize() {
		if (!this.camera) return;
		this.camera.aspect = AppStatus.innerWidth() / AppStatus.innerHeight();
		this.camera.updateProjectionMatrix();


		this.uiCamera = new THREE.OrthographicCamera(0, AppStatus.innerWidth(), 0, AppStatus.innerHeight(), -1, 1);
		if (this.pauseButton) {
			if (!SETTINGS.isMobile || SETTINGS.isTablet) {
			// if (AppStatus.innerWidth() > 512) {
				this.pauseButton.scale.set(20,20,20);
				this.pauseButton.position.x = 32;
				this.pauseButton.position.y = AppStatus.innerHeight()-32;
			} else {
				this.pauseButton.position.y = AppStatus.innerHeight()-28;
				this.pauseButton.position.x = 28;
				this.pauseButton.scale.set(18,18,18);
				$('#pause-restart-container').toggleClass('mobile', true);
				$('#timecode-final').toggleClass('mobile', true);
			}

			if (SETTINGS.INSTALLATION_MODE) {
				// this.pauseButton.scale.y *= 1.25;
				this.pauseButton.position.x -= 2;
				this.pauseButton.position.y -= 2;
			}
		}

		if (SETTINGS.VOLUME_BUTTON_ENABLED && this.volumeButton) {
			if (!SETTINGS.isMobile || SETTINGS.isTablet) {
				this.volumeButton.scale.set(20*1.181818,20,20);
				this.volumeButton.position.x = AppStatus.innerWidth()-38;
				this.volumeButton.position.y = AppStatus.innerHeight()-32;
				// this.pauseButton.scale.set(18,18,18);
			} else {
				this.volumeButton.position.y = AppStatus.innerHeight()-28;
				this.volumeButton.position.x = AppStatus.innerWidth()-36;
				this.volumeButton.scale.set(18*1.181818,18,18);
			}
			
			this.volumeMoinsButton.position.x = AppStatus.innerWidth()-38;
			this.volumeMoinsButton.position.y = AppStatus.innerHeight() - 32 - 48;
			this.volumePlusButton.position.x = AppStatus.innerWidth() - 38;
			this.volumePlusButton.position.y = AppStatus.innerHeight() - 32 - 48 - 48;
		}
		

		//fit camera width if vertical
		// var ratio = ((AppStatus.innerHeight()/AppStatus.innerWidth()) / (2246/1125));
		var ratio = ((AppStatus.innerHeight()/AppStatus.innerWidth()) / (4/2.9));

		if (ratio < 1.0) {

			ratio = ((AppStatus.innerHeight()/AppStatus.innerWidth()) / (1920/1080));

			if (AppStatus.innerWidth() > AppStatus.innerHeight()) {
				this.camera.position.z = CAMERA_DISTANCE;
				this.cameraStretch.y = 1.0;
				this.cameraStretch.x = (1080.0/1920.0)/ratio;

			} else {
				this.camera.position.z = CAMERA_DISTANCE;
				this.cameraStretch.y = 1.0;
				this.cameraStretch.x = (1080.0/1920.0)/ratio;
			}

		} else {

			var ratioSize = (2300/1080);

			ratio = ((AppStatus.innerHeight()/AppStatus.innerWidth()) / ratioSize);
			ratio = Math.pow(ratio,0.5);
			this.camera.position.z = CAMERA_DISTANCE * ratio;
			// console.log("xx", ratio);

			// ratio = ((AppStatus.innerHeight()/AppStatus.innerWidth()) / (1920/1440));
			this.cameraStretch.y = ratio;
			this.cameraStretch.x = (1.0/ratioSize) / ratio;
		}

		if (this.effectsFbo) {
			this.effectsFbo.resize2(renderer.domElement.width, renderer.domElement.height);
			this.oignonFbo.resize2(Math.floor(renderer.domElement.width*0.6),Math.floor(renderer.domElement.height*0.6));
		
			for (var i =0; i<this.fbos.length; i++) {
				this.fbos[i].resize2(Math.floor(renderer.domElement.width*0.6)-4,Math.floor(renderer.domElement.height*0.6)-4);
			}
		}
	

		if (AppStatus.isOnIntro) {
			$('#instructions-enter-container').css('top', (80.5 - (this.cameraStretch.y-1.0)*30.5 )+'%');
			$('#instructions-arrow-container').css('top', (80.5 - (this.cameraStretch.y-1.0)*30.5 )+'%');
		}



	}

	updateAfter() {
		this.pressedThisFrame = false;
		this.releasedThisFrame = false;
		if (MenuController.pausedForMenu) this.copresenceEnabledPc = 0;
	}

	dispose() {
		if (!this.fbos) return;
		for (var i=0; i<this.fbos.length; i++) {
			this.fbos[i].cleanUp();
		}
		this.oignonFbo.cleanUp();
		this.effectsFbo.cleanUp();
		this.targetFbo.cleanUp();
		this.fbo.cleanUp();
		this.noiseTexture.dispose();

		this.lineGeometry2.dispose();
		this.lineGeometry4.dispose();
		this.lineGeometry4.dispose();
		this.lineGeometry8.dispose();
		this.lineGeometry16.dispose();
		this.lineGeometry24.dispose();
		this.lineGeometry32.dispose();


		this.oignonVectorInfo = [];
		this.container = null;
		this.line = null;
		this.positions = null;




	}
}
window.SequenceRenderer = window.SequenceRenderer||new SequenceRendererStatic();
export default window.SequenceRenderer;
