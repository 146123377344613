import ABDisposeController from '../controllers/ABDisposeController.js';

/**
 *
 * Simple wrapper around AudioBuffer to handle which data is decoded and when
 * Helps prevent lag, memory inflation and leaks
 *
*/
function AudioBinaryBuffer(b,name) {
	var self = this;
	this.binaryBuffer = b;
	b = null;
	this.buffer = null;
	this.decoded = false;
	this.isShared = false;
	this.decoding = false;


	this.decode = function() {
		if (self.decoding) {
			return self.decoding;
		}

		self.decoding = new Promise(function (fulfill, reject){
			if (self.decoded) {
				self.decoding = null;
				fulfill(self.buffer);
				return;
			}
			//console.time('decoding audio');
			// if (AudioController.contextIsSafe()) {

				if (!self.binaryBuffer) {
					console.warn('Major audio loading error. Using empty buffer.',self.name);
					self.buffer = null; //window.audioContext.createBuffer(1, 44100, 44100);
				    self.decoded = true;
				    // self.buffer = buffer;
				    self.buffer.name = self.name;
				    self.decoding = false;
				    fulfill(self.buffer);
				    return;
				}
				window.audioContext.decodeAudioData(self.binaryBuffer,
					function(buffer) {
					    if (!buffer) {
					        console.warn('AudioBuffer Buffer Decode Error',self.name);
					    }
					    if (self.binaryBuffer && self.binaryBuffer.byteLength) {
					    	// BackgroundWorkerController.disposeArraybuffer(self.binaryBuffer);
					    	self.binaryBuffer = null;
					    }
					    // self.binaryBuffer = null;
					    self.decoded = true;
					    self.buffer = buffer;
					    self.buffer.name = self.name;
					    self.decoding = false;
					    //console.timeEnd('decoding audio');
					   	fulfill(buffer);
					},
					function(er) {
						console.warn('Major audio buffer decoding error. Using empty buffer.',self.name);
						self.buffer = window.audioContext.createBuffer(1, 44100, 44100);
					    self.decoded = true;
					    // self.buffer = buffer;
					    self.buffer.name = self.name;
					    self.decoding = false;
					    fulfill(self.buffer);
					}
				);
			// } else {
			// 	AudioController.contextWaitingList.push(self);
			// 	self.decoded = false;
			// 	self.decoding = null;
			// 	fulfill(null);
			// 	return;
			// }
		});
		return self.decoding;
	};
	this.disposeDecoded = function() {
		if (self.isShared) return;
		self.buffer = undefined;
		self.decoded = false;
	};
	this.getBuffer = function() {
		if (!self.decoded) console.error('AudioBuffer Not Decoded',self.name);
		return self.buffer;
	};
	this.dispose = function() {
		// if (self.isShared) return;
		if (self.binaryBuffer && self.binaryBuffer.byteLength) ABDisposeController.dispose(self.binaryBuffer);
		self.buffer = self.binaryBuffer = self.decoded = self.name = undefined;
		self = undefined;
	};
}

export default AudioBinaryBuffer;


