export default {
	//meta tags
	"meta": {
		"title": "Brainstream",
		"description": "An interactive animated film in which a young girl livestreams her brain activity. Written and directed by Caroline Robert. In collaboration with Édouard Lanctôt-Benoit, Mathieu Charbonneau and Vincent Morisset. Performed by Sophie Shields-Rivard • Created by the studio AATOAA • Produced by the National Film Board of Canada",
		"keywords": "ONF, NFB, AATOAA, Brainstream, animation, interactive, fiction, mobile, brain, livestream, video, film",
		"url": "https://brainstream.nfb.ca",	
		"alturl": "https://serotonine.onf.ca",
		"nfburl": "https://www.nfb.ca",

		"share_meta_line1": "Brainstream : an interactive film by Caroline Robert",
		"share_meta_line2": "Today, you will be massaging D.’s brain.",

		"twitter_meta_line1": "Brainstream : an interactive film by Caroline Robert",
		"twitter_meta_line2": "Today, you will be massaging D.’s brain.",

		"share_image": "https://serotonine.onf.ca/images/brainstream_share_img.jpg",

		"share_twitter": "Brainstream : an interactive film by Caroline Robert. Today, you will be massaging D.’s brain. #brainstream",
		"share_others": "Brainstream : an interactive film by Caroline Robert. Today, you will be massaging D.’s brain."
	},
	

	//EN: Messages d'erreur
	"errors": {
		"loading": `
				<div id="error-title" class="en"></div>
				<div class="error-sep">__________________</div><br>
				<br>Something's wrong.<br>
				Check your connection<br>
				or try again later&mdash;<br>
				I promise I'll wait<br>for you!<br><br><br>
				<a class="error-link" href="https://nfb.ca/interactive/brainstream" target="_BLANK">About</a><br><br>
			`,
		"webgl": `
				<div id="error-title" class="en"></div>
				<div class="error-sep">__________________</div><br>
				Sorry! We couldn't find<br>
				a way to make Brainstream<br>
				work on your device.<br><br>
				Capitalism is the worst.<br>
				We hope you can try<br>
				again on a newer machine.<br><br><br>
				<a class="error-link" href="https://nfb.ca/interactive/brainstream" target="_BLANK">About</a><br><br>
			`,
		"browser": `
				<div id="error-title" class="en"></div>
				<div class="error-sep">__________________</div><br>
				Hi! Unfortunately,<br>
				Brainstream doesn't work<br>
				on this version of your browser.<br>
				Try updating your<br>
				software and reloading<br>
				this page so we can<br>
				go exploring.<br><br><br>
				<a class="error-link" href="https://nfb.ca/interactive/brainstream" target="_BLANK">About</a><br><br>
			`,
		"device": `
				<div id="error-title" class="en"></div>
				<div class="error-sep">__________________</div><br>
				Sorry! We couldn't find<br>
				a way to make Brainstream<br>
				work on your device.<br><br>
				Capitalism is the worst.<br>
				We hope you will try<br>
				again on a newer machine.<br><br><br>
				<a class="error-link" href="https://nfb.ca/interactive/brainstream" target="_BLANK">About</a><br><br>
			`,
		"temp_page":`
				<div id="error-title" class="en"></div>
				<div class="error-sep">__________________</div><br>
				Available November 19th<br><br>
				<a class="error-link" href="https://nfb.ca/interactive/brainstream" target="_BLANK">About Brainstream</a><br><br>
				<a id="press-access-btn" class="error-link" href="#">Press access</a><br>

		`
	},


	//EN: views
	"views": {

		"loading": "Loading",
		"buffering": "Loading",

		"early_end_question": "Do you want to",
		"early_end_continue": "Continue",
		"early_end_stop": "Stop",

		"timeselect_question": "Select a duration",
		"timeselect_short": "5 min.",
		"timeselect_long": "20 min.",

		"endbtn_credits": "Credits",
		"endbtn_share": "Share",
		"endbtn_restart": "Restart"
	},


	//
	// EN: Les textes de page
	//
	"pages": {
		"about-title": "About Brainstream",
		"share-title": "Share Brainstream",
		"credits-title": "Credits",
		"about": `
			<div class="about-credits">
				Written and directed by<br> <a target="_BLANK" href="https://caroline-robert.com" class="about-name-link"><b>Caroline Robert</b></a><br>
				In collaboration with<br> <b>Édouard Lanctôt-Benoit, Mathieu Charbonneau</b> and <b>Vincent Morisset</b><br>
				Performed by<br> <b>Sophie Shields-Rivard</b><br>
				Created by the studio <a target="_BLANK" href="http://aatoaa.com/" class="about-name-link"><b>AATOAA</b></a><br>
				Produced by <a target="_BLANK" href="https://nfb.ca/" class="about-name-link"><b>the National Film Board of Canada</b></a><br><br>
				<a target="_BLANK" class="about-link" onclick="if (window.MenuController) {window.MenuController.openCredits();}">Full credits</a><br>
			</div>
			<br>
			<div class="text-spacer-line"></div>
			<br>
			You’re inside the head of D., a young girl who’s livestreaming her brain activity during a new kind of treatment session. Online, along with other participants, you massage her brain, experiencing the free flow of her thoughts, emotions and obsessions. Now, she’s telling you about her weekend. Earlier, her anxiety played some catchy beats that still lurk in one corner of her mind—like an earworm that won’t let go. Keep massaging. It’s doing her some good. In exchange, she’s giving you a guided tour of what’s going on inside her head, empowered by your gestures and stimulating presence. D. opens up as her mind wanders—perhaps a little bit like your own mind. Brainstream is an interactive animated film that explores, with sensitivity and humour, the mysteries of brain activity and the unpredictable trajectory of our thoughts.<br>
			<br>
			<div class="text-spacer-line"></div>
			<br>
			<a target="_BLANK" class="about-link" href="https://mediaspace.nfb.ca/epk/brainstream/">Link to the press kit</a><br>
			<br>
			<div class="text-spacer-line"></div>
			<br>
			<b>Legal notices</b><br><br>
			Brainstream uses “cookies” to allow you to enjoy the experience over several sessions without losing your progress. We do not collect any personal data at any time during the experience. If you do not want the NFB to install a cookie on your device, you can disable cookies in your browser. If you do so, your progress in Brainstream will not be saved when you close your browser window.<br>
		`,
		"credits": `
			Created by the studio <b>AATOAA</b><br>
			<br>
			Produced by <b>the National Film Board of Canada</b><br>
			<br>
			<div class="credits-spacer-small"></div>
			<br>
			Directed and Animated by<br>
			<b>Caroline Robert (AATOAA)</b><br>
<br>
			Coded by<br>
			<b>Édouard Lanctôt-Benoit (AATOAA)</b><br>
<br>
			Music, Sound Design and Audio Supervision<br>
			<b>Mathieu Charbonneau</b><br>
<br>
			Production Manager and Consultant to the Director<br>
			<b>Vincent Morisset (AATOAA)</b><br>
<br>
			Voice of D. and Little Sister<br>
			<b>Sophie Shields-Rivard</b><br>
<br>
			Voice of the Obsessions<br>
			<b>Caroline Robert</b><br>
<br>
			Written by<br>
			<b>Caroline Robert</b><br>
<br>
			Monologue Writers/Consultants<br>
			<b>Carl Bessette<br>
			Julianne Côté</b><br>
<br>
			Research<br>
			<b>Alix Gagnon</b><br>
<br>
			English Translation<br>
			<b>J.C. Sutcliffe</b><br>
<br>
			Sound Mix Consultant<br>
			<b>Bernard Gariépy Strobl</b><br>
<br>
			Assistant Sound Designer - English Monologue<br>
			<b>Nataq Huault (Bande à part)</b><br>
<br>
			Editor - Trailer<br>
			<b>Jules Saulnier</b><br>
<br>
			<div class="credits-spacer-small"></div>
			<br>
			National Film Board of Canada<br>
<br>
			Producers<br>
			<b>Marie-Pier Gauthier<br>
			Isabelle Repelin</b><br>
<br>
			Executive Producer<br>
			<b>Louis-Richard Tremblay</b><br>
<br>
			Head of Production<br>
			<b>Marie-Ève Babineau</b><br>
<br>
			Senior Production Coordinators<br>
			<b>Stéphanie Quevillon<br>
			Véronique Tessier</b><br>
<br>
			Administrator<br>
			<b>Marie-Andrée Bonneau</b><br>
<br>
			Senior Production Coordinator - Administration<br>
			<b>Isabelle Limoges</b><br>
<br>
			Production Coordinators - Administration<br>
			<b>Isabelle Gatti<br>
			Evelyne Cortes Oquendo</b><br>
<br>
			Technology Director<br>
			<b>Martin Viau</b><br>
<br>
			Information Technology<br>
			<b>Sergiu Suciu</b><br>
<br>
			Interactive Media Coordinator<br>
			<b>Caroline Fournier</b><br>
<br>
			Voice Recording<br>
			<b>Geoff Mitchell</b><br>
<br>
			Technical Coordinator (post-production)<br>
			<b>Mira Mailhot</b><br>
<br>
			Project Manager, Digital Production<br>
			<b>Catherine Perreault</b><br>
<br>
			Editorial Manager<br>
			<b>Valérie Darveau</b><br>
<br>
			Legal Services<br>
			<b>Christian Pitchen</b><br>
<br>
			Marketing Manager<br>
			<b>Laurianne Désormiers</b><br>
<br>
			Marketing Coordinator, Audiovisual & Digital<br>
			<b>Angel Carpio</b><br>
<br>
			Marketing Coordinator<br>
			<b>Éric Bondo</b><br>
<br>
			Social Media Strategists<br>
			<b>Emilie Nguyen Ngoc<br>
			Hannah Martin</b><br>
<br>
			Community Managers<br>
			<b>Alyssia Duval-Nguon<br>
			Melissa Sauvé</b><br>
<br>
			<div class="credits-spacer-small"></div>
			<br>
			Press Relations<br>
			<b>MingoTwo</b><br>
<br>
			Additional Music<br>
			<b>"Les gens qui m'aiment", Jimmy Hunt (2020)<br>
			with the kind collaboration of Bravo Musique</b><br>
			<br>
			<div class="credits-spacer-small"></div>
			<br>
			Special thanks<br>
<br>
			Thank you for your outside eye and precious advice,<br>
			<b>Laura Nadeau<br>
			J-F Nadeau<br>
			my nephew, Ravenne Portail<br>
			Simone Stämpfli</b><br>
<br>
			Thank you for inspiring me,<br>
			<b>all the young girls who agreed to answer my questions,<br>
			all the teenagers in my life and their parents.</b><br>
<br>
			Thank you, from the bottom of my heart,<br>
			<b>Gabi<br>
			Vincent<br>
			Ed<br>
			my sisters, Fanny Morel and Sophie Robert<br>
			my nephew and niece, Louis and Sybille Morel<br>
			my parents, Chantal and Gérard Robert<br>
			my grandparents, Fernande and Aimé Robert</b><br>
<br>
			<b>In memory of my grandmother Maïck (Yvonne Zulemanian), who loved classic French songs.<br>
			And in memory of my uncle Patou (Patrick Thomas), who loved the Rolling Stones.</b><br>
<br>
			Thank you to my drawing club, La Mine:<br>
			<b>Joël Vaudreuil, Isabelle Brouillette, Stéphane Lafleur, François Turcotte, Caroline Lavergne, Maxime Veilleux, Catherine Leduc and Matthieu Beaumont</b><br>
<br>
			And thank you to my talented and inspiring friends of all ages and to the generous people around me, who, whether consciously or not, helped make this film a reality.<br>
			<b>Laurence Simard-Émond, Jules, Guillaume, Renaud, Paul and Antoine Avril<br>
			Stéphanie Béliveau<br>
			Henry Bernadet<br>
			Stéphane Buellet<br>
			Gabriel Luciani and Pascal Brouard<br>
			Anne, Alice and Marc-André Chaput<br>
			Yann Dechatrette<br>
			Carole De Haro<br>
			Sophie Bisson, Olive and Thibaut Duverneix<br>
			Miryam Bouchard, Alice and PM Fortin<br>
			Daniel Sanche and Sarah Fortin<br>
			Chloé B. Fortin<br>
			Ludivine Jacquemier<br>
			Annie Jaimes<br>
			Philippe Lambert<br>
			Christina Maurey<br>
			Thea Metcalfe, Miro and Sean Michaels<br>
			Aymeric Morel<br>
			Claudette Raymond, Marie-Ève, Olivier, Jérémie, Antoine and Paul Morisset<br>
			Daniel and Martine Moussy<br>
			Julien Paget<br>
			Nathalie Loignon, Jeanne, Henri, Laure and Mathieu Pellerin<br>
			Raphaëlle Nadeau and Madeleine Péloquin<br>
			Jean-Michel Portail<br>
			Isabelle de Bie, Laurent, Pierre and Yves Renaud<br>
			Gustave, Laurier, Catherine D'Amour and Nicolas S. Roy<br>
			Annie Saint-Pierre<br>
			Mélanie Chabot, Victor and Nicolas Stämpfli<br>
			Monique Simard<br>
			Sophie Cadieux, Oscar and Mani Soleymanlou<br>
			Hugues Sweeney<br>
			Iris, Jessica and Brice Thomas<br>
			Josiane La Pouette Thomas<br>
			Sophie Galipeau, Eli, Sam and Dominic Turmel</b><br>
			<br>
		`,
		"share": `
			<div class="share-large">
				<div class="share-btn" id="share-facebook">
					<div id="facebook-logo"></div>
					<div class="share-text">Facebook</div>
				</div>
			</div>
			<div class="share-large">
				<div class="share-btn" id="share-twitter">
					<div id="twitter-logo"></div>
					<div class="share-text">Twitter</div>
				</div>
			</div>
			<div class="share-large" id="share-link-container">
				<div class="share-btn" id="share-btn-link">
					<div id="sharelink-logo" id="share-link"></div>
					<div class="share-text">Copy Link</div>
				</div>
			</div>
		`
	},

	"analytics": `
		<!-- Facebook Pixel Code -->
		<script>
		  !function(f,b,e,v,n,t,s)
		  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
		  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
		  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
		  n.queue=[];t=b.createElement(e);t.async=!0;
		  t.src=v;s=b.getElementsByTagName(e)[0];
		  s.parentNode.insertBefore(t,s)}(window, document,'script',
		  'https://connect.facebook.net/en_US/fbevents.js');
		  fbq('init', '481568595855542');
		  fbq('track', 'PageView');
		</script>
		<noscript>
		  <img height="1" width="1" style="display:none" 
		       src="https://www.facebook.com/tr?id=481568595855542&ev=PageView&noscript=1"/>
		</noscript>
		<!-- End Facebook Pixel Code -->
		`,

	"fonts": {
		"online": `<link rel='preconnect' href='https://fonts.gstatic.com' crossorigin>
		<link rel='preload' as='style' href='https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=block'>
		<link rel='stylesheet' href='https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=block'>
		`,
		"local": `<link rel='preload' as='style' href='css/worksans.css'>
		<link rel='stylesheet' href='css/worksans.css'>
		`
	}
}