import $ from 'jquery';
import FastEvent from './FastEvent.js';
/*

	<div class="ui-item">
        <div class="ui-color-code"></div>
        <div class="ui-label-folder">Close Input</div>
    </div>

*/
function HideButton(UI) {
	if (!UI.enabled) return;

	//
	// Create the div hierarchy
	//
	var containerDiv = document.createElement('div'),
		labelDiv = document.createElement('div');
	this.domElement = containerDiv;
	this.isOpen = true;
	$(labelDiv).text('Hide GUI');

	window.labelDiv = labelDiv;

	containerDiv.appendChild(labelDiv);

	//
	// Set the classes and basic properties
	//
	$(containerDiv).addClass('ui-hide-button');
	$(labelDiv).addClass('ui-label-hide-button');

	
	//
	// props
	//
	this.action = function(){
		if (UI.mainFolder) {
			UI.mainFolder.toggleClose();
		}
		$(labelDiv).text(
			UI.mainFolder.isOpen ? 'Hide GUI' : 'Show GUI'
		);
	};


	//
	// init the properties
	//
	this.init = function(propName) {
		

	}.bind(this);


	//
	// ACTION
	//
	$(containerDiv).on('click touchstart', function(e) {
	
		this.action();

	}.bind(this));



	//
	// Clean up
	//
	this.dispose = function() {

		$(containerDiv).off();
		$(labelDiv).off();

		$(containerDiv).remove();
		$(labelDiv).remove();

		this.domElement = null;
		containerDiv = null;
		labelDiv = null;
	};
};

export default HideButton;
