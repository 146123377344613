import SETTINGS from '../Settings.js';

function AnalyticsController() {


	var PROJECT_UA = 'UA-42015401-49';
	var nfb_ua_main_account = "UA-32257069-1";
    if (location.hostname.indexOf('dev') >= 0 || location.hostname.indexOf('localhost') >= 0 || location.hostname.indexOf('stage') >= 0){
    	nfb_ua_main_account = "UA-32257069-11";
    }
	//Google Universal Analytics setup https://developers.google.com/analytics/devguides/collection/analyticsjs/
   	if (!SETTINGS.NFB_MENU) {
   		 (function(i, s, o, g, r, a, m) {
	        i['GoogleAnalyticsObject'] = r;
	        i[r] = i[r] || function() {
	            (i[r].q = i[r].q || []).push(arguments)
	        }, i[r].l = 1 * new Date();
	        a = s.createElement(o),
	            m = s.getElementsByTagName(o)[0];
	        a.async = 1;
	        a.src = g;
	        m.parentNode.insertBefore(a, m)
	    })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

		var _lngType = SETTINGS.LANGUAGE;
		var _domainName = SETTINGS.LANGUAGE === 'fr' ? '.onf.ca' : '.nfb.ca';
		var _intType = 'interactive';
		var _projectName = "brainstream";
		var _projectType = 'website'; //SETTINGS.EVENEMENT_MODE ? "installation" : "website";

		ga('create', nfb_ua_main_account, 'auto', {'name':'onfglobal', 'allowLinker': true, cookieDomain: _domainName, legacyCookieDomain: _domainName});
		ga('create', PROJECT_UA, 'auto', {'name':'onfproject', 'allowLinker': true});
		ga('onfglobal.set', 'language', _lngType);
		ga('onfproject.set', 'language', _lngType);


		// ga('onfglobal.send', 'pageview', '/' + _intType + '/' + _projectName + '/' + _projectType);// /interactif/reve/website
		// ga('onfproject.send', 'pageview', '/' + _intType + '/' + _projectName + '/' + _projectType); // /interactif/reve/website
		
		function trackEventInternal(category, action) {
			if (SETTINGS.INSTALLATION_MODE) return;
			ga('onfglobal.send', {
				dimension1: SETTINGS.LANGUAGE,
				hitType: 'event',
				eventCategory: 'interactive',
				eventAction: category+(action?('_'+action):''),
				eventLabel: 'brainstream_website' //+'_'+_projectType
			});
			ga('onfproject.send', {
				dimension1: SETTINGS.LANGUAGE,
				hitType: 'event',
				eventCategory: 'interactive',
				eventAction: category+(action?('_'+action):''),
				eventLabel: 'brainstream_website' //+'_'+_projectType
			//	dimension1: SETTINGS.LANGUAGE,
			//	hitType: 'event',
			//	eventCategory: category,
			//	eventAction: action,
			//	eventLabel: 'brainstream' //+'_'+_projectType
			});
		};

		// General GA Universal Page view
	    this.pageview = function(page) {
			if (SETTINGS.INSTALLATION_MODE) return;
	    	var _pageview = '/' + _intType + '/' + _projectName + '/' + _projectType;
		    if (page && page.length>1) {
		        if (page.charAt(0) != "/"){
		            page = '/' + page;
		        }
		        _pageview = _pageview + page;
		    }
	    	ga('onfglobal.send', 'pageview', _pageview);
	    	ga('onfproject.send', 'pageview', _pageview);
		};

		this.trackEvent = function(category,action) {
			setTimeout(trackEventInternal.bind(window,category,action), 10);
		};
		this.trackEventDirectly = function(category,action) {
			trackEventInternal(category,action);
		};
		this.pageview( (/index/gi.test(window.location.pathname)||window.location.pathname=='/'||!window.location.pathname) ? '/index.html' : window.location.pathname);


   	} else {

   		var pageviewQueue = [];
		var eventQueue = [];

   		function trackEventInternal(category, action) {
			if (SETTINGS.INSTALLATION_MODE) return;
			if (window.google_analytics && window.google_analytics.gaTrack) {
				window.google_analytics.gaTrack({
					language: SETTINGS.LANGUAGE,
					name: "brainstream",
					ua_code: PROJECT_UA,
					event: category,
					event_label: action
				});
			}
			else {
				eventQueue.push([category,action]);
				console.warn('analytics not loaded');
			}
		};

   		this.trackEvent = function(category,action) {
			setTimeout(trackEventInternal.bind(window,category,action), 10);
		};
		this.trackEventDirectly = function(category,action) {
			trackEventInternal(category,action);
		};
		this.pageview = function(page) {
			if (SETTINGS.INSTALLATION_MODE) return;
			if (window.google_analytics && window.google_analytics.gaTrack) {
				window.google_analytics.gaTrack({
					language: SETTINGS.LANGUAGE,
					name: "brainstream",
					ua_code: PROJECT_UA,
					pageview: page
				});
			}
			else {
				pageviewQueue.push(page);
				console.warn('analytics not loaded');
			}
		}

		this.sendQueue = function() {
			for (var i=0; i<eventQueue.length; i++) {
				console.log("EVENT QUEUE:",eventQueue[i][0], eventQueue[i][1]);
				this.trackEventDirectly(eventQueue[i][0], eventQueue[i][1])
			};
			eventQueue = [];

			for (var i=0; i<pageviewQueue.length; i++) {
				console.log("PAGE VIEW QUEUE:",eventQueue[i][0], eventQueue[i][1]);
				this.pageview(pageviewQueue[i]);
			};
			pageviewQueue = [];

		}




   	}

};
window.AnalyticsController = window.AnalyticsController||new AnalyticsController();

export default window.AnalyticsController;
