import $ from 'jquery';
import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import ABDisposeController from '../controllers/ABDisposeController.js';
import * as THREE from 'three';
import Utils from '../utils/Utils.js';
import JSON6 from 'json-6';



function LoaderXHR(url, dataType) {
	var self = this;

	url = url.replace(/\\/gi,'/');

	this.url = url;
	this.data = {doneCallbacks:[]};
	this.loaded = false;
	this.loading = false;
	this.loadError = false;
	this.progress = 0.0;
	this.weight = 1;
	this.loadType = 'XHR';
	this.dataType = dataType;
	this.doneCallbacks = [];
	this.errorCallbacks = null;

	this.minPageId = 99999;
	this.maxPageId = 0;
	this.isInErrorLoopMode = 0;
	this.loopErrors = true;

	this.loadedBytes = 0;

	this.lastErrorReset = 0;


	// window.allLoadedFiles.push(url);
	if (/undefined/.test(url)) {
		console.warn("UNDEFINED URL",url);
		url = '#';
	}

	//Load text, json or arraybuffer
	this.start = function(completeCallback, errorCallback) {

		//presets bundle
		if (SETTINGS.USE_PRESETS_BUNDLE && AppStatus.presetsBundle && AppStatus.presetsBundle.value)  {
			if (AppStatus.presetsBundle.value[url]) {
				setTimeout(()=>{
					self.data.value = AppStatus.presetsBundle.value[url];
					self.loaded = true;
					self.loading = false;
					self.progress = 1.0;
					if (completeCallback) completeCallback(self);
	                for (var i=0; i<self.doneCallbacks.length; i++) {
						self.doneCallbacks[i](self);
					}
					self.doneCallbacks = [];
					if (self.data) {
						for (var i=0; i<self.data.doneCallbacks.length; i++) {
							self.data.doneCallbacks[i](self);
						}
						self.data.doneCallbacks = [];
					}
				},1)
				return;
			}
		}


		//shaders bundle
		if (SETTINGS.USE_SHADERS_BUNDLE && AppStatus.shadersBundle && AppStatus.shadersBundle.value)  {
			if (AppStatus.shadersBundle.value[url]) {
				setTimeout(()=>{
					self.data.value = AppStatus.shadersBundle.value[url];
					self.loaded = true;
					self.loading = false;
					self.progress = 1.0;
					if (completeCallback) completeCallback(self);
	                for (var i=0; i<self.doneCallbacks.length; i++) {
						self.doneCallbacks[i](self);
					}
					self.doneCallbacks = [];
					if (self.data) {
						for (var i=0; i<self.data.doneCallbacks.length; i++) {
							self.data.doneCallbacks[i](self);
						}
						self.data.doneCallbacks = [];
					}
				},1)
				return;
			}
		}


		//debugging
		if (/m2v|jpeg|jpg/.test(self.url) && SETTINGS.BLOCK_LOADING) {
			return;
		}
		if (/m2v|jpeg|jpg/.test(self.url) && SETTINGS.SLOW_LOADING && !this.slowLoaded) {
			self.loading = true;
			if (!this.slowLoading) {
				this.slowLoading = true;
				setTimeout(function() {
					this.slowLoaded = true;
					this.start(completeCallback, errorCallback);
				}.bind(this),5000);
			} else {
				console.log("retried loading!");
			}
			return;
		}

		if ((self.loading || self.loaded) && !self.isInErrorLoopMode) {
			console.warn('Already loading: ',url);
		}
		self.errorCallback = errorCallback;
		
		if (self.isInErrorLoopMode) console.log("Trying again : load error loop.");
		//
		// Load in background thread instead
		//
		self.loading = true;
		self.loaded = false;

		//start xhr
		var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function() {
        	if (self.loaded || !xhr.status) return;
            if ( xhr.readyState === 4 || xhr.status>=400) {
                self.loaded = true;
                self.progress = 1.0;
                if ( xhr.status >= 200 && xhr.status <= 299 ) {

                	if (self.isInErrorLoopMode) {
                		self.isInErrorLoopMode = 0;
                		if (AppStatus.mainErrorDoneCallback) AppStatus.mainErrorDoneCallback(self);
                	}

                	self.data.loaded = true;
                	self.data.loadError = false;

                    //JSON loading
                    if (dataType === 'json' || dataType === 'json5' || dataType === 'json6') {
                    	// if (!SETTINGS.RELEASE_MODE && dataType == 'json5') self.data.commented = xhr.response + '';

                    	// if (!SETTINGS.RELEASE_MODE) {
                    		try {
								if (dataType === 'json') {
									self.data.value = JSON.parse(xhr.response);
									// self.data.response = xhr.response;
								}
								else self.data.value = JSON6.parse(xhr.response); //remove comments

								self.loadError = false;
                				self.data.loadError = false;
							
							} catch (er) {
								// if (!SETTINGS.RELEASE_MODE && SETTINGS.numErrors<4) {alert('Erreur dans le json: '+self.url+' > '+er); SETTINGS.numErrors++;}
								
								self.loadError = true;
                				self.data.loadError = true;
								xhr.onreadystatechange = null;
								xhr.onprogress = null;
                  			 	xhr.onerror = null;
								xhr = null;
								if (errorCallback) {self.loadError = true; errorCallback(self);}
								for (var i=0; i<self.doneCallbacks.length; i++) {
									self.doneCallbacks[i](self);
								}
								self.doneCallbacks = [];
								for (var i=0; i<self.data.doneCallbacks.length; i++) {
									self.data.doneCallbacks[i](self);
								}
								self.data.doneCallbacks = [];
								return;
							}
                    	
                    } else {
                    	self.data.value = xhr.response;
                    	if (self.data.value) self.loadedBytes = self.data.value.byteLength || self.data.value.size || 0;
                    	self.data.loadedBytes = self.loadedBytes;
                    	self.loadError = false;
                		self.data.loadError = false;
                    }
              
                    //finish loading
	                self.data.loadError = false;
                    xhr.onreadystatechange = null;
                    xhr.onprogress = null;
                    xhr.onerror = null;
                    xhr = null;
                    if (completeCallback) completeCallback(self);
                    for (var i=0; i<self.doneCallbacks.length; i++) {
						self.doneCallbacks[i](self);
					}
					self.doneCallbacks = [];
					if (self.data) {
						for (var i=0; i<self.data.doneCallbacks.length; i++) {
							self.data.doneCallbacks[i](self);
						}
						self.data.doneCallbacks = [];
					}
					

                //handle errors
                } else if (xhr.status>=400) {
                	if (AppStatus.started && !SETTINGS.ERROR_MODE && !self.errorCallbacks && !self.data.noErrorLoop) {

                		self.loadError = true;
                		self.data.loadError = true;
                		self.isInErrorLoopMode++;
                		if (self.isInErrorLoopMode < 5) {
                			if (self.isInErrorLoopMode==2 && errorCallback) errorCallback(self);
                			if (AppStatus.mainErrorCallback) AppStatus.mainErrorCallback(self);
	                		this.errorLoopTimeout = setTimeout(function() {
	                			console.log("looping",self);
	                			this.lastErrorReset = performance.now();
	                			this.start(completeCallback, errorCallback);
	                		}.bind(self), Math.pow(self.isInErrorLoopMode+1, 2.5) * 2000);
                		}

                	} else {
                		self.loadError = true;
                		self.data.loadError = true;
                		xhr.onreadystatechange = null;
	                    xhr.onprogress = null;
	                    xhr.onerror = null;
	                    xhr = null;
	                    if (completeCallback) completeCallback(self);
	                    if (errorCallback) errorCallback(self);

	                    if (self.errorCallbacks) {
	                    	for (var i=0; i<self.errorCallbacks.length; i++) {
								self.errorCallbacks[i](self);
							}
							self.errorCallbacks = [];
						}
                    	for (var i=0; i<self.doneCallbacks.length; i++) {
							self.doneCallbacks[i](self);
						}
						self.doneCallbacks = [];
						if (self.data) {
							for (var i=0; i<self.data.doneCallbacks.length; i++) {
								self.data.doneCallbacks[i](self);
							}
							self.data.doneCallbacks = [];
						}
	                  
	                    
                	}
            	}
            }
        };
        //handle progress
        xhr.onprogress = function(e) {
        	if (e.lengthComputable) {
        		self.progress = e.loaded / e.total;
        	}
        };
         if (self.forceReload) {
         	url = url+'?v='+Math.random();
         }
        xhr.onerror = self.sendError;
        // if (SETTINGS.EDIT_MODE) xhr.timeout = 30000;
        xhr.ontimeout = self.sendError;

        xhr.open(self.data.protocol||"GET", SETTINGS.ASSETS_URL+url, true);

        var mime = 'text/plain';
        if (dataType === 'arraybuffer' || dataType === 'compressedImage') { mime = 'application/octet-stream'; }
        xhr.overrideMimeType(mime);
        if (dataType === 'compressedImage') xhr.responseType = 'arraybuffer';
        else xhr.responseType = (dataType==='json'||dataType==='json5'||dataType==='json6')?'text':dataType;
        if (!dataType) {
        	console.log("undefined datatype");
        }
        if (url === 'undefined') {
        	console.error('UNDEFINED XHR');
        }
        if (self.forceReload) {
        	// xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        	// xhr.setRequestHeader('cache-control', 'no-cache, must-revalidate, post-check=0, pre-check=0');
			// xhr.setRequestHeader('cache-control', 'max-age=0');
			// xhr.setRequestHeader('expires', '0');
			// xhr.setRequestHeader('expires', 'Tue, 01 Jan 1980 1:00:00 GMT');
			// xhr.setRequestHeader('pragma', 'no-cache');
        }

 		if (self) xhr.send(self.data.params||null);
	};
	this.sendError = function() {
		console.log("XHR ERROR!",self.errorCallback);
		self.loadError = true;
		self.data.loadError = true;
		if (self.errorCallback) self.errorCallback(self);
		self.errorCallback = null;
	};
	this.getProgress = function() { return self.progress; };
	this.dispose = function() {
		self.errorCallback = null;
		self.url = self.start = self.getProgress = self.dispose = self.data = null;
	};
	this.reset = function() {
		self.data.loaded = false;
		if (self.data && self.data.value && self.data.value.byteLength) ABDisposeController.dispose(self.data.value);
		self.data.value = self.data.response = null;
		self.loading = self.loaded = false;
		self.data.loadError = self.loadError = false;
		if (this.errorLoopTimeout) {
			window.clearTimeout(this.errorLoopTimeout);
			this.errorLoopTimeout = null;
		}
	}
	this.getWeight = function() {
		return this.weight;
	};
}
window.LoaderXHR = LoaderXHR;
export default LoaderXHR;

