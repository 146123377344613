/**
 *
 * Extends the AudioNode and AudioParam prototypes
 * Adds utility functions to ramp and fade their values
 * tweenToValue is used extensively everywhere for fading volumes and other parameters
 *
 */
(function(window) {

	 window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;

	 if (!window.AudioContext) return;

	if (AudioParam.prototype.tweenToValue) return;


	ConvolverNode.prototype.dispose = function() {
		this.buffer = null;
		console.log("Dispose reverb buffer");
	};

	function getLinearTweenValue(now, info) {
		var t = (now - info.startTime) / (info.fadeTime);
		if (t >= 1.0) return info.endValue;
		if (t <= 0.0) return info.startValue;
		if (isNaN(t)) t = 0.0;
		return (t * (info.endValue - info.startValue) + info.startValue);
	};

	AudioParam.prototype.tweenToValue = function(newv, fadeTime) {
		if (!newv || isNaN(newv)) newv = 0.0;
		if (this.lastv === newv) return;
		this.lastv = newv;
		fadeTime = fadeTime || 0;

		// Just skip very short ramps
		if (fadeTime <= 0.04) {
			this.cancelScheduledValues(0);
			this.value = newv;
			this.tweenInfo = undefined;
			return;
		}

		// Since firefox doesn't implement the "computedValue" property
		// We look it up manually with the audioContext.currentTime and the start/end time of the last ramp
		var now = audioContext.currentTime,
			oldv = this.tweenInfo ? getLinearTweenValue(now, this.tweenInfo) : this.value;
		if (isNaN(oldv)) oldv = this.value;

		this.cancelScheduledValues(0);
		this.value = oldv;
		this.setValueAtTime(oldv,now);
		this.linearRampToValueAtTime(newv,now+fadeTime);
		
		this.tweenInfo = {
			startValue: oldv,
			endValue: newv,
			startTime: now,
			fadeTime: fadeTime
		};
	};

	//
	// Gain Node extensions
	//
	// GainNode.prototype.dispose = function() {
	// 	this.disconnect();
	// 	this.gain.cancelScheduledValues(0);
	// };

	GainNode.prototype.fadeOut = function(t) {
		if (this.isFadingOut) return;
		this.isFadingOut = true;
		this.gain.tweenToValue(0.0, t||0);
		AudioDisposeController.dispose(this, t||0);
	};

})(window);

export default {};
