function addGuiOption(currentFolder,options,ranges,guiCallbacks) {
	for (var option in options) {
		if (ranges[option] && ranges[option].isFolder) {
			var nfolder = currentFolder.addFolder(option);
			if (!ranges[option].isOpen) nfolder.close(); else nfolder.open();
			addGuiOption(nfolder,options[option],ranges[option],guiCallbacks ? guiCallbacks[option] : undefined);
		} else {
			if (!ranges[option] || !ranges[option].hidden) {
				var ctl;
				var copts = options;
				var coptName = option;
				if (options[option] && options[option].value!==undefined) {
					copts = options[option];
					coptName = 'value';
				}
				if (ranges[option] && ranges[option].min!==undefined && ranges[option].max!==undefined) ctl = currentFolder.add(copts, coptName, ranges[option].min, ranges[option].max, ranges[option].isFloat?0.01:undefined).listen();
				else if (ranges[option] === 'color') ctl = currentFolder.addColor(copts, coptName).listen();
				else if (ranges[option]) ctl = currentFolder.add(copts, coptName, ranges[option]).listen();
				else ctl = currentFolder.add(copts, coptName).listen();
				// window.ctl = ctl;
				if (options[option] && options[option].value!==undefined) {
					ctl.name(option);
				}

				if (guiCallbacks && guiCallbacks[option] && guiCallbacks[option].isOnChange) ctl.onChange(guiCallbacks[option]);
				else if (guiCallbacks && guiCallbacks[option]) ctl.onFinishChange(guiCallbacks[option]);

				if (ranges[option] && ranges[option].isInt) {ctl.__step = 1;}
				// else if (ranges[option] && ranges[option].isFloat) {
				// 	if (option === "substract") window.ctl = ctl;
				// 	ctl.step(0.01);
				// 	ctl.__step = 0.01; ctl.__impliedStep = 0.01; ctl.__precision = 0.01; ctl.updateDisplay().__step = 0.01; ctl.updateDisplay().__impliedStep = 0.01; ctl.updateDisplay().__precision = 0.01;}
			}
		}
	}
};
export default addGuiOption;