import $ from 'jquery';
import FastEvent from './FastEvent.js';
import Utils from './Utils.js';
import Autofill from './Autofill.js';

/*

	<div class="ui-item">
        <div class="ui-color-code"></div>
        <div class="ui-label">input checkbox</div>
        <input class="ui-te-input" type="text" value=""></input>
    </div>

*/
function Dropdown(UI) {
	if (!UI.enabled) return;

	//
	// Create the div hierarchy
	//
	var containerDiv = document.createElement('div'),
		colorCodeDiv = document.createElement('div'),
		labelDiv = document.createElement('div'),
		dropdownDiv = document.createElement('select');
	this.domElement = containerDiv;
	this.labelDiv = labelDiv;



	containerDiv.appendChild(colorCodeDiv);
	containerDiv.appendChild(labelDiv);
	containerDiv.appendChild(dropdownDiv);

	//
	// Set the classes and basic properties
	//
	$(containerDiv).addClass('ui-item');
	$(colorCodeDiv).addClass('ui-color-code');
	$(labelDiv).addClass('ui-label');
	$(dropdownDiv).addClass('ui-dropdown-input');

	// dropdownDiv.type = 'text';
	dropdownDiv.value = '';


	//-----------------
	//
	// props
	//
	//-----------------
	this.uuid = Math.floor(Math.random() * 100000000000).toFixed(0);
	this.value = false;
	this.propName = '';
	this.toWatch = {};
	this.changing = false;
	this.callback = null;
	this.values = [];
	this.path = [];
	this.shouldSend = true;
	this.folder = null;
	this.lastAutofillFolder = null;
	this.autofillExtensions = null;
	this.autofillArrays = null;
	this.props = null;
	this.lastValue = null;
	this.relativeValue = null;






	//-------------------------
	//
	// object.observe change
	//
	//--------------------------
	this.updateValue = function(evt, changes) {
		//prevent double changes / infinite loops
		if (this.changing)
			return;
		
		this.changing = true;
		var lv = this.value;
		this.value = this.toWatch[this.propName];
		if (lv !== this.value) {this.shouldUpdateUI = true; this.updateUI();}
		if (this.callback && lv !== this.value) this.callback.apply(this, [this.value]);
		this.changing = false;
		
	}.bind(this);

	this.updateValueUI = function() {

		// this.shouldUpdateUI = true;
		// this.updateUI();
		this.updateValue();

	}.bind(this);



	// var autoArray = function(evt, changes) {
	// 	if (this.propName === 'source') {
	// 		// console.log('autofill',this, this.autofillArrays, UI.currentSoundEngine.getNamedArray('allModulators'));
	// 	}
	// 	var arr = [];
	// 	for (var j=0; j<this.autofillArrays.length; j++) {
	// 		var na = this.autofillArrays[j];
	// 		if (typeof na === "string") na = UI.currentSoundEngine.getNamedArray(na.replace('SoundEngine.',''));
	// 		arr = arr.concat(na);
	// 	}
	// 	this.updateRange(arr);

	// }.bind(this);


	//
	// init the properties
	//
	this.init = function(toWatch, propName, props, path) {
		


		// $(dropdownDiv).css('width', $(containerDiv).width()+'px');

		this.propName = propName;
		this.toWatch = toWatch;
		this.path = path;
		this.props = props;
		if (props.shouldSend!== undefined) this.shouldSend = props.shouldSend;
		props.values = props.values||[];
		//add options
		for (var i=0; i<props.values.length; i++) {
			var d = document.createElement('option');
			d.value = props.values[i];
			$(d).text(props.values[i]);
			dropdownDiv.appendChild(d);
		}
		this.values = props.values;


		// $(dropdownDiv).on('change', this.updateValue);

		this.updateValue(null, [{name:propName}]);


		$(labelDiv).text(propName);
		$(colorCodeDiv).css('background-color', props.colorCode || '#'+Math.floor(Math.random()*0xffffff).toString(16));

		UI.watchProperty(this.toWatch, this.propName, this.updateValueUI);

		FastEvent.on(toWatch, this.propName+'-random', function() {
			this.toWatch[this.propName] = Utils.randoma(this.values);
			UI.triggerProperty(this.toWatch, this.propName);
		}.bind(this));

		this.callback = props.callback;

		this.autofillBaseValues = props.autofillBaseValues||[];
		if (props.autofill) {
			this.autofillSort = props.autofillSort;
			this.autofillExtensions = props.autofillExtensions;
			if (this.autofillExtensions && this.autofillExtensions.mp3) this.autofillExtensions['ogg'] = true;
			this.autofillAllowFolders = props.autofillAllowFolders;
			this.autofill(props.autofill);

			if (props.autofillEvent) {
				// (function(props,self) {
				// 	$(self.props).on('autofill', function(evt, newFolder) {
				// 		self.autofill(newFolder);
				// 	});
				// })(props,this);
				FastEvent.on(props, 'autofill', function(evt) {
					this.autofill(evt.data);
				}.bind(this));
			}
		}


		FastEvent.on(props, 'updateRange', function(evt) {
			this.shouldUpdateRange = true;
			this.updateRange(props.values);
		}.bind(this));
		//  else if (props.autofillArrays) {
		// 	this.autofillArrays = props.autofillArrays;
		// 	for (var i=0; i<props.autofillArrays.length; i++) {
		// 		var arr = props.autofillArrays[i];
		// 		if (typeof arr === 'string') {
		// 			// this.soundEngine.addNamedArraysCallbacks(arr, autoArray);
		// 			FastEvent.on(SoundEngine, 'updateArray-'+arr.replace('SoundEngine.',''), autoArray);
		// 		} else {
		// 			//Object.observe(arr, autoArray);
		// 		}

		// 		// 
		// 		// if (typeof arr === "string") arr = UI.getNamedArray(arr);
		// 		// Object.observe(arr, autoArray);
		// 		// console.log('AUTO ARRAY',arr);
		// 	}
		// 	autoArray();
		// }


		// $(this.toWatch).bind('modulateDropdown-'+this.propName, function(e,value) {
		// 	this.toWatch[this.propName] = this.values[value];
		// }.bind(this));
		this.props.numValues = this.values.length;


		//
		// Midi link
		//
		// this.props = props;
		// UI.midiEnable(props, props.midiChannel, labelDiv);
		// FastEvent.on(props,'midiMessage', function(e,channel, note, velocity) {
		// 	var value = Math.floor( Utils.cmap(velocity, 0, 127, 0, this.values.length-1) );
		// 	if (UI.shiftPressed) {
		// 		if (this.relativeValue === null) {
		// 			this.relativeValue = this.value;
		// 		}
		// 		value = this.relativeValue + Utils.cmap(velocity, 0, 127, -64, 64);
		// 		value = Math.floor(value);
		// 	} else {
		// 		this.relativeValue = null;
		// 	}
		// 	var lv = this.toWatch[this.propName];
		// 	this.toWatch[this.propName] = this.values[value];
		// 	if (lv!==this.toWatch[this.propName]) SoundEngine.triggerPropertyUI(this.toWatch, this.propName);
		// }.bind(this));

		// $(dropdownDiv).css('width', '75%');
		// $(dropdownDiv).select2({
		// 	width: '100%'
		// })
		// .on('select2-open', function() {
//          // however much room you determine you need to prevent jumping
//          var requireHeight = $("#select2-drop").height()+10;
//          var viewportBottom = $(window).height() - $("#line_item").offset().top;

//          // figure out if we need to make changes
//          if (viewportBottom < requireHeight)
//          {
//              // determine how much padding we should add (via marginBottom)
//              var marginBottom = requireHeight - viewportBottom;

//              // adding padding so we can scroll down
//              $(".aLwrElmntOrCntntWrppr").css("marginBottom", marginBottom + "px");

//              // animate to just above the select2, now with plenty of room below
//              $('html, body').animate({
//                  scrollTop: $("#select2-drop").offset().top - 10
//              }, 1000);
//          }
//      });


	}.bind(this);


	this.setLabel = function(newName) {
		$(labelDiv).text(newName);
	};

	//
	// Input change
	//
	$(dropdownDiv).on('change',function() {
		if (this.changing) return;
		FastEvent.trigger(this.toWatch, 'ui-update');
		var lv = this.toWatch[this.propName];
		this.toWatch[this.propName] = dropdownDiv.value;
		if (lv!==this.toWatch[this.propName]) UI.triggerProperty(this.toWatch, this.propName);
		this.updateValue();
	}.bind(this));

	// $(dropdownDiv).on('focus',function(e) {
	// 	$('meta[name=viewport]').attr("content", "minimum-scale=1.0, maximum-scale=1.0, user-scalable=no");
	// });
	// $(dropdownDiv).focusout(function(e) {
	// 	$('meta[name=viewport]').attr("content", "user-scalable=yes");
	// });

	
	//
	// Update UI 
	//
	this.canUpdateUI = true;
	this.shouldUpdateUI = false;
	this.shouldUpdateRange = false;
	this.updateRangeValues = null;

	// this.lastUpdateFrame = -1;
	this.updateUI = function() {
		if (!this.canUpdateUI) return;
		if (this.shouldUpdateRange) this.updateRange(this.updateRangeValues);
		if (!this.shouldUpdateUI) return;
		this.shouldUpdateUI = false;
		if (this.lastUpdateFrame === UI.currentFrame) return;
		this.lastUpdateFrame = UI.currentFrame;
		
		if (!dropdownDiv) return;
			this.changing = true;
		dropdownDiv.value = this.value;
		this.changing = false;
		
	}.bind(this);



	//update range
	this.updateRange = function(values) {
		if (this.disposed) return;
		if (!this.canUpdateUI) {this.shouldUpdateRange = true; this.updateRangeValues = values||this.values; return;}
		this.updateRangeValues = null;
		this.shouldUpdateRange = false;

		var ov = this.toWatch[this.propName];

		$(dropdownDiv).empty();

		var valueChanged = true;

		//add options
		for (var i=0; i<values.length; i++) {
			var d = document.createElement('option');
			d.value = values[i];
			$(d).text(values[i]);
			dropdownDiv.appendChild(d);

			if (ov === values[i]) {
				valueChanged = false;
				dropdownDiv.value = ov;

				// var lv = this.toWatch[this.propName];
				// this.toWatch[this.propName] = ov;
				// if (lv!==this.toWatch[this.propName]) SoundEngine.triggerPropertyUI(this.toWatch, this.propName);
			}
		}
		this.values = values;

		if (valueChanged && !UI.settingUp) {
			dropdownDiv.value = values[0];
			
			var lv = this.toWatch[this.propName];
			this.toWatch[this.propName] = values[0];
			if (lv!==this.toWatch[this.propName]) UI.triggerProperty(this.toWatch, this.propName);
		}

		this.props.numValues = this.values.length;

		//if (values.length>0) this.toWatch[this.propName] = values[0];

		FastEvent.trigger(this, 'rangeUpdate');

	}.bind(this);

	//
	// Autofill
	//
	this.autofill = function(folder) {
		folder = folder || this.folder;

		if (!folder) {
			console.warn('trying to autofill with no target folder');
			return;
		}

		if (this.lastAutofillFolder === folder) {
			return;
		}
		this.lastAutofillFolder = folder;

		Autofill(this.props, UI.autofillEnabled, function(list) {
			this.updateRange(list);
		}.bind(this));



		// allZoomPlanePresets = Loader.addAutoFill(batchName, {folder: 'data/presets/visuel/zoomplanes/', "recurseFolders": true});

		// $.ajax({
		// 	type: "POST",
		// 	data:{ 
		// 		folder: folder,
		// 		allowFolders: this.autofillAllowFolders
		// 	},
		// 	url: window.location.origin + window.location.pathname.replace(/index.html(.*)/gi,'')+'build/auto_fill_folder.php',
		// 	success: function(e) {
		// 		try {
		// 			var list = JSON.parse(e).value;
		// 			var nlist = list;
		// 			//only use extensions
		// 			if (this.autofillExtensions) {
		// 				nlist = [];
		// 				for (var i=0; i<list.length; i++) {
		// 					var ext = list[i].split('.').pop();
		// 					if (this.autofillExtensions[ext]) nlist.push(list[i]);
		// 				}
		// 			}

		// 			if (this.autofillSort) {
		// 				nlist = nlist.sort();
		// 			}

		// 			if (this.autofillBaseValues) {
		// 				nlist = this.autofillBaseValues.concat(nlist);
		// 			}

		// 			this.updateRange(nlist);
		// 		} catch (er) {
		// 			console.warn(e,er);
		// 		}
				

		// 	}.bind(this),
		// 	error: function(e) {
		// 		console.log("Couldn't autofill folder: ",folder);
		// 	}
		// });
	};


	$(labelDiv).on('click', function(e) {
		if (e.altKey) {
			Utils.copyToClipboard(labelDiv, this.toWatch[this.propName]);
				// this.path.concat(this.propName).join('.'));
		}
	}.bind(this));
	
	//
	// Clean up
	//
	this.disposed = false;
	this.dispose = function() {
		if (this.disposed) return;
		this.disposed = true;

		// UI.midiDisable(this.props, this.props.midiChannel, labelDiv);
		// FastEvent.off(this.props, 'midiMessage');


		UI.unwatchProperty(this.toWatch, this.propName, this.updateValueUI);
		// if (this.autofillArrays) {
		// 	for (var i=0; i<this.autofillArrays.length; i++) {
		// 		var arr = this.autofillArrays[i];
		// 		if (typeof arr === "string") {
		// 			// arr = UI.getNamedArray(arr);
		// 			// this.soundEngine.addNamedArraysCallbacks(arr, autoArray);
		// 			FastEvent.off(SoundEngine, 'updateArray-'+arr.replace('SoundEngine.',''), autoArray);
		// 		} else {
		// 			//Object.unobserve(arr, autoArray);
		// 		}
		// 	}
		// }
		this.autofillArrays = [];
		$(dropdownDiv).off('change', this.updateValue);

		this.callback = null;
		$(containerDiv).off();
		$(colorCodeDiv).off();
		$(labelDiv).off();
		$(dropdownDiv).off();

		$(containerDiv).remove();
		$(colorCodeDiv).remove();
		$(labelDiv).remove();
		$(dropdownDiv).remove();

		this.domElement = null;
		this.labelDiv = null;
		containerDiv = null;
		colorCodeDiv = null;
		labelDiv = null;
		dropdownDiv = null;
	};

};
export default Dropdown;