// import MobileDetect from 'mobile-detect';

function SETTINGS() {

		this.INTERNAL_VERSION = 14;
		this.VERSION = window.APP_VERSION || 1.0;
		


		//-----------------
		//	
		// Check for window parameters
		// For Debugging or specific-resolution modes
		//
		//-----------------
		var queryParameters = window.location.search.substr(1).split('&').concat(window.location.hash.substr(1).split('&'));
		var params = {};
		for (var i=0; i<queryParameters.length; i++) {
			var pair = queryParameters[i].split('=');
			if (pair.length > 1) params[pair[0]] = pair[1]; else params[queryParameters[i]] = true;
		}
		this.params = params;
		
		this.ASSETS_URL = '';
		if (window.appConfig) this.ASSETS_URL = window.appConfig.static_root;
		this.IMAGES_URL = this.ASSETS_URL+"images/"; 
		this.DATA_URL = this.ASSETS_URL+'data/'; 
		this.VIDEOS_URL = this.ASSETS_URL+'videos/';
		this.RELEASE_MODE = params['release']||params['kiosk']||params['prod'];
		if (this.RELEASE_MODE) window.oneAlert = true; //prevent alerts
		this.DEBUG_MODE = params["debug"];

		this.IS_LOCAL = !(/\.com|\.ca|\.net|\.io/gi.test(window.location.href)) && !params["prod"];


		// this.IS_LOCAL = false;
		this.INSTALLATION_MODE = params["installation"] || /installation/.test(window.location.pathname);
		if (this.INSTALLATION_MODE || params["live"] || params["presentation"]) {
			this.IS_LOCAL = this.IS_LOCAL && !/installation_en|installation_fr/.test(window.location.pathname);
		}


		//standalone web append
		this.STANDLONE = false;
		if (window && window.navigator &&("standalone" in window.navigator) && window.navigator.standalone){
			// this.IS_LOCAL = false;
			this.STANDLONE = true;
		}

		this.PRODUCTION_MODE = !this.IS_LOCAL || params["prod"];

		this.COPRESENCE_SERVER = params["copresence"]||window.WS_URL||'brainstream-srv.nfb.ca/copresence';
		// (window.location.hostname+':6060')
		// if (!this.IS_LOCAL) this.COPRESENCE_SERVER = "brainstream-srv.nfb.ca/copresence";

		//-----------------
		//
		// Mobile detection
		//
		//-----------------
		this.isMobile = navigator.userAgent.toLowerCase().match(/(ipad|iphone|ipod|android|webos|blackberry|tablet|mobile|iemobile|windows phone)/gi) ? true : false;
		this.isDesktop = !this.isMobile;
		this.isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/gi) ? true : false;
		this.isAndroid = navigator.userAgent.match(/Android/gi) ? true : false;
		this.isIOS = (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
		this.isFirefox = navigator.userAgent.match(/Firefox/gi) ? true : false;
		this.isMac = navigator.platform.toUpperCase().indexOf('MAC')>=0;

		this.isChrome = navigator.userAgent.match(/Chrome/gi) ? true : false;
		this.isSafari = !this.isChrome && navigator.userAgent.match(/Safari/gi) ? true : false;

		if (this.isSafari && !this.isTablet) {
			this.isTablet = navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
			if ('ontouchend' in document) this.isTablet = true;
		}
		if (this.INSTALLATION_MODE && ('ontouchend' in document))  {
			this.isTablet = this.isMobile = true; this.isIOS = true; this.isSafari = true;
		}

		//get ios VERSION
		this.IOS_VERSION_15 = false;
		if (this.isIOS) {
			  var matches = /OS (\d\d?_\d(_\d)?)/.exec(navigator.userAgent)
			  if (matches) {
			  	var parts = matches[1].split('_');
			  	if (parts[0]) this.IOS_VERSION_15 = parseInt(parts[0],10) >= 15;
			  }
		}


		// try {
		// 	window.mobileDetect = new MobileDetect(navigator.userAgent);
		// 	this.isTablet = this.isTablet||window.mobileDetect.tablet();
		// } catch (err) {}
		if (this.isTablet) this.isMobile = true;


		//-----------------
		//
		// Support and error modes
		//
		//-----------------
		this.FORCE_SUPPORT = params["support"];
		if (params['forceios']) {this.isIOS = true; this.isAndroid = false; this.FAKE_IOS = true;}
		this.FORCE_ERROR = params["error"];

		this.SCENE = params["s"];

		this.TIMELINE_MODE = this.SCENE == 'timeline' || !this.SCENE;

		this.MOUSEMOVE_MODE = !this.isMobile && !params["mousedrag"];
		// && params["mousemove"]; //(this.TIMELINE_MODE||params["mousemove"]);
		this.MOUSEGRAB_MODE = !this.isMobile && params["mousegrab"]; //(this.TIMELINE_MODE||params["mousemove"]);
		this.MOUSEGRAB2_MODE = !this.isMobile && params["mousegrab2"]; //(this.TIMELINE_MODE||params["mousemove"]);
		this.MOUSECAPTURE_MODE = !this.isMobile && params["mousecapture"]; //(this.TIMELINE_MODE||params["mousemove"]);


		this.BASE_LINEWIDTH = 0.0027;

		this.BASE_PRESET = params["p"];
		if (this.BASE_PRESET && !/json/.test(this.BASE_PRESET)) this.BASE_PRESET = this.BASE_PRESET+".json";

		this.LINE_RESOLUTION = 512; //params["resolution"]?parseInt(params["resolution"],10):256;
		this.DISABLE_SVGC = params["svg"];
		this.JUMP_TIME = params["time"]||params["seek"];


		//-----------------
		//
		// Experimental animations mode
		//
		//-----------------
		this.CLICK_WAVE_MODE = params["wave"];
		// this.CLICK_ADVANCE_MODE = params["advance"];
		this.CLICK_MODE = true; //params["click"];
		this.CLICK_MODE_2 = params["click2"];
		if (this.CLICK_MODE_2) this.CLICK_MODE = true;

		this.TRACKPAD_MODE = params["trackpad"];
		this.TRACKPAD_MODE_2 = params["trackpad2"];
		this.SIDES_CURVE_MODE = true; //params["sides"];
		this.RANDOM_CURSOR = params["randomcursor"];
		this.RANDOM_DEFORM = params["randomdeform"];
		this.RANDOM_SHAKE = params["randomshake"];
		if (this.RANDOM_CURSOR || this.RANDOM_DEFORM || this.RANDOM_SHAKE) {
			this.SIDES_CURVE_MODE = true;
			this.CLICK_MODE = true;
		}
		this.RANDOM_CURSOR_LENT = params["randomcursorlent"];
		if (this.RANDOM_CURSOR_LENT) this.RANDOM_CURSOR = true;

		this.RANDOM_CURSOR = true;
		this.SIDES_CURVE_MODE = true;
		this.CLICK_MODE = true;

		this.EXTRAS_ENABLED = params["extras"];



		//-----------------
		//
		// Select timeline file based on params or language
		//
		//-----------------
		this.INTRO_MODE = params["intro"] && this.TIMELINE_MODE;
		if (this.PRODUCTION_MODE) this.INTRO_MODE = !params["intro"] && this.TIMELINE_MODE;
		// if (this.STANDLONE) this.INTRO_MODE = false;
		if (this.JUMP_TIME) this.INTRO_MODE = false;
		this.LANGUAGE = params['lng']||'fr';
		if (window.document && document.querySelector && document.querySelector('html') && document.querySelector('html').getAttribute('lang')) this.LANGUAGE = document.querySelector('html').getAttribute('lang');
		if (/fr\.|\/fr|\.onf/.test(window.location.href)) this.LANGUAGE = 'fr';
		if (/en\.|\/en|\.nfb/.test(window.location.href)) this.LANGUAGE = 'en';
		if (this.IS_LOCAL) {
			if (params['lng']=='fr' && /en\.|\/en/.test(window.location.href)) window.location.pathname = '/fr.html'
			if (params['lng']=='en' && (/fr\.|\/fr/.test(window.location.href)||window.location.pathname=='/'||window.location.pathname=='index.html')) window.location.pathname = '/en.html'
		}



		this.TIMELINE_FILE = params["t"] ? ("data/"+params["t"]) : (this.LANGUAGE=='fr'?"data/timeline.json":"data/timeline_en.json");
		if (this.TIMELINE_FILE && !/json/.test(this.TIMELINE_FILE)) this.TIMELINE_FILE = this.TIMELINE_FILE+".json";
		if (!this.IS_LOCAL && window.appConfig && window.appConfig.timeline_fr && !params["t"]) {
			this.TIMELINE_FILE = this.LANGUAGE=='fr' ? window.appConfig.timeline_fr : window.appConfig.timeline_en;
		}


		this.MENU_DISABLED = !this.TIMELINE_MODE;

		this.LOGOS_ENABLED = this.TIMELINE_MODE && this.INTRO_MODE;
		if (params["logos"]) this.LOGOS_ENABLED = false;
		this.SHOW_UI = !this.INTRO_MODE;
		if (this.STANDLONE) this.SHOW_UI = false;

		if (params["ui"]!==undefined) this.SHOW_UI = !this.SHOW_UI;

		this.SLOW_LOAD = params["slow"];
		this.SLOW_LOAD_2 = params["slow2"];

		if (this.FORCE_ERROR) this.INTRO_MODE = true;
		this.FORCE_PASSIVE = params["passive"];

		this.SCREEN_RECORD = params["screenrec"];
		this.SKIP_BRANCHES = params["nobranch"] || this.SCREEN_RECORD;
		this.ALL_BRANCHES = params["allbranch"];

		this.CHIFFRE_TEST = params["chiffre"];

		this.NFB_MENU = params["menuonf"];
		if (!this.isMobile) this.NFB_MENU = !this.NFB_MENU;


		this.ELASTIC_MODE = params["elastic"]
		this.CURSOR_HOLD = params["hold"];

		this.GLOBAL_SCALE = params["scale"]||1.0;

		this.UI_LANGUAGE = this.LANGUAGE; //~~change when UI done

		this.NOISE_COLOR = params["noisecolor"];
		this.NOISE_ENABLED = params["noise"] || this.NOISE_COLOR;

		this.AUDIO_EFFECT_EDITOR = !this.TIMELINE_MODE && this.SCENE == "audioeffect";

		this.RELOAD_ENABLED = !this.IS_LOCAL;
		if (params["reload"]) this.RELOAD_ENABLED = !this.RELOAD_ENABLED;
		if (params["reload24"]) {this.RELOAD_ENABLED = true; this.RELOAD_24H = true;}



		if (params["nocookie"]||params["clear"]||params["nosave"]||params["refresh"]||params["qa"]||params["qc"]) this.RELOAD_ENABLED = false;
		if (this.SCREEN_RECORD || this.SKIP_BRANCHES || this.ALL_BRANCHES) {
			this.RELOAD_ENABLED = false;
			this.MENU_DISABLED = !this.ALL_BRANCHES;
			this.SHOW_UI = !!this.ALL_BRANCHES;
			this.NFB_MENU = false;
		}

		
		// this.ENABLE_AUTO_INTENT = false; //this.isAndroid && window.CONFIG.ENABLE_AUTO_INTENT && (navigator.userAgent.match(/Instagram|FBAN|FBAV|FB4A/gi) ? true : false);
		// this.INTENT_LINK = "intent:https://www.motto.io#Intent;end";



		this.IS_TEMP_PAGE = (params["error"]==4 || !!window.appConfig.temp_page) && !params["unlock"];

		if (this.IS_TEMP_PAGE) { // && params["error"]!==4
			if (window.localStorage) {
				try {
					var pressOk = window.localStorage.getItem("press_ok");
					if (pressOk) {
						this.IS_TEMP_PAGE = false;
						this.FORCE_ERROR = false;
						this.TEMP_PAGE_TRACK = pressOk;
					}
				} catch(er) {}
			}
		}
		if (this.IS_TEMP_PAGE) { this.LOGOS_ENABLED = false; this.MENU_DISABLED = false;}

		this.FAST_DEBUG_MODE = params["fastdebug"];
		if (this.FAST_DEBUG_MODE) {
			this.INTRO_MODE = false;
			this.RELOAD_ENABLED = false;
		}


		//-----------------
		//
		// Installation
		//
		//-----------------
		this.HUE_BRIDGE_USERNAME = "H-Vg4EE9UpuX8uJs82aYL1G00VFue6fjDxsC1PUa";
		this.SIMULATION_ENABLED = params["simulation"]||params["simulator"];
		this.LIVE_CINEMA_MODE = params["live"];
		this.PRESENTATION_MODE = params["presentation"];

		if (this.PRESENTATION_MODE) this.RELOAD_ENABLED = false;
		if (this.LIVE_CINEMA_MODE) this.INSTALLATION_MODE = true;

		if (/standalone|fullscreen/gi.test(window.location.href)) this.INSTALLATION_MODE = true;
		if (this.INSTALLATION_MODE) {
			// this.LANGUAGE = 'en';
			this.TIMELINE_FILE = params["t"] || (this.LANGUAGE=='fr'?"data/timeline.json":"data/timeline_en.json");
			
			// this.IS_LOCAL = this.IS_LOCAL && !/installation_en|installation_fr/.test(window.location.pathname);
			this.INTRO_MODE = !params["seek"] && !params["intro"];
			this.PRODUCTION_MODE = true;
			this.NFB_MENU = false;
			this.MENU_DISABLED = false;
			this.MENU_BAR_HIDDEN = true;
			this.SHOW_UI = false;
			if (params["ui"]) this.SHOW_UI = true;
			this.RELOAD_ENABLED = false;
			this.LOGOS_ENABLED = true; 

			this.HUE_ENABLED = !this.PRESENTATION_MODE;
			this.SIMULATION_ENABLED = true;

			this.INSTALLATION_PAUSE_STOP_HUE = params["pausehue"] || true;

			this.LIGHT_2_ENABLED = params["bulb"] || params["ampoule"];

			this.RELOAD_AS_INSTALLATION = params["live"] && params["installation"];
		
		}

		if (this.PRESENTATION_MODE) this.SIMULATION_ENABLED = true;

		if (!this.TIMELINE_MODE && this.SCENE == "editor") this.HUE_ENABLED = true;

		this.USE_PRESETS_BUNDLE = !this.IS_LOCAL || params["bundle"];
		this.USE_SHADERS_BUNDLE = !this.IS_LOCAL || params["bundle"];
		this.REVERB_OPTIM = params["reverboptim"]||!this.IS_LOCAL;

		this.HEADPHONES_MODE = params["headphones"] || params["volume"];
		this.VOLUME_BUTTON_ENABLED = params["volume"];

		this.REAL_HUMANS_SIMULATOR_ENABLED = this.SIMULATION_ENABLED && !params["robot"] && !params["nohumans"] ;

		this.FORCE_5MINUTES = params["5min"]||params["short"];


		//----------------------------------------
		//
		//   AUTO-FTP ON FACEBOOK AND INSTAGRAM  
		// 
		//----------------------------------------
		this.USE_SCREEN_WIDTH = (navigator.userAgent.toLowerCase().search("instagram")>-1) && (window.innerWidth > window.screen.width);
		// if (this.isAndroid) this.USE_SCREEN_WIDTH = false;
		// console.log("ookk2",this.USE_SCREEN_WIDTH,(navigator.userAgent.toLowerCase().search("instagram")>-1), !!this.isIOS , (window.innerWidth > window.screen.width));


		this.ENABLE_AUTO_FTP = this.isIOS && window.appConfig.ENABLE_AUTO_FTP && (navigator.userAgent.toLowerCase().search("messenger")>-1&&!(navigator.userAgent.toLowerCase().search("instagram")>-1)) && (navigator.userAgent.match(/FBAN|FBAV|FB4A/gi) ? true : false);
		this.FTP_LINK = this.LANGUAGE === 'fr' ? window.appConfig.FTP_LINK_FR : window.appConfig.FTP_LINK_EN;

		//blocked tmp page on messenger sorry
		// this.BLOCKED_ON_MESSENGER = false;
		if (this.ENABLE_AUTO_FTP && this.IOS_VERSION_15) {//} && navigator.userAgent.toLowerCase().test("messenger")) {
			this.ENABLE_AUTO_FTP = false;
			// this.BLOCKED_ON_MESSENGER = true;
		}



		this.HEIGHT_TEST = params["height"];
		// if (this.HEIGHT_TEST) this.USE_SCREEN_WIDTH = false;
};

window.SETTINGS = window.SETTINGS||new SETTINGS();
export default window.SETTINGS;
