import * as THREE from 'three';
import Easing from 'easing-functions';

import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import BranchController from '../controllers/BranchController.js';
import AudioController from '../controllers/AudioController.js';

import Utils from '../utils/Utils.js';

import SVGFrame from './SVGFrame.js';
import DrawFrame from './DrawFrame.js';
import SVGMorph from './SVGMorph.js';
import PointState from './PointState.js';
import SequenceOptions from "./SequenceOptions.js";

import PageLoader from "../loading/PageLoader.js";


import CopresenceController from '../controllers/CopresenceController.js';
import HueController from "../controllers/HueController.js";

// window.CopresenceController = CopresenceController;
// var defaultSeqTransitionTime = 1.0;


//----------------
//
// A sequence with a list of frames, morph and interactions
// Three.js scene, lines, rendering, audio is handled by the timeline/editor, 
//
//----------------
class Sequence {

	constructor(timecode, params) {
	
		this.ready = false;
		this.disposed = false;
		this.timecode = this.timeStart = timecode;
		this.params = params;
		this.duration = 30.0;
		this.presetName = this.params.preset;
		this.timelineId = this.params.id;
		this.drawFrame = new DrawFrame();
		this.drawFrame.isDrawFrame = true;
		this.timelineEyesFrame = new SVGFrame();
		this.timelineEyesMode = false;
		this.dragToNext = 0;
		this.pageId = 0;
		this.isPause = params.isPause;


		this.pulseDepth = 0.0;
		this.strokeInteractive = 0.0;
		this.strokeInteractiveDistance = 0.0;
		this.strokeInteractivePulse = 0.0;



		this.currentDrawAnimation = null;
		this.currentDrawKaleidoscope = [];

		this.smoothNoiseTime = 0.0;
		this.deformParams = {
			noise: 0.0,
			smoothNoise: 0.0,
			smoothNoiseScale: 0.0,
			smoothNoiseSpeed: 0.0,
			waveform: 0.0,
			backLayer: 0.0,
			frontLayer: 0.0,
			eyesLayer: 0.0,
			overLayer: 0.0
		};

		this.audioWaveTime = new Float32Array(12);

		//branching
		this.id = this.params.id;
		this.branchId = this.params.branchId||this.params.id;
		this.isBranch = !!this.params.isBranch;
		this.playedBranch = false;
		this.specialButtonMode = this.id == "11_1_bouton" || this.params.downloadCheck;
		this.specialButtonState = 0;
		this.specialButtonStateStartTime = 0;
		this.hasBranchPreset = false;
		this.isBranchSequence = false;

		this.specialCountdownMode = this.params.countdownChiffre||SETTINGS.CHIFFRE_TEST;


		//intro
		this.isIntro = false;
		this.intoStarted = false;
		this.introStartTime = 0;
		this.forcedTransitionPc = 0.0;
		this.autoTime = this.params.autoTime || this.params.theEnd || this.params.isPause;
		this.autoTimeStartTime = 0;
		this.gotRoundedBoxInfo = false;

	}


	//-------------------------
	//
	// Handle assets loading
	//
	//-------------------------
	preloadData(batchName, loader) {
		if (this.params.preset && this.params.preset!=="none") {
			this.preset = Loader.addXHR(batchName, "data/presets/sequences/"+this.params.preset, 'json');	
		}
		//branching alternative presets
		// if (!this.isBranchSequence && this.params.branchPreset && this.params.branchPreset!=="none") {
		// 	var params = Utils.clone(this.params);
		// 	params.preset = this.params.branchPreset;
		// 	delete params.branchPreset;
		// 	this.branchSequence = new Sequence(this.timecode, Utils.clone(this.params));
		// 	this.branchSequence = true;
		// 	this.branchSequence.preloadData(batchName, loader);
		// 	this.hasBranchPreset = true;
		// }
	}

	preload(batchName, loader) {
		this.batchName = batchName;
		if (this.preset) {
			this.options = this.migratePreset(this.preset.value.options, this.preset.value.version, this.preset.value);
			// console.log(this.options, this.preset.value);
		} else {
			this.options = Utils.clone(SequenceOptions.options);
			if (this.isTransitionSequence) this.options.layers.back.values = ["storyboard_v1/43_3_end.svg"];
		}

		// console.log(this.options);
		this.loadAssets(batchName, loader);

		//branching alternative presets
		// if (this.hasBranchPreset) {
		// 	this.branchSequence.pageId = this.pageId;
		// 	this.branchSequence.preload(batchName, loader);
		// }
	}

	loadAssets(batchName, loader) {
		this.batchName = batchName;

		//
		// Load all assets for this preset
		//
		// this.backPaths = [];
		// for (var i=0; i<backFrameURL.length; i++) {
		// 	if (backFrameURL[i] && backFrameURL[i]!=="none") {
		// 		this.backPaths.push(loader.addXHR(batchName, "svg/"+backFrameURL[i],'text'));
		// 	}
		// }
		// this.frontPaths = [];
		// for (var i=0; i<this.options.layers.front.values.length; i++) {
		// 	if (this.options.layers.front.values[i] && this.options.layers.front.values[i]!=="none") {
		// 		this.frontPaths.push(loader.addXHR(batchName, "svg/"+this.options.layers.front.values[i],'text'));
		// 	}
		// }
		// this.eyesPaths = [];
		// for (var i=0; i<this.options.layers.eyes.values.length; i++) {
		// 	if (this.options.layers.eyes.values[i] && this.options.layers.eyes.values[i]!=="none") {
		// 		this.eyesPaths.push(loader.addXHR(batchName, "svg/"+this.options.layers.eyes.values[i],'text'));
		// 	}
		// }
		this.hasSvgSequence = false;
		var frameURL = this.options.layers.back.values;
		this.backFrames = [];
		for (var i=0; i<frameURL.length; i++) {
			if (frameURL[i] && frameURL[i]!=="none") {
				if (frameURL[i]=="draw") {
					this.backFrames = [this.drawFrame];
					i = frameURL.length;
				} else if (AppStatus.svgSequenceMeta[frameURL[i]]) {
					this.backFrames.push(loader.addSVGSequence(batchName, frameURL[i]));
					if (frameURL[i] == 'seq_ui_chiffres') {
						this.backFrames[this.backFrames.length-1].isSpecialDate = true;
					} else {
						this.hasSvgSequence = true;
					}
				} else {
					this.backFrames.push(loader.addSVG(batchName, "svg/"+frameURL[i]));
				}
			}
		}

		frameURL = this.options.layers.front.values;
		this.frontFrames = [];
		for (var i=0; i<frameURL.length; i++) {
			if (frameURL[i] && frameURL[i]!=="none") {
				if (frameURL[i]=="draw") {
					this.frontFrames = [this.drawFrame];
					i = frameURL.length;
				} else if (AppStatus.svgSequenceMeta[frameURL[i]]) {
					this.frontFrames.push(loader.addSVGSequence(batchName, frameURL[i]));
					if (frameURL[i] == 'seq_ui_chiffres') {
						this.frontFrames[this.frontFrames.length-1].isSpecialDate = true;
					} else {
						this.hasSvgSequence = true;
					}
				} else {
					this.frontFrames.push(loader.addSVG(batchName, "svg/"+frameURL[i]));
				}
			}
		}

		frameURL = this.options.layers.eyes.values;
		this.eyesFrames = [];
		for (var i=0; i<frameURL.length; i++) {
			if (this.options.layers.timelineEyes) {
				this.eyesFrames = [this.timelineEyesFrame];
			} else if (frameURL[i] && frameURL[i]!=="none" && frameURL[i]!=="draw") {
				if (frameURL[i]=="draw") {
					this.eyesFrames = [this.drawFrame];
					i = frameURL.length;
				} else if (AppStatus.svgSequenceMeta[frameURL[i]]) {
					this.eyesFrames.push(loader.addSVGSequence(batchName, frameURL[i]));
					if (frameURL[i] == 'seq_ui_chiffres') {
						this.eyesFrames[this.eyesFrames.length-1].isSpecialDate = true;
					}
				} else {
					this.eyesFrames.push(loader.addSVG(batchName, "svg/"+frameURL[i]));
				}
			}
		}

		frameURL = this.options.layers.over.values;
		this.overFrames = [];
		for (var i=0; i<frameURL.length; i++) {
			if (frameURL[i] && frameURL[i]!=="none") {
				if (frameURL[i]=="draw") {
					this.overFrames = [this.drawFrame];
					i = frameURL.length;
				} else if (AppStatus.svgSequenceMeta[frameURL[i]]) {
					this.overFrames.push(loader.addSVGSequence(batchName, frameURL[i]));
					if (frameURL[i] == 'seq_ui_chiffres') {
						this.overFrames[this.overFrames.length-1].isSpecialDate = true;
					} else {
						this.hasSvgSequence = true;
					}
				} else {
					this.overFrames.push(loader.addSVG(batchName, "svg/"+frameURL[i]));
				}
			}
		}

		//load brushes
		if (this.options.brush.enabled) {
			if (this.options.brush.texture == 'none') this.brushTexture = Utils.blackTexture;
			else this.brushTexture = loader.addTexture(batchName, 'images/brushes/'+this.options.brush.texture, {format: THREE.RGBAFormat});
		}

		//-----------
		//
		// UI CSS images
		//
		//-----------
		if (this.params.enterButton) {
			loader.addCSSImage(batchName, "images/ui/entrer_"+SETTINGS.UI_LANGUAGE+".png", '#instructions-enter-img');
			loader.addCSSImage(batchName, "images/ui/entrer_"+SETTINGS.UI_LANGUAGE+".png", '#instructions-enter-img');
			// if (SETTINGS.NFB_MENU) loader.addCSSImage(batchName, "images/ui/about_v2.png", '#intro-aboutbtn-img');
		}
		if (this.params.arrowButton) {
			loader.addCSSImage(batchName, "images/ui/arrow.png", '#instructions-arrow-img');
		}
		if (this.params.ecouteurs) {
			loader.addCSSImage(batchName, 
				SETTINGS.isMobile && SETTINGS.isAndroid ? ("images/ui/ecouteurs_android_"+SETTINGS.UI_LANGUAGE+".png") : 
				SETTINGS.isMobile ? ("images/ui/ecouteurs_iphone_"+SETTINGS.UI_LANGUAGE+".png") :
				"images/ui/ecouteurs_"+SETTINGS.UI_LANGUAGE+".png",
				'#instructions-headphones-img');
		}
		if (this.params.glissez) {
			if (!SETTINGS.isMobile) $('#instructions-glissez-img').toggleClass('desktop', true);
			loader.addCSSImage(batchName, "images/ui/instructions_"+SETTINGS.UI_LANGUAGE+(SETTINGS.isMobile?"_phone":"_desktop")+".png", '#instructions-glissez-img');
		}
		if (this.params.timeSelection) {
			loader.addCSSImage(batchName, "images/ui/session_commencer_"+SETTINGS.UI_LANGUAGE+".png", '#timeselect-question-img');
			loader.addCSSImage(batchName, "images/ui/duree_5_"+SETTINGS.UI_LANGUAGE+".png", '#timeselect-short-img');
			loader.addCSSImage(batchName, "images/ui/duree_18_"+SETTINGS.UI_LANGUAGE+".png", '#timeselect-long-img');
			// loader.addCSSImage(batchName, "images/ui/duree_5_fr.png", '#timeselect-short-img');
			// loader.addCSSImage(batchName, "images/ui/duree_18_fr.png", '#timeselect-long-img');
		}
		if (this.params.theEnd) {
			loader.addCSSImage(batchName, "images/ui/end_credits_"+SETTINGS.UI_LANGUAGE+".png", '#end-credits-img');
			loader.addCSSImage(batchName, "images/ui/end_partager_"+SETTINGS.UI_LANGUAGE+".png", '#end-share-img');
			loader.addCSSImage(batchName, "images/ui/end_recommencer_"+SETTINGS.UI_LANGUAGE+".png", '#end-restart-img');
		}
		// if (this.params.firstPreset) {
		// 	loader.addCSSImage(batchName, "images/ui/end_recommencer_"+SETTINGS.UI_LANGUAGE+".png", '#pause-img');
		// }
		if (this.params.isPause && !this.params.isReload) {
			// loader.addCSSImage(batchName, "images/ui/reprendre_"+SETTINGS.UI_LANGUAGE+".png", '#pause-img');
			// loader.addCSSImage(batchName, "images/ui/pause_recommencer_"+SETTINGS.UI_LANGUAGE+".png", '#pause-restart-img');

		}
		if (this.params.early_end) {
			loader.addCSSImage(batchName, "../images/ui/voulez-vous_"+SETTINGS.UI_LANGUAGE+".png", '#early-end-question');
			loader.addCSSImage(batchName, "../images/ui/voulez-vous_continuer_"+SETTINGS.UI_LANGUAGE+".png", '#early-end-continue-img');
			loader.addCSSImage(batchName, "../images/ui/voulez-vous_arreter_"+SETTINGS.UI_LANGUAGE+".png", '#early-end-stop-img');
		}
	}

	//start batch if not started
	prepare() {
		if (!this.batchStarted) {
			this.batchStarted = true;

			// Loader.queueBatch(this.batchName);
			// PageLoader.isLoaded(this.arrayId);
		}
	}

	isReady() {
		// if (this.ready) return true;
		if (!SETTINGS.TIMELINE_MODE && !this.batchName) return false;
		if (this.isPause) return true;
		this.ready = SETTINGS.TIMELINE_MODE ? PageLoader.isLoaded(this.pageId) : Loader.isComplete(this.batchName); //Loader.getBatch(this.batchName).done;
		return this.ready;	
	}

	setup() {
		if (this.wasSetup) return;
		this.wasSetup = true;
		// this.options = (this.preset);


		//
		// Parse SVG data for each layers & create SVGFrames
		//
		// console.log(this.backPaths);
		
		this.morphs = {
			back: null,
			front: null,
			eyes: null,
			over: null
		};
		this.morphById = {};

		this.layers = {
			eyes: this.eyesFrames,
			back: this.backFrames,
			front: this.frontFrames,
			over: this.overFrames
		};

		if (this.options.layers.shuffleFrames) {
			this.layers = {
				eyes: Utils.shuffleClone(this.eyesFrames),
				back: Utils.shuffleClone(this.backFrames),
				front: Utils.shuffleClone(this.frontFrames),
				over: !this.specialCountdownMode ? Utils.shuffleClone(this.overFrames) : this.overFrames
			};
		}

		var numSvg = 0;
		if (SETTINGS.isMobile && this.params.mobileAutoOffset) {
			this.options.layers.mobileTextOffset = 1*28/AppStatus.innerHeight();
			// console.log("MOBILE OFFSET:",this.options.layers.mobileTextOffset);
		} else {

		}

		//split internal layers from svg?
		for (var layerName in this.layers) {
			var frames = this.layers[layerName];
			for (var i = 0; i < frames.length; i++) {
				if (frames[i] && !frames[i].isDrawFrame) numSvg++;
				if (frames[i].isSpecialDate) {
					this.layers[layerName][i] = new SVGFrame().createDate(frames[i],  this.options.layers.mobileTextOffset,this.params.yearInText);
				} else if (frames[i].layerMode) {
					var f = frames[i];
					this.layers[layerName].splice(i,1);
					i--;
					if (f.layers.back) this.layers.back.push(f.layers.back);
					if (f.layers.front) this.layers.front.push(f.layers.front);
					if (f.layers.eyes) this.layers.eyes.push(f.layers.eyes);
					if (f.layers.over) this.layers.over.push(f.layers.over);
					// if (f.layers.back) this.layers.back.push(f.layers.back);
				} else if (frames[i].isSequence && frames[i].isLayerMode()) {

					var f = frames[i];
					this.layers[layerName].splice(i,1);
					i--;
					var back = f.getLayer('back'),
						front = f.getLayer('front'),
						eyes = f.getLayer('eyes'),
						over = f.getLayer('over');
					if (back) this.layers.back.push(back);
					if (front) this.layers.front.push(front);
					if (eyes) this.layers.eyes.push(eyes);
					if (over) this.layers.over.push(over);
				}
			}
		};
		
		if (numSvg == 0) {
			console.log("ADDING EMPTY");
			var empty = new SVGFrame();
			empty.fromPoints([]);
			empty.isEmpty = true;
			this.layers.back.push(empty);
		}

		if (this.layers["over"] && SETTINGS.isMobile) {
			if (this.options.layers.mobileTextOffset>0.0) {
				for (var i=0; i<this.layers["over"].length; i++) {
					if (!this.layers.over[i].isSequence && !this.layers.over[i].isSpecialDate) {
						var pos = this.layers.over[i].getPositions();
						var nPos = [];
						for (var j=0; j<pos.length; j++) {
							var np = pos[j].slice(0);
							for (var k=1; k<np.length; k+=3) {
								np[k] += this.options.layers.mobileTextOffset;
							}
							nPos.push(np);
						}
						this.layers.over[i] = new SVGFrame().fromPoints(nPos);
					}
				}
			}
		}
		


		this.slowMode = false;
		var maxNumLayers = Math.max(this.layers.back.length, this.layers.front.length, this.layers.over.length, this.layers.eyes.length);
		if ((maxNumLayers  > 1 && this.options.layers.shuffleFrames) || maxNumLayers >= 4) {
			this.slowMode = true;
		}
	
		this.transitionFrames = {
			eyes: null,
			back: null,
			front: null,
			over: null
		};

		this.latestPointsByLayer = {
			eyes: [],
			back: [],
			front: [],
			over: []
		};

		this.magnetismParams = Utils.clone(SequenceOptions.options.magnetism);

		this.nextCligne = performance.now()+5000;
		this.cligneStart = performance.now();
		this.currentDragProgress = 0.0;
		this.strokeColor = new THREE.Color(0);


		//brush
		if (this.options.brush.enabled) {
			this.brushMaterial = SequenceRenderer.brushMaterials[this.options.brush.shader];
		}
		this.brushRatio = new THREE.Vector2(1,1);


		this.drawAnimations = [];
		this.specialButtonState = 0;
		if (this.params.downloadCheck) BranchController.hasDownloaded = false;
	}

	update(time, progress, delta, noRender) {
		if (this.isReady() && !this.wasSetup) this.setup();
		this.disposed = false;

		if (this.isBranch) {
			time = BranchController.getCurrentTime();
			if (BranchController.getDuration() && !this.specialButtonMode) this.duration = BranchController.getDuration();
		}
		if (this.params.early_end && !this.specialButtonMode) this.duration = 17.375;

		var now = performance.now(),
			opt = this.options,
			transitionPc = 0.0,
			tOpt = this.transitionStarted ? this.nextSequence.options : null;





		this.lastPositions = null;

		if (this.autoTime) time = noRender?0:(now-this.autoTimeStartTime)/1000;

		var durationExclTransition = this.duration - opt.transition.duration;
		durationExclTransition = Math.max(durationExclTransition,0.01);
		if (this.autoTime) durationExclTransition = 10.0;




		//-------------
		//
		// For each layers, determine target frames based on current progress
		//
		//-------------
		for (var layerName in this.layers) {


			//-----------------
			//
			// Get layer info
			//
			var currentMorph = this.morphs[layerName];
			var frames = this.layers[layerName];
			this.latestPointsByLayer[layerName] = [];


			//create frames sub-timeline based on number of frames, sequence and progress/duration
			var subTimeline = [];
			var morphTimeTotal = this.duration;
			var seqTimeTotal = 0.0;
			var numSeq = 0;
			var numMorph = 1;
			var wasSequence = false;

			if (!frames) {
				console.warn("wtf");
			}

			//
			// branching: button
			//
			if (this.specialButtonMode && frames.length>1) {

				if (!this.params.downloadCheck && this.specialButtonState == 0 && (BranchController.branchStarted && BranchController.autoButtonPress)) {
					this.specialButtonState = 1;
					this.specialButtonStateStartTime = now;
					console.log("auto state 1");
				}
				if (SequenceRenderer.pressedThisFrame && this.specialButtonState == 0 && SequenceRenderer.rawPosition.length() <0.15) {
					this.specialButtonState = 1;
					this.specialButtonStateStartTime = now;
					console.log("state 1");
				}
				if (this.specialButtonState == 1 && now - this.specialButtonStateStartTime >= (this.params.downloadCheck?475:475) ) {
						
					this.specialButtonState = 2;
					if (BranchController.autoButtonPress && !this.params.downloadCheck) this.specialButtonState = 3;
					console.log("state ",this.specialButtonState);
					this.specialButtonStateStartTime = now;
				}

				//branching: download
				if (this.params.downloadCheck) {
					if (SequenceRenderer.pressedThisFrame && SequenceRenderer.rawPosition.length() < 0.15) {
						BranchController.hasDownloaded = true;
						console.log("Downloaded!");
					}
				}
			// }

			// if (this.specialButtonMode && frames.length>1) {
				if (this.specialButtonState == 0) {
					frames = [frames[0]];
				} else if (this.specialButtonState == 1) {
					if (!this.params.downloadCheck) {
						frames = frames[2]?[frames[2]]:[];
					} else {
						frames = [frames[1]];
					}
				} else if (this.specialButtonState == 2) {
					if (!this.params.downloadCheck) {
						frames = [frames[1]];
					} else {
						frames = frames[2]?[frames[2]]:[];
					}
				} else if (this.specialButtonState == 3) {
					frames = frames[3]?[frames[3]]:[];
				}
			}
			

			//get timeline duration for each frames
			for (var i = 0; i < frames.length; i++) {
				if (frames[i].isSequence) {
					morphTimeTotal -= frames[i].getDuration(opt.morph.sequenceFps);
					seqTimeTotal += frames[i].getDuration(opt.morph.sequenceFps);
					numSeq++;
					if (i>0 && i<frames.length-1) numMorph++;

				} else {
					if (i < frames.length-1 && i>0) numMorph++;
				}
			}
			//looping sequence : add morph to first frame
			if (frames.length>1 && opt.morph.interactive>0) {
				numMorph++;
			}

			
			//
			// Adjust duration for the final transition
			// default is one morph timing
			//
			// opt.transition.duration = 0.0;
			if (opt.transition.default) {
				opt.transition.duration = morphTimeTotal/(numMorph+2);
				if (!SETTINGS.TIMELINE_MODE) {
					DreamUI.triggerProperty(opt.transition, "duration");
				}
			}
			morphTimeTotal -= opt.transition.duration;

			//
			// Build Timeline
			//
			var ct = 0, numSequences = 0;
			if (frames.length > 0 && (layerName !== 'eyes' || !opt.layers.timelineEyes)) {

				//first frame
				if (frames[0].isSequence) {
					numSequences++;
					ct += frames[0].getDuration(opt.morph.sequenceFps);
					if (frames.length == 1) ct = durationExclTransition;
					subTimeline.push({startTime:0, endTime:ct, frame: frames[0], nextFrame: null, isMorphing: false});

				} else {
					subTimeline.push({startTime:-1, endTime:0, frame: frames[0].getFrame(), nextFrame: null, isMorphing: false});
				}


				//add each morphs
				for (var i = 1; i < frames.length; i++) {
					if (frames[i].isSequence) {
						numSequences++;

						//add transition in
						var st = ct;
						ct += morphTimeTotal/numMorph;
						subTimeline.push({startTime:st, endTime:ct, frame: frames[i-1].getEndFrame(), nextFrame: frames[i].getFrame(), isMorphing: true});

						//add sequence
						st = ct;
						ct += frames[i].getDuration(opt.morph.sequenceFps);
						if (this.specialButtonState==2 && this.specialButtonMode && !this.params.downloadCheck) ct += this.params.duration;
						subTimeline.push({startTime:st, endTime:ct, frame: frames[i], nextFrame: null, isMorphing: false});
						// morphTimeTotal -= frames[i].getDuration();

					} else {

						//add transition in
						var st = ct;
						ct += morphTimeTotal/numMorph;
						subTimeline.push({startTime:st, endTime:ct, frame: frames[i-1].getEndFrame(), nextFrame: frames[i].getFrame(), isMorphing: true});

					}
				}

				//looping sequence : add morph to first frame
				if (frames.length>1 && opt.morph.interactive>0) {
					//add transition in
					var st = ct;
					ct += morphTimeTotal/numMorph;
					subTimeline.push({startTime:st, endTime:ct, frame: frames[frames.length-1].getEndFrame(), nextFrame: frames[0].getFrame(), isMorphing: true});
				}
			}


			//-----------------
			//
			// update drag progress
			//	
			//-----------------
			this.currentDragProgress +=
					(Utils.distance(SequenceRenderer.currentDragSpeed.x, SequenceRenderer.currentDragSpeed.y, 0, 0) / durationExclTransition) * 1.0 * delta  * opt.morph.interactive;
				if (isNaN(this.currentDragProgress)) this.currentDragProgress = 0;

				if (opt.morph.interactive > 0 && SequenceRenderer.pressedThisFrame) this.dragToNext = this.slowMode?(numMorph*0.5):0.5;
				this.currentDragProgress +=  this.dragToNext * delta * 0.03 * opt.morph.click;
				this.dragToNext -= this.dragToNext*delta * 0.03;
				this.dragToNext = Utils.deltaSmoothingSnap2(this.dragToNext,0.0,0.055,delta);

			if (opt.morph.interactive <= 0) this.currentDragProgress = 0;
			if (SETTINGS.SKIP_BRANCHES && !SETTINGS.SCREEN_RECORD) this.currentDragProgress = 0;

			// AppStatus.log(morphTimeTotal);
			// console.log(this.currentDragProgress,opt.morph.interactive,Utils.distance(SequenceRenderer.currentDragSpeed.x, SequenceRenderer.currentDragSpeed.y, 0, 0), durationExclTransition, delta);
			// Utils.tmpVector2.set(opt.morph.automatic,opt.morph.interactive);
			// Utils.tmpVector2.normalize();

			// AppStatus.log(1.0-Utils.tmpVector2.x);
			// if (layerName=="back") AppStatus.log(numSeq);
			var dragProgressDiv = ((this.currentDragProgress * durationExclTransition) / numMorph) * 0.5;
			dragProgressDiv = Utils.lerp(this.currentDragProgress, dragProgressDiv, this.slowMode ? 1 : 0);

			var currentTime = (opt.morph.automatic*time + dragProgressDiv);
			if (currentTime >= durationExclTransition && opt.morph.interactive > 0) {
				currentTime = currentTime%durationExclTransition;
			}


			// if (this.isBranch) 
			// 	AppStatus.log(currentTime, opt.morph.automatic, opt.morph.click, this.currentDragProgress,SequenceRenderer.currentDragSpeed.x, this.dragToNext, time, durationExclTransition);

			//
			// Select frames for morphing
			//
			var isMorphing = false;
			var numFrames = frames.length-1;
			var startFrame = frames[frames.length-1];
			var endFrame = null;
			var morphPc = 0.0;


			if (!this.transitionStarted) {

				//pick current and next frame based on subTimeline
				for (var i=0; i<subTimeline.length; i++) {
					if ((currentTime >= subTimeline[i].startTime && currentTime < subTimeline[i].endTime) || this.autoTime) {

						startFrame = subTimeline[i].frame;
						endFrame = subTimeline[i].nextFrame;
						isMorphing = subTimeline[i].isMorphing;

						if (startFrame.isSequence) {
							
							// morphPc =  Utils.ccmap(time, subTimeline[i].startTime, subTimeline[i].endTimeframeTimes[i]+frames[i].getDuration(), 0.0, 1.0);
							endFrame = null;
							isMorphing = false;

							//different sequence handling for eyes : clignement
							if (layerName == "eyes" && startFrame.frames.length <= 3 && startFrame.frames.length>1) {
								startFrame = startFrame;

							//pick current frame based on time/loop time
							} else {
									
								var ct = currentTime;
								if (this.autoTime) ct = opt.morph.automatic * (noRender?0:(now-this.autoTimeStartTime)/1000) + dragProgressDiv


								morphPc =  Utils.map(ct, subTimeline[i].startTime, subTimeline[i].startTime+startFrame.getDuration(opt.morph.sequenceFps), 0.0, 1.0);
								if (this.specialButtonMode && this.specialButtonState == 1) morphPc = Utils.cmap(now-this.specialButtonStateStartTime, 0,this.params.downloadCheck?480:480, 0.0, 1.0);
								if (this.specialButtonMode && this.specialButtonState == 2) morphPc = Utils.cmap((now-this.specialButtonStateStartTime)/1000, 0,startFrame.getDuration(opt.morph.sequenceFps), 0.0, 1.0);
								if (this.specialButtonMode && this.specialButtonState == 2 && this.params.downloadCheck) morphPc = Utils.map((now-this.specialButtonStateStartTime)/1000, 0,startFrame.getDuration(opt.morph.sequenceFps), 0.0, 1.0);
								if (this.specialButtonMode && this.specialButtonState == 3 && this.params.downloadCheck) morphPc = Utils.map((now-this.specialButtonStateStartTime)/1000, 0,startFrame.getDuration(opt.morph.sequenceFps), 0.0, 1.0);

								// console.log(this.specialButtonState, morphPc);
								var f = Math.min(Math.floor(morphPc*(startFrame.frames.length-0.99)) % startFrame.frames.length, startFrame.frames.length-1);
								// if (!noRender) {
								// }
								// if (AppStatus.currentFrame % 4 == 0) console.log(performance.now() / 1000, currentTime-subTimeline[i].startTime, f, ((currentTime-subTimeline[i].startTime)*opt.morph.sequenceFps)% startFrame.frames.length );
								// if (morphPc>=1) f = startFrame.frames.length-1;
								// startFrame = startFrame.getFrame[f];
								startFrame = startFrame.frames[f];
							}

						} else {

							morphPc =  Utils.ccmap(currentTime, subTimeline[i].startTime, subTimeline[i].endTime, 0.0, 1.0);
							isMorphing = startFrame && endFrame && startFrame.uuid !== endFrame.uuid && morphPc>0;

						}

					//last frame, cap, no morph
					} else if (i>=subTimeline.length-1 && currentTime >= subTimeline[i].endTime) {
						startFrame = frames[frames.length-1];
						if (startFrame.isSequence) startFrame = startFrame.frames[startFrame.frames.length-1];
						endFrame = null;
						morphPc = 0.0;
						isMorphing = false;
					}


				}

				// startFrame = frames[Math.floor(progress * numFrames)];
				// endFrame = frames[Math.min(Math.ceil(progress * numFrames), numFrames)];
				// morphPc = (progress * numFrames) - Math.floor(progress * numFrames);
				// isMorphing = startFrame && endFrame && startFrame.uuid !== endFrame.uuid;
				
		

			} else {
				// if sequence transition is started, go from current sequence to first frame of next sequence
				// if (isMorphing) {

				isMorphing = true;
				startFrame = this.getFreezeFrame(layerName);
				endFrame = this.nextSequence?this.nextSequence.getFreezeFrame(layerName):null;
				
				transitionPc = morphPc = Utils.ccmap(time, durationExclTransition, this.duration, 0.0, 1.0);
				if (isNaN(morphPc)) transitionPc = morphPc = 1.0;

				if (this.isBranch) {
					transitionPc = morphPc = BranchController.getTransitionPc();
				}

				if (this.isIntro) transitionPc = morphPc = this.forcedTransitionPc;
				// AppStatus.log(this.id, this.isBranch, transitionPc, startFrame);
				// if (currentMorph && !currentMorph.isTransitionMorph) {
				// 	var lastEnd = currentMorph.endFrame;
				// 	currentMorph.endForwards();
				// 	currentMorph = new SVGMorph(lastEnd, startFrame, this.options, {});
				// 	currentMorph.endForwards();
				// 	currentMorph = null;
				// }
				// if (!currentMorph) {
				// 	currentMorph = new SVGMorph(, this.nextSequence.getFreezeFrame(layerName), this.options, {isMorphing:true});
				// 	currentMorph.isTransitionMorph = true;
				// 	this.morphs[layerName] = currentMorph;
				// }
				// }	
			}
			// if (this.isBranch) console.log(this.transitionStarted, transitionPc);

			if (opt.morph.dontMorphOver && layerName == 'over') morphPc = morphPc>0.5?1.0:0.0;
			if (this.specialCountdownMode && layerName == 'over' && frames.length>0 && !this.transitionStarted && !noRender) {
				morphPc = 0.0;
				var dur = durationExclTransition + opt.transition.duration; //*0.9;
				startFrame = frames[Math.min(Math.floor((time/dur) * frames.length), frames.length-1)]; //.getFrame();
				if (time>dur) startFrame = null;
			}

			//clignement des yeux : different type de séquence pour les yeux
			this.timelineEyesMode = false;
			if (startFrame) { //only if there's a frame to use
				if (layerName == 'eyes' && opt.layers.timelineEyes && (!this.transitionStarted||(this.transitionStarted&&tOpt.layers.timelineEyes))) {
				
					startFrame = EyesController.getCurrentFrame();

					if (!this.transitionStarted) {
						endFrame = null;
						isMorphing = false;
						this.timelineEyesMode = true;
					} else {
						endFrame = this.nextSequence && this.nextSequence.options.layers.timelineEyes ? null : this.nextSequence.getFreezeFrame(layerName);
						if (endFrame) {
							isMorphing = true;
						} else {
							isMorphing = false;
							this.timelineEyesMode = true;
						}
					}
					

				} else if (!layerName == "eyes" && startFrame.isSequence && startFrame.frames.length <= 3 && startFrame.frames.length>1) {
					if (now > this.nextCligne) {
						this.nextCligne = now+Math.random()*4000+2000;
						if (Math.random()<0.2) this.nextCligne = now+500;
						this.cligneStart = now;
					}
					if (now-this.cligneStart < 100) {
						startFrame = startFrame.frames[1];
					} else {
						startFrame = startFrame.frames[0];
					}
				} else {
					startFrame = startFrame.getFrame();
				}
			}
			if (endFrame) endFrame = endFrame.getFrame();

			//
			// End morph and get state for each point
			//
			if (currentMorph) {
				if (startFrame && endFrame) {
					if (startFrame.uuid !== currentMorph.startFrame.uuid || endFrame.uuid !== currentMorph.endFrame.uuid) {

						//
						// If current morph should end forwards
						//
						if (startFrame.uuid == currentMorph.endFrame.uuid) {

							currentMorph.endForwards();
							currentMorph = null;

						//
						// If current morph should end backwards
						//
						} else if (endFrame.uuid == currentMorph.startFrame.uuid) {

							currentMorph.endBackwards();
							currentMorph = null;

						//
						// If neither target frames is in current morph end forwards 2x, move point state
						//
						} else {
							// console.log("what? no morph");

							currentMorph.endForwards();
							currentMorph = null;

							// var lastEnd = currentMorph.endFrame;

							// if (!lastEnd || !startFrame) {
							// 	console.warn("no start/end a", currentMorph, startFrame, lastEnd)
							// }
							// currentMorph.endForwards();

							// if (!lastEnd || !startFrame) {
							// 	console.warn("no start/end b", currentMorph, startFrame, lastEnd)
							// }
							// currentMorph = new SVGMorph(lastEnd, startFrame, this.options, {});
							// currentMorph.endForwards();
							// currentMorph = null;
						}
					}
				} else if (!isMorphing) {
					currentMorph = null;
				}
			}

			//
			// Easing, morph interactif, randomness
			//
			if (opt.morph.easing !== 'Linear') {
				if (opt.morph.easing==='easeIn') Easing['Exponential'].In(morphPc);
				else if (opt.morph.easing==='easeOut') Easing['Exponential'].Out(morphPc);
				else morphPc = Easing[opt.morph.easing].InOut(morphPc);
			}


			



			//------------------------
			//
			// Update magnetism and transition
			//
			//------------------------
			if (!this.transitionStarted) {
				this.magnetismParams.alpha = opt.magnetism.alpha;
				this.magnetismParams.attract =  Easing.Exponential.InOut(opt.magnetism.attract);
				this.magnetismParams.inverse =  Easing.Exponential.InOut(opt.magnetism.inverse);
				this.magnetismParams.elastic = opt.magnetism.elastic;
				this.magnetismParams.curve = opt.magnetism.curve;
				this.magnetismParams.size = opt.magnetism.size;
				this.magnetismParams.strength = opt.magnetism.strength;
				this.magnetismParams.extrude = opt.magnetism.extrude;
				this.magnetismParams.randomShake = opt.magnetism.randomShake;

				this.magnetismParams.overLayer = opt.magnetism.overLayer;
				this.magnetismParams.eyesLayer = opt.magnetism.eyesLayer;
				this.magnetismParams.backLayer = opt.magnetism.backLayer;
				this.magnetismParams.frontLayer = opt.magnetism.frontLayer;

				SequenceRenderer.randomCursorPc = opt.magnetism.randomCursor;

			} else {

				var pTransitionPc = transitionPc;
				if (opt.magnetism.alpha<=0.001) pTransitionPc = 1.0;
				if (tOpt.magnetism.alpha<=0.001) pTransitionPc = 0.0;

				this.magnetismParams.alpha = Utils.lerp(opt.magnetism.alpha,tOpt.magnetism.alpha, transitionPc);

				this.magnetismParams.attract =  Easing.Exponential.InOut(Utils.lerp(opt.magnetism.attract,tOpt.magnetism.attract, pTransitionPc));
				this.magnetismParams.inverse =  Easing.Exponential.InOut(Utils.lerp(opt.magnetism.inverse,tOpt.magnetism.inverse, pTransitionPc));
				this.magnetismParams.elastic = Utils.lerp(opt.magnetism.elastic,tOpt.magnetism.elastic, pTransitionPc);
				this.magnetismParams.curve = Utils.lerp(opt.magnetism.curve,tOpt.magnetism.curve, pTransitionPc);
				this.magnetismParams.size = Utils.lerp(opt.magnetism.size,tOpt.magnetism.size, pTransitionPc);
				this.magnetismParams.strength = Utils.lerp(opt.magnetism.strength,tOpt.magnetism.strength, pTransitionPc);
				this.magnetismParams.extrude = Utils.lerp(opt.magnetism.extrude,tOpt.magnetism.extrude, pTransitionPc);
				this.magnetismParams.randomShake = Utils.lerp(opt.magnetism.randomShake,tOpt.magnetism.randomShake, pTransitionPc);

				this.magnetismParams.backLayer = Utils.lerp(opt.magnetism.backLayer,tOpt.magnetism.backLayer, pTransitionPc);
				this.magnetismParams.frontLayer = Utils.lerp(opt.magnetism.frontLayer,tOpt.magnetism.frontLayer, pTransitionPc);
				this.magnetismParams.eyesLayer = Utils.lerp(opt.magnetism.eyesLayer,tOpt.magnetism.eyesLayer, pTransitionPc);
				this.magnetismParams.overLayer = Utils.lerp(opt.magnetism.overLayer,tOpt.magnetism.overLayer, pTransitionPc);

				SequenceRenderer.randomCursorPc =Utils.lerp(opt.magnetism.randomCursor,tOpt.magnetism.randomCursor, pTransitionPc);


			}
			
			this.strokeColor.set( opt.stroke.color );
			if (this.transitionStarted) this.strokeColor.lerp( new THREE.Color(tOpt.stroke.color), transitionPc );

			if (this.transitionStarted) {

				if (opt.brush.enabled)  this.strokeColor.set( tOpt.stroke.color);
				if (tOpt.brush.enabled)  this.strokeColor.set( opt.stroke.color);
				if (opt.effects.oignonSkin.enabled>0.01 && opt.effects.oignonSkin.color>=0.999)  this.strokeColor.set( tOpt.stroke.color);
				if (tOpt.effects.oignonSkin.enabled>0.01 && tOpt.effects.oignonSkin.color>=0.999)  this.strokeColor.set( opt.stroke.color);
				if (opt.effects.oignonSkinVector.enabled>0.01 && opt.effects.oignonSkinVector.color>=0.999)  this.strokeColor.set( tOpt.stroke.color);
				if (tOpt.effects.oignonSkinVector.enabled>0.01 && tOpt.effects.oignonSkinVector.color>=0.999)  this.strokeColor.set( opt.stroke.color);

			}


			if (!this.transitionStarted) {
				this.deformParams.noise = opt.deform.noise;
				this.deformParams.smoothNoise = opt.deform.smoothNoise;
				this.deformParams.smoothNoiseSpeed = opt.deform.smoothNoiseSpeed;
				this.deformParams.smoothNoiseScale = opt.deform.smoothNoiseScale;

				this.deformParams.waveform = opt.deform.waveform;
				this.deformParams.backLayer = opt.deform.backLayer;
				this.deformParams.frontLayer = opt.deform.frontLayer;
				this.deformParams.eyesLayer = opt.deform.eyesLayer;
				this.deformParams.overLayer = opt.deform.overLayer;
			} else {
				this.deformParams.noise = Utils.lerp(opt.deform.noise,tOpt.deform.noise,transitionPc);
				this.deformParams.smoothNoise = Utils.lerp(opt.deform.smoothNoise,tOpt.deform.smoothNoise,transitionPc);
				this.deformParams.smoothNoiseSpeed = Utils.lerp(opt.deform.smoothNoiseSpeed,tOpt.deform.smoothNoiseSpeed,transitionPc);
				this.deformParams.smoothNoiseScale = Utils.lerp(opt.deform.smoothNoiseScale,tOpt.deform.smoothNoiseScale,transitionPc);

				this.deformParams.waveform = Utils.lerp(opt.deform.waveform,tOpt.deform.waveform,transitionPc);
				this.deformParams.backLayer = Utils.lerp(opt.deform.backLayer,tOpt.deform.backLayer,transitionPc);
				this.deformParams.frontLayer = Utils.lerp(opt.deform.frontLayer,tOpt.deform.frontLayer,transitionPc);
				this.deformParams.eyesLayer = Utils.lerp(opt.deform.eyesLayer,tOpt.deform.eyesLayer,transitionPc);
				this.deformParams.overLayer = Utils.lerp(opt.deform.overLayer,tOpt.deform.overLayer,transitionPc);
			}


			//update audio wave
			if (this.deformParams.waveform > 0.0001) {
				for (var i=0; i<12; i++) {
					this.audioWaveTime[i] = (Math.sin(now / (100+(i+3)*11) )) * 1.0 * (AudioController.audioData[i+3]/255) * this.deformParams.waveform;
				}
			}


			if (!noRender) {
				//scene scale
				if (this.transitionStarted) {
					SequenceRenderer.container.scale.set(1,1,1).multiplyScalar(Utils.lerp(opt.layers.scale, tOpt.layers.scale, transitionPc));
				} else {
					SequenceRenderer.container.scale.set(opt.layers.scale,opt.layers.scale,opt.layers.scale);
				}
				SequenceRenderer.container.scale.multiplyScalar(SETTINGS.GLOBAL_SCALE);


				//rounded box
				SequenceRenderer.roundedBoxMaterial.uniforms.alpha.value = Utils.deltaSmoothingSnap2(SequenceRenderer.roundedBoxMaterial.uniforms.alpha.value, (opt.background.roundedBox&&!this.transitionStarted)?1.0:0.0, this.transitionStarted?0.02:0.0125, delta );
				SequenceRenderer.showRoundedBox = SequenceRenderer.roundedBoxMaterial.uniforms.alpha.value > 0.005;
			}




			//------------------------
			//
			// Update line display
			// Different/optimized if there's a morph or not
			//
			//------------------------
			if (startFrame || endFrame) {

				//magnetism prepare
				var mOpt = this.magnetismParams;

				var pressPc = Utils.lerp(
					SequenceRenderer.pressPc,
					SequenceRenderer.pressPcElastic,
					mOpt.elastic);
				pressPc *= mOpt.alpha;
				pressPc *= mOpt.strength;





				var pressPcBack = pressPc * mOpt.backLayer;
				var pressPcFront = pressPc * mOpt.frontLayer;
				var pressPcEyes = pressPc * mOpt.eyesLayer;
				var pressPcOver = pressPc * mOpt.overLayer;





				//update morphing lines
				if (this.specialCountdownMode && layerName === 'over' && !noRender  && !this.transitionStarted && !noRender) {
					var positions = (startFrame||endFrame).getPositions();
					for (var i = 0; i < positions.length; i++) {
						SequenceRenderer.addChiffre(positions[i]);
					}
					this.latestPointsByLayer[layerName] = positions;

				} else if (isMorphing) {

				

					// If no morph is started, create new morph info object
					if (!currentMorph) {
						currentMorph = new SVGMorph(startFrame||endFrame, endFrame||startFrame, this.options, {isMorphing:isMorphing}, layerName);
						this.morphs[layerName] = currentMorph;
					}	

					//
					// Update renderer lines based on current progress
					//
					currentMorph.update(morphPc);
					this.latestPointsByLayer[layerName] = currentMorph.linePoints;
					if (currentMorph.linePoints.length>0 && isNaN(currentMorph.linePoints[0][0])) {
						console.warn("nan points 1");
					}


					var pressTargets = {
						back:pressPcBack,
						front:pressPcFront,
						eyes:pressPcEyes,
						over:pressPcOver
					}
					var currentPressPc = pressTargets[layerName];

					var dOpt = this.deformParams;
					var deformParam = 1.0;
					var deformTargets = {
						back:dOpt.backLayer,
						front:dOpt.frontLayer,
						eyes:dOpt.eyesLayer,
						over:dOpt.overLayer
					};

						
					var powerEffect = SequenceRenderer.powerEffectPc;
					var powerEffectTargets = {
						back:powerEffect * mOpt.backLayer,
						front:powerEffect * mOpt.frontLayer,
						eyes:powerEffect * mOpt.eyesLayer,
						over:powerEffect * mOpt.overLayer
					};
				


					if (!noRender) {
						for (var i = 0; i < currentMorph.numPoints.length; i++) {
							var numPoints = currentMorph.numPoints[i]*3;
							var positions = currentMorph.linePoints[i];

							if (currentMorph.hasLayerTransition && currentMorph.layerTransitions[i]) {
								currentPressPc = Utils.lerp(
									pressTargets[currentMorph.layerTransitions[i].from],
									pressTargets[currentMorph.layerTransitions[i].to],
								morphPc);

								deformParam = Utils.lerp(
									deformTargets[currentMorph.layerTransitions[i].from],
									deformTargets[currentMorph.layerTransitions[i].to],
								morphPc);

								powerEffect = Utils.lerp(
									powerEffectTargets[currentMorph.layerTransitions[i].from],
									powerEffectTargets[currentMorph.layerTransitions[i].to],
								morphPc);

								// console.log("morphing currentPressPc",currentMorph.layerTransitions[i].from, pressTargets[currentMorph.layerTransitions[i].from], currentMorph.layerTransitions[i].to, pressTargets[currentMorph.layerTransitions[i].to]);
							} else {
								currentPressPc = pressTargets[layerName];
								deformParam = deformTargets[layerName];
								powerEffect = powerEffectTargets[layerName];
							}


							this.updateLine(now, i, positions, numPoints, currentPressPc, powerEffect, deformParam, layerName, false);
						}
					}

				//
				// No Morphing : use the raw points from the start frame instead
				//
				} else {

					var currentPressPc = pressPc;
					if (layerName == 'back') currentPressPc = pressPcBack;
					if (layerName == 'front') currentPressPc = pressPcFront;
					if (layerName == 'eyes') currentPressPc = pressPcEyes;
					if (layerName == 'over') currentPressPc = pressPcOver;


					var dOpt = this.deformParams;
					var deformParam = 1.0;
					if (layerName == 'back') deformParam = dOpt.backLayer;
					if (layerName == 'front') deformParam = dOpt.frontLayer;
					if (layerName == 'eyes') deformParam  = dOpt.eyesLayer;
					if (layerName == 'over') deformParam  = dOpt.overLayer;

						
					var powerEffect = SequenceRenderer.powerEffectPc;
					if (layerName == 'back') powerEffect *= mOpt.backLayer;
					if (layerName == 'front') powerEffect *= mOpt.frontLayer;
					if (layerName == 'eyes') powerEffect *= mOpt.eyesLayer;
					if (layerName == 'over') powerEffect *= mOpt.overLayer;


					//
					// Update renderer lines based on current progress
					//
					var framePoints = (startFrame||endFrame).getPositions();
					this.latestPointsByLayer[layerName] = framePoints;
					if (framePoints.length>0 && isNaN(framePoints[0][0])) {
						console.warn("nan points 2");
					}

					// console.log("num lines:",framePoints.length);
					if (!noRender && !(startFrame||endFrame).isEmpty) {
						for (var i = 0; i < framePoints.length; i++) {
							var numPoints = framePoints[i].length;
							var positions = framePoints[i]; //~~ use geometry.attributes.position.array instead

							if (!numPoints) {
								console.warn("no points",i,framePoints,framePoints[i]);
							}

							this.updateLine(now, i, positions, numPoints, currentPressPc, powerEffect, deformParam, layerName, framePoints[i].isDrawing);
						}
					}


					if (!noRender) {
						if (SequenceRenderer.showRoundedBox && !this.transitionStarted && !this.gotRoundedBoxInfo) {
							var minX = 1;
							var minY = 1;
							var maxX = 0;
							var maxY = 0;
							// console.log("num lines:",framePoints.length);
							if (!noRender && !(startFrame||endFrame).isEmpty) {
								for (var i = 0; i < framePoints.length; i++) {
									var positions = framePoints[i];
									for (var j=0; j<positions.length; j+=3) {
										minX = Math.min(positions[j], minX);
										maxX = Math.max(positions[j], maxX);

										minY = Math.min(positions[j+1], minY);
										maxY = Math.max(positions[j+1], maxY);
									}
								}
							}
							this.gotRoundedBoxInfo = true;

							SequenceRenderer.roundedBoxMaterial.uniforms.ratio.value.set(1024 * (maxX-minX) * 1.0 + 30, 1024 * (maxY-minY) * 1.0 + 20).normalize();
							SequenceRenderer.roundedBoxPlane.scale.set( 1024 * (maxX-minX) * 1.0 + 60, 1024 * (maxY-minY) * 1.0 + 60);
							SequenceRenderer.roundedBoxPlane.position.set( 0, (minY+maxY)*1024*0.5, 0);
							SequenceRenderer.roundedBoxScene.scale.copy(SequenceRenderer.container.scale);
						}
					}


				}
				if (!noRender) {

					var line = SequenceRenderer.line;
					line.visible = true;
					// line.geometry.instanceCount = numPoints/3-1; //(SequenceRenderer.currentIdx+numPoints/3)-1;
					// line.geometry.setPositions(positions);
					// SequenceRenderer.currentIdx += numPoints/3;

					//update material
					line.material = line.defaultMaterial;


					//transition brush to/from no brush
					if (this.transitionStarted && opt.brush.enabled !== tOpt.brush.enabled) {

						line.material = opt.brush.enabled?this.brushMaterial:this.nextSequence.brushMaterial;

						//select source
						if (renderer.domElement.width <= renderer.domElement.height) {
							this.brushRatio.x = renderer.domElement.width / renderer.domElement.height;
							this.brushRatio.y = 1.0;
						} else {
							this.brushRatio.x = 1.0;
							this.brushRatio.y = renderer.domElement.height / renderer.domElement.width;
						}

						line.material.uniforms.lineWidth.value = SequenceRenderer.lineWidth*SETTINGS.BASE_LINEWIDTH;
						line.material.uniforms.tDiffuse.value = this.brushTexture||this.nextSequence.brushTexture;
							
						var bOpt = opt.brush.enabled?opt:tOpt;

						line.material.uniforms.source.value.set(bOpt.brush.sourceX - SequenceRenderer.currentPosition.x*bOpt.brush.deplacement, bOpt.brush.sourceY-SequenceRenderer.currentPosition.y*bOpt.brush.deplacement);
						line.material.uniforms.brushRatio.value.copy(this.brushRatio);
						line.material.uniforms.sourceSize.value = bOpt.brush.sourceSize;
						line.material.uniforms.rotation.value = bOpt.brush.rotation;
						line.material.uniforms.colorPc.value = opt.brush.enabled?transitionPc : (1.0-transitionPc);

					} else if (this.transitionStarted && opt.brush.enabled && tOpt.brush.enabled) {

						line.material = transitionPc<0.5?this.brushMaterial:this.nextSequence.brushMaterial;

						//select source
						if (renderer.domElement.width <= renderer.domElement.height) {
							this.brushRatio.x = renderer.domElement.width / renderer.domElement.height;
							this.brushRatio.y = 1.0;
						} else {
							this.brushRatio.x = 1.0;
							this.brushRatio.y = renderer.domElement.height / renderer.domElement.width;
						}

						line.material.uniforms.lineWidth.value = SequenceRenderer.lineWidth*SETTINGS.BASE_LINEWIDTH;
						line.material.uniforms.tDiffuse.value = transitionPc < 0.5 ? this.brushTexture:this.nextSequence.brushTexture;
							
						var bOpt = transitionPc<0.5?opt:tOpt;

						line.material.uniforms.source.value.set(bOpt.brush.sourceX - SequenceRenderer.currentPosition.x*bOpt.brush.deplacement, bOpt.brush.sourceY-SequenceRenderer.currentPosition.y*bOpt.brush.deplacement);
						line.material.uniforms.brushRatio.value.copy(this.brushRatio);
						line.material.uniforms.sourceSize.value = bOpt.brush.sourceSize;
						line.material.uniforms.rotation.value = bOpt.brush.rotation;
						line.material.uniforms.colorPc.value = 0.0;


					//update brush
					} else if (opt.brush.enabled && this.brushMaterial) {
						line.material = this.brushMaterial;

						//select source
						if (renderer.domElement.width <= renderer.domElement.height) {
							this.brushRatio.x = renderer.domElement.width / renderer.domElement.height;
							this.brushRatio.y = 1.0;
						} else {
							this.brushRatio.x = 1.0;
							this.brushRatio.y = renderer.domElement.height / renderer.domElement.width;
						}

						this.brushMaterial.uniforms.lineWidth.value = SequenceRenderer.lineWidth*SETTINGS.BASE_LINEWIDTH;
						this.brushMaterial.uniforms.tDiffuse.value = this.brushTexture;
						this.brushMaterial.uniforms.source.value.set(opt.brush.sourceX - SequenceRenderer.currentPosition.x*opt.brush.deplacement, opt.brush.sourceY-SequenceRenderer.currentPosition.y*opt.brush.deplacement);
						this.brushMaterial.uniforms.brushRatio.value.copy(this.brushRatio);
						this.brushMaterial.uniforms.sourceSize.value = opt.brush.sourceSize;
						this.brushMaterial.uniforms.rotation.value = opt.brush.rotation;
						this.brushMaterial.uniforms.colorPc.value = 0.0;

						// Utils.autoReloadShaderManual(this.brushMaterial, 'shaders/brushes/linebrush_global.vert', 'shaders/brushes/linebrush_global.frag');
					}


					line.material.uniforms.resolution.value.set(AppStatus.innerWidth(), AppStatus.innerHeight());
					line.material.uniforms.color.value.copy( this.strokeColor ) ;
					line.material.uniforms.lineWidth.value = SequenceRenderer.lineWidth*SETTINGS.BASE_LINEWIDTH;
					line.material.uniforms.ratio.value = renderer.domElement.height/renderer.domElement.width;
					if (line.material.uniforms.pixelRatio) line.material.uniforms.pixelRatio.value = window.devicePixelRatio||1;
					if (line.material.uniforms.pulseTime) {
						line.material.uniforms.pulseTime.value = SequenceRenderer.pulseTime;
						line.material.uniforms.pulseDepth.value = this.pulseDepth;
					}
					if (line.material.uniforms.strokeInteractive) {

						line.material.uniforms.strokeInteractive.value = this.strokeInteractive * SequenceRenderer.pressPc;
						line.material.uniforms.strokeInteractiveDistance.value = this.strokeInteractiveDistance;
						line.material.uniforms.strokeInteractivePulse.value = this.strokeInteractivePulse;
						line.material.uniforms.mousePos.value.set(SequenceRenderer.rawPosition.x*2.0, SequenceRenderer.rawPosition.y*-2.0);
					}
				}
				// Utils.autoReloadShaderManual(line.material, 'shaders/brushes/base.vert', 'shaders/brushes/base.frag');
			}

		
					
			//------------------------
			//
			// Update pulse & deform params
			//
			//------------------------
			if (!noRender) {

				if (!this.transitionStarted) {
					SequenceRenderer.pulseTime += delta * 1/60 * opt.stroke.pulse.speed;
					this.pulseDepth = opt.stroke.pulse.enabled?opt.stroke.pulse.depth:0.0;

					this.strokeInteractive = opt.stroke.interactive.enabled ? opt.stroke.interactive.depth : 0.0;
					this.strokeInteractiveDistance = opt.stroke.interactive.distance;
					this.strokeInteractivePulse = opt.stroke.interactive.pulse;
					SequenceRenderer.copresenceEnabled = opt.copresence.enabled;


				} else {
					var pTransitionPc = transitionPc;
					if (!opt.stroke.pulse.enabled) pTransitionPc = 1.0;
					if (!tOpt.stroke.pulse.enabled) pTransitionPc = 0.0;
					SequenceRenderer.pulseTime += delta * 1/60 * Utils.lerp(opt.stroke.pulse.speed, tOpt.stroke.pulse.speed, pTransitionPc);
					this.pulseDepth = Utils.lerp(opt.stroke.pulse.enabled?opt.stroke.pulse.depth:0.0, tOpt.stroke.pulse.enabled?tOpt.stroke.pulse.depth:0.0, transitionPc);
				

					pTransitionPc = transitionPc;
					if (!opt.stroke.interactive.enabled) pTransitionPc = 1.0;
					if (!tOpt.stroke.interactive.enabled) pTransitionPc = 0.0;
					this.strokeInteractive = Utils.lerp(
						opt.stroke.interactive.enabled ? opt.stroke.interactive.depth : 0.0,
						tOpt.stroke.interactive.enabled ? tOpt.stroke.interactive.depth : 0.0,
						transitionPc);

					this.strokeInteractiveDistance = Utils.lerp(opt.stroke.interactive.distance,tOpt.stroke.interactive.distance, pTransitionPc);
					this.strokeInteractivePulse = Utils.lerp(opt.stroke.interactive.pulse,tOpt.stroke.interactive.pulse, pTransitionPc);

					SequenceRenderer.copresenceEnabled = (opt.copresence.enabled && transitionPc<0.5)||tOpt.copresence.enabled;
				}
			}
			if (!noRender && delta) SequenceRenderer.smoothNoiseTime += delta * 0.05 * this.deformParams.smoothNoiseSpeed;
			if (isNaN(SequenceRenderer.smoothNoiseTime)) SequenceRenderer.smoothNoiseTime = 0.0;
		}



		if (!noRender) {
			if (SequenceRenderer.oignonSkinVectorEnabled) SequenceRenderer.pushOignonVectorPoints(SequenceRenderer.positions, SequenceRenderer.line.material);

			if (!this.strokeColor) {
				console.warn("wtf");
			}
			SequenceRenderer.strokeColor.copy(this.strokeColor);
			SequenceRenderer.clickFlash = opt.background.clickFlash;

			//---------------------------
			//
			// Update background shader
			//
			//---------------------------
			var bgMaterial = SequenceRenderer.backgroundMaterial;


			//---------------------------
			//
			// Transition all options / features to next state
			//
			//---------------------------
			if (this.transitionStarted ) {

				//---------------------------
				//
				// Transition background
				//
				//---------------------------
			
				//update background
				SequenceRenderer.renderBackground = (opt.background.enabled && opt.background.alpha>0.0)||tOpt.background.enabled && tOpt.background.alpha>0.0;

				bgMaterial.uniforms.resolution.value.set(AppStatus.innerWidth(), AppStatus.innerHeight());



				bgMaterial.uniforms.alpha.value = Utils.lerp(
					opt.background.enabled?opt.background.alpha:0.0,
					tOpt.background.enabled?tOpt.background.alpha:0.0,
					transitionPc
				);
				if (!SequenceRenderer.renderBackground) bgMaterial.uniforms.alpha.value = 0;

				var optDisabled = !opt.background.enabled || opt.background.alpha<=0.0;
				var tOptDisabled = !tOpt.background.enabled || tOpt.background.alpha<=0.0;

				var colorPc = transitionPc;
				if (opt.background.pulseGradient.alpha>=1.0 || optDisabled) colorPc = 1.0;
				if (tOpt.background.pulseGradient.alpha>=1.0 || tOptDisabled) colorPc = 0.0;
				bgMaterial.uniforms.fillColor.value.set(opt.background.fillColor).lerp(Utils.getTmpColor(tOpt.background.fillColor), colorPc);



				bgMaterial.uniforms.fillAlpha.value = Utils.lerp(
					!optDisabled?(1.0-opt.background.pulseGradient.alpha) : 0.0,
					!tOptDisabled?(1.0-tOpt.background.pulseGradient.alpha) : 0.0,
					transitionPc);
				// console.log(colorPc, bgMaterial.uniforms.fillAlpha.value);

				var pulsePc = transitionPc;
				if (opt.background.pulseGradient.alpha<=0.0 || optDisabled) pulsePc = 1.0;
				if (tOpt.background.pulseGradient.alpha<=0.0 || tOptDisabled) pulsePc = 0.0;

				//update material
				bgMaterial.uniforms.gradientMin.value = Utils.lerp(opt.background.pulseGradient.gradientMin,tOpt.background.pulseGradient.gradientMin,pulsePc);
				bgMaterial.uniforms.gradientMax.value = Utils.lerp(opt.background.pulseGradient.gradientMax,tOpt.background.pulseGradient.gradientMax,pulsePc);
				bgMaterial.uniforms.noiseA.value = Utils.lerp(opt.background.pulseGradient.noiseA,tOpt.background.pulseGradient.noiseA,pulsePc);
				bgMaterial.uniforms.noiseB.value = Utils.lerp(opt.background.pulseGradient.noiseB,tOpt.background.pulseGradient.noiseB,pulsePc);

				bgMaterial.uniforms.colorA.value.set(opt.background.pulseGradient.colorA).lerp(Utils.getTmpColor(tOpt.background.pulseGradient.colorA), pulsePc);
				bgMaterial.uniforms.colorB.value.set(opt.background.pulseGradient.colorB).lerp(Utils.getTmpColor(tOpt.background.pulseGradient.colorB), pulsePc);
				bgMaterial.uniforms.colorC.value.set(opt.background.pulseGradient.colorC).lerp(Utils.getTmpColor(tOpt.background.pulseGradient.colorC), pulsePc);

				SequenceRenderer.pulseSpeed = Utils.lerp(opt.background.pulseGradient.pulseSpeed,tOpt.background.pulseGradient.pulseSpeed,pulsePc);
				SequenceRenderer.gradientSpeed = Utils.lerp(opt.background.pulseGradient.changeSpeed,tOpt.background.pulseGradient.changeSpeed,pulsePc);

				SequenceRenderer.backgroundMaterialSimple.uniforms.fillColor.value.copy(bgMaterial.uniforms.fillColor.value);
				SequenceRenderer.backgroundMaterialSimple.uniforms.alpha.value = bgMaterial.uniforms.alpha.value;




				// console.log(opt.background.pulseGradient.alpha, tOpt.background.pulseGradient.alpha, pulsePc, bgMaterial.uniforms.fillAlpha.value);

				// //if no background before and background now, set all properties and tween alpha
				// if (!SequenceRenderer.renderBackground && transitionBackground) {
				// 	bgMaterial.uniforms.fillColor.value.set(tOpt.background.fillColor);
				// 	bgMaterial.uniforms.alpha.value = 0.0;
				// }

				// //if no pulse before and pulse now, set all properties and tween fillAlpha
				// if ((!SequenceRenderer.renderBackground || opt.background.pulseGradient.alpha<=0) && transitionPulse) {
				// 	bgMaterial.uniforms.gradientMin.value = tOpt.background.pulseGradient.gradientMin;
				// 	bgMaterial.uniforms.gradientMax.value = tOpt.background.pulseGradient.gradientMax;
				// 	bgMaterial.uniforms.noiseA.value = tOpt.background.pulseGradient.noiseA;
				// 	bgMaterial.uniforms.noiseB.value = tOpt.background.pulseGradient.noiseB;

				// 	bgMaterial.uniforms.colorA.value.set(tOpt.background.pulseGradient.colorA);
				// 	bgMaterial.uniforms.colorB.value.set(tOpt.background.pulseGradient.colorB);
				// 	bgMaterial.uniforms.colorC.value.set(tOpt.background.pulseGradient.colorC);
				// 	bgMaterial.uniforms.fillAlpha.value = 1.0-tOpt.background.pulseGradient.alpha;
				// }


				// //tween alpha
				// bgMaterial.uniforms.alpha.value = Utils.lerp(bgMaterial.uniforms.alpha.value, !transitionBackground?0.0:tOpt.background.alpha, transitionPc);
				
				// bgMaterial.uniforms.fillAlpha.value = Utils.lerp(bgMaterial.uniforms.fillAlpha.value, !transitionPulse?1.0:(1.0-tOpt.background.pulseGradient.alpha), transitionPc)

				// //tween all properties
				// bgMaterial.uniforms.fillColor.value.lerp(new THREE.Color(!transitionBackground?0xffffff:tOpt.background.fillColor), transitionPc);
				// SequenceRenderer.backgroundMaterialSimple.uniforms.fillColor.value.copy(bgMaterial.uniforms.fillColor.value);
				// SequenceRenderer.backgroundMaterialSimple.uniforms.alpha.value = bgMaterial.uniforms.alpha.value;

				// if (transitionPulse) {

				// 	bgMaterial.uniforms.gradientMin.value = Utils.lerp(bgMaterial.uniforms.gradientMin.value, tOpt.background.pulseGradient.gradientMin, transitionPc);
				// 	bgMaterial.uniforms.gradientMax.value = Utils.lerp(bgMaterial.uniforms.gradientMax.value, tOpt.background.pulseGradient.gradientMax, transitionPc);
				// 	bgMaterial.uniforms.noiseA.value = Utils.lerp(bgMaterial.uniforms.noiseA.value, tOpt.background.pulseGradient.noiseA, transitionPc);
				// 	bgMaterial.uniforms.noiseB.value = Utils.lerp(bgMaterial.uniforms.noiseB.value, tOpt.background.pulseGradient.noiseB, transitionPc);

				// 	bgMaterial.uniforms.colorA.value.lerp(new THREE.Color(opt.background.pulseGradient.colorA), transitionPc);
				// 	bgMaterial.uniforms.colorB.value.lerp(new THREE.Color(opt.background.pulseGradient.colorB), transitionPc);
				// 	bgMaterial.uniforms.colorC.value.lerp(new THREE.Color(opt.background.pulseGradient.colorC), transitionPc);
				// }

				if (opt.background.clickFlash) {
					var flashPc =  Math.pow(Utils.ccmap(now-SequenceRenderer.lastPressTime,0,350,1.0,0.0),2.0); //Math.pow(transitionPc,2.0));
					if (!tOpt.background.clickFlash) flashPc *= Math.pow(1.0- transitionPc,1.5);

					bgMaterial.uniforms.colorA.value.lerp(Utils.whiteColor,flashPc);
					bgMaterial.uniforms.colorB.value.lerp(Utils.whiteColor,flashPc);
					bgMaterial.uniforms.colorC.value.lerp(Utils.whiteColor,flashPc);
					bgMaterial.uniforms.fillColor.value.lerp(Utils.whiteColor,flashPc);

				}
			
			} else {
			
				SequenceRenderer.renderBackground = opt.background.enabled && opt.background.alpha>0.0;

				bgMaterial.uniforms.resolution.value.set(AppStatus.innerWidth(), AppStatus.innerHeight());
				bgMaterial.uniforms.alpha.value = SequenceRenderer.renderBackground?opt.background.alpha:0.0;
				bgMaterial.uniforms.fillColor.value.set(opt.background.fillColor);
				bgMaterial.uniforms.fillAlpha.value = 1.0-opt.background.pulseGradient.alpha;
				//update material
				bgMaterial.uniforms.gradientMin.value = opt.background.pulseGradient.gradientMin;
				bgMaterial.uniforms.gradientMax.value = opt.background.pulseGradient.gradientMax;
				bgMaterial.uniforms.noiseA.value = opt.background.pulseGradient.noiseA;
				bgMaterial.uniforms.noiseB.value = opt.background.pulseGradient.noiseB;

				bgMaterial.uniforms.colorA.value.set(opt.background.pulseGradient.colorA);
				bgMaterial.uniforms.colorB.value.set(opt.background.pulseGradient.colorB);
				bgMaterial.uniforms.colorC.value.set(opt.background.pulseGradient.colorC);

				SequenceRenderer.pulseSpeed = opt.background.pulseGradient.pulseSpeed;;
				SequenceRenderer.gradientSpeed = opt.background.pulseGradient.changeSpeed;;

				SequenceRenderer.backgroundMaterialSimple.uniforms.fillColor.value.set(opt.background.fillColor);
				SequenceRenderer.backgroundMaterialSimple.uniforms.alpha.value = bgMaterial.uniforms.alpha.value;

				if (opt.background.clickFlash) {
					var flashPc =  Math.pow(Utils.ccmap(now-SequenceRenderer.lastPressTime,0,350,1.0,0.0),2.0);

					bgMaterial.uniforms.colorA.value.lerp(Utils.whiteColor,flashPc);
					bgMaterial.uniforms.colorB.value.lerp(Utils.whiteColor,flashPc);
					bgMaterial.uniforms.colorC.value.lerp(Utils.whiteColor,flashPc);
					bgMaterial.uniforms.fillColor.value.lerp(Utils.whiteColor,flashPc);
				}
			}

			//pause button color
			var hslA = bgMaterial.uniforms.colorA.value.getHSL({h:0,s:0,l:1}),
				hslB = bgMaterial.uniforms.colorB.value.getHSL({h:0,s:0,l:1}),
				hslC = bgMaterial.uniforms.colorC.value.getHSL({h:0,s:0,l:1}),
				hslF = SequenceRenderer.backgroundMaterialSimple.uniforms.fillColor.value.getHSL({h:0,s:0,l:1});


			var gradientLuma = (hslA.l + hslB.l + hslC.l) /3;
			var fillLuma = hslF.l;
			var totalLuma = Utils.lerp(gradientLuma, fillLuma, bgMaterial.uniforms.fillAlpha.value);
			totalLuma += 1.0-bgMaterial.uniforms.alpha.value;


			var gradientSat = (hslA.s + hslB.s + hslC.s) /3;
			var fillSat = hslF.s;
			var totalSat = Utils.lerp(gradientSat, fillSat, bgMaterial.uniforms.fillAlpha.value);
			totalSat += 1.0-bgMaterial.uniforms.alpha.value;
			// AppStatus.log(totalSat,totalLuma,Utils.ccmap(totalLuma,0.95,1.0,0.0,1.0));

			// if (totalSat >= 0.5) {
			// 	SequenceRenderer.pauseButtonColor = 0.0;
			// } else {
			// 	SequenceRenderer.pauseButtonColor = Utils.ccmap(totalSat,0.4,0.5,1.0,0.0);
			// }
			SequenceRenderer.pauseButtonColor = Utils.ccmap(totalSat+totalLuma,1.0,1.25,1.0,0.0) - Utils.ccmap(totalLuma,0.95,1.0,0.0,1.0);
			// if (totalSat<=0.05 && totalLuma>0.9) SequenceRenderer.pauseButtonColor = Utils.ccmap(totalSat+totalLuma,0.9,1.25,1.0,0.0);
			if (this.params.isPause) {
				SequenceRenderer.pauseButtonColor = 0;
			}

			//---------------------------
			//
			// Update effects shaders
			//
			//---------------------------
			SequenceRenderer.lineWidth = opt.stroke.lineWidth||1;
			if (this.hasSvgSequence) SequenceRenderer.lineWidth *= 0.8;
			if (this.transitionStarted) {
				var startLW = (opt.stroke.lineWidth||1) * (this.hasSvgSequence?0.8:1.0);
				var endLW = (tOpt.stroke.lineWidth||1) * (this.nextSequence.hasSvgSequence?0.8:1.0);
				SequenceRenderer.lineWidth = Utils.lerp(startLW, endLW, transitionPc);
			}
			

			SequenceRenderer.oignonFrames = opt.effects.oignonSkin.enabled ? opt.effects.oignonSkin.frames : 0;
			SequenceRenderer.oignonSkip = opt.effects.oignonSkin.skip;
			SequenceRenderer.oignonColor = opt.effects.oignonSkin.color;
			SequenceRenderer.oignonFps = opt.effects.oignonSkin.fps;
			// SequenceRenderer.palettePc = opt.effects.oignonSkin.palette == 'palette_a' ? 0.0 : 1.0;
			SequenceRenderer.paletteA = SequenceRenderer.paletteB = opt.effects.oignonSkin.palette;
			SequenceRenderer.paletteMix = 0.0;


			SequenceRenderer.oignonVectorFrames = opt.effects.oignonSkinVector.enabled ? opt.effects.oignonSkinVector.frames : 0;
			SequenceRenderer.oignonVectorSkip = opt.effects.oignonSkinVector.skip;
			SequenceRenderer.oignonVectorColor = opt.effects.oignonSkinVector.color;
			SequenceRenderer.oignonVectorFps = opt.effects.oignonSkinVector.fps;
			// SequenceRenderer.oignonVectorPalettePc = opt.effects.oignonSkinVector.palette == 'palette_a' ? 0.0 : 1.0;
			SequenceRenderer.oignonVectorPaletteMixPc = 0.0;
			SequenceRenderer.oignonVectorPaletteA = SequenceRenderer.oignonVectorPaletteB = opt.effects.oignonSkinVector.palette;

			if (!this.transitionStarted) {

				SequenceRenderer.embossDst = opt.effects.emboss.dst;
				SequenceRenderer.embossAlpha = opt.effects.emboss.alpha;
				SequenceRenderer.embossEnabled = opt.effects.emboss.enabled;
				SequenceRenderer.oignonSkinEnabled = opt.effects.oignonSkin.enabled;
				SequenceRenderer.oignonSkinVectorEnabled = opt.effects.oignonSkinVector.enabled;
				SequenceRenderer.oignonVectorRandomBrush = opt.effects.oignonSkinVector.randomBrush;

			} else {
				SequenceRenderer.embossEnabled = opt.effects.emboss.enabled || tOpt.effects.emboss.enabled;
				SequenceRenderer.embossDst = opt.effects.emboss.dst;
				SequenceRenderer.embossAlpha = Utils.lerp(
					opt.effects.emboss.alpha * (opt.effects.emboss.enabled?1.0:0.0),
					tOpt.effects.emboss.alpha * (tOpt.effects.emboss.enabled?1.0:0.0),
					transitionPc);

				var pTransitionPc = transitionPc;
				var colorTransition = transitionPc;
				if (opt.transition.fastOignonSkin) colorTransition = pTransitionPc = Utils.ccmap(transitionPc, 0.333, 0.666, 0.0, 1.0);
				if (!opt.effects.oignonSkin.enabled) pTransitionPc = 1.0;
				if (!tOpt.effects.oignonSkin.enabled) pTransitionPc = 0.0;

				SequenceRenderer.oignonSkinEnabled = opt.effects.oignonSkin.enabled || tOpt.effects.oignonSkin.enabled;

				SequenceRenderer.oignonFrames =(
						Utils.lerp(opt.effects.oignonSkin.enabled ? opt.effects.oignonSkin.frames : 0,
								   tOpt.effects.oignonSkin.enabled ? tOpt.effects.oignonSkin.frames : 0,
								   colorTransition));

				SequenceRenderer.palettePc = Utils.lerp(opt.effects.oignonSkin.palette == 'palette_a' ? 0.0 : 1.0, tOpt.effects.oignonSkin.palette == 'palette_a' ? 0.0 : 1.0, pTransitionPc);
				SequenceRenderer.oignonColor = Utils.lerp(opt.effects.oignonSkin.color, tOpt.effects.oignonSkin.color, pTransitionPc);
				if (!tOpt.effects.oignonSkin.enabled) SequenceRenderer.oignonColor *= 1.0-Math.pow(colorTransition,5.0);
				if (!opt.effects.oignonSkin.enabled) SequenceRenderer.oignonColor *= Math.pow(colorTransition,0.33);

				// SequenceRenderer.oignonFps = Utils.lerp(opt.effects.oignonSkin.fps, tOpt.effects.oignonSkin.fps, pTransitionPc);
				SequenceRenderer.paletteMixPc = pTransitionPc;
				
				SequenceRenderer.paletteA = opt.effects.oignonSkin.enabled && opt.effects.oignonSkin.color > 0.0 ? opt.effects.oignonSkin.palette :  tOpt.effects.oignonSkin.palette;
				SequenceRenderer.paletteB = tOpt.effects.oignonSkin.enabled && tOpt.effects.oignonSkin.color > 0.0 ? tOpt.effects.oignonSkin.palette :  opt.effects.oignonSkin.palette;

		
				SequenceRenderer.oignonSkip = Math.round(Utils.lerp(opt.effects.oignonSkin.skip, tOpt.effects.oignonSkin.skip, pTransitionPc));
				SequenceRenderer.oignonFps = Math.round(Utils.lerp(opt.effects.oignonSkin.fps, tOpt.effects.oignonSkin.fps, pTransitionPc));


				pTransitionPc = transitionPc;
				colorTransition = transitionPc;
				if (opt.transition.fastOignonSkin) colorTransition = pTransitionPc = Utils.ccmap(transitionPc, 0.333, 0.666, 0.0, 1.0);
				if (!opt.effects.oignonSkinVector.enabled) pTransitionPc = 1.0;
				if (!tOpt.effects.oignonSkinVector.enabled) pTransitionPc = 0.0;

				SequenceRenderer.oignonSkinVectorEnabled = opt.effects.oignonSkinVector.enabled || tOpt.effects.oignonSkinVector.enabled;
				SequenceRenderer.oignonVectorFrames =(
						Utils.lerp(opt.effects.oignonSkinVector.enabled ? opt.effects.oignonSkinVector.frames : 1,
								   tOpt.effects.oignonSkinVector.enabled ? tOpt.effects.oignonSkinVector.frames : 1,
								   colorTransition));

				SequenceRenderer.oignonVectorSkip = Math.round(Utils.lerp(opt.effects.oignonSkinVector.skip, tOpt.effects.oignonSkinVector.skip, pTransitionPc));
				SequenceRenderer.oignonVectorFps = Math.round(Utils.lerp(opt.effects.oignonSkinVector.fps, tOpt.effects.oignonSkinVector.fps, pTransitionPc));


				SequenceRenderer.oignonVectorPaletteMixPc = pTransitionPc;
				SequenceRenderer.oignonVectorColor = Utils.lerp(opt.effects.oignonSkinVector.enabled?opt.effects.oignonSkinVector.color:0.0, tOpt.effects.oignonSkinVector.enabled?tOpt.effects.oignonSkinVector.color:0.0, transitionPc);
				if (!tOpt.effects.oignonSkinVector.enabled) SequenceRenderer.oignonVectorColor *= 1.0-Math.pow(colorTransition,5.0);
				if (!opt.effects.oignonSkinVector.enabled) SequenceRenderer.oignonVectorColor *= Math.pow(colorTransition,0.33);

				SequenceRenderer.oignonVectorPaletteA = opt.effects.oignonSkinVector.enabled && opt.effects.oignonSkinVector.color > 0.0 ? opt.effects.oignonSkinVector.palette :  tOpt.effects.oignonSkinVector.palette;
				SequenceRenderer.oignonVectorPaletteB = tOpt.effects.oignonSkinVector.enabled && tOpt.effects.oignonSkinVector.color > 0.0 ? tOpt.effects.oignonSkinVector.palette :  opt.effects.oignonSkinVector.palette;

				SequenceRenderer.oignonVectorRandomBrush = Utils.lerp(opt.effects.oignonSkinVector.randomBrush, tOpt.effects.oignonSkinVector.randomBrush, pTransitionPc);


			}
			



			//---------------------------
			//
			// Update installation color
			//
			//---------------------------
			if ((SETTINGS.INSTALLATION_MODE || SETTINGS.HUE_ENABLED) && !noRender) {
				if (opt.installation && (!tOpt || (tOpt && tOpt.installation))  ) {


					//intro slow glow
					HueController.introMode = (this.isPause || this.isIntro || this.params.isPause || this.params.isIntro || this.params.ending) && !this.params.introFin;

					//tonnerre flash mode
					HueController.flashMode = opt.background.clickFlash;
					if (opt.background.clickFlash) {
						if (this.transitionStarted && !tOpt.background.clickFlash) HueController.flashPc = Math.pow(1.0- transitionPc,1.5);
						else HueController.flashPc = 1.0;

						HueController.shouldFlash = HueController.shouldFlash || SequenceRenderer.pressedThisFrame;
					}

					//custom color
					HueController.customColorEnabled = !opt.installation.defaultColors;
					HueController.customColor.setStyle(opt.installation.color);
					HueController.customColorPaletteMode = opt.installation.paletteMode;

					HueController.customColorBEnabled = !opt.installation.defaultColors && opt.installation.colorBEnabled;

					HueController.silhouetteMode = opt.layers.timelineEyes && !opt.installation.paletteMode;

					if (opt.installation.paletteMode) {

						var paletteA = AppStatus.infoJson.oignon_palettes[
							opt.effects.oignonSkinVector.enabled ? opt.effects.oignonSkinVector.palette : opt.effects.oignonSkin.palette
						];
						var palCol = Math.floor( (now/800) * paletteA.length) % paletteA.length;
						opt.installation.color = paletteA[palCol];
						HueController.customColor.setStyle(opt.installation.color);

					}

					if (HueController.silhouetteMode) {

						var paletteA = AppStatus.infoJson.oignon_palettes[
							opt.effects.oignonSkinVector.enabled ? opt.effects.oignonSkinVector.palette : opt.effects.oignonSkin.palette
						];
						var palCol = Math.floor( (now/4000) * paletteA.length) % paletteA.length;
						HueController.customColor.setStyle(opt.installation.color);
						HueController.customColor.r *= 0.2;
						HueController.customColor.g *= 0.2;
						HueController.customColor.b *= 0.2;
						HueController.customColor.r += 0.5;
						HueController.customColor.g += 0.5;
						HueController.customColor.b += 0.5;
						opt.installation.color = '#'+HueController.customColor.getHexString();

					}





					//custom color transition
					if (!this.transitionStarted) {
						HueController.customColorTransitionPc = 0.0;
						HueController.customColorNextEnabled = false;
						HueController.customColorBNextEnabled = false;
						HueController.silhouetteModeNext = false;

					} else {
						HueController.customColorTransitionPc = transitionPc;
						HueController.customColorNextEnabled = !tOpt.installation.defaultColors;
						HueController.customColorNext.setStyle(tOpt.installation.color);
						HueController.customColorNextPaletteMode = tOpt.installation.paletteMode;

						HueController.customColorBNextEnabled = !tOpt.installation.defaultColors && tOpt.installation.colorBEnabled;
						HueController.customColorBNext.setStyle(tOpt.installation.colorB);

						HueController.silhouetteModeNext = tOpt.layers.timelineEyes && !tOpt.installation.paletteMode;
						HueController.customColorNextTime += delta * 1/60 * (tOpt.background.pulseGradient.pulseSpeed+tOpt.background.pulseGradient.changeSpeed/4);
					}

					if (HueController.customColorBEnabled) HueController.customColorB.setStyle(opt.installation.colorB);
					if (HueController.customColorBNextEnabled) HueController.customColorB.setStyle(tOpt.installation.colorB);


					//palette mode
					HueController.customColorPaletteMode = opt.installation.paletteMode;
					HueController.customColorNextPaletteMode = this.transitionStarted && tOpt && tOpt.installation.paletteMode;

					HueController.customColorTime += delta * 1/60 * (0.1 + Utils.cmap(opt.background.pulseGradient.alpha,0.0,0.25,0.0,1.0)*(opt.background.pulseGradient.pulseSpeed/3+opt.background.pulseGradient.changeSpeed/4));


					//editor
					// console.log("wtf");
					if (!SETTINGS.TIMELINE_MODE) {
						HueController.enabled = opt.installation.connected;
						if (opt.installation.defaultColors) {
							Utils.tmpColor.setRGB(HueController.latestColor.x, HueController.latestColor.y, HueController.latestColor.z);
							opt.installation.color = '#'+Utils.tmpColor.getHexString();
						}
						// if (HueController.enabled) {
						// Utils.tmpColor.setRGB(HueController.customColorB.r, HueController.customColorB.g, HueController.customColorB.b);
						opt.installation.colorB = '#'+HueController.customColorB.getHexString();
						// AppStatus.log('#'+HueController.customColorB.getHexString());
						// }
						
					}
				}
			}



			//---------------------------
			//
			// Drawing
			//
			//---------------------------
			if (opt.draw.enabled) {

				this.updateDraw(0);
				if (opt.draw.kaleidoscope > 0) {
					for (var i = 0; i <opt.draw.kaleidoscope; i++) {
						this.updateDraw(i+1);
					}
				}
			}

			//
			// Update points for all animations
			//
			var currentPoints = [];
			// if (this.drawAnimations.length>0) console.log(Math.floor(this.drawAnimations[0].currentFrame));
			for (var an=0; an<this.drawAnimations.length; an++) {
				var animation = this.drawAnimations[an];

				animation.currentFrame += Math.min(delta * (1/60) * opt.draw.fps,0.99);
				var cf = Math.floor(animation.currentFrame % animation.animationFrames.length);
				if (animation.isDrawing) cf = animation.animationFrames.length-1;
				var svgPoints = animation.animationFrames[cf]; //].concat(animation.multiPoints[cf]);
				// svgPoints.isDrawing = animation.isDrawing;
				if (svgPoints.length >= 6 && (!animation.isDrawing||opt.draw.duplicateCursor||animation.drawId>0)) currentPoints.push(svgPoints);
					// currentPoints = currentPoints.concat(svgPoints);
			}
			this.drawFrame.points = currentPoints;


		}
	}





	//---------------
	// 
	// Update a line to render with a positions vec3 array
	//
	//--------------
	updateLine(now, lineIndex, positions, numPoints, _pressPc, powerEffect, deformPc, layerName, noEffect) {
		var opt = this.options,
			mOpt = this.magnetismParams,
			dOpt = this.deformParams;

		var size = mOpt.size;
		var curve = mOpt.curve;

		var pressPc = _pressPc;
		// if (SETTINGS.SIDES_CURVE_MODE) {
			size *= 1.0 + SequenceRenderer.sidesDistance*2.0;
			pressPc *= 1.0 + SequenceRenderer.sidesDistance*1.75;
			pressPc *= 1.0 + Math.pow(SequenceRenderer.staticDragPc,1.5);
			curve *= 1.0 + SequenceRenderer.staticDragPc;
			size *= 1.0 - SequenceRenderer.staticDragPc*0.2;
		// }
		
		// var timelineEyesMode = (opt.layers.timelineEyes && layerName == 'eyes' );

		var centerY = SequenceRenderer.currentPosition.y;
		if (SETTINGS.isMobile) centerY-=0.06*mOpt.attract;
		var centerX = SequenceRenderer.currentPosition.x;

		var linePositions = null;
		if (!this.timelineEyesMode) linePositions = SequenceRenderer.getPositionsArray(positions);
		else linePositions = SequenceRenderer.getEyesArray(positions);


		if (!noEffect) {

			var waveTime = now/1000;

			var magnetismEnabled = Math.abs(pressPc) > 0.0001;


			var deformNoise = dOpt.noise * deformPc * 0.001;
			var deformSmoothNoise = dOpt.smoothNoise * deformPc * 0.005;
			var deformWaveform = dOpt.waveform * deformPc;

			var noiseEnabled = deformNoise > 0.00001;
			var smoothNoiseEnabled = deformSmoothNoise > 0.00001;
			var waveformEnabled = deformWaveform > 0.001;
			var smoothNoiseTimeX = SequenceRenderer.smoothNoiseTime + lineIndex;
			var smoothNoiseTimeY = -SequenceRenderer.smoothNoiseTime - lineIndex;
			var smoothNoiseScale = Math.pow(dOpt.smoothNoiseScale,1.5)*100.333;

			var randomShake = (powerEffect+SequenceRenderer.staticDragPc*(mOpt.attract*0.2+0.05)) * mOpt.randomShake * SequenceRenderer.pressPc;
			if (SequenceRenderer.simulationStarted) randomShake = 0.0;
			var randomShakeEnabled = randomShake > 0.0;


			var wave = this.audioWaveTime;

			var waveCenterX = Math.cos(now/1000);
			var waveCenterY = Math.sin(now/1000);

			// var mobileOffset = (layerName == 'over' && opt.layers.mobileTextOffset > 0) ? 


			if (!positions || !linePositions) {
				// window.lineErrorSent = true;
				console.warn("major updateLine error",positions,positions.length, linePositions, SequenceRenderer.currentIdx);
				return;
			}


			var iInverse = 1.0-mOpt.inverse;
			// var iAttract = 1.0-mOpt.attract;

			var cdst = 0.0;
			for (var j = 0; j < numPoints; j+=3) {

				var vx = 0.0, vy = 0.0;

				//calculate magnetism
				if (magnetismEnabled) {
					// var dst = Utils.distance(positions[j], positions[j+1], centerX, centerY );
					// // var dst = Utils.fastD(positions[j], positions[j+1], centerX, centerY);
					// vx = (positions[j] - centerX) / dst;
					// vy = (positions[j+1] - centerY ) / dst;
					// var strg = Utils.ccmap(Math.pow(dst, curve), size, 0.0, 0.0, 1.0);
					// strg = Utils.lerp(strg, 1.0 - strg, mOpt.inverse);
					// strg = Utils.lerp(strg, strg * -1, mOpt.attract);
						
					// if (j==0) strg *= 1.0-mOpt.extrude;
					// // positions[j] += vx * strg * pressPc;
					// // positions[j+1] += vy * strg * pressPc;
					// // 
					// // if (SETTINGS.SIDES_CURVE_MODE) {
					// //  var angle = Math.atan2(positions[j+1], positions[j]);
					// // 	strg *= 1.0 + 0.2*Math.sin(angle*5.0+waveTime) * SequenceRenderer.sidesDistance;
					// // }
					// vx *= strg * pressPc;
					// vy *= strg * pressPc;
					vx = (positions[j] - centerX);
					vy = (positions[j+1] - centerY);
					var dst = Math.sqrt(vx*vx + vy*vy);
					var strg = Math.min(Math.max( ((Math.pow(dst, curve) - size) / (- size)), 0), 1);
					strg = strg * iInverse + (1.0-strg) * mOpt.inverse;
					strg += (-strg) * mOpt.attract*2.0;

					if (j==0) strg *= 1.0-mOpt.extrude;
					vx *= strg * pressPc * (1.0/dst);
					vy *= strg * pressPc * (1.0/dst);
				}
				

				//calculate noise effect
				if (noiseEnabled) {
					vx += (Math.random()*2.0-1.0)*deformNoise;
					vy += (Math.random()*2.0-1.0)*deformNoise;
				}

				//calculate smooth noise effect
				if (smoothNoiseEnabled) {
					cdst += j<numPoints-3?Utils.distance(positions[j], positions[j+1], positions[j+3], positions[j+4])*smoothNoiseScale:0.0;
					vx += SequenceRenderer.rng(smoothNoiseTimeX,cdst)*deformSmoothNoise;
					vy += SequenceRenderer.rng(smoothNoiseTimeY,cdst)*deformSmoothNoise;
				}

				//calculate waveform effect
				if (waveformEnabled) {
					var angle = Math.atan2(positions[j+1], positions[j]);
					var centerDst = Math.pow(Utils.distance(positions[j], positions[j+1], 0, 0),2.0);
					for (var k=0; k<12; k++) {
						var angleDelta = (Math.sin(angle*(10.5*k+12.0))*0.5+0.5) * wave[k] * centerDst;
						vx += Math.cos(angle) * angleDelta;
						vy += Math.sin(angle) * angleDelta;
					}
				}

				if (randomShakeEnabled) {
					var waveAngle = Math.atan2(positions[j+1]-  SequenceRenderer.currentPosition.y, positions[j]- SequenceRenderer.currentPosition.x);
					var waveDst = Utils.distance(positions[j], positions[j+1], SequenceRenderer.currentPosition.x, SequenceRenderer.currentPosition.y);
					vx += Math.cos(waveAngle)*SequenceRenderer.rng(now/100,waveDst*3.0 + lineIndex*0.02+j*0.01)*0.07*Math.pow(waveDst,0.5) * randomShake; //1.0 * 1.0;
					vy += Math.sin(waveAngle)*SequenceRenderer.rng(now/100,-waveDst*3.0 + lineIndex*0.02+j*0.01)*0.07*Math.pow(waveDst,0.5) * randomShake; //1.0 * 1.0;
				}

				//click wave
				// if (SETTINGS.CLICK_WAVE_MODE) {
				// 	var waveAngle = Math.atan2(positions[j+1], positions[j]);
				// 	var waveDst = Utils.distance(positions[j], positions[j+1], waveCenterX, waveCenterY) * 20;
				// 	waveDst = Math.abs(Math.sin(waveDst + now/500) * (Math.sin(waveDst)/(waveDst+0.01)));
				// 	vx += Math.cos(waveAngle) * waveDst * 1.0 * SequenceRenderer.clickPc;
				// 	vy += Math.sin(waveAngle) * waveDst * 1.0 * SequenceRenderer.clickPc;
				// }

				// if (SETTINGS.RANDOM_DEFORM) {
				// 	var waveAngle = Math.atan2(positions[j+1]-  SequenceRenderer.currentPosition.y, positions[j]- SequenceRenderer.currentPosition.x);
				// 	var waveDst = Utils.distance(positions[j], positions[j+1], SequenceRenderer.currentPosition.x, SequenceRenderer.currentPosition.y);
				// 	waveDst = waveDst * (Math.sin(waveDst*10.0 + now/300) * (Math.sin(waveDst*3.0)/(waveDst+0.01)));
				// 	waveDst = Math.pow(waveDst,2.0) * (Math.sin(waveAngle*4.0 + now/300));
				// 	vx += Math.cos(waveAngle) * -(waveDst*0.1) * SequenceRenderer.powerEffectPc * SequenceRenderer.pressPc; //1.0 * 1.0;
				// 	vy += Math.sin(waveAngle) * -(waveDst*0.1) * SequenceRenderer.powerEffectPc * SequenceRenderer.pressPc; //1.0 * 1.0;
				// }

				linePositions[j] = positions[j] + vx;
				linePositions[j+1] = positions[j+1] + vy;
				linePositions[j+2] = 0;
				// positions[j] += vx;
				// positions[j+1] += vy;
			}
		}

		//Update renderer lines
		if (positions.length <=0 || numPoints/3-1 <= 0) {
			if (AppStatus.currentFrame%30==0) console.warn("oh no",positions.length);
		}
		// SequenceRenderer.addPositions(positions);



		if (!this.timelineEyesMode) SequenceRenderer.setPositionsArray(linePositions);
		else SequenceRenderer.setEyesArray(linePositions);
	}


	
	updateDraw(drawId) {
		var opt = this.options,
			now = performance.now();

		var drawAnimation = null;
		if (drawId == 0) {
			drawAnimation = this.currentDrawAnimation;
		} else {
			drawAnimation = this.currentDrawKaleidoscope[drawId];
		}
		var rotationAngle = opt.draw.kaleidoscope == 0 ? 0 : ( (drawId) * (Math.PI*2 / (opt.draw.kaleidoscope+1)));

		if (opt.draw.kaleidoscope > 0.1) {
			rotationAngle = rotationAngle*0.3 -  (Math.PI*2 / (opt.draw.kaleidoscope+1)) * 0.6;
			rotationAngle += SequenceRenderer.rng(drawId*3.0, now/500)*0.25 - 0.5;
		}
	

		if (drawAnimation && (!SequenceRenderer.isDragging || this.transitionStarted)) {

			if (!SequenceRenderer.isDragging && !this.transitionStarted && drawAnimation.currentDrawPoints && drawAnimation.currentDrawPoints.length>9) {
				drawAnimation.currentFrame = Math.max(Math.floor(drawAnimation.currentFrame)-1,0);
				while (drawAnimation.currentDrawPoints.length>6) {
					drawAnimation.currentDrawPoints.shift();
					drawAnimation.currentDrawPoints.shift();
					drawAnimation.currentDrawPoints.shift();
					drawAnimation.currentFrame += 0.25;
					drawAnimation.animationFrames[Math.floor(drawAnimation.currentFrame)] = new Float32Array(drawAnimation.currentDrawPoints);
				}
				drawAnimation.currentFrame = 0;
			}
			// } else {
			drawAnimation.currentDrawPoints = [0,0,0];
			if (drawAnimation) drawAnimation.isDrawing = false;
			drawAnimation = null;
		}

		if (SequenceRenderer.isDragging) {


			var rawPosition = SequenceRenderer.rawPosition.clone();
			rawPosition.x *= SequenceRenderer.cameraStretch.x;
			rawPosition.y *= SequenceRenderer.cameraStretch.y;

			if (Math.abs(rotationAngle) > 0.01 && opt.draw.kaleidoscope > 0) {
				var an = Math.atan2(rawPosition.y, rawPosition.x);
				var dst = Utils.distance(rawPosition.x, rawPosition.y, 0, 0);
				dst *= 1.0 + SequenceRenderer.rng(drawId*3.0, now/500)*0.2;
				rawPosition.x = Math.cos(an+rotationAngle)*dst;
				rawPosition.y = Math.sin(an+rotationAngle)*dst;
			}


			//start drawing at 0
			if (!drawAnimation) {
				drawAnimation = {
					currentFrame: 0,
					animationFrames: [],
					isDrawing: true,
					drawId: drawId,
					currentDrawPoints: [],
					lastDrawPoint: null
				};
				this.drawAnimations.push(drawAnimation);



				drawAnimation.currentDrawPoints = [rawPosition.x, rawPosition.y, 0];
				// drawAnimation.currentDrawPoints[0].y += 0.25;
				drawAnimation.lastDrawPoint = rawPosition.clone();
				// drawAnimation.lastDrawPoint.x *= SequenceRenderer.cameraStretch.x;
				// drawAnimation.lastDrawPoint.y *= SequenceRenderer.cameraStretch.y;
			}

		
			var dstMod = (SETTINGS.isTablet && opt.draw.kaleidoscope > 0) ? 0.0015 : 0.0005;
			var numRes = (SETTINGS.isTablet && opt.draw.kaleidoscope > 0) ? 24 : 64;

			if (drawAnimation.lastDrawPoint.distanceTo(rawPosition) > dstMod*(opt.draw.lineLength*0.5+0.5)) {

				if (opt.draw.mode == 'small') {

					var dst = rawPosition.distanceTo(drawAnimation.lastDrawPoint);
					var n = dst * numRes * 1.0 * Utils.cmap(opt.draw.lineLength,0,0.5,4.0,1.0); //point resolution on screen
					n = Math.max(Math.ceil(n),1); //num points

					var sp = drawAnimation.lastDrawPoint.clone();
					var tp = rawPosition.clone();
					// tp.x += SequenceRenderer.rng(rawPosition.x*0.0005-now/30000, rawPosition.y*0.0005-now/30000)*0.25;
					// tp.y += SequenceRenderer.rng(rawPosition.x*-0.0005+now/30000, rawPosition.y*-0.0005+now/30000)*0.25;
					
					for (var i = 0; i < n; i++) {
						var pos = sp.clone();

						pos.lerp(tp, (i+1)/n);

						// pos.x *= renderer.domElement.width / renderer.domElement.height;
						// pos.y += 0.25;

						drawAnimation.currentDrawPoints.push(pos.x);
						drawAnimation.currentDrawPoints.push(pos.y);
						drawAnimation.currentDrawPoints.push(drawAnimation.currentDrawPoints.length%4==3?1:0);
					}
					while (drawAnimation.currentDrawPoints.length > Math.max((12*3) * opt.draw.lineLength,6)) {
						drawAnimation.currentDrawPoints.shift();
						drawAnimation.currentDrawPoints.shift();
						drawAnimation.currentDrawPoints.shift();
					}
				}
				drawAnimation.lastDrawPoint.copy(rawPosition);
			}

			//set current frame
			drawAnimation.animationFrames[Math.floor(drawAnimation.currentFrame)] = new Float32Array(drawAnimation.currentDrawPoints);
		}


		//set drawAnimation
		if (drawId ==0) {
			this.currentDrawAnimation = drawAnimation;
		} else {
			this.currentDrawKaleidoscope[drawId] = drawAnimation;
		}
	}



	render(target) {



	}


	//
	// Begin transition to next sequence
	//
	beginTransition(nextSequence, transitionOptions, useFirstFrame) {


		if (!this.wasSetup) {
			console.warn("Starting transition before update");
		}


		this.nextSequence = nextSequence;
		this.transitionOptions = transitionOptions;
		this.transitionStarted = true;
		this.autoTimeStartTime = performance.now();


		//
		// Create transition start frame
		//
		this.transitionFrames = {
			eyes: null,
			back: null,
			front: null,
			over: null
		};

		if (this.specialCountdownMode && (!this.latestPointsByLayer["over"] || (this.latestPointsByLayer["over"] && this.latestPointsByLayer["over"].length<=0))) {
			this.latestPointsByLayer["over"] = this.layers["over"][this.layers["over"].length - 1] ? this.layers["over"][this.layers["over"].length-1].getPositions() : [];
		}

		// if (useFirstFrame) {
		// 	this.update(0,0,0);
		// }

		for (var layerName in this.latestPointsByLayer) {
			if (this.latestPointsByLayer[layerName] && this.latestPointsByLayer[layerName].length > 0) {
				// if (this.latestPointsByLayer[layerName].length>0 && isNaN(this.latestPointsByLayer[layerName].linePoints[0])) {
				// 	console.warn("nan points 3");
				// }
				var f = new SVGFrame();
				f.fromPoints(this.latestPointsByLayer[layerName]);
				this.transitionFrames[layerName] = f;
			}
		}

		if (!nextSequence.layers) {
			console.warn('transition error:',nextSequence);
		}

	
		//merge over
		var shouldMergeOver = (!!this.latestPointsByLayer.over.length !== !!nextSequence.latestPointsByLayer.over.length);
		if (shouldMergeOver && this.layers.over.length) {
			if (this.transitionFrames.back||this.transitionFrames.front||this.transitionFrames.eyes)
				(this.transitionFrames.back||this.transitionFrames.front||this.transitionFrames.eyes).merge(this.transitionFrames.over, "over");
			else
				{this.transitionFrames.back = this.transitionFrames.over;  if (this.transitionFrames.back) {this.transitionFrames.back.setSourceLayer("over");}}
			this.transitionFrames.over = null;
		}

		//merge eyes
		var shouldMergeEyes = (!!this.latestPointsByLayer.eyes.length !== !!nextSequence.latestPointsByLayer.eyes.length);
		if (shouldMergeEyes && this.layers.eyes.length) {
			if (this.transitionFrames.back||this.transitionFrames.front||this.transitionFrames.over)
				(this.transitionFrames.back||this.transitionFrames.front||this.transitionFrames.over).merge(this.transitionFrames.eyes, "eyes");
			else
				{this.transitionFrames.back = this.transitionFrames.eyes;  if (this.transitionFrames.back) {this.transitionFrames.back.setSourceLayer("eyes");}}
			this.transitionFrames.eyes = null;
		}
		// console.log("eyes:",this.latestPointsByLayer.eyes, nextSequence.latestPointsByLayer.eyes);


		//merge and match layers
		var shouldMergeFront = (!!this.latestPointsByLayer.front.length !== !!nextSequence.latestPointsByLayer.front.length);
		if (shouldMergeFront) {
			if (this.transitionFrames.back||this.transitionFrames.eyes||this.transitionFrames.over)
				(this.transitionFrames.back||this.transitionFrames.eyes||this.transitionFrames.over).merge(this.transitionFrames.front, "front");
			else
				{this.transitionFrames.back = this.transitionFrames.front;  if (this.transitionFrames.back) {this.transitionFrames.back.setSourceLayer("front");}}
			this.transitionFrames.front = null;
		}
		// console.log("front:",this.latestPointsByLayer.front, nextSequence.latestPointsByLayer.front);

		//merge and match layers
		var shouldMergeBack = (!!this.latestPointsByLayer.back.length !== !!nextSequence.latestPointsByLayer.back.length);
		if (shouldMergeBack) {
			if (this.transitionFrames.front||this.transitionFrames.eyes||this.transitionFrames.over) {
				(this.transitionFrames.front||this.transitionFrames.eyes||this.transitionFrames.over).merge(this.transitionFrames.back, "back");
				this.transitionFrames.back = null;
			}
		}
		// console.log("back:",this.latestPointsByLayer.back, nextSequence.latestPointsByLayer.back);

		// console.log("Merges:",shouldMergeBack, shouldMergeFront, shouldMergeEyes, shouldMergeOver, this.transitionFrames);


	}
	endTransition(dispose) {

		this.nextSequence = null;
		this.transitionStarted = false;
		this.autoTimeStartTime = performance.now();

		this.transitionFrames = {
			eyes: null,
			back: null,
			front: null,
			over: null
		};


		if (dispose) {
			this.dispose();
		}
	}

	//
	// Get current frame for transitioning between sequences or svgsequences or states 
	//
	getFreezeFrame(layerName) {
		return this.transitionFrames[layerName];
	}
	// getFreezeFrame(layerName, shouldMerge, useFirstFrame) {

	// 	//add other layers to frame
	// 	if (shouldMerge) {

	// 	}
	// }

	hasLayer(layerName) {
		return !!this.layers[layerName];
	}

	//
	// Migrate older options
	//
	migratePreset(opt, version, info) {

		if (info && info.migrated) return opt;
		if (info) info.migrated = true;

		// if (!opt.effects.oignonSkin.fadeTime)
		// console.log("migrate:", opt.brush);
		if (opt.brush.texture == 'brush1.png' && version < 4) {
			opt.brush.texture = 'none';
		}
		if (!opt.effects.oignonSkinVector) {
			opt.effects.oignonSkinVector = Utils.clone(SequenceOptions.options.effects.oignonSkinVector);
		}

		if (version < 4) {
			opt.magnetism.backLayer = 1.0;
			opt.magnetism.frontLayer = 1.0;
			opt.magnetism.eyesLayer = 1.0;

			opt.timeline = opt.audio;

			// opt.morph.sequenceFps = 12;

			opt.draw = Utils.clone(SequenceOptions.options.draw);

			delete opt.aaudio;
		}
		if (version <= 5 && opt.morph.sequenceFps == 8) {
			opt.morph.sequenceFps = 12;
		}

		if (!opt.stroke.interactive) opt.stroke.interactive = Utils.clone(SequenceOptions.options.stroke.interactive);
		if (!opt.stroke.pulse) opt.stroke.pulse = Utils.clone(SequenceOptions.options.stroke.pulse);
		if (!opt.deform) opt.deform = Utils.clone(SequenceOptions.options.deform);
		if (!opt.deform.noise) opt.deform.noise = 0.0;
		if (!opt.deform.smoothNoiseSpeed) opt.deform.smoothNoiseSpeed = Utils.clone(SequenceOptions.options.deform.smoothNoiseSpeed);
		if (!opt.deform.smoothNoiseScale) opt.deform.smoothNoiseScale = Utils.clone(SequenceOptions.options.deform.smoothNoiseScale);

		if (opt.magnetism.extrude == undefined) opt.magnetism.extrude = 0.0;
		if (opt.layers.scale == undefined) opt.layers.scale = 1.0;

		if (!opt.effects.digitalPrototype) opt.effects.digitalPrototype = Utils.clone(SequenceOptions.options.effects.digitalPrototype);

		if (opt.effects.oignonSkinVector.randomBrush == undefined) opt.effects.oignonSkinVector.randomBrush = 0.0;

		// if (version < 6) {
		// 	opt.effects.oignonSkinVector = Utils.clone(opt.effects.oignonSkin);
		// 	opt.effects.oignonSkin = Utils.clone(SequenceOptions.options.effects.oignonSkin);
		// }
		if (version < 8) {
			opt.layers.over = {values:[]};
			opt.deform.overLayer = opt.deform.frontLayer;
			opt.magnetism.overLayer = opt.magnetism.frontLayer;
		}

		if (opt.magnetism.randomShake == undefined) opt.magnetism.randomShake = 0.0;
		if (opt.magnetism.randomCursor == undefined) opt.magnetism.randomCursor = 1.0;

		if (opt.morph.click == undefined) opt.morph.click = 1.0;
		
		if (version < 10) {
			opt.magnetism.randomShake = opt.magnetism.attract;
			opt.magnetism.randomCursor = 1.0-opt.magnetism.attract;
		}
		if (version < 11) {
			opt.layers.timelineEyes = false;
			if (opt.layers.eyes.values.length > 0) {
				if (/seq_eyes/.test(opt.layers.eyes.values[0])) {
					opt.layers.timelineEyes = true;
				}
			}
		}
		if (opt.morph.dontMorphOver === undefined) opt.morph.dontMorphOver = false;
		if (opt.background.clickFlash === undefined) opt.background.clickFlash = false;
		if (version < 13) {
			opt.magnetism.randomShake = 1.0;
			opt.magnetism.randomCursor = 1.0;
		}
		if (opt.draw.duplicateCursor == undefined) opt.draw.duplicateCursor = false;
		if (opt.draw.kaleidoscope == undefined) opt.draw.kaleidoscope = 0;

		if (opt.layers.mobileTextOffset == undefined) opt.layers.mobileTextOffset = 0.0;
		if (opt.background.roundedBox == undefined) opt.background.roundedBox = false;

		if (opt.transition.fastOignonSkin == undefined) opt.transition.fastOignonSkin = false;


		if (!opt.installation) {
			opt.installation = Utils.clone(SequenceOptions.options.installation);
			opt.installation.defaultColors = true;
			opt.installation.paletteMode = false;
		}
		opt.installation.connected = false;

		opt.installation.colorBEnabled = !!opt.installation.colorBEnabled;
		if (!opt.installation.colorBEnabled) opt.installation.colorB = '#ffffff';
		// opt.installation.paletteModeSlow = !!opt.installation.paletteModeSlow;


		if (SETTINGS.FAST_DEBUG_MODE) {

			// opt.effects.oignonSkin.enabled = false;
			// if (tOpt) tOpt.effects.oignonSkin.enabled = false;

			// opt.effects.oignonSkinVector.enabled = false;
			// if (tOpt) tOpt.effects.oignonSkinVector.enabled = false;

			// opt.effects.draw.enabled = false;
			// if (tOpt) tOpt.effects.draw.enabled = false;

			// opt.effects.brush.enabled = false;
			// if (tOpt) tOpt.effects.brush.enabled = false;
			// opt.copresence.enabled = Math.floor(now/5000) % 2 == 0;
		}

		return opt;
	}


	//
	// Dispose : not coming back, release all assets
	//
	dispose() {
		if (this.disposed) return;
		this.disposed = true;
		if (this.params.downloadCheck) BranchController.hasDownloaded = false;
		this.wasSetup = false;
		this.setup();
	}

}

export default Sequence;