import SETTINGS from '../Settings.js';
import AppStatus from './AppStatus.js'


class StorageController {

	constructor() {
		this.testDone = false;
		this.enabled = false;
		this.reloaded = false;
		this.reloadedLastDateTime = 0;
		this.reloadedTimecode = 0;
	}

	init() {
		this.testStorage();
	}

	setState(timecode) {
		if (!this.testDone) this.init();
		if (this.enabled) {
			var state = {
				dateTime: Date.now(),
				timecode: timecode,
				longDuration: AppStatus.SELECTED_DURATION_LONG
			};
			try {
				window.localStorage.setItem("state_"+SETTINGS.LANGUAGE, JSON.stringify(state));
			} catch (err) {console.log(err);}
		}
	}

	clearState() {
		if (!this.testDone) this.init();
		if (this.enabled) {
			window.localStorage.removeItem("state_"+SETTINGS.LANGUAGE);
		}
	}

	getState() {
		if (!this.testDone) this.init();
		if (this.enabled) {
			var loadedState = null;
			try {
				loadedState = window.localStorage.getItem("state_"+SETTINGS.LANGUAGE);
			} catch (err) {console.log(err);}
			if (!loadedState) {
				this.reloaded = false;
				return false;
			}
			loadedState = JSON.parse(loadedState);
			this.reloadedDateTime = loadedState.dateTime;
			return loadedState;
		} else {
			return false;
		}
	}


	testStorage() {
		if (this.testDone) return this.enabled;
		this.testDone = true;
		if (window.localStorage) {
			try 
		    {
		        window.localStorage.setItem("localstorage-test", '1');
		        window.localStorage.removeItem("localstorage-test");
		        this.enabled = true;
		    }  catch (error)  {console.log(error);}
		}
		console.log("Local storage:",this.enabled);
		return this.enabled;
	};
}

window.StorageController = window.StorageController||new StorageController();
export default window.StorageController;