import SETTINGS from '../Settings.js';
import LoaderXHR from './LoaderXHR.js';
import AudioBinaryBuffer from './AudioBinaryBuffer.js';
import ABDisposeController from '../controllers/ABDisposeController.js';

function LoaderAudio(url,decodeDirectly) {
	var self = this,
		completeCallback,
		errorCallback;

	url = url.replace(/\\/gi,'/');


	if (SETTINGS.REVERB_OPTIM) {
		if (/blackhole_small/.test(url)) url = "audio/impulse_mp3/blackhole_small.mp3";
		if (/Forest_Light/.test(url)) url = "audio/impulse_mp3/forest.mp3";
		if (/shimmer_smaller/.test(url)) url = "audio/impulse_mp3/shimmer_smaller.mp3";
	}
	

	this.data = {};
	this.url = url;
	this.loaded = false;
	this.loading = false;
	this.progress = 0.0;
	this.weight = 1;
	this.loadType = 'audio';
	this.xhr = null;
	this.multipleBatches = false;
	this.loadedBytes = 0;
	this.decodeDirectly = true; //decodeDirectly || false;

	this.data.loaded = false;

	this.doneCallbacks = [];
	window.allLoadedFiles.push(url);

	//callbacks
	function xhrDone() {
		if (!self.xhr) return;
		self.data.value = new AudioBinaryBuffer(self.xhr.data.value, url);
		self.data.value.isShared = self.isShared;
		self.xhr.dispose();
		self.xhr = null;
		self.progress = 1.0;
		if ((self.decodeDirectly || decodeDirectly)&& !SETTINGS.DEFER_DECODING) {
			self.data.value.decode().then(function() {
				//console.log("Audio Decoded", url, self.decodeDirectly || decodeDirectly);
				self.data.loaded = true;
				self.loaded = true;
				self.loading = false;
				completeCallback(self);
				for (var i=0; i<self.doneCallbacks.length; i++) {
					self.doneCallbacks[i](self);
				}
				self.doneCallbacks = [];
				// completeCallback(self);
			});
		} else {
			self.loaded = true;
			self.loading = false;
			self.data.loaded = true;
			completeCallback(self);
			for (var i=0; i<self.doneCallbacks.length; i++) {
				self.doneCallbacks[self];
			}
			self.doneCallbacks = [];
		}
	}
	function xhrError() {
		self.data.value = new AudioBinaryBuffer(null, url);
		self.xhr.dispose();
		self.xhr = null;
		self.progress = 1.0;
		self.loaded = true;
		self.loading = false;
		console.log('error',url);
		for (var i=0; i<self.doneCallbacks.length; i++) {
			self.doneCallbacks[self];
		}
		self.doneCallbacks = [];
		errorCallback(self);
	}

	this.start = function(_completeCallback, _errorCallback) {
		if (self.loading || self.loaded) console.error('Already loading: ',url);
		self.loading = true;
		completeCallback = _completeCallback;
		errorCallback = _errorCallback;
        
		self.xhr = new LoaderXHR(url, 'arraybuffer');
		self.xhr.start(xhrDone,xhrError);
	};

	this.getProgress = function() { 
		if (self.xhr) return self.xhr.getProgress();
		return self.progress;
	};

	this.dispose = function() {
		console.log("dispose audio");
		if (self.xhr) self.xhr.dispose();
		completeCallback = errorCallback = self.url = self.start = self.getProgress = self.dispose = self.data = null;
	};

	this.reset = function() {
		// console.log("reset audio", url);
		self.data.loaded = false;
		if (self.data && self.data.value && self.data.value.dispose) self.data.value.dispose();
		if (self.xhr) self.xhr.reset();
		self.data.value = self.xhr = null;
		self.loading = self.loaded = false;
		self.data.loadError = self.loadError = false;
		self.progress = 0.0;
	}

	this.getWeight = function() {
		return this.weight;
	};
}
export default LoaderAudio;