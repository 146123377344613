export default {
	//meta tags
	"meta": {
		"title": "Sérotonine Anonyme",
		"description": "Un film d’animation interactif où une jeune fille diffuse son activité cérébrale en ligne. Écrit et réalisé par Caroline Robert. En collaboration avec Édouard Lanctôt-Benoit, Mathieu Charbonneau et Vincent Morisset. Interprété par Julianne Côté • Une création du studio AATOAA • Une production de l'Office national du film du Canada.",
		"keywords": "ONF, NFB, AATOAA, Sérotonine Anonyme, interactif, cerveau, animation, mobile, film",
		"url": "https://serotonine.onf.ca",	
		"alturl": "https://brainstream.nfb.ca",
		"nfburl": "https://www.onf.ca",

		"share_meta_line1": "Sérotonine Anonyme : un film interactif de Caroline Robert",
		"share_meta_line2": "Aujourd’hui, vous allez masser le cerveau de D.",

		"twitter_meta_line1": "Sérotonine Anonyme : un film interactif de Caroline Robert",
		"twitter_meta_line2": "Aujourd’hui, vous allez masser le cerveau de D.",

		"share_image": "https://serotonine.onf.ca/images/serotonine_share_img.jpg",

		"share_twitter": "Sérotonine Anonyme : un film interactif de Caroline Robert. Aujourd’hui, vous allez masser le cerveau de D. #serotonineanonyme",
		"share_others": "Sérotonine Anonyme : un film interactif de Caroline Robert. Aujourd’hui, vous allez masser le cerveau de D."
	},


	//FR: Messages d'erreur
	"errors": {
		"loading": `
				<div id="error-title" class="fr"></div>
				<div class="error-sep">__________________</div><br>
				<br>Quelque chose ne<br>
				tourne pas rond.<br>
				Vérifiez votre connexion<br>
				ou essayez plus tard.<br>
				On vous attendra, promis!<br><br><br>
				<a class="error-link" href="https://onf.ca/interactif/serotonine" target="_BLANK">À propos</a><br><br>
			`,
		"webgl": `
				<div id="error-title" class="fr"></div>
				<div class="error-sep">__________________</div><br>
				Toutes nos excuses :<br>
				nous n’avons pas trouvé<br>
				le moyen de faire fonctionner<br>
				Sérotonine Anonyme sur cet appareil.<br><br>
				Le capitalisme est une plaie,<br>
				mais nous espérons que<br>
				vous essaierez sur un<br>
				appareil plus récent.<br><br><br>
				<a class="error-link" href="https://onf.ca/interactif/serotonine" target="_BLANK">À propos</a><br><br>
			`,
		"browser": `
				<div id="error-title" class="fr"></div>
				<div class="error-sep">__________________</div><br>
				Bonjour! Malheureusement, Sérotonine Anonyme<br>
				requiert un navigateur plus récent.<br><br>
				Veuillez mettre à jour votre logiciel et<br>
				recharger cette page pour que nous puissions<br>
				partir à l’aventure.<br><br><br>
				<a class="error-link" href="https://onf.ca/interactif/serotonine" target="_BLANK">À propos</a><br><br>

			`,
		"device": `
				<div id="error-title" class="fr"></div>
				<div class="error-sep">__________________</div><br>
				Toutes nos excuses :<br>
				nous n’avons pas trouvé<br>
				le moyen de faire fonctionner<br>
				Sérotonine Anonyme sur cet appareil.<br><br>
				Le capitalisme est une plaie,<br>
				mais nous espérons que<br>
				vous essaierez sur un<br>
				appareil plus récent.<br><br><br>
				<a class="error-link" href="https://onf.ca/interactif/serotonine" target="_BLANK">À propos</a><br><br>

			`,
		"temp_page":`
				<div id="error-title" class="fr"></div>
				<div class="error-sep">__________________</div><br>
				À partir du 19 novembre<br><br><br>
				<a class="error-link" href="https://onf.ca/interactif/serotonine" target="_BLANK">À propos</a><br><br>
				<a id="press-access-btn" class="error-link" href="#">Accès presse</a><br><br>
		`
	},

	//FR: views
	"views": {

		"loading": "Chargement",
		"buffering": "Chargement",

		"early_end_question": "Voulez-vous",
		"early_end_continue": "Continuer",
		"early_end_stop": "Arrêter",	

		"timeselect_question": "Choisir une durée",
		"timeselect_short": "5 min.",
		"timeselect_long": "19 min.",

		"endbtn_credits": "Crédits",
		"endbtn_share": "Partager",
		"endbtn_restart": "Recommencer"
	},


	//
	// FR: Les textes de page
	//
	"pages": {
		"about-title": "À propos",
		"share-title": "Partager Sérotonine Anonyme",
		"credits-title": "Crédits",
		"about": `
			<div class="about-credits">
				Écrit et réalisé par<br> <a target="_BLANK" href="https://caroline-robert.com/" class="about-name-link"><b>Caroline Robert</b></a><br>
				en collaboration avec<br> <b>Édouard Lanctôt-Benoit, Mathieu Charbonneau</b> et <b>Vincent Morisset</b><br>
				Interprété par<br> <b>Julianne Côté</b><br>
				Une création du studio <a target="_BLANK" href="http://aatoaa.com/" class="about-name-link"><b>AATOAA</b></a><br>
				Une production de <a target="_BLANK" href="https://nfb.ca/" class="about-name-link"><b>l'Office national du film du Canada</b></a><br><br>
				<a target="_BLANK" class="about-link" onclick="if (window.MenuController) {window.MenuController.openCredits();}">Tous les crédits</a><br>
				<br>
			</div>
			<div class="text-spacer-line"></div>
			<br>
			Vous êtes dans la tête de D., une jeune fille qui diffuse en direct son activité cérébrale lors d’une séance de traitement nouveau genre. En ligne avec d’autres participants, vous lui massez le cerveau pendant qu’elle laisse libre cours à ses pensées, ses émotions et ses obsessions. Là, elle vous raconte sa fin de semaine. Tantôt, son anxiété lui chante des beats accrocheurs qui lui restent dans un coin de la tête comme un ver d’oreille qui ne veut pas partir. Continuez à masser. Vous lui faites du bien. En échange, elle vous offre une visite guidée et commentée à l’intérieur de sa tête, encouragée par vos gestes et votre présence stimulante. D. s’abandonne sans filtre à ce cerveau qui ne cesse de tourner — peut-être un peu comme le vôtre. Sérotonine Anonyme est un film d’animation interactif qui explore avec sensibilité et humour les mystères de l’activité cérébrale et les chemins imprévisibles qu’empruntent nos pensées.<br>
			<div class="text-spacer-line"></div>
			<br>
			<a target="_BLANK" class="about-link" href="https://espacemedia.onf.ca/epk/serotonine/">Lien vers le kit de presse</a><br>
			<br>
			<div class="text-spacer-line"></div>
			<br>
			<b>
			Informations légales</b><br><br>
			Sérotonine Anonyme utilise des témoins (cookies) pour permettre de vivre l'expérience en plusieurs séances, sans perdre le fil de votre progression. Notez qu’aucune donnée personnelle n’est recueillie au cours de l’expérience. Si vous ne voulez pas qu’un témoin soit inscrit sur votre appareil par le site de l’ONF, vous pouvez désactiver la fonction de stockage des témoins de votre navigateur. Toutefois, votre progression dans Sérotonine Anonyme ne sera pas sauvegardée en fermant la fenêtre de Sérotonine Anonyme dans votre fureteur Web.
			<br><br>
		`,
		"credits": `
			Une création du studio <b>AATOAA</b><br>
			<br>
			Une production de <b>l'Office national du film du Canada</b><br>
			<br>
			<div class="credits-spacer-small"></div>
			<br>
			Réalisé et animé par<br>
			<b>Caroline Robert (AATOAA)</b><br>
<br>
			Code<br>
			<b>Édouard Lanctôt-Benoit (AATOAA)</b><br>
<br>
			Musique, conception sonore et supervision audio<br>
			<b>Mathieu Charbonneau</b><br>
<br>
			Direction de production et conseil à la réalisation<br>
			<b>Vincent Morisset (AATOAA)</b><br>
<br>
			Voix de D. et de la petite soeur<br>
			<b>Julianne Côté</b><br>
<br>
			Voix des obsessions<br>
			<b>Caroline Robert</b><br>
<br>
			Écrit par<br>
			<b>Caroline Robert</b><br>
<br>
			Auteurs-conseils pour le monologue<br>
			<b>Carl Bessette<br>
			Julianne Côté</b><br>
<br>
			Recherchiste<br>
			<b>Alix Gagnon</b><br>
<br>
			Direction de plateau (enregistrement de la narration française)<br>
			<b>Benoit Rousseau</b><br>
<br>
			Consultant au mixage<br>
			<b>Bernard Gariépy Strobl</b><br>
<br>
			Assistant monteur - monologue<br>
			<b>Pietro Amato</b><br>
<br>
			Monteur de la bande-annonce<br>
			<b>Jules Saulnier</b><br>
<br>
			<div class="credits-spacer-small"></div>
			<br>
			Office national du film du Canada<br>
<br>
			Productrices<br>
			<b>Marie-Pier Gauthier<br>
			Isabelle Repelin</b><br>
<br>
			Producteur exécutif<br>
			<b>Louis-Richard Tremblay</b><br>
<br>
			Chargée de production<br> 
			<b>Marie-Ève Babineau</b><br>
<br>
			Coordonnatrices principales de production<br>
			<b>Stéphanie Quevillon<br>
			Véronique Tessier</b><br>
<br>
			Administratrice<br>
			<b>Marie-Andrée Bonneau</b><br>
<br>
			Coordonnatrice principale de production - administration<br>
			<b>Isabelle Limoges</b><br>
<br>
			Coordonnatrices de production - administration<br>
			<b>Isabelle Gatti<br>
			Evelyne Cortes Oquendo</b><br>
<br>
			Directeur des technologies<br>
			<b>Martin Viau</b><br>
<br>
			Technologies de l'information<br>
			<b>Sergiu Suciu</b><br>
<br>
			Coordonnatrice, médias interactifs<br>
			<b>Caroline Fournier</b><br>
<br>
			Enregistrement de la voix<br>
			<b>Geoff Mitchell</b><br>
<br>
			Coordonnatrice technique (post-production)<br>
			<b>Mira Mailhot</b><br>
<br>
			Chargée de projets, produits numériques<br>
			<b>Catherine Perreault</b><br>
<br>
			Chargée d'édition<br>
			<b>Valérie Darveau</b><br>
<br>
			Services juridiques<br>
			<b>Christian Pitchen</b><br>
<br>
			Agente de mise en marché<br>
			<b>Laurianne Désormiers</b><br>
<br>
			Coordonnateur marketing, audiovisuel et numérique<br>
			<b>Angel Carpio</b><br>
<br>
			Coordonnateur à la mise en marché<br>
			<b>Éric Bondo</b><br>
<br>
			Stratèges, médias sociaux<br>
			<b>Emilie Nguyen Ngoc<br>
			Hannah Martin</b><br>
<br>
			Gestionnaires de communautés<br>
			<b>Alyssia Duval-Nguon<br>
			Mélissa Sauvé</b><br>
<br>
			<div class="credits-spacer-small"></div>
			<br>
			Relations de presse<br>
			<b>Mingotwo</b><br>
<br>
			Musique complémentaire<br>
			<b>Les gens qui m'aiment, Jimmy Hunt (2020)<br>
			avec l'aimable collaboration de Bravo musique</b><br>
<br>
			<div class="credits-spacer-small"></div>
			<br>
			Remerciements<br>
<br>
			Merci, pour leur oeil extérieur et leurs conseils précieux, à<br>
			<b>Laura Nadeau<br>
			J.-F. Nadeau<br>
			mon neveu Ravenne Portail<br>
			Simone Stämpfli</b><br>
<br>
			Merci, pour l'inspiration,<br>
			<b>aux jeunes filles qui ont accepté de répondre à mes questions<br>
			à tous et toutes les jeunes de mon entourage et à leurs parents</b><br>
<br>
			Merci du fond du coeur à<br>
			<b>Gabi<br>
			Vincent<br>
			Ed<br>
			mes soeurs, Fanny Morel et Sophie Robert<br>
			mes neveu et nièce Louis et Sybille Morel<br>
			mes parents, Chantal et Gérard Robert<br>
			mes grands-parents, Fernande et Aimé Robert</b><br>
<br>
			À la mémoire<br>
			<b>de ma grand-mère Maïck (Yvonne Zulemanian), qui aimait la chanson française<br>
			et de mon oncle Patou (Patrick Thomas), qui aimait les Rolling Stones</b><br>
<br>
			Merci à mon club de dessin, La Mine<br>
			<b>Joël Vaudreuil, Isabelle Brouillette, Stéphane Lafleur, François Turcotte, Caroline Lavergne, Maxime Veilleux, Catherine Leduc, Matthieu Beaumont</b><br>
<br>
			Et merci à toutes mes amies et à tous mes amis, petits et grands, talentueux et passionnés, ainsi qu'à mon entourage généreux qui ont contribué consciemment ou non à faire en sorte que ce film existe<br>       
			<b>Laurence Simard-Émond, Jules, Guillaume, Renaud, Paul et Antoine Avril<br>
			Stéphanie Béliveau<br>
			Henry Bernadet<br>
			Stéphane Buellet<br>
			Gabriel Luciani et Pascal Brouard<br>
			Anne, Alice et Marc-André Chaput<br>
			Yann Dechatrette<br>
			Carole De Haro<br>
			Sophie Bisson, Olive et Thibaut Duverneix<br>
			Miryam Bouchard, Alice et P.M. Fortin<br>
			Daniel Sanche et Sarah Fortin<br>
			Chloé B. Fortin<br>
			Ludivine Jacquemier<br>
			Annie Jaimes<br>
			Philippe Lambert<br>
			Christina Maurey<br>
			Thea Metcalfe, Miro et Sean Michaels<br>
			Aymeric Morel<br>
			Claudette Raymond, Marie-Ève, Olivier, Jérémie, Antoine et Paul Morisset<br>
			Daniel et Martine Moussy<br>
			Julien Paget<br>
			Nathalie Loignon, Jeanne, Henri, Laure et Mathieu Pellerin<br>
			Raphaëlle Nadeau et Madeleine Péloquin<br>
			Jean-Michel Portail<br>
			Isabelle de Bie, Laurent, Pierre et Yves Renaud<br>
			Gustave, Laurier, Catherine D'Amour et Nicolas S. Roy<br>
			Annie Saint-Pierre<br>
			Mélanie Chabot, Victor et Nicolas Stämpfli<br>
			Monique Simard<br>
			Sophie Cadieux, Oscar et Mani Soleymanlou<br>
			Hugues Sweeney<br>
			Iris, Jessica et Brice Thomas<br>
			Josiane La Pouette Thomas<br>
			Sophie Galipeau, Eli, Sam et Dominic Turmel</b><br>
			<br>
		`,
		"share": `
			<div class="share-large">
				<div class="share-btn" id="share-facebook">
					<div id="facebook-logo"></div>
					<div class="share-text">Facebook</div>
				</div>
			</div>
			<div class="share-large">
				<div class="share-btn" id="share-twitter">
					<div id="twitter-logo"></div>
					<div class="share-text">Twitter</div>
				</div>
			</div>
			<div class="share-large" id="share-link-container">
				<div class="share-btn" id="share-btn-link">
					<div id="sharelink-logo" id="share-link"></div>
					<div class="share-text">Copier le lien</div>
				</div>
			</div>
		`,
	},

	"analytics": `
		<!-- Facebook Pixel Code -->
		<script>
		  !function(f,b,e,v,n,t,s)
		  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
		  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
		  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
		  n.queue=[];t=b.createElement(e);t.async=!0;
		  t.src=v;s=b.getElementsByTagName(e)[0];
		  s.parentNode.insertBefore(t,s)}(window, document,'script',
		  'https://connect.facebook.net/en_US/fbevents.js');
		  fbq('init', '481568595855542');
		  fbq('track', 'PageView');
		</script>
		<noscript>
		  <img height="1" width="1" style="display:none" 
		       src="https://www.facebook.com/tr?id=481568595855542&ev=PageView&noscript=1"/>
		</noscript>
		<!-- End Facebook Pixel Code -->
		`,

	"fonts": {
		"online": `<link rel='preconnect' href='https://fonts.gstatic.com' crossorigin>
		<link rel='preload' as='style' href='https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=block'>
		<link rel='stylesheet' href='https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=block'>
		`,
		"local": `<link rel='preload' as='style' href='css/worksans.css'>
		<link rel='stylesheet' href='css/worksans.css'>
		`
	}
}