import * as THREE from "three";


// var FboRef = [];
// window.FboRef = FboRef;
/**
 *
 * @author Édouard Lanctôt < edouardlb@gmail.com >
 *
 */
function Fbo(w,h,options) {

    this.width = w;
    this.height = h;


    // this.renderer = options.renderer || window.renderer;
    this.forceClear = options.forceClear || true;
    this.disposed = false;

    this.texture = options.texture || new THREE.WebGLRenderTarget(w,h);
    this.texture.isActive = false;



    this.texture.texture.antialias = !!options.antialias;
    this.texture.texture.minFilter = options.minFilter !== undefined ? options.minFilter : THREE.LinearFilter;
    this.texture.texture.magFilter = options.magFilter !== undefined ? options.magFilter : THREE.LinearFilter;
    this.texture.texture.format = options.format !== undefined ? options.format : THREE.RGBAFormat;
    this.texture.texture.type = options.type !== undefined ? options.type : THREE.UnsignedByteType;
    this.texture.texture.generateMipmaps = options.generateMipmaps !== undefined ? options.generateMipmaps : false;
    this.texture.texture.wrapS = this.texture.texture.wrapT = options.wrap !== undefined ? options.wrap : THREE.ClampToEdgeWrapping;
    this.texture.texture.premultiplyAlpha = options.premultiplyAlpha !== undefined ? options.premultiplyAlpha : false;
    this.texture.texture.flipY = false;
    this.options = options;


    // FboRef.push(this);
    // this.defaultMaterial = new THREE.MeshBasicMaterial({color: 0xffffff, transparent: false, blending: THREE.NoBlending, alphaTest: false, depthTest: false, map:this.texture.texture});


    this.texture.depthBuffer = options.depthBuffer !== undefined ? options.depthBuffer : false;
    this.texture.stencilBuffer = options.stencilBuffer !== undefined ? options.stencilBuffer : false;
        
    //pingpong
    if (options.pingPongEnabled || options.pingpong) {
        this.pingPongEnabled = true;
        this.textureIsPing = true;
        this.texturePong = options.texturePong || new THREE.WebGLRenderTarget(w,h);
        this.texturePong.isActive = false;
        this.texturePong.texture.antialias = !!options.antialias;
        this.texturePong.texture.minFilter = options.minFilter !== undefined ? options.minFilter : THREE.LinearFilter;
        this.texturePong.texture.magFilter = options.magFilter !== undefined ? options.magFilter : THREE.LinearFilter;
        this.texturePong.texture.format = options.format !== undefined ? options.format : THREE.RGBAFormat;
        this.texturePong.texture.type = options.type !== undefined ? options.type : THREE.UnsignedByteType;
        this.texturePong.texture.generateMipmaps = options.generateMipmaps !== undefined ? options.generateMipmaps : false;
        this.texturePong.texture.wrapS = this.texturePong.texture.wrapT = options.wrap !== undefined ? options.wrap : THREE.ClampToEdgeWrapping;
        this.texturePong.texture.premultiplyAlpha = options.premultiplyAlpha !== undefined ? options.premultiplyAlpha : false;
        this.texturePong.texture.flipY = false;

        this.texturePong.depthBuffer = options.depthBuffer !== undefined ? options.depthBuffer : false;
        this.texturePong.stencilBuffer = options.stencilBuffer !== undefined ? options.stencilBuffer : false;
        


    } else {
        this.pingPongEnabled = false;
    }


    this.getFbo = function() {
        return this.texture;
    }

    //Ping Pong
    if (this.pingPongEnabled) {
        this.pingPong = function() {
            this.textureIsPing = !this.textureIsPing;
            var p = this.texture;
            this.texture = this.texturePong;
            this.texturePong = p;
        };
        this.getPing = function() {
            return this.texture;
        };
        this.getPong = function() {
            return this.texturePong;
        };
        this.setPing = function() {
            if (this.textureIsPing) return;
            this.textureIsPing = true;
            var p = this.texture;
            this.texture = this.texturePong;
            this.texturePong = p;
        };
        this.setPong = function() {
            if (!this.textureIsPing) return;
            this.textureIsPing = false;
            var p = this.texture;
            this.texture = this.texturePong;
            this.texturePong = p;
        };
    }
    if (options.leftRightEnabled) {
        this.getLeft = this.getPing;
        this.getRight = this.getPong;
        this.getLeftRight = function(id) {
            return id==0 ? this.texture : this.texturePong;
        }
    }


    this.resize =  function(nw,nh) {

        if (this.width == nw && this.height == nh) return;

        for (var i=0; i<FboRef.length; i++) {
            if (FboRef[i]==this){
                FboRef.splice(i,1);
            }
        }
        FboRef.push(this);


        this.width = nw;
        this.height = nh;
        // this.texture.width = nw;
        // this.texture.height = nh;
        this.texture.setSize(nw, nh);

        if (this.pingPongEnabled) {
            this.texturePong.width = nw;
            this.texturePong.height = nh;
            this.texturePong.setSize(nw, nh);
        }
    };



    this.resize2 =  function(nw,nh) {

        if (this.width == nw && this.height == nh) return;

        var options = this.options;
        this.width = nw;
        this.height = nh;
        this.texture.dispose();
        this.texture = new THREE.WebGLRenderTarget(nw,nh);
        this.texture.isActive = false;
        this.texture.texture.antialias = !!options.antialias;
        this.texture.texture.minFilter = options.minFilter !== undefined ? options.minFilter : THREE.LinearFilter;
        this.texture.texture.magFilter = options.magFilter !== undefined ? options.magFilter : THREE.LinearFilter;
        this.texture.texture.format = options.format !== undefined ? options.format : THREE.RGBAFormat;
        this.texture.texture.type = options.type !== undefined ? options.type : THREE.UnsignedByteType;
        this.texture.texture.generateMipmaps = options.generateMipmaps !== undefined ? options.generateMipmaps : false;
        this.texture.texture.wrapS = this.texture.texture.wrapT = options.wrap !== undefined ? options.wrap : THREE.ClampToEdgeWrapping;
        this.texture.texture.premultiplyAlpha = options.premultiplyAlpha !== undefined ? options.premultiplyAlpha : false;
        this.texture.texture.flipY = false;



        // if (this.pingPongEnabled) {
        //     this.texturePong.width = nw;
        //     this.texturePong.height = nh;
        //     this.texturePong.dispose(nw, nh);
        // }
    };




    this.resizeTexture =  function(nw,nh) {

        if (this.width == nw && this.height == nh) return;

        this.width = nw;
        this.height = nh;

        // this.texture.width = nw;
        // this.texture.height = nh;

        //Clone texture with new width to force a full resize
        if (!this.texture.isActive) {
            this.texture.setSize(nw,nh);
        } else {
            var t = this.texture;
            this.texture = t.clone();
            this.texture.setSize(nw, nh);
            t.dispose();
            this.texture.isActive = false;
        }
       
        if (this.pingPongEnabled) {
            if (!this.texturePong.isActive) {
                 this.texturePong.setSize(nw, nh);
            } else {
                var tp = this.texturePong;
                this.texturePong = tp.clone();
                this.texturePong.setSize(nw, nh);
                tp.dispose();
                this.texturePong.isActive = false;
            }
        }
    };


    this.resizeTextureAndCopy =  function(nw,nh) {

        this.width = nw;
        this.height = nh;
        
        //Clone texture with new width to force a full resize
        var t = this.texture;
        this.texture = t.clone();
        this.texture.setSize(nw, nh);
        if (!this.disposed) {
            window.renderer.setRenderTarget(this.texture);
            window.renderer.setClearColor(Utils.blackColor, 0);
            window.renderer.clear(true,true,true);
            Utils.renderTexture(t.texture, this.texture, false);
            window.renderer.setRenderTarget();
        }
        this.texture.isActive = false;
        t.dispose();

        if (this.pingPongEnabled) {
            t = this.texturePong;
            this.texturePong = t.clone();
            this.texturePong.setSize(nw, nh);
            window.renderer.setRenderTarget(this.texturePong);
            window.renderer.setClearColor(Utils.blackColor, 0);
            window.renderer.clear(true,true,true);
            Utils.renderTexture(t.texture,  this.texturePong, false);
            window.renderer.setRenderTarget();
            t.dispose();
            this.texturePong.isActive = false;
        }
    };

    this.dispose =  function() {

        if (this.disposed) return;
        var t = this.texture;
        this.texture = t.clone(); //for future re-alloc
        t.dispose();
        this.disposed = true;

        if (this.pingPongEnabled) {
            t = this.texturePong;
            this.texturePong = t.clone();  //for future re-alloc
            t.dispose();
            this.disposed = true;
        } 

        for (var i=0; i<FboRef.length; i++) {
            if (FboRef[i]==this){
                FboRef.splice(i,1);
            }
        }
    };

    this.cleanUp = function() {
        this.texture =
        this.texturePong = 
        this.scene =
        this.defaultPlane = 
        this.camera = 
        this.renderPlane =
        this.tempColor =
        this.renderMaterial = 
        options = null;
    }
}

export default Fbo;

