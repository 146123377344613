import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';


//----------------
//
// SVG Frame
// Load all splines from an svg in an easily readable/morphable format
//
//----------------
class DrawFrame {

	constructor() {
		this.loaded = false;
		this.uuid = "frame"+Utils.generateID();
		this.isDrawFrame = true;
		this.points = [];
	}

	getPositions() {
		return this.points;
	}


	getFrame() {
		return this;
	}

	getEndFrame(){
		return this;
	}


}
export default DrawFrame;