import $ from 'jquery';
import FastEvent from './FastEvent.js';

/*
<div class="ui-row">
	<div class="ui-item">
		<div class="ui-folder">
			<div class="ui-folder-arrow">▶</div>
			<div class="ui-label-folder"></div>
		</div>
    </div>
</div>
*/
function Folder(UI) {
	if (!UI.enabled) return;

	//
	// Create the new ROW
	//
	this.rowDiv = document.createElement('div');
	this.rowDiv.classList.add('ui-row');
	UI.allFolders.push(this);
	//$(this.rowDiv).toggleClass('closed', true);

	//
	// Create the div hierarchy
	//
	var containerDiv = document.createElement('div'),
		folderDiv = document.createElement('div'),
		arrowDiv = document.createElement('div'),
		labelDiv = document.createElement('div'),
		allItems = [];
	this.domElement = this.rowDiv;
	this.uiItem = containerDiv;

	containerDiv.appendChild(folderDiv);
	folderDiv.appendChild(arrowDiv);
	folderDiv.appendChild(labelDiv);

	//
	// Set the classes and basic properties
	//
	$(containerDiv).addClass('ui-item');
	$(folderDiv).addClass('ui-folder');
	$(labelDiv).addClass('ui-label-folder');
	$(arrowDiv).addClass('ui-folder-arrow');
	$(arrowDiv).text('▼');

	//
	// props
	//
	this.uuid = Math.floor(Math.random() * 100000000000).toFixed(0);
	this.value = false;
	this.propName = '';
	this.toWatch = {};
	this.props = null;
	this.changing = false;
	this.path = [];
	this.isOpen = false;
	this.subfolders = {};
	this.canUpdateUI = false;
	this.isHidden = false;


	//
	//  open / close
	//
	FastEvent.on(this,'open', function() {

		this.isOpen = true;
		this.props.isOpen = true;
		$(folderDiv).toggleClass('open', true);
		$(arrowDiv).css('transform', 'rotate(0deg)');

		if (this.isHidden) return;
		$(this.rowDiv).toggleClass('closed', false);
		$(this.rowDiv).toggleClass('open', true);
		// if (this.canUpdateUI) this.enableUIUpdates();

	}.bind(this));


	this.close = function() {
		this.isOpen = false;
		this.props.isOpen = false;
		$(folderDiv).toggleClass('open', false);
		$(arrowDiv).css('transform', 'rotate(-90deg)');

		if (this.isHidden) return;
		$(this.rowDiv).toggleClass('open', false);
		$(this.rowDiv).toggleClass('closed', true);
		// this.disableUIUpdates();
		
	}.bind(this)

	FastEvent.on(this,'close', this.close);

	this.toggleClose = function(e) {
		this.isOpen = !this.isOpen;
		this.props.isOpen = this.isOpen;
		FastEvent.trigger(this, this.isOpen ? 'open' : 'close');
	}.bind(this);



	//
	// init the properties
	//
	this.init = function(toWatch, propName, props, path, startingSize) {
		

		this.propName = propName;
		this.props = props;
		this.toWatch = toWatch;

		this.isHidden = this.props.isHidden;
		if (this.isHidden) props.isOpen = false;
		if (this.isHidden) $(this.uiItem).hide();
		FastEvent.on(this.props, 'showFolder', this.show);
		FastEvent.on(this.props, 'hideFolder', this.hide);
		FastEvent.on(this.props, 'openFolder', function() {
			FastEvent.trigger(this,'open');
		}.bind(this));
		FastEvent.on(this.props, 'closeFolder', function() {
			FastEvent.trigger(this,'close');
		}.bind(this));

		// this.updateValue([{name:propName}]);
	
		$(labelDiv).text(propName);
		$(labelDiv).css('border-color', props.colorCode || '#ffffff');
		this.path = path;

		$(this.rowDiv).css('width', (UI.width-(this.path.length-startingSize)*4)+'px');

		this.isOpen = (props.isOpen === true) || this.path.length == 0;
		FastEvent.trigger(this, this.isOpen ? 'open' : 'close');
		if (this.isHidden) this.hide();

	}.bind(this);


	this.changeName = function(newName) {
		this.propName = newName;
		$(labelDiv).text(this.propName);
	}


	//
	// Open Folder
	//
	$(containerDiv).on('click touchend', function(e) {
		this.isOpen = !this.isOpen;
		FastEvent.trigger(this, this.isOpen ? 'open' : 'close');
	}.bind(this));


	//--------------------
	//
	// Drag into a new window
	//
	//--------------------
	var isDragging = false,
		draggingStartX = 0,
		draggingStartY = 0,
		creatingNewWindow = false,
		creatingNewWindowSecured = false,
		nw = null;

	//
	// Update positon
	//
	this.dragUpdate = function(e) {
		
		var dx = e.pageX !== undefined ? e.pageX : e.originalEvent.touches[0].pageX,
			dy = e.pageY !== undefined ? e.pageY : e.originalEvent.touches[0].pageY,
			dst = Math.abs(draggingStartX-dx) + Math.abs(draggingStartY - dy);


		if (UI.isMaster) {
			// if (!creatingNewWindow) {
			// 	if (dst >= 5) {
					
			// 		$(document.body).css('cursor','move');
			// 		$(containerDiv).css('cursor','move');

			// 		creatingNewWindow = true;
			// 		nw = new UI.Window();
			// 		nw.initFromFolder(UI.currentSoundEngine, this.toWatch, this.propName, this.props, this.path);
			// 		console.log(this.toWatch, this.propName, this.props, this.path);
			// 		$('body').append(nw.domElement);
			// 		$(nw.domElement).addClass('opening');

			// 	}
			// }

			// if (creatingNewWindow && !creatingNewWindowSecured && dst >= 60) {
			// 	creatingNewWindowSecured = true;
			// 	$(nw.domElement).removeClass('opening');
			// 	$(nw.domElement).addClass('openingAnim');
			// }

			if (nw) {
				if (e.shiftKey)
					nw.setPositionSnap(dx-128, dy-15);
				else
					nw.setPosition(dx-128, dy-15);
			}
		}

		e.preventDefault();
		e.stopPropagation();

	}.bind(this);

	//
	// stop drag
	//
	this.dragOff = function(e) {
		isDragging = false;
		$(window).off('mousemove.'+this.uuid+' touchmove.'+this.uuid);
		$(window).off('touchend.'+this.uuid+' mouseup.'+this.uuid);
		$(document.body).css('cursor','auto');
		$(containerDiv).css('cursor','pointer');
		e.preventDefault();
		e.stopPropagation();

		//
		// Create NEW WINDOW
		//
		if (creatingNewWindowSecured) {
			nw = null;
		} else if (nw) {
			nw.dispose(true);
		}


		creatingNewWindowSecured = false;
		creatingNewWindow = false;
		

	}.bind(this);

	$(containerDiv).on('mousedown touchstart', function(e) {
		isDragging = true;
		creatingNewWindow = false;
		creatingNewWindowSecured = false;
		$(window).on('mousemove.'+this.uuid+' touchmove.'+this.uuid, this.dragUpdate);
		$(window).on('touchend.'+this.uuid+' mouseup.'+this.uuid, this.dragOff);
		draggingStartX = e.pageX !== undefined ? e.pageX : e.originalEvent.touches[0].pageX;
		draggingStartY = e.pageY !== undefined ? e.pageY : e.originalEvent.touches[0].pageY;
		e.preventDefault();
		e.stopPropagation();
	}.bind(this));

	$(containerDiv).on('mouseup touchend', function(e) {
		isDragging = false;
	}.bind(this));


	//
	// Update ranges
	//
	this.updateRanges = function() {
		for (var i=0; i<allItems.length; i++) {
			if (allItems[i].updateRanges) allItems[i].updateRanges();
		}
	};


	//
	// Allow / Disable UI updates
	//
	this.enableUIUpdates = function() {
		for (var i=0; i<allItems.length; i++) {
			allItems[i].canUpdateUI = true;
			if (allItems[i].enableUIUpdates) allItems[i].enableUIUpdates();
			if (allItems[i].updateUI) allItems[i].updateUI();
		}
		for (var o in this.subfolders) {
			if (this.subfolders[o]) this.subfolders[o].enableUIUpdates();
		}
		this.canUpdateUI = true;
	};
	this.disableUIUpdates = function() {
		for (var i=0; i<allItems.length; i++) {
			allItems[i].canUpdateUI = false;
			if (allItems[i].disableUIUpdates) allItems[i].disableUIUpdates();
		}
		for (var o in this.subfolders) {
			if (this.subfolders[o]) this.subfolders[o].disableUIUpdates();
		}
		this.canUpdateUI = false;
	};


	//
	// hide/show
	//
	this.show = function() {
		if (!this.isHidden) return;
		this.isHidden = this.props.isHidden = false;
		$(this.uiItem).show();

		if (this.isOpen) {
			$(this.rowDiv).toggleClass('closed', false);
			$(this.rowDiv).toggleClass('open', true);
		}
		if (this.canUpdateUI) this.enableUIUpdates();
	}.bind(this);
	
	this.hide = function() {
		// if (this.isHidden) return;

		// if (this.isOpen) {
			// FastEvent.trigger(this,'close');
		// }
		// this.close();
		this.isHidden = this.props.isHidden = true;
		var ov = this.canUpdateUI;
		this.disableUIUpdates();
		this.canUpdateUI = ov;

		$(this.rowDiv).toggleClass('open', false);
		$(this.rowDiv).toggleClass('closed', true);
		$(this.uiItem).hide();

	}.bind(this);

	//
	// Dispose all sub-element
	//
	this.addItem = function(item) {
		this.rowDiv.appendChild(item.domElement);
		allItems.push(item);
	};
	this.getItems = function(item) {
		return allItems;
	};
	this.dispose = function() {
		if (!this.rowDiv) return;

		for (var i = 0; i < allItems.length; i++) {
			if (allItems[i].domElement) this.rowDiv.removeChild(allItems[i].domElement);
			allItems[i].dispose();
		}
		allItems = [];

		FastEvent.off(this.props, 'showFolder', this.show);
		FastEvent.off(this.props, 'hideFolder', this.hide);
		FastEvent.off(this.props, 'openFolder');
		FastEvent.off(this.props, 'closeFolder');
		FastEvent.off(this.props, 'open');
		FastEvent.off(this.props, 'close');

		this.subfolders = {};

		$(this.rowDiv).off();
		$(containerDiv).off();
		$(folderDiv).off();
		$(arrowDiv).off();
		$(labelDiv).off();

		$(this.rowDiv).remove();
		$(this.domElement).remove();
		$(this.uiItem).remove();

		$(containerDiv).remove();
		$(folderDiv).remove();
		$(arrowDiv).remove();
		$(labelDiv).remove();

		this.rowDiv = null;
		this.domElement = null;
		this.uiItem = null;
		containerDiv = null;
		folderDiv = null;
		arrowDiv = null;
		labelDiv = null;

		for (var i=0; i<UI.allFolders; i++) {
			if (UI.allFolders[i] === this) UI.allFolders[i] = null;
		}
	};


};

export default Folder;
