//
// Keeps current BufferingPause status
// Displays the buffering error message
// Orders VideoController et AudioController to pause
//
import AppStatus from "./AppStatus.js";
import AudioController from './AudioController.js';

function BufferingController() {

	this.isPaused = false;
	this.pauseCause = 'scene';

	//
	// The Webgl Scene
	// 
	var scene,
		plane,
		backPlane,
		material,
		texture,
		lastColorUpdate = 0;

	this.preload = function(batchName) {};


	this.pause = function(cause) {
		if (this.isPaused) return;
		this.pauseCause = cause;
		this.isPaused = true;

		//~~VideoController.pause();
		AudioController.stop();
		// Controls.releaseAll();

		$('#buffering-container').show();
	};

	this.isPausedBecause = function(cause) {
		return this.isPaused && cause === this.pauseCause;
	};

	this.unpause = function(cause) {
		if (!this.isPaused) return;
		this.isPaused = false;
		//~~if (cause !== 'video') VideoController.play();
		AudioController.play();
		$('#buffering-container').hide();
	};

	this.unpausePreloader = function() {
		if (!this.isPaused) return;
		this.isPaused = false;
		$('#buffering-container').hide();
	};

	this.update = function() {
		if (!this.isPaused) return;

	};

	this.render = function() {
		//renderer.render(scene, RendererController.camera);
	};
};
window.BufferingController = window.BufferingController||new BufferingController();
export default window.BufferingController;





/**

WEBGL VERSION


//
// Keeps current BufferingPause status
// Displays the buffering error message
// Orders VideoController et AudioController to pause
//
(function(document,window) {

	function BufferingController() {

		this.isPaused = false;
		this.pauseCause = 'scene';


		//
		// The Webgl Scene
		// 
		var scene,
			plane,
			backPlane,
			material,
			texture,
			lastColorUpdate = 0;

		this.preload = function(batchName) {
			Loader.addShader(batchName, 'shaders/vertex.vert');
			Loader.addShader(batchName, 'shaders/bwalphaTint.frag');
			textureOptions = {
				format: THREE.LuminanceFormat,
				minFilter: THREE.LinearFilter,
				magFilter: THREE.LinearFilter,
				generateMipmaps: false,
				upload: false
			};
			texture = Loader.addTexture(batchName, 'images/assets/buffering.png', textureOptions);
		};


		this.pause = function(cause) {
			if (this.isPaused) return;
			this.pauseCause = cause;
			this.isPaused = true;

			VideoController.pause();
			AudioController.stop();
			Controls.releaseAll();


			if (!scene) {
				scene = new THREE.Scene();

				backPlane = new THREE.Mesh(
					Utils.planeGeometry,
					new THREE.MeshBasicMaterial({
						color: new THREE.Color(0),
						transparent: true,
						opacity: 0.5
					})
				);
				scene.add(backPlane);

				texture.needsUpdate = true;
				material = new THREE.ShaderMaterial({
					vertexShader: Loader.getShader('shaders/vertex.vert').value,
					fragmentShader: Loader.getShader('shaders/bwalphaTint.frag').value,
					defines: {},
					uniforms: {
						'tDiffuse': {'type':'t', value: texture},
						'tint': {'type':'c', value: new THREE.Color(0xff00ff)},
						'alpha': {'type':'f', value: 1.0}
					},
					blending: THREE.NormalBlending,
					transparent: true,
					depthTest: false
				});
				plane = new THREE.Mesh(Utils.planeGeometry,material);
				plane.scale.set(147, 40, 1);
				scene.add(plane);
			}
		};

		this.isPausedBecause = function(cause) {
			return this.isPaused && cause === this.pauseCause;
		};

		this.unpause = function(cause) {
			if (!this.isPaused) return;
			this.isPaused = false;
			if (cause !== 'video') VideoController.play();
			AudioController.play();
		};


		this.update = function() {
			if (!this.isPaused) return;

			backPlane.scale.set(AppStatus.innerWidth(), AppStatus.innerHeight(), 1);
			if (performance.now() - lastColorUpdate >= 40) {
				lastColorUpdate = performance.now();
				material.uniforms.tint.value.set(Utils.randoma(SETTINGS.palette));
			}
		};

		this.render = function() {
			renderer.render(scene, RendererController.camera);
		};
	};
	window.BufferingController = new BufferingController();

})(document,window);


*/