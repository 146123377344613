

export default {
	options: {
		timeline: {
			cue: "none",
			file:"v1_proto_caro_lowpitch",
			timeStart: "00:00",
			timeEnd: "00:15",
		},
		layers: {
			back: {values:[]},
			front: {values:[]},
			eyes: {values:[]},
			over: {values:[]},
			scale: 1.0,
			timelineEyes: false,
			shuffleFrames: false,
			mobileTextOffset: 0.0
		},
		morph: {
			automatic: 1.0,
			interactive: 0.0,
			click: 1.0,
			easing: 'Linear',
			sequenceFps: 12.0,
			dontMorphOver: false,
			randomness: {
				assignRandom: false,
				tweenRotation: 0,
				tweenNoise: false,
				tweenNoiseSmooth: false
			}
		},
		transition: {
			default: true,
			duration: 1.0,
			fastOignonSkin: false
		},
		stroke: {
			lineWidth: 1.0,
			color: '#000000',
			useSvgColors: false,
			pulse: {
				enabled: false,
				depth: 1.0,
				speed: 1.0,
			},
			interactive: {
				enabled: false,
				depth: 1.0,
				distance: 1.0,
				pulse: 0.0
			},
		},
		
		deform: {
			waveform: 0.0,
			noise: 0.0,
			smoothNoise: 0.0,
			smoothNoiseScale: 1.0,
			smoothNoiseSpeed: 1.0,
			backLayer: 1.0,
			frontLayer: 1.0,
			eyesLayer: 1.0,
			overLayer: 1.0
		},
		effects: {
			oignonSkin: {
				enabled: false,
				frames: 12,
				fps: 30,
				skip: 2,
				color: 0.0,
				palette: "palette_a",
			},
			oignonSkinVector: {
				enabled: false,
				frames: 12,
				fps: 30,
				skip: 2,
				color: 0.0,
				palette: "palette_a",
				randomBrush: 0.0
			},
			emboss: {
				enabled: false,
				dst: 2.0,
				alpha: 1.0
			},
			digitalPrototype: {
				enabled: false,
				alpha: 1.0,
				radial: 0.0,
				colorA: 0.0,
				colorB: 0.0,
				gridA: 0.0,
				noiseB: 0.0,
				gridC: 0.0,
				radialD: 0.0,
				radialE: 0.0,
				gridF: 0.0,
				accumG: 0.0,
				accumNoiseH: 0.0
			}
		},
		magnetism: {
			algorithm: 'default',
			alpha: 1.0,
			attract: 0.0,
			inverse: 0.0,
			elastic: 0.0,
			curve: 1.0,
			size: 1.0,
			strength: 0.5,
			extrude: 0.0,
			randomCursor: 1.0,
			randomShake: 1.0,
			backLayer: 1.0,
			frontLayer: 1.0,
			eyesLayer: 1.0,
			overLayer: 1.0
		},

		brush: {
			enabled: false,
			useColors: false,
			shader: "linebrush_global",
			texture: 'microscope_0.jpg',
			sourceSize: 0.05,
			deplacement: 0.0,
			sourceX: 0.5,
			sourceY: 0.5
		},
		draw: {
			enabled: false,
			clear: function(){},
			mode: "small",
			lineLength: 2.0,
			fps: 30,
			duplicateCursor: false,
			kaleidoscope: 0
		},
		background: {
			enabled: false,
			alpha: 1.0,
			fillColor: "#ffffff",
			clickFlash: false,
			roundedBox: false,
			pulseGradient: {
				alpha: 0.0,
				pulseSpeed: 1.0,
				changeSpeed: 1.0,
				gradientMin: 0.75,
				gradientMax: 1.25,
				noiseA: 1.0,
				noiseB: 1.0,
				colorA: "#CC4C4C",
				colorB: "#4C4CCC",
				colorC: "#ff00ff"
			}
		},

		copresence: {
			enabled: false
		},

		installation: {
			defaultColors: true,
			connected: false,
			color: "#ffffff",
			paletteMode: false,
			colorBEnabled: false,
			colorB: "#ffffff",
			// paletteModeSlow: false
		}
	},


	ranges: {
		isFolder: true,
		isOpen: true,
		timeline: {
			isFolder: true,
			isOpen: false,
			cue: {isDropdown: true, values:[], autofillBaseValues:["none"]},
			file: {isDropdown: true, values:["v5_Julianne_1"]},
			timeStart: {isText: true}, //, min: 0.0, max: AppStatus.AUDIO_DURATION, callback: null},
			timeEnd: {isText: true}, //{isSlider: true, min: 0.0, max: AppStatus.AUDIO_DURATION},
		},	
		layers: {
			isFolder: true,
			isOpen: false,
			back: {isDropdownList: true, values:[], autofillBaseValues:["none", "draw"]},
			front: {isDropdownList: true, values:[], autofillBaseValues:["none", "draw"]},
			eyes: {isDropdownList: true, values:[], autofillBaseValues:["none", "draw"]},
			over: {isDropdownList: true, values:[], autofillBaseValues:["none", "draw"]},
			timelineEyes: {isBoolean: true},
			shuffleFrames: {isBoolean: true},
			scale: {isSlider: true, min: 0.5, max: 2.0},
			mobileTextOffset: {isSlider: true, min: 0.0, max: 0.15}
		},

		morph: {
			isFolder: true,
			isOpen: false,
			automatic: {isSlider: true, min: 0, max: 1.0},
			interactive: {isSlider: true, min: 0, max: 1.0},
			click: {isSlider: true, min: 0, max: 1.0},
			easing: {isDropdown:true, values:['Linear', 'Sinusoidal', 'Quadratic', 'Exponential','Circular', 'Elastic','Bounce', 'easeIn', 'easeOut']},
			sequenceFps: {isSlider: true, min: 1, max: 30.0},
			dontMorphOver: {isBoolean: true},
			randomness: {
				isFolder: true,
				isOpen: false,
				assignRandom: {isBoolean: true},
				tweenRotation: {isSlider: true, min: 0, max: 16, isInt: true},
				tweenNoise: {isBoolean: true},
				tweenNoiseSmooth: {isBoolean: true}
			}
		},
		transition: {
			isFolder: true,
			isOpen: false,
			default: {isBoolean: true},
			duration: {isSlider: true, min: 0, max: 15.0},
			fastOignonSkin: {isBoolean: true}
		},
		stroke: {
			isFolder: true,
			isOpen: false,
			lineWidth: {isSlider: true, min: 0.0, max: 10.0},
			color: {isColor: true},
			useSvgColors: {isBoolean: true},

			pulse: {
				isFolder: true,
				isOpen: false,
				enabled: {isBoolean: true},
				depth: {isSlider: true, min: 0, max: 2.0},
				speed: {isSlider: true, min: 0, max: 4.0},
			},
			interactive: {
				isFolder: true,
				isOpen: false,
				enabled: {isBoolean: true},
				depth: {isSlider: true, min: 0.0, max: 5.0},
				distance: {isSlider: true, min: 0.0, max: 3.0},
				pulse: {isSlider: true, min: 0.0, max: 1.0}
			},
		},
		deform: {
			isFolder: true,
			isOpen: false,
			waveform: {isSlider: true, min: 0.0, max: 2.0},
			noise: {isSlider: true, min: 0.0, max: 2.0},
			smoothNoise: {isSlider: true, min: 0.0, max: 2.0},
			smoothNoiseScale: {isSlider: true, min: 0.0, max: 2.0},
			smoothNoiseSpeed: {isSlider: true, min: 0.0, max: 5.0},

			backLayer: {isSlider: true, min: 0.0, max: 1.0},
			frontLayer: {isSlider: true, min: 0.0, max: 1.0},
			eyesLayer: {isSlider: true, min: 0.0, max: 1.0},
			overLayer: {isSlider: true, min: 0.0, max: 1.0}
		},
		
		effects: {
			isFolder: true,
			isOpen: false,
			oignonSkin: {
				isFolder: true,
				isOpen: false,
				frames: {isSlider: true, min: 1, max: 24, isInt: true},
				fps: {isSlider: true, min: 0, max: 60, isInt: true},
				skip: {isSlider: true, min: 0, max: 2, isInt: true},
				color: {isSlider: true, min: 0.0, max: 1.0},
				palette: {isDropdown:true, values:["palette_a", "palette_b"]}
			},
			oignonSkinVector: {
				isFolder: true,
				isOpen: false,
				frames: {isSlider: true, min: 1, max: 24, isInt: true},
				fps: {isSlider: true, min: 0, max: 60, isInt: true},
				skip: {isSlider: true, min: 0, max: 2, isInt: true},
				color: {isSlider: true, min: 0.0, max: 1.0},
				palette: {isDropdown:true, values:["palette_a", "palette_b"]},
				randomBrush: {isSlider: true, min: 0.0, max: 1.0},
			},
			
			emboss: {
				isFolder: true,
				isOpen: false,
				enabled: {isBoolean: true},
				dst: {isSlider: true, min: 0.0, max: 5.0},
				alpha: {isSlider: true, min: 0.0, max: 1.0},
			},

			digitalPrototype: {
				isFolder: true,
				isOpen: false,
				enabled: {isBoolean: true},
				alpha: {isSlider: true, min: 0.0, max: 1.0},
				radial: {isSlider: true, min: 0.0, max: 1.0},
				colorA: {isSlider: true, min: 0.0, max: 1.0},
				colorB: {isSlider: true, min: 0.0, max: 1.0},
				gridA: {isSlider: true, min: 0.0, max: 1.0},
				noiseB: {isSlider: true, min: 0.0, max: 1.0},
				gridC: {isSlider: true, min: 0.0, max: 1.0},
				radialD: {isSlider: true, min: 0.0, max: 1.0},
				radialE: {isSlider: true, min: 0.0, max: 1.0},
				gridF: {isSlider: true, min: 0.0, max: 1.0},
				accumG: {isSlider: true, min: 0.0, max: 1.0, isHidden: true},
				accumNoiseH: {isSlider: true, min: 0.0, max: 1.0, isHidden: true}
			}

		},

		magnetism: {
			isFolder: true,
			isOpen: false,
			algorithm: {isDropdown: true, values: ['default', "guitare"]},
			alpha: {isSlider: true, min: 0.0, max: 1.0},
			attract: {isSlider: true, min: 0.0, max: 1.0},
			inverse: {isSlider: true, min: 0.0, max: 1.0},
			elastic:{isSlider: true, min: 0.0, max: 1.0},
			curve: {isSlider: true, min: 0.0, max: 3.0},
			size: {isSlider: true, min: 0.0, max: 2.0},
			strength: {isSlider: true, min: 0.0, max: 2.0},
			extrude: {isSlider: true, min: 0.0, max: 1.0},
			randomCursor:  {isSlider: true, min: 0.0, max: 1.0},
			randomShake: {isSlider: true, min: 0.0, max: 1.0},
			backLayer: {isSlider: true, min: 0.0, max: 1.0},
			frontLayer: {isSlider: true, min: 0.0, max: 1.0},
			eyesLayer: {isSlider: true, min: 0.0, max: 1.0},
			overLayer: {isSlider: true, min: 0.0, max: 1.0}
		},
	
		brush: {
			isFolder: true,
			isOpen: false,
			enabled: {isBoolean: true},
			useColors: {isBoolean: true, isHidden: true},
			shader: {isDropdown: true, values:["linebrush_global", "emboss", "emboss2"]}, //"linebrush_col", "linebrush_0", "linebrush_1", "linebrush_2", 
			texture: {isMultiDropdown: true, autofillBaseValues: ["none"], values:[], autofill: 'images/brushes/'},
			sourceSize: {isSlider: true, min:0.00, max: 0.25},
			deplacement: {isSlider: true, min:0.0, max: 0.25},
			sourceX: {isSlider: true, min:0.0, max: 1.0},
			sourceY: {isSlider: true, min:0.0, max: 1.0}
		},

		draw: {
			isFolder: true,
			isOpen: false,
			enabled: {isBoolean: true},
			clear: {isButton: true},
			mode: {isDropdown: true, values:["small", "dashed"]},
			lineLength: {isSlider: true, min:0.0, max: 5.0},
			fps: {isSlider: true, min:0.0, max: 60.0},
			duplicateCursor: {isBoolean: true},
			kaleidoscope: {isSlider: true, min:0, max: 16, isInt: true},
		},

		background: {
			isFolder: true,
			isOpen: false,
			enabled: {isBoolean: true},
			alpha:{isSlider: true, min:0.0, max: 1.0},
			fillColor: {isColor: true},
			clickFlash: {isBoolean: true},
			roundedBox: {isBoolean: true},

			pulseGradient: {
				isFolder: true,
				isOpen: false,
				alpha: {isSlider: true, min:0.0, max: 1.0},
				pulseSpeed: {isSlider: true, min:0.0, max: 4.0},
				changeSpeed: {isSlider: true, min:0.0, max: 4.0},
				gradientMin: {isSlider: true, min:0.0, max: 5.0},
				gradientMax: {isSlider: true, min:0.0, max: 10.0},
				noiseA: {isSlider: true, min:0.0, max: 4.0},
				noiseB: {isSlider: true, min:0.0, max: 4.0},

				colorA: {isColor: true},
				colorB: {isColor: true},
				colorC: {isColor: true},
			}
		},

		copresence: {
			isFolder: true,
			isOpen: false,
			enabled: {isBoolean: false}
		},

		installation: {
			isFolder: true,
			isOpen: false,
			defaultColors: {isBoolean: true},
			connected: {isBoolean: true},
			color: {isColor: true},
			paletteMode: {isBoolean: true},
			colorBEnabled: {isBoolean: true},
			colorB: {isColor: true}
			// paletteModeSlow: {isBoolean: true},
		}
	}
};