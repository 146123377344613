import $ from 'jquery';



function Autofill(params, enabled, callback) {
	//
	// Trim list
	//
	function success(e) {
		var nlist = [];
		try {
			var list = JSON.parse(e).files;

			nlist = list;

			//only use extensions
			if (params.autofillExtensions) {
				nlist = [];
				for (var i=0; i<list.length; i++) {
					var ext = list[i].split('.').pop();
					if (params.autofillExtensions[ext]) nlist.push(list[i]);
				}
			}

			//replace sequence folders
			if (params.autofillSort) {
				nlist = nlist.sort();
			}

			if (params.autofillBaseValues) {
				nlist = params.autofillBaseValues.concat(nlist);
			}

		} catch (er) {
			console.warn(e,er);
		}

		callback(nlist);
	}


	//
	// Load
	//
	if (enabled && SETTINGS.IS_LOCAL && !params.preloaded) {

		$.ajax({
			type: "GET",
			data:{ 
				dir: params.autofill
				// recursive: params.allowFolders
			},
			url: '/filelist',
			success: success
		});

	} else if (params.preloaded) {


	//load json cache
	} else {

		$.ajax({
			type: "GET",
			dataType: "text",
			url: 'data/autofillcache/'+params.autofill.replace(/\//gi,'_').replace(/\ /gi,'-')+'.json',
			success: success
		});

	}


};


export default Autofill;