import $ from 'jquery';
import FastEvent from './FastEvent.js';

function BinFolder(UI) {
	if (!UI.enabled) return;

	//
	// Create the new ROW
	//
	this.rowDiv = document.createElement('div');
	this.rowDiv.classList.add('ui-row');
	UI.allFolders.push(this);
	//$(this.rowDiv).toggleClass('closed', true);

	//
	// Create the div hierarchy
	//
	var containerDiv = document.createElement('div'),
		folderDiv = document.createElement('div'),
		arrowDiv = document.createElement('div'),
		labelDiv = document.createElement('div'),
		sequencerAddDiv = document.createElement('div'),
		sequencerDeleteDiv = document.createElement('div'),
		allItems = [];
	this.domElement = this.rowDiv;
	this.uiItem = containerDiv;

	containerDiv.appendChild(folderDiv);
	folderDiv.appendChild(arrowDiv);
	folderDiv.appendChild(labelDiv);
	//this.rowDiv.appendChild(containerDiv);
	
	//
	// Set the classes and basic properties
	//
	$(containerDiv).addClass('ui-item');
	$(folderDiv).addClass('ui-folder');
	$(labelDiv).addClass('ui-window-header-label');
	$(arrowDiv).addClass('ui-folder-arrow');
	$(arrowDiv).text('▼');


	folderDiv.appendChild(sequencerAddDiv);
	folderDiv.appendChild(sequencerDeleteDiv);
	$(sequencerDeleteDiv).addClass('ui-folder-delete');
	$(sequencerAddDiv).addClass('ui-folder-add');
	$(sequencerDeleteDiv).text("-");
	$(sequencerAddDiv).text("+");


	//
	// props
	//
	this.uuid = Math.floor(Math.random() * 100000000000).toFixed(0);
	this.value = false;
	this.propName = '';
	this.toWatch = {};
	this.props = null;
	this.changing = false;
	this.path = [];
	this.isOpen = false;
	this.subfolders = {};


	//
	//  open / close
	//
	FastEvent.on(this, 'open', function() {
		this.isOpen = true;
		this.props.isOpen = true;
		$(folderDiv).toggleClass('open', true);
		$(arrowDiv).css('transform', 'rotate(0deg)')

		$(this.rowDiv).toggleClass('closed', false);
		$(this.rowDiv).toggleClass('open', true);

		$(sequencerDeleteDiv).toggleClass('disabled', !this.isOpen || (allItems.length <= 1));
		$(sequencerAddDiv).toggleClass('disabled', !this.isOpen);

	}.bind(this));

	FastEvent.on(this,'close', function() {
		this.isOpen = false;
		this.props.isOpen = false;
		$(folderDiv).toggleClass('open', false);
		$(arrowDiv).css('transform', 'rotate(-90deg)')

		$(this.rowDiv).toggleClass('open', false);
		$(this.rowDiv).toggleClass('closed', true);

		$(sequencerDeleteDiv).toggleClass('disabled', !this.isOpen || (allItems.length <= 1));
		$(sequencerAddDiv).toggleClass('disabled', !this.isOpen);
		
	}.bind(this));

	this.toggleClose = function(e) {
		this.isOpen = !this.isOpen;
		this.props.isOpen = this.isOpen;
		FastEvent.trigger(this, this.isOpen ? 'open' : 'close');
	}.bind(this);


	this.open = function(e) {
		if (this.isOpen) return;
		this.toggleClose();
	}.bind(this);



	//
	// init the properties
	//
	this.init = function(toWatch, propName, props, path, startingSize) {
		

		this.propName = propName;
		this.props = props;
		this.toWatch = toWatch;
	
		$(labelDiv).text(propName);
		$(labelDiv).css('border-color', props.colorCode || '#ffffff');
		this.path = path;

		$(this.rowDiv).css('width', (256-(this.path.length-startingSize+1)*4)+'px');

		this.isOpen = (props.isOpen === true) || this.path.length == 0;
		FastEvent.trigger(this, this.isOpen ? 'open' : 'close');

		//
		// Add new dropdown items for each prop in array
		//
		for (var i=0; i<this.toWatch[this.propName].length; i++) {
			var dropdown = new UI.Dropdown(UI);
			dropdown.init(this.toWatch[this.propName], i.toString(), this.props.binRange, path.concat(i.toString()));
			this.addItem(dropdown);
		}
		$(sequencerDeleteDiv).toggleClass('disabled', (allItems.length <= 1));

	}.bind(this);




	//
	// Add/Delete
	//
	$(sequencerAddDiv).on('click touchstart', function(e) {
		e.preventDefault();
		e.stopPropagation();

		if (!this.isOpen) return;

		var ni = this.toWatch[this.propName].length;

		this.toWatch[this.propName].push('');

		var dropdown = new UI.Dropdown(UI);
		dropdown.init(this.toWatch[this.propName], ni.toString(), this.props.binRange, this.path.concat(ni.toString()));
		this.addItem(dropdown);

		$(sequencerDeleteDiv).toggleClass('disabled', (allItems.length <= 1));

		UI.triggerProperty(this.toWatch, this.propName);

	}.bind(this));


	$(sequencerDeleteDiv).on('click touchstart', function(e) {

		e.preventDefault();
		e.stopPropagation();

		if (allItems.length <= 1) return;

		var item = allItems.pop();
		this.rowDiv.removeChild(item.domElement);
		item.dispose();
		this.toWatch[this.propName].pop();

		$(sequencerDeleteDiv).toggleClass('disabled', (allItems.length <= 1));

		UI.triggerProperty(this.toWatch, this.propName);

	}.bind(this));


	//
	// Open Folder
	//
	$(containerDiv).on('click touchstart', function(e) {

		this.isOpen = !this.isOpen;
		FastEvent.trigger(this, this.isOpen ? 'open' : 'close');

	}.bind(this));


	//
	// Update ranges
	//
	this.updateRanges = function() {
		for (var i=0; i<allItems.length; i++) {
			if (allItems[i].updateRanges) allItems[i].updateRanges();
		}
	};

	//
	// Dispose all sub-element
	//
	this.addItem = function(item) {
		this.rowDiv.appendChild(item.domElement);
		allItems.push(item);
	};
	this.dispose = function() {
		if (!this.rowDiv) return;

		for (var i = 0; i < allItems.length; i++) {
			if (allItems[i].domElement) this.rowDiv.removeChild(allItems[i].domElement);
			allItems[i].dispose();
		}
		allItems = [];

		this.subfolders = {};

		$(this.rowDiv).off();
		$(containerDiv).off();
		$(folderDiv).off();
		$(arrowDiv).off();
		$(labelDiv).off();
		$(sequencerAddDiv).off();
		$(sequencerDeleteDiv).off();



		$(this.rowDiv).remove();
		$(containerDiv).remove();
		$(folderDiv).remove();
		$(arrowDiv).remove();
		$(labelDiv).remove();

		this.rowDiv = null;
		this.domElement = null;
		this.uiItem = null;
		containerDiv = null;
		folderDiv = null;
		arrowDiv = null;
		labelDiv = null;

		for (var i=0; i<UI.allFolders; i++) {
			if (UI.allFolders[i] === this) UI.allFolders[i] = null;
		}
	};


};

export default BinFolder;

