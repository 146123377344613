import * as THREE from 'three';

class SimpleSpline {

	constructor() {

		// this.startPoint = new THREE.Vector2();
		// this.endPoint = new THREE.Vector2();
		// this.centroid = new THREE.Vector2();

		this.cachedLength = 0;
		this.totalPoints = 0;
		// this.centroidAngle = 0;
		this.sortedAngle = 0;

		this.points = new Float32Array();
		this.numPoints = 0;
		this.layer = "svg";
	}

	setVectors(src) {
		var np = src.length;
		this.points = new Float32Array(Math.floor(np*2));
		for (var i =0; i<np; i++) {
			this.points[i*2] = src[i].x;
			this.points[i*2+1] = src[i].y;
		}
		this.numPoints = np;
		// if (this.numPoints < 3) console.log("shit", this.numPoints);
	}
	setArray(src) {
		var np = Math.floor(src.length/3);
		this.points = new Float32Array(Math.floor(np*2));
		for (var i =0; i<np; i++) {
			this.points[i*2] = src[i*3];
			this.points[i*2+1] = src[i*3+1];
		}
		this.numPoints = np;

		// if (this.numPoints < 3) {
		// 	console.log("oops", this.numPoints);
		// }
	}
	setArray2(src) {
		var np = Math.floor(src.length/2);
		this.points = src;
		this.numPoints = np;
		// if (this.numPoints < 3) {
		// 	console.log("oops", this.numPoints);
		// }
	}

	getPoints(numPoints) {
		var nf = new Float32Array(numPoints * 3);
		
		// same number of points
		if (numPoints == this.numPoints) {
			for (var i =0; i<numPoints; i++) {
				nf[i*3] = this.points[i*2];
				nf[i*3+1] =  this.points[i*2+1];
			}

		//tween points
		} else {

			var npm1 = numPoints-1,
				tpm1 = this.numPoints - 1;
			for (var i = 0; i < numPoints; i++) {
				var pc = i/npm1;
				var pPc = (pc * tpm1) % 1.0;
				var pStart = Math.floor(pc * tpm1);
				var pEnd = Math.min(Math.ceil(pc * tpm1),tpm1);

				nf[i*3] = this.points[pStart*2] * pPc + this.points[pEnd*2] * (1.0-pPc);
				nf[i*3+1] = this.points[pStart*2+1] * pPc + this.points[pEnd*2+1] * (1.0-pPc);
			}
		}
		return nf;
	}

	getLength() {
		var totalLength = 0;

		for (var i=1; i<this.points.length; i+=2) {
			totalLength += Utils.distance(this.points[i-1].x, this.points[i-1].y, this.points[i].x, this.points[i].y);
		}

		this.cachedLength = totalLength;
		if (isNaN(this.cachedLength)) {
			console.log("NaN again!");
		}
		return this.cachedLength;
	}

	dispose() {
		this.points = new Float32Array();
	}

}

export default SimpleSpline;