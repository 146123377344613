/**
 *
 * Simple Audio Node based on two gains that mixes a node towards two outputs (dry and wet)
 * Used everywhere for reverb and filters mixing
 *
*/
import AudioDisposeController from '../controllers/AudioDisposeController.js';
//
//
// Utility functions to mix logarithmically
//
//
function mixWet(pc) {
	return Math.log(1.0+pc*(Math.E-1.0)) || 0;
}
function mixDry(pc) {
	return Math.log(1.0+(1.0-pc)*(Math.E-1.0)) || 0;
}

//
// Reverb Mix Node
//
function MixNode(context, mix) {

	var self = this,
		audioContext = context;

	//create the gains
	this.dry = audioContext.createGain();
	this.dry.gain.setValueAtTime(mixDry(mix), audioContext.currentTime); 

	this.wet = audioContext.createGain();
	this.wet.gain.setValueAtTime(mixWet(mix), audioContext.currentTime); 

	this.currentMix = mix;
	//this.destination = [];
	this.wetDestination = null;
	this.dryDestination = null;
	this.disposed = false;

	this.dryConnected = false;
	this.wetConnected = false;

	//
	// Disconnect @ 0
	//
	var disconnectDryTimeout = 0,
		disconnectWetTimeout = 0;
	this.autoDisconnect = false;


	//
	// Mix over time
	//
	this.setMix = function(value, fadeTime, force) {
	
		if ((!force && this.currentMix === value) || this.disposed) return;
		if (fadeTime>=30) console.warn('MixNode fade time should be in seconds not milliseconds');

		this.dry.gain.tweenToValue(mixDry(value), fadeTime||0);
		this.wet.gain.tweenToValue(mixWet(value), fadeTime||0);

		this.currentMix = value;
	
	};

	//
	// Connect or disconnect
	//
	this.receive = function(node) {
		if (self.disposed) console.error('Trying to use to MixNode after disposal');
		node.connect(this.dry);
		node.connect(this.wet);
	};
	this.connectWet = function(destination) {
		if (self.disposed) console.error('Trying to use to MixNode after disposal');
		//self.destination = self.destination.concat(destination);
		self.wetDestination = destination;
		var ndst = [].concat(destination);
		for (var i=0; i<ndst.length; i++) {
			if (ndst[i].receive) ndst[i].receive(self.wet); else self.wet.connect(ndst[i]);
		}
		self.wetConnected = true;
	};
	this.disconnectWet = function() {
		self.wet.disconnect();
		self.wetConnected = false;
	};
	this.disconnectDry = function() {
		self.dry.disconnect();
		self.dryConnected = false;
	};
	this.connectDry = function(destination) {
		if (self.disposed) console.error('Trying to use to MixNode after disposal');
		//self.destination = self.destination.concat(destination);
		self.dryDestination = destination;
		var ndst = [].concat(destination);
		for (var i=0; i<ndst.length; i++) {
			if (ndst[i].receive) ndst[i].receive(self.dry); else self.dry.connect(ndst[i]);
		}
		self.dryConnected = true;
	};
	this.reconnectWet = function() {
		if (self.wetConnected || !self.wetDestination) return;
		self.connectDry(self.wetDestination);
	};
	this.reconnectDry = function() {
		if (self.dryConnected || !self.dryDestination) return;
		self.connectDry(self.dryDestination);
	};

	this.fadeOut = function(fadeTime) {
		if (fadeTime>=30) console.warn('MixNode fade time should be in seconds not milliseconds');

		self.dry.gain.tweenToValue(0.0, fadeTime);
		self.wet.gain.tweenToValue(0.0, fadeTime);

		AudioDisposeController.dispose(this, fadeTime+0.05);
	};
	this.getDryGain = function() {
		return self.dry;
	};
	this.getWetGain = function() {
		return self.wet;
	};

	//
	// Kill over time
	//
	this.dispose = function() {
		if (self.disposed) return;

		self.dry.dispose();
		self.wet.dispose();
		self.wetDestination = undefined;
		self.dry = null;
		self.wet = null;
		self.disposed = true;
		self.dryConnected = false;
		self.wetConnected = false;
	};
}
export default MixNode;