import $ from 'jquery';
import * as THREE from 'three';
import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import Fbo from '../utils/Fbo.js';

import Loader from "../loading/Loader.js";
import LoaderAudio from "../loading/LoaderAudio.js";
import AudioDisposeController from "../controllers/AudioDisposeController.js";
import InteractionController from "../controllers/InteractionController.js";
import HueController from "../controllers/HueController.js";

import Scene from './Scene.js';

import Sequence from '../objects/Sequence.js';
import SVGFrame from '../objects/SVGFrame.js';
import SVGMorph from '../objects/SVGMorph.js';
import PointState from '../objects/PointState.js';
import { makeNoise2D } from "open-simplex-noise";
import SequenceOptions from "../objects/SequenceOptions.js";
import DreamUI from "../DreamUI.js";


var presetFolder = 'data/presets/sequences/';
var audioFolder = "audio/timeline/";

var AUDIO_DIVISION_DURATION = 60.0;
var AUDIO_DIVISION_OVERLAP = 0.5;

var MAX_LINES = 64;
// SETTINGS.LINE_RESOLUTION = 512;
var audioContext = window.audioContext = window.audioContext||new AudioContext();

class SceneEditor extends Scene {

	constructor() {
		super()
		this.disposed = false;
		this.presetFolder = presetFolder;
		window.DreamUI = DreamUI;

		// if (SETTINGS.isMobile) SETTINGS.LINE_RESOLUTION = 512;

		//gui presets
		this.currentDragSpeed = new THREE.Vector2(0.0, 0.0);
		this.currentDragPosition = new THREE.Vector2(0.0, 0.0);
		this.targetPosition = new THREE.Vector2(0.0, 0.0);
		this.currentPosition = new THREE.Vector2(0.0, 0.0);
		this.targetSplines = [];

		//three.js
		this.scene = new THREE.Scene();
		this.camera = new THREE.PerspectiveCamera( 50, AppStatus.innerWidth() / AppStatus.innerHeight(), 0.1, 100000 );
		this.camera.position.set( 0, 0, 2200 );
		this.camera.scale.y = -1;
		this.camera.aspect = AppStatus.innerWidth() / AppStatus.innerHeight();
		this.camera.updateProjectionMatrix();

		//mode tween, mode jouer loops
		this.audioBuffersByName = {};
		this.audioLoadingByName = {};

		//gui
		this.audioShouldPlay = false;
		this.audioPlaying = false;
		this.hasDatGUI = false;
		this.options = {
			preset: SETTINGS.BASE_PRESET||"none",
			savePreset: () => {
				var value = prompt('Nom du preset', this.options.preset);
				if (!value) {return;}


				if (!/\.json/.test(value)) {
					value = value+'.json';
				}
		        var jsonFile = JSON.stringify(
		        	{
						version: SETTINGS.INTERNAL_VERSION,
						options: this.options.sequence
					},
				null,'\t');

		        Utils.saveJsonSimple(value, presetFolder, jsonFile).then(function() {
		       	 	this.options.preset = value;
		       	 	DreamUI.update(this.options, 'preset');
					DreamUI.triggerEvent(this.ranges.preset, 'autofill');
		       	 	console.log("Save successful ",value);
		       	 	// Utils.cloneInto()
		        }.bind(this)).catch(function(e) {
		        	console.log("Save error!",e);
		        	// saving = false;
		        });

			},
			currentTime: "0.0",
			progress: 0.0,
			"play/pause": () => {
				AudioController.timelineStarted = !AudioController.timelineStarted;
				AudioController.startContext();
			},
			"pauseanim": () => {
				window.pausedAnim = !window.pausedAnim;
			},
			transitionPreset: "none",
			sequence: SequenceOptions.options
		};
		this.defaultOptions = Utils.clone(this.options.sequence);


		this.ranges = {
			preset: {isMultiDropdown: true, autofillBaseValues: ["none"], values:[], autofill: presetFolder, autofillEvent: true, callback: function() {
				if (this.options.preset === 'none') {
					this.cloneIntoMod(this.defaultOptions, this.options.sequence);
					this.currentPresetName = 'none';
				} else {
					this.reloadPreset(true);
				}
			}.bind(this),

			customSort: function(a,b) {



					if (!a||a.length<3) return 1;
					if (!b||b.length<3) return -1;
					var alist = a.split('/').pop().split('_');
					var blist = b.split('/').pop().split('_');

					var aa = parseInt(alist[0],10);
					var bb = parseInt(blist[0],10);
					if ((isNaN(aa) || !aa) && (isNaN(bb) || !bb)) return a.toLowerCase()[0] < b.toLowerCase()[0] ? -1 : 1;
					if (isNaN(aa) || !aa) return 1;
					if (isNaN(bb) || !bb) return -1;
					
					if (aa == bb) {
						aa = parseInt(alist[1],10);
						bb = parseInt(blist[1],10);
						if (isNaN(aa) || !aa) return 1;
						if (isNaN(bb) || !bb) return -1;
					}
					return aa < bb ? -1 : 1;
				}
			},
			savePreset: {isButton: true},
			currentTime: {isText: true, callback: (time, dragging)=> {
				if (!dragging) return;
				// this.currentTime = this.soundPauseTime = this.options.currentTime;
				// if (!this.audioPlaying) return;
				// this.resyncing = true;
				// this.stopAudio();
				// this.currentTime = this.options.currentTime;
				// this.playAudio(this.options.currentTime);
			}},
			progress: {isSlider: true, min: 0.0, max: 1.0, callback: (time, dragging)=> {
				if (!dragging) return;
				var ct =  Utils.ccmap(this.options.progress,0.0,1.0, Utils.timecodeToSeconds(this.options.sequence.timeline.timeStart), Utils.timecodeToSeconds(this.options.sequence.timeline.timeEnd));
				AudioController.seek(ct);
			}},
			play: {isButton: true},
			pauseanim: {isButton: true},
			transitionPreset: {isMultiDropdown: true, autofillBaseValues: ["none"], values:[], autofill: presetFolder, autofillEvent: true, callback: function() {
				this.shouldReloadFramesTransition = true;
			}.bind(this)},
			sequence: SequenceOptions.ranges
		};

		if (SETTINGS.ELASTIC_MODE) {
			this.options.elastic = {
				time: 0.75,
				bounces: 5.25,
				pressCurve: 1.0,
				releaseCurve: 0.9
			}
			this.ranges.elastic = {
				isFolder: true,
				isOpen: true,
				time: {isSlider: true, min: 0.33, max: 3.0},
				bounces: {isSlider: true, min: Math.PI*0.5, max: 12.0},
				pressCurve: {isSlider: true, min: 0.33, max: 3.0},
				releaseCurve: {isSlider: true, min: 0.33, max: 3.0},
			}
		}

		//change audio cue
		this.ranges.sequence.timeline.cue.callback = (val, dragging) => {
			if (!this.timecodeById[this.options.sequence.timeline.cue]) {
				console.log("no timecode for cue",this.options.sequence.timeline.cue);
				if (this.timecodeByPreset[this.options.preset]) {
					console.log("replacing by cue:",this.timecodeByPreset[this.options.preset]);
					this.options.sequence.timeline.cue = this.timecodeByPreset[this.options.preset];
					setTimeout(()=>{DreamUI.update(this.options.sequence.timeline, "cue")},500);
				} else {
					console.log("No cue for preset:",this.options.preset);
				}
			}
			if (this.timecodeById[this.options.sequence.timeline.cue]) {
				var info = this.timecodeById[this.options.sequence.timeline.cue];
				this.options.sequence.timeline.timeStart = Utils.secondsToTimecode(info.timeStart);
				DreamUI.triggerProperty(this.options.sequence.timeline, "timeStart", info.timeStart);
				this.options.sequence.timeline.timeEnd = Utils.secondsToTimecode(info.timeEnd);
				DreamUI.triggerProperty(this.options.sequence.timeline, "timeEnd", info.timeEnd);


				this.ranges.sequence.transition.duration.max = info.timeEnd - info.timeStart;
				DreamUI.triggerEvent(this.ranges.sequence.transition.duration, "updateRange");

				AudioController.seek(info.timeStart);
			}

			//set transitionPreset based on timeline
			if (this.nextPresetByCue[this.options.sequence.timeline.cue]) {
				this.options.transitionPreset = this.nextPresetByCue[this.options.sequence.timeline.cue];
				// DreamUI.triggerProperty(this.options, "transitionPreset");
				// DreamUI.update(this.options, "transitionPreset");
				setTimeout(()=>{DreamUI.update(this.options, "transitionPreset");}, 1000);
			}
		};


		this.shouldReloadFrames = true;
		this.ranges.sequence.layers.mobileTextOffset.callback = this.ranges.sequence.layers.timelineEyes.callback = this.ranges.sequence.layers.shuffleFrames.callback = this.ranges.sequence.layers.back.callback = this.ranges.sequence.layers.front.callback = this.ranges.sequence.layers.eyes.callback = this.ranges.sequence.layers.over.callback = 
		this.options.sequence.draw.clear = this.ranges.sequence.brush.enabled.callback = this.ranges.sequence.brush.texture.callback = this.ranges.sequence.brush.shader.callback =
		()=>{
			this.shouldReloadFrames = true;
		};
		this.ranges.sequence.morph.randomness.tweenRotation.callback = 
		this.ranges.sequence.morph.randomness.assignRandom.callback = ()=>{
			this.shouldReloadFrames = true;
		};


		this.guiCallbacks = Utils.cloneIntoAdd({}, this.guiCallbacks||{});

		DreamUI.autofillEnabled = true;
		DreamUI.init(this.options, this.ranges);

		this.uiBrushFolder = DreamUI.getFolder(['sequence', 'brush']); //to show brush ui when folder is opens


		//
		// Create sequence
		//
		// this.sequence = new Sequence(0, this.options.sequence);
		this.shouldReloadFrames = true;
		this.shouldReloadFramesTransition = true;


		HueController.setup();

		//preload font
		// $('<div style="font-family: Work Sans; position:absolute; z-index:-1;">Participant</div>').appendTo(document.body);
	}



	cloneIntoMod(source, target) {
		for (var prop in source) {
			if (prop in target) {
				var isObj = source[prop] && source[prop].constructor == Object;

				if (isObj && source[prop].value==undefined && source[prop].values==undefined) {
					this.cloneIntoMod(source[prop], target[prop])
				
				} else if (isObj && source[prop].value!==undefined) {

					var up = (target[prop].value != source[prop].value);
					target[prop].value = source[prop].value;
					DreamUI.update(target, prop); //if (up) 

				} else if (isObj && source[prop].values) {

					target[prop].values = Utils.cloneArray(source[prop].values);
					DreamUI.update(target, prop);

				} else {
					var up = (target[prop] != source[prop]);
					target[prop] = source[prop];
					DreamUI.update(target, prop); ////if (up) 
				}
			}
		}
	}

	reloadPreset(force) {
		if (this.options.preset == this.currentPresetName) return;
		this.currentPresetName = this.options.preset;
		var xhr = new LoaderXHR(this.presetFolder+this.options.preset, 'json', true);
		xhr.start(function(val) {
			var migrated = this.migratePreset(xhr.data.value);
			if (migrated.options.preset) delete migrated.options.preset;
			if (!this.sequence)  migrated.options = (new Sequence(0, this.options.sequence)).migratePreset(migrated.options, migrated.version, migrated);
			else migrated.options = this.sequence.migratePreset(migrated.options, migrated.version, migrated);
			this.cloneIntoMod(migrated.options, this.options.sequence);

		}.bind(this))
	}

	//preload json data
	prePreloadData(batchName) {

		this.timelineInfo = Loader.addXHR(batchName, SETTINGS.TIMELINE_FILE, 'json5');
		console.log("LOADING TIMELINE:",SETTINGS.TIMELINE_FILE);

		Loader.addShader(batchName, 'shaders/brushes/source.frag');

		SequenceRenderer.preloadData(batchName);


	}

	//preload json data
	preloadData(batchName) {
		super.preloadData(batchName);
		this.timelineInfo = this.timelineInfo.value;
		if (this.timelineInfo.AUDIO_DURATION) AppStatus.AUDIO_DURATION = this.timelineInfo.AUDIO_DURATION;
		if (this.timelineInfo.voice) AudioController.setAudioFile(this.timelineInfo.voice, this.timelineInfo.audio_divisions);
		AppStatus.timelineInfo = this.timelineInfo;

		//
		// Get all timecodes and update GUI
		//
		this.timecodes = [];
		this.timecodeIds = [];
		this.timecodeById = {};
		this.timecodeByPreset = {};
		for (var timecode in this.timelineInfo.sequences) {
			var info = {
				timeStart:Utils.timecodeToSeconds(timecode),
				timecode:Utils.timecodeToSeconds(timecode),
				info: this.timelineInfo.sequences[timecode],
				id: this.timelineInfo.sequences[timecode].id
			}
			this.timecodeById[info.id] = info;
			this.timecodeByPreset[this.timelineInfo.sequences[timecode].preset] = info.id;
			this.timecodes.push(info);
		}

		//sort and add durations
		this.timecodes = this.timecodes.sort(function(a,b) {
			return a.timecode < b.timecode ? -1 : 1;
		});
		for (var i=0; i<this.timecodes.length-1; i++) {
			this.timecodes[i].duration = this.timecodes[i+1].timecode - this.timecodes[i].timecode;
			this.timecodes[i].timeEnd = this.timecodes[i].timeStart + this.timecodes[i].duration;
			this.timecodes[i].arrayId = i;
		}
		this.timecodes[this.timecodes.length-1].duration = AppStatus.AUDIO_DURATION - this.timecodes[i].timecode;
		this.timecodes[this.timecodes.length-1].timeEnd = this.timecodes[this.timecodes.length-1].timeStart + this.timecodes[this.timecodes.length-1].duration;
		for (var i=0; i<this.timecodes.length; i++) {
			this.timecodeIds.push( this.timecodes[i].id);
		}

		//get next preset
		this.nextPresetByCue = {};
		for (var i=0; i<this.timecodes.length-1; i++) {
			this.nextPresetByCue[this.timecodes[i].id] = this.timecodes[i+1].info.preset;
		}

		SequenceRenderer.preload(batchName);

	}

	//preload all assets
	preload(batchName) {
		super.preload(batchName);
	
		// Loader.addShader(batchName, 'shaders/line/v0.vert');
		// Loader.addShader(batchName, 'shaders/line/v0.frag');
		// Loader.addShader(batchName, 'shaders/line/magneticState.vert');
		// Loader.addShader(batchName, 'shaders/line/magneticState.frag');
		// Loader.addShader(batchName, 'shaders/common/color.frag');
		// Loader.addShader(batchName, 'shaders/common/vertex.vert');

		//
		// other assets
		//


		//
		// For each sequence, create a batch, add svg and audio files
		// Don't autostart batch
		//
	}



	//setup interaction and events
	start() {

		super.start();


		//update ui
		this.ranges.sequence.layers.back.values = AppStatus.svgList;
		DreamUI.triggerEvent(this.ranges.sequence.layers.back, "updateRange");

		this.ranges.sequence.layers.front.values = AppStatus.svgList;
		DreamUI.triggerEvent(this.ranges.sequence.layers.front, "updateRange");

		this.ranges.sequence.layers.eyes.values = AppStatus.svgList;
		DreamUI.triggerEvent(this.ranges.sequence.layers.eyes, "updateRange");

		this.ranges.sequence.layers.over.values = AppStatus.svgList;
		DreamUI.triggerEvent(this.ranges.sequence.layers.over, "updateRange");

		this.ranges.sequence.timeline.cue.values = ["none"].concat(this.timecodeIds);
		DreamUI.triggerEvent(this.ranges.sequence.timeline.cue, "updateRange");


		this.ranges.sequence.effects.oignonSkin.palette.values = Object.keys(AppStatus.infoJson.oignon_palettes);
		DreamUI.triggerEvent(this.ranges.sequence.effects.oignonSkin.palette, "updateRange");
		
		this.ranges.sequence.effects.oignonSkinVector.palette.values = Object.keys(AppStatus.infoJson.oignon_palettes);
		DreamUI.triggerEvent(this.ranges.sequence.effects.oignonSkinVector.palette, "updateRange");


		AudioController.setup(this.options);
		SequenceRenderer.setup();

		this.fbo = new Fbo(renderer.domElement.width, renderer.domElement.height, {
			format: THREE.RGBAFormat,
			minFilter: THREE.LinearFilter,
			magFilter: THREE.LinearFilter,
			generateMipmaps: false,
			wrap: THREE.ClampToEdgeWrapping,
			type: THREE.UnsignedByteType,
			premultiplyAlpha: false,
			depthBuffer: false,
			stencilBuffer: false,
			pingPongEnabled: false
		});


		//
		// Brush handling
		//
		this.sourceDisplayMaterial = new THREE.RawShaderMaterial({
			transparent: true,
			depthTest: false,
			depthWrite: false,
			side: THREE.DoubleSide,
			vertexShader: Loader.getShader('shaders/common/vertex.vert'),
			fragmentShader: Loader.getShader('shaders/brushes/source.frag'),
			uniforms: {
				tDiffuse: { type: 't', value: Utils.emptyTexture},
				source: { type: 'v2', value:new THREE.Vector2(0.5,0.5)},
				ratio: { type: 'v2', value:new THREE.Vector2(0.5625,1.0)},
				size: {type: 'f', value: 0.05}
			}
		});
		this.sourceDisplayPlane = new THREE.Mesh(Utils.planeGeometry, this.sourceDisplayMaterial);
		this.sourceDisplayPlane.scale.set(0.25,0.25,0.25);
		this.sourceDisplayPlane.position.set(-0.5+0.1,-0.5+0.1, 0.0);
		this.sourceDisplayScene = new THREE.Scene();
		this.sourceDisplayScene.add(this.sourceDisplayPlane);
		this.sourcePosition = new THREE.Vector2(0.5,0.5);
		this.selectingBrush = false;


		if (SETTINGS.BASE_PRESET) this.reloadPreset();


		$(document).keydown(function(e) {
			if (e.key === ';') {
				window.pausedAnim = !window.pausedAnim;
			}
		});
	}


	//main loop
	update(delta) {
		super.update(delta);

		if (window.pausedAnim) return;


		PageLoader.update(0);

		var now = performance.now(),
			opt = this.options;


		AudioController.preloadEditor();
		AudioController.update();

		// if (!this.resyncing && !this.ranges.currentTime.ui.dragging) {

		if (!AudioController.resyncing && !this.ranges.progress.ui.dragging) {
			opt.currentTime = Utils.zeroPad(Math.floor(AudioController.getCurrentTime()/60),2) + ":"+Utils.zeroPad(Math.floor(AudioController.getCurrentTime())%60,2);
			DreamUI.triggerProperty(opt, "currentTime");
		// }
			opt.progress = Utils.ccmap(AudioController.getCurrentTime(), Utils.timecodeToSeconds(opt.sequence.timeline.timeStart), Utils.timecodeToSeconds(opt.sequence.timeline.timeEnd), 0.0, 1.0);
			DreamUI.triggerProperty(opt, "progress");
		}

		this.resyncing = false;


		//
		// Update loading : make sure all svg are loaded before proceding
		//
		if (this.shouldReloadFrames) {
			this.shouldReloadFrames = false;
			if (this.sequence) this.sequence.dispose();
			this.sequence = new Sequence(0, {preset:"none", id:"editor"});
			this.sequence.options = this.options.sequence;


			var batchName = "sequence"+Utils.generateUUID();
			Loader.createBatch(batchName)
			this.sequence.loadAssets(batchName, Loader);
			Loader.start();
		}

		//transition sequence
		if (this.shouldReloadFramesTransition) {
			this.shouldReloadFramesTransition = false;
			if (this.transitionSequence) this.transitionSequence.dispose();
			this.transitionSequence = new Sequence(1, {preset:opt.transitionPreset, id:"editor"});
			this.transitionSequence.isTransitionSequence = true;

			((seq) => {
				var batchName = "preload_transition_sequence"+Utils.generateUUID();
				Loader.createBatch(batchName);
				seq.preloadData(batchName);
				Loader.setBatchCompleteCallback(batchName, () =>{
					if (this.transitionSequence == seq) {
						var batchName = "transition_sequence"+Utils.generateUUID();
						Loader.createBatch(batchName);
						seq.preload(batchName, Loader);
						Loader.start();
					}
				})
				Loader.start();
			})(this.transitionSequence);	
		}


		//
		// Update playback
		//
		var timeDelta = (now - this.lastTime) / 1000;
		this.lastTime = now;

		this.ready = false;
		if (this.sequence.isReady()) {
			this.ready = true;


			this.sequence.duration = Utils.timecodeToSeconds(opt.sequence.timeline.timeEnd)-Utils.timecodeToSeconds(opt.sequence.timeline.timeStart);
			
			//begin transition
			if (this.transitionSequence && this.transitionSequence.isReady() && AudioController.getCurrentTime()-Utils.timecodeToSeconds(opt.sequence.timeline.timeStart) >= (this.sequence.duration - opt.sequence.transition.duration)) {

				if (!this.sequence.transitionStarted) {
					if (this.transitionSequence) this.transitionSequence.update(0,0,0, true);
					this.sequence.beginTransition(this.transitionSequence, {});
				}
				if (this.transitionSequence && !this.transitionSequence.transitionStarted) {
					this.transitionSequence.update(0,0,0, true);
					this.transitionSequence.beginTransition(this.sequence, {}, true);
				}
				this.ranges.progress.ui.setColor('#aa3333');
			} else {
				if (this.sequence.transitionStarted) this.sequence.endTransition();
				this.ranges.progress.ui.setColor('#2bbab6');
				if (this.sequence.transitionStarted) {
					this.sequence.endTransition();
				}
				if (this.transitionSequence && this.transitionSequence.transitionStarted) {
					this.transitionSequence.endTransition();
				}
			}

			SequenceRenderer.update(this.options.sequence, 0.0, delta);
			this.sequence.update(AudioController.getCurrentTime()-Utils.timecodeToSeconds(opt.sequence.timeline.timeStart), opt.progress, delta);

			//update ui
			// if (Math.abs(this.sequence.duration - this.ranges.sequence.transition.duration.max) > 0.01) {
			// 	this.ranges.sequence.transition.duration.max = this.sequence.duration;
			// 	DreamUI.triggerProperty(this.ranges.sequence.transition.duration, 'max');
			// }



			// if (this.options.sequence.installation.connected) {
			// 	if (!this.sequence.options.installation.defaultColors) {
			// 		HueController.customColor.set(this.sequence.options.installation.color);
			// 		HueController.customColorEnabled = true;
			// 	} else {
			// 		HueController.customColorEnabled = false;
			// 	}
			// 	HueController.setup();
			// 	HueController.update(delta);
			// }
		}



		if (opt.sequence.brush.enabled) {

			if (SequenceRenderer.pressedThisFrame) {
				this.selectingBrush = (SequenceRenderer.rawPosition.x*2.0 < -0.5 && SequenceRenderer.rawPosition.y*2.0 > 0.5);
			}

			if (SequenceRenderer.isDragging && this.selectingBrush) { //this.currentPosition.x < -0.5 && this.currentPosition.y > 0.5) {
				// selectingSource = true;
				opt.sequence.brush.sourceX = Utils.ccmap(SequenceRenderer.rawPosition.x*2.0, -1, -0.5, 0.0, 1.0);
				opt.sequence.brush.sourceY = Utils.ccmap(SequenceRenderer.rawPosition.y*2.0, 0.5, 1.0, 1.0, 0.0);

				opt.sequence.brush.sourceX = (opt.sequence.brush.sourceX - 0.5) * this.sequence.brushRatio.x + 0.5 + opt.sequence.brush.sourceSize * this.sequence.brushRatio.x;
				opt.sequence.brush.sourceY = (opt.sequence.brush.sourceY - 0.5) * this.sequence.brushRatio.y + 0.5 + opt.sequence.brush.sourceSize * this.sequence.brushRatio.y;
				DreamUI.update(opt.sequence.brush, 'sourceX');
				DreamUI.update(opt.sequence.brush, 'sourceY');
			}
			this.sourceDisplayMaterial.uniforms.size.value = opt.sequence.brush.sourceSize;
			this.sourceDisplayMaterial.uniforms.source.value.set(
					opt.sequence.brush.sourceX - SequenceRenderer.rawPosition.x*opt.sequence.brush.deplacement,
					opt.sequence.brush.sourceY - SequenceRenderer.rawPosition.y*opt.sequence.brush.deplacement);
			this.sourceDisplayMaterial.uniforms.tDiffuse.value = this.sequence.brushTexture;
		}


		if (SETTINGS.ELASTIC_MODE) {
			InteractionController.ELASTIC_TIME = this.options.elastic.time;
			InteractionController.ELASTIC_BOUNCES = this.options.elastic.bounces;
			InteractionController.ELASTIC_PRESS_CURVE = this.options.elastic.pressCurve;
			InteractionController.ELASTIC_RELEASE_CURVE = this.options.elastic.releaseCurve;
		}



		//------------------
		//
		// Get start shape
		//
		//------------------
		// if (!opt.elastic) {
		// 	this.pressPc = Utils.deltaSmoothingSnap2(this.pressPc, this.isDragging?1.0:0.0, 0.05, delta);
		// } else {
		// 	if (this.isDragging) {
		// 		this.pressPcSpeed = Utils.deltaSmoothingSnap2(this.pressPcSpeed, this.isDragging?1.0:0.0, 0.05, delta);
		// 		this.pressPc = Utils.deltaSmoothingSnap2(this.pressPc, Easing['Sinusoidal'].In(this.pressPcSpeed), 0.5, delta);
		// 	} else {
		// 		this.pressPcSpeed = Utils.deltaSmoothingSnap2(this.pressPcSpeed, this.isDragging?1.0:0.0, 0.005 + this.pressPcSpeed * 0.01, delta);
		// 		this.pressPc = Utils.deltaSmoothingSnap2(this.pressPc, Easing['Elastic'].In(this.pressPcSpeed), 0.5, delta);
		// 	}
		// }


		//
		// Update ui
		//
		HueController.update(delta);
		if (HueController.enabled) DreamUI.triggerProperty(this.options.sequence.installation, "color");
		if (HueController.enabled) DreamUI.triggerProperty(this.options.sequence.installation, "colorB");
		DreamUI.triggerUpdates();
		SequenceRenderer.pressedThisFrame = false;
	}


	render() {
		super.render();

		renderer.setRenderTarget(null);
		renderer.setClearColor(0xffffff,1);
		renderer.clear();

		if (this.ready) {
			SequenceRenderer.render();
			if (this.options.sequence.brush.enabled && !SETTINGS.isMobile && this.uiBrushFolder.isOpen) renderer.render(this.sourceDisplayScene, Utils.orthographicCamera);
		}
		// renderer.render(this.scene, this.camera);
	}

	getTexture() {
		return this.fbo.texture.texture;
	}

	//cleanup everything
	dispose() {
		super.dispose();
		if (this.disposed) return;
		this.disposed = true;
	}
};

export default SceneEditor;	
