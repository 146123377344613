import $ from 'jquery';
import FastEvent from './FastEvent.js';
import Utils from './Utils.js';

/*

	<div class="ui-item">
        <div class="ui-color-code"></div>
        <div class="ui-label">input checkbox</div>
        <input class="ui-te-input" type="text" value=""></input>
    </div>

*/
function TextInput(UI) {
	if (!UI.enabled) return;

	//
	// Create the div hierarchy
	//
	var containerDiv = document.createElement('div'),
		colorCodeDiv = document.createElement('div'),
		labelDiv = document.createElement('div'),
		textDiv = document.createElement('input');
	this.domElement = containerDiv;



	containerDiv.appendChild(colorCodeDiv);
	containerDiv.appendChild(labelDiv);
	containerDiv.appendChild(textDiv);

	//
	// Set the classes and basic properties
	//
	$(containerDiv).addClass('ui-item');
	$(colorCodeDiv).addClass('ui-color-code');
	$(labelDiv).addClass('ui-label');
	$(textDiv).addClass('ui-text-input');

	textDiv.type = 'text';
	textDiv.value = '';


	//
	// props
	//
	this.uuid = Math.floor(Math.random() * 100000000000).toFixed(0);
	this.value = false;
	this.propName = '';
	this.toWatch = {};
	this.path = [];
	this.changing = false;
	this.isNumber = false;


	//object.observe change
	this.updateValue = function(evt, changes) {
		//prevent double changes / infinite loops
		if (this.changing)
			return;


		this.changing = true;

		//
		// update values pos
		//
		var lv = this.value;
		this.value = this.toWatch[this.propName];
		if (this.isNumber) {
			this.value = parseFloat(this.toWatch[this.propName],10);
			if (isNaN(this.value) || !this.value) this.value = 0.0;
		}

		if (lv !== this.value) {
			this.shouldUpdateUI = true;
			this.updateUI();			
			if (this.callback) this.callback.apply(this, [this.value]);
		}
		this.changing = false;

	}.bind(this);


	
	
	//
	// Update UI 
	//
	this.canUpdateUI = false;
	this.shouldUpdateUI = false;
	this.updateUI = function() {
	
		if (!this.canUpdateUI || !this.shouldUpdateUI) return;
			this.shouldUpdateUI = false;
			this.changing = true;
		textDiv.value = this.value;
		this.changing = false;

	}.bind(this);


	//
	// init the properties
	//
	this.init = function(toWatch, propName, props, path) {
		
		this.propName = propName;
		this.toWatch = toWatch;
		this.path = path;

		if (props && props.isNumber) {
			textDiv.type = 'number';
			this.isNumber = true;
		}

		this.updateValue(null, [{name:propName}]);
	
		$(labelDiv).text(propName);
		$(colorCodeDiv).css('background-color', props.colorCode || '#'+Math.floor(Math.random()*0xffffff).toString(16));

		UI.watchProperty(this.toWatch, this.propName, this.updateValue);
		this.callback = props ? props.callback : null;

	}.bind(this);


	//
	// Input change
	//
	$(textDiv).on('change',function(e) {
		if (this.changing) return;
		FastEvent.trigger(this.toWatch, 'ui-update');
		this.toWatch[this.propName] = textDiv.value;
		UI.triggerProperty(this.toWatch, this.propName);
	}.bind(this));

	//copy path name
	$(labelDiv).on('click', function(e) {
		if (e.altKey) {
			Utils.copyToClipboard(labelDiv, this.path.concat(this.propName).join('.'));
		}
	}.bind(this));
	
	//
	// Clean up
	//
	this.dispose = function() {

		UI.unwatchProperty(this.toWatch, this.propName, this.updateValue);

		this.callback = null;
		$(containerDiv).off();
		$(colorCodeDiv).off();
		$(labelDiv).off();
		$(textDiv).off();

		$(containerDiv).remove();
		$(colorCodeDiv).remove();
		$(labelDiv).remove();
		$(textDiv).remove();

		this.domElement = null;
		containerDiv = null;
		colorCodeDiv = null;
		labelDiv = null;
		textDiv = null;
	};



};

export default TextInput;