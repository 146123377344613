import SETTINGS from '../Settings.js';
import AppStatus from './AppStatus.js';
import AudioController from './AudioController.js';
import Loader from "../loading/Loader.js";
import LoaderAudio from "../loading/LoaderAudio.js";

import Sequence from "../objects/Sequence.js";

var audioFolder = "audio/branching/";
var BRANCH_CROSSFADE = 0.05;
var EARLY_END_P2 = 10.0;


class BranchController {

	constructor() {

		//state
		this.currentBranch = 0;
		this.currentBranchState = 0;
		this.currentStateEndTime = 0;
		this.currentStateDuration = 0;

		this.earlyEndAudioStartTime = 0;
		this.earlyEndAudioEndTime = 0;

		this.branchStarted = false;
		this.branchStartTime = 0;
		this.stateStartTime = 0;
		this.audioIsPlaying = false;
		this.noAudio = false;

		this.mainSound = null;
		this.mainGain = null;
		this.autoButtonPress = false;


		//loading
		this.audioBuffersByName = {};
		this.audioLoadingByName = {};

		//info
		this.branchSequencesByPreset = {};
		this.sequences = [];
		this.currentBranchInfo = 0;
		this.currentSequence = null;
		this.hasDownloaded = false;

		//pausing
		this.soundPauseTimeInState = 0;
		this.branchPaused = false;
		this.hasBeenActive = false;
		this.hasBeenActiveCenter = false;
		this.hasPressed = false;

		//extras
		this.extraBuffers = [];
		this.currentExtra = 0;
		this.lastExtra = 0;
		this.extraPlaying = false;
		this.extraStartTime = 0;
		this.extraEndTime = 0;
		this.earlyEndSelectedState = 0;

	}


	preload(batchName, loader) {
		if (SETTINGS.EXTRAS_ENABLED) {
			for (var i = 1; i <40; i++) {
				this.extraBuffers.push(Loader.addAudio(batchName, 'audio/extras/extras_'+i+'_1.mp3', true));
			}
			this.extraBuffers = Utils.shuffle(this.extraBuffers);
		}
		// var seq = new Sequence();
		// seq.preloadData(batchName);
		// this.sequences.push(seq);

	}

	preloadTimeline(batchName, loader, sequence) {
		if (sequence.params.isBranch) {
			for (var i=0; i<5; i++) {
				if (sequence.params["audio"+i]) {
					var currentFile = sequence.params["audio"+i];
					this.audioBuffersByName[currentFile] = loader.addAudio(batchName, audioFolder+currentFile, true);
				}
			}
			this.sequences.push(sequence);
		}
	}

	preloadEditor() {

	}


	shouldSkipBranch(sequence) {
		var info = sequence.params;
		if (info.skipLocked) {
			return info.shouldSkip;
		}

		switch (info.branchId) {
			case "passive_0":
			case "passive_1":
				if (SETTINGS.SKIP_BRANCHES) return true;
				if (SETTINGS.ALL_BRANCHES) return false;
				return SequenceRenderer.passivePc < 0.95;
				break;

			case "early_end":
				if (SETTINGS.SKIP_BRANCHES) return true;
				if (SETTINGS.ALL_BRANCHES) return false;
				return AppStatus.SELECTED_DURATION_LONG;
				break;

			case "11_1_bouton":
				return false;
				break;

			case "download":
				if (SETTINGS.SKIP_BRANCHES) return true;
				return false;
				break;

			case "poke":
				return false;
				break;

			case "glisse":
				return false;
				break;
		}
	}

	getCurrentBranchBuffer() {
		switch (this.currentBranchInfo.branchId) {
			case "passive_0":
			case "passive_1":
				if (this.currentBranchState == 0) {
					return Loader.getAsset(audioFolder+this.currentBranchInfo.audio0).value.buffer;

				} else if (this.currentBranchState == 1) {
					return Loader.getAsset(audioFolder+this.currentBranchInfo.audio1).value.buffer;

				} else if (this.currentBranchState == 2) {
					return Loader.getAsset(audioFolder+this.currentBranchInfo.audio2).value.buffer;
				}
				break;

			case "early_end":
				if (this.currentBranchState == 0) {
					return Loader.getAsset(audioFolder+this.currentBranchInfo.audio0).value.buffer;

				} else if (this.currentBranchState == 1) {
					return Loader.getAsset(audioFolder+this.currentBranchInfo.audio2).value.buffer;

				} else if (this.currentBranchState == 2) {
					return Loader.getAsset(audioFolder+this.currentBranchInfo.audio1).value.buffer;
				}
				 else if (this.currentBranchState >= 4) {
					return Loader.getAsset(audioFolder+this.currentBranchInfo.audio3).value.buffer;
				}
				break;

			case "11_1_bouton":
				return Loader.getAsset(audioFolder+this.currentBranchInfo.audio0).value.buffer;
				break;

			case "download":
				if (this.currentBranchState == 0) {
					return Loader.getAsset(audioFolder+this.currentBranchInfo.audio0).value.buffer;
				}  else {
					return Loader.getAsset(audioFolder+this.currentBranchInfo.audio1).value.buffer;
				}
				break;

			case "glisse":
				return Loader.getAsset(audioFolder+this.currentBranchInfo.audio0).value.buffer;
				break;

			case "poke":
				return Loader.getAsset(audioFolder+this.currentBranchInfo.audio0).value.buffer;
				break;

		}
		console.log("wtf no id");
		return null;
	}

	shouldTransitionOut() {
		if (!this.branchStarted) return true;
		switch (this.currentBranchInfo.branchId) {
			case "passive_0":
			case "passive_1":
				return this.currentBranchState > 0 && this.getCurrentTime() >= this.currentStateEndTime-2.0;
				break;

			case "early_end":
				if (this.currentBranchState == 2) return true; //transition to preset 0 (early_end ah ok)

				if (this.currentBranchState == 3 && this.getCurrentTime() >= this.currentBranchInfo.preset_end_0_duration-2.0) return true;  //transition to preset 1 (merci)
				if (this.currentBranchState == 4 && this.getCurrentTime() >= this.currentStateEndTime-2.0) return true;  //transition to preset 1 (merci)

				if (this.currentBranchState == 5 && this.getCurrentTime() >= this.currentStateEndTime+this.currentBranchInfo.preset_end_1_duration-1.0) return true;  //transition to preset 2 (credits)
				if (this.currentBranchState == 6 && this.getCurrentTime() >= this.currentStateEndTime+this.currentBranchInfo.preset_end_1_duration+this.currentBranchInfo.preset_end_2_duration-1.0) return true;  //transition to final preset (menu)
				if (this.currentBranchState == 7) return false;
				return this.currentBranchState == 1 && this.getCurrentTime() >= this.currentStateEndTime-2.0; 
				break;

			case "11_1_bouton":
				// console.log("transition out", this.currentBranchState, this.getCurrentTime(), this.currentStateEndTime);

				if (this.currentBranchState==0) return false;
				if (this.currentBranchState==2) {
					// AppStatus.log("transition out state 2:",this.getCurrentTime(),this.currentStateEndTime, this.getCurrentTime() >= this.currentStateEndTime-1.0);
					return this.getCurrentTime() >= this.currentStateEndTime-1.0;
				}
				return (this.currentBranchState>0 && this.getCurrentTime() >= this.currentStateEndTime-0.5);
				break;

			case "download":
				return (this.getCurrentTime() >= this.currentStateEndTime-1.0);
				break;

			case "glisse":
				return (this.currentBranchState>0);
				break;

			case "poke":
				return (this.getCurrentTime() >= this.currentStateEndTime-1.0);
				break;

		}
		return true;
	}
	getTransitionPc() {
		if (!this.branchStarted) return 1;
		if (this.currentBranchInfo.branchId=='early_end') {

			if (this.currentBranchState == 2) return Utils.cmap(audioContext.currentTime - this.stateStartTime, 0, 2.0, 0.0, 1.0);

			if (this.currentBranchState == 3) return Utils.cmap(audioContext.currentTime - this.stateStartTime, this.currentBranchInfo.preset_end_0_duration-2.0, this.currentBranchInfo.preset_end_0_duration, 0.0, 1.0);

			if (this.currentBranchState == 4) return Utils.cmap(audioContext.currentTime - this.stateStartTime, this.currentStateEndTime-2.0, this.currentStateEndTime, 0.0, 1.0);
			if (this.currentBranchState == 5) return Utils.cmap(audioContext.currentTime - this.stateStartTime, this.currentStateEndTime+this.currentBranchInfo.preset_end_1_duration-1.0, this.currentStateEndTime+this.currentBranchInfo.preset_end_1_duration, 0.0, 1.0);
			if (this.currentBranchState == 6) return Utils.cmap(audioContext.currentTime - this.stateStartTime, this.currentStateEndTime+this.currentBranchInfo.preset_end_1_duration+this.currentBranchInfo.preset_end_2_duration-1.0, this.currentStateEndTime+this.currentBranchInfo.preset_end_1_duration+this.currentBranchInfo.preset_end_2_duration, 0.0, 1.0);
		}
		if (this.currentBranchInfo.branchId=='download') return Utils.cmap(audioContext.currentTime - this.stateStartTime, this.currentStateEndTime-1.0, this.currentStateEndTime, 0.0, 1.0);
		if (this.currentBranchInfo.branchId=='poke') return Utils.cmap(audioContext.currentTime - this.stateStartTime, this.currentStateEndTime-1.0, this.currentStateEndTime, 0.0, 1.0);
		if (this.currentBranchInfo.branchId=='11_1_bouton' && (this.currentBranchState==2||(this.currentBranchState==0&&this.hasPressed))) return Utils.cmap(audioContext.currentTime - this.stateStartTime, this.currentStateEndTime-1.0, this.currentStateEndTime, 0.0, 1.0);
		if (this.currentBranchInfo.branchId=='11_1_bouton') return Utils.cmap(audioContext.currentTime - this.stateStartTime, this.currentStateEndTime-0.5, this.currentStateEndTime, 0.0, 1.0);
		if (this.currentBranchInfo.branchId=='glisse' && this.currentBranchState==2) return Utils.cmap(audioContext.currentTime - this.stateStartTime, this.currentStateEndTime-1.0, this.currentStateEndTime, 0.0, 1.0);
		if (this.currentBranchInfo.branchId=='glisse' && this.currentBranchState==1) return Utils.cmap(audioContext.currentTime - this.stateStartTime, 0, this.currentStateEndTime, 0.0, 1.0);

		return Utils.cmap(audioContext.currentTime - this.stateStartTime, this.currentStateEndTime-2.0, this.currentStateEndTime, 0.0, 1.0);
	}


	playAudio() {

		console.log("Start audio", this.branchPaused, this.noAudio, this.soundPauseTimeInState);

		//get current branch state
		// if (this.currentBranchInfo.branchId=='early_end'&& this.currentBranchState == 4) return;

		//start branch audio
		var mainGain = audioContext.createGain();
		mainGain.gain.tweenToValue(0.0, 0.0);
		mainGain.gain.tweenToValue(AudioController.volume, BRANCH_CROSSFADE);
		mainGain.connect(audioContext.destination);

		var buf = this.getCurrentBranchBuffer();

		var startOffset = 0;
		var duration = buf.duration;
		if (this.currentBranchInfo.branchId !== "poke") this.stateStartTime = audioContext.currentTime;

		//if was paused
		if (this.branchPaused && !this.noAudio) {
			startOffset = this.soundPauseTimeInState;
			duration -= startOffset;
			this.stateStartTime -= startOffset;
			this.branchPaused = false;
			console.log("state start time", this.stateStartTime, audioContext.currentTime);
		}

		var mainSound = audioContext.createBufferSource();
		mainSound.loop = false;
		mainSound.connect(mainGain);
		mainSound.buffer = buf;
		mainSound.start(audioContext.currentTime, startOffset, duration);
		if (this.currentBranchInfo.branchId !== "poke") this.currentStateEndTime = buf.duration - 0.1; //
		if (this.currentBranchInfo.branchId == "early_end" && this.currentBranchState<3) this.currentStateEndTime = buf.duration - 1.0;


		this.mainSound = mainSound;
		this.mainGain = mainGain;
	}

	stopAudio() {
		if (this.mainGain) {
			this.mainGain.gain.tweenToValue(0.0, BRANCH_CROSSFADE);
			AudioDisposeController.dispose(this.mainSound, BRANCH_CROSSFADE);
			AudioDisposeController.dispose(this.mainGain, BRANCH_CROSSFADE);
			this.mainGain = this.mainSound = null;
		}
	}

	getCurrentTime() {
		if (!this.branchStarted) return 0;
		return audioContext.currentTime - this.stateStartTime;
	}
	getDuration() {
		switch (this.currentBranchInfo.branchId) {
			case "passive_0":
			case "passive_1":
				var duration = 0.0;
				
				var a0 = Loader.getAsset(audioFolder+this.currentBranchInfo.audio0);
				var a1 = Loader.getAsset(audioFolder+this.currentBranchInfo.audio1);
				var a2 = Loader.getAsset(audioFolder+this.currentBranchInfo.audio2);

				if (this.currentBranchState == 0) {
					
					if (a0 && a0.value && a0.value.buffer) duration += a0.value.buffer.duration;
					if (a1 && a1.value && a1.value.buffer) duration += a1.value.buffer.duration;

				} else if (this.currentBranchState == 1) {
					if (a0 && a0.value && a0.value.buffer) duration += a0.value.buffer.duration;
					if (a1 && a1.value && a1.value.buffer) duration += a1.value.buffer.duration;

				} else if (this.currentBranchState == 2) {
					if (a0 && a0.value && a0.value.buffer) duration += a0.value.buffer.duration;
					if (a2 && a2.value && a2.value.buffer) duration += a2.value.buffer.duration;
				}
				return duration;
				break;

			case "early_end":
				var duration = 0.0;
				
				var a0 = Loader.getAsset(audioFolder+this.currentBranchInfo.audio0);
				var a1 = Loader.getAsset(audioFolder+this.currentBranchInfo.audio1);
				var a2 = Loader.getAsset(audioFolder+this.currentBranchInfo.audio2);

				if (this.currentBranchState == 0) {
					
					if (a0 && a0.value && a0.value.buffer) duration += a0.value.buffer.duration;
					if (a1 && a1.value && a1.value.buffer) duration += a2.value.buffer.duration;

				} else if (this.currentBranchState == 1) {
					if (a0 && a0.value && a0.value.buffer) duration += a0.value.buffer.duration;
					if (a1 && a1.value && a1.value.buffer) duration += a2.value.buffer.duration;

				} else if (this.currentBranchState >= 2) {
					if (a0 && a0.value && a0.value.buffer) duration += a0.value.buffer.duration;
					if (a2 && a2.value && a2.value.buffer) duration += a1.value.buffer.duration;
				}
				return duration;
				break;

			case "11_1_bouton":
				return false;
				break;

			case "download":
				if (this.currentBranchState == 0) {
					return Loader.getAsset(audioFolder+this.currentBranchInfo.audio0).value.buffer.duration;
				}  else {
					return Loader.getAsset(audioFolder+this.currentBranchInfo.audio1).value.buffer.duration;
				}
				break;

			case "glisse":
				return false; //this.currentDuration;
				// return Loader.getAsset(audioFolder+this.currentBranchInfo.audio0).value.buffer;
				break;

			case "poke":
				return this.currentDuration;
				break;

		}
		return null;
	}
	getCurrentProgress() {
		if (!this.branchStarted) return 0;
		return Utils.cmap(audioContext.currentTime - this.stateStartTime, 0, this.currentStateEndTime, 0.0, 1.0);
	}


	currentSequenceIsEarlyEnd(id) {
		if (!this.branchStarted) return false;
		return this.currentBranchInfo.branchId == 'early_end' && this.currentBranchState == 3+id;
	}
	nextSequenceIsEarlyEnd(id) {
		if (!this.branchStarted) return false;
		return this.currentBranchInfo.branchId == 'early_end' && this.currentBranchState == 2+id;
	}


	currentSequenceIsEarlyEndAudio() {
		if (!this.branchStarted) return false;
		return this.currentBranchInfo.branchId == 'early_end' && this.currentBranchState >= 5 && (audioContext.currentTime - this.earlyEndAudioStartTime) >= this.earlyEndAudioEndTime;
	}
	nextSequenceIsEarlyEndAudio() {
		if (!this.branchStarted) return false;
		return this.currentBranchInfo.branchId == 'early_end' && this.currentBranchState >= 5 && (audioContext.currentTime - this.earlyEndAudioStartTime) >= this.earlyEndAudioEndTime-5.0  && (audioContext.currentTime - this.earlyEndAudioStartTime) < this.earlyEndAudioEndTime;
	}
	earlyEndAudioTransitionPc() {
		return Utils.cmap((audioContext.currentTime - this.earlyEndAudioStartTime), this.earlyEndAudioEndTime-5.0, this.earlyEndAudioEndTime, 0, 1);
	}


	showEarlyEndDivs() {
		this.earlyEndSelectedState = 0;
		$('#early-end-buttons-container').css('opacity',0).css('display','flex').animate({ opacity: 1 }, 300);
		$('#main').css('cursor', 'auto');

		$('#early-end-continue').toggleClass('pressed',false);
		$('#early-end-stop').toggleClass('pressed',false);

		$('#early-end-continue').on('click',(e)=>{
			if (this.earlyEndSelectedState==1) {
				this.earlyEndSelectedState = 0;
				$('#early-end-continue').toggleClass('pressed',false);
				$('#early-end-stop').toggleClass('pressed',false);
			} else {
				this.earlyEndSelectedState = 1;
				$('#early-end-continue').toggleClass('pressed',true);
				$('#early-end-stop').toggleClass('pressed',false);
			}
		});
		$('#early-end-stop').on('click',(e)=>{
			if (this.earlyEndSelectedState==2) {
				this.earlyEndSelectedState = 0;
				$('#early-end-continue').toggleClass('pressed',false);
				$('#early-end-stop').toggleClass('pressed',false);
			} else {
				this.earlyEndSelectedState = 2;
				$('#early-end-continue').toggleClass('pressed',false);
				$('#early-end-stop').toggleClass('pressed',true);
			}
			
		});

		// $('#early-end-continue').on('click',(e)=>{
		// 	this.currentBranchState = 2;
		// 	this.stopAudio();
		// 	this.playAudio();
		// 	$('#early-end-continue').off('click');
		// 	$('#early-end-stop').off('click');
		// 	$('#early-end-buttons-container').fadeOut(300);
		// 	$('#main').css('cursor', 'none');
		// });
		// $('#early-end-stop').on('click',(e)=>{
		// 	this.currentBranchState = 1;
		// 	this.stopAudio();
		// 	this.playAudio();
		// 	$('#early-end-continue').off('click');
		// 	$('#early-end-stop').off('click');
		// 	$('#early-end-buttons-container').fadeOut(300);
		// 	$('#main').css('cursor', 'none');
		// });
	}


	update(delta) {

		//not in branch :: check to start branch within timecode
		if (!this.branchStarted && !this.extraPlaying)  {

			if (AudioController.audioPlaying && AudioController.audioShouldPlay) {

				var ct = AudioController.getCurrentTime();
				// console.log(ct, this.sequences[0].timecode);


				//branch start check
				for (var i = 0; i <this.sequences.length; i++) {
					if (ct >= this.sequences[i].timecode - 0.06 && ct < this.sequences[i].timecode+1.0) {
						// console.log("Should play this branch",this.sequences[i].branchId, ct, this.sequences[i].timecode)
						if (!this.sequences[i].playedBranch) {
							this.currentBranchInfo = this.sequences[i].params;
							this.currentSequence = this.sequences[i];

							this.sequences[i].playedBranch = true;
							if (!this.shouldSkipBranch(this.currentSequence)) {

								console.log("Start branch",this.currentBranchInfo.branchId);

								//set state
								this.currentBranch = i;
								this.branchStarted = true;
								this.audioIsPlaying = true;
								this.currentBranchState = 0;
								this.hasBeenActive = false;
								this.hasPressed = false;
								this.autoButtonPress = false;

								AnalyticsController.trackEvent("start_branch_"+this.currentBranchInfo.branchId);


								//select state 0 or 1 for download
								// if (this.hasDownloaded) {
								// conso
								// console.log("HAS DOWNLOADED?",this.sequences[i].branchId == 'download', this.hasDownloaded);
								if (this.sequences[i].branchId == 'download') this.currentBranchState = this.hasDownloaded ? 0 : 1;
								// console.log("HAS DOWNLOADED -> ",this.currentBranchState);

								//show end divs
								if (this.sequences[i].branchId == "early_end") this.showEarlyEndDivs();
								
								this.branchStartTime = audioContext.currentTime;

								//stop main timeline
								if (this.sequences[i].branchId !== "11_1_bouton" && this.sequences[i].branchId !== 'glisse' && this.sequences[i].branchId !== 'poke') {
									AudioController.pauseAudioForBranch(this.sequences[i].timecode);
									this.stopAudio();
									this.playAudio();
									this.audioIsPlaying = true;
									this.noAudio = false;
								} else {
									this.stateStartTime = audioContext.currentTime;
									this.currentStateEndTime = this.sequences[i].duration;
									this.noAudio = true;
								}
								this.currentDuration = this.sequences[i].duration;

								i = this.sequences.length;
								break;
							} else {
								console.log("Skip branch",this.currentBranchInfo.branchId);
								if (this.currentBranchInfo.skipTime) {
									AudioController.seek(AudioController.getCurrentTime()+this.currentBranchInfo.skipTime);
								}
							}
						}
					} else if (this.sequences[i].playedBranch && ct > this.sequences[i].timecode+3.0) {
						console.log("reset branch played", this.sequences[i].branchId);
						this.sequences[i].playedBranch = false; //reset
						this.sequences[i].params.skipLocked = false;
						this.sequences[i].skipLocked = false;
					}
				}


				//extras start check
				if (SETTINGS.EXTRAS_ENABLED && !this.branchStarted) {
					var currentWordVolume = (AudioController.audioData[3]+AudioController.audioData[4]+AudioController.audioData[5]+AudioController.audioData[6]+AudioController.audioData[7]+AudioController.audioData[8]) / 256 / 6;
					// AppStatus.log(SequenceRenderer.currentDragSpeed.length());
					if ((SequenceRenderer.multiClickPc >= 0.95 || SequenceRenderer.pressPc>=0.98 || SequenceRenderer.currentDragSpeed.length()>=0.4) && currentWordVolume<0.15) {
						// console.log(currentWordVolume, AudioController.audioData);

						this.currentExtra = (this.currentExtra+1) % this.extraBuffers.length;

						AudioController.pauseAudioForBranch();

						var buf = this.extraBuffers[this.currentExtra].value.buffer;

						var duration = buf.duration;
						if (buf.duration >= 2.0) duration = buf.duration-1.0;
						else duration = buf.duration-0.5;

						var extraGain = audioContext.createGain();
						extraGain.gain.setValueAtTime(0.0, audioContext.currentTime);
						extraGain.gain.linearRampToValueAtTime(AudioController.volume, audioContext.currentTime+BRANCH_CROSSFADE);
						extraGain.gain.linearRampToValueAtTime(AudioController.volume, audioContext.currentTime+BRANCH_CROSSFADE+duration-0.5);
						extraGain.gain.linearRampToValueAtTime(0.0, audioContext.currentTime+BRANCH_CROSSFADE+duration);
						extraGain.connect(AudioController.finalGain);

						var duration = buf.duration;

						var extraSound = audioContext.createBufferSource();
						extraSound.loop = false;
						extraSound.connect(extraGain);
						extraSound.buffer = buf;
						extraSound.start(audioContext.currentTime);

						AudioDisposeController.dispose(extraSound,audioContext.currentTime + duration);
						AudioDisposeController.dispose(extraGain,audioContext.currentTime + duration);


						this.extraPlaying = true;
						this.extraEndTime = audioContext.currentTime + duration-0.2;
						this.lastExtra = performance.now();
					}
				}
			}

		} else if (this.extraPlaying) {

			if (audioContext.currentTime >= this.extraEndTime && AudioController.audioShouldPlay) {
				this.extraPlaying = false;
				AudioController.endBranching();
			}

		} else {

			//in branch :: pause audio from button/buffering
			if (this.audioIsPlaying && !AudioController.audioShouldPlay) {
				console.log("branch paused");
				this.soundPauseTimeInState = audioContext.currentTime-this.stateStartTime;
				this.audioIsPlaying = false;
				this.stopAudio();
				this.branchPaused = true;

			//in branch :: restart audio at last time
			} else if (!this.audioIsPlaying && !this.noAudio && AudioController.audioShouldPlay) {
				console.log("Branch restart");
				this.audioIsPlaying = true;
				this.playAudio();
				this.branchPaused = false;
			//in branch :: stop :: audio controller seeked outside of branch?

			//branch is playing :: play sound buffers, wait for specified time, play next sound buffer, stop when done
			} else if ((this.audioIsPlaying||this.noAudio) && AudioController.audioShouldPlay) {

				//activity check
				// if (SequenceRenderer.pressedThisFrame || SequenceRenderer.currentDragSpeed.length()>=0.05 || SequenceRenderer.pressPc >= 0.33) {
				// 	this.hasBeenActive = true;
				// }
				this.hasBeenActive = SequenceRenderer.passivePc < 0.95;
				if (SequenceRenderer.pressedThisFrame  && (!this.branchId == "11_1_bouton" || SequenceRenderer.rawPosition.length() <0.15)) {
					console.log("HAS PRESSED!");
					this.hasPressed = true;
				}

				//branch state advance
				switch (this.currentBranchInfo.branchId) {
					case "passive_0":
					case "passive_1":
						if (this.currentBranchState == 0) {

							if (this.getCurrentTime() >= this.currentStateEndTime) {
								console.log("state advance 0");
								if (this.hasBeenActive) {
									console.log("Branch advance from state 0 to 1", this.hasBeenActive);
									this.currentBranchState = 1;
								} else {
									console.log("Branch advance from state 0 to 2", this.hasBeenActive);
									this.currentBranchState = 2;
								}
								this.playAudio();
							}
						} else if (this.currentBranchState == 1 || this.currentBranchState == 2) {
							if (this.getCurrentTime() >= this.currentStateEndTime) {
								console.log("state advance 1");
								console.log("Branch end", audioContext.currentTime, this.getCurrentTime(), this.currentStateEndTime);
								this.branchStarted = false;
								this.audioIsPlaying = false;
								AudioController.endBranching();
							}
						}
						break;

					case "early_end":

						// console.log(this.currentBranchState, this.getCurrentTime());
						if (this.currentBranchState == 0) {

							var skip = false;
							if (this.earlyEndSelectedState>0) {
								if (SETTINGS.LANGUAGE === 'fr') {
									if (this.getCurrentTime() >= 3.9 && this.getCurrentTime() <= 4.05) skip = true;
									if (this.getCurrentTime() >= 8.4 && this.getCurrentTime() <= 8.55) skip = true;
									if (this.getCurrentTime() >= 12) skip = true;
								} else {
									if (this.getCurrentTime() >= 3.85 && this.getCurrentTime() <= 4.0) skip = true;
									if (this.getCurrentTime() >= 8.4 && this.getCurrentTime() <= 8.55) skip = true;
									if (this.getCurrentTime() >= 12.75) skip = true;
								}
							}
							if (SETTINGS.ALL_BRANCHES) {skip = false;}

							// console.log(this.getCurrentTime(), this.currentStateEndTime);

							//continue
							var endOffset = SETTINGS.LANGUAGE === 'fr' ? -0.05 : 4.0;
							if (this.getCurrentTime() >= this.currentStateEndTime + 10.0 || skip || (this.getCurrentTime() >= this.currentStateEndTime+endOffset && this.earlyEndSelectedState>0)) {
								// console.log("Waiting for click");
								// console.log("next",this.earlyEndSelectedState,this.getCurrentTime(),this.currentStateEndTime)
								this.currentBranchState = this.earlyEndSelectedState>0?this.earlyEndSelectedState:1; //default to continue after 10 seconds
								
								AnalyticsController.trackEvent("early_end_selected_"+(this.currentBranchState==0?'auto':this.currentBranchState==1?'continue':'stop'));

								if (this.currentBranchState==1) $('#early-end-continue').toggleClass('pressed',true);
								this.stopAudio();
								this.playAudio();
								$('#early-end-continue').off('click');
								$('#early-end-stop').off('click');
								$('#early-end-buttons-container').fadeOut(300);
								$('#main').css('cursor', 'none');
							}

						//finished "continue" branch
						} else if (this.currentBranchState == 1) {
							if (this.getCurrentTime() >= this.currentStateEndTime) {
								AnalyticsController.trackEvent("5min_ending", "continuer");
								console.log("state advance 1");
								console.log("Branch end", audioContext.currentTime, this.currentStateEndTime);
								this.branchStarted = false;
								this.audioIsPlaying = false;
								AudioController.endBranching();
								AppStatus.SELECTED_DURATION_LONG = true;
								StorageController.setState(AudioController.getCurrentTime());
							}

						//selected "end", transition to preset 0 (early_end)
						} else if (this.currentBranchState == 2) {
							
							if (this.getCurrentTime() >= 2.0) {
								AppStatus.SELECTED_DURATION_LONG = false;
								AppStatus.finished = true;
								StorageController.clearState();
								AnalyticsController.trackEvent("5min_ending", "arreter");
								console.log("early end state 3");
								this.currentBranchState = 3;
							}

						//selected "end", show first preset, transition to preset 1 (merci)
						} else if (this.currentBranchState == 3) {

							if (this.getCurrentTime() >= this.currentBranchInfo.preset_end_0_duration) {
								console.log("early end state 4, preset 0a");
								this.currentBranchState = 4;
							}

						} else if (this.currentBranchState == 4) {

							// if (this.getCurrentTime() >= this.currentStateEndTime) {
							// 	console.log("early end state 4");
							// 	this.currentBranchState = 4;
							// 	var start = this.stateStartTime; // = audioContext.currentTime;
							// 	var end = this.currentStateEndTime;// = this.sequences[i].duration-0.1;
							// 	this.playAudio();
							// 	this.stateStartTime = start;
							// 	this.stateEndTime = end;
							// }
							if (this.getCurrentTime() >= this.currentStateEndTime) {
								console.log("early end state 5, preset 1");
								this.currentBranchState = 5;
								var start = this.stateStartTime; // = audioContext.currentTime;
								var end = this.currentStateEndTime;// = this.sequences[i].duration-0.1;
								this.playAudio();
								this.earlyEndAudioStartTime = audioContext.currentTime;
								this.earlyEndAudioEndTime = this.currentStateEndTime+0.5;
								this.stateStartTime = start;
								this.currentStateEndTime = end;
							}

						//finished "end" branch, show preset 2 (merci)
						} else if (this.currentBranchState == 5) {

							if (this.getCurrentTime() >= this.currentStateEndTime+this.currentBranchInfo.preset_end_1_duration) {
								console.log("early end state 6, preset 2");
								this.currentBranchState = 6;
							}

						//finished "end" branch, show preset 3 (credits)
						}  else if (this.currentBranchState == 6) { //

							if (this.getCurrentTime() >= this.currentStateEndTime+this.currentBranchInfo.preset_end_1_duration+this.currentBranchInfo.preset_end_2_duration) {
								console.log("early end state 7, preset 3");
								this.currentBranchState = 7;
							}

						//finished "end" branch, show final preset (theEnd)
						}  else if (this.currentBranchState == 7) {

						}
						break;

					case "11_1_bouton":
						//stop audio
						if (this.currentBranchState == 0) {
							// console.log("current button state 0", this.hasPressed, this.getCurrentTime(), this.currentStateEndTime);
							if (SETTINGS.SKIP_BRANCHES)  this.hasPressed = true;
							if (SETTINGS.ALL_BRANCHES) this.hasPressed = false;
							if (this.getCurrentTime() >= this.currentStateEndTime || this.hasPressed) {
								if (!this.hasPressed) {
									console.log("auto press state ",1, this.getCurrentTime(), this.currentStateEndTime);
									this.currentBranchState = 1;
									AudioController.pauseAudioForBranch();
									this.stopAudio();
									this.playAudio();
									this.audioIsPlaying = true;
									this.autoButtonPress = true;
								} else {
									console.log("has pressed, state advance 0 to 2");
									this.currentBranchState = 2;
								}
							}
							// if (!this.hasPressed &&-2.0) {
							// 	console.log("Set state 1, has pressed",this.getCurrentTime(), this.currentStateEndTime);
							// } else if (this.hasPressed && this.getCurrentTime() >= this.currentStateEndTime) {
							// 	console.log("Set state 1, has pressed");
							// 	this.currentBranchState = 1;
							// 	AudioController.pauseAudioForBranch();
							// 	this.stopAudio();
							// 	this.playAudio();
							// 	this.audioIsPlaying = true;
							// }
						} else if (this.currentBranchState == 1) {
							if (this.getCurrentTime() >= this.currentStateEndTime) {
								console.log("state advance 1");
								console.log("Branch end state 1", audioContext.currentTime, this.currentStateEndTime);
								this.branchStarted = false;
								this.audioIsPlaying = false;
								AudioController.endBranching();
							}
						} else if (this.currentBranchState == 2) {
							if (this.getCurrentTime() >= this.currentStateEndTime) {
								console.log("Branch end state 2", audioContext.currentTime-this.stateStartTime, this.currentStateEndTime);
								this.branchStarted = false;
								this.audioIsPlaying = false;
							}
						} else {
							console.log("branch state error:")
						}
						break;

					case "poke":
						if (this.currentBranchState == 0) {
							if (SETTINGS.SKIP_BRANCHES) SequenceRenderer.passivePc = 0;
							if (SequenceRenderer.passivePc < 0.75 && this.getCurrentTime() >= this.currentStateEndTime-2.00) {
								this.currentBranchState = 2;
								console.log("Set state 2, has pressed");
							} else if (this.getCurrentTime() >= this.currentStateEndTime-2.00) {
								console.log("Set state 1, has not pressed", this.getCurrentTime());
								this.currentBranchState = 1;

								var endTime = this.currentStateEndTime;
								// AudioController.pauseAudioForBranch();
								this.stopAudio();
								this.playAudio();
								this.audioIsPlaying = false;
								this.currentStateEndTime = endTime;
								
							}
						} else if (this.currentBranchState == 1) {
							if (this.getCurrentTime() >= this.currentStateEndTime) {
								console.log("state advance 1");
								console.log("Branch end state 1", audioContext.currentTime, this.currentStateEndTime);
								this.branchStarted = false;
								this.audioIsPlaying = false;
								// this.noAudio = false;
								// AudioController.endBranching();
							}
						} else if (this.currentBranchState == 2) {
							if (this.getCurrentTime() >= this.currentStateEndTime) {
								console.log("Branch end state 2", audioContext.currentTime, this.currentStateEndTime);
								this.branchStarted = false;
								this.audioIsPlaying = false;
							}
						}
						break;

					case "glisse":
						if (this.currentBranchState == 0) {
							if (SETTINGS.SKIP_BRANCHES) SequenceRenderer.passivePc = 0;
							if (SequenceRenderer.passivePc < 0.8 && this.getCurrentTime() >= this.currentStateEndTime-1.0) {
								this.currentBranchState = 2;
								console.log("Set state 2, has pressed");
							} else if (this.getCurrentTime() >= this.currentStateEndTime) {
								console.log("Set state 1, has not pressed");
								this.currentBranchState = 1;
								AudioController.pauseAudioForBranch();
								this.stopAudio();
								this.playAudio();
								this.audioIsPlaying = true;
								
							}
						} else if (this.currentBranchState == 1) {
							if (this.getCurrentTime() >= this.currentStateEndTime) {
								console.log("state advance 1");
								console.log("Branch end state 1", audioContext.currentTime, this.currentStateEndTime);
								this.branchStarted = false;
								this.audioIsPlaying = false;
								AudioController.endBranching();
							}
						} else if (this.currentBranchState == 2) {
							if (this.getCurrentTime() >= this.currentStateEndTime) {
								console.log("Branch end state 2", audioContext.currentTime, this.currentStateEndTime);
								this.branchStarted = false;
								this.audioIsPlaying = false;
							}
						}
						break;


					case "download":
						if (this.getCurrentTime() >= this.currentStateEndTime-0.05) {
							console.log("Branch end", audioContext.currentTime, this.currentStateEndTime);
							this.branchStarted = false;
							this.audioIsPlaying = false;
							AudioController.endBranching();
						}
						break;

				}

				// this.playAudio();
			
			}
			
		}
	}



};

window.BranchController = window.BranchController||new BranchController();
export default window.BranchController;