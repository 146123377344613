import $ from 'jquery';
import FastEvent from './FastEvent.js';
import Utils from './Utils.js';

/*

	<div class="ui-item">
        <div class="ui-color-code"></div>
        <div class="ui-label">input checkbox</div>
        <input class="ui-te-input" type="text" value=""></input>
    </div>

*/
function ColorPicker(UI) {
	if (!UI.enabled) return;

	//
	// Create the div hierarchy
	//
	var containerDiv = document.createElement('div'),
		colorCodeDiv = document.createElement('div'),
		labelDiv = document.createElement('div'),
		colorDiv = document.createElement('input');
	this.domElement = containerDiv;



	containerDiv.appendChild(colorCodeDiv);
	containerDiv.appendChild(labelDiv);
	containerDiv.appendChild(colorDiv);

	//
	// Set the classes and basic properties
	//
	$(containerDiv).addClass('ui-item');
	$(colorCodeDiv).addClass('ui-color-code');
	$(labelDiv).addClass('ui-label');
	$(colorDiv).addClass('ui-color-input');

	colorDiv.type = 'color';
	colorDiv.value = '#ffffff';

	//$(colorDiv).spectrum();


	//
	// props
	//
	this.uuid = Math.floor(Math.random() * 100000000000).toFixed(0);
	this.value = '#ffffff';
	this.propName = '';
	this.toWatch = {};
	this.path = [];
	this.changing = false;


	//object.observe change
	this.updateValue = function() {
		//prevent double changes / infinite loops
		if (this.changing)
			return;
		
		this.changing = true;

		//
		// update values pos
		//
		this.value = this.toWatch[this.propName];
		this.shouldUpdateUI = true;
		this.updateUI();			
		if (this.callback) this.callback.apply(this, [this.value]);

		this.changing = false;

	}.bind(this);

	//
	// Update UI 
	//
	this.canUpdateUI = false;
	this.shouldUpdateUI = false;
	this.updateUI = function() {
		if (!this.canUpdateUI || !this.shouldUpdateUI) return;
			this.shouldUpdateUI = false;
			this.changing = true;
		colorDiv.value = this.value;
		this.changing = false;

	}.bind(this);


	//
	// init the properties
	//
	this.init = function(toWatch, propName, props, path) {
		

		this.propName = propName;
		this.toWatch = toWatch;
		this.path = path;
		this.updateValue([{name:propName}]);
	
		$(labelDiv).text(propName);
		$(colorCodeDiv).css('background-color', props.colorCode || '#'+Math.floor(Math.random()*0xffffff).toString(16));
		if (props) this.callback = props.callback;


		FastEvent.on(toWatch, this.propName, this.updateValue);

		// if ( /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent) ) {
		// 	if ($(colorDiv).spectrum({
		// 		color: this.value,
		// 		preferredFormat: "hex"
		// 	});
		// }
		$(colorDiv).on('change input', this.updateValue);

		UI.watchProperty(this.toWatch, this.propName, this.updateValue);

	}.bind(this);


	//
	// Input change
	//
	$(colorDiv).on('change input',function() {
		if (this.changing) return;
		FastEvent.trigger(this.toWatch, 'ui-update');
		this.toWatch[this.propName] = colorDiv.value;
		UI.triggerProperty(this.toWatch, this.propName);
	}.bind(this));

	$(labelDiv).on('click', function(e) {
		if (e.altKey) {
			Utils.copyToClipboard(labelDiv, this.path.concat(this.propName).join('.'));
		}
	}.bind(this));
	
	//
	// Clean up
	//
	this.dispose = function() {

		
		this.callback = null;
		$(containerDiv).off();
		$(colorCodeDiv).off();
		$(labelDiv).off();
		$(colorDiv).off();

		$(containerDiv).remove();
		$(colorCodeDiv).remove();
		$(labelDiv).remove();
		$(colorDiv).remove();

		this.domElement = null;
		containerDiv = null;
		colorCodeDiv = null;
		labelDiv = null;
		colorDiv = null;

		UI.unwatchProperty(this.toWatch, this.propName, this.updateValue);
	};

};

export default ColorPicker;