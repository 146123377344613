//
// Controller for main eyes w/ follow mode
//
import * as THREE from 'three';
import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';

import AudioController from './AudioController.js';
import SequenceRenderer from './SequenceRenderer.js';


var ANIMATIONS_LIST = [
	"neutre",
	"genee",
	"rire",
	"smile_a",
	"smile_b",
	"surprise"
];
var ANIMATIONS_DIRECTION_ENABLED = {
	"neutre":true,
	"smile_a":true,
	"smile_b":true,
	"surprise":true
};
var ANIMATIONS_ONCE = {
	"rire":true,
	"genee":true
	// "surprise":true
};

var DIRECTION_MAPPING = [
	[10,9,6,7,8],
	[4,3,0,1,2],
	[16,15,12,13,14]
];


class EyesControllerStatic {
	
	constructor() {

		//eyes state
		this.eyesByName = {};
		this.framesBySequence = {};
		this.currentAnimation = 'neutre';
		this.animStartTransition = false;
		this.animStartTransitionTime = 0;

		this.currentSVGFrame = null;
		this.cligneStarted = false;
		this.nextCligne = 0;
		this.cligneCentered = false;
		this.cligneStartTime = 0;
		this.lastActive = 0;
		this.currentTarget = new THREE.Vector2(0,0);
		this.isZero = true;
		this.zeroStart = 0;
	}

	preload(batchName, loader) {
		var seqList = [
			"genee",
			"neutre_cligne",
			"neutre_direction",
			"rire",
			"smile_a",
			"smile_a_cligne",
			"smile_a_direction",
			"smile_b",
			"smile_b_cligne",
			"smile_b_direction",
			"surprise",
			"surprise_cligne"
		];
		for (var i=0; i<seqList.length; i++) {
			this.eyesByName[seqList[i]] = loader.addSVGSequence(batchName, "seq_eyes_"+seqList[i]);
			this.framesBySequence[seqList[i]] = AppStatus.svgSequenceMeta["seq_eyes_"+seqList[i]].numFrames;
		}

	}

	setup() {
		if (!AppStatus.timelineInfo) return;

		//build timeline
		this.timecodes = [];
		for (var o in AppStatus.timelineInfo.eyes) {
			var time = Utils.timecodeToSeconds(o);
			this.timecodes.push({
				timecode: time,
				timeStart: time,
				timeEnd: AppStatus.AUDIO_DURATION,
				animation: AppStatus.timelineInfo.eyes[o]
			});
		}
		this.timecodes.sort(function(a,b) {
			return a.timecode < b.timecode ? -1 : 1;
		});

		//add new timecode after ONCE-type sequences -> return to previous
		for (var i=1; i<this.timecodes.length-1; i++) {
			if (ANIMATIONS_ONCE[this.timecodes[i].animation]) {
				var endTime = this.timecodes[i].timecode + (this.framesBySequence[this.timecodes[i].animation]) / 12;
				this.timecodes.splice(i+1,0,{
					timecode: endTime,
					timeStart: endTime,
					timeEnd: AppStatus.AUDIO_DURATION,
					animation: this.timecodes[i-1].animation
				});
			}
		}
		for (var i=0; i<this.timecodes.length-1; i++) {
			this.timecodes[i].timeEnd = this.timecodes[i+1].timeStart;
		}
		this.timecodes[this.timecodes.length-1].duration = 1000000;
		this.timecodes[this.timecodes.length-1].timeEnd = 1000000;
	}




	update(delta) {
		if (AppStatus.currentFrame-this.lastActive >= 60 || !AppStatus.timelineInfo) return;

		var now = performance.now()/1000;
		var currentTime = AudioController.getCurrentTime();
		for (var i=0; i<this.timecodes.length; i++) {
			if (currentTime >= this.timecodes[i].timeStart && currentTime < this.timecodes[i].timeEnd) {

				var targetAnimation = this.timecodes[i].animation;

				if (!SequenceRenderer.isDragging && SequenceRenderer.pressPc<=0.1  && SequenceRenderer.passivePc>=(SETTINGS.isMobile?0.0:0.1))  {
					if (!this.isZero) this.zeroStart = now;
					this.isZero = true;
				} else {
					if (this.isZero) this.zeroStart = now;
					this.isZero = false;
				}

				//if forced on zero for > x seconds, restart moving
				if (!this.isZero && (now-this.zeroStart) > 5.25) this.zeroStart = now;

					
				// 
				//new animation switch:
				//smile_a and smile_b: 2 frames transition
				//
				if (targetAnimation !== this.currentAnimation) {
					if ((this.currentAnimation == 'neutre' && (targetAnimation == 'smile_a' || targetAnimation == 'smile_b')) || (!ANIMATIONS_ONCE[this.currentAnimation] && (targetAnimation == 'surprise'||targetAnimation == 'genee') )) {
						this.animStartTransition = true;
						this.animStartTransitionTime = now;
					
					} else {
						this.animStartTransition = false;
						this.currentTarget.set(0,0);
					}
					this.nextCligne = now+Math.random()*4.0+0.5;
					if (Math.random()<0.2) this.nextCligne = now+0.5;
					this.cligneStarted = false;
				}

				var animationPc = Utils.cmap(currentTime, this.timecodes[i].timeStart, this.timecodes[i].timeEnd, 0.0, 1.0);

				//
				// handle directional + cligne animations
				//
				if (targetAnimation == 'smile_a' || targetAnimation == 'smile_b' || targetAnimation == "neutre" || targetAnimation == "surprise" || targetAnimation == "genee") {
					
					//play start animation
					if (this.animStartTransition && (targetAnimation == 'smile_a' || targetAnimation == 'smile_b')) {
						animationPc = Utils.cmap(now, this.animStartTransitionTime, this.animStartTransitionTime+2/12, 0.0, 1.0);
						this.currentSVGFrame = this.eyesByName[targetAnimation].frames[Math.floor(animationPc * 2)];
						if (animationPc>=1) this.animStartTransition = false;

					} else if (this.animStartTransition && (targetAnimation == 'surprise' || targetAnimation == 'genee')) {

						animationPc = Utils.cmap(now, this.animStartTransitionTime, this.animStartTransitionTime+this.eyesByName[targetAnimation].frames.length/12, 0.0, 1.0);
						this.currentSVGFrame = this.eyesByName[targetAnimation].frames[Math.min(Math.floor(animationPc * this.eyesByName[targetAnimation].frames.length),this.eyesByName[targetAnimation].frames.length-1)];
						if (animationPc>=1) this.animStartTransition = false;


					//select direction frame
					} else {
						
						var cx=2, cy=1;
						if (targetAnimation !== 'surprise' && targetAnimation !== 'genee') {
							// this.currentSVGFrame = this.eyesByName[targetAnimation+"_direction"].frames[0];
							var t = SequenceRenderer.rawPosition.clone();
							t.y = t.y + 0.19;
								


							//if too active: return to zero
							if (this.isZero || (now-this.zeroStart > 2.75)) {
								t.set(0,0);
								this.currentTarget.lerp(t, this.isZero?(delta*0.25):(delta*0.1) );
								if (this.isZero) this.currentTarget.y = 0;
							} else {
								this.currentTarget.lerp(t, delta*0.15);
							}
						
							var cx = Utils.clamp(Math.round(Utils.cmap(this.currentTarget.x, -0.33, 0.33, -0.45, 4.45)),0,4);
							var cy = Utils.clamp(Math.round(Utils.cmap(this.currentTarget.y, -0.1, 0.1, -0.45, 2.45)),0,2);

							this.currentSVGFrame = this.eyesByName[targetAnimation+"_direction"].frames[DIRECTION_MAPPING[cy][cx]];

						} else {
							this.currentSVGFrame = this.eyesByName[targetAnimation].frames[this.eyesByName[targetAnimation].frames.length-1];
						}
						
						if (targetAnimation !== "genee") {
							//start cligne animation
							if (now > this.nextCligne) {
								this.nextCligne = now+Math.random()*4.0+2.0;
								if (Math.random()<0.2) this.nextCligne = now+0.4;
								this.cligneStarted = true;
								this.cligneStartTime = now;	

								this.cligneCentered = (cx == 2 && cy == 1);		
							}
							//play cligne animation
							if (this.cligneStarted) {

								var seq = targetAnimation + "_cligne";
								var endTime = this.cligneStartTime+0.075; //this.framesBySequence[seq]/12;
								this.currentSVGFrame = this.eyesByName[seq].frames[2];

								if (this.cligneCentered) {
									endTime = this.cligneStartTime + this.framesBySequence[seq]/30;
									this.currentSVGFrame = this.eyesByName[seq].frames[
										Math.floor(Utils.cmap(now, this.cligneStartTime, endTime, 0, this.framesBySequence[seq]-1))
									];
								}
								if (now >= endTime) this.cligneStarted = false;
							}
						}
					}


				// 
				// handle non-directional looping animation
				//
				// } else if (targetAnimation == 'surprise') {
				
				//
				// handle single playback animaitons
				//
				} else { 
					this.currentSVGFrame = 
						this.eyesByName[targetAnimation].frames[Math.min(Math.floor(animationPc * this.framesBySequence[targetAnimation]),this.framesBySequence[targetAnimation]-1)]; 
				}

				this.currentAnimation = targetAnimation;
			}
		}
	}

	getCurrentFrame() {
		this.lastActive = AppStatus.currentFrame;
		return this.currentSVGFrame;
	}
};

window.EyesController = window.EyesController||new EyesControllerStatic();
export default window.EyesController;