//import libraries
import $ from 'jquery';
window.jQuery = window.$ = $;
import * as THREE from 'three';

import JSON6 from 'json-6';
import FastClick from 'fastclick';
import Stats from 'stats.js';

//import utils
import './utils/polyfills.js';
import './sound/AudioTween.js';
import Utils from './utils/Utils.js';
import SETTINGS from './Settings.js';

import EmbeddedTextFR from '../html/texte_fr.js';
import EmbeddedTextEN from '../html/texte_en.js';
const EmbeddedText = SETTINGS.LANGUAGE === 'fr' ? EmbeddedTextFR : EmbeddedTextEN;


//import project
import AppStatus from './controllers/AppStatus.js';
import RendererController from './controllers/RendererController.js';
import WebsocketController from './controllers/WebsocketController.js';
import AudioDisposeController from "./controllers/AudioDisposeController.js";
import SequenceRenderer from './controllers/SequenceRenderer.js';
import InteractionController from './controllers/InteractionController.js';
import CopresenceController from './controllers/CopresenceController.js';
import AnalyticsController from './controllers/AnalyticsController.js';
import EyesController from './controllers/EyesController.js';
import StorageController from './controllers/StorageController.js';

import SVGLoader from './utils/SVGLoader.js';
window.SVGLoader = SVGLoader;

import Loader from './loading/Loader.js';

import Scene from './scenes/Scene.js';

// import BasicScene from './scenes/BasicScene.js';
// import MeshScene from './scenes/MeshScene.js';
// import CleanTween from './scenes/CleanTween.js';
// import CleanTweenTwo from './scenes/CleanTweenTwo.js';
// import CleanLoad from './scenes/CleanLoad.js';
// import CleanTween3 from './scenes/CleanTween3.js';
// import CleanTween4 from './scenes/CleanTween4.js';
// import CleanTween5 from './scenes/CleanTween5.js';
// import CleanTween6 from './scenes/CleanTween6.js';
// import CleanTweenLine from './scenes/CleanTweenLine.js';
// import AnimationTween from './scenes/AnimationTween.js';
// import AnimationTween2 from './scenes/AnimationTween2.js';
// import CustomLine from './scenes/CustomLine.js';
// import Reverb from './scenes/Reverb.js';
// import Magnetic from './scenes/Magnetic.js';
// import BrushesDraw from './scenes/Brushes.js';
// import BrushesVector from './scenes/BrushesVector.js';
// import BrushVectorAnimation from './scenes/BrushVectorAnimation.js';
// import PulseGradient from './scenes/PulseGradient.js';
// import Magnetic2 from './scenes/Magnetic2.js';
// import Copresence from './scenes/Copresence.js';
// import Elastic from './scenes/Elastic.js';
import SceneEditor from './scenes/SceneEditor.js';
import TimelineScene from './scenes/TimelineScene.js';
// import AudioSpeed from './scenes/AudioSpeed.js';
import AudioEffect from './scenes/AudioEffect.js';
// import CopresenceDev from './scenes/CopresenceDev.js';
// import ReverbTest from './scenes/reverbtest.js';
// import SVGText from './scenes/SVGText.js';
import OignonTestScene from './scenes/OignonTestScene.js';


// var DefaultScene = RadialTextureScenePlayback;
var DefaultScene = TimelineScene;

var scenes = {
	// scene: Scene,
	// mesh: MeshScene,
	// load: CleanLoad,
	// tween: CleanTween,
	// two: CleanTweenTwo,
	// basic: BasicScene,
	// line: CleanTweenLine,
	// tween5: CleanTween5,
	// tween6: CleanTween6,
	// animation: AnimationTween,
	// reverb: Reverb,
	// magnetic: Magnetic,
	// brushdraw: BrushesDraw,
	// brushvector: BrushesVector,
	// brushanimation: BrushVectorAnimation,
	// pulsegradient: PulseGradient,
	// magnetic2: Magnetic2,
	// copresence: Copresence,
	// copresencedev: CopresenceDev,
	// elastic: Elastic,
	// reverbtest: ReverbTest,
	// svgtext: SVGText,
	oignon:  OignonTestScene,
	editor: SceneEditor,
	timeline: TimelineScene,
	// audiospeed: AudioSpeed,
	audioeffect: AudioEffect,
};
if (SETTINGS.SCENE && scenes[SETTINGS.SCENE]) DefaultScene = scenes[SETTINGS.SCENE];
if (!scenes[SETTINGS.SCENE]) {SETTINGS.TIMELINE_MODE = true;}

// import SplashView from './views/SplashView.js';
// import PreloaderView from './views/PreloaderView.js';
// import PermissionView from './views/PermissionView.js';
// import DebugControlsView from './views/DebugControlsView.js';

(function() {

	//-----------------------
	//  
	//  Main variables
	//  
	//-----------------------
	var lastTime = 0,
		started = false,
		errorSent = false,
		errorMode = 0,
		errorMaterial,
		mainScene = DefaultScene ? new DefaultScene() : null,
		svgList = [];
	window.mainScene = mainScene;
	// controllers
	// SETTINGS.init();


	//-----------------------
	//
	// Basic setup, load basic assets
	//
	//-----------------------		
	function start() {

		// // $('#main').css('display','block').css('z-index', '9999999');

		// $('#main').on('touchstart',function(e) {
		// 	document.getElementById("main").innerHTML = e.originalEvent.touches[0].pageX;
		// });
		// $('#main').on('touchmove',function(e) {
		// 	document.getElementById("main").innerHTML = e.originalEvent.touches[0].pageX;
		// });
		// $(window).on('touchend',function(){document.getElementById("main").innerHTML = "end";});
		
		// // return;


		if (SETTINGS.TEMP_PAGE_TRACK) {
			AnalyticsController.trackEventDirectly("press_early_access", window.localStorage.getItem("press_ok"));
		}


		errorMode = errorCheck();
		if (errorMode>0) {
			mainError(errorMode);
			return;
		}

		if (!SETTINGS.LOGOS_ENABLED) {
			$('#logos-background').fadeOut(200);
			$('#logos-container').remove();
			AppStatus.logosFinished = true;
		} else {
			$('#logos-background').toggleClass('shown',true);
			$('#logos-container').toggleClass('shown',true);
			// setTimeout(function() {
			// 	if (AppStatus.mainSceneReady && !SETTINGS.SLOW_LOAD_2) {
			// 		AppStatus.logosFinished = true;
			// 		$('#logos-background').fadeOut(1000);
			// 		$('#logos-container').delay(400).fadeOut(1000);
			// 		console.log("Fade out fast");
			// 	}
			// },4000);
			setTimeout(function() {
				$('#logos-container').remove();

				if (!AppStatus.mainSceneReady) {
					AppStatus.mainLoaderShown = true;
					console.log("FADEIN!");
					AppStatus.mainLoaderShownTime = performance.now();
					$('#loader').toggleClass('fadein', true);
				} else {
					$('#logos-background').fadeOut(800);
					$('#logos-container').fadeOut(800);
					// if (!AppStatus.logosFinished) $('#logos-background').fadeOut(500);
				}
				AppStatus.logosFinished = true;
			}, 3200);
		}

		RendererController.init();
		if (SETTINGS.IS_LOCAL) WebsocketController.init();
		resize();

		Loader.createBatch('prePreloadData');
		if (mainScene && mainScene.prePreloadData) mainScene.prePreloadData('prePreloadData');
		Loader.setBatchCompleteCallback('prePreloadData', preload);


		//list all svgs
		if (window.appConfig && window.appConfig.presets && SETTINGS.USE_PRESETS_BUNDLE)
			AppStatus.presetsBundle = Loader.addXHR('prePreloadData', window.appConfig.presets, 'json');
		
		if (window.appConfig && window.appConfig.shadersBundle && SETTINGS.USE_SHADERS_BUNDLE && !SETTINGS.IS_LOCAL)
			AppStatus.shadersBundle = Loader.addXHR('prePreloadData', window.appConfig.shadersBundle, 'json');

		AppStatus.infoJson = Loader.addXHR('prePreloadData', (window.appConfig.info_json && !SETTINGS.IS_LOCAL)?window.appConfig.info_json:'data/info.json', 'json6');
		

		if (window.appConfig && window.appConfig.svgMeta && !SETTINGS.IS_LOCAL) {
			AppStatus.svgBinaryMeta = Loader.addXHR('prePreloadData', window.appConfig.svgMeta, 'json');
		} else {
			AppStatus.svgBinaryMeta = Loader.addXHR('prePreloadData', 'data/svg_meta.json', 'json');
		}
		
		if (SETTINGS.IS_LOCAL) svgList =  Loader.addXHR('prePreloadData', '/filelist?dir=svg/', 'json');
		else svgList =  AppStatus.svgBinaryMeta;
		// svgList =  Loader.addXHR('prePreloadData', '/filelist?dir=svg/', 'json');
		// svgList =  AppStatus.svgBinaryMeta; //Loader.addXHR('prePreloadData', 'data/autofillcache/svg_.json', 'json');

		Loader.start();

	}

	//-----------------------
	//
	// Data loaded : load main scene
	//
	//-----------------------
	function preload() {
		AppStatus.infoJson = AppStatus.infoJson.value;
		AppStatus.svgBinaryMeta = AppStatus.svgBinaryMeta.value;

		//
		// Parse svg list for sequences
		//
		var svgFiles = SETTINGS.IS_LOCAL ? svgList.value.files : Object.keys(svgList.value);

		AppStatus.svgList = [];
		AppStatus.svgSequenceMeta = {};
		for (var i=0; i<svgFiles.length; i++) {
			var fileName = svgFiles[i].split("svg/").pop();
			var pathDiv = fileName.split('/');

			//is sequence:
			if (pathDiv.length > 1 && /seq\_/.test(pathDiv[pathDiv.length-2])) {
				var sequenceName = [].concat(pathDiv);
				sequenceName.pop();
				sequenceName = sequenceName.join('/');
				if (!AppStatus.svgSequenceMeta[sequenceName]) {
					AppStatus.svgSequenceMeta[sequenceName] = {frames:[], numFrames:0};
					AppStatus.svgList.push(sequenceName);
				}
				AppStatus.svgSequenceMeta[sequenceName].frames.push(fileName);
				AppStatus.svgSequenceMeta[sequenceName].numFrames++;

			} else {
				AppStatus.svgList.push(fileName);
			}
		}


		for (var sequenceName in AppStatus.svgSequenceMeta) {
			if (AppStatus.svgSequenceMeta[sequenceName].frames.length > 0) {
				var t = AppStatus.svgSequenceMeta[sequenceName].frames[0].split("_").pop().replace(".svg","");
				if (t && !isNaN(parseInt(t,10))) {
					AppStatus.svgSequenceMeta[sequenceName].frames.sort(function(a, b) {
						return parseInt(a.split("_").pop().replace(".svg",""),10) < parseInt(b.split("_").pop().replace(".svg",""),10) ? -1 : 1;
					});
				}
			}
		}

		//headphones installation
		if (SETTINGS.VOLUME_BUTTON_ENABLED) {
			AppStatus.globalVolume = Utils.clamp(AppStatus.globalVolume-0.2, AppStatus.infoJson.installation_volume_min, AppStatus.infoJson.installation_volume_max);
			if (window.localStorage) {
				try {AppStatus.globalVolume = parseFloat(window.localStorage.getItem('volume'),10);}catch(er){}
				AppStatus.globalVolume = AppStatus.globalVolume||AppStatus.infoJson.installation_volume_start;
			}
		}

		Loader.createBatch('preloadData');
		if (mainScene && mainScene.preloadData) mainScene.preloadData('preloadData');
		Loader.setBatchCompleteCallback('preloadData', loadMain);
		Loader.start();
	}

	function loadMain() {
		Loader.createBatch('main');

		if (mainScene && mainScene.preload) mainScene.preload('main');

		EyesController.preload('main', Loader);
		Loader.addShader('main', 'shaders/common/vertex.vert');
		Loader.setBatchCompleteCallback('main', setup);

		Loader.start();

	}

	//-----------------------
	//
	// Setup main scene and start project
	//
	//-----------------------
	function setup() {
		if (mainScene && mainScene.start) mainScene.start();
		CopresenceController.connect();
		EyesController.setup();
		ABDisposeController.setup();
		if (!SETTINGS.isMobile) $('.ui-btn').css('cursor', 'pointer');

		//create stats
		if (SETTINGS.SHOW_UI) {
			window.stats = new Stats();
			window.stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
			document.body.appendChild( window.stats.dom );
		}

		//start main loop
		lastTime = performance.now();	
		requestAnimationFrame(update);
	}


	//-----------------------
	//
	// Main loop
	//
	//-----------------------
	function update() {
		requestAnimationFrame(update);
		if (AppStatus.cleanedUp) return;
		var deltaTime = performance.now() - lastTime;
		var delta = Utils.cmap(deltaTime/1000, 1.0/120.0, 1.0/10.0, 0.5, 6.0);
		lastTime = performance.now();
		AppStatus.currentFrame++;

		//
		// Update controllers
		//
		CopresenceController.sendPosition(SequenceRenderer.isDragging, SequenceRenderer.rawPosition.x, SequenceRenderer.rawPosition.y);
		CopresenceController.update(delta);
		EyesController.update(delta);

		//update main scene
		if (mainScene && mainScene.update) {
			mainScene.update(delta);
		}
		render();

		//prepare next frame
		if (window.stats) window.stats.update();
		AudioDisposeController.update();
		SequenceRenderer.updateAfter();
		InteractionController.updateAfter()
		// BlobController.updateAfter();
	}

	//-----------------------
	//
	// Three.js rendering
	//
	//-----------------------
	function render() {
		// renderer.setRenderTarget(null);
		// renderer.setClearColor(Utils.whiteColor, 1.0);
		// renderer.clear();
		if (mainScene && mainScene.render) mainScene.render();
	}

	//-----------------------
	//
	// Global resize
	//
	//-----------------------
	function resize() {
		RendererController.resize();
		SequenceRenderer.resize();
		if (mainScene && mainScene.resize) mainScene.resize();
	}
	document.body.onorientationchange = window.onresize = document.onresize = document.onfullscreenchange = document.documentElement.onfullscreenchange = resize;



	// if (!SETTINGS.INSTALLATION_MODE) {

		//
		// Prevent zoom
		//
		function cancelZoom(event) {
			if (SETTINGS.INSTALLATION_MODE) return;
		  if ((event.scale!==undefined && event.scale!=1)  || event.ctrlKey || (event.touches && event.touches.length>1)) {
		  	event.preventDefault(); return false; 
		  }
		}
		if (SETTINGS.isMobile && SETTINGS.isIOS) {
			
			//disable pinch to zoom!
			//doubletap to zoom disabled by css touch:manipulation
			document.addEventListener('touchmove', cancelZoom,{passive: false, capture:true}, true);
			document.addEventListener('touchstart', cancelZoom,{passive: false, capture:true}, true);
			window.addEventListener('touchmove', cancelZoom,{passive: false, capture:true}, true);
			window.addEventListener('touchstart', cancelZoom,{passive: false, capture:true}, true);

		} else {

			if (SETTINGS.isSafari && !SETTINGS.isMobile) {
				document.addEventListener("gesturestart", function(e) {
				    e.preventDefault();
				    document.body.style.zoom = 0.99;
				});
				document.addEventListener("gesturechange", function(e) {
				    e.preventDefault();
				    document.body.style.zoom = 0.99;
				});
				document.addEventListener("gestureend", function(e) {
				    e.preventDefault();
				    document.body.style.zoom = 1;
				});
			}
			
			// document.addEventListener('gesturestart', cancelZoom,{passive: false, capture:true}, true);
			// document.addEventListener('gesturechange', cancelZoom,{passive: false, capture:true}, true);
			// document.addEventListener('gestureend', cancelZoom,{passive: false, capture:true}, true);
			document.addEventListener('keydown',function(event) {
			if (event.ctrlKey==true && (event.which == '61' || event.which == '107' || event.which == '173' || event.which == '109'  || event.which == '187'  || event.which == '189'  ) ) {
			        event.preventDefault();
			     }
			    // 107 Num Key  +, 109 Num Key  -, 173 Min Key  hyphen/underscor Hey, 61 Plus key  +/= key
			},{passive: false, capture:true}, true);
			window.addEventListener('mousewheel', cancelZoom,{passive: false, capture:true}, true);
			window.addEventListener('DOMMouseScroll', cancelZoom,{passive: false, capture:true}, true);
		}


		if (SETTINGS.SCREEN_RECORD || SETTINGS.SKIP_BRANCHES) {
			document.addEventListener('keydown',function(event) {
				if (event.which == 13) {
					var element = document.body;
					if (event instanceof HTMLElement) {
						element = event;
					}
					var isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

					element.requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || function () { return false; };
					document.cancelFullScreen = document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || function () { return false; };

					isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
				}
			});
		}




		//
		// Enable ui-hide events
		//
		function keydownHandler(e) {
			if (e.keyCode==9 || e.keyCode==32) e.preventDefault();
			if (e.keyCode==9 || e.keyCode==32) { // || e.keyCode==32
				if (!window.guiHidden) {
					window.guiHidden = true;
					if (window.stats) $(window.stats.domElement).remove();
					// $('#background').hide();
					$('.dg').hide();
					$('#main').css('z-index',999);
					if (window.gui) $(window.gui.domElement).hide();
					if (window.DreamUI) $('.ui-main').hide();
				} else {
					window.guiHidden = false;
					if (window.stats) $('body').append(window.stats.domElement);
					// $('#background').show();
					$('#main').css('z-index',1);
					$('.dg').show();
					if (window.gui) $(window.gui.domElement).show();
					if (window.DreamUI) $('.ui-main').show();
				}
				e.preventDefault();
			}
		};
		$(document).keydown(keydownHandler);

	// }



	//-----------------------
	//
	// An error has blocked the loading of the project. Display it in fullscreen.
	//
	//-----------------------
	function webglCheck() {

		// console.log(canvas.getContext( 'webgl' , ));
		// return false;
		// if (!SETTINGS.isSafari && !SETTINGS.isMobile) webglOpt = ;
		try {
			var canvas = document.createElement( 'canvas' );
			if ( !canvas.getContext( 'webgl', {failIfMajorPerformanceCaveat: !SETTINGS.isSafari} )) {return false;}
			RendererController.init();
			return true;
		} catch ( e ) {
			return false;
		}
	};

	//
	// Check for major errors that block site access
	//
	function errorCheck() {
		if (SETTINGS.FORCE_SUPPORT) return 0;
		if (SETTINGS.FORCE_ERROR) return SETTINGS.FORCE_ERROR;


		//error 1 : no webgl : display error on fullscreen
		if (!webglCheck()) {
			if (!errorSent) {AnalyticsController.trackEvent('error', 'webgl');errorSent = true;}
			return 1;
		}

		var browserIsTooOld = false;

		if (SETTINGS.isSafari) {
			try {
				var safariVersion = parseFloat((navigator.userAgent).match(/OS (\d)?\d_\d(_\d)?/i)[0].replace("_",".").replace("_","").replace("OS ",""),10)
				if (safariVersion && !isNaN(safariVersion) && safariVersion>2 && safariVersion<=10.2) {
					browserIsTooOld = true;
					console.log("safari is too old", safariVersion);
				}  
			}catch (er) {console.log(er);}
		} else if (SETTINGS.isChrome) {
			try {
				var chromeVersion = parseFloat(navigator.userAgent.match(/(MSIE|Trident|(?!Gecko.+)Firefox|(?!AppleWebKit.+Chrome.+)Safari(?!.+Edge)|(?!AppleWebKit.+)Chrome(?!.+Edge)|(?!AppleWebKit.+Chrome.+Safari.+)Edge|AppleWebKit(?!.+Chrome|.+Safari)|Gecko(?!.+Firefox))(?: |\/)([\d\.apre]+)/)[2],10);
				if (chromeVersion && !isNaN(chromeVersion) && chromeVersion>10 && chromeVersion<50) {
					browserIsTooOld = true;
					console.log("chrome is too old", chromeVersion);
				}
			}catch (er) {console.log(er);}
		} else if (SETTINGS.isFirefox) {
			try {
				var firefoxVersion = parseFloat(navigator.userAgent.match(/(MSIE|Trident|(?!Gecko.+)Firefox|(?!AppleWebKit.+Chrome.+)Safari(?!.+Edge)|(?!AppleWebKit.+)Chrome(?!.+Edge)|(?!AppleWebKit.+Chrome.+Safari.+)Edge|AppleWebKit(?!.+Chrome|.+Safari)|Gecko(?!.+Firefox))(?: |\/)([\d\.apre]+)/)[2],10);
				if (firefoxVersion && !isNaN(firefoxVersion) && firefoxVersion>10 && firefoxVersion<50) {
					browserIsTooOld = true;
					console.log("firefox is too old", firefoxVersion);
				}
			}catch (er) {console.log(er);}
		}


		//error 2 : device is too old but webgl works : display error
		if (SETTINGS.isMobile && (!window.WebAssembly || window.devicePixelRatio<2||browserIsTooOld)) {
			if (!errorSent) {AnalyticsController.trackEvent('error', 'too_old');errorSent = true;}
			return 2;
		}

		//error 3 : browser VERSION is too old but webgl and everything works : display error, load video
		if (!SETTINGS.isMobile && browserIsTooOld) {
			if (!errorSent) {AnalyticsController.trackEvent('error', 'browser_version');errorSent = true;}
			return 3;
		}

		if (SETTINGS.IS_TEMP_PAGE) {
			return 4;
		}

		return 0;
	};


	//--------------
	//
	// Display errors that block site access
	//
	//--------------
	function mainError(error) {
		console.log("ERROR, Project Stopped @: ",error);

		//fullscreen header, no task
		MenuController.init();
		$('#loader').remove();

		MenuController.show();
		var errorText = EmbeddedText.errors[
			error==1?'webgl':
			error==2?'device':
			error==3?'browser':
			error==4?'temp_page':
			'loading'
		];

		$('#error').css('display', 'flex');
		$('#error-txt').html(errorText);
		$('#logos-background').fadeOut(200);
		$('#logos-container').remove();
		console.log(error, EmbeddedText.errors, errorText, error==1?'webgl':
			error==2?'device':
			error==3?'browser':
			error==4?'temp_page':
			'loading');

		if (error==4) {
			$('#press-access-btn').on('click',()=>{
				var pswd = window.prompt(SETTINGS.LANGUAGE == 'fr' ? "Mot de passe :": "Password :");

				var passwordList = {
					"cerveau": true,
					"brain": true
				};
				if (passwordList[pswd]) {
					window.localStorage.setItem("press_ok", pswd);
					window.location.reload();
				} else {
					window.alert(SETTINGS.LANGUAGE == 'fr' ? "Erreur : mot de passe érroné": "Error : wrong password");
					window.location.reload();
				}
			});
		}

		if (error>1) {
			Loader.createBatch('main');
			Loader.addShader('main', 'shaders/effects/pulseGradient.vert');
			Loader.addShader('main', 'shaders/effects/pulseGradient3.frag');
			SequenceRenderer.noiseTexture = Loader.addTexture('main', 'images/common/noise_couleur0.jpg', {
				format: THREE.LuminanceFormat,
				minFilter: THREE.LinearMipMapLinearFilter,
				magFilter: THREE.LinearFilter,
				wrapping: THREE.MirroredRepeatWrapping,
				generateMipmaps: true
			});
			Loader.setBatchCompleteCallback('main', errorSetup);
			Loader.start();
		}
	};
	function errorSetup() {
		RendererController.init();
		errorMaterial = new THREE.RawShaderMaterial({
			transparent: false,
			depthTest: false,
			depthWrite: false,
			side: THREE.DoubleSide,
			vertexShader: Loader.getShader('shaders/effects/pulseGradient.vert'),
			fragmentShader: Loader.getShader('shaders/effects/pulseGradient3.frag'),
			uniforms: {
				tNoise: {type: 't', value:SequenceRenderer.noiseTexture},
				alpha: { type: 'f', value: 1 },

				fillColor: { type: 'c', value: new THREE.Color(0xf0f0f0) },
				fillAlpha: { type:'f', value:0.7},

				resolution: {type:'v2', value:new THREE.Vector2(AppStatus.innerWidth(), AppStatus.innerHeight())},

				pulseTime: { type: 'f', value: 0 },
				gradientTime: { type: 'f', value: 0 },

				gradientMin: { type: 'f', value: 0.5741573654432103 },
				gradientMax: { type: 'f', value: 0.6830590702077567 },
				noiseA: { type: 'f', value: 0.35 },
				noiseB: { type: 'f', value: 0.35 },
				noiseTime: { type: 'f', value: 0 },
				randomOffset: { type: 'v2', value: new THREE.Vector2(1,1) },

				colorA: { type: 'c', value: new THREE.Color(0) },
				colorB: { type: 'c', value: new THREE.Color(0) },
				colorC: { type: 'c', value: new THREE.Color(0) }
			}
		});
		errorMaterial.uniforms.colorA.value.setRGB(0.7215686274509804,1, 0.7764705882352941);
		errorMaterial.uniforms.colorB.value.setRGB(1,1,1);
		errorMaterial.uniforms.colorC.value.setRGB( 0.9882352941176471, 0.8196078431372549, 1);
		errorUpdate();
	}
	function errorUpdate() {
		requestAnimationFrame(errorUpdate);

		var now = performance.now();

		errorMaterial.uniforms.gradientTime.value = now/1000 * 1.2589361875475427;
		errorMaterial.uniforms.pulseTime.value = now/1000 * -0.1;
		errorMaterial.uniforms.noiseTime.value = now/1000;
		errorMaterial.uniforms.randomOffset.value.set(Math.random()*1000.0, Math.random()*1000.0);
		errorMaterial.uniforms.resolution.value.set(AppStatus.innerWidth(), AppStatus.innerHeight());


		Utils.renderMaterial(errorMaterial);
	}


	//-----------------------
	//
	// Start app
	//
	//-----------------------
	$(document).ready(function() {

		if (SETTINGS.LIVE_CINEMA_MODE && !SETTINGS.PRESENTATION_MODE && SETTINGS.INTRO_MODE) {
			$('#logos-background').toggleClass('black', true);

			setTimeout(()=> {
				$('#instructions-enter-live-container').show();
				$('#instructions-enter-live-container').one('click', ()=>{
					$('#instructions-enter-live-container').hide();
					$('#logos-background').toggleClass('black', false);
					start();
				});
			}, 2000);
			
			return;
		}


		if (SETTINGS.isIOS) {
			// $('#manifest').attr('href', SETTINGS.ASSETS_URL+'manifest_ios_'+SETTINGS.LANGUAGE+'.webmanifest');
		}

		if (SETTINGS.HEIGHT_TEST) {
			$("#main").append(`
				<div style="position:absolute; z-index:1000000; color: #000000; background-color: rgba(255,255,255,0.825);">
				<br><br><br>
				Height test: <br>
				<br>
				SETTINGS.USE_SCREEN_WIDTH ${SETTINGS.USE_SCREEN_WIDTH} <br>
				navigator.userAgent.toLowerCase().search("instagram")>-1 ${navigator.userAgent.toLowerCase().search("instagram")>-1}  <br>
				AppStatus.innerWidth() ${AppStatus.innerWidth()} <br>
				window.innerWidth > window.screen.width ${window.innerWidth > window.screen.width}  <br>
				window.innerWidth ${window.innerWidth}   window.innerHeight ${window.innerHeight}  <br>
				screen.width ${screen.width}   screen.height ${screen.height}  <br>
				screen.availWidth ${screen.availWidth} screen.availHeight ${screen.availHeight}  <br>
				$('#main').width() ${$('#main').width()}  $('#main').height() ${$('#main').height()}  <br>
				$('#main').outerHeight() ${$('#main').outerHeight()}  <br>
				$('#loader').width() ${$('#loader').width()}  $('#loader').height() ${$('#loader').height()}  <br>
				$(document).width() ${$(document).width()}  $(document).height() ${$(document).height()} <br>
				$(document.body).outerWidth() ${$(document).outerWidth()}  $(document.body).outerHeight() ${$(document).outerHeight()} <br>
				$(document.body).width() ${$(document.body).width()}  $(document.body).height() ${$(document.body).height()} <br>
				window.outerWidth ${window.outerWidth}  window.outerHeight ${window.outerHeight} <br>
				$(window).outerWidth() ${$(window).outerWidth()}  $(window).outerHeight() ${$(window).outerHeight()} <br>
				<br>
				</div>
			`);
			// return
		}

		//redirect if necessary
		if (SETTINGS.ENABLE_AUTO_FTP) {
			AnalyticsController.trackEvent('FTP_redirect');
			window.location.href = SETTINGS.FTP_LINK;
			document.body.addEventListener('touchstart', function() {
				window.location.href = SETTINGS.FTP_LINK;
			});
		}

		//start app
		start();
	});
})();