import * as THREE from 'three';
import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';

import SVGFrame from './SVGFrame.js';
import PointState from './PointState.js';
import { makeNoise2D } from "open-simplex-noise";




//----------------
//
// SVG Transition
// Transition info between a start svg and end svg
//
//----------------
class SVGMorph {

	constructor(startFrame, endFrame, sequenceOptions, params, layerName) {

		if (startFrame.isDrawFrame) startFrame = new SVGFrame().fromPoints(startFrame.points);
		if (endFrame.isDrawFrame) endFrame = new SVGFrame().fromPoints(endFrame.points);


		this.uuid = 'morph_'+startFrame.uuid+endFrame.uuid+JSON.stringify(params);
		this.startFrame = startFrame;
		this.endFrame = endFrame;

		this.layerName = layerName;
		this.hasLayerTransition = false;

		var opt = sequenceOptions;
		this.options = sequenceOptions;

		var splinesStart = [].concat(startFrame.splines);
		if (splinesStart.length<=0 && endFrame.splines.length>0) splinesStart = splinesStart.concat(endFrame.splines);
		var splinesEnd = [].concat(endFrame.splines);
		if (splinesEnd.length<=0 && startFrame.splines.length>0) splinesEnd = splinesEnd.concat(startFrame.splines);
		var invertStartStop = true;
		var randomSort = false; //this.options.assignRandom;


		//invert start end stop
		// expl: Morph should always go from least points(start) to most points(end)
		if (splinesStart.length > splinesEnd.length) {
			invertStartStop = false;
			var o = splinesEnd;
			splinesEnd = splinesStart;
			splinesStart = o;
		}


		//should sort shapes based on size / angle / distance
		//but just assign randomly for now
		// splinesStart = Utils.shuffle(splinesStart);
		splinesEnd = Utils.shuffle(splinesEnd);

		var numShapesStart = splinesStart.length;
		var numShapesEnd = splinesEnd.length;


		//
		// Get total length
		//
		var totalLengthStart = startFrame.totalLength;
		var totalLengthEnd = endFrame.totalLength;

		var lines = [];
		var linePoints = [];
		var randomSpeed = [];
		var startShapeTargets= [];
		var endShapeMorphInfo = [];

		//
		// assign end shapes to start shapes
		//
		splinesStart.sort(function(a,b) {
			return a.sortedAngle > b.sortedAngle ? 1 : -1;
		});
	
		splinesEnd.sort(function(a,b) {
			return a.sortedAngle > b.sortedAngle ? 1 : -1;
		});


		//randomness
		if (opt.morph.randomness.assignRandom) splinesEnd = Utils.shuffle(splinesEnd);
		for (var i=0; i<opt.morph.randomness.tweenRotation; i++) {
			splinesEnd.unshift(splinesEnd.pop());
		}


		var currentPc = 0.0;
		var currentShapeStart = 0;
		var currentStartPoint = 0;

		// assign shape (1-2-3) to shape 1, (4-5-6) to 2, etc
		// var pointsByShape = SETTINGS.SETTINGS.LINE_RESOLUTION / splinesStart.length;
		var numShapesByShape = (splinesStart.length+1) / ((splinesEnd.length));


		//select num points for start shape
		// for (var i = 0; i < splinesStart.length; i++) {
		// 	splinesStart[i].numPoints = this.mapNumPoints(splinesStart[i].cachedLength);
		// }
		var numPoints = endFrame.getNumPoints(splinesEnd);


		this.minLine = 6;
		this.resolutionRatio = 1.0
		if (splinesEnd.length) this.resolutionRatio *= Utils.ccmap(splinesEnd.length, 500, 1500, 1.0, 0.5);
		if (endFrame.totalLength) this.resolutionRatio *= Utils.ccmap(endFrame.totalLength, 10, 30, 1.0, 0.5);
		// console.log(this.resolutionRatio);

		//roughly assign each end shape to a start shape
		var currentStartShape = 0, currentNShape = 0, totalLengthByStartShape = [];
		for (var i = 0; i < splinesEnd.length; i++) {
			
			//select target
			endShapeMorphInfo[i] = ({
				"target": currentStartShape,
				numPoints: 0,
				startPoint: 0,
				endPoint: 0,
				// totalPoints: this.mapNumPoints( Math.max(splinesStart[currentStartShape].cachedLength, splinesEnd[i].cachedLength ))
			});

			if (!splinesEnd[i]) {
				console.warn("Disposed wtf");
			}


			//calc total length
			totalLengthByStartShape[currentStartShape] = totalLengthByStartShape[currentStartShape]||0;
			totalLengthByStartShape[currentStartShape] += splinesEnd[i].cachedLength;

			//increment shape
			currentNShape+=numShapesByShape;
			if (currentNShape >= 1 && currentStartShape<splinesStart.length-1) {
				currentNShape -= 1;
				currentStartShape++;
			}
		}

		//get num points for each spline
		this.layerTransitions = [];
		this.pointsByStartShape = [];

		var currentPointByStartShape = [];
		var currentPointByEndShape = [];
		for (var i = 0; i < splinesEnd.length; i++) {
			var startShape = endShapeMorphInfo[i].target;

			if (!splinesStart[startShape]) {
				console.warn("error");
			}

			endShapeMorphInfo[i].totalPoints = this.mapNumPoints(Math.max(totalLengthByStartShape[startShape], splinesStart[startShape].cachedLength));
			var pc = splinesEnd[i].cachedLength / totalLengthByStartShape[startShape];
			endShapeMorphInfo[i].numPoints = Math.max(Math.round(pc * endShapeMorphInfo[i].totalPoints),3);
	
			
			// console.log(i, estSrcPoints, endShapeMorphInfo[i].numPoints);


			if (isNaN(endShapeMorphInfo[i].numPoints)) {
				console.log('wtf points',splinesEnd[i].cachedLength,totalLengthByStartShape[startShape]);
			}

			// console.log(
			// 	i,
			// 	estSrcPoints,
			// 	endShapeMorphInfo[i].numPoints
			// );
			currentPointByStartShape[startShape] = currentPointByStartShape[startShape]||0;
			endShapeMorphInfo[i].startPoint = currentPointByStartShape[startShape];
			currentPointByStartShape[startShape] += endShapeMorphInfo[i].numPoints;
			endShapeMorphInfo[i].endPoint = currentPointByStartShape[startShape];

			//max points			
			endShapeMorphInfo[i].numPoints = Math.max(endShapeMorphInfo[i].numPoints, numPoints[i]);
			endShapeMorphInfo[i].endPoints = splinesEnd[i].getPoints(Math.max(endShapeMorphInfo[i].numPoints,2));

			if (!this.pointsByStartShape[startShape]) this.pointsByStartShape[startShape] = splinesStart[startShape].getPoints(endShapeMorphInfo[i].totalPoints)
		

			if ((splinesStart[startShape].sourceLayer && splinesStart[startShape].sourceLayer!==this.layerName) || (splinesEnd[i].sourceLayer && splinesEnd[i].sourceLayer!==this.layerName)) {
				this.hasLayerTransition = true;
				this.layerTransitions[i] = invertStartStop ?
						{from: splinesStart[startShape].sourceLayer||this.layerName, to:splinesEnd[i].sourceLayer||this.layerName}:
						{to: splinesStart[startShape].sourceLayer||this.layerName, from:splinesEnd[i].sourceLayer||this.layerName};
			}
		}



		//
		// Transition infos : state for each point, random seed, etc
		//
		// this.rng = makeNoise2D(Date.now());
		this.numPoints = [];
		this.linePoints = [];
		this.randomSeed = [];
		this.randomSeedSmooth = [];
		var now = performance.now()/1000;
		for (var i=0;i<endShapeMorphInfo.length;i++) {
			var np3 = (Math.max(endShapeMorphInfo[i].numPoints-1,2)+1)*3;
			var points = new Float32Array(np3);
			// var rs = new Float32Array(np3);
			// var rss = new Float32Array(np3/3);

			var maxnp = Math.max(endShapeMorphInfo[i].numPoints, endShapeMorphInfo[i].endPoints.length)
			// console.log(np3, maxnp, endShapeMorphInfo[i].numPoints);;
			// for (var j=0; j<maxnp; j++) {
			// 	rs[j] = Math.random();
			// 	rss[j] = 1.5 + 1.0 * SequenceRenderer.rng(i+now,j*0.025);
			// }
			this.numPoints.push(np3/3);
			this.linePoints.push(points);
			// this.randomSeed.push(rs);
			// this.randomSeedSmooth.push(rss);
		}

		// if (this.hasLayerTransition) {
		// 	console.log("HAS LAYER TRANSITION!");
		// }



		//
		// Save data for updating morph
		//
		this.endShapeMorphInfo = endShapeMorphInfo;
		this.splinesStart = splinesStart;
		this.splinesEnd = splinesEnd;
		this.invertStartStop = invertStartStop;


	}


	mapNumPoints(cachedLength) {
		// return Math.floor(Utils.cmap(cachedLength, 0.0, 3.0, 8, 512));
		return Math.floor(Utils.cmap(Math.pow(cachedLength,0.75), 0.0, 2.25*this.resolutionRatio, this.minLine, SETTINGS.LINE_RESOLUTION*this.resolutionRatio));
	}

	update(_morphPc) {
		var opt = this.options;

		var morphPc = _morphPc;
		if (this.invertStartStop) {
			morphPc = 1.0-morphPc;
		}


		//
		// Fit end shapes to start shapes
		//
		// var this.pointsByStartShape = [];
		// var pointsByEndShape = [];
		for (var i=0; i<this.endShapeMorphInfo.length; i++) {
			var targetMeta = this.endShapeMorphInfo[i];

			var startSpline = this.splinesStart[targetMeta.target];
			var endSpline = this.splinesEnd[i];
			var endPoints = targetMeta.endPoints;

			var startPoints = this.pointsByStartShape[targetMeta.target];

			if (opt.morph.randomness.tweenNoiseSmooth) {

				if (!this.randomSeedSmooth[i] || (this.randomSeedSmooth[i] && this.randomSeedSmooth[i].length < endPoints.length)) {
					var rss = new Float32Array(endPoints.length), now = performance.now();
					for (var j=0; j<endPoints.length; j++) {
						rss[j] = 1.5 + 1.0 * SequenceRenderer.rng(i+now,j*0.025);
					}
					this.randomSeedSmooth[i] = rss;
				}

				for (var j=0; j<endPoints.length; j+=3) {
					var tp = Math.min(targetMeta.startPoint+j/3,(targetMeta.totalPoints-1))*3;
					var mpc = Math.pow(morphPc, this.randomSeedSmooth[i][j]);
					// if (isNaN(mpc)) {
					// 	console.log(mpc,this.randomSeedSmooth[i][j]);
					// }
					this.linePoints[i][j] = endPoints[j] * (1.0-mpc) + startPoints[tp]*mpc;
					this.linePoints[i][j+1] = endPoints[j+1] * (1.0-mpc) + startPoints[tp+1]*mpc;
				}
			
			} else if (opt.morph.randomness.tweenNoise) {

				if (!this.randomSeed[i] || (this.randomSeed[i] && this.randomSeed[i].length < endPoints.length)) {
					var rss = new Float32Array(endPoints.length), now = performance.now();
					for (var j=0; j<endPoints.length; j++) {
						rss[j] = Math.random();
					}
					this.randomSeed[i] = rss;
				}

				for (var j=0; j<endPoints.length; j+=3) {
					var tp = Math.min(targetMeta.startPoint+j/3,(targetMeta.totalPoints-1))*3;
					var mpc = Math.pow(morphPc, 0.5+this.randomSeed[i][j]);
					this.linePoints[i][j] = endPoints[j] * (1.0-mpc) + startPoints[tp]*mpc;
					this.linePoints[i][j+1] = endPoints[j+1] * (1.0-mpc) + startPoints[tp+1]*mpc;
				}
			} else {
				//no randomness
				for (var j=0; j<endPoints.length; j+=3) {
					var tp = Math.min(targetMeta.startPoint+j/3,(targetMeta.totalPoints-1))*3;
					this.linePoints[i][j] = endPoints[j] * (1.0-morphPc) + startPoints[tp]*morphPc;
					this.linePoints[i][j+1] = endPoints[j+1] * (1.0-morphPc) + startPoints[tp+1]*morphPc;
					if (j==0 && isNaN(this.linePoints[i][0])) {
						console.warn("nan 4");
					}
				}
			}

			if ((startSpline.sourceLayer && startSpline.sourceLayer!==this.layerName) || (endSpline.sourceLayer && endSpline.sourceLayer!==this.layerName)) {

			}


			
			// tData.lines[i].geometry.setPositions(this.linePoints[i]);
		}
	}


	endForwards() {

	}

	endBackwards() {

	}


}


/*
//
// Other best match sorting algo
//

//get distance score between shapes
		for (var i=0; i<splinesEnd.length; i++) {
			splinesEnd[i].matchScore = [];
			splinesEnd[i].matched = false;
			splinesEnd[i].matchVector = new THREE.Vector2(0,0);
			for (var j=0; j<splinesStart.length; j++) {
				var score =
					// Utils.distanceBetweenAngles( splinesStart[j].sortedAngle, splinesEnd[i].sortedAngle)*0.1 +
					splinesStart[j].centroid.distanceTo(splinesEnd[i].centroid) +
					splinesStart[j].startPoint.distanceTo(splinesEnd[i].startPoint) +
					splinesStart[j].endPoint.distanceTo(splinesEnd[i].endPoint);
					// splinesEnd[i].cachedLength;
					// Math.abs(splinesStart[j].cachedLength - splinesEnd[i].cachedLength) * 1.0;
					// Utils.distanceBetweenAngles(splinesStart[j].centroidAngle, splinesEnd[i].centroidAngle) * 0.33;
				splinesEnd[i].matchScore[j] = {score:
					score,
					id: j
				}
				// // console.log(Math.abs(splinesStart[j].cachedLength - splinesEnd[j].cachedLength) * 1000.0);
				// splinesEnd[i].matchVector.x += Math.cos(splinesStart[j].sortedAngle) * (1.0/(score+0.001));
				// splinesEnd[i].matchVector.y += Math.sin(splinesStart[j].sortedAngle) * (1.0/(score+0.001));
			}
			// var an = Math.atan2(splinesEnd[i].matchVector.y, splinesEnd[i].matchVector.x);
			// splinesEnd[i].matchAngle = Utils.distanceBetweenAngles(an, 0) * -Utils.directionBetweenAngles(an, 0);
			// console.log(splinesEnd[i].matchAngle);
		}


		var currentPc = 0.0;
		var currentShapeStart = 0;
		var currentStartPoint = 0;

		// assign shape (1-2-3) to shape 1, (4-5-6) to 2, etc
		// var pointsByShape = SETTINGS.SETTINGS.LINE_RESOLUTION / splinesStart.length;
		var numShapesByShape = (splinesStart.length+1) / ((splinesEnd.length));
		
		//roughly assign each end shape to a start shape
		var currentStartShape = 0, currentNShape = 0, totalLengthByStartShape = [];
		// for (var i = 0; i < splinesEnd.length; i++) {
		var currentEndShape = 0;
		while (currentEndShape < splinesEnd.length) {

			//get best end shape
			var i = 0;
			var bestMatch = 0;
			var bestMatchScore = 999.0;
			for (var j = 0; j <splinesEnd.length; j++) {
				if (!splinesEnd[j].matched && splinesEnd[j].matchScore[currentStartShape].score<bestMatchScore) {
					bestMatchScore = splinesEnd[j].matchScore[currentStartShape].score;
					bestMatch = j;
				}
			}
			var i = bestMatch;
			splinesEnd[i].matched = true;
			currentEndShape++;


			//select target
			endShapeMorphInfo[i] = ({
				"target": currentStartShape,
				numPoints: 0,
				startPoint: 0,
				endPoint: 0
			});

			//calc total length
			totalLengthByStartShape[currentStartShape] = totalLengthByStartShape[currentStartShape]||0;
			totalLengthByStartShape[currentStartShape] += splinesEnd[i].cachedLength;

			//increment shape
			currentNShape+=numShapesByShape;
			if (currentNShape >= 1 && currentStartShape<splinesStart.length-1) {
				currentNShape -= 1;
				currentStartShape++;
			}

		}



*/
export default SVGMorph;