import $ from 'jquery';
import * as THREE from 'three';
import SETTINGS from '../Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import Fbo from '../utils/Fbo.js';
import Loader from "../loading/Loader.js";
import SequenceRenderer from './SequenceRenderer.js';

var protocol = 'wss';///https/.test(window.location.protocol) ? 'wss':'ws';
var MAX_USERS = 32;

function CopresenceController(IP, _port) {

	this.socket = null;
	this.socketConnected = false;
	this.socketConnecting = false;

	this.wsId = Math.floor(Math.random()*939393);
	this.receivedId = false;
	this.hasError = false;
	this.latestClicks = new Float32Array();
	this.participants = {};
	this.fakeClients = {};
	this.numUsers = 0;

	this.lastClickFrame = -1;
	this.currentClickFrame = 0;
	// this.participantsScore = 0.0; //should add fakes (0) or not (1)
	this.shouldStartSimulationScore = 0.5;

	this.sentRelease = false;

	this.simulatorEnabled = true;
	this.lastSimulated = 0;
	this.lastSend = 0;
	this.targetNumSimulated = 1;
	this.wasEnabled = false;
	this.numActive = 0;


	//
	// Initialize the this.socket & this events
	//
	this.init = function() {
		if (this.socketConnecting || this.socketConnected) return;
		this.connect();
	}.bind(this);



	//
	// try to connect once
	//
	this.connect = function() {
		if (this.socket || this.socketConnecting || this.socketConnected) return;
		console.log('Websocket - Trying to connect.', SETTINGS.COPRESENCE_SERVER);
		this.socketConnecting = true;
		try {
			this.socket = new WebSocket( protocol+'://'+SETTINGS.COPRESENCE_SERVER);
		} catch (err) {
			console.log('Websocket creation error @', protocol+'://'+SETTINGS.COPRESENCE_SERVER);
		}
		
		this.socket.binaryType = 'arraybuffer';

		if (this.socket) {
			this.socket.onopen = function() {
				console.log('Websocket connected');
				this.hasError = false;
				this.socketConnected = true;
			}.bind(this);

			this.socket.onerror = function(e) {
				console.log('Websocket creation error @', protocol+'://'+SETTINGS.COPRESENCE_SERVER);
				this.hasError = true;
				this.socketConnecting = false;
				this.socketConnected = false;
				// this.socket = null;
			}.bind(this);

			this.socket.onclose = function(e) {
				console.log("Closed websocked");
				this.socketConnecting = false;
				this.socketConnected = false;
				// this.socket = null;
			}.bind(this);

			this.socket.onmessage = this.handleMessage;
		}
	};


	//
	// Get id or current broadcast of all clients clicks
	//
	this.handleMessage = function(e) {
		if (!this.receivedId) {
			this.receivedId = true;
			this.wsId = new Float32Array(e.data)[0];
			console.log("Copresence : Got id",this.wsId);
			return;
		}
		this.latestClicks = null;
		this.latestClicks = new Float32Array(e.data);
		this.currentClickFrame++;
	}.bind(this);


	this.sendPosition = function(dragging, x,y) {
		if (!this.socketConnected || !this.receivedId || SequenceRenderer.simulationStarted) return;
		if (!dragging && performance.now()-this.lastSend < 20000 && this.sentRelease) return; //only send when dragging + onces for release
		this.lastSend = performance.now();
		
		// if (Math.random()<0.95) return;
		this.sentRelease = !dragging;
		this.socket.send(new Float32Array([dragging?1:0,Utils.clamp(x,-1,1),Utils.clamp(y,-1,1)]).buffer);
	}


	//
	// Update in scenes where its needs
	//
	this.update = function(delta) {

		var now = performance.now();

		if (!SETTINGS.REAL_HUMANS_SIMULATOR_ENABLED && SequenceRenderer.copresenceEnabledPc<=0.005) {
			//clean up
			if (this.wasEnabled) {
				this.wasEnabled = false;
				for (var id in this.participants) {
					var client = this.participants[id];
					if (client) {
						if (client.assigned) {
							SequenceRenderer.innactiveCursors.unshift(client.assignedId);
							client.assigned = false;
						}
						delete this.participants[id];
					}
				}
			}
			return;
		}
		this.wasEnabled = true;


		//don't analyze server data twice
		if (this.lastClickFrame !== this.currentClickFrame) {
			this.lastClickFrame = this.currentClickFrame;

			for (var i = 0; i <this.latestClicks.length; i+=4) {
				var id = this.latestClicks[i],
					pressed =  this.latestClicks[i+1]>0,
					x =  this.latestClicks[i+2],
					y =  this.latestClicks[i+3];

				if (Math.abs(id - this.wsId) > 0.1) { //ignore self id
					if (!this.participants[id]) {
						this.participants[id] = {
							id: id,
							pressed: pressed,
							targetX: x,
							targetY: y,
							lastX: x,
							lastY: y,
							currentX: x,
							currentY: y,
							motionX: 0,
							motionY: 0,
							speed: 1.0,
							lastPressed: now,
							lastUpdate: now,

							simulationTypeA: Math.random()>0.5,
							assigned: false,
							assignedId: -1
						};
						this.numUsers++;
					}


					//current speed per ms
					this.participants[id].motionX = Utils.lerp(this.participants[id].motionX, (x-this.participants[id].lastX) / Math.max(now-this.participants[id].lastUpdate,1000/60), 0.75);
					this.participants[id].motionY = Utils.lerp(this.participants[id].motionY, (y-this.participants[id].lastY) / Math.max(now-this.participants[id].lastUpdate,1000/60), 0.75);

					this.participants[id].pressed = pressed;
					this.participants[id].targetX = x;
					this.participants[id].targetY = y;
					this.participants[id].lastX = x;
					this.participants[id].lastY = y;
					this.participants[id].lastUpdate = now;
					if (pressed) this.participants[id].lastPressed = now;
				}
			}
		}

		//
		// cleanup/release old server clients if not new messages for a while
		// 
		var numActive = 0,
			numSimulated = 0;
		for (var id in this.participants) {
			var client = this.participants[id];
			if (client) {

				if (client.simulated) numSimulated++; else numActive++;
				if (client.simulated && this.shouldStartSimulationScore<=0) client.lastUpdate = now-2500;

				if (now - this.participants[id].lastPressed < 2000 && !client.simulated) numActive++;

				if (now-client.lastUpdate >= 1800) {
					client.pressed = false;
				}
				if ((now-client.lastUpdate >= 2500) || (client.simulated && now-client.lastUpdate >= 1700) || (!client.simulated && now-client.lastPressed >= 300)){
					if (client.assigned) {
						SequenceRenderer.innactiveCursors.unshift(client.assignedId);
						client.assigned = false;
					}
					delete this.participants[id];
				}
			}
		}
		this.numActive = numActive;
		



		//
		// NOT ENOUGH clients // server not connected : generate fake ones 
		//
		if (numActive >= 7) {
			this.shouldStartSimulationScore = 0;
		} else {
			this.shouldStartSimulationScore = Utils.deltaSmoothingSnap2(this.shouldStartSimulationScore,1.0-numActive*0.05, 0.01, delta);
		}
		// this.shouldStartSimulationScore*=SequenceRenderer.copresenceEnabledPc>0?1:0;
		
		if (now-this.lastSimulated >= 0) {
			this.lastSimulated = now + 500 + Math.random()*1000;
			this.targetNumSimulated = Utils.clamp(Math.floor(Math.random()*12*this.shouldStartSimulationScore)+1-numActive*1,this.targetNumSimulated-4,this.targetNumSimulated+4);
			if (Math.random()<0.5) 	this.targetNumSimulated = Utils.clamp(Math.floor(Math.random()*8*this.shouldStartSimulationScore)+1-numActive*1,this.targetNumSimulated-4,this.targetNumSimulated+4);
			this.targetNumSimulated = Utils.clamp(this.targetNumSimulated, 0, 12-numActive);

			if (this.shouldStartSimulationScore<=0.25) this.targetNumSimulated = Math.floor(Math.random()*4);
		}

		// if (this.shouldStartSimulationScore<=0.25) this.targetNumSimulated = 3;
		// AppStatus.log(this.targetNumSimulated, this.shouldStartSimulationScore);

		if (this.simulatorEnabled) {

			// if (now - this.lastSimulated >= 0) {

				if (numSimulated < this.targetNumSimulated ) {
					this.lastSimulated = now + 300 + Math.random()*1800;
					if (Math.random()<0.5) this.lastSimulated = now+100;

					var id = Math.floor(Math.random()*93939 + 13);
					this.participants["s"+id] = {
						id: id,
						pressed: true,
						targetX: Utils.random(-0.35, 0.35),
						targetY: Utils.random(-0.35, 0.35),

						nextTx:  Utils.random(-0.35, 0.35),
						nextTy:  Utils.random(-0.35, 0.35),
						nextPc: 0.0,
						nextSpeed: Math.random()*0.75+0.2,

						simulationTypeA: Math.random()>0.5,
						currentX: Utils.random(-0.35, 0.35),
						currentY: Utils.random(-0.35, 0.35),
						lastPressed: now+Math.random()*3000,
						lastUpdate: now+Math.random()*3000,

						speed: Math.random()*0.4+0.1,
						simulated: true,
						assigned: false,
						assignedId: -1
					};
					this.participants["s"+id].oTx = this.participants["s"+id].targetX;
					this.participants["s"+id].oTy = this.participants["s"+id].targetX;
				}
			// }

		} else {
			this.lastSimulated = now + (2000 + Math.random()*1000)*SequenceRenderer.copresenceEnabledPc + (200 * (1.0-SequenceRenderer.copresenceEnabledPc));
		}


		//cleanup old server clients
		// for (var i = 0; i <this.participants.length; i++) {
		// 	if (this.participants[i].pressed)
		// }

		//Smooth update to target for each client
		for (var o in this.participants) {
			var client = this.participants[o];

			if (!client.assigned && client.pressed && SequenceRenderer.innactiveCursors.length>0 && (SequenceRenderer.copresenceEnabled||(SETTINGS.REAL_HUMANS_SIMULATOR_ENABLED&&!client.simulated))) {
				client.assigned = true;
				client.assignedId = SequenceRenderer.innactiveCursors.pop();
			}

			if (client && client.assigned) {
				if (client.simulated) {

					client.nextPc += client.nextSpeed*0.05*delta;

					if (client.nextPc >= 1.0) {
						client.nextPc -= 1.0;
						client.oTx = client.nextTx;
						client.oTy = client.nextTy;
						client.nextTx = Utils.random(-0.3, 0.3);
						client.nextTy = Utils.random(-0.3, 0.3);
					}

					client.targetX = Utils.lerp(client.oTx, client.nextTx, client.nextPc);
					client.targetY = Utils.lerp(client.oTy, client.nextTy, client.nextPc);
					// console.log(client.nextPc);
				}

				client.currentX = Utils.deltaSmoothingSnap2(
					client.currentX,
					client.targetX,
					(client.pressed?0.07:0.07)*client.speed,
					delta
				);
				client.currentY = Utils.deltaSmoothingSnap2(
					client.currentY,
					client.targetY,
					(client.pressed?0.07:0.07)*client.speed,
					delta
				);

				if (!client.simulated) {
					client.targetX += client.motionX * delta * (1000/60) * 0.75;
					client.targetY += client.motionY * delta * (1000/60) * 0.75;
				}


			}
		}

	};



	//-----------------------------------
	//
	//  The visual feedback
	//
	//-----------------------------------
	this.visualUsers = {};
	this.fadedTime = -5000;
	this.faded = true;
	this.preload = function(batchName) {
		// Loader.addShader(batchName, 'shaders/cursor/copresence.vert');
		// Loader.addShader(batchName, 'shaders/cursor/copresence.frag');
	};

	this.setupVisual = function() {
		if (this.visualWasSetup) return;
		this.visualWasSetup = true;
		this.faded = true;


		// this.scene = new THREE.Scene();
		// this.planeMaterial = new THREE.RawShaderMaterial({
		// 	vertexShader: Loader.getShader('shaders/cursor/copresence.vert'),
		// 	fragmentShader: Loader.getShader('shaders/cursor/copresence.frag'),
		// 	blending: THREE.AdditiveBlending,
		// 	transparent: true,
		// 	depthTest: false,
		// 	depthWrite: false,
		// 	side: THREE.DoubleSide,
		// 	uniforms: {
		// 		tNoise: { type: 't', value: this.noiseTexture },
		// 		time: { type: 'f', value: 0},
		// 		randomOffset: { type: 'v2', value: new THREE.Vector2()},
		// 		alpha: { type: 'f', value: 0},
		// 		speed: { type: 'f', value: 0},
		// 		direction: { type: 'v2', value: new THREE.Vector2(1,1)},
		// 		resolution: { type: 'v2', value:new THREE.Vector2(renderer.domElement.width, renderer.domElement.height)}
		// 	}
		// })
		// Utils.compileMaterial(this.planeMaterial);


	};

	this.fadeInVisual = function() {
		if (!this.faded) return;
		this.faded = false;
		// $('#copresence-overlay').fadeIn(2000);
	};

	this.fadeOutVisual = function() {
		if (this.faded) return;
		this.faded = true;
		this.fadedTime = performance.now();
		// $('#copresence-overlay').fadeOut(2000);
	};


	this.updateVisual = function(delta) {
		var now = performance.now();
		if (this.faded && now - this.fadedTime >= 5000) return;


		this.participants["self"] = {
			id: this.wsId,
			pressed: SequenceRenderer.isDragging,
			targetX: SequenceRenderer.targetPosition.x,
			targetY: SequenceRenderer.targetPosition.x,
			currentX: SequenceRenderer.rawPosition.x,
			currentY: SequenceRenderer.rawPosition.y,
			lastUpdate: performance.now()
		};


		//select a number of users (show all for now)
		for (var id in this.participants) {
			var client = this.participants[id],
				user = this.visualUsers[id] ;

			// if (id=="self") console.log(client.id);

			//add div & assign plane
			if (!user && client && client.pressed) {
				console.log("create!");
				var innerDiv = document.createElement('div');
				$(innerDiv).addClass('copresence-user-text').text('Participant #'+client.id);

				var div = document.createElement('div')
				$(div).addClass('copresence-user')
					.css('transform', 'translate('+((client.currentX+0.5) * SequenceRenderer.cameraStretch.x * AppStatus.innerWidth())+'px,'+((client.currentY+0.5) * SequenceRenderer.cameraStretch.y * AppStatus.innerHeight())+'px)')
					.css('opacity',0.0).fadeTo(id=="self"?100:200,1.0)
					.append(innerDiv);
				// $('#copresence-overlay').append(div);

				// //create a 3d plane
				// var c = new THREE.Mesh(Utils.planeGeometry, this.planeMaterial.clone());
				// c.visible = true;
				// c.scale.set(0.05,0.05,0.05);
				// c.material.uniforms.alpha.value = 0.0;
				// this.scene.add(c);


				user = this.visualUsers[id] = {
					div: div
					// plane: c
				};
			} else if (user && client && client.pressed && user.fading) {
				user.fading = false;
				$(user.div).stop(true).fadeTo(50,1.0);
			}
		}

		//update
		for (var id in this.visualUsers) {
			var user = this.visualUsers[id],
				client = this.participants[id];

			// if (user && client) {
			// 	var plane = user.plane;
			// 	plane.material.uniforms.tNoise.value = SequenceRenderer.noiseTexture;
			// 	plane.material.uniforms.time.value = now/1000;
			// 	plane.material.uniforms.randomOffset.value.set(Math.random(), Math.random());
			// 	plane.material.uniforms.resolution.value.set(renderer.domElement.width, renderer.domElement.height);
			// 	plane.material.uniforms.alpha.value = Utils.lerp(plane.material.uniforms.alpha.value, client.pressed?1.0:0.0, 0.1);
			// 	plane.position.set(client.currentX, -client.currentY); //-this.rawPosition.y);
			// // Utils.autoReloadShaderManual(plane.material, 'shaders/cursor/copresence.vert', 'shaders/cursor/copresence.frag');

			// }
			

			//release!
			if (!user.fading && (!client || (client && !client.pressed))) {
				((user, id) => {
					user.fading = true;
					$(user.div).stop(true).fadeTo(id=="self"?150:250,0.0, () =>{
						if (!user.fading) return;
						$(user.div).remove();
						user.faded = true;
						user.div = null;
						// this.scene.remove(user.plane);
						// user.plane.material.dispose();
						// user.plane = null;
						delete this.visualUsers[id];
					});
				})(user, id);
			}
			
			//update!
			if (client && user && !user.faded) {

				$(user.div).css('transform', 'translate('+((client.currentX+0.5) * AppStatus.innerWidth())+'px,'+((client.currentY+0.5) * AppStatus.innerHeight())+'px)');

			}

		}


		this.participants["self"] = undefined;
	};

	this.render = function(target) {

		// renderer.render(this.scene, Utils.orthographicCamera);
	};

	this.connected = function() {
		return this.socketConnected;
	};
};
window.CopresenceController = window.CopresenceController||new CopresenceController();
export default window.CopresenceController;