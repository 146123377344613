import SETTINGS from '../Settings.js';
import AppStatus from './AppStatus.js';
import Loader from "../loading/Loader.js";
import LoaderAudio from "../loading/LoaderAudio.js";
import BranchController from './BranchController.js';

import MenuController from "./MenuController.js";

import AudioDisposeController from "./AudioDisposeController.js";



var AUDIO_DIVISION_DURATION = 60.0;
var AUDIO_DIVISION_OVERLAP = 0.35; //
var AUDIO_DIVISION_OVERLAP_SKIP = 0.026122448979592; //skip beginning frame of mp3



// var AUDIO_DIVISION_DURATION = 3.0;
// var AUDIO_DIVISION_OVERLAP = 0.35; //
// var AUDIO_DIVISION_OVERLAP_SKIP = 0.026122448979592; //skip beginning frame of mp3


var BRANCH_CROSSFADE = 0.05;
var LOOKAHEAD_TIME = 0.25;
var MAX_FILE = 19;
var audioFolder = "audio/timeline/";




class AudioControllerStatic {

	constructor() {
		this.AUDIO_FILE = "v5_Julianne_1";
		this.audioDivisions = [
	        0.0,
	        64.0,
	        124.25,
	        189.5,
	        256.0,
	        307.0,
	        371.5,
	        418.0,
	        480.0,
	        537.0,
	        606.0,
	        659.5,
	        717.25,
	        777.0,
	        842.5,
	        902.5,
	        961.5,
	        1026.0,
	        1087.0,
	        9999.0
	    ];
	}
	setup(opt) {
		this.options = opt;

		//mode tween, mode jouer loops
		this.audioBuffersByName = {};
		this.audioLoadingByName = {};

		//gui
		this.audioShouldPlay = false;
		this.timelineStarted = false;
		this.audioPlaying = false;
		this.currentBufferIndex = 0;
		this.resyncing = false;
		this.TIMELINE_MODE = false;
		this.audioData = new Uint8Array( 31 );
		this.volume = 1.0;
		this.currentBufferEndTime = 0;


		//pause for branch or extras
		this.pausedForBranch = false;
		this.pausedForExtra = false;
		this.pausedForMenu = false;
	}

	setAudioFile(file, divisions) {
		this.AUDIO_FILE = file;
		this.audioDivisions = divisions;
	}


	preloadTimeline(batchName, loader, sequence) {
		this.audioReady = true;
		this.TIMELINE_MODE = true;

		//add audio files necessary for this sequence
		var t = sequence.timecode;
		var loadingFiles = {};

		// var targetBufferIndex = Math.floor(t / AUDIO_DIVISION_DURATION);

		while (t <= sequence.timeEnd) {
			var targetBufferIndex = this.getBufferIndexAtTime(t), shouldBreak = false;
			if (targetBufferIndex > this.audioDivisions.length-2) {
				targetBufferIndex = this.audioDivisions.length-2;
				shouldBreak = true;
			}
			
			var currentFile = this.AUDIO_FILE+'_'+Utils.zeroPad(targetBufferIndex,2)+'.mp3';

			this.audioLoadingByName[currentFile] = true;
			if (!loadingFiles[currentFile]) {
				loadingFiles[currentFile] = true;
				this.audioBuffersByName[currentFile] = loader.addAudio(batchName, audioFolder+currentFile, true);
			}
			t = this.audioDivisions[targetBufferIndex+1];
			if (shouldBreak) {
				break;
			}
		}
	}

	//----------------
	//
	// Update loading in editor
	//
	//----------------
	preloadEditor() {

		// var targetBufferIndex = Math.floor(this.getCurrentTime() / AUDIO_DIVISION_DURATION);
		// if (targetBufferIndex > Math.floor(AppStatus.AUDIO_DURATION/AUDIO_DIVISION_DURATION)) targetBufferIndex = Math.floor(AppStatus.AUDIO_DURATION/AUDIO_DIVISION_DURATION);

		// var targetBufferIndex = Math.floor(this.getCurrentTime() / AUDIO_DIVISION_DURATION);
		// if (targetBufferIndex > Math.floor(AppStatus.AUDIO_DURATION/AUDIO_DIVISION_DURATION)) targetBufferIndex = Math.floor(AppStatus.AUDIO_DURATION/AUDIO_DIVISION_DURATION);

		var targetBufferIndex = this.getBufferIndexAtTime(this.getCurrentTime());
		if (targetBufferIndex > this.audioDivisions.length-2) targetBufferIndex = this.audioDivisions.length-2;



		//
		// Update loading : make sure the audio file is ready before playing
		//
		var currentFile = audioFolder+this.AUDIO_FILE+'_'+Utils.zeroPad(targetBufferIndex,2)+'.mp3';
		if (!this.audioBuffersByName[currentFile] && !this.audioLoadingByName[currentFile]) {
			this.audioLoadingByName[currentFile] = true;
			((fileName)=>{
				var req = new LoaderAudio(fileName, true);
				req.start(() =>{
					this.audioBuffersByName[fileName] = req.data;
				},console.warn);
				Loader.allAssets[fileName] = req;
			})(currentFile);
		}
		this.audioReady = this.audioBuffersByName[currentFile];

		var nextFile = audioFolder+this.AUDIO_FILE+'_'+Utils.zeroPad(targetBufferIndex+1,2)+'.mp3';
		if (this.audioReady && !this.audioBuffersByName[nextFile] && !this.audioLoadingByName[nextFile] && this.currentBufferIndex+1 < 14) {
			this.audioLoadingByName[nextFile] = true;
			((fileName)=>{
				var req = new LoaderAudio(fileName, true);
				req.start(() =>{
					this.audioBuffersByName[fileName] = req.data;
				},console.warn);
				Loader.allAssets[fileName] = req;
			})(nextFile);	
		}
	}


	//----------------
	//
	// Setup
	//
	//----------------
	startContext(callback) {
		if ((this.contextStarted || this.contextStarting) && !SETTINGS.INSTALLATION_MODE) return;
		this.contextStarting = true;

		// var forceSR = window.audioContext.createBufferSource()
		// forceSR.buffer = window.audioContext.createBuffer(1, 1, 44100);
		// forceSR.connect(window.audioContext.destination);
		// forceSR.start(window.audioContext.currentTime);
		// forceSR.disconnect();

		audioContext.resume().then(() =>{
			this.contextStarted = true;

			this.finalGain = audioContext.createGain();
			this.finalGain.connect(audioContext.destination);

			this.analyser = audioContext.createAnalyser();
			this.analyser.fftSize = 32;
			this.audioData = new Uint8Array( this.analyser.frequencyBinCount );
			this.finalGain.connect( this.analyser );

			if (callback) callback();
		})
	}


	//----------------
	//
	// External Controls
	//
	//----------------
	playAudio(startPosition) {
		// console.warn("PLAY AUDIO", startPosition);
		var ct = startPosition!==undefined ? startPosition : this.getCurrentTime();
		// var startTime = ct % AUDIO_DIVISION_DURATION;
		var targetBufferIndex = this.getBufferIndexAtTime(ct);
		if (targetBufferIndex > this.audioDivisions.length-2) targetBufferIndex = this.audioDivisions.length-2;
		
		var startOffset = ct - this.audioDivisions[targetBufferIndex];


		// Math.floor(ct / AUDIO_DIVISION_DURATION);
		// if (targetBufferIndex > Math.floor(AppStatus.AUDIO_DURATION/AUDIO_DIVISION_DURATION)) targetBufferIndex = Math.floor(AppStatus.AUDIO_DURATION/AUDIO_DIVISION_DURATION);

		var mainGain = audioContext.createGain();
		mainGain.gain.tweenToValue(0.0, 0.0);
		mainGain.gain.tweenToValue(this.volume, 0.075);
		mainGain.connect(this.finalGain);

		var mainSound = audioContext.createBufferSource();
		mainSound.loop = false;
		mainSound.connect(mainGain);
		var file = SETTINGS.TIMELINE_MODE?this.AUDIO_FILE:this.AUDIO_FILE;
		
		var binaryBuffer = Loader.getAsset(audioFolder+file+'_'+Utils.zeroPad(targetBufferIndex,2)+'.mp3');
		if (binaryBuffer && binaryBuffer.value && binaryBuffer.value.buffer) {
			mainSound.buffer = binaryBuffer.value.buffer;
			if (Math.abs(startOffset) < mainSound.buffer.duration) {
				// console.log("playing!", audioContext.currentTime, startTime);
				mainSound.start(audioContext.currentTime, startOffset );
			}
			this.audioPlaying = true;
		} else {
			console.warn("Not loaded: ", audioFolder+file+'_'+Utils.zeroPad(targetBufferIndex,2)+'.mp3');
			this.audioPlaying = false;
			mainGain.disconnect(this.finalGain);
			mainSound.disconnect(mainGain);
		}

		this.mainSound = mainSound;
		this.mainGain = mainGain;
		this.currentBufferIndex = targetBufferIndex;


		this.timelineStartTime = audioContext.currentTime - this.audioDivisions[targetBufferIndex] - startOffset;
		this.currentTime = ct;
		this.currentBufferEndTime = audioContext.currentTime - startOffset + (this.audioDivisions[targetBufferIndex+1]-this.audioDivisions[targetBufferIndex]);
			


			// console.log("Current time, current buffer:", this.getCurrentTime(), this.currentBufferIndex);
			// 	console.log("Current time ahead:", this.getCurrentTimeAhead(), targetBufferIndex);
			// 	console.log("Audio time:", audioContext.currentTime);
			// 	console.log("Audio start time:", startOffset);

		// this.timelineStartTime = audioContext.currentTime - this.audioDivisions[targetBufferIndex] - startTime;
		// this.currentTime = audioContext.currentTime - this.timelineStartTime;
		// this.currentBufferEndTime = audioContext.currentTime + (this.audioDivisions[targetBufferIndex+1]-this.audioDivisions[targetBufferIndex])-startTime;

		// console.log(startPosition, ct, targetBufferIndex, startTime, audioContext.currentTime, this.timelineStartTime, this.currentTime, this.currentBufferEndTime);

	}


	stopAudio() {
		// console.warn("STOP AUDIO");
		this.soundPauseTime = this.getCurrentTime();
		this.audioPlaying = false;

		if (this.mainGain) {
			this.mainGain.gain.tweenToValue(0.0, 0.1);
			AudioDisposeController.dispose(this.mainSound, 0.1);
			AudioDisposeController.dispose(this.mainGain, 0.1);
			this.mainGain = this.mainSound = null;
		}
	}



	pauseAudioBuffering() {

	}

	
	pauseAudioForBranch(forceTime) {
		//pause sound for branch

		console.log("PAUSE AUDIO AT TIME", forceTime);
		this.soundPauseTime = this.getCurrentTime();
		if (forceTime) this.currentTime = this.soundPauseTime = forceTime;
		this.pausedForBranch = true;

		//fade out current
		if (this.mainGain) {
			this.mainGain.gain.tweenToValue(0.0, BRANCH_CROSSFADE);
			AudioDisposeController.dispose(this.mainSound, BRANCH_CROSSFADE);
			AudioDisposeController.dispose(this.mainGain, BRANCH_CROSSFADE);
			this.mainGain = this.mainSound = null;
		}
	}

	endBranching() {
		this.pausedForBranch = false;

		console.log("end branching, restart audio",this.soundPauseTime);
		// if (this.audioShouldPlay && !this.audioPlaying) {
			this.currentTime = this.soundPauseTime;
			this.resyncing = true;
			this.playAudio(this.soundPauseTime);
			this.resyncing = false;
		// }
	}


	getCurrentTime() {
		if (!this.audioPlaying || this.pausedForBranch) {
			this.currentTime = this.soundPauseTime||0;
		} else if (this.audioPlaying) {
			this.currentTime = audioContext.currentTime - this.timelineStartTime;
		}
		this.currentTime = Math.min(this.currentTime, AppStatus.AUDIO_DURATION);
		return this.currentTime;
	}
	getCurrentTimeAhead() {
		if (!this.audioPlaying || this.pausedForBranch) {
			this.currentTime = this.soundPauseTime||0;
		} else if (this.audioPlaying) {
			this.currentTime = audioContext.currentTime - this.timelineStartTime;
		}
		this.currentTime = Math.min(this.currentTime+LOOKAHEAD_TIME, AppStatus.AUDIO_DURATION);
		return this.currentTime;
	}

	seek(ct) {
		this.currentTime = this.soundPauseTime = ct;
		if (!this.audioPlaying) return;
		// if (!SETTINGS.TIMELINE_MODE) this.preloadEditor();
		this.resyncing = true;
		this.stopAudio();
		this.currentTime = AudioController.soundPauseTime = ct;
		// if (this.audioReady) this.playAudio(ct);

	}


	// getTargetBufferIndex() {
	// 	var currentTime = this.getCurrentTime();
	// 	var targetBufferIndex = Math.floor(currentTime / AUDIO_DIVISION_DURATION);
	// 	if (BranchController.isOnBranch()) 
	// 	for (var i = 0; i <BranchController.branches.length; i++) {
	// 		var branch = BranchController.branches[i];
	// 		if (currentTime >= branch[i].timecode-0.2 && currentTime <= branch[i].timecode+1.0) {
	// 		}
	// 	}
	// }
	getBufferIndexAtTime(time) {
		var index = 0;
		for (var i=0; i<this.audioDivisions.length; i++) {
			if (time >= this.audioDivisions[i]) index = i; else break;
		}
		return index;
	}

	//----------------
	//
	// Update audio every frame, loop and transition between buffers
	//
	//----------------
	update() {
		var opt = this.options;

		this.audioShouldPlay = this.timelineStarted && !this.pausedForMenu;

		//
		// Start audio
		//
		if (this.audioReady && this.contextStarted && !this.pausedForBranch) {
			var targetBufferIndex = this.getBufferIndexAtTime(this.getCurrentTimeAhead());
			if (targetBufferIndex > this.audioDivisions.length-2) targetBufferIndex = this.audioDivisions.length-2;
			
			if (this.audioShouldPlay && !this.audioPlaying) {

				this.playAudio();
				this.resyncing = false;

			//change to branch
			// } else if (this.audioPlaying && !this.pausedForBranch && BranchController.shouldStartBranch()) {

			// 	//pause sound for branch
			// 	BranchController.startCurrentBranch();
			// 	this.soundPauseTime = this.getCurrentTime();
			// 	this.pausedForBranch = true;

			// 	//fade out current
			// 	this.mainGain.gain.tweenToValue(0.0, BRANCH_CROSSFADE);
			// 	AudioDisposeController.dispose(this.mainSound, BRANCH_CROSSFADE);
			// 	AudioDisposeController.dispose(this.mainGain, BRANCH_CROSSFADE);

			// //change to branch
			// } else if (this.audioPlaying && !this.pausedForBranch && BranchController.shouldStartBranch()) {

			// 	//pause sound for branch
			// 	this.pausedForBranch = true;
			// 	this.audioPlaying = false;

			// 	//fade out current
			// 	this.mainGain.gain.tweenToValue(0.0, BRANCH_CROSSFADE);
			// 	AudioDisposeController.dispose(this.mainSound, BRANCH_CROSSFADE);
			// 	AudioDisposeController.dispose(this.mainGain, BRANCH_CROSSFADE);

			// 	//start new sound
			// 	var mainGain = audioContext.createGain();
			// 	mainGain.gain.tweenToValue(0.0, 0.0);
			// 	mainGain.gain.tweenToValue(this.volume, BRANCH_CROSSFADE);
			// 	mainGain.connect(this.finalGain);

			// 	var mainSound = audioContext.createBufferSource();
			// 	mainSound.loop = false;
			// 	mainSound.connect(mainGain);
			// 	var file = SETTINGS.TIMELINE_MODE?this.AUDIO_FILE:this.AUDIO_FILE;
			// 	mainSound.buffer = Loader.getAsset(audioFolder+file+'_'+Utils.zeroPad(targetBufferIndex,2)+'.mp3').value.buffer;
			// 	mainSound.start(audioContext.currentTime, startTime);


			} else if (!this.TIMELINE_MODE && this.audioShouldPlay && this.audioPlaying && (this.getCurrentTime() < Utils.timecodeToSeconds(opt.sequence.timeline.timeStart)-0.5 || this.getCurrentTime() >= Utils.timecodeToSeconds(opt.sequence.timeline.timeEnd))) {
				this.stopAudio();
				this.currentTime = this.soundPauseTime = Utils.timecodeToSeconds(opt.sequence.timeline.timeStart);
				this.preloadEditor();
				if (this.audioReady) this.playAudio(Utils.timecodeToSeconds(opt.sequence.timeline.timeStart));


			//change to next audio file
			} else if (this.audioShouldPlay && this.audioPlaying && this.currentBufferIndex != targetBufferIndex) {
				
				console.log("-----------NEXT BUFFER--------------");
				//current time in current buffer
				// var diff = (this.getCurrentTimeAhead() > );




				//if over current buffer duration: fade out for time left (ex 60.5-60.1=0.4)
				var tweenDuration =  AUDIO_DIVISION_OVERLAP; //Math.max(AUDIO_DIVISION_OVERLAP - diff,0.0);

				// console.log(diff, tweenDuration);
				//if no tween, start time is at 0
				var startTime = Math.max(this.currentBufferEndTime, audioContext.currentTime);
				// console.log(audioContext.currentTime, startTime, startTime-audioContext.currentTime);

				//fade out current
				if (this.mainGain) {
					this.mainGain.gain.setValueAtTime(Math.max(this.volume,0.00001), startTime+AUDIO_DIVISION_OVERLAP_SKIP);
					this.mainGain.gain.exponentialRampToValueAtTime(0.00001, startTime+AUDIO_DIVISION_OVERLAP_SKIP+tweenDuration);

					AudioDisposeController.dispose(this.mainSound, tweenDuration+AUDIO_DIVISION_OVERLAP_SKIP+LOOKAHEAD_TIME);
					AudioDisposeController.dispose(this.mainGain, tweenDuration+AUDIO_DIVISION_OVERLAP_SKIP+LOOKAHEAD_TIME);
					this.mainGain = this.mainSound = null;
				}

				
				//start new sound
				var mainGain = audioContext.createGain();
				mainGain.gain.setValueAtTime(0.0, audioContext.currentTime);
				mainGain.gain.setValueAtTime(0.00001, startTime+AUDIO_DIVISION_OVERLAP_SKIP);
				mainGain.gain.exponentialRampToValueAtTime(Math.max(this.volume,0.00001), startTime+AUDIO_DIVISION_OVERLAP_SKIP+tweenDuration);
				// mainGain.gain.linearRampToValueAtTime(this.volume, startTime+tweenDuration);

				// mainGain.gain.tweenToValue(0.0, 0.0);
				// mainGain.gain.tweenToValue(this.volume, tweenDuration);
				mainGain.connect(this.finalGain);

				var mainSound = audioContext.createBufferSource();
				mainSound.loop = false;
				mainSound.connect(mainGain);
				var file = SETTINGS.TIMELINE_MODE?this.AUDIO_FILE:this.AUDIO_FILE;
				mainSound.buffer = Loader.getAsset(audioFolder+file+'_'+Utils.zeroPad(targetBufferIndex,2)+'.mp3').value.buffer;
				mainSound.start(startTime);


				this.mainSound = mainSound;
				this.mainGain = mainGain;
				this.currentBufferIndex = targetBufferIndex;

				//audio context theorical zero of sound time
				this.timelineStartTime = startTime - this.audioDivisions[targetBufferIndex]; //time to timeline zero start point
				this.currentTime = startTime - this.timelineStartTime; //current time in timeline
				this.currentBufferEndTime = startTime + (this.audioDivisions[targetBufferIndex+1]-this.audioDivisions[targetBufferIndex]);
				

				// console.log("Current time, current buffer:", this.getCurrentTime(), this.currentBufferIndex);
				// console.log("Current time ahead:", this.getCurrentTimeAhead(), targetBufferIndex);
				// console.log("Audio time:", audioContext.currentTime);
				// console.log("Audio start time:", startTime);

				// console.log(startTime, this.timelineStartTime, this.currentTime, this.currentBufferEndTime);
			}
			//resync audio if visual an audio are off?
		}

		if (this.audioPlaying && this.analyser) {
			this.analyser.getByteFrequencyData( this.audioData );
		}

		//stop audio
		if (this.audioPlaying && (!this.audioReady || !this.audioShouldPlay)) {
			this.stopAudio();
		}
	}
}
window.AudioController = window.AudioController||new AudioControllerStatic();
export default window.AudioController;