import $ from 'jquery';
import FastEvent from './FastEvent.js';
import AppStatus from "../controllers/AppStatus.js";

/*
	<div class="ui-window">
		<div class="ui-window-header-container">
			<div class="ui-window-header">
				<div class="ui-folder-arrow"></div>
				<div class="ui-window-header-label"></div>
				<div class="ui-window-header-closebtn"></div>
			</div>
		</div>
		<div class="ui-window-container">
			<div class="ui-row"></div> <!-- from folder -->
		</div>
	</div>
*/

function Window(UI) {
	if (!UI.enabled) return;

	//
	// create the divs, classes & properties
	//
	var windowDiv = document.createElement('div'),
		headerContainerDiv = document.createElement('div'),
		headerDiv = document.createElement('div'),
		headerLabelDiv = document.createElement('div'),
		arrowDiv = document.createElement('div'),
		headerCloseButtonDiv = document.createElement('div'),
		containerDiv = document.createElement('div'),
		folder = null;

	UI.allWindows.push(this);

	windowDiv.appendChild(headerContainerDiv);
	headerContainerDiv.appendChild(headerDiv);

	headerDiv.appendChild(arrowDiv);
	headerDiv.appendChild(headerLabelDiv);
	headerDiv.appendChild(headerCloseButtonDiv);


	windowDiv.appendChild(containerDiv);
	this.domElement = windowDiv;


	$(windowDiv).addClass('ui-window');
	$(headerContainerDiv).addClass('ui-window-header-container')
	$(headerDiv).addClass('ui-window-header');
	$(headerLabelDiv).addClass('ui-window-header-label');
	$(arrowDiv).addClass('ui-window-header-hidebtn');
	$(arrowDiv).text('▼');
	$(arrowDiv).css('transform', 'rotate(-90deg)')
	$(headerCloseButtonDiv).addClass('ui-window-header-closebtn');

	$(containerDiv).addClass('ui-row');

	$(windowDiv).css('top', '200px');
	$(windowDiv).css('left', '200px');
	$(windowDiv).css('z-index', ++UI.currentZIndex);

	//-----------------------------
	//
	// Init a window from a folder
	//
	//-----------------------------
	this.disposed = false;
	this.windowName = '';
	this.currentEngineName = '';

	this.initFromFolder = function(soundEngine, options, propName, ranges, path) {
		this.path = path;
		this.optionsSource = options;
		this.rangesSource = ranges;

		this.ranges = ranges[propName];
		this.options = options[propName];
		this.propName = propName;
		this.soundEngine = soundEngine;

		if (folder) {
			folder.dispose();
		}

		if (this.ranges && this.ranges._type && UI.Templates[this.ranges._type] && UI.Templates[this.ranges._type].subfolder) {
			if (UI.themeColors[UI.Templates[this.ranges._type].subfolder]) {
				$(headerDiv).css('background-color', UI.themeColors[UI.Templates[this.ranges._type].subfolder]);
			}
		}


		this.windowName = (this.ranges.windowName && typeof this.ranges.windowName === 'string') ? this.ranges.windowName : path.join('/');
		$(headerLabelDiv).text(this.windowName);

		this.currentEngineName = this.propName;
		if (this.ranges.windowName && typeof this.ranges.windowName === 'string') {
			this.soundEngine.changeName(this.propName, this.ranges.windowName);
			this.currentEngineName = this.ranges.windowName;
		}

		folder = UI.createFolder(this.options, propName, this.ranges, path, path.length);
		FastEvent.trigger(folder, 'open');
		containerDiv.appendChild(folder.rowDiv);

		
		if (this.ranges.themeColor) this.updateTheme();
		if (this.ranges.headerColor) $(headerDiv).css('background-color',this.ranges.headerColor);

		folder.enableUIUpdates();
		folder.disableUIUpdates();

		UI.triggerProperty(this.soundEngine, 'redrawCanvas');
	};

	//-------------------
	//
	//  Drag Window Around
	//
	//-------------------
	this.positionX = 200;
	this.positionY = 200;
	this.isDragging = false;
	this.uuid = Math.floor(Math.random() * 100000000000).toFixed(0);
	this.path = [];
	this.options = null;
	this.propName = null;

	var isActuallyDragging = false,
		wasActuallyDragging = false,
		draggingStart = 0,
		draggingStartX = 0,
		draggingStartY = 0,
		draggingStartPosX = 0,
		draggingStartPosY = 0;

	//
	// Update positon
	//
	this.dragUpdate = function(e) {

		if (!isActuallyDragging) {
			if (performance.now() - draggingStart >= 10) {
				isActuallyDragging = true;
				wasActuallyDragging = true;
				$(document.body).css('cursor','move');
				$(headerDiv).css('cursor','move');
			}
		}
		// draggingStart = performance.now();
		var dx = e.pageX !== undefined ? e.pageX : e.originalEvent.touches[0].pageX,
			dy = e.pageY !== undefined ? e.pageY : e.originalEvent.touches[0].pageY + $('body').scrollTop();

		if (!e.shiftKey) {

			this.positionX += dx - draggingStartX;
			this.positionY += dy - draggingStartY;

			this.setPosition(this.positionX, this.positionY);

			draggingStartX = dx;
			draggingStartY = dy;
			draggingStartPosX = draggingStartX;
			draggingStartPosY = draggingStartY;

		} else {

			this.positionX = ( (dx - draggingStartX) + draggingStartPosX );
			this.positionY = ( (dy - draggingStartY) + draggingStartPosY );
			this.setPositionSnap(this.positionX, this.positionY);

		}
	
		this.ranges.positionX = this.positionX;
		this.ranges.positionY = this.positionY;

		var body = document.body, html = document.documentElement;
		var height = Math.max( body.scrollHeight, body.offsetHeight, 
                   html.clientHeight, html.scrollHeight, html.offsetHeight );
		$('#container').css('height', height + 'px' );

		e.preventDefault();
		e.stopPropagation();

		UI.triggerProperty(this.soundEngine, 'redrawCanvas');

	}.bind(this);

	// stop drag
	this.dragOff = function(e) {
		this.isDragging = false;
		isActuallyDragging = false;
		$(window).off('mousemove.'+this.uuid+' touchmove.'+this.uuid);
		$(window).off('touchend.'+this.uuid+' mouseup.'+this.uuid);
		$(document.body).css('cursor','auto');
		$(headerDiv).css('cursor','pointer');


		e.preventDefault();

	}.bind(this);

	//
	// Start drag
	//
	$(headerDiv).on('mousedown touchstart', function(e) {

		if (this.isDragging || e.button > 0) return;
		this.isDragging = true;

		$(windowDiv).css('z-index', ++UI.currentZIndex);

		isActuallyDragging = false;

		draggingStart = performance.now();
		draggingStartX = e.pageX || e.originalEvent.touches[0].pageX;
		draggingStartY = e.pageY || e.originalEvent.touches[0].pageY;
		draggingStartPosX = this.positionX;
		draggingStartPosY = this.positionY;

		$(window).on('mousemove.'+this.uuid+' touchmove.'+this.uuid, this.dragUpdate);
		$(window).on('touchend.'+this.uuid+' mouseup.'+this.uuid, this.dragOff);

		e.preventDefault();
		e.stopPropagation();

	}.bind(this));

	$(headerDiv).on('mouseup touchend', function(e) {
	
		if (isActuallyDragging || wasActuallyDragging || e.button > 0) {
			return;
		}
		$(headerDiv).css('cursor', 'pointer');

	}.bind(this));


	//
	// External move
	//
	this.setPosition = function(x,y) {
		// if (this.isMixer) {
		// 	var mixerPos = parseInt(this.isMixer.split('_').pop(),10);
		// 	if (isNaN(mixerPos) || !isFinite(mixerPos)) mixerPos = 0;
		// 	x = mixerPos * 256;
		// 	y = UI.headerStart;
		// 	if (/mix_out/.test(this.isMixer)) {
		// 		x = 0;
		// 		y = UI.headerStart + 190*mixerPos;
		// 	}
		// }

		this.positionX = Math.max(x, -100); //),AppStatus.innerWidth()-100);
		this.positionY = Math.max(y, UI.headerStart);

		$(windowDiv).css('left', this.positionX+'px');
		$(windowDiv).css('top', this.positionY+'px');
		var body = document.body, html = document.documentElement;
		var height = Math.max( body.scrollHeight, body.offsetHeight, 
                   html.clientHeight, html.scrollHeight, html.offsetHeight );
		$('#container').css('height', height + 'px' );

		this.ranges.positionX = this.positionX;
		this.ranges.positionY = this.positionY;
	};

	this.setPositionSnap = function(x,y) {

		this.positionX = Math.round(  x / 256 ) * 256;
		this.positionY = Math.floor( ( y - 30) / 28) * 28 + UI.headerStart;

		this.setPosition(this.positionX, this.positionY);

	};

	//-------------------
	//
	//  open / close
	//
	//-------------------
	FastEvent.on(this,'open', function() {
		$(headerDiv).toggleClass('open', true);
		$(arrowDiv).css('transform', 'rotate(0deg)')

		$(containerDiv).toggleClass('closed', false);
		$(containerDiv).toggleClass('open', true);

	}.bind(this));

	FastEvent.on(this,'close', function() {
		$(headerDiv).toggleClass('open', false);
		$(arrowDiv).css('transform', 'rotate(-90deg)')
		$(containerDiv).toggleClass('open', false);
		$(containerDiv).toggleClass('closed', true);
	}.bind(this));

	$(headerDiv).on('click touchend', function(e) {
		if (e.shiftKey && this.soundElement) return;
		if (wasActuallyDragging) {
			wasActuallyDragging = false;
			return;
		}
		wasActuallyDragging = false;
		this.isOpen = !this.isOpen;
		FastEvent.trigger(this, this.isOpen ? 'open' : 'close');
	}.bind(this));
	this.isOpen = true;
	FastEvent.trigger(this, 'open');



	this.updatePropRangeHeaderColor = function() {
		$(headerDiv).css('background-color',this.ranges.headerColor);
	}.bind(this);
	this.updatePropRangeThemeColor = function() {
		this.updateTheme();
	}.bind(this);
	this.updatePropRangeWindowName = function() {
		$(headerLabelDiv).text(this.ranges.windowName);
		this.windowName = this.ranges.windowName;
		this.soundEngine.changeName(this.currentEngineName, this.ranges.windowName);
		this.currentEngineName = this.ranges.windowName;
	}.bind(this);

	
	//-------------------
	//
	//  Right-Click header to change properties
	//
	//-------------------
	var propertiesWindowShown = false,
		propertyWindow = null;
	
	$(headerDiv).on('mousedown', function(e) {
		if (propertiesWindowShown && propertyWindow && !propertyWindow.disposed) return;

		if (e.shiftKey && this.soundElement) {

			if (this.soundElement.updateTick) {
				this.soundElement.updateTick(null, {id:0, time:audioContext.currentTime})
			} else if (this.soundElement.onTick) {
				this.soundElement.onTick(null, {id:0, time:audioContext.currentTime})
			}

			e.preventDefault();
			e.stopPropagation();
			e.cancelBubble = true;
      		return false;
		}


		if (this.isDragging || e.button == 0 ) return;





		this.ranges.headerColor = this.ranges.headerColor || UI.headerColor;
		this.ranges.themeColor = this.ranges.themeColor || UI.themeColor;
		this.ranges.windowName = this.windowName;

		propertiesWindowShown = true;
		propertyWindow = new UI.Window(UI);
		var opt = {}; opt[this.windowName] = this.ranges;
		var ran = {}; ran[this.windowName] = {
			windowName: {isText: true},
			headerColor: {isColor: true},
			themeColor: {isColor: true},
			isFolder: {hidden:true}, isOpen: {hidden:true},
			positionX: {hidden:true}, positionY: {hidden:true},
			_type: {hidden:true}
		};
		propertyWindow.initFromFolder(this.soundEngine, opt, this.windowName, ran, ['properties -> ']);
		propertyWindow.setPosition(e.pageX, e.pageY);
		document.body.appendChild(propertyWindow.domElement);
		$(propertyWindow.domElement).addClass('openingAnim');

		SoundEngine.watchProperty(this.ranges, 'headerColor', this.updatePropRangeHeaderColor);
		SoundEngine.watchProperty(this.ranges, 'themeColor', this.updatePropRangeThemeColor);
		SoundEngine.watchProperty(this.ranges, 'windowName', this.updatePropRangeWindowName);


		$('#ui-master').on('mousedown.'+this.uuid+' touchstart.'+this.uuid, function(e) {
			$('#ui-master').off('mousemove.'+this.uuid+' touchmove.'+this.uuid);
			if (propertiesWindowShown && propertyWindow) {
				propertyWindow.dispose(true);
				SoundEngine.unwatchProperty(this.ranges, 'headerColor', this.updatePropRangeHeaderColor);
				SoundEngine.unwatchProperty(this.ranges, 'themeColor', this.updatePropRangeThemeColor);
				SoundEngine.unwatchProperty(this.ranges, 'windowName', this.updatePropRangeWindowName);

				propertyWindow = null;
				propertiesWindowShown = false;
			}
		}.bind(this));

		e.preventDefault();
		e.stopPropagation();
		e.cancelBubble = true;
  		return false;

	}.bind(this));

	$(headerDiv).on('contextmenu',function(e){return false;});
	this.updateTheme = function() {
		var color = this.ranges.themeColor;

		this.domElement.id = this.uuid;
		$('#'+this.uuid+' .ui-slider-in').css('background-color',color);
		$('#'+this.uuid+' .ui-number-input').css('color',color);
		//$('#'+this.uuid+' .ui-sequencer-checkbox.checked').css('background-color',color);
		//$('#'+this.uuid+' .ui-sequencer-checkbox .checked').css('background-color',color);
		// var style = $('<style>'+'#'+this.uuid+' .ui-sequencer-checkbox .checked'+' { background-color: '+color+'; }</style>');
		// $('html > head').append(style);
	};


	//-------------------
	//
	//  Allow / Disable UI updates
	//
	//-------------------
	$(this.domElement).on('mouseover')

	$(this.domElement).hover(
		function() {
			folder.enableUIUpdates();
		},
		function() {
			folder.disableUIUpdates();
		}
	);

	//-------------------
	//
	//  Close window
	//
	//-------------------
	$(headerCloseButtonDiv).on('click touchend', function(e){
		if (wasActuallyDragging) {
			wasActuallyDragging = false;
			return;
		}
		wasActuallyDragging = false;
		e.stopPropagation();
		e.preventDefault();

		this.dispose(false, true);

	}.bind(this));


	this.dispose = function(force, fdelete) {

		if (!force && !window.confirm('Supprimer le module: '+$(headerLabelDiv).text()+' ?')) {
			return;
		}

		if (folder) {
			folder.dispose();
			folder = null;
		}
		if (this.disposed) return;
		this.disposed = true;

		if (fdelete) {
			delete this.optionsSource[this.propName];
			delete this.rangesSource[this.propName];
		}

		$(this.domElement).unbind('mouseenter mouseleave');
		$(this.domElement).off('hover');
		$(headerDiv).off();
		$(headerCloseButtonDiv).off();

		$(windowDiv).remove();
		$(headerContainerDiv).remove();
		$(headerDiv).remove();
		$(headerLabelDiv).remove();
		$(arrowDiv).remove();
		$(headerCloseButtonDiv).remove();
		$(containerDiv).remove();

		windowDiv = null;
		headerContainerDiv = null;
		headerDiv = null;
		headerLabelDiv = null;
		arrowDiv = null;
		headerCloseButtonDiv = null;
		containerDiv = null;
		
		for (var i=0; i<UI.allWindows; i++) {
			if (UI.allWindows[i] === this) UI.allWindows[i] = null;
		}

		UI.deleteElement(this.path, this.propName);
		if (this.soundElement) {
			this.soundElement.dispose();
			this.soundElement = null;
		}

		// UI.removeWindow(this);
		// this.soundEngine.removeWindow(this.currentEngineName);

		UI.triggerProperty(this.soundEngine, 'redrawCanvas');
		this.soundEngine = null;
	};


	//-------------------
	//
	//  Context Menu / Panel
	//
	//-------------------
};
export default Window;

